import { sendToUserApp } from '@planity/webview';
import { LoadingPage } from './loading_page';
import React, { useEffect } from 'react';
import { useAuth } from '@planity/components';
import styles from './native_app_landing_page.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { NativeAppLandingPageSignedIn } from './native_app_landing_page_signed_in';
import { NativeAppLandingPageSignedOut } from './native_app_landing_page_signed_out';

export const NativeAppLandingPage = () => {
	useStyles(styles);
	const { isLoading: userIsLoading, isSignedIn } = useAuth();

	useEffect(() => {
		if (!isSignedIn && !userIsLoading)
			sendToUserApp({ type: 'USER_DATA_HAS_FULLY_LOADED' });
	}, [isSignedIn, userIsLoading]);

	if (userIsLoading) return <LoadingPage />;
	if (!isSignedIn) return <NativeAppLandingPageSignedOut />;
	return <NativeAppLandingPageSignedIn />;
};
