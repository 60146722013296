import React, { useEffect, useRef } from 'react';
import { useTranslation } from '@planity/localization';
import { Input, Spinner } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './gift_voucher_payment.modules.scss';
import { formatPrice } from '@planity/helpers';

/**
 * @param {string} giftVoucherNumber Gift voucher number
 * @param {number} amount Available amount of money in cents
 * @param {boolean} isLoading Loading state indicator
 * @param {boolean} isValidated Wether the voucher number was successfully validated
 * @param {boolean} error Error state indicator (if true, triggers error message display)
 * @param {string} errorMessage Error message
 * @param {(voucherNumber: string) => void} onVoucherNumberChange Triggered when input changes
 * @param {() => void} onClickValidate Triggered when user wants to perform voucher number validation
 * @param {() => void} onClickDelete Triggered when user wants to delete the voucher number he provided
 */
export const GiftVoucherPayment = ({
	giftVoucherNumber,
	amount, // should be provided in cents
	isLoading,
	isValidated,
	error,
	errorMessage,
	onFocus,
	onVoucherNumberChange,
	onClickValidate,
	onClickDelete,
	focus
}) => {
	const { t } = useTranslation();

	const inputRef = useRef();

	useEffect(() => {
		// set the focus on the input when the state turns to error after loading
		if ((!isLoading && error) || focus) {
			inputRef.current.focus();
		}
	}, [isLoading, error, focus]);

	useStyles(styles);
	const classes = classNames.bind(styles)({
		giftVoucher: true
	});

	const handleInputChange = value => {
		// truncate & only keep numbers
		const voucherNumber = value.replace(/\D+/g, '');
		// trigger callback only if the sanitized input value is different from the previous one
		if (voucherNumber !== giftVoucherNumber) {
			onVoucherNumberChange(voucherNumber);
		}
	};

	const handleDeleteClick = () => {
		// before focusing, wait for react state to update so the input state is turned to "enabled"
		setTimeout(() => inputRef.current.focus(), 0);
		inputRef.current.focus();
		onClickDelete();
	};

	const shouldDisplayValidateButton = (giftVoucherNumber, error) =>
		giftVoucherNumber.length > 0 && !error;

	const shouldDisplayEndingNumbers = giftVoucherNumber =>
		giftVoucherNumber.length > 5;

	const inputValue = (giftVoucherNumber, isValidated) => {
		if (isValidated) {
			if (shouldDisplayEndingNumbers(giftVoucherNumber)) {
				return t(
					'onlinePayment.giftVoucher.validVoucherStatement.withVoucherNumbers',
					{
						voucherNumberEnding: giftVoucherNumber.slice(-4),
						amount: formatPrice(amount, true)
					}
				);
			} else {
				return t(
					'onlinePayment.giftVoucher.validVoucherStatement.withoutVoucherNumbers',
					{
						amount: formatPrice(amount, true)
					}
				);
			}
		} else {
			return giftVoucherNumber;
		}
	};

	// when the users presses Enter key after editing, trigger validation
	const handleKeyPress = (key, giftVoucherNumber, error) => {
		if (
			key === 'Enter' &&
			shouldDisplayValidateButton(giftVoucherNumber, error) &&
			!isValidated
		) {
			onClickValidate();
		}
	};

	return (
		<div className={`${classes}`}>
			<Input
				className={`${error && styles.error}`}
				error={error}
				errorMessage={errorMessage}
				forwardedRef={inputRef}
				icon={'Gift'}
				isDisabled={isLoading || isValidated}
				label={t('onlinePayment.giftVoucher.title')}
				name={'gift-voucher-number'}
				placeholder={t('onlinePayment.giftVoucher.placeholder')}
				suffix={
					isLoading ? (
						<div className={styles.spinner}>
							<Spinner />
						</div>
					) : (
						shouldDisplayValidateButton(giftVoucherNumber, error) && (
							<span
								className={styles.suffix}
								onClick={() => {
									isValidated ? handleDeleteClick() : onClickValidate();
								}}
							>
								{isValidated
									? t('onlinePayment.giftVoucher.delete')
									: t('onlinePayment.giftVoucher.validate')}
							</span>
						)
					)
				}
				value={inputValue(giftVoucherNumber, isValidated)}
				onChange={handleInputChange}
				onFocus={onFocus}
				onKeyPress={event =>
					handleKeyPress(event.key, giftVoucherNumber, error)
				}
			/>
		</div>
	);
};
