import React from 'react';

export const Crosshair = ({ ...props }) => (
	<svg
		width='25'
		height='24'
		viewBox='0 0 25 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M18.65 12C18.65 15.4518 15.8518 18.25 12.4 18.25C8.94821 18.25 6.14999 15.4518 6.14999 12C6.14999 8.54822 8.94821 5.75 12.4 5.75C15.8518 5.75 18.65 8.54822 18.65 12Z'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M12.4 4.75V9.25'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M19.6499 12L15.1499 12'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M12.4 14.75V19.25'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M9.64993 12L5.14993 12'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
