import React from 'react';

export const BR = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_4812_776)'>
				<mask
					id='mask0_4812_776'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_4812_776)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#009933'
					/>
					<mask
						id='mask1_4812_776'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_4812_776)'>
						<g filter='url(#filter0_d_4812_776)'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M9.95424 2.31503L17.5804 7.62969L9.8505 12.607L2.38058 7.52667L9.95424 2.31503Z'
								fill='#FFD221'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M9.95424 2.31503L17.5804 7.62969L9.8505 12.607L2.38058 7.52667L9.95424 2.31503Z'
								fill='url(#paint0_linear_4812_776)'
							/>
						</g>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M10 10.75C11.7259 10.75 13.125 9.35089 13.125 7.625C13.125 5.89911 11.7259 4.5 10 4.5C8.27411 4.5 6.875 5.89911 6.875 7.625C6.875 9.35089 8.27411 10.75 10 10.75Z'
							fill='#2E42A5'
						/>
						<mask
							id='mask2_4812_776'
							style={{ maskType: 'luminance' }}
							maskUnits='userSpaceOnUse'
							x='6'
							y='4'
							width='8'
							height='7'
						>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M10 10.75C11.7259 10.75 13.125 9.35089 13.125 7.625C13.125 5.89911 11.7259 4.5 10 4.5C8.27411 4.5 6.875 5.89911 6.875 7.625C6.875 9.35089 8.27411 10.75 10 10.75Z'
								fill='white'
							/>
						</mask>
						<g mask='url(#mask2_4812_776)'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M8.9875 9.10625L8.8479 9.17964L8.87456 9.0242L8.76162 8.91411L8.9177 8.89143L8.9875 8.75L9.0573 8.89143L9.21338 8.91411L9.10044 9.0242L9.1271 9.17964L8.9875 9.10625Z'
								fill='#F7FCFF'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M10.2375 9.10625L10.0979 9.17964L10.1246 9.0242L10.0116 8.91411L10.1677 8.89143L10.2375 8.75L10.3073 8.89143L10.4634 8.91411L10.3504 9.0242L10.3771 9.17964L10.2375 9.10625Z'
								fill='#F7FCFF'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M10.2375 9.85625L10.0979 9.92964L10.1246 9.7742L10.0116 9.66411L10.1677 9.64143L10.2375 9.5L10.3073 9.64143L10.4634 9.66411L10.3504 9.7742L10.3771 9.92964L10.2375 9.85625Z'
								fill='#F7FCFF'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M9.6125 7.23125L9.4729 7.30464L9.49956 7.1492L9.38662 7.03911L9.5427 7.01643L9.6125 6.875L9.6823 7.01643L9.83838 7.03911L9.72544 7.1492L9.7521 7.30464L9.6125 7.23125Z'
								fill='#F7FCFF'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M9.6125 8.48125L9.4729 8.55464L9.49956 8.3992L9.38662 8.28911L9.5427 8.26643L9.6125 8.125L9.6823 8.26643L9.83838 8.28911L9.72544 8.3992L9.7521 8.55464L9.6125 8.48125Z'
								fill='#F7FCFF'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M8.73748 7.85625L8.59789 7.92964L8.62455 7.7742L8.51161 7.66411L8.66769 7.64143L8.73748 7.5L8.80728 7.64143L8.96336 7.66411L8.85042 7.7742L8.87708 7.92964L8.73748 7.85625Z'
								fill='#F7FCFF'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M7.86247 8.35625L7.72287 8.42964L7.74953 8.2742L7.63659 8.16411L7.79267 8.14143L7.86247 8L7.93227 8.14143L8.08835 8.16411L7.97541 8.2742L8.00207 8.42964L7.86247 8.35625Z'
								fill='#F7FCFF'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M10.7375 6.23125L10.5979 6.30464L10.6246 6.1492L10.5117 6.03911L10.6677 6.01643L10.7375 5.875L10.8073 6.01643L10.9634 6.03911L10.8505 6.1492L10.8771 6.30464L10.7375 6.23125Z'
								fill='#F7FCFF'
							/>
							<path
								d='M6.20301 6.87323L6.297 5.62677C9.29571 5.85289 11.6625 6.83887 13.3676 8.59276L12.4714 9.46409C10.9935 7.94395 8.91399 7.07765 6.20301 6.87323Z'
								fill='#F7FCFF'
							/>
						</g>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
				style={{ mixBlendMode: 'multiply' }}
			/>
			<defs>
				<filter
					id='filter0_d_4812_776'
					x='2.38058'
					y='2.31503'
					width='15.1998'
					height='10.292'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset />
					<feColorMatrix
						type='matrix'
						values='0 0 0 0 0.0313726 0 0 0 0 0.368627 0 0 0 0 0 0 0 0 0.28 0'
					/>
					<feBlend
						mode='normal'
						in2='BackgroundImageFix'
						result='effect1_dropShadow_4812_776'
					/>
					<feBlend
						mode='normal'
						in='SourceGraphic'
						in2='effect1_dropShadow_4812_776'
						result='shape'
					/>
				</filter>
				<linearGradient
					id='paint0_linear_4812_776'
					x1='20'
					y1='15'
					x2='20'
					y2='0'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#FFC600' />
					<stop offset='1' stopColor='#FFDE42' />
				</linearGradient>
				<clipPath id='clip0_4812_776'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
