export const CAC_FLOW_MACHINE_STATES = {
	CART: 'cart',
	AUTHENTICATION: 'authentication',
	CREDIT_CARD: 'creditCard',
	CAN_PAY: 'canPay',
	PAYING: 'paying',
	CONFIRMED: 'confirmed',
	CANCEL: 'cancel',
	DELIVERY_FORM: 'deliveryForm',
	REINJECT_DATA: 'reinjectData'
};
