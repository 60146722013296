import { Icon } from '@planity/ui';
import { useTranslation } from '@planity/localization';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './children_limit_warning.module.scss';

export const ChildrenLimitWarning = ({ className = '' }) => {
	useStyles(styles);

	const { t } = useTranslation();
	const classes = classNames.bind(styles)({
		limitWarning: true,
		[className]: true
	});

	return (
		<div className={classes}>
			<Icon icon='Information' size={'small'} />
			<div className={styles.warningText}>
				<span className={styles.text1}>{t('children.warning.text1')}</span>
				<span className={styles.text2}>{t('children.warning.text2')}</span>
			</div>
		</div>
	);
};
