import React, { useCallback, useEffect } from 'react';
import { useAppBanner, TEMPLATES_MODAL } from '@planity/context';
import { useTranslation } from '@planity/localization';
import { Button, Logo, useModal } from '@planity/ui';
import styles from './app_banner_modal.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Localize, useViewerHeaders } from '@planity/components';
import classNames from 'classnames/bind';
import {
	getBrowser,
	CHROME_ANDROID,
	CHROME_IOS,
	EDGE,
	FIREFOX,
	SAFARI_IOS,
	SAMSUNG_INTERNET,
	UNKNOWN_BROWSER,
	sendAppBannerHasBeenDisplayed,
	sendAppBannerHasBeenClicked
} from '@planity/helpers';
import {
	FirefoxSvg,
	ChromeSvg,
	DefaultBrowserSvg,
	SafariSvg,
	SamsungInternetSvg,
	EdgeSvg
} from '@planity/ui/assets/icons';
import config from '@planity/credentials';

const logoMap = {
	[FIREFOX]: FirefoxSvg,
	[CHROME_ANDROID]: ChromeSvg,
	[CHROME_IOS]: ChromeSvg,
	[SAFARI_IOS]: SafariSvg,
	[SAMSUNG_INTERNET]: SamsungInternetSvg,
	[EDGE]: EdgeSvg,
	[UNKNOWN_BROWSER]: DefaultBrowserSvg
};

export const AppBannerModal = () => {
	useStyles(styles);
	const { t } = useTranslation();
	const { bannerTemplate, dismissAppBanner } = useAppBanner();
	const { closeModal } = useModal();
	const { isIOSViewer, isAndroidViewer } = useViewerHeaders();
	const browser = getBrowser({ isIOSViewer, isAndroidViewer });
	const classes = classNames.bind(styles);
	const BrowserLogo = logoMap[browser] || DefaultBrowserSvg;

	useEffect(() => {
		sendAppBannerHasBeenDisplayed();
	}, []);
	const stayOnWebsite = useCallback(() => {
		dismissAppBanner();
		closeModal();
	}, [closeModal, dismissAppBanner]);

	// When the user arrives on the application.
	const ModalDefault = () => (
		<div className={classes({ appBannerModal: true })}>
			<div className={styles.modalContainer}>
				<Localize text={'applicationBanner.modal.description'} />

				<div className={styles.modalContentItem}>
					<div className={classes({ browserIcon: true, planityLogo: true })}>
						<Logo type='app' />
					</div>
					{t('applicationBanner.modal.appPlanity')}
					<div className={styles.buttonColumn}>
						<Button
							label={t('applicationBanner.modal.open')}
							onClick={() => sendAppBannerHasBeenClicked()}
							isExternal
							to={config.USER_APP_LINK}
						/>
					</div>
				</div>
				<div className={styles.modalContentItem}>
					<div className={styles.browserIcon}>
						<BrowserLogo />
					</div>
					<Localize text={`browser.${browser}`} />
					<div className={styles.buttonColumn}>
						<Button
							type={'linked'}
							label={t('applicationBanner.modal.continue')}
							onClick={stayOnWebsite}
						/>
					</div>
				</div>
			</div>
		</div>
	);

	// When it comes to scheduling an appointment.
	const ModalBookAppointment = () => (
		<div
			className={classes({
				appBannerModal: true,
				modalPadding: true
			})}
		>
			<div className={styles.modalContainer}>
				<Logo type='app' />
				<div className={styles.modalDescriptionColumn}>
					{t('applicationBanner.modal.description2')}
				</div>
				<div className={styles.modalItemsColumnList}>
					<div className={styles.modalContentItemColumn}>
						<Button
							label={t('applicationBanner.modal.download')}
							onClick={() => sendAppBannerHasBeenClicked()}
							isExternal
							to={config.USER_APP_LINK}
							isFullWidth
						/>
					</div>
					<div className={styles.modalContentItemColumn}>
						<Button
							type={'linked'}
							label={t('applicationBanner.modal.ignore')}
							onClick={stayOnWebsite}
						/>
					</div>
				</div>
			</div>
		</div>
	);

	return TEMPLATES_MODAL.BOOK_APPOINTMENT === bannerTemplate ? (
		<ModalBookAppointment />
	) : (
		<ModalDefault />
	);
};
