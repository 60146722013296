import React from 'react';
import classNames from 'classnames/bind';
import styles from './busines_rating.module.scss';
import { Icon } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';

export function BusinessRating({ total = 4.8, items = [], voteCount = 0 }) {
	const { t } = useTranslation();
	useStyles(styles);
	const classes = classNames.bind(styles)({
		rating: true
	});

	return (
		<div className={classes}>
			<div className={styles.container}>
				<div className={styles.left}>
					<span className={styles.total}>{total}</span>
				</div>
				<div className={styles.right}>
					<ul className={styles.list}>
						{items.map((item, i) => (
							<li key={i} className={styles.row}>
								<span className={styles.category}>{item.category}</span>
								<span className={styles.note}>
									{item.note}
									<Icon
										icon={'FullStar'}
										className={styles.icon}
										size={'small'}
									/>
								</span>
							</li>
						))}
					</ul>
					<span className={styles.vote}>
						{t('business.reviews.ratingsCount', {
							numberOfReviews: voteCount,
							count: voteCount
						})}
					</span>
				</div>
			</div>
		</div>
	);
}
