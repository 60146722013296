import React from 'react';
import styles from './gift_voucher_header.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import { CloudinaryPicture, Localize } from '@planity/components';

export default function GiftVoucherHeader({
	boughtAt,
	business,
	giftVoucherId,
	receiver,
	expiresAt,
	linkToBusiness
}) {
	useStyles(styles);

	const classes = classNames.bind(styles)({
		giftVoucherHeader: true
	});

	return (
		<div className={classes}>
			<div
				className={styles.image}
				id='gift-product-image'
				onClick={() => linkToBusiness(business)}
			>
				{business?.pictures?.[0] ? (
					<CloudinaryPicture
						alt={`image-${business.name}`}
						src={business?.pictures?.at(0)}
						transformation={'t_m_main'}
					/>
				) : (
					<div className={styles.noPicture}></div>
				)}
			</div>

			<div className={styles.giftVoucherId}>
				<span id='gift-voucher-header-giftVoucherId'>
					<Localize text={'myAccount.orders.giftVoucherId'} />
					{giftVoucherId}
				</span>
			</div>

			<div className={styles.giftLeftInfo}>
				<div className={styles.boughtAt}>
					<div className={styles.label}>
						<Localize text={'myAccount.orders.creationDate'} />
					</div>
					<div className={styles.value}>
						<span id='gift-voucher-header-boughtAt'>{boughtAt}</span>
					</div>
				</div>
				{!!receiver && (
					<div className={styles.receiver}>
						<div className={styles.label}>
							<Localize text={'myAccount.orders.receiver'} />
						</div>
						<div className={styles.value}>
							<span id='gift-voucher-header-receiver'>{receiver}</span>
						</div>
					</div>
				)}
				<div className={styles.expiresAt}>
					<div className={styles.label}>
						<Localize text={'myAccount.orders.expirationDate'} />
					</div>
					<div className={styles.value}>
						<span id='gift-voucher-header-expiresAt'>{expiresAt}</span>
					</div>
				</div>
			</div>
		</div>
	);
}
