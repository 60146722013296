import React, { Component } from 'react';
import {
	availabilitiesShards,
	masterShard,
	publicShard
} from '@planity/datastores/firebase';
import { identity, noop } from '@planity/helpers';
import CreateFetcher from '../create_fetcher';
import { WithFirebaseSubscriptions } from '../firebase_subscriptions_context';
import hash from 'object-hash';

const SHARDS = {
	availabilities1: availabilitiesShards[1],
	availabilities2: availabilitiesShards[2],
	master: masterShard,
	public: publicShard
};

export default function FirebaseSubscriptionWithContext(props) {
	return (
		<WithFirebaseSubscriptions>
			{context => (
				<FirebaseSubscription subscriptionsContext={context} {...props} />
			)}
		</WithFirebaseSubscriptions>
	);
}

class FirebaseSubscription extends Component {
	static defaultProps = {
		format: identity,
		shard: 'master'
	};
	initialState = {
		data: null
	};
	componentDidMount() {
		if (this.props.subscriptionsContext) {
			this.props.subscriptionsContext.register(this.queryHash());
		}
	}
	render() {
		const {
			children,
			debug,
			cacheProvider,
			onChange,
			subscriptionsContext,
			format,
			...query
		} = this.props;
		return (
			<CreateFetcher
				cacheProvider={this.props.cacheProvider}
				debug={this.props.debug && `FirebaseSubscription(${this.props.debug})`}
				getServerCacheProviderKey={() => `${query.path}@firebase`}
				initialState={this.initialState}
				isDisabled={!query.path}
				isPermanent={!this.props.once}
				query={query}
				subscribe={this.subscribe}
				onChange={onChange}
			>
				{children
					? ({ isLoading, data }) => {
							return children({
								data,
								isLoading:
									isLoading ||
									!!(
										subscriptionsContext && subscriptionsContext.queries.length
									)
							});
					  }
					: null}
			</CreateFetcher>
		);
	}
	subscribe = ({ emit, once: mustOnce }) => {
		const {
			once: shouldOnce,
			path,
			orderByKey,
			orderByChild,
			orderByValue,
			startAt,
			equalTo,
			format,
			endAt,
			limitToFirst,
			limitToLast,
			subscriptionsContext,
			shard
		} = this.props;
		if (path) {
			const firebase = SHARDS[shard] || masterShard;
			let ref = firebase.database().ref(path);
			if (orderByKey) {
				ref = ref.orderByKey();
			} else if (orderByChild) {
				ref = ref.orderByChild(orderByChild);
			} else if (orderByValue) {
				ref = ref.orderByValue(orderByValue);
			}
			if (isValidLimit(startAt)) ref = ref.startAt(startAt);
			if (isValidLimit(equalTo)) ref = ref.equalTo(equalTo);
			if (isValidLimit(endAt))
				ref = ref.endAt(typeof endAt === 'string' ? `${endAt}\uf8ff` : endAt);
			if (limitToFirst) ref = ref.limitToFirst(limitToFirst);
			if (limitToLast) ref = ref.limitToLast(limitToLast);
			const publish = snapshot => {
				emit({ data: format(snapshot.val()) });
				if (subscriptionsContext) {
					subscriptionsContext.release(this.queryHash());
				}
			};
			if (mustOnce || shouldOnce) {
				return ref.once('value').then(publish);
			} else {
				ref.on('value', publish);
				return () => ref.off('value', publish);
			}
		} else {
			emit({ data: undefined });
			return noop;
		}
	};
	queryHash() {
		const {
			children,
			debug,
			cacheProvider,
			onChange,
			subscriptionsContext,
			format,
			...query
		} = this.props;
		return hash(query);
	}
}

function isValidLimit(limit) {
	return limit || limit === 0 || limit === '';
}
