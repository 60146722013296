import React, { Fragment, forwardRef } from 'react';
import { colors } from '@planity/theme';
import Localize from '../localize/helper';
import { breakpoints } from '@planity/theme';
import { Spinner } from '@planity/ui';

export default forwardRef(function Button(
	{
		text,
		localizedText,
		onPress,
		className,
		isDisabled,
		isActive,
		isSecondary,
		component,
		renderIcon,
		isExternal,
		isLoading,
		href,
		style,
		customClassName,
		localizedArgs,
		target,
		...buttonProps
	},
	ref
) {
	const isUsable = !isActive && !isDisabled;
	const color = isSecondary
		? {
				backgroundColor: 'transparent',
				color: colors.black.text,
				[breakpoints.homeDesktopQuery]: {
					':hover': {
						color: colors.grey.brownGrey,
						borderColor: colors.grey.brownGrey
					}
				}
		  }
		: {
				backgroundColor: colors.black.background,
				border: 'none',
				color: colors.white,
				[breakpoints.homeDesktopQuery]: {
					':hover': {
						color: colors.white,
						background: colors.grey.brownGrey,
						borderColor: colors.grey.brownGrey
					}
				}
		  };
	const ButtonTag = component || 'button';
	if (isExternal) {
		return (
			<a
				css={{ textDecoration: 'none' }}
				href={href}
				rel='noreferrer'
				target={target || '_blank'}
			>
				<Localize
					args={localizedArgs}
					localizedText={localizedText}
					text={text}
				>
					{text => (
						<ButtonTag
							className={[
								'planity-component-button',
								className,
								customClassName
							]
								.filter(x => !!x)
								.join(' ')}
							css={[
								buttonStyles.button,
								color,
								!isUsable && buttonStyles.unusableButton,
								!isUsable && !isSecondary && buttonStyles.unusablePrimaryButton,
								Array.isArray(style) ? null : style
							].concat(Array.isArray(style) ? style : [])}
							ref={ref}
							onClick={isDisabled || isActive ? undefined : onPress}
							{...buttonProps}
						>
							{renderIcon && renderIcon()}
							{text}
						</ButtonTag>
					)}
				</Localize>
			</a>
		);
	}
	return (
		<Localize args={localizedArgs} localizedText={localizedText} text={text}>
			{text => (
				<Fragment>
					{isLoading ? (
						<div
							className={className}
							css={[
								buttonStyles.button,
								color,
								!isUsable && buttonStyles.unusableButton,
								!isUsable && !isSecondary && buttonStyles.unusablePrimaryButton,
								style
							]}
						>
							<Spinner />
						</div>
					) : (
						<ButtonTag
							className={[
								'planity-component-button',
								className,
								customClassName
							]
								.filter(x => !!x)
								.join(' ')}
							css={[
								buttonStyles.button,
								color,
								className,
								!isUsable && buttonStyles.unusableButton,
								!isUsable && !isSecondary && buttonStyles.unusablePrimaryButton,
								Array.isArray(style) ? null : style
							].concat(Array.isArray(style) ? style : [])}
							ref={ref}
							onClick={isDisabled || isActive ? undefined : onPress}
							{...buttonProps}
						>
							{renderIcon && renderIcon()}
							{text}
						</ButtonTag>
					)}
				</Fragment>
			)}
		</Localize>
	);
});

export const buttonStyles = {
	button: {
		'flexShrink': 0,
		'display': 'inline-flex',
		'alignItems': 'center',
		'justifyContent': 'center',
		'cursor': 'pointer',
		'textDecoration': 'none',
		'fontSize': 14,
		'lineHeight': 1.29,
		'borderRadius': 4,
		'border': 'solid 1px #000000',
		'padding': '12px 30px',
		'width': 'fit-content',
		'maxHeight': 42,
		'transition':
			'background-color .3s ease-out, color .3s ease-out, border-color .3s ease-out',
		'minHeight': 42,
		':focus': { outline: 0 },
		'userSelect': 'none'
	},
	unusableButton: {
		cursor: 'not-allowed',
		borderColor: colors.grey.border,
		color: colors.grey.border
	},
	unusablePrimaryButton: {
		background: colors.grey.brownGrey,
		borderColor: colors.grey.brownGrey,
		color: 'white'
	}
};
