import classNames from 'classnames/bind';
import styles from './gift_voucher_customisation.module.scss';
import { Textarea, Button } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';

const CHARACTER_LIMIT = 150;

export function GiftVoucherCustomisation({
	message,
	messageIsMinified,
	onChange,
	onClick,
	onSkip,
	setMessageIsMin,
	isPending
}) {
	useStyles(styles);
	const { t } = useTranslation();

	const classes = classNames.bind(styles)({
		giftVoucherCustomisation: !messageIsMinified,
		message: messageIsMinified
	});

	return (
		<div className={classes}>
			{messageIsMinified ? (
				<div className={styles.info}>
					<span className={styles.space}>{message || t('gift.noMessage')}</span>
					<Button
						label={t('common.edit')}
						type='underlined'
						onClick={() => setMessageIsMin(false)}
						isLoading={isPending}
					/>
				</div>
			) : (
				<div>
					<Textarea
						className={styles.textarea}
						placeholder={t('gift.message')}
						characterLimit={CHARACTER_LIMIT}
						minHeight={120}
						id={'gift-voucher-form-textarea'}
						maxLength={CHARACTER_LIMIT}
						onChange={onChange}
						value={message}
					/>
					<Button label={t('gift.validate')} isFullWidth onClick={onClick} />
					<Button
						id={'voucher-skip-step'}
						className={styles.skip}
						label={t('myAccount.giftVouchers.skipStep')}
						isFullWidth
						type='linked'
						onClick={onSkip}
					/>
				</div>
			)}
		</div>
	);
}
