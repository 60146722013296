import React from 'react';

export const TN = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5981)'>
				<mask
					id='mask0_11534_5981'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5981)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#E31D1C'
					/>
					<mask
						id='mask1_11534_5981'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_11534_5981)'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M10 12.5C12.7614 12.5 15 10.2614 15 7.5C15 4.73858 12.7614 2.5 10 2.5C7.23858 2.5 5 4.73858 5 7.5C5 10.2614 7.23858 12.5 10 12.5Z'
							fill='#F7FCFF'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M10.8767 11.0317C10.8767 11.0317 8.24146 10.3128 8.24146 7.48341C8.24146 4.65404 10.8767 3.87346 10.8767 3.87346C9.78701 3.45171 6.59803 4.09882 6.59803 7.48341C6.59803 10.868 9.87597 11.4775 10.8767 11.0317ZM10.7316 6.86772L9.4242 7.34542L10.8294 7.83661L10.8767 9.15219L11.7319 8.13014L13.1419 8.23023L12.126 7.40139L12.7378 6.20445L11.5427 6.60673L10.7147 5.57178L10.7316 6.86772Z'
							fill='#E31D1C'
						/>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5981'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
