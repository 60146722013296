export const userCanDisplayConfirm = appointment => {
	const { steps, date, hasConfirmableUser } = appointment;

	return (
		!!steps?.length &&
		!steps.find(step => !step.serviceId) &&
		!!date &&
		!!hasConfirmableUser
	);
};
