export function Database({ color }) {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M19.65 7C19.65 8.10457 16.266 9.25 12.4 9.25C8.534 9.25 5.14999 8.10457 5.14999 7C5.14999 5.89543 8.534 4.75 12.4 4.75C16.266 4.75 19.65 5.89543 19.65 7Z'
				stroke={color || 'currentColor'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M19.65 12C19.65 13.1046 16.266 14.25 12.4 14.25C8.534 14.25 5.14999 13.1046 5.14999 12'
				stroke={color || 'currentColor'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M19.65 7V17C19.65 18.1046 16.266 19.25 12.4 19.25C8.534 19.25 5.14999 18.1046 5.14999 17V7'
				stroke={color || 'currentColor'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
