// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".breadcrumbs-module_breadcrumbs-tBgt3{max-width:calc(1176px + 2*var(--gutter));margin:0 auto;padding:12px var(--gutter)}.breadcrumbs-module_breadcrumbs-tBgt3 .breadcrumbs-module_nav-pgBDe{display:flex;flex:1;width:100%;flex-direction:row}.breadcrumbs-module_breadcrumbs-tBgt3 .breadcrumbs-module_center-HmRf7{display:flex;align-items:center}.breadcrumbs-module_breadcrumbs-tBgt3.breadcrumbs-module_removePadding-OizNu .breadcrumbs-module_nav-pgBDe{padding:0}.breadcrumbs-module_breadcrumbs-tBgt3.breadcrumbs-module_hasBackgroundColor-G-8NM{background-color:var(--grey-100)}@media only screen and (max-width: 767px){.breadcrumbs-module_breadcrumbs-tBgt3.breadcrumbs-module_isHiddenOnMobile-SuiWJ{display:none}}@media only screen and (min-width: 768px)and (max-width: 1079px){.breadcrumbs-module_breadcrumbs-tBgt3.breadcrumbs-module_isHiddenOnTablet-EXD6T{display:none}}", "",{"version":3,"sources":["webpack://./app/components/layout/breadcrumbs.module.scss"],"names":[],"mappings":"AAEA,sCACC,wCAAA,CACA,aAAA,CACA,0BAAA,CAEA,oEACC,YAAA,CACA,MAAA,CACA,UAAA,CACA,kBAAA,CAED,uEACC,YAAA,CACA,kBAAA,CAGA,2GACC,SAAA,CAIF,kFACC,gCAAA,CAIA,0CADD,gFAEE,YAAA,CAAA,CAKD,iEADD,gFAEE,YAAA,CAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.breadcrumbs {\n\tmax-width: calc(1176px + 2 * var(--gutter));\n\tmargin: 0 auto;\n\tpadding: 12px var(--gutter);\n\n\t.nav {\n\t\tdisplay: flex;\n\t\tflex: 1;\n\t\twidth: 100%;\n\t\tflex-direction: row;\n\t}\n\t.center {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t}\n\t&.removePadding {\n\t\t.nav {\n\t\t\tpadding: 0;\n\t\t}\n\t}\n\n\t&.hasBackgroundColor {\n\t\tbackground-color: var(--grey-100);\n\t}\n\n\t&.isHiddenOnMobile {\n\t\t@media only screen and (max-width: #{styles.$tablet - 1px}) {\n\t\t\tdisplay: none;\n\t\t}\n\t}\n\n\t&.isHiddenOnTablet {\n\t\t@media only screen and (min-width: styles.$tablet) and (max-width: #{styles.$desktop - 1px}) {\n\t\t\tdisplay: none;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"breadcrumbs": "breadcrumbs-module_breadcrumbs-tBgt3",
	"nav": "breadcrumbs-module_nav-pgBDe",
	"center": "breadcrumbs-module_center-HmRf7",
	"removePadding": "breadcrumbs-module_removePadding-OizNu",
	"hasBackgroundColor": "breadcrumbs-module_hasBackgroundColor-G-8NM",
	"isHiddenOnMobile": "breadcrumbs-module_isHiddenOnMobile-SuiWJ",
	"isHiddenOnTablet": "breadcrumbs-module_isHiddenOnTablet-EXD6T"
};
module.exports = ___CSS_LOADER_EXPORT___;
