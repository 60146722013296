export function getDesktopWidth(columnsNumber, intercolumnsNumber) {
	return `calc((100vw / 12 - 30px)*${columnsNumber} + ${intercolumnsNumber} * 15px)`;
}

export function getTabletWidth(columnsNumber, intercolumnsNumber) {
	return `calc((100vw / 6 - 30px)*${columnsNumber} + ${intercolumnsNumber} * 15px)`;
}

export function getMobileWidth(columnsNumber, intercolumnsNumber) {
	return `calc((100vw / 4 - 30px)*${columnsNumber} + ${intercolumnsNumber} * 15px)`;
}

export const mobilePadding = 15;

export const desktopPadding = 'calc((100vw - 1185px)/2)';

export const tabletPadding = 'calc((100vw - 720px)/2)';
