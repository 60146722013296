import React from 'react';

export function TagIcon() {
	return (
		<svg
			width='25'
			height='24'
			viewBox='0 0 25 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M16.1 9C16.1 9.27614 15.8761 9.5 15.6 9.5C15.3238 9.5 15.1 9.27614 15.1 9C15.1 8.72386 15.3238 8.5 15.6 8.5C15.8761 8.5 16.1 8.72386 16.1 9Z'
				stroke='currentColor'
			/>
			<path
				d='M12.6 4.75H19.85V12L13.1535 18.6708C12.3545 19.4668 11.0556 19.445 10.2837 18.6226L5.88994 13.941C5.14042 13.1424 5.17266 11.8895 5.96228 11.1305L12.6 4.75Z'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
