import React from 'react';

export default function Inputs({ className, children, customClassName }) {
	return (
		<div
			className={`planity-inputs ${customClassName}`}
			css={[
				{
					display: 'flex',
					marginBottom: 18
				},
				className
			]}
		>
			{children}
		</div>
	);
}
