import { useViewerHeaders } from '@planity/components';
import { getBrowser } from '@planity/helpers';
import { UNKNOWN_BROWSER } from '@planity/helpers/browser';
import { Button, useModal } from '@planity/ui';
import { useTranslation } from '@planity/localization';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './localization_denied_modal.module.scss';
import classNames from 'classnames/bind';

export const LocalizationDeniedModal = () => {
	const { t } = useTranslation();
	const { closeModal } = useModal();

	const { isIOSViewer, isAndroidViewer } = useViewerHeaders();
	const detectedBrowser = getBrowser({ isIOSViewer, isAndroidViewer });
	const browserHelperUrl =
		detectedBrowser && detectedBrowser !== UNKNOWN_BROWSER
			? t(`localization.helper.${detectedBrowser}`)
			: null;
	useStyles(styles);
	const classes = classNames.bind(styles)({
		localizationDeniedModal: true
	});

	return (
		<div className={classes}>
			<div className={styles.modalTitle}>
				{t('localization.denied.modal.title')}
			</div>
			<p className={styles.modalContent}>
				{t('localization.denied.modal.content')}
			</p>
			<div className={styles.modalButtons}>
				<Button
					isFullWidth
					label={t('localization.denied.modal.gotit')}
					size='large'
					type='primary'
					onClick={closeModal}
				/>
				{browserHelperUrl && (
					<Button
						isFullWidth
						label={t('localization.denied.modal.knowmore')}
						type='secondary'
						onClick={() => window.open(browserHelperUrl)}
					/>
				)}
			</div>
		</div>
	);
};
