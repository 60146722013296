import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import { addDays, isSameDay } from 'date-fns';
import { useTranslation } from '@planity/localization';
import styles from './date_picker_filters.module.scss';

export const DatePickerFilters = ({
	className,
	onFilterClick,
	selectedDate
}) => {
	useStyles(styles);
	const NOW = useMemo(() => new Date(), []);
	const isTodaySelected = selectedDate && isSameDay(selectedDate, NOW);
	const isTomorrowSelected =
		selectedDate && isSameDay(selectedDate, addDays(NOW, 1));
	const { t } = useTranslation();
	const classes = classNames.bind(styles)({
		DatePickerFilters: true,
		[className]: className !== undefined
	});
	return (
		<div className={classes}>
			<div
				className={`${styles.filter} ${
					!selectedDate && styles.filter__selected
				}`} // mean no special date is selected yet}
				onClick={() => onFilterClick(null)}
			>
				{t('search.filter.dateReset')}
			</div>
			<div
				className={`${styles.filter} ${
					isTodaySelected && styles.filter__selected
				}`}
				onClick={() => onFilterClick(NOW)}
			>
				{t('search.filter.today')}
			</div>
			<div
				className={`${styles.filter} ${
					isTomorrowSelected && styles.filter__selected
				}`}
				onClick={() => onFilterClick(addDays(NOW, 1))}
			>
				{t('search.filter.tomorrow')}
			</div>
		</div>
	);
};
