import {
	BookAppointment,
	BookGiftVoucher,
	Localize,
	OpeningHours,
	Tabs,
	useUserQueries
} from '@planity/components';
import credentials from '@planity/credentials';
import { noop, parseOpeningHours } from '@planity/helpers';
import {
	sendBookingDoesNotMatchSearchDate,
	sendBookingMatchesSearchDate
} from '@planity/helpers/analytics';
import { useLocalizedRoutes } from '@planity/localization';
import { BusinessReviewsCard, BusinessTitle, Spinner } from '@planity/ui';
import { useFilters } from 'planity-website/app/components/ui/filter/context';
import classNames from 'classnames/bind';
import { isSameDay, parseISO } from 'date-fns';
import useStyles from 'isomorphic-style-loader/useStyles';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { PageSection, SplitPage } from '../../layout';
import styles from './business_info.module.scss';
import { PastAppointments } from './pastAppointments';
import WithHydration from './withHydration';

const { ENABLE_USER_PAYS_FEES } = credentials;

export function BusinessInfo(props) {
	useStyles(styles);
	const { routes } = useLocalizedRoutes();
	const history = useHistory();
	const { userQueries, isLoadingUserQueries } = useUserQueries();
	const { filters: userFilter } = useFilters();
	const {
		business,
		businessId,
		showRating,
		onBookingStart,
		hasStartedBooking,
		hasStartedBookingGiftVoucher,
		onBookingSuccess,
		hasSuccessfulBooking,
		setLastSuccessfulAppointment,
		publishBookingStepEvent,
		publishBookingSuccessEvent,
		warnOnLeave,
		initialServiceId,
		noRedirection,
		isLoading,
		giftCards,
		onTabChanged = noop,
		currentTabId,
		popupGiftService,
		isPreview,
		highlightedServices,
		giftVoucherOptionEnabled,
		forwardedRef,
		menuTabs,
		globalSearch,
		pageContext,
		businessIsFullyLoaded,
		displayClickAndCollect,
		displayClickAndShop,
		parentPlace,
		place,
		crumbs
	} = props;

	const classes = classNames.bind(styles);

	const openingHours = parseOpeningHours(business?.openingHours, {
		convertToMinutes: false
	});
	const hasPastAppointments =
		!!businessIsFullyLoaded &&
		!!userQueries[businessId] &&
		Object.keys(userQueries || {}).length > 0;
	const businessType = getBusinessType(business);

	const userPaysFees =
		business?.settings?.onlinePayment?.userPaysFees && ENABLE_USER_PAYS_FEES;
	const userPrePayment = business?.settings?.onlinePayment?.prePayment;

	const onBookAppointmentSuccess = ({
		sequenceId,
		appointmentMoved,
		articles: products,
		vevents
	}) =>
		onBookingSuccess(() => {
			setLastSuccessfulAppointment(
				{
					lastSuccessfulAppointmentId: sequenceId,
					appointmentMoved,
					products,
					vevents
				},
				() => {
					history.replace(routes.myAccount);
					const searchDate = userFilter.data && userFilter.data.date;
					const bookDate = vevents[0].userSequenceStart;
					if (searchDate) {
						if (isSameDay(searchDate, parseISO(bookDate))) {
							sendBookingMatchesSearchDate();
						} else {
							sendBookingDoesNotMatchSearchDate();
						}
					}
				}
			);
			if (publishBookingSuccessEvent) {
				publishBookingSuccessEvent(businessId, businessType);
			}
		});
	const subtitle = () => {
		if (userPaysFees) {
			if (userPrePayment) {
				return 'business.businessPageSubtitleNotFreePrePayment';
			} else {
				return 'business.businessPageSubtitleNotFree';
			}
		} else {
			if (userPrePayment) {
				return 'business.businessPageSubtitleFreePrePayment';
			} else {
				return 'business.valueProp';
			}
		}
	};
	return (
		<WithHydration>
			<div
				className={classes({
					business: !hasStartedBooking && !hasStartedBookingGiftVoucher,
					booking: hasStartedBooking || hasStartedBookingGiftVoucher
				})}
				ref={forwardedRef}
			>
				<PageSection className={classes({ onBooking: hasStartedBooking })}>
					{!!business.phoneNumber && (
						<meta content={business.phoneNumber} itemProp={'telephone'} />
					)}
				</PageSection>
				<div id='services-container'>
					<Tabs.Wrapper currentId={currentTabId}>
						<div
							className={classes({
								tabContainer: !hasStartedBooking
							})}
						>
							<Tabs.List
								hidden={hasStartedBooking || !businessIsFullyLoaded}
								hideIfOnlyOneTab
								tabs={menuTabs}
								onTabChanged={onTabChanged}
							/>
						</div>
						<Tabs.Panel id={'book'}>
							{/* That stuff should probably be rendered in the parent*/}
							<div>
								{!hasStartedBooking && (
									<PageSection
										className={classes({
											titleContainer: true,
											titleLoading: !businessIsFullyLoaded,
											hasOnlyOneTab: menuTabs.length === 1
										})}
									>
										<BusinessTitle
											args={{ businessName: business.name }}
											Component={'h2'}
											path={'bookAppointment.title'}
										/>
										<div className={classes({ subtitle: true })}>
											<Localize text={subtitle()} />
										</div>
									</PageSection>
								)}
								{!hasStartedBooking &&
									!isLoadingUserQueries &&
									!!hasPastAppointments && (
										<div className={styles.mobile}>
											<PastAppointments
												business={business}
												businessId={businessId}
											/>
										</div>
									)}
								<SplitPage.Wrapper
									className={classes({
										split: true,
										displayClickWithNoPastAppointment:
											(displayClickAndCollect || displayClickAndShop) &&
											!hasPastAppointments
									})}
								>
									<div className={styles.columns}>
										<SplitPage.Main
											className={classes({
												booking: hasStartedBooking
											})}
										>
											{isLoading ? (
												<div
													className={classes({
														loadingContainer: true
													})}
												>
													<Spinner />
												</div>
											) : (
												<Fragment>
													{!hasStartedBooking &&
														!isLoadingUserQueries &&
														!!hasPastAppointments && (
															<div className={styles.tablet}>
																<PastAppointments
																	business={business}
																	businessId={businessId}
																/>
															</div>
														)}

													{hasStartedBookingGiftVoucher ? (
														<BookGiftVoucher
															business={business}
															businessId={businessId}
															giftCards={giftCards}
															onSuccess={() =>
																history.push(routes.myAccountGiftCards)
															}
														/>
													) : businessIsFullyLoaded ? (
														<BookAppointment
															business={business}
															businessId={businessId}
															conditionsLink={routes.legal}
															crumbs={crumbs}
															giftButtonText={giftVoucherOptionEnabled}
															globalSearch={globalSearch}
															hasPastAppointments={hasPastAppointments}
															highlightedServices={highlightedServices}
															initialServiceId={initialServiceId}
															isPreview={isPreview}
															key={`${pageContext}`}
															noRedirection={noRedirection}
															parentPlace={parentPlace}
															place={place}
															redirectTo={
																noRedirection || hasStartedBooking
																	? false
																	: serviceId =>
																			history.push(
																				routes.catchAll({
																					reservation: { business },
																					state: {
																						business: {
																							...business,
																							isBooking: true
																						},
																						initialServiceId: serviceId
																					}
																				})
																			)
															}
															userFilter={userFilter}
															warnOnLeave={warnOnLeave && !hasSuccessfulBooking}
															onBooking={hasStartedBooking}
															onGiftButtonClick={() => popupGiftService()}
															onServiceAdd={() => {
																onBookingStart();
																publishBookingStepEvent();
															}}
															onSuccess={onBookAppointmentSuccess}
														/>
													) : null}
												</Fragment>
											)}
										</SplitPage.Main>
										{!hasStartedBooking && !!businessIsFullyLoaded && (
											<SplitPage.Aside className={styles.aside}>
												{showRating ? (
													<div className={styles.hideMobile}>
														<BusinessReviewsCard
															data={{
																rating: business.rating,
																businessName: business.name,
																businessId,
																business
															}}
														/>
														{!!openingHours && (
															<OpeningHours
																business={business}
																openingHours={openingHours}
															/>
														)}
													</div>
												) : (
													!!openingHours && (
														<div className={styles.hideMobile}>
															<OpeningHours
																business={business}
																openingHours={openingHours}
															/>
														</div>
													)
												)}
											</SplitPage.Aside>
										)}
									</div>
								</SplitPage.Wrapper>
							</div>
						</Tabs.Panel>
					</Tabs.Wrapper>
				</div>
			</div>
		</WithHydration>
	);
}

function getBusinessType({ mainType, types }) {
	if (mainType) {
		return mainType;
	}
	if (types) {
		const [firstType] = types.split(',').filter(x => !!x);
		return firstType;
	}
	return null;
}
