import React from 'react';
import { Localize } from '@planity/components';
import styles from './title.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';

export function BookAppointmentTitle({
	children,
	index,
	isDark,
	className,
	...props
}) {
	useStyles(styles);
	const classes = classNames.bind(styles)({ title: true, isDark }, className);

	return (
		<div className={classes}>
			<span className={index && styles.index}>{index || ''}</span>
			<span>
				<Localize {...props} />
			</span>
			{children}
		</div>
	);
}
