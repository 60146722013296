// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".thumbnail-module_thumbnail-pDJWZ{display:flex;align-items:center;width:243px;padding:12px;box-shadow:var(--shadow-light);border-radius:var(--border-radius-large)}.thumbnail-module_thumbnail-pDJWZ .thumbnail-module_image-E9dRC{width:48px;height:48px;border:1px solid var(--grey-200);border-radius:var(--border-radius-medium);margin-right:12px}.thumbnail-module_thumbnail-pDJWZ .thumbnail-module_title-78\\+3l{font:var(--regular) var(--body-3)}", "",{"version":3,"sources":["webpack://./../../packages/ui/uikit/images/thumbnail/thumbnail.module.scss"],"names":[],"mappings":"AAAA,kCACC,YAAA,CACA,kBAAA,CACA,WAAA,CAEA,YAAA,CACA,8BAAA,CACA,wCAAA,CAEA,gEACC,UAAA,CACA,WAAA,CACA,gCAAA,CACA,yCAAA,CACA,iBAAA,CAGD,iEACC,iCAAA","sourcesContent":[".thumbnail {\n\tdisplay: flex;\n\talign-items: center;\n\twidth: 243px;\n\n\tpadding: 12px;\n\tbox-shadow: var(--shadow-light);\n\tborder-radius: var(--border-radius-large);\n\n\t.image {\n\t\twidth: 48px;\n\t\theight: 48px;\n\t\tborder: 1px solid var(--grey-200);\n\t\tborder-radius: var(--border-radius-medium);\n\t\tmargin-right: 12px;\n\t}\n\n\t.title {\n\t\tfont: var(--regular) var(--body-3);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thumbnail": "thumbnail-module_thumbnail-pDJWZ",
	"image": "thumbnail-module_image-E9dRC",
	"title": "thumbnail-module_title-78+3l"
};
module.exports = ___CSS_LOADER_EXPORT___;
