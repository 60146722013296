import { useLocalization, useLocalizedRoutes } from '@planity/localization';
import { isNativeApp } from '@planity/webview';
import React, { useEffect, useState } from 'react';
import { useAppBanner } from '@planity/context';
import credentials from '@planity/credentials';
import { DidomiSDK } from '@didomi/react';
import { useActiveElement } from '@planity/helpers';

/**
 * Display the cookies modal thanks to Didomi SDK
 * @see https://developers.didomi.io/
 * @see https://github.com/didomi/react
 * @return {JSX.Element}
 */
export const CookiesWarning = () => {
	const { setIsDisplayable } = useAppBanner();
	const { language } = useLocalization();
	const { routes } = useLocalizedRoutes();
	const focusedElement = useActiveElement();
	const [isNoticeShown, setIsNoticeShown] = useState(null);
	const isTrackingStatusForbidden =
		isNativeApp && ['denied', 'restricted'].includes(window.trackingStatus);

	useEffect(() => {
		if (focusedElement && isNoticeShown) {
			focusedElement.blur();
		}
	}, [focusedElement, isNoticeShown]);

	if (isNativeApp) console.log('Tracking status is ', window.trackingStatus);
	const onDidomiReady = didomi => {
		const { purposes, vendors } = didomi?.getUserConsentStatusForAll() || {};
		const hasPreviouslyAgreedToBeTracked =
			purposes?.enabled?.length || vendors?.enabled?.length;

		if (isTrackingStatusForbidden && hasPreviouslyAgreedToBeTracked) {
			didomi?.setUserDisagreeToAll();
		}
		setIsDisplayable(!didomi.shouldConsentBeCollected());
	};

	// https://developers.didomi.io/
	// https://github.com/didomi/react
	return (
		<DidomiSDK
			apiKey={credentials.DIDOMI_PUBLIC_KEY}
			config={{
				notice: {
					enable: !isTrackingStatusForbidden
				},
				app: {
					privacyPolicyURL: `https://www.${
						credentials.HOST + routes.privacyPolicy
					}`
				},
				languages: {
					enabled: [language],
					default: language
				}
			}}
			embedTCFStub={true}
			gdprAppliesGlobally={true}
			iabVersion={2}
			noticeId={credentials.DIDOMI_NOTICE_ID}
			onNoticeHidden={() => setIsNoticeShown(false)}
			onNoticeShown={() => setIsNoticeShown(true)}
			onReady={onDidomiReady}
		/>
	);
};
