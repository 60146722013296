// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inline_link-module_inlineLink-Ex2nc{background-image:linear-gradient(90deg, currentColor, currentColor);background-size:100% 1px;background-repeat:no-repeat;background-position:left 1.45em;padding-bottom:.2em;margin-bottom:-0.2em;font:var(--medium) var(--body-4);color:inherit;align-items:center}@supports not (-webkit-touch-callout: none){a.inline_link-module_inlineLink-Ex2nc{text-decoration:underline}}", "",{"version":3,"sources":["webpack://./../../packages/ui/uikit/navigation/inline_link/inline_link.module.scss"],"names":[],"mappings":"AAAA,qCACC,mEAAA,CACA,wBAAA,CACA,2BAAA,CACA,+BAAA,CACA,mBAAA,CACA,oBAAA,CACA,gCAAA,CACA,aAAA,CACA,kBAAA,CAED,4CACC,sCACC,yBAAA,CAAA","sourcesContent":[".inlineLink {\n\tbackground-image: linear-gradient(90deg, currentColor, currentColor);\n\tbackground-size: 100% 1px;\n\tbackground-repeat: no-repeat;\n\tbackground-position: left 1.45em;\n\tpadding-bottom: 0.2em;\n\tmargin-bottom: -0.2em;\n\tfont: var(--medium) var(--body-4);\n\tcolor: inherit;\n\talign-items: center;\n}\n@supports not (-webkit-touch-callout: none) {\n\ta.inlineLink {\n\t\ttext-decoration: underline;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inlineLink": "inline_link-module_inlineLink-Ex2nc"
};
module.exports = ___CSS_LOADER_EXPORT___;
