import { useLocalization, useLocalizedRoutes } from '@planity/localization';
import { isNativeApp } from '@planity/webview';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useCategories } from '../data';
import React, { useEffect, useMemo, useRef } from 'react';
import { scrollTo } from '@planity/helpers';
import { useHistory, useLocation } from 'react-router-dom';
import {
	Footer,
	Header,
	MenuOverlay,
	AppBannerModal,
	useModal
} from '@planity/ui';
import { Breadcrumbs } from './breadcrumbs';
import { ErrorMessages } from '@planity/components';
import styles from './layout.module.scss';
import { MenuSearch } from './menu_search';
import { useAppBanner } from '@planity/context';

export const Layout = ({
	showAppTeaser,
	skipScrollHandling,
	children,
	hideBreadcrumbs,
	breadcrumbsAtTop,
	crumbs,
	crumbName,
	hideFooter,
	hideHeader,
	id,
	hasBackgroundColor,
	isDark,
	headerIsCollapsible,
	className,
	hideNavigationItems,
	centerNavItems,
	withHeaderMarginTop,
	isHomePage,
	isHiddenOnTablet,
	isHiddenOnMobile,
	hasBreadcrumbsSeparator,
	hasFullWidth,
	headerIsFullWidth,
	stickyOnDesktop = true,
	showBottomSearchItems
}) => {
	const { appBannerIsVisible, dismissAppBanner } = useAppBanner();
	const location = useLocation();
	const { routes } = useLocalizedRoutes();
	const history = useHistory();
	const { localizedBasePath, countryCode } = useLocalization();
	const { categories } = useCategories();
	const navItems = useMemo(
		() =>
			categories
				?.filter(category => filterCategory(category, countryCode))
				?.map(category => formatNavItem(category, localizedBasePath)),
		[localizedBasePath, categories]
	);
	const { setModal } = useModal();

	const classes = classNames.bind(styles);
	useStyles(styles);

	const topNodeRef = useRef(null);

	useEffect(() => {
		if (process.env.BROWSER && history?.action !== 'POP') {
			scrollTo({ top: 0 });
		}
	}, []);
	useEffect(() => {
		if (process.env.BROWSER) {
			if (history.action !== 'POP' && !skipScrollHandling)
				// Meh
				scrollTo({ top: 0 });
		}
	}, [location.pathname, history.action, skipScrollHandling]);

	useEffect(() => {
		const isValidRouteModal = [routes.home, routes.myAccount].includes(
			location.pathname
		);
		if (showAppTeaser && appBannerIsVisible && isValidRouteModal) {
			setModal({
				content: <AppBannerModal />,
				hasCloseBtn: false,
				onCloseModal: dismissAppBanner,
				hasBorderRadius: true,
				order: 1
			});
		}
	}, [showAppTeaser, appBannerIsVisible]);
	return (
		<div
			className={classes({
				layout: true,
				[className]: className,
				hasBackgroundColor,
				withHeaderMarginTop
			})}
			id={id}
			ref={topNodeRef}
		>
			<MenuOverlay navItems={navItems} />
			<div className={styles.errorMessages}>
				<ErrorMessages />
			</div>
			<div className={styles.headerWrapper}>
				{!hideHeader && (
					<Header
						center={centerNavItems}
						hideNavigationItems={hideNavigationItems}
						isCollapsible={headerIsCollapsible}
						isFullWidth={headerIsFullWidth}
						isInverted={isHomePage}
						isTransparent={headerIsCollapsible}
						navItems={navItems}
						stickyOnDesktop={stickyOnDesktop}
					/>
				)}
			</div>
			<div className={styles.contentWrapper}>
				{!hideBreadcrumbs && !!breadcrumbsAtTop && (
					<Breadcrumbs
						className={classes({
							topBreadcrumbs: true,
							hasFullWidth
						})}
						crumbName={crumbName}
						crumbs={crumbs}
						hasBackgroundColor={hasBackgroundColor}
						isHiddenOnMobile={isHiddenOnMobile}
						isHiddenOnTablet={isHiddenOnTablet}
					/>
				)}
				<main
					className={classes({
						main: true,
						isNativeApp,
						isHomePage
					})}
				>
					{children}
				</main>
				{!hideBreadcrumbs && !breadcrumbsAtTop && !isNativeApp && (
					<div>
						<Breadcrumbs
							className={classes({
								bottomBreadcrumbs: true,
								hasBreadcrumbsSeparator: hasBreadcrumbsSeparator
							})}
							crumbName={crumbName}
							crumbs={crumbs}
							hasBackgroundColor={hasBackgroundColor}
							isHiddenOnMobile={isHiddenOnMobile}
							isHiddenOnTablet={isHiddenOnTablet}
						/>
					</div>
				)}

				{showBottomSearchItems && <MenuSearch navItems={navItems} />}

				{!hideFooter && !isNativeApp && (
					<Footer
						hasBreadcrumbsSeparator={hasBreadcrumbsSeparator}
						isDark={isDark}
						topNode={topNodeRef}
					/>
				)}
			</div>
		</div>
	);
};

function formatNavItem(category, basePath) {
	return {
		link: `${basePath}/${category.slug}`,
		id: category.objectID,
		label: category.name,
		isActive: false
	};
}

function filterCategory(category, countryCode) {
	/**
	 show only well_being feature if process.env.WELL_BEING is true
	 */
	const { isBusinessCategory, parentIsBusinessCategory, objectID, landing } =
		category;

	const shouldFilterOut =
		(isBusinessCategory || parentIsBusinessCategory) && !process.env.WELL_BEING;
	return !shouldFilterOut && objectID !== 'spa' && !!landing;
}
