export const ChromeSvg = () => {
	return (
		<svg
			fill='none'
			width='28'
			height='28'
			viewBox='0 0 28 28'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_7606_2998)'>
				<mask
					id='mask0_7606_2998'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='28'
					height='28'
				>
					<path
						d='M14 27.9825C21.7223 27.9825 27.9825 21.7223 27.9825 14C27.9825 6.27767 21.7223 0.0174789 14 0.0174789C6.27767 0.0174789 0.0174866 6.27767 0.0174866 14C0.0174866 21.7223 6.27767 27.9825 14 27.9825Z'
						fill='white'
					/>
				</mask>
				<g mask='url(#mask0_7606_2998)'>
					<path
						d='M2.23721 0.0174789V17.1778H8.49598L14 7.64431H27.9825V0.0174789H2.23721Z'
						fill='#DB4437'
					/>
					<path
						d='M2.23721 0.0174789V17.1778H8.49598L14 7.64431H27.9825V0.0174789H2.23721Z'
						fill='url(#paint0_linear_7606_2998)'
					/>
				</g>
				<mask
					id='mask1_7606_2998'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='28'
					height='28'
				>
					<path
						d='M14 27.9825C21.7223 27.9825 27.9825 21.7223 27.9825 14C27.9825 6.27767 21.7223 0.0174789 14 0.0174789C6.27767 0.0174789 0.0174866 6.27767 0.0174866 14C0.0174866 21.7223 6.27767 27.9825 14 27.9825Z'
						fill='white'
					/>
				</mask>
				<g mask='url(#mask1_7606_2998)'>
					<path
						d='M8.64691 17.1222L2.31823 6.2683L2.22607 6.4272L8.50868 17.2001L8.64691 17.1222Z'
						fill='#3E2723'
						fillOpacity='0.15'
					/>
				</g>
				<mask
					id='mask2_7606_2998'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='28'
					height='28'
				>
					<path
						d='M14 27.9825C21.7223 27.9825 27.9825 21.7223 27.9825 14C27.9825 6.27767 21.7223 0.0174789 14 0.0174789C6.27767 0.0174789 0.0174866 6.27767 0.0174866 14C0.0174866 21.7223 6.27767 27.9825 14 27.9825Z'
						fill='white'
					/>
				</mask>
				<g mask='url(#mask2_7606_2998)'>
					<path
						d='M0.0174866 27.9825H13.3279L19.5056 21.8048V17.1778H8.49598L0.0174866 2.63602V27.9825Z'
						fill='#0F9D58'
					/>
					<path
						d='M0.0174866 27.9825H13.3279L19.5056 21.8048V17.1778H8.49598L0.0174866 2.63602V27.9825Z'
						fill='url(#paint1_linear_7606_2998)'
					/>
				</g>
				<mask
					id='mask3_7606_2998'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='28'
					height='28'
				>
					<path
						d='M14 27.9825C21.7223 27.9825 27.9825 21.7223 27.9825 14C27.9825 6.27767 21.7223 0.0174789 14 0.0174789C6.27767 0.0174789 0.0174866 6.27767 0.0174866 14C0.0174866 21.7223 6.27767 27.9825 14 27.9825Z'
						fill='white'
					/>
				</mask>
				<g mask='url(#mask3_7606_2998)'>
					<path
						d='M19.3769 17.3892L19.245 17.3129L13.1452 27.9825H13.3279L19.3817 17.3939L19.3769 17.3892Z'
						fill='#263238'
						fillOpacity='0.15'
					/>
				</g>
				<mask
					id='mask4_7606_2998'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='28'
					height='28'
				>
					<path
						d='M14 27.9825C21.7223 27.9825 27.9825 21.7223 27.9825 14C27.9825 6.27767 21.7223 0.0174789 14 0.0174789C6.27767 0.0174789 0.0174866 6.27767 0.0174866 14C0.0174866 21.7223 6.27767 27.9825 14 27.9825Z'
						fill='white'
					/>
				</mask>
				<g mask='url(#mask4_7606_2998)'>
					<mask
						id='mask5_7606_2998'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='2'
						width='20'
						height='26'
					>
						<path
							d='M0.0174866 27.9825H13.3279L19.5056 21.8048V17.1778H8.49598L0.0174866 2.63602V27.9825Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask5_7606_2998)'>
						<path
							d='M14 7.64431L19.5056 17.1778L13.3279 27.9825H27.9825V7.64431H14Z'
							fill='#FFCD40'
						/>
						<path
							d='M14 7.64431L19.5056 17.1778L13.3279 27.9825H27.9825V7.64431H14Z'
							fill='url(#paint2_linear_7606_2998)'
						/>
					</g>
				</g>
				<mask
					id='mask6_7606_2998'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='28'
					height='28'
				>
					<path
						d='M14 27.9825C21.7223 27.9825 27.9825 21.7223 27.9825 14C27.9825 6.27767 21.7223 0.0174789 14 0.0174789C6.27767 0.0174789 0.0174866 6.27767 0.0174866 14C0.0174866 21.7223 6.27767 27.9825 14 27.9825Z'
						fill='white'
					/>
				</mask>
				<g mask='url(#mask6_7606_2998)'>
					<path
						d='M14 7.64431L19.5056 17.1778L13.3279 27.9825H27.9825V7.64431H14Z'
						fill='#FFCD40'
					/>
					<path
						d='M14 7.64431L19.5056 17.1778L13.3279 27.9825H27.9825V7.64431H14Z'
						fill='url(#paint3_linear_7606_2998)'
					/>
				</g>
				<mask
					id='mask7_7606_2998'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='28'
					height='28'
				>
					<path
						d='M14 27.9825C21.7223 27.9825 27.9825 21.7223 27.9825 14C27.9825 6.27767 21.7223 0.0174789 14 0.0174789C6.27767 0.0174789 0.0174866 6.27767 0.0174866 14C0.0174866 21.7223 6.27767 27.9825 14 27.9825Z'
						fill='white'
					/>
				</mask>
				<g mask='url(#mask7_7606_2998)'>
					<mask
						id='mask8_7606_2998'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='13'
						y='7'
						width='15'
						height='21'
					>
						<path
							d='M14 7.64431L19.5056 17.1778L13.3279 27.9825H27.9825V7.64431H14Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask8_7606_2998)'>
						<path
							d='M2.23721 0.0174789V17.1778H8.49598L14 7.64431H27.9825V0.0174789H2.23721Z'
							fill='#DB4437'
						/>
						<path
							d='M2.23721 0.0174789V17.1778H8.49598L14 7.64431H27.9825V0.0174789H2.23721Z'
							fill='url(#paint4_linear_7606_2998)'
						/>
					</g>
				</g>
				<mask
					id='mask9_7606_2998'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='28'
					height='28'
				>
					<path
						d='M14 27.9825C21.7223 27.9825 27.9825 21.7223 27.9825 14C27.9825 6.27767 21.7223 0.0174789 14 0.0174789C6.27767 0.0174789 0.0174866 6.27767 0.0174866 14C0.0174866 21.7223 6.27767 27.9825 14 27.9825Z'
						fill='white'
					/>
				</mask>
				<g mask='url(#mask9_7606_2998)'>
					<path
						d='M14 7.64431V10.9731L26.4572 7.64431H14Z'
						fill='url(#paint5_radial_7606_2998)'
					/>
				</g>
				<mask
					id='mask10_7606_2998'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='28'
					height='28'
				>
					<path
						d='M14 27.9825C21.7223 27.9825 27.9825 21.7223 27.9825 14C27.9825 6.27767 21.7223 0.0174789 14 0.0174789C6.27767 0.0174789 0.0174866 6.27767 0.0174866 14C0.0174866 21.7223 6.27767 27.9825 14 27.9825Z'
						fill='white'
					/>
				</mask>
				<g mask='url(#mask10_7606_2998)'>
					<mask
						id='mask11_7606_2998'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='2'
						y='0'
						width='26'
						height='18'
					>
						<path
							d='M2.23721 0.0174789V6.42719L8.49598 17.1778L14 7.64431H27.9825V0.0174789H2.23721Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask11_7606_2998)'>
						<path
							d='M0.0174866 27.9825H13.3279L19.5056 21.8048V17.1778H8.49598L0.0174866 2.63602V27.9825Z'
							fill='#0F9D58'
						/>
						<path
							d='M0.0174866 27.9825H13.3279L19.5056 21.8048V17.1778H8.49598L0.0174866 2.63602V27.9825Z'
							fill='url(#paint6_linear_7606_2998)'
						/>
					</g>
				</g>
				<mask
					id='mask12_7606_2998'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='28'
					height='28'
				>
					<path
						d='M14 27.9825C21.7223 27.9825 27.9825 21.7223 27.9825 14C27.9825 6.27767 21.7223 0.0174789 14 0.0174789C6.27767 0.0174789 0.0174866 6.27767 0.0174866 14C0.0174866 21.7223 6.27767 27.9825 14 27.9825Z'
						fill='white'
					/>
				</mask>
				<g mask='url(#mask12_7606_2998)'>
					<path
						d='M2.23721 6.44467L11.3338 15.5397L8.49598 17.1778L2.23721 6.44467Z'
						fill='url(#paint7_radial_7606_2998)'
					/>
				</g>
				<mask
					id='mask13_7606_2998'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='28'
					height='28'
				>
					<path
						d='M14 27.9825C21.7223 27.9825 27.9825 21.7223 27.9825 14C27.9825 6.27767 21.7223 0.0174789 14 0.0174789C6.27767 0.0174789 0.0174866 6.27767 0.0174866 14C0.0174866 21.7223 6.27767 27.9825 14 27.9825Z'
						fill='white'
					/>
				</mask>
				<g mask='url(#mask13_7606_2998)'>
					<path
						d='M13.3374 27.965L16.6678 15.5397L19.5056 17.1778L13.3374 27.965Z'
						fill='url(#paint8_radial_7606_2998)'
					/>
				</g>
				<mask
					id='mask14_7606_2998'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='28'
					height='28'
				>
					<path
						d='M14 27.9825C21.7223 27.9825 27.9825 21.7223 27.9825 14C27.9825 6.27767 21.7223 0.0174789 14 0.0174789C6.27767 0.0174789 0.0174866 6.27767 0.0174866 14C0.0174866 21.7223 6.27767 27.9825 14 27.9825Z'
						fill='white'
					/>
				</mask>
				<g mask='url(#mask14_7606_2998)'>
					<path
						d='M14 20.3557C17.5102 20.3557 20.3557 17.5102 20.3557 14C20.3557 10.4898 17.5102 7.64431 14 7.64431C10.4899 7.64431 7.64432 10.4898 7.64432 14C7.64432 17.5102 10.4899 20.3557 14 20.3557Z'
						fill='#F1F1F1'
					/>
					<path
						d='M14 19.0846C16.8081 19.0846 19.0846 16.8081 19.0846 14C19.0846 11.1919 16.8081 8.91545 14 8.91545C11.1919 8.91545 8.91547 11.1919 8.91547 14C8.91547 16.8081 11.1919 19.0846 14 19.0846Z'
						fill='#4285F4'
					/>
				</g>
				<mask
					id='mask15_7606_2998'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='28'
					height='28'
				>
					<path
						d='M14 27.9825C21.7223 27.9825 27.9825 21.7223 27.9825 14C27.9825 6.27767 21.7223 0.0174789 14 0.0174789C6.27767 0.0174789 0.0174866 6.27767 0.0174866 14C0.0174866 21.7223 6.27767 27.9825 14 27.9825Z'
						fill='white'
					/>
				</mask>
				<g mask='url(#mask15_7606_2998)'>
					<path
						d='M14 7.48542C10.4901 7.48542 7.64432 10.3312 7.64432 13.8411V14C7.64432 10.4901 10.4901 7.64431 14 7.64431H27.9825V7.48542H14Z'
						fill='#3E2723'
						fillOpacity='0.2'
					/>
					<path
						d='M19.4977 17.1778C18.3981 19.075 16.35 20.3557 14 20.3557C11.6484 20.3557 9.60028 19.075 8.50075 17.1778H8.49439L0.0174866 2.63602V2.79492L8.49598 17.3367H8.50234C9.60187 19.2339 11.65 20.5146 14.0016 20.5146C16.3516 20.5146 18.3997 19.2355 19.4993 17.3367H19.5072V17.1778H19.4977Z'
						fill='white'
						fillOpacity='0.1'
					/>
					<path
						opacity='0.1'
						d='M14.1589 7.64431C14.1319 7.64431 14.1065 7.64749 14.0795 7.64908C17.5529 7.69198 20.3557 10.5171 20.3557 14C20.3557 17.4829 17.5529 20.308 14.0795 20.3509C14.1065 20.3509 14.1319 20.3557 14.1589 20.3557C17.6688 20.3557 20.5146 17.5099 20.5146 14C20.5146 10.4901 17.6688 7.64431 14.1589 7.64431Z'
						fill='#3E2723'
					/>
					<path
						d='M19.5612 17.3892C20.1015 16.4549 20.4145 15.3744 20.4145 14.2177C20.4145 13.5472 20.3096 12.9021 20.1173 12.2967C20.2683 12.8401 20.3557 13.4105 20.3557 14.0016C20.3557 15.1583 20.0427 16.2388 19.5024 17.1731L19.5056 17.1794L13.3279 27.9841H13.5122L19.5644 17.3955L19.5612 17.3892Z'
						fill='white'
						fillOpacity='0.2'
					/>
				</g>
				<mask
					id='mask16_7606_2998'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='28'
					height='28'
				>
					<path
						d='M14 27.9825C21.7223 27.9825 27.9825 21.7223 27.9825 14C27.9825 6.27767 21.7223 0.0174789 14 0.0174789C6.27767 0.0174789 0.0174866 6.27767 0.0174866 14C0.0174866 21.7223 6.27767 27.9825 14 27.9825Z'
						fill='white'
					/>
				</mask>
				<g mask='url(#mask16_7606_2998)'>
					<path
						d='M14 0.176371C21.6952 0.176371 27.938 6.39383 27.9809 14.0794C27.9809 14.0524 27.9825 14.027 27.9825 14C27.9825 6.27784 21.7222 0.0174789 14 0.0174789C6.27784 0.0174789 0.0174866 6.27784 0.0174866 14C0.0174866 14.027 0.0190755 14.0524 0.0190755 14.0794C0.0619765 6.39383 6.30485 0.176371 14 0.176371Z'
						fill='white'
						fillOpacity='0.2'
					/>
					<path
						d='M14 27.8236C21.6952 27.8236 27.938 21.6062 27.9809 13.9206C27.9809 13.9476 27.9825 13.973 27.9825 14C27.9825 21.7222 21.7222 27.9825 14 27.9825C6.27784 27.9825 0.0174866 21.7222 0.0174866 14C0.0174866 13.973 0.0190755 13.9476 0.0190755 13.9206C0.0619765 21.6062 6.30485 27.8236 14 27.8236Z'
						fill='#3E2723'
						fillOpacity='0.15'
					/>
				</g>
				<path
					d='M14 27.9825C21.7223 27.9825 27.9825 21.7223 27.9825 14C27.9825 6.27767 21.7223 0.0174789 14 0.0174789C6.27767 0.0174789 0.0174866 6.27767 0.0174866 14C0.0174866 21.7223 6.27767 27.9825 14 27.9825Z'
					fill='url(#paint9_radial_7606_2998)'
				/>
			</g>
			<defs>
				<linearGradient
					id='paint0_linear_7606_2998'
					x1='3.40777'
					y1='10.6666'
					x2='11.7496'
					y2='5.79385'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#A52714' stopOpacity='0.6' />
					<stop offset='0.66' stopColor='#A52714' stopOpacity='0' />
				</linearGradient>
				<linearGradient
					id='paint1_linear_7606_2998'
					x1='16.3627'
					y1='24.8841'
					x2='7.09423'
					y2='19.4548'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#055524' stopOpacity='0.4' />
					<stop offset='0.33' stopColor='#055524' stopOpacity='0' />
				</linearGradient>
				<linearGradient
					id='paint2_linear_7606_2998'
					x1='18.109'
					y1='6.65981'
					x2='20.4431'
					y2='16.8807'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#EA6100' stopOpacity='0.3' />
					<stop offset='0.66' stopColor='#EA6100' stopOpacity='0' />
				</linearGradient>
				<linearGradient
					id='paint3_linear_7606_2998'
					x1='18.109'
					y1='6.65981'
					x2='20.4431'
					y2='16.8807'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#EA6100' stopOpacity='0.3' />
					<stop offset='0.66' stopColor='#EA6100' stopOpacity='0' />
				</linearGradient>
				<linearGradient
					id='paint4_linear_7606_2998'
					x1='3.40777'
					y1='10.6666'
					x2='11.7496'
					y2='5.79385'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#A52714' stopOpacity='0.6' />
					<stop offset='0.66' stopColor='#A52714' stopOpacity='0' />
				</linearGradient>
				<radialGradient
					id='paint5_radial_7606_2998'
					cx='0'
					cy='0'
					r='1'
					gradientUnits='userSpaceOnUse'
					gradientTransform='translate(13.393 7.63605) scale(13.3593)'
				>
					<stop stopColor='#3E2723' stopOpacity='0.2' />
					<stop offset='1' stopColor='#3E2723' stopOpacity='0' />
				</radialGradient>
				<linearGradient
					id='paint6_linear_7606_2998'
					x1='16.3627'
					y1='24.8841'
					x2='7.09423'
					y2='19.4548'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#055524' stopOpacity='0.4' />
					<stop offset='0.33' stopColor='#055524' stopOpacity='0' />
				</linearGradient>
				<radialGradient
					id='paint7_radial_7606_2998'
					cx='0'
					cy='0'
					r='1'
					gradientUnits='userSpaceOnUse'
					gradientTransform='translate(2.22291 6.45579) scale(12.4006)'
				>
					<stop stopColor='#3E2723' stopOpacity='0.2' />
					<stop offset='1' stopColor='#3E2723' stopOpacity='0' />
				</radialGradient>
				<radialGradient
					id='paint8_radial_7606_2998'
					cx='0'
					cy='0'
					r='1'
					gradientUnits='userSpaceOnUse'
					gradientTransform='translate(13.9746 14.0219) scale(13.9619 13.9619)'
				>
					<stop stopColor='#263238' stopOpacity='0.2' />
					<stop offset='1' stopColor='#263238' stopOpacity='0' />
				</radialGradient>
				<radialGradient
					id='paint9_radial_7606_2998'
					cx='0'
					cy='0'
					r='1'
					gradientUnits='userSpaceOnUse'
					gradientTransform='translate(4.19413 3.83312) scale(28.0842)'
				>
					<stop stopColor='white' stopOpacity='0.1' />
					<stop offset='1' stopColor='white' stopOpacity='0' />
				</radialGradient>
				<clipPath id='clip0_7606_2998'>
					<rect width='28' height='28' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
