import React from 'react';
import styles from './search_item.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Avatar } from '@planity/ui';

export const SearchItem = ({ item, onSelect, className }) => {
	useStyles(styles);
	const classes = classNames.bind(styles);

	return (
		<div
			className={classes({ searchItem: true }, className)}
			onClick={() => onSelect(item)}
		>
			{item.avatar && (
				<div>
					<Avatar imageUrl={item.picture} {...item.avatar} />
				</div>
			)}

			<div className={styles.description}>
				<span
					className={classes({ mainText: true })}
					dangerouslySetInnerHTML={{ __html: item.dangerousLabel }}
				>
					{!item.dangerousLabel ? item.name : null}
				</span>
				<span className={classes({ secondaryText: true })}>
					{item.description ? item.description : null}
				</span>
			</div>
		</div>
	);
};
