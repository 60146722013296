import React from 'react';
import {
	Button,
	EmailInput,
	Form,
	Inputs,
	Link,
	TextInput
} from '@planity/components';
import {
	EMAIL_CHANGE,
	FIRST_NAME_CHANGE,
	LAST_NAME_CHANGE,
	RESET_MACHINE,
	USER_DATA_EDITION_SUBMIT
} from '../../auth_actions';
import { Localize } from '@planity/components';
import { useFormHooks } from '../useFormHooks';

export const LoginWithAskingMissingData = () => {
	const { state, dispatch, formValues } = useFormHooks();
	const { pending, children, emailHasNotBeenShared } = formValues;
	return (
		<Form css={css.container}>
			{children}
			<p>
				<Localize text={'auth.texts.fillMissingData'} />
			</p>
			{emailHasNotBeenShared && (
				<Inputs css={css.inputs}>
					<EmailInput
						localizedPlaceholder={'auth.form.placeholders.email'}
						value={state?.context?.email || ''}
						onChange={emailInput =>
							dispatch({
								type: EMAIL_CHANGE,
								email: emailInput
							})
						}
					/>
				</Inputs>
			)}
			<Inputs css={css.inputs}>
				<TextInput
					localizedPlaceholder={'auth.form.placeholders.firstName'}
					value={state?.context?.firstName || ''}
					onChange={firstNameInput =>
						dispatch({
							type: FIRST_NAME_CHANGE,
							firstName: firstNameInput
						})
					}
				/>
			</Inputs>
			<Inputs css={css.inputs}>
				<TextInput
					localizedPlaceholder={'auth.form.placeholders.lastName'}
					value={state?.context?.lastName || ''}
					onChange={lastNameInput =>
						dispatch({
							type: LAST_NAME_CHANGE,
							lastName: lastNameInput
						})
					}
				/>
			</Inputs>

			<div>
				<Button
					css={css.button}
					isActive={pending}
					localizedText={
						pending
							? 'auth.form.buttons.pending'
							: 'auth.form.buttons.acceptAndContinue'
					}
					onPress={() => dispatch({ type: USER_DATA_EDITION_SUBMIT })}
				/>
			</div>
			<div css={css.bottomLink}>
				<Link
					localizedText={`auth.form.links.BACK_TO_SIGN_IN`}
					onPress={() => dispatch({ type: RESET_MACHINE })}
				/>
			</div>
		</Form>
	);
};
const css = {
	inputs: {
		flex: 1
	},
	button: {
		marginBottom: 18,
		width: '100%'
	},
	bottomLink: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'center',
		fontSize: 16,
		lineHeight: 1.13
	}
};
