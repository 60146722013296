import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import { formatPhoneNumber } from '@planity/helpers';
import { Button, useModal, Badge } from '@planity/ui';
import { useTranslation } from '@planity/localization';
import { SetChildModal } from '../../../children';
import styles from './selectable_user.module.scss';

export const SelectableUser = props => {
	useStyles(styles);

	const { setModal } = useModal();

	const { t } = useTranslation();
	const {
		displayBadge,
		editParent,
		isParent,
		isPending,
		isVerified,
		setSelectedUser,
		userChildren,
		user,
		handleChildUpdating,
		parent
	} = props;
	const classes = classNames.bind(styles)({
		selectableUser: true
	});

	const formatUserProfile = user => {
		const phoneNumber = user.phone && formatPhoneNumber(user.phone);

		return (
			<div className={styles.info}>
				<div className={styles.nameSection}>
					<span className={styles.name}>{user.name}</span>
					{isParent && displayBadge && (
						<span className={styles.badge}>
							<Badge
								id={`my-profile`}
								label={t(`bookAppointment.children.me`)}
							/>
						</span>
					)}
				</div>
				{user.email && (
					<span className={styles.email} id='selectable-user-email'>
						{user.email}
					</span>
				)}
				{phoneNumber && (
					<span className={styles.phone} id='selectable-user-phone'>
						{phoneNumber}
					</span>
				)}
				{isParent && !isVerified && (
					<span>{` ${t('bookAppointment.userProfile.notVerified')}`}</span>
				)}
			</div>
		);
	};

	return (
		<div className={classes}>
			{
				<div className={styles.description}>
					{formatUserProfile(user)}
					<div className={styles.actionButtons}>
						{!user.hasPlanityAccount && (
							<Button
								className={styles.modifyButton}
								id='selectable-user-edit-button'
								isLoading={isPending}
								label={t('common.edit')}
								type={'linked'}
								onClick={() => {
									if (isParent) {
										editParent();
									} else {
										setModal({
											className: 'isChildModal',
											content: (
												<SetChildModal
													initialChild={user}
													parent={parent}
													userChildren={userChildren}
													onConfirmClick={handleChildUpdating}
												/>
											),
											isFullHeight: true
										});
									}
								}}
							/>
						)}
						{
							<Button
								id='selectable-user-select-button'
								isLoading={isPending}
								label={t('bookAppointment.children.choose')}
								size='small'
								type={'primary'}
								onClick={() => {
									setSelectedUser(user);
								}}
							/>
						}
					</div>
				</div>
			}
		</div>
	);
};
