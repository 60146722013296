// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".my_account_page-module_myAccountPage-a1z6B .my_account_page-module_breadcrumbsContainer-IZaEU{width:100%;max-width:calc(1176px + 2*var(--gutter));padding:32px var(--gutter) 24px}", "",{"version":3,"sources":["webpack://./app/components/my_account_page/my_account_page.module.scss"],"names":[],"mappings":"AACC,+FACC,UAAA,CACA,wCAAA,CACA,+BAAA","sourcesContent":[".myAccountPage {\n\t.breadcrumbsContainer {\n\t\twidth: 100%;\n\t\tmax-width: calc(1176px + 2 * var(--gutter));\n\t\tpadding: 32px var(--gutter) 24px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myAccountPage": "my_account_page-module_myAccountPage-a1z6B",
	"breadcrumbsContainer": "my_account_page-module_breadcrumbsContainer-IZaEU"
};
module.exports = ___CSS_LOADER_EXPORT___;
