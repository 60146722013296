import React, { useContext } from 'react';
import { FORGOTTEN_PASSWORD, BACK_TO_SIGN_IN, SIGN_UP } from '../form';
import { ContextForm } from '../context_form';
import { useTranslation } from '@planity/localization';
import { useActor } from '@xstate/react';
import { Authentication } from '@planity/ui';
import styles from '../authentication.module.scss';

export const ForgottenPasswordForm = () => {
	const formValues = useContext(ContextForm);
	const { machineService, navigateLogState, pending } = formValues;
	const [state] = useActor(machineService);

	const { t } = useTranslation();
	const localizedForgetPasswordText = {
		title: t(`auth.form.texts.forgetPassword`),
		labels: {
			email: t('auth.form.labels.email'),
			button: t(`auth.form.buttons.${FORGOTTEN_PASSWORD}`),
			login: t(`auth.form.links.${BACK_TO_SIGN_IN}`)
		},
		placeholders: {
			email: t(`auth.form.placeholders.email`)
		},
		memberConnection: {
			title: t(`${process.env.WIDGET ? 'widget.' : ''}auth.form.buttons.new`),
			label: t(`auth.form.buttons.${SIGN_UP}`)
		}
	};
	return (
		<div className={styles.forget} id='forget-password-form'>
			<Authentication
				data={state?.context}
				localizedText={localizedForgetPasswordText}
				logState={'reset'}
				navigateLogState={navigateLogState}
				pending={pending}
			/>
		</div>
	);
};
