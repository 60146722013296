import React from 'react';
import styles from './menu_search.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Link } from 'react-router-dom';

export const MenuSearch = ({ navItems }) => {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		menuSearch: true
	});

	return (
		<div className={classes}>
			<div className={styles.items}>
				{navItems?.map((item, i) => (
					<Link
						aria-current={i === item.isActive}
						className={styles.item}
						key={item.label}
						to={item.link}
					>
						{item.label}
					</Link>
				))}
			</div>
		</div>
	);
};
