import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './icon.module.scss';
import * as library from '@planity/ui/assets/icons';
import classNames from 'classnames/bind';
import { noop } from '@planity/helpers';

/**
 *
 * @param {string} icon Icon name (choose from Store, ChevronLeft, ChevronRight...).
 * @param {string} size {string} Icon size , in px. eg: '24', '28', '32' and so on. Should be a multiple of 4, between 4 and 128. (usage of `large`, `medium`... is now deprecated)
 * @param {string} className - Additional CSS class for styling.
 * @param {string} outlined {boolean} If true, the icon will be displayed with an outline.
 * @param {function} onClick {function} Function to be executed when the icon is clicked.
 * @param {string} id {string} SVG icon id
 * @param {boolean} disabled {boolean} If true, the icon will be disabled.
 * @param {string} strokeWidth {string} SVG icon stroke width.
 * @param {string} stroke {string} SVG icon stroke color.
 * @param {boolean} isClickable {boolean} Indicates whether the icon is clickable or not.
 * @param {Object} svgProps props that are spread to the actual SVG
 * @returns {React.ReactElement}
 */
export function Icon({
	icon = 'none',
	size = '24',
	className = undefined,
	outlined,
	onClick = noop,
	id,
	disabled = false,
	strokeWidth,
	stroke,
	isClickable,
	...svgProps
}) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		icon: true,
		[className]: className !== undefined,
		// @deprecated
		isLarge: size === 'large',
		// @deprecated
		isMedium: size === 'medium',
		// @deprecated
		isSmall: size === 'small',
		isClickable,
		[`icon${size}`]: !!size
	});

	const RenderedIcon = library[icon];
	if (process.env.NODE_ENV === 'development') {
		if (!Icon) {
			console.warn('Icon type not known : ' + icon);
		}
	}
	if (!RenderedIcon) {
		return null;
	}
	return (
		<span className={classes} disabled={disabled} onClick={onClick} id={id}>
			<RenderedIcon
				outlined={outlined}
				strokeWidth={strokeWidth}
				stroke={stroke}
				{...svgProps}
			/>
		</span>
	);
}
