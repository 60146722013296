import { useTranslation } from '@planity/localization';
import React from 'react';
import { Localize } from '@planity/components';
import {
	formatDuration,
	formatPrices,
	getFixedPrice,
	noop,
	sanitizeName
} from '@planity/helpers';
import classNames from 'classnames/bind';
import styles from './service_item.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Button } from '@planity/ui';

export default function ServiceItem({
	service,
	business,
	onRemove,
	onSelect = noop,
	isShort,
	hideChose,
	index,
	onBooking
}) {
	const { t } = useTranslation();
	const duration = formatDuration(service.duration, t);
	const price = isShort
		? formatFixedPrice(service.prices, null)
		: formatPrice(service.prices, null);

	useStyles(styles);

	const classes = classNames.bind(styles)({
		bookingCart: true,
		onBooking
	});

	return (
		<div
			className={classes}
			itemProp={'itemListElement'}
			itemScope
			itemType={'https://schema.org/Offer'}
			onClick={() => onSelect(service)}
		>
			<div className={styles.card}>
				<div className={styles.voucher}>
					<div className={styles.info}>
						<span
							className={`${styles.name} planity-primary-color`}
							itemProp={'name'}
						>
							{sanitizeName(service.name)}
						</span>
						{service.parentCategory && <span>{service.parentCategory}</span>}

						<span>
							{!!duration && !business.hideServiceDurations && (
								<span
									className={`${styles.duration} planity-secondary-color planity_appointment_service_small-cell`}
								>
									{duration}
								</span>
							)}
							{!!price && !business.hideServicePrices && (
								<span
									className={`${styles.price} planity-secondary-color planity_appointment_service_small-cell`}
								>
									{price}
								</span>
							)}
						</span>
					</div>
					<div>
						{!hideChose && (
							<Button
								className={
									'planity-gift-voucher-button-choose planity-secondary-color planity-secondary-border'
								}
								label={t('gift.choose')}
								size='small'
							/>
						)}
						{!!onRemove && (
							<Button
								className={
									'planity_ui_action_button_root planity_ui_action_button_icon-remove'
								}
								label={t('common.edit')}
								type='underlined'
								onClick={() => {
									onRemove({
										index,
										price: getFixedPrice(service.prices)
									});
								}}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

function formatPrice(...args) {
	const prices = formatPrices(...args);
	if (prices) {
		if (!prices.find(x => !!x.price)) return null;
		return (
			<div css={[styles.priceTextContainer]}>
				{prices.map((price, i) => (
					<span
						css={[
							styles.duration,
							price.text && styles.priceFrom,
							price.price && styles.priceContent
						]}
						key={i}
					>
						{price.text ? <Localize text={price.text} /> : price.price}
					</span>
				))}
			</div>
		);
	} else {
		return null;
	}
}

function formatFixedPrice(...args) {
	const prices = formatPrices(...args);
	if (prices) {
		if (!prices.find(x => !!x.price)) return null;
		return (
			<span>
				{prices.map((price, i) => (
					<span key={i}>
						{price.text ? <Localize text={price.text} /> : price.price}
					</span>
				))}
			</span>
		);
	} else {
		return null;
	}
}
