import { MIN_DURATION_SPINNER_DISPLAY } from '@planity/helpers';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './search_bar_header.module.scss';
import {
	Button,
	Container,
	SearchBar,
	TagBar,
	SearchFilters,
	SearchTags,
	useSearchFilter,
	Spinner
} from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';
import { useFilters } from 'planity-website/app/components/ui/filter/context';

export function SearchBarHeader({
	isMap,
	isLoading,
	toggleMap,
	handleIsExpanded,
	showNoResults,
	businessesAreAllDirectories
}) {
	useStyles(styles);
	const { t } = useTranslation();

	const classes = classNames.bind(styles);
	const { handleFilterClick } = useSearchFilter();
	const { filters: userFilter } = useFilters();
	const filterCount =
		(!!userFilter.byDate ? 1 : 0) + (!!userFilter.sort ? 1 : 0);

	const [showSpinner, setShowSpinner] = useState(isLoading && isMap);

	useEffect(() => {
		if (!isMap) {
			setShowSpinner(false);
			return;
		}
		if (isLoading) {
			setShowSpinner(true);
		}
		const timeout = setTimeout(() => {
			if (!isLoading) setShowSpinner(false);
		}, MIN_DURATION_SPINNER_DISPLAY);
		return () => timeout && clearTimeout(timeout);
	}, [isLoading, isMap]);

	return (
		<div
			className={classes({
				search: true,
				isMap
			})}
		>
			{/* Filters */}
			<div className={styles.bar}>
				<div className={styles.filter}>
					<TagBar
						filterCount={filterCount}
						handleFilterClick={event =>
							handleFilterClick({
								event,
								title: t('search.filter.titleResponsive'),
								content: <SearchFilters />
							})
						}
					/>
				</div>

				<Container className={styles.container}>
					<SearchBar handleIsExpanded={handleIsExpanded} />

					<div className={styles.tabs}>
						<Button
							className={`${styles.mobile} ${styles.buttonBorder}`}
							label={t('search.tabs.service')}
							iconLeft='TagIcon'
							size='small'
							type='white'
							isFullWidth={true}
							hasColoredIcon={true}
							onClick={event =>
								handleFilterClick({
									event,
									isFullHeight: true,
									hasCloseBtn: false,
									title: null,
									content: <SearchTags />
								})
							}
						/>

						<Button
							className={`${styles.tablet} ${styles.buttonBorder}`}
							label={t('search.tabs.service')}
							iconLeft='TagIcon'
							size='small'
							type='white'
							isFullWidth={true}
							hasColoredIcon={true}
							onClick={event =>
								handleFilterClick({
									event,
									hasCloseBtn: false,
									content: <SearchTags />
								})
							}
						/>
						<Button
							className={styles.buttonBorder}
							label={!isMap ? t('common.map') : t('search.tabs.list')}
							onClick={event => {
								if (!isLoading) {
									event.preventDefault();
									toggleMap();
								}
							}}
							iconLeft={!isMap ? 'MapIcon' : 'List'}
							size='small'
							type='white'
							isDisabled={
								!isLoading && (showNoResults || businessesAreAllDirectories)
							}
							isFullWidth={true}
							hasColoredIcon={true}
						/>
						<Button
							className={`${styles.mobile} ${styles.buttonBorder}`}
							label={t('search.filter.titleResponsive')}
							iconLeft='Filter'
							size='small'
							type='white'
							isFullWidth={true}
							hasColoredIcon={true}
							hasBadgeIcon={filterCount}
							onClick={event =>
								handleFilterClick({
									event,
									title: null,
									isFullHeight: true,
									content: <SearchFilters />
								})
							}
						/>

						<Button
							className={`${styles.tablet} ${styles.buttonBorder}`}
							label={t('search.filter.titleResponsive')}
							iconLeft='Filter'
							size='small'
							type='white'
							isFullWidth={true}
							hasColoredIcon={true}
							hasBadgeIcon={filterCount}
							onClick={event =>
								handleFilterClick({
									event,
									title: t('search.filter.titleResponsive'),
									content: <SearchFilters />
								})
							}
						/>
					</div>
					{showSpinner && (
						<div className={classes({ spinnerContainer: true })}>
							<div className={classes({ spinner: true })}>
								<Spinner />
							</div>
						</div>
					)}
				</Container>
			</div>
		</div>
	);
}
