import { breakpoints } from '@planity/theme';
import classNames from 'classnames/bind';
import styles from './business_product.module.scss';
import { Image, Button, Icon } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';
import { formatPrice, noop } from '@planity/helpers';
import React, { useEffect, useRef, useState } from 'react';

const transformations = {
	default: 'b_white,c_pad,h_256,w_256',
	breakpoints: [
		{
			query: breakpoints.phoneQuery,
			transformation: 'b_white,c_pad,h_256,w_256'
		},
		{
			query: breakpoints.tabletQuery,
			transformation: 'b_white,c_pad,h_256,w_256'
		}
	]
};

export function BusinessProduct({
	cart,
	name = '',
	description = '',
	price = '',
	images,
	onAddProduct,
	brand,
	format,
	quantity,
	id,
	productSetIndex,
	index
}) {
	const { t } = useTranslation();
	useStyles(styles);
	const content = useRef();

	const [isCollapsed, setIsCollapsed] = useState(true);
	const [stock, setStock] = useState(quantity);
	const isSoldOut = stock <= 0;
	const hasDetails = !!description;

	const classes = classNames.bind(styles)({
		businessProduct: true,
		isDisabled: isSoldOut,
		isCollapsed
	});

	useEffect(() => {
		const productInCart = cart.find(p => p.id === id);
		if (!!productInCart) {
			setStock(quantity - productInCart.quantity);
		}
	}, [cart, id, quantity]);

	useEffect(() => {
		if (content.current)
			content.current.style.height =
				(!isCollapsed ? content.current.scrollHeight : 0) + 'px';
	}, [isCollapsed]);

	const renderDetails = (
		<div
			className={styles.accordion}
			onClick={() => setIsCollapsed(!isCollapsed)}
		>
			<div className={styles.description} ref={content}>
				{description}
			</div>
			{hasDetails && (
				<button
					className={styles.toggle}
					onClick={e => {
						setIsCollapsed(!isCollapsed);
						e.stopPropagation();
					}}
				>
					{isCollapsed
						? t('bookAppointment.showMoreDetailsDescription')
						: t('bookAppointment.showLessDetailsDescription')}
					<Icon className={styles.icon} icon='ChevronUp' />
				</button>
			)}
		</div>
	);

	return (
		<div className={classes} onClick={isSoldOut ? noop : onAddProduct}>
			<div className={styles.image}>
				{images && (
					<Image
						alt={name}
						fit='cover'
						isCloudinary
						transformations={transformations}
						url={images[0]}
					/>
				)}
			</div>
			<div className={styles.content}>
				<span className={styles.title}>{brand}</span>
				<span className={styles.name}>{name}</span>
				{description && renderDetails}
				<div className={styles.attributes}>
					<span>{formatPrice(price)}</span>
					{format && <span className={styles.separator}>{format}</span>}
				</div>
			</div>
			<div className={styles.actions}>
				<Button
					className={'planity_bookappointment-button-choose'}
					id={`product-choose-${productSetIndex}-${index}`}
					label={t(
						isSoldOut
							? 'clickAndCollect.soldOut'
							: 'bookAppointment.chooseService'
					)}
					size='small'
					isDisabled={isSoldOut}
					onClick={isSoldOut ? noop : onAddProduct}
				/>
			</div>
		</div>
	);
}
