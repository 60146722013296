import { sendAnalytics } from './helper';

const eventCategory = 'WaitingList';
const eventAction = 'waiting_list';

const COME_FROM_NEW_AVAILABILITIES_EMAIL = 'COME_FROM_NEW_AVAILABILITIES_EMAIL';
const MOVE_APPOINTMENT_WITH_WAITING_LIST = 'MOVE_APPOINTMENT_WITH_WAITING_LIST';
const MOVE_APPOINTMENT_NO_MAIL_WITH_WAITING_LIST =
	'MOVE_APPOINTMENT_NO_MAIL_WITH_WAITING_LIST';
const ENABLE_WAITING_LIST = 'ENABLE_WAITING_LIST';
const DISABLE_WAITING_LIST = 'DISABLE_WAITING_LIST';
const PRO_DISABLE_WAITING_LIST = 'PRO_DISABLE_WAITING_LIST';
const NO_AVAILABILITIES_FROM_EMAIL = 'NO_AVAILABILITIES_FROM_EMAIL';

export const userEnabledWaitingList = sendAnalytics(
	ENABLE_WAITING_LIST,
	eventCategory,
	eventAction
);

export const userDisabledWaitingList = sendAnalytics(
	DISABLE_WAITING_LIST,
	eventCategory,
	eventAction
);

export const proDisabledWaitingList = sendAnalytics(
	PRO_DISABLE_WAITING_LIST,
	eventCategory,
	eventAction
);

export const comeFromNewAvailabilitiesEmail = sendAnalytics(
	COME_FROM_NEW_AVAILABILITIES_EMAIL,
	eventCategory,
	eventAction
);

export const noAvailabilitiesFromEmail = sendAnalytics(
	NO_AVAILABILITIES_FROM_EMAIL,
	eventCategory,
	eventAction
);

export const moveAppointmentWithWaitingList = sendAnalytics(
	MOVE_APPOINTMENT_WITH_WAITING_LIST,
	eventCategory,
	eventAction
);

export const moveAppointmentNoMailWithWaitingList = sendAnalytics(
	MOVE_APPOINTMENT_NO_MAIL_WITH_WAITING_LIST,
	eventCategory,
	eventAction
);
