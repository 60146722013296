// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".appointment_empty-module_text-VeCBM{font:var(--regular) var(--body-3);color:var(--grey-600);padding-bottom:20px}", "",{"version":3,"sources":["webpack://./../../packages/ui/components/account/appointment_empty/appointment_empty.module.scss"],"names":[],"mappings":"AAAA,qCACC,iCAAA,CACA,qBAAA,CACA,mBAAA","sourcesContent":[".text {\n\tfont: var(--regular) var(--body-3);\n\tcolor: var(--grey-600);\n\tpadding-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "appointment_empty-module_text-VeCBM"
};
module.exports = ___CSS_LOADER_EXPORT___;
