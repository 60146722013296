import { useLocalizedRoutes } from '@planity/localization';
import { isNativeApp } from '@planity/webview';
import React, { useCallback } from 'react';
import { Localize } from '@planity/components';
import { Icon } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './search_header.module.scss';
import { useHistory } from 'react-router-dom';
import { isMobileSafari } from '@planity/helpers';

export function SearchHeader({ isSearchPlace }) {
	useStyles(styles);
	const history = useHistory();
	const { routes } = useLocalizedRoutes();
	const onClosePress = useCallback(() => {
		if (history.length > 1) {
			if (isMobileSafari()) {
				// history.go(-n) not working in safari ios
				history.replace(routes.home);
			} else {
				history.goBack();
			}
		} else {
			history.push(routes.home);
		}
	}, [history, routes]);

	return (
		<div className={styles.header}>
			<div className={styles.icon} onClick={onClosePress}>
				<Icon
					icon={isNativeApp && isSearchPlace ? 'ArrowLeft' : 'Close'}
					size={'medium'}
				/>
			</div>
			<div>
				{isSearchPlace ? (
					<Localize text='search.adresseHeader' />
				) : (
					<Localize text='nav.search' />
				)}
			</div>
		</div>
	);
}
