// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu_search-module_menuSearch-YjY1R{max-width:calc(1176px + 2*var(--gutter));margin:0 auto;padding:12px var(--gutter);border-bottom:1px solid var(--grey-200)}@media(max-width: 767px){.menu_search-module_menuSearch-YjY1R{display:none}}.menu_search-module_menuSearch-YjY1R .menu_search-module_items-vMWAD{display:grid;grid:1fr/repeat(5, max-content);gap:12px}.menu_search-module_menuSearch-YjY1R .menu_search-module_items-vMWAD .menu_search-module_item-dNFXc{font:var(--regular) var(--body-4)}", "",{"version":3,"sources":["webpack://./app/components/layout/menu_search.module.scss"],"names":[],"mappings":"AAEA,qCACC,wCAAA,CACA,aAAA,CACA,0BAAA,CACA,uCAAA,CACA,yBALD,qCAME,YAAA,CAAA,CAGD,qEACC,YAAA,CACA,+BAAA,CACA,QAAA,CACA,oGACC,iCAAA","sourcesContent":["@use '@planity/ui/styles/utilities/variables';\n\n.menuSearch {\n\tmax-width: calc(1176px + 2 * var(--gutter));\n\tmargin: 0 auto;\n\tpadding: 12px var(--gutter);\n\tborder-bottom: 1px solid var(--grey-200);\n\t@media (max-width: #{variables.$tablet - 1px}) {\n\t\tdisplay: none;\n\t}\n\n\t.items {\n\t\tdisplay: grid;\n\t\tgrid: 1fr / repeat(5, max-content);\n\t\tgap: 12px;\n\t\t.item {\n\t\t\tfont: var(--regular) var(--body-4);\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"menuSearch": "menu_search-module_menuSearch-YjY1R",
	"items": "menu_search-module_items-vMWAD",
	"item": "menu_search-module_item-dNFXc"
};
module.exports = ___CSS_LOADER_EXPORT___;
