import React from 'react';
import {
	ClickAndCollectCTA,
	GiftVoucherCTA,
	Localize
} from '@planity/components';
import { Button } from '@planity/ui';
import styles from './cta.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';

export const CTA = ({
	giftVoucherEnabled,
	onGiftVoucherClick,
	displayClickAndShop,
	displayClickAndCollect,
	onClickAndCollectClick,
	onBookClick
}) => {
	useStyles(styles);
	const classes = classNames.bind(styles);

	return (
		<>
			<div
				className={classes({
					cta: true,
					hideOnTabletAndDesktop: true,
					hideBloc: !displayClickAndCollect || !displayClickAndShop
				})}
			>
				{giftVoucherEnabled && (
					<GiftVoucherCTA
						id={`gift-voucher-cta-mobile`}
						size={'large'}
						onPress={onGiftVoucherClick}
					/>
				)}
				{(displayClickAndCollect || displayClickAndShop) && (
					<ClickAndCollectCTA
						id={`click-and-collect-cta-mobile`}
						size={'large'}
						onPress={onClickAndCollectClick}
					/>
				)}
				<div className={classes({ hideOnMobile: true })}>
					<Button
						id={`book-now-cta`}
						label={<Localize text={'business.bookNow'} />}
						onClick={onBookClick}
					/>
				</div>
			</div>

			<div className={classes({ cta: true, hideOnMobile: true })}>
				{giftVoucherEnabled && (
					<GiftVoucherCTA
						id={`gift-voucher-cta-desktop`}
						size={'medium'}
						onPress={onGiftVoucherClick}
					/>
				)}
				{(displayClickAndCollect || displayClickAndShop) && (
					<ClickAndCollectCTA
						id={`click-and-collect-cta-desktop`}
						size={'medium'}
						onPress={onClickAndCollectClick}
					/>
				)}
				<div className={styles.bookNow}>
					<Button
						id={`book-now-cta`}
						label={<Localize text={'business.bookNow'} />}
						onClick={onBookClick}
					/>
				</div>
			</div>
		</>
	);
};
