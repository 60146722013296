import React, { Component } from 'react';
import { invokeLambda, getFinalStripeCountryCode } from '@planity/helpers';
import {
	breakpoints,
	colors,
	fontSize,
	fontWeight,
	layers,
	withResponsive
} from '@planity/theme';
import { isAfter, parseISO, startOfDay } from 'date-fns';
import { firebase } from '@planity/datastores';
import { Localize, withStripeElementsConsumer } from '@planity/components';
import * as refonte_dimensions from '@planity/theme/refonte_dimensions';
import ModalAddCard from './../onlinePayment/modal_add_card';
import { OnlinePaymentConsumer } from '../../business_booking/online_payment/providerComponent';
import { SavedCards, Spinner } from '@planity/ui';
import { withLocalization } from '@planity/localization';
import credentials from '@planity/credentials';

export default withLocalization(
	withStripeElementsConsumer(
		withResponsive(
			OnlinePaymentConsumer(
				class MyProfile extends Component {
					state = {
						user: null,
						cardIsAdding: false,
						paymentMethod: [],
						selectedPaymentMethod: null,
						paymentMethodNeededToDelete: null,
						deletingCard: null
					};

					componentDidUpdate(prevProps, prevState) {
						if (this.props.displayAddCard !== prevProps.displayAddCard) {
							this.setState({
								displayAddCard: this.props.displayAddCard
							});
						}

						if (
							typeof this.props.userId === 'undefined' &&
							this.props.userId !== prevProps.userId
						) {
							this.props.resetPaymentMethodDatas();
						} else if (
							this.props.userId &&
							this.props.userId !== prevProps.userId
						) {
							this.props.getCustomerStripe({
								userId: this.props.userId,
								user: this.state.user,
								createCustomer: false,
								countryCode: this.props.countryCode
							});
						}
					}

					componentDidMount() {
						const { countryCode } = this.props;
						/**
						 * Stripe country code will be changed when reaching:  the business page
						 * OR "My account" information page ONLY if the actual stripe country code
						 * is different from the business country code or locale country code
						 * PS : we already tried to move this effect in a parent component but
						 * unfortunately this is worse. As redirection in my account tabs is done through a state,
						 * when the country code change, the component unmount, we lose the tab state and fallback to MY_APPOINTMENTS_TAB
						 */
						const finalCountryCode = getFinalStripeCountryCode(
							credentials,
							countryCode
						);

						if (
							countryCode &&
							finalCountryCode !== this.props.stripeCountryCode
						) {
							this.props.setStripeCountryCode(finalCountryCode);
						}
						this.setState(
							{
								user: {
									...this.props.user,
									email: firebase.auth().currentUser
										? firebase.auth().currentUser.email
										: null
								}
							},
							() => {
								this.props.getCustomerStripe({
									userId: this.props.userId,
									user: this.state.user,
									createCustomer: false,
									countryCode: this.props.countryCode
								});
							}
						);
					}

					render() {
						const { customerID, countryCode } = this.props;

						return (
							<div>
								{customerID ? (
									<div>
										{this.state.isModalOpen && (
											<ModalAddCard
												appointments={this.state.appointmentWithPaymentMethod}
												cardAdded={newPaymentMethod => {
													if (newPaymentMethod) {
														this.deleteCard(
															this.state.paymentMethodNeededToDelete,
															true,
															newPaymentMethod.id
														);

														this.props.getNewIntent({
															countryCode,
															customerID
														});

														this.setState({
															isModalOpen: false,
															cancellationIsPending: false
														});
													}
												}}
												closeModal={() => {
													this.setState({
														isModalOpen: false,
														cancellationIsPending: false
													});
												}}
												customerID={this.props.customerID}
												isAccount
												paymentMethod={this.props.paymentMethods}
												paymentMethodNeededToDelete={
													this.state.paymentMethodNeededToDelete
												}
												updatePaymentMethods={methods => {
													this.props.setPaymentMethods({
														response: { paymentMethods: { data: methods } }
													});
												}}
												{...this.props}
											/>
										)}
										{this.state.user && this.props.customerID ? (
											<div
												className={'planity_my-account-profile-form-wrapper'}
											>
												{this.props.paymentMethods === null && (
													<div css={styles.activityContainer}>
														<Spinner />
													</div>
												)}
												{this.props.paymentMethods?.length > 0 ? (
													<SavedCards
														defaultCard={this.props.defaultPaymentMethod}
														deleteCard={this.deleteCard}
														deletingCard={this.state.deletingCard}
														items={this.props.paymentMethods}
														makeDefault={this.props.makeDefault}
													/>
												) : null}
												<p css={styles.onlinePaymentDescription}>
													<Localize text={'bookAppointment.wannaAddCard'} />
												</p>
											</div>
										) : (
											<div css={styles.spinnerContainer}>
												<Spinner />
											</div>
										)}
									</div>
								) : null}
							</div>
						);
					}

					deleteCard = (
						paymentMethod,
						forceDelete = false,
						newPaymentMethodId = null
					) => {
						const { allFutureAppointments, pastAppointments, countryCode } =
							this.props;
						const appointments = (allFutureAppointments || []).concat(
							pastAppointments
						);

						const appointmentWithPaymentMethod = appointments.filter(
							appointment =>
								appointment.paymentMethod === paymentMethod.id &&
								!appointment.chargeId &&
								isAfter(parseISO(appointment.start), startOfDay(new Date()))
						);
						if (appointmentWithPaymentMethod.length === 0 || forceDelete) {
							this.setState({ deletingCard: paymentMethod.id });
							firebase
								.auth()
								.currentUser.getIdToken()
								.then(userToken => {
									invokeLambda('onlinePayment', {
										firebaseToken: userToken,
										actions: {
											type: 'customer.deleteCard',
											paymentMethod: paymentMethod,
											appointmentWithPaymentMethod:
												appointmentWithPaymentMethod,
											userId: firebase.auth().currentUser.uid,
											newPaymentMethodId,
											countryCode
										}
									})
										.then(response => {
											if (response.response) {
												this.props.setPaymentMethods(response.response);
												this.setState({
													deletingCard: null,
													appointmentWithPaymentMethod:
														appointmentWithPaymentMethod,
													paymentMethodNeededToDelete: null
												});
											}
										})
										.catch(error => {
											this.setState({ deletingCard: null });
											console.warn(error);
										});
								});
						} else {
							this.setState({
								isModalOpen: true,
								paymentMethodNeededToDelete: paymentMethod,
								appointmentWithPaymentMethod: appointmentWithPaymentMethod
							});
						}
					};
				}
			)
		)
	)
);

const styles = {
	spinnerContainer: {
		display: 'flex',
		justifyContent: 'center',
		margin: '15px 0',
		[breakpoints.tabletQuery]: {
			margin: 0
		}
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		fontSize: fontSize.extraRegular,
		fontWeight: fontWeight.bold,
		marginBottom: 11,
		marginLeft: 15,
		[breakpoints.tabletQuery]: {
			marginBottom: 5,
			marginLeft: 0
		}
	},
	phoneVerificationForm: {
		fontSize: '.875em'
	},
	signOut: {
		fontSize: '.875em',
		marginTop: 20,
		marginBottom: 20
	},
	myProfileWrapper: {
		base: {
			width: '100%',
			boxShadow: `0 1px 6px 1px rgba(0, 0, 0, 0.15)`,
			borderRadius: '4px',
			margin: '0 auto',
			paddingTop: 15,
			paddingLeft: 15
		},
		tablet: {
			paddingTop: 0,
			paddingLeft: 0,
			margin: 0,
			boxShadow: 'none'
		}
	},
	myProfile: {
		base: {
			'display': 'flex',
			'flexDirection': 'column',
			'& > div': {
				'marginBottom': 19,
				'fontSize': fontSize.regular,
				'& > a': {
					fontSize: '.87em'
				}
			}
		},
		tablet: {
			'& > div': {
				marginBottom: 13
			}
		}
	},
	unknown: {
		color: colors.grey.text
	},
	userProfileForm: {
		fontSize: '.875em'
	},
	passwordChangeForm: {
		fontSize: fontSize.regular
	},
	wrapper: {
		base: {
			width: '100%',
			marginTop: 19
		},
		tablet: {
			marginTop: 28,
			maxWidth: refonte_dimensions.getTabletWidth(2, 4)
		},
		desktop: {
			paddingLeft: 30,
			borderLeft: '1px solid #e5e5e5',
			maxWidth: 'unset'
		}
	},
	paymentMethodContainer: {
		display: 'flex',
		flexDirection: 'column',
		borderBottom: '1px solid #e5e5e5'
	},
	cardInfos: {
		padding: '20px 30px',
		[breakpoints.tabletQuery]: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between'
		}
	},
	cardCTA: {
		display: 'flex',
		flexDirection: 'row',
		paddingRight: 15
	},
	onlinePaymentDescription: {
		fontSize: 14,
		lineHeight: 1.29,
		color: colors.grey.brownGrey,
		margin: 0
	},
	hideOnMobile: {
		display: 'none',
		[breakpoints.tabletQuery]: {
			display: 'block'
		}
	},
	onlinePaymentDescriptionContainer: {
		padding: '22px 39px 20px 15px',
		[breakpoints.tabletQuery]: {
			padding: '18px 29px'
		}
	},
	paymentMethodPicture: {
		marginRight: 18,
		maxWidth: 37,
		height: 'auto',
		width: 'auto',
		display: 'inline-block',
		maxHeight: 20
	},
	lastDigits: {
		fontSize: 14,
		lineHeight: 1.29,
		marginRight: 25
	},
	choseDefault: {
		fontSize: 14,
		color: '#0d0d0d',
		textDecoration: 'underline',
		cursor: 'pointer',
		marginRight: 30
	},
	alreadyDefault: {
		fontSize: 14,
		color: '#0d0d0d',
		marginRight: 30
	},
	activityContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: 96
	},
	choseDefaultContainer: {
		minWidth: 155,
		display: 'flex',
		alignItems: 'center'
	},
	deleteCard: {
		fontSize: 14,
		color: '#0d0d0d',
		textDecoration: 'underline',
		cursor: 'pointer'
	},
	overlay: {
		backgroundColor: 'white',
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: layers.modalOverlay,
		[breakpoints.tabletQuery]: {
			backgroundColor: 'rgba(0,0,0,.69)'
		}
	},
	modal: {
		fontSize: '1rem',
		backgroundColor: colors.grey.light,
		width: 'calc(100% - 30px)',
		height: 'auto',
		padding: '21px 12px',
		textAlign: 'left',
		borderRadius: 4,
		boxShadow: '0 1px 6px 1px rgba(0, 0, 0, 0.15)',
		[breakpoints.tabletQuery]: {
			padding: '38px 52px 42px 38px',
			textAlign: 'center',
			width: 615,
			borderRadius: 4
		}
	},
	alert: {
		'marginBottom': 21,
		'lineHeight': 1.13,
		'& > div:first-child': {
			marginBottom: 6
		},
		[breakpoints.tabletQuery]: {
			marginBottom: 15
		}
	},
	actions: {
		'marginTop': 26,
		'display': 'flex',
		'alignItems': 'flex-start',
		'justifyContent': 'center',
		'flexDirection': 'column',
		'& > a': {
			marginLeft: '1em'
		},
		'& > button': {
			[breakpoints.tabletQuery]: {
				width: 'auto'
			}
		},
		[breakpoints.tabletQuery]: {
			marginTop: 15,
			flexDirection: 'row',
			justifyContent: 'center'
		}
	},
	hint: {
		textAlign: 'left',
		display: 'flex',
		flexDirection: 'column',
		[breakpoints.tabletQuery]: {
			'alignItems': 'center',
			'flexDirection': 'row',
			'& > div': {
				marginLeft: 5
			},
			'& > a': {
				marginLeft: 3
			}
		}
	},
	modalTitle: {
		margin: 0,
		marginBottom: 9,
		fontSize: 18,
		lineHeight: 1.5,
		fontWeight: 'bold'
	},
	step: {
		justifyContent: 'center'
	},
	cancel: {
		fontSize: 13,
		margin: 0,
		textAlign: 'left',
		[breakpoints.tabletQuery]: {
			textAlign: 'center'
		}
	},
	feeButtons: {
		margin: '12px 0',
		width: 'auto',
		[breakpoints.tabletQuery]: {
			margin: '0 15px'
		}
	},
	creditCardContainer: {
		backgroundColor: colors.white,
		[breakpoints.tabletQuery]: {
			borderRadius: 4,
			boxShadow: '0 1px 4px 1px rgba(0, 0, 0, 0.08)'
		}
	},
	creditCard: {
		left: {
			display: 'flex',
			marginBottom: 19,
			[breakpoints.tabletQuery]: {
				marginBottom: 0
			}
		},
		right: {}
	}
};
