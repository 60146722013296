import React from 'react';
import { WithAuth } from '@planity/components';
import { OnlineBusinessStatus } from '../business_booking/online_payment/onlineStatus';
import { OnlinePaymentProvider } from '../business_booking/online_payment/providerComponent';
import BookAppointmentComponent from './book_appointment';
import { useLocalization } from '@planity/localization';
import { useUserData } from '@planity/context';

export function BookAppointment(props) {
	const { locale } = useLocalization();
	const { userCures } = useUserData();
	return (
		<WithAuth skipIsAdmin={true}>
			{({ userId, isVerified, user, isPro, isLoading }) => (
				<OnlineBusinessStatus business={props.business}>
					{({ status }) => (
						<OnlinePaymentProvider business={props.business}>
							<BookAppointmentComponent
								{...props}
								isAuthLoading={isLoading}
								isPro={isPro}
								isVerified={isVerified}
								locale={locale}
								status={status}
								user={user}
								userCures={userCures}
								userId={userId}
							/>
						</OnlinePaymentProvider>
					)}
				</OnlineBusinessStatus>
			)}
		</WithAuth>
	);
}
