import { shouldDisplayClickAndCollect } from '@planity/helpers';
import {
	useState,
	useEffect
	// useDebugValue
} from 'react';

export const useClickAndCollect = (business, businessId) => {
	const [products, setProducts] = useState({});
	const [displayClickAndCollect, setDisplayClickAndCollect] = useState(false);
	const [displayClickAndShop, setDisplayClickAndShop] = useState(false);

	useEffect(() => {
		const loadClickAndCollect = async () => {
			const result = await shouldDisplayClickAndCollect(business, businessId);
			const { clickAndCollectProducts, clickAndShopProducts } = result;
			setProducts({ clickAndCollectProducts, clickAndShopProducts });
			setDisplayClickAndCollect(result.displayClickAndCollect);
			setDisplayClickAndShop(result.displayClickAndShop);
		};

		if (!!business.settings && !!business.modules && !!businessId) {
			loadClickAndCollect();
		}
	}, [business, businessId]);

	// useDebugValue(products)
	// useDebugValue(displayClickAndCollect)

	return [products, displayClickAndCollect, displayClickAndShop];
};
