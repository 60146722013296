import React, { useEffect, useState } from 'react';
import { Layout } from '../layout';
import styles from './complain_rejected_user_review_page.module.scss';
import { BusinessTitle, Container, Spinner } from '@planity/ui';
import { Localize } from '@planity/components';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useLocation } from 'react-router-dom';
import { invokeLambda } from '@planity/helpers/browser';
import { useTranslation } from '@planity/localization';

export function ComplainRejectedUserReview() {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		rejectedReview: true
	});
	const location = useLocation();
	const { t } = useTranslation();

	const searchParams = new URLSearchParams(location?.search);
	const token = searchParams?.get('token');
	const [isSuccessfulComplaint, setIsSuccessfulComplaint] = useState(false);
	const [hasError, setHasError] = useState(false);

	useEffect(() => {
		if (token) {
			invokeLambda('rejectedReviews', {
				token
			})
				.then(res =>
					res === 'SUCCESSFUL USER COMPLAINT'
						? setIsSuccessfulComplaint(true)
						: setHasError(true)
				)
				.catch(e => {
					console.warn(e);
					setHasError(true);
				});
		} else {
			setIsSuccessfulComplaint(true);
		}
	}, [token]);

	if (!isSuccessfulComplaint && !hasError) {
		return (
			<div className={styles.spinner}>
				<Spinner />
			</div>
		);
	}
	return (
		<Layout hideBreadcrumbs skipCoronavirusModal>
			<div className={classes}>
				<Container>
					{hasError ? (
						<>
							<BusinessTitle
								path={'complainCustomerRejectedReview.error.title'}
							/>
							<p>{t('complainCustomerRejectedReview.error.message')}</p>
						</>
					) : (
						<>
							<BusinessTitle
								path={'complainCustomerRejectedReview.mainMessage'}
							/>
							<p>
								<Localize
									text={'complainCustomerRejectedReview.firstSubMessage'}
								/>
								<br />
								<Localize
									text={'complainCustomerRejectedReview.secondSubMessage'}
								/>
								<br />
								<br />
								<br />
								<Localize
									text={'complainCustomerRejectedReview.thirdSubMessage'}
								/>
							</p>
						</>
					)}
				</Container>
			</div>
		</Layout>
	);
}
