import { useReducer, useContext, useState, useEffect } from 'react';
import {
	CLEAN,
	DATE,
	filtersdDefaultState,
	getDateIdByDate,
	GETGLOBAL,
	PUSH,
	SORT
} from '@planity/helpers';
import { FilterContext } from 'planity-website/app/components/ui/filter/context';
import { sendOpenDatePickerEvent } from '@planity/helpers/analytics';
import { useModal } from '@planity/ui';

// translation keys
const defaultPlaceholder = 'search.placeholders.when.default';
const dateReset = 'search.placeholders.when.dateReset';
export const useSearchFilter = () => {
	const { filters, dispatch } = useContext(FilterContext);
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
	const [textValueField, setTextValueField] = useState(defaultPlaceholder);
	const isDateChosenOnce = textValueField === dateReset;
	const toggleIsDatePickerOpen = () => setIsDatePickerOpen(!isDatePickerOpen);
	const closeDatePicker = () => setIsDatePickerOpen(false);
	const { setModal } = useModal();
	const filterReducer = (state, { type, id, date, payload }) => {
		switch (type) {
			case SORT:
				return { ...state, sort: id };
			case DATE:
				return { ...state, byDate: id, data: { date } };
			case CLEAN:
				return filtersdDefaultState;
			case GETGLOBAL:
				return { ...filters };
			case PUSH:
				return { ...payload };
			default:
				return { ...state, ...filters };
		}
	};
	const [localFilters, setLocalFilters] = useReducer(
		filterReducer,
		filtersdDefaultState
	);
	useEffect(() => {
		setLocalFilters(filters);
	}, [filters]);
	const handleInputClick = event => {
		event.preventDefault();
		event.stopPropagation();
		sendOpenDatePickerEvent();
		toggleIsDatePickerOpen();
	};
	const handleFilterClick = ({
		event,
		title,
		content,
		className,
		isFullHeight,
		hasCloseBtn
	}) => {
		event.preventDefault();
		event.stopPropagation();
		setModal({ content, title, className, isFullHeight, hasCloseBtn });
	};
	const handleDateChange = date => {
		toggleIsDatePickerOpen();
		setTextValueField(dateReset); // text value when a date has been chosen once
		setLocalFilters({ type: DATE, id: getDateIdByDate(date), date });
	};
	const submitDateChange = date => {
		toggleIsDatePickerOpen();
		setTextValueField(dateReset); // text value when a date has been chosen once
		setLocalFilters({ type: DATE, id: getDateIdByDate(date), date });
		dispatch({ type: DATE, id: getDateIdByDate(date), date });
	};
	return {
		filters,
		dispatch,
		localFilters,
		setLocalFilters,
		closeDatePicker,
		toggleIsDatePickerOpen,
		handleInputClick,
		textValueField,
		handleDateChange,
		submitDateChange,
		isDatePickerOpen,
		isDateChosenOnce,
		handleFilterClick
	};
};
