/**
 * Only keeps clickAndCollect and clickAndShop from userBuyings
 * @param buyings {Object} User buyings
 * @return {Object} clickAndCollect and clickAndShop buyings
 */
export const clickAndCollectAndShopFilter = buyings =>
	Object.entries(buyings || {}).reduce((all, [buyingId, buying]) => {
		if (['clickAndCollect', 'clickAndShop'].includes(buying.type)) {
			all[buyingId] = buying;
		}
		return all;
	}, {});
