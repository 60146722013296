import React, { useEffect, useMemo, useReducer } from 'react';

const TRANSITION_TIME = 300;

export function Dots({ imagesLength, imageIndex, navToIndex }) {
	const initialState = useMemo(
		() => ({
			index: imageIndex,
			isSliding: false
		}),
		[]
	);
	const [state, dispatch] = useReducer(dotsReducer, initialState);
	const { index, isSliding } = state;

	useEffect(() => {
		if (imageIndex > 2 && imageIndex < imagesLength - 3) {
			dispatch({ type: 'TRANSITION_START', direction: imageIndex - index });
		}
		const timeout = setTimeout(() => {
			requestAnimationFrame(() => {
				dispatch({ type: 'TRANSITION_END', index: imageIndex });
			});
		}, TRANSITION_TIME);
		return () => {
			clearTimeout(timeout);
		};
	}, [imageIndex]);

	if (imagesLength <= 1) {
		return null;
	}
	const { dotsLength, firstIndex } = visibleDotsLength(imagesLength, index);

	const isNavToNext = useMemo(() => {
		return isSliding === 1;
	}, [isSliding]);
	const isNavToPrev = useMemo(() => {
		return isSliding === -1;
	}, [isSliding]);

	return (
		<div css={styles.dotsWrapper}>
			{Array.from(new Array(dotsLength).keys()).map(i => {
				return (
					<a
						css={[
							styles.dot,
							i + firstIndex === index && styles.currentDot,
							isEdge(i, firstIndex, dotsLength, imagesLength) && styles.edgeDot
						]
							.concat(
								isNavToNext
									? [
											styles.nextTransition,
											i === 0 && { width: 0, height: 0 },
											i === 1 && styles.edgeDot,
											i === 2 && styles.dot,
											i === 3 && styles.currentDot,
											i === 4 && styles.dot
									  ]
									: []
							)
							.concat(
								isNavToPrev
									? [
											styles.prevTransition,
											i === 4 && { width: 0, height: 0 },
											i === 3 && styles.edgeDot,
											i === 2 && styles.dot,
											i === 1 && styles.currentDot,
											i === 0 && styles.dot
									  ]
									: []
							)}
						key={i}
						onClick={e => navToIndex(e, i + firstIndex)}
					/>
				);
			})}
		</div>
	);
}

function dotsReducer(state, action) {
	switch (action.type) {
		case 'TRANSITION_START': {
			return {
				index: state.index,
				isSliding: action.direction
			};
		}
		case 'TRANSITION_END':
			return {
				index: action.index,
				isSliding: false
			};
		default:
			throw new Error('UNKNOWN_ACTION_ERROR');
	}
}

const MAX_DOTS_SIZE = 5;

function visibleDotsLength(size, index) {
	if (size <= MAX_DOTS_SIZE) {
		return { dotsLength: size, firstIndex: 0 };
	}
	if (index <= 2) {
		return { dotsLength: MAX_DOTS_SIZE, firstIndex: 0 };
	}
	if (index >= size - 3) {
		return { dotsLength: MAX_DOTS_SIZE, firstIndex: size - MAX_DOTS_SIZE };
	}
	return {
		dotsLength: MAX_DOTS_SIZE,
		firstIndex: Math.ceil(index - MAX_DOTS_SIZE / 2)
	};
}

function isEdge(i, firstIndex, dotsLength, imageLength) {
	const isFirst = i === 0;
	const isLast = i === dotsLength - 1;
	if (dotsLength < imageLength) {
		if (dotsLength === MAX_DOTS_SIZE) {
			return (
				(isFirst && firstIndex !== 0) ||
				(isLast && firstIndex === 0) ||
				(isLast && firstIndex !== imageLength - MAX_DOTS_SIZE)
			);
		} else {
			return false;
		}
	}
	return false;
}

const styles = {
	dotsWrapper: {
		zIndex: 2,
		position: 'absolute',
		right: 0,
		bottom: 0,
		left: 0,
		height: 28,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	dot: {
		backgroundColor: 'white',
		height: 6,
		width: 6,
		borderRadius: 3,
		margin: '0 2.5px',
		display: 'inline-block',
		cursor: 'pointer',
		opacity: 0.7
	},
	currentDot: {
		backgroundColor: 'white',
		width: 6,
		height: 6,
		borderRadius: 6,
		opacity: 1
	},
	edgeDot: {
		height: 4,
		width: 4,
		borderRadius: 2,
		opacity: 0.6
	},
	nextTransition: {
		transform: 'translateX(-6px)',
		transition: 'all var(--timing-short) linear'
	},
	prevTransition: {
		transform: 'translateX(6px)',
		transition: 'all var(--timing-short) linear'
	}
};
