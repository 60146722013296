import { getHumanCalendars } from '@planity/helpers';
import { Button, Card } from '@planity/ui';
import { useTranslation } from '@planity/localization';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './business_collaborators.scss';
import { CloudinaryPicture } from '@planity/components';
import React, { useMemo, useState } from 'react';
const COLLAPSED_CHILDREN_SIZE = 8; // Default max size of collapsed list

const BusinessCollaboratorItem = ({ collaborator }) => {
	useStyles(styles);
	const { name, picture } = collaborator;
	const cx = classNames.bind(styles);

	/**
	 * Get initials : Tony Stark => TS
	 * @type {string}
	 */
	const nameInitials = useMemo(
		() =>
			(name || '')
				.replace(
					/[^\w\sÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿ]/gi,
					''
				)
				.split(' ')
				.slice(0, 2)
				.map(name => name[0])
				.filter(x => x)
				.join(''),
		[name]
	);

	return (
		<div className={cx({ collaboratorItem: true })}>
			<div className={cx({ pictureContainer: true })}>
				{!!picture ? (
					<CloudinaryPicture
						src={picture}
						alt={name}
						transformation={'h_128,w_128,q_auto'}
					/>
				) : (
					<div className={styles.picture}>{nameInitials}</div>
				)}
				<div className={cx({ text: true })}>{name}</div>
			</div>
		</div>
	);
};

export const BusinessCollaborators = ({ calendars, settings }) => {
	useStyles(styles);
	const cx = classNames.bind(styles);
	const { t } = useTranslation();
	const forbidCalendarSelection = !!settings?.forbidCalendarSelection;
	const humanCalendars = getHumanCalendars(calendars);
	const sortedHumanCalendars = Object.values(humanCalendars)?.sort(
		(a, b) => a.sort - b.sort
	);
	const visibleCalendars = forbidCalendarSelection
		? sortedHumanCalendars?.filter(x => x?.picture)
		: sortedHumanCalendars;
	const numberOfCalendars = visibleCalendars?.length;
	const [limit, setLimit] = useState(COLLAPSED_CHILDREN_SIZE);
	const isCollapsed = limit !== numberOfCalendars;
	const label = isCollapsed
		? t('business.collaborators.showMore')
		: t('business.collaborators.showLess');
	const calendarsToDisplay = visibleCalendars?.slice(
		0,
		isCollapsed ? limit - 1 : limit
	);

	if (!calendarsToDisplay) return null;
	return (
		<Card title={t('business.labels.collaborators')}>
			<div
				className={cx({
					collaboratorsList: true,
					isCollapsed
				})}
			>
				{calendarsToDisplay.map((collaborator, index) => (
					<BusinessCollaboratorItem collaborator={collaborator} key={index} />
				))}
				{numberOfCalendars > COLLAPSED_CHILDREN_SIZE ? (
					<div
						className={cx({
							collaboratorItem: true,
							button: true
						})}
						onClick={() =>
							setLimit(
								isCollapsed ? numberOfCalendars : COLLAPSED_CHILDREN_SIZE
							)
						}
					>
						<div className={cx({ pictureContainer: true })}>
							<div className={styles.showMorePicture}>
								{`${isCollapsed ? '+' : '-'} ${
									numberOfCalendars - COLLAPSED_CHILDREN_SIZE + 1
								}`}
							</div>
						</div>
						<Button
							className={styles.showMore}
							label={label}
							noFocus
							type='underlined'
						/>
					</div>
				) : null}
			</div>
		</Card>
	);
};
