import countries from './countries.json';

/**
 *
 * @param {string} locale user's locale
 * @returns list of neighbouring country codes
 *
 * primaryCountries : FR, DE, BE -> Countries where planity exist
 *
 * order of the preferredCountries
 * - defaultCountry
 * - primaryCountries except defaultCountry
 * - neighbouring countries of the default country
 */
export function getPreferredCountries(locale) {
	switch (locale) {
		case 'fr-FR':
			return ['FR', 'DE', 'BE', 'AD', 'ES', 'IT', 'LU', 'MC', 'CH'];
		case 'nl-BE':
			return ['BE', 'FR', 'DE', 'LU', 'NL'];
		case 'de-DE':
			return ['DE', 'FR', 'BE', 'DK', 'LU', 'NL', 'AT', 'PL', 'CZ', 'CH'];
		case 'fr-BE':
			return ['BE', 'FR', 'DE', 'LU', 'NL'];
		default:
			return ['FR', 'DE', 'BE', 'AD', 'ES', 'IT', 'LU', 'MC', 'CH'];
	}
}

export const countriesCodes = countries;
