import { filterDataForWidget } from '@planity/helpers';

/**
 * Format queries for each appointment/buying
 * @param {Object|Array<Object>} userItems can be either appointments, buyings...
 * @returns {{ [businessId] : {path, shard} }}
 */
export function formatBusinessesQueries(userItems) {
	try {
		const filteredItems = filterDataForWidget(userItems);
		return Object.values(filteredItems || {})?.reduce((all, item) => {
			const businessId = item?.businessId;
			if (businessId && !all[businessId]) {
				all[businessId] = {
					path: `businesses/${businessId}`,
					shard: 'public'
				};
			}
			return all;
		}, {});
	} catch (e) {
		console.error(e);
	}
}

/**
 *	This function merges business & online payment data into each appointment/buying
 * @param {Object} userItems can be either appointments, buyings...
 * @param {Object} onlinePaymentData
 * @param {Object} businesses all businesses data loaded
 * @returns {Object} {[userItemId] : ...item, business }
 */
export function mergeAppointmentsData(
	userItems,
	onlinePaymentData,
	businesses
) {
	return Object.entries(userItems || {}).reduce((all, [id, item]) => {
		if (id && businesses[item?.businessId]) {
			all[id] = {
				...item,
				...onlinePaymentData?.[id],
				review: item.review,
				business: businesses[item.businessId]
			};
		}
		return all;
	}, {});
}
