import React, { useContext } from 'react';
import credentials from '@planity/credentials';
import {
	SIGN_IN_WITH_FACEBOOK,
	SIGN_IN_WITH_GITHUB,
	SIGN_IN_WITH_GOOGLE
} from '../auth_actions';
import { SocialButton } from '@planity/components';
import { ContextSocialLogin } from '../context_form';
export const LoginWithSocialMedia = ({ type }) => {
	const { ENABLE_FACEBOOK_LOGIN, ENABLE_GOOGLE_LOGIN, ENABLE_GITHUB_LOGIN } =
		credentials;
	const loginWithSocialMediasValues = useContext(ContextSocialLogin);
	const {
		dispatch,
		disableSocialLogin,
		fromAccount,
		isDesktop,
		numberOfSocialButtons,
		socialLoginWidth,
		pending
	} = loginWithSocialMediasValues;

	const socialMedias = [
		{
			id: 1,
			name: 'facebook',
			localizedText: `auth.form.buttons.${type}WithFacebook`,
			condition: ENABLE_FACEBOOK_LOGIN,
			payloadAction: SIGN_IN_WITH_FACEBOOK
		},
		{
			id: 2,
			name: 'google',
			condition: ENABLE_GOOGLE_LOGIN,
			payloadAction: SIGN_IN_WITH_GOOGLE
		},
		{
			id: 3,
			name: 'github',
			condition: ENABLE_GITHUB_LOGIN,
			payloadAction: SIGN_IN_WITH_GITHUB
		}
	];
	return (
		(ENABLE_FACEBOOK_LOGIN || ENABLE_GOOGLE_LOGIN || ENABLE_GITHUB_LOGIN) &&
		!disableSocialLogin && (
			<React.Fragment>
				<div
					css={[
						fromAccount ? css.fromAccountLine : css.outAccountLine,
						isDesktop ? null : css.mobileLine,
						css.line
					]}
				/>

				{socialMedias.map(
					social =>
						social.condition && (
							<React.Fragment key={social.id}>
								<SocialButton
									css={css.socialButton}
									isAlone={numberOfSocialButtons === 1}
									isDisabled={pending}
									key={social.id}
									localizedText={
										social.localizedText ? social.localizedText : ''
									}
									provider={social.name}
									onPress={() => dispatch({ type: social.payloadAction })}
								/>
								{social.name !== 'github' && (
									<div css={{ width: socialLoginWidth }} />
								)}
							</React.Fragment>
						)
				)}
			</React.Fragment>
		)
	);
};
const css = {
	line: {
		height: 1,
		backgroundColor: '#e5e5e5',
		margin: '18px auto'
	},
	socialButton: {
		flex: 1,
		marginBottom: 6
	},
	outAccountLine: {
		width: 420
	},
	fromAccountLine: {
		width: 360
	},
	mobileLine: {
		width: '100%',
		margin: '18px 0px'
	}
};
