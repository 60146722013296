import { useFirebaseSubscription } from '@planity/datastores';
import { useAuth } from '@planity/components';

export const useUserBuyings = ({ isAllowedToFetchData }) => {
	const { userId } = useAuth();
	const [userBuyings, userBuyingsError, userBuyingsAreLoading] =
		useFirebaseSubscription(
			isAllowedToFetchData && {
				path: userId && `user_buyings/${userId}`,
				orderByChild: 'boughtAt'
			}
		);

	if (process.env.NODE_ENV === 'development' && userBuyingsError) {
		console.warn({
			userBuyingsError
		});
	}
	return { userBuyings, userBuyingsAreLoading };
};
