import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './toast.module.scss';
import { Icon } from '@planity/ui';

export function Toast({
	text = '',
	type = 'default',
	closeToast,
	id = 'toast'
}) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		toast: true,
		[type]: true
	});
	return (
		<div className={styles.background} id={id}>
			<div className={classes}>
				<span>{text}</span>
				<button className={styles.close} aria-label='Close'>
					<Icon icon={'Close'} onClick={closeToast} />
				</button>
			</div>
		</div>
	);
}
