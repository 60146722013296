import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './container.module.scss';

export function Container({
	children,
	className,
	size,
	type,
	withMarginTop,
	isFullWidth,
	noStupidMarginBottom
}) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		container: true,
		[className]: className !== undefined,
		[type]: type !== undefined,
		isSmall: size === 'small',
		isMedium: size === 'medium',
		withMarginTop,
		isFullWidth,
		noStupidMarginBottom
	});

	return <div className={classes}>{children}</div>;
}
