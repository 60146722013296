import React, { Component } from 'react';
import { firebase } from '@planity/datastores';

export default class AuthenticateWithToken extends Component {
	componentDidMount() {
		this.authenticate();
	}

	componentDidUpdate(prevProps) {
		if (this.props.token !== prevProps.token) {
			this.authenticate();
		}
	}

	render() {
		return this.props.children;
	}

	authenticate() {
		const { token } = this.props;
		if (token) {
			firebase
				.auth()
				.signInWithCustomToken(token)
				.catch(error => {
					// TODO handle error
				});
		}
	}
}
