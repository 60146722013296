import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './thumbnail.module.scss';

export function Thumbnail({ imageUrl = '', altDescription = '', title = '' }) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		thumbnail: true
	});

	return (
		<div className={classes}>
			<img className={styles.image} src={imageUrl} alt={altDescription} />
			<span className={styles.title}>{title}</span>
		</div>
	);
}
