import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './select_modal.module.scss';
import { Modal } from '@planity/ui';

export const SelectModal = ({
	children,
	openModal,
	setOpenModal,
	isFullHeight,
	hasNoRightPadding,
	preventScroll
}) => {
	useStyles(styles);
	const cx = classNames.bind(styles);

	return (
		<div className={styles.modal}>
			<Modal
				closeModal={setOpenModal}
				hasCloseBtn
				isOpen={openModal}
				isFullHeight={isFullHeight}
				preventScroll={preventScroll}
			>
				<div
					className={cx({
						modalContainer: true,
						hasNoRightPadding
					})}
				>
					{children}
				</div>
			</Modal>
		</div>
	);
};
