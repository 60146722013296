export const noop = () => {};
export const truth = () => true;
export const falseness = () => false;
export const identity = x => x;
export const formatDistance = distance => {
	// TODO localize
	if (!distance) return '0m';
	if (distance < 1000) return `${distance}m`;
	return `${Math.round(distance / 100) / 10}km`;
};

/**
 * @deprecated use conditional chaining instead : myObj?.field?.otherField. Need optional chaining
 *   in an array ? Use myArray?.at(0)?.filed (Ecmascript 2022)
 * @param object {Object}
 * @param path {Array<String>}
 * @param fallback
 * @returns {*|undefined}
 */
export const safeRead = (object, path, fallback) => {
	const value = (path || []).reduce((node, key) => {
		if (node) {
			try {
				return typeof key === 'function' ? key(node) : node[key];
			} catch (e) {
				if (process.env.NODE_ENV === 'development') {
					console.warn(e);
				}
				return undefined;
			}
		} else {
			return undefined;
		}
	}, object || {});
	if (value === undefined) {
		if (fallback && fallback.path)
			return safeRead(object, fallback.path, { value: fallback.value });
		if (fallback && fallback.value !== undefined) return fallback.value;
		return undefined;
	} else {
		return value;
	}
};

export function stringPredecessor(string) {
	if (!string) return '';
	const prefix = string.slice(0, -1);
	const lastCharCode = string.charCodeAt(string.length - 1);
	if (lastCharCode === 0) {
		return prefix;
	} else {
		return prefix + String.fromCharCode(lastCharCode - 1);
	}
}

const enhance =
	(fn, attr) =>
	(object, ...args) => {
		if (object && object[attr] && typeof object[attr] === 'function') {
			return object[attr](...args);
		} else {
			return fn(object, ...args);
		}
	};

export const map = enhance((object, fn) => {
	if (object) {
		return Object.keys(object).reduce((newObject, key) => {
			newObject[key] = fn(object[key], key);
			return newObject;
		}, {});
	} else {
		return object;
	}
}, 'map');

export const filter = enhance((object, fn) => {
	if (object) {
		return Object.keys(object).reduce((newObject, key) => {
			if (fn(object[key], key)) {
				newObject[key] = object[key];
			}
			return newObject;
		}, {});
	} else {
		return object;
	}
}, 'filter');

export const reduce = enhance((object, fn, acc) => {
	if (object) {
		return Object.keys(object).reduce((newObject, key) => {
			return fn(newObject, object[key], key);
		}, acc);
	} else {
		return object;
	}
}, 'reduce');

export const size = enhance(object => {
	return Object.keys(object || {}).length;
}, 'size');

export function first(object) {
	return object[Object.keys(object || {})[0]];
}

export function remove(object, key) {
	if (object) {
		const newObject = { ...object };
		delete newObject[key];
		return newObject;
	} else {
		return object;
	}
}

export function values(object) {
	if (object) {
		return Object.keys(object).reduce((objectValues, key) => {
			objectValues.push(object[key]);
			return objectValues;
		}, []);
	} else {
		return [];
	}
}

export const find = enhance((object, fn) => {
	if (object) {
		return Object.keys(object).find(key => fn(object[key], key));
	} else {
		return null;
	}
}, 'find');

/**
 * Capitalize first letter but doesn't change anything else
 * "tony stark" => "Tony stark"; "tony STARK" => "Tony STARK"
 * @param s {string}
 * @param condition {boolean} if you should capitalize under some condition (like being a german
 *   word)
 * @return {string}
 */
export function capitalize(s, condition = true) {
	if (!condition || !s || typeof s !== 'string') return s;
	return s.charAt(0).toUpperCase() + s.slice(1);
}

/**
 *
 * @param data
 * @returns {{}|*}
 */
export function filterDataForWidget(data) {
	try {
		if (!data) return data;
		if (typeof data !== 'object' || Array.isArray(data)) return data;
		if (process.env.BROWSER && process.env.WIDGET && window.planity.key)
			return Object.entries(data).reduce((all, [id, value]) => {
				if (value?.businessId === window.planity.key) all[id] = value;
				return all;
			}, {});
		else return data;
	} catch (e) {
		console.error(e);
		return data;
	}
}

export const franceAndDomToms = [
	'FR',
	'PM',
	'RE',
	'GP',
	'MQ',
	'GF',
	'YT',
	'MF',
	'BL'
];

export const getStripeCustomerNode = countryCode => {
	if (!['BE', 'DE'].includes(countryCode)) return 'customers';
	else return `customers_${countryCode.toLowerCase()}`;
};

export const hasStripeAPIKey = (credentials, countryCode) =>
	!!credentials[`STRIPE_API_KEY_${countryCode}`];

/**
 * Handle case where the country code is part of the DOM-TOM
 * or is not a country handled with a Special stripe account,
 * both cases have to be replaced as FR country
 * @param {object} credentials
 * @param {string} countryCode
 * @returns
 */
export const getFinalStripeCountryCode = (credentials, countryCode) =>
	franceAndDomToms.includes(countryCode) ||
	!hasStripeAPIKey(credentials, countryCode)
		? 'FR'
		: countryCode;

/**
 * Extract token data from query string when user is redirected from stripeRedirect lambda
 * @param {string} queryData
 * @returns {boolean|object}
 */
export const extractQueryDataJSON = queryData => {
	if (!(queryData && typeof queryData === 'string')) {
		return false;
	}
	try {
		return JSON.parse(queryData);
	} catch (e) {
		return false;
	}
};

export async function sleep(ms) {
	await new Promise(resolve => setTimeout(resolve, ms));
}

export const removeQueryFromParams = (searchParams, paramToRemove) => {
	const newSearchParams = new URLSearchParams(
		[...searchParams.entries()].filter(([key]) => key !== paramToRemove)
	);
	return newSearchParams.toString();
};
