export const checkInputError = (type, t, state) => {
	// TODO That's a big thing to work on
	const errors = state?.errors;
	const index =
		errors && errors.length && Array.isArray(errors)
			? errors.findIndex(
					x => x?.name && x?.name.toUpperCase() === type.toUpperCase()
			  )
			: -1;
	return {
		message:
			index > -1 && errors[index].code
				? t(`auth.errors.${errors[index].code}`)
				: null,
		format: index > -1
	};
};
