// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text_section-module_texts-ljegf h2{font:var(--regular) var(--title-alt-1);margin-bottom:44px;line-height:52px}@media only screen and (max-width: 767px){.text_section-module_texts-ljegf h2{font:var(--regular) var(--title-alt-3)}}.text_section-module_texts-ljegf h3{font:var(--semibold) var(--title-3);margin-bottom:8px;line-height:36px}.text_section-module_texts-ljegf img{margin-bottom:44px}.text_section-module_texts-ljegf .text_section-module_paragraphs-0jBrT{margin-bottom:44px}.text_section-module_texts-ljegf p{font-size:18px;line-height:27px}", "",{"version":3,"sources":["webpack://./app/components/category_page/text_section.module.scss"],"names":[],"mappings":"AAGC,oCACC,sCAAA,CACA,kBAAA,CACA,gBAAA,CACA,0CAJD,oCAKE,sCAAA,CAAA,CAIF,oCACC,mCAAA,CACA,iBAAA,CACA,gBAAA,CAGD,qCACC,kBAAA,CAGD,uEACC,kBAAA,CAGD,mCACC,cAAA,CACA,gBAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.texts {\n\th2 {\n\t\tfont: var(--regular) var(--title-alt-1);\n\t\tmargin-bottom: 44px;\n\t\tline-height: 52px;\n\t\t@media only screen and (max-width: #{styles.$tablet - 1px}) {\n\t\t\tfont: var(--regular) var(--title-alt-3);\n\t\t}\n\t}\n\n\th3 {\n\t\tfont: var(--semibold) var(--title-3);\n\t\tmargin-bottom: 8px;\n\t\tline-height: 36px;\n\t}\n\n\timg {\n\t\tmargin-bottom: 44px;\n\t}\n\n\t.paragraphs {\n\t\tmargin-bottom: 44px;\n\t}\n\n\tp {\n\t\tfont-size: 18px;\n\t\tline-height: 27px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"texts": "text_section-module_texts-ljegf",
	"paragraphs": "text_section-module_paragraphs-0jBrT"
};
module.exports = ___CSS_LOADER_EXPORT___;
