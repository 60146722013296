import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { Text } from './text';
import styles from './text_section.module.scss';

export const TextSection = ({ title, sections, className }) => {
	const classes = classNames.bind(styles)({
		texts: true,
		[className]: className
	});
	useStyles(styles);

	return (
		<article className={classes}>
			<h2
				style={{
					fontSize: '32px',
					lineHeight: '40px'
				}}
			>
				{title}
			</h2>
			{sections?.map(({ title, text, image }, i) => (
				<section key={i}>
					{!!title && <h3>{title}</h3>}
					{text && (
						<div className={styles.paragraphs}>
							{text?.map((paragraph, j) => (
								<Text dangerouslyHTML={{ __html: paragraph }} key={j} />
							))}
						</div>
					)}
					{!!image && <img alt={image.description} src={image.src} />}
				</section>
			))}
		</article>
	);
};
