export function FileCopyIcon({ color }) {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='var(--white)'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M6.89999 15.25C5.9335 15.25 5.14999 14.4665 5.14999 13.5V6.75C5.14999 5.64543 6.04542 4.75 7.14999 4.75H13.9C14.8665 4.75 15.65 5.5335 15.65 6.5'
				stroke={color || '#040407'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M9.14999 10.75C9.14999 9.64543 10.0454 8.75 11.15 8.75H17.65C18.7546 8.75 19.65 9.64543 19.65 10.75V17.25C19.65 18.3546 18.7546 19.25 17.65 19.25H11.15C10.0454 19.25 9.14999 18.3546 9.14999 17.25V10.75Z'
				stroke={color || '#040407'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
