// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".signed_out-module_authForm-xq7cT{height:100%}.signed_out-module_authForm-xq7cT .signed_out-module_signedOut-tpPas{height:100%}.signed_out-module_authForm-xq7cT .signed_out-module_title-UWena{margin-bottom:18px;font-size:24px;font-weight:bold;line-height:1.5;text-align:center}@media all and (min-width: 769px){.signed_out-module_authForm-xq7cT .signed_out-module_title-UWena{max-width:480px}}.signed_out-module_authForm-xq7cT .signed_out-module_spinner-unBt0{padding:20px 0;display:flex;justify-content:center;min-height:95vh;width:100%}", "",{"version":3,"sources":["webpack://./../../packages/components/my_account_page/signed_out.module.scss"],"names":[],"mappings":"AAEA,kCACC,WAAA,CACA,qEACC,WAAA,CAED,iEACC,kBAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CACA,iBAAA,CACA,kCAND,iEAOE,eAAA,CAAA,CAGF,mEACC,cAAA,CACA,YAAA,CACA,sBAAA,CACA,eAAA,CACA,UAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.authForm {\n\theight: 100%;\n\t.signedOut {\n\t\theight: 100%;\n\t}\n\t.title {\n\t\tmargin-bottom: 18px;\n\t\tfont-size: 24px;\n\t\tfont-weight: bold;\n\t\tline-height: 1.5;\n\t\ttext-align: center;\n\t\t@media all and (min-width: #{styles.$tablet + 1px}) {\n\t\t\tmax-width: 480px;\n\t\t}\n\t}\n\t.spinner {\n\t\tpadding: 20px 0;\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\tmin-height: 95vh;\n\t\twidth: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"authForm": "signed_out-module_authForm-xq7cT",
	"signedOut": "signed_out-module_signedOut-tpPas",
	"title": "signed_out-module_title-UWena",
	"spinner": "signed_out-module_spinner-unBt0"
};
module.exports = ___CSS_LOADER_EXPORT___;
