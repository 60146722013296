/**
 * Associate a "card brand" to a displayable name.
 * WARNING : Seems that the onlinePayment lambda returns lower cased card name
 * @link https://stripe.com/docs/api/cards/object#card_object-brand
 * @param name the card name that Stripe gives us
 * @returns {string} the logo file name
 */
export function formatCardName(name) {
	switch (name) {
		case 'amex':
			return 'American Express';
		case 'mastercard':
			return 'Mastercard';
		case 'visa':
			return 'Visa';
		case 'unionpay':
			return 'UnionPay';
		case 'dinersclub':
			return 'Diners Club';
		case 'discover':
			return 'Discover';
		case 'jcb':
			return 'JCB';
		case 'unknown':
		default:
			return 'Unknown';
	}
}

/**
 * Associate a "card brand" to a logo file.
 * WARNING : Seems that the onlinePayment lambda returns lower cased card name
 * @link https://stripe.com/docs/api/cards/object#card_object-brand
 * @param name the card name that Stripe gives us
 * @returns {string} the logo file name
 */
export function formatCardBrand(name) {
	switch (name) {
		case 'amex':
			return 'Amex';
		case 'mastercard':
			return 'Mastercard';
		case 'visa':
			return 'Visa';
		case 'unionpay':
		case 'dinersclub':
		case 'discover':
		case 'jcb':
		case 'unknown':
		default:
			return 'Unknown';
	}
}
