import { Button, Card } from '@planity/ui';
import styles from './appointment_empty.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';

export function AppointmentEmpty({ title, emptyText, localizedText, onPress }) {
	useStyles(styles);
	const classes = classNames.bind(styles);
	return (
		<Card title={title} hasTitleInside id='appointment-empty-title'>
			<p className={classes({ text: true })}>{emptyText}</p>
			{process.env.WIDGET && !process.env.WHITE_LABEL_WEBSITE ? null : (
				<Button
					label={localizedText}
					isFullMobile
					onClick={onPress}
					id={'appointment-empty-button'}
				/>
			)}
		</Card>
	);
}
