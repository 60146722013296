import { GooglePlacesSearch } from '@planity/components';
import { useRandomId } from '@planity/context';
import { getGooglePlace } from '@planity/datastores';
import { useLocalization, useTranslation } from '@planity/localization';
import { Drawer, MainSearchInput } from '@planity/ui';
import React, { forwardRef, useState } from 'react';
import styles from './where_search_bar.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';

export const WhereSearchBar = forwardRef(
	({ setWhereResult, displayOnlyOnDesktop, hide }, ref) => {
		useStyles(styles);
		const classes = classNames.bind(styles);

		const [whereQuery, setWhereQuery] = useState('');
		const [whereInputContent, setWhereInputContent] = useState('');
		const [isWhereDrawerOpen, setIsWhereDrawerOpen] = useState(false);
		const { googleSearchableCountries } = useLocalization();
		const { t } = useTranslation();
		const randomId = useRandomId();

		const onWhereBlur = event => {
			event.preventDefault();
			setIsWhereDrawerOpen(false);
		};
		const onWhereFocus = event => {
			event.preventDefault();
			setWhereQuery(whereInputContent);
			setIsWhereDrawerOpen(true);
		};
		const onWhereSelect = async prediction => {
			if (prediction) {
				getGooglePlace({
					placeId: prediction.place_id,
					prediction,
					countries: googleSearchableCountries
				}).then(place => {
					setWhereInputContent(place?.name || '');
					setWhereResult(place);
				});
			}
		};
		const onWhereChange = where => {
			setWhereInputContent(where || '');
			setWhereQuery(where || '');
		};

		return (
			<MainSearchInput
				id={'main-where-input_' + randomId}
				className={classes({
					whereSearchBar: true,
					displayOnlyOnDesktop,
					hide
				})}
				ref={ref}
				value={whereInputContent}
				onChange={onWhereChange}
				onFocus={onWhereFocus}
				onBlur={onWhereBlur}
				label={t('search.placeholders.placeShort')}
				placeholder={t('search.placeholders.place')}
				autoComplete={'new-password'}
				autoCorrect={'off'}
			>
				<GooglePlacesSearch query={whereQuery} format={formatWhere}>
					{({ places }) => (
						<Drawer
							id={'Google'}
							onSelect={onWhereSelect}
							className={classes({ whereDrawer: true })}
							items={places || []}
							isOpen={isWhereDrawerOpen}
							hasArrowKeysNavigation
						/>
					)}
				</GooglePlacesSearch>
			</MainSearchInput>
		);
	}
);

function formatWhere(places) {
	return places?.map(formatPlace);
}

function formatPlace(place) {
	if (!place) return null;
	const { structured_formatting } = place;
	const { main_text, secondary_text, main_text_matched_substrings } =
		structured_formatting;

	// Bold the matched text
	const main_text_formatted = main_text.split('').reduce((str, char, i) => {
		const needToOpenBracket = main_text_matched_substrings.find(
			({ offset }) => offset === i
		);
		const needToCloseBracket = main_text_matched_substrings.find(
			({ offset, length }) => offset + length === i
		);
		if (needToOpenBracket) {
			return str + '<b>' + char;
		}
		if (needToCloseBracket) return str + '</b>' + char;
		return str + char;
	}, '');
	return {
		...place,
		dangerousLabel: main_text_formatted,
		description: secondary_text
	};
}
