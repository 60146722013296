// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".details-module_inline-dtHFO{display:flex;align-items:center;justify-content:flex-start}.details-module_text-n18aS{line-height:24px;font-weight:400;font-size:14px;font:var(--regular) var(--body-4);color:var(--grey-600)}.details-module_icon-xFCDn{padding-right:4px;width:20px;height:20px}.details-module_info-Kz3PB{display:flex;flex-wrap:nowrap}.details-module_info-Kz3PB>div{padding-right:8px}.details-module_info-Kz3PB>:last-child{padding-right:0}.details-module_dot-1trHz{width:4px;height:4px;border-radius:50%;background:var(--grey-400)}", "",{"version":3,"sources":["webpack://./../../packages/components/my_account_page/appointment_preview/confirm_cancellation_modal/confirm_cancellation_modal_content/appointment_details/details.module.scss"],"names":[],"mappings":"AAGA,6BACC,YAAA,CACA,kBAAA,CACA,0BAAA,CAGD,2BACC,gBAAA,CACA,eAAA,CACA,cAAA,CACA,iCAAA,CACA,qBAAA,CAGD,2BACC,iBAAA,CACA,UAAA,CACA,WAAA,CAED,2BACC,YAAA,CACA,gBAAA,CACA,+BACC,iBAAA,CAED,uCACC,eAAA,CAUF,0BACC,SAAA,CACA,UAAA,CACA,iBAAA,CACA,0BAAA","sourcesContent":[".container {\n}\n\n.inline {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: flex-start;\n}\n\n.text {\n\tline-height: 24px;\n\tfont-weight: 400;\n\tfont-size: 14px;\n\tfont: var(--regular) var(--body-4);\n\tcolor: var(--grey-600);\n}\n\n.icon {\n\tpadding-right: 4px;\n\twidth: 20px;\n\theight: 20px;\n}\n.info {\n\tdisplay: flex;\n\tflex-wrap: nowrap;\n\t> div {\n\t\tpadding-right: 8px;\n\t}\n\t> :last-child {\n\t\tpadding-right: 0;\n\t}\n}\n\n.duration {\n}\n\n.price {\n}\n\n.dot {\n\twidth: 4px;\n\theight: 4px;\n\tborder-radius: 50%;\n\tbackground: var(--grey-400);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inline": "details-module_inline-dtHFO",
	"text": "details-module_text-n18aS",
	"icon": "details-module_icon-xFCDn",
	"info": "details-module_info-Kz3PB",
	"dot": "details-module_dot-1trHz"
};
module.exports = ___CSS_LOADER_EXPORT___;
