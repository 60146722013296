import React, { Component } from 'react';
import { getScrollPosition, scrollTo } from '@planity/helpers';
import FocusTrap from 'focus-trap-react';

export default class Modal extends Component {
	hasPreventedScroll = false;
	componentDidMount() {
		this.setBodyScroll(true);
	}
	componentDidUpdate(prevProps) {
		if (this.props.isDisabled !== prevProps.isDisabled) {
			this.setBodyScroll();
		}
	}
	componentWillUnmount() {
		this.allowBodyScroll();
	}
	render() {
		const { children, isDisabled } = this.props;
		return children(isDisabled ? this.disabledProps : this.enabledProps);
	}
	renderModal = isActive => modal =>
		this.props.skipFocusTrap ? (
			modal
		) : (
			<FocusTrap
				active={isActive}
				css={[styles.focusTrap]}
				focusTrapOptions={{
					escapeDeactivates: false
				}}
			>
				{modal}
			</FocusTrap>
		);
	setBodyScroll(isInitial) {
		if (this.props.isDisabled && !isInitial) {
			this.allowBodyScroll();
		} else if (!this.props.isDisabled) {
			this.preventBodyScroll();
		}
	}
	preventBodyScroll() {
		const { body } = document;
		body.style.top = `${getScrollPosition() * -1}px`;
		body.style.overflow = 'hidden';
		body.style.left = 0;
		body.style['-webkit-overflow-scrolling'] = 'touch';
		body.style.position = 'fixed';
		body.style.width = '100%';
		this.hasPreventedScroll = true;
	}
	allowBodyScroll() {
		if (this.hasPreventedScroll) {
			const { body } = document;
			const top = Math.abs(parseInt(body.style.top) || 0);
			body.style.overflow = 'auto';
			body.style.left = 'auto';
			body.style['-webkit-overflow-scrolling'] = 'auto';
			body.style.position = 'static';
			body.style.top = 'auto';
			body.style.width = 'auto';
			scrollTo({ top });
		}
	}
	enabledProps = {
		overlayStyle: styles.overlay,
		modalStyle: styles.modal,
		renderModal: this.renderModal(true)
	};
	disabledProps = {
		overlayStyle: styles.overlay,
		renderModal: this.renderModal(false)
	};
}

const styles = {
	overlay: {
		position: 'fixed',
		top: 0,
		right: 0,
		left: 0,
		height: '100vh'
	},
	focusTrap: {
		height: '100%'
	},
	modal: {
		WebkitOverflowScrolling: 'touch',
		overflowY: 'auto'
	}
};
