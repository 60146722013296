/* eslint-disable react/jsx-no-literals */
import {
	CGU_ACCEPTANCE_CHANGE,
	EMAIL_CHANGE,
	PASSWORD_CHANGE,
	PHONE_NUMBER_CHANGE,
	useFormHooks,
	Localize
} from '@planity/components';
import React from 'react';
import {
	Input,
	InputPassword,
	Phone,
	Checkbox,
	Button,
	InlineLink
} from '@planity/ui';
import classNames from 'classnames/bind';
// TODO Really ?
import styles from '../authentication/authentication.module.scss';
// import MemberConnection from '../member_connection';
import { MemberConnection } from '@planity/ui/components';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useUikit } from '@planity/ui/shared/useUikit';
import { checkInputError } from '@planity/components';
import {
	useTranslation,
	useLocalization,
	useLocalizedRoutes
} from '@planity/localization';
import { isNativeApp, sendToUserApp } from '@planity/webview';

export function SignUp({
	navigateLogState,
	data,
	localizedText,
	pending,
	phoneInput,
	handleDispatchEvent,
	handleDispatchInputs
}) {
	const { language, locale } = useLocalization();
	const { routes } = useLocalizedRoutes();

	const PRIVACY_URL = `https://policies.google.com/privacy?hl=${language}`;
	const TERMS_URL = `https://policies.google.com/terms?hl=${language}`;

	const { formValues } = useFormHooks();
	const { authBooking } = formValues;
	const classes = classNames.bind(styles)({
		auth: true,
		connection: authBooking
	});
	useStyles(styles);
	const { toggle, toggleIcon } = useUikit();
	const { t } = useTranslation();
	const handleClickLink = (e, link) => {
		e.preventDefault();
		sendToUserApp({
			type: 'SHOW_MODAL',
			payload: link
		});
	};
	return (
		<div className={`${classes} ${styles.signup}`} id='signup-comp'>
			{!authBooking && <h1 className={styles.title}>{localizedText.title}</h1>}

			<form className={styles.form}>
				<div className={styles.fields}>
					{phoneInput ? (
						phoneInput
					) : (
						<Phone
							error={checkInputError('phoneNumber', t, data)?.format}
							errorMessage={checkInputError('phoneNumber', t, data)?.message}
							isRequired={true}
							label={localizedText.labels.phoneNumber}
							name='phone_number'
							placeholder={localizedText.placeholders.phoneNumber}
							value={data && data.phoneNumber}
							onChange={event =>
								handleDispatchInputs({
									type: PHONE_NUMBER_CHANGE,
									phoneNumber: event
								})
							}
						/>
					)}
					<Input
						error={checkInputError('email', t, data)?.format}
						errorMessage={checkInputError('email', t, data)?.message} //&& errors.email.message
						id={'signup-comp__input-email'}
						isRequired={true}
						label={localizedText.labels.email}
						name='email'
						placeholder={localizedText.placeholders.email}
						type='email'
						value={data && data.email}
						onChange={email =>
							handleDispatchInputs({ type: EMAIL_CHANGE, email })
						}
					/>
					<InputPassword
						error={checkInputError('password', t, data)?.format}
						errorMessage={checkInputError('password', t, data)?.message}
						icon={toggle ? 'Eye' : 'EyeOff'}
						id={'signup-comp__input-password'}
						isRequired={true}
						label={localizedText.labels.password}
						name='password'
						placeholder={localizedText.placeholders.password}
						toggle={toggle}
						value={data && data.password}
						onChange={password =>
							handleDispatchInputs({ type: PASSWORD_CHANGE, password })
						}
						onToggleIcon={toggleIcon}
					/>
					{!data.skipPasswordConfirmation && (
						<InputPassword
							error={checkInputError('passwordConfirmation', t, data)?.format}
							errorMessage={
								checkInputError('passwordConfirmation', t, data)?.message
							}
							id={'signup-comp__input-password-confirmation'}
							isRequired={true}
							label={localizedText.labels.passwordConfirmation}
							name='password_confirmation'
							placeholder={localizedText.placeholders.passwordConfirmation}
							value={data && data.passwordConfirmation}
							onChange={password =>
								handleDispatchInputs({ type: PASSWORD_CHANGE, password })
							}
						/>
					)}
				</div>
				<div className={styles.extra}>
					<Checkbox
						checked={data && data.cguAcceptance}
						errorMessage={checkInputError('cguAcceptance', t, data)?.message}
						id={'signup-comp__cehckbox'}
						label={
							<>
								{localizedText.labels.checkbox.accept}
								&nbsp;
								<InlineLink
									className={styles.cgu}
									id={'signup-comp__inline-link'}
									label={localizedText.labels.checkbox.cgu}
									target={'_target'}
									to={isNativeApp ? null : localizedText.labels.checkbox.link}
									onClick={
										isNativeApp ? e => handleClickLink(e, t('links.cgu')) : null
									}
								/>
								&nbsp;
								{!process.env.WIDGET && localizedText.labels.checkbox.from}
							</>
						}
						name='cgu_acceptance'
						onChange={e => {
							handleDispatchInputs({
								type: CGU_ACCEPTANCE_CHANGE,
								cguAcceptance: e.target.checked
							});
						}}
					/>
				</div>

				<Button
					className={styles.submit}
					id={'signup-comp__submit'}
					isDisabled={pending}
					isFullMobile
					isLoading={pending}
					label={localizedText.labels.button}
					size='large'
					onClick={event =>
						handleDispatchEvent({ event: event, type: 'signup' })
					}
				/>
				<div className={styles.recaptcha}>
					<span>
						<Localize text={'auth.policy.text1'} />
						<InlineLink
							className={styles.recaptchaLink}
							label={t('auth.policy.privacyPolicy')}
							target={'_blank'}
							to={isNativeApp ? null : routes.privacyPolicy}
							onClick={
								isNativeApp
									? e =>
											handleClickLink(
												e,
												locale === 'fr-BE'
													? t('links.confidentialityPolicyBE')
													: t('links.confidentialityPolicy')
											)
									: null
							}
						/>
						<Localize text={'auth.recaptcha.text1'} />
						<InlineLink
							className={styles.recaptchaLink}
							label={t('auth.recaptcha.privacyPolicy')}
							target={'_target'}
							to={isNativeApp ? null : PRIVACY_URL}
							onClick={
								isNativeApp ? e => handleClickLink(e, PRIVACY_URL) : null
							}
						/>
						<Localize text={'auth.recaptcha.text2'} />
						<InlineLink
							className={styles.recaptchaLink}
							label={t('auth.recaptcha.termsOfUse')}
							target={'_target'}
							to={isNativeApp ? null : TERMS_URL}
							onClick={isNativeApp ? e => handleClickLink(e, TERMS_URL) : null}
						/>
						<Localize text={'auth.recaptcha.text3'} />
					</span>
				</div>
			</form>

			<MemberConnection
				id={'signup-comp__memberBtn'}
				label={localizedText.memberConnection.label}
				navigateLogState={() => navigateLogState('login')}
				title={localizedText.memberConnection.title}
			/>
		</div>
	);
}
