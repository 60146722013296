import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './menu.module.scss';
import { Logo, Button, Icon } from '@planity/ui';
import { Link } from 'react-router-dom';
import {
	useLocalizedRoutes,
	useTranslation,
	useLocalization
} from '@planity/localization';
import { useAuth } from '@planity/components';
import { useAppBanner, useOverlay } from '@planity/context';
import config from '@planity/credentials';

export function Menu({ items = [] }) {
	useStyles(styles);
	const { t } = useTranslation();
	const { routes } = useLocalizedRoutes();
	const { user, isLoading: userIsLoading } = useAuth();
	const { setIsOpen } = useOverlay();
	const { countryCodeIsBlacklisted } = useAppBanner();
	const { locale } = useLocalization();
	const isGermany = locale === 'de-DE';

	return (
		<div className={styles.menu}>
			<div className={styles.close} onClick={() => setIsOpen(false)}>
				<Icon icon='Close' size={'medium'} />
			</div>
			<div className={styles.items}>
				<Button
					isLoading={userIsLoading}
					to={routes.myAccount}
					className={styles.button}
					size='mobile'
					label={user ? user.firstName : t('auth.form.buttons.SIGN_IN')}
					useEllipsis
					id='mobile-menu-my-account'
				/>
				<Button
					className={styles.button}
					size='mobile'
					label={t('nav.becomePartner')}
					type='tertiary'
					isExternal
					to={`${routes.becomePartner}?utm_source=planity&utm_campaign=menumobile`}
				/>
				{items.map((item, i) => (
					<Link
						to={item.link}
						key={item.label}
						className={styles.item}
						aria-current={i === item.isActive}
					>
						{item.label}
					</Link>
				))}
				{isGermany && (
					<a
						href={routes.mag}
						className={styles.item}
						rel='noreferrer'
						target='_blank'
					>
						{t('nav.mag')}
					</a>
				)}
			</div>
			{!countryCodeIsBlacklisted && (
				<a className={styles.push} href={config.USER_APP_LINK}>
					<Logo size='56' type='app' />
					<span>{t('header.mobileMenu.appLinkText')}</span>
				</a>
			)}
		</div>
	);
}
