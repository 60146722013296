import { sendAnalytics } from './helper';

const eventCategory = 'SearchPage';
const eventAction = 'search_by_date';
const PICK_SERVICE_FROM_TAG = 'PICK_SERVICE_FROM_TAG';
const OPEN_DATEPICKER = 'OPEN_DATEPICKER';
const PICK_DATE_FROM_NUMBER = 'PICK_DATE_FROM_NUMBER';
const PICK_DATE_FROM_LABEL = 'PICK_DATE_FROM_LABEL';
const PICK_BUSINESS = 'PICK_BUSINESS';
const TIMESLOT_MATCHES_SEARCH = 'TIMESLOT_MATCHES_SEARCH';
const TIMESLOT_DOES_NOT_MATCH_SEARCH = 'TIMESLOT_DOES_NOT_MATCH_SEARCH';
const USERS_BOOKING_MATCHES_SEARCH_DATE = 'USERS_BOOKING_MATCHES_SEARCH_DATE';
const USERS_BOOKING_DOES_NOT_MATCH_SEARCH_DATE =
	'USERS_BOOKING_DOES_NOT_MATCH_SEARCH_DATE';

export const sendPickServiceFromTagEvent = sendAnalytics(
	PICK_SERVICE_FROM_TAG,
	eventCategory,
	eventAction
);
export const sendOpenDatePickerEvent = sendAnalytics(
	OPEN_DATEPICKER,
	eventCategory,
	eventAction
);
export const sendPickDateFromNumberEvent = sendAnalytics(
	PICK_DATE_FROM_NUMBER,
	eventCategory,
	eventAction
);
export const sendPickDateFromLabelEvent = sendAnalytics(
	PICK_DATE_FROM_LABEL,
	eventCategory,
	eventAction
);
export const sendPickBusinessFromAvailabilities = sendAnalytics(
	PICK_BUSINESS,
	eventCategory,
	eventAction
);
export const sendTimeslotMatchesSearch = sendAnalytics(
	TIMESLOT_MATCHES_SEARCH,
	eventCategory,
	eventAction
);
export const sendTimeslotDoesNotMatchSearch = sendAnalytics(
	TIMESLOT_DOES_NOT_MATCH_SEARCH,
	eventCategory,
	eventAction
);
export const sendBookingMatchesSearchDate = sendAnalytics(
	USERS_BOOKING_MATCHES_SEARCH_DATE,
	eventCategory,
	eventAction
);
export const sendBookingDoesNotMatchSearchDate = sendAnalytics(
	USERS_BOOKING_DOES_NOT_MATCH_SEARCH_DATE,
	eventCategory,
	eventAction
);
