export function Eye() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='25'
			height='24'
			viewBox='0 0 25 24'
			fill='none'
		>
			<path
				d='M19.65 12C19.65 13 17.9 18.25 12.4 18.25C6.89999 18.25 5.14999 13 5.14999 12C5.14999 11 6.89999 5.75 12.4 5.75C17.9 5.75 19.65 11 19.65 12Z'
				stroke='#08080B'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M14.65 12C14.65 13.2426 13.6426 14.25 12.4 14.25C11.1574 14.25 10.15 13.2426 10.15 12C10.15 10.7574 11.1574 9.75 12.4 9.75C13.6426 9.75 14.65 10.7574 14.65 12Z'
				stroke='#08080B'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
