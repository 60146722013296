// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".confirmation-module_confirmation-PSmxL{margin-top:15px;display:flex;justify-content:center;flex-direction:column;color:var(--white);font:var(--medium) var(--body-4)}@media only screen and (min-width: 768px)and (max-width: 1079px){.confirmation-module_confirmation-PSmxL{margin-top:18px}}.confirmation-module_container-fW-mq{display:flex;align-items:center;flex-direction:column;text-align:center}.confirmation-module_container-fW-mq .confirmation-module_deleteLink-WiXlx{margin-top:20px;font:var(--medium) var(--body-4);color:var(--grey-700)}", "",{"version":3,"sources":["webpack://./../../packages/components/book_appointment/confirmation.module.scss"],"names":[],"mappings":"AAEA,wCACC,eAAA,CACA,YAAA,CACA,sBAAA,CACA,qBAAA,CACA,kBAAA,CACA,gCAAA,CACA,iEAPD,wCAQE,eAAA,CAAA,CAGF,qCACC,YAAA,CACA,kBAAA,CACA,qBAAA,CACA,iBAAA,CAEA,2EACC,eAAA,CACA,gCAAA,CACA,qBAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.confirmation {\n\tmargin-top: 15px;\n\tdisplay: flex;\n\tjustify-content: center;\n\tflex-direction: column;\n\tcolor: var(--white);\n\tfont: var(--medium) var(--body-4);\n\t@media only screen and (min-width: styles.$tablet) and (max-width: #{styles.$desktop - 1px}) {\n\t\tmargin-top: 18px;\n\t}\n}\n.container {\n\tdisplay: flex;\n\talign-items: center;\n\tflex-direction: column;\n\ttext-align: center;\n\n\t.deleteLink {\n\t\tmargin-top: 20px;\n\t\tfont: var(--medium) var(--body-4);\n\t\tcolor: var(--grey-700);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"confirmation": "confirmation-module_confirmation-PSmxL",
	"container": "confirmation-module_container-fW-mq",
	"deleteLink": "confirmation-module_deleteLink-WiXlx"
};
module.exports = ___CSS_LOADER_EXPORT___;
