import { identity } from '@planity/helpers';

export function minutesFromTimeString(time) {
	let parts = time.replace(/\s/g, '').split(/[^\d]/);
	return (parseInt(parts[0]) || 0) * 60 + (parseInt(parts[1]) || 0);
}

/**
 *
 * @param int {number} a number representing minutes
 * @param t {function} the translate function
 * @return {string}
 */
export function formatDuration(int, t = identity) {
	const hours = Math.floor(int / 60);
	const minutes = int % 60;
	const hoursFormat =
		hours === 0 ? '' : t('common.durations.short.hours', { hour: hours });
	const minutesFormat =
		minutes === 0 ? '' : t('common.durations.short.minutes', { min: minutes });
	const space = hours === 0 || minutes === 0 ? '' : ' ';
	return `${hoursFormat}${space}${minutesFormat}`;
}

export function getDateFromString(string) {
	let [day, time] = string.split(' ');
	if (time) {
		const [hours, minutes] = time.split(':').map(x => +x);
		const [year, month, date] = day.split('-').map(x => +x);
		const d = new Date(year, month - 1, date, hours, minutes);
		return d.getTime();
	} else {
		const [year, month, date] = day.split('-').map(x => +x);
		const d = new Date(year, month - 1, date);
		return d.getTime();
	}
}
