import { useErrorMessages } from '@planity/components';
import { Button } from '@planity/ui';
import { isNativeApp, sendToUserApp } from '@planity/webview';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useCallback } from 'react';
import { useSearch } from '@planity/context';
import styles from './around_me.module.scss';
import { useTranslation } from '@planity/localization';

export const AroundMe = ({ category }) => {
	const { setSearch } = useSearch();
	const classes = classNames.bind(styles)({
		aroundMe: true
	});
	useStyles(styles);
	const { t } = useTranslation();
	const { pushMessage } = useErrorMessages();

	const onClick = useCallback(() => {
		if (isNativeApp) {
			return sendToUserApp({ type: 'GEOLOCATION', payload: null });
		}
		if (!navigator?.geolocation)
			return console.error('No navigator?.geolocation available');
		navigator?.geolocation?.getCurrentPosition(
			position =>
				setSearch(
					{
						googlePlace: null,
						place: null,
						parentPlace: null,
						category,
						userLocation: {
							lat: position.coords.latitude,
							lng: position.coords.longitude
						}
					},
					null,
					true,
					'push'
				),
			e => {
				pushMessage({
					message: 'search.geolocationError',
					type: 'error'
				});
				console.error(e);
			}
		);
	}, [category]);
	return (
		<Button
			className={classes}
			iconLeft={'Crosshair'}
			label={t(`category.searchAroundMe`)}
			size={'mobile'}
			type={'primary'}
			onClick={onClick}
		/>
	);
};
