import React from 'react';

export function Gift({ outlined }) {
	return (
		<svg
			width='25'
			height='24'
			viewBox='0 0 25 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M6.54999 11.25H19.05V17.25C19.05 18.3546 18.1546 19.25 17.05 19.25H8.54999C7.44542 19.25 6.54999 18.3546 6.54999 17.25V11.25Z'
				stroke={!outlined ? 'currentColor' : 'none'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M5.54999 7.75H20.05V11.25H5.54999V7.75Z'
				stroke={!outlined ? 'currentColor' : 'none'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M12.8 19V11.5'
				stroke={!outlined ? 'currentColor' : 'none'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M13.3 7.5L16.05 4.75'
				stroke={!outlined ? 'currentColor' : 'none'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M12.3 7.5L9.54999 4.75'
				stroke={!outlined ? 'currentColor' : 'none'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
