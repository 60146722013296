import { identity } from '@planity/helpers';
import { useLocalization } from '@planity/localization';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import CreateFetcher from '../create_fetcher';
import { autocompletePlaces } from '@planity/datastores';

const TIME_BETWEEN_TWO_QUERIES = 200;

export function GooglePlacesSearch({
	query,
	types,
	format = identity,
	children
}) {
	const { googleSearchableCountries: countries } = useLocalization();
	const [debouncedQuery, setDebouncedQuery] = useState(query);
	const debounceQuery = useCallback(
		debounce(query => setDebouncedQuery(query), TIME_BETWEEN_TWO_QUERIES),
		[]
	);

	useEffect(() => {
		debounceQuery(query);
		return debounceQuery?.cancel;
	}, [query]);

	return (
		<CreateFetcher
			query={debouncedQuery}
			subscribe={({ emit }) => {
				const queryWas = debouncedQuery;
				autocompletePlaces(debouncedQuery, countries, types).then(places => {
					if (debouncedQuery === queryWas) {
						emit({ places: format(places) });
					}
				});
				return () => {};
			}}
		>
			{children}
		</CreateFetcher>
	);
}
