import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './badge.module.scss';
import { Icon } from '@planity/ui';

export function Badge({ label = 'Lorem', iconLeft, iconRight, className, id }) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		badge: true,
		[className]: className !== undefined
	});

	return (
		<div className={classes} id={id || 'badge-ui'}>
			<Icon className={styles.iconLeft} icon={iconLeft} />
			<span className={styles.label}>{label}</span>
			<Icon className={styles.iconRight} icon={iconRight} />
		</div>
	);
}
