import React from 'react';

export default function ElleLogo() {
	return (
		<svg
			height='18'
			viewBox='0 0 45 18'
			width='45'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M1.371 17.691V.265H0V0h7.409v4.749h-.225s-.02-2.752-.921-3.648C5.485.346 4.85.265 3.52.265v8.336s.921.061 1.372-.387c.716-.693.777-1.59.777-2.833h.246v6.726h-.246c0-1.244-.082-2.14-.777-2.833-.45-.449-1.372-.388-1.372-.388v8.785c1.33 0 2.15-.061 2.927-.836.921-.897.921-3.648.921-3.648h.225v4.77H0v-.266h1.371zM16.21.265v17.426c1.33 0 2.149-.081 2.926-.835.9-.897.921-3.649.921-3.649h.225v4.77H12.69v-.265h1.372V.245h-1.372V0h4.769v.265H16.21zm12.198 0v17.426c1.33 0 2.149-.081 2.926-.835.901-.897.921-3.649.921-3.649h.226v4.77h-7.594v-.265h1.372V.245h-1.372V0h4.77v.265h-1.25zm10.356 17.426V.265h-1.371V0H44.8v4.749h-.225s-.02-2.752-.92-3.648c-.778-.755-1.413-.836-2.743-.836v8.336s.92.061 1.371-.387c.716-.693.778-1.59.778-2.833h.245v6.726h-.245c0-1.244-.082-2.14-.778-2.833-.45-.449-1.371-.388-1.371-.388v8.785c1.33 0 2.149-.061 2.927-.836.92-.897.92-3.648.92-3.648h.226v4.77h-7.593v-.266h1.37z'
				fill='#ffff'
				fillRule='evenodd'
			/>
		</svg>
	);
}
