import React from 'react';

export default function Form({ children, onSubmit, className }) {
	return (
		<form
			className={className}
			onSubmit={e => {
				if (e && e.preventDefault) e.preventDefault();
				if (onSubmit) onSubmit();
			}}
		>
			{children}
		</form>
	);
}
