import React from 'react';
import {
	AuthenticateWithToken,
	FirebaseSubscription,
	LambdaInvocation
} from '@planity/components';
import { reviewHasExpired } from './appointment_preview/review';
import { safeRead } from '@planity/helpers';

export function WithKeyAuth({ reviewKey, userId, children }) {
	// server-side or missing review key
	if (!process.env.BROWSER || !reviewKey) {
		// review key present: display loading children
		// review key absent: display children
		return children({ isLoading: !!reviewKey });
	}

	// client side with review key: load data related to review key
	return (
		// listen to pending reviews
		<FirebaseSubscription path={`pending_reviews_keys/${reviewKey}`}>
			{({ data: pendingReviewInfo, isLoading }) => {
				// no pending review data: either firebase data is loading or no review => display children
				// (loading or not)
				if (!pendingReviewInfo) return children({ isLoading });
				// fetch review token
				return (
					<LambdaInvocation
						lambda={'getReviewUserToken'}
						payload={{
							customerId: pendingReviewInfo.customerId,
							appointmentId: pendingReviewInfo.appointmentId,
							key: reviewKey
						}}
					>
						{({ isLoading: lambdaIsLoading, response } = {}) => {
							// process lambda response
							if (lambdaIsLoading) return children({ isLoading: true });

							// response contains an appointment for which a review can be posted
							if (!safeRead(response, ['vevent', 'key'])) {
								return children({});
							}

							// appointment is more than 6 days ago and has not been reviewed
							if (reviewHasExpired(response.vevent)) {
								return children({ reviewDateHasPassed: true });
							}
							return (
								<AuthenticateWithToken token={response?.token}>
									{children({
										isLoading: lambdaIsLoading
											? true
											: userId !== response.customerId,
										appointment: response?.vevent && {
											...response.vevent,
											// this is for consistency purpose - original sequence and sequence are similar in this flow (the email flow) - in the other flow (directly from the my account page), sequence is different thus we create an original sequence for obvious reasons
											originalSequence: response.vevent.sequence
										},
										appointmentId:
											response.appointmentId || pendingReviewInfo.appointmentId
									})}
								</AuthenticateWithToken>
							);
						}}
					</LambdaInvocation>
				);
			}}
		</FirebaseSubscription>
	);
}
