import { fetchExposedProductsForClickAndCollect } from '@planity/components/click_and_collect/fetchExposedProductsForClickAndCollect';
import { computeSortedProducts } from '../clickAndCollect/clickAndCollect';
import credentials from '@planity/credentials';

export const clickAndCollectIsEnabled = business => {
	return !!(
		JSON.parse(credentials.ENABLE_CLICK_AND_COLLECT) &&
		business?.modules?.clickAndCollect &&
		business?.settings?.clickAndCollect?.status
	);
};

export const clickAndShopIsEnabled = business => {
	return !!(
		JSON.parse(credentials.ENABLE_CLICK_AND_SHOP) &&
		business?.modules?.clickAndCollect &&
		business?.settings?.clickAndShop?.status
	);
};

export const fetchProducts = async businessId => {
	const fetchedProducts = await fetchExposedProductsForClickAndCollect(
		businessId
	);

	// The business probably has no exposed products
	// else, an error occured so let's just hide the click & collect tab
	if (fetchedProducts.errorMessage) {
		return {};
	}

	const sortedCacProducts = computeSortedProducts(
		fetchedProducts.clickAndCollectProducts
	);
	const sortedCasProducts = computeSortedProducts(
		fetchedProducts.clickAndShopProducts
	);

	return {
		clickAndCollectProducts: sortedCacProducts,
		clickAndShopProducts: sortedCasProducts
	};
};

export const shouldDisplayClickAndCollect = async (business, businessId) => {
	const clickAndCollectEnabled = clickAndCollectIsEnabled(business);
	const clickAndShopEnabled = clickAndShopIsEnabled(business);

	const { clickAndCollectProducts, clickAndShopProducts } =
		clickAndCollectEnabled || clickAndShopEnabled
			? await fetchProducts(businessId)
			: { clickAndCollectProducts: {}, clickAndShopProducts: {} };

	const displayClickAndCollect =
		clickAndCollectEnabled &&
		Object.keys(clickAndCollectProducts || {}).length > 0;

	const displayClickAndShop =
		clickAndShopEnabled && Object.keys(clickAndShopProducts || {}).length > 0;

	return {
		displayClickAndCollect,
		displayClickAndShop,
		clickAndCollectProducts,
		clickAndShopProducts
	};
};

export const shouldDisplayClickAndCollectSync = business => {
	const clickAndCollectEnabled = clickAndCollectIsEnabled(business);
	const clickAndShopEnabled = clickAndShopIsEnabled(business);
	return {
		displayClickAndCollect: !!clickAndCollectEnabled,
		displayClickAndShop: !!clickAndShopEnabled,
		clickAndCollectProducts: {},
		clickAndShopProducts: {}
	};
};
