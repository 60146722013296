export function Email() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
		>
			<path
				d='M3.95837 6.45835C3.95837 5.53788 4.70457 4.79169 5.62504 4.79169H14.375C15.2955 4.79169 16.0417 5.53788 16.0417 6.45835V13.5417C16.0417 14.4622 15.2955 15.2084 14.375 15.2084H5.62504C4.70457 15.2084 3.95837 14.4622 3.95837 13.5417V6.45835Z'
				stroke='#4E5860'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M4.58337 5.41669L10 10.2084L15.4167 5.41669'
				stroke='#4E5860'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
