import useStyles from 'isomorphic-style-loader/useStyles';
import { useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './input_password.module.scss';
import { Icon } from '@planity/ui';
import { useUikit } from '@planity/ui/shared/useUikit';
import { noop } from '@planity/helpers';
import React from 'react';

export function InputPassword({
	autoFocus = false,
	label,
	placeholder,
	defaultValue,
	name,
	icon,
	value,
	isDisabled = false,
	isRequired = false,
	error = false,
	errorMessage = 'Invalid field',
	type = 'password',
	toggle,
	onChange = noop,
	onToggleIcon = noop,
	id
}) {
	useStyles(styles);
	const input = useRef();

	// States

	const { dispatchError, isFocused, hasError, setIsFocused } = useUikit();

	// Classes

	const classes = classNames.bind(styles)({
		password: true
	});

	// useEffect
	useEffect(() => {
		dispatchError(error);
	}, [dispatchError, error]);

	// Render

	const renderLabel = label && (
		<span className={styles.label} id='label-input-password-field'>
			{label} {isRequired && '*'}
		</span>
	);

	const renderField = (
		<div
			id='input-password-field'
			className={classNames.bind(styles)(
				{
					container: true,
					isDisabled: isDisabled,
					isFocused: isFocused,
					isInvalid: hasError
				},
				'planity-inputs'
			)}
		>
			{icon && (
				<Icon
					id='icon-input-password-field'
					className={styles.icon}
					icon={icon}
					onClick={onToggleIcon}
					size={'medium'}
				/>
			)}
			<input
				autoFocus={autoFocus}
				id={id || 'input-password'}
				type={!toggle ? type : 'text'}
				ref={input}
				name={name}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
				value={value || ''}
				disabled={isDisabled}
				required={isRequired}
				placeholder={placeholder}
				defaultValue={defaultValue}
				autoComplete='password'
				className={styles.field}
				onChange={e => onChange(e.currentTarget.value)}
			/>
		</div>
	);

	const renderError = !isDisabled && hasError && errorMessage && (
		<span id='error-input-password-field' className={styles.error}>
			{errorMessage}
		</span>
	);

	return (
		<div className={classes} id='input-password-field-comp'>
			<label>{renderLabel}</label>
			{renderField}
			{renderError}
		</div>
	);
}
