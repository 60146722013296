import { computeFees, formatPrice } from './price';

export function totalAppointmentPrice(
	onlinePaymentServices,
	includeFees,
	stripeFees = {},
	serviceIdsWithEligibleCures = []
) {
	// Get max value of all online payment services' deposit rate
	const depositRateToApply = Math.max.apply(
		null,
		onlinePaymentServices.isOnline.map(
			service => (service.onlinePayment && service.cancelRate) || 0
		)
	);
	const { totalPrice, _depositPrice, totalPriceServicesWithEligibleCures } =
		onlinePaymentServices.isOnline.reduce(
			(acc, service) => {
				const { prices, id } = service;
				if (!prices) return acc;

				const price = prices.default || prices.min || prices.max || 0;
				const isEligible = serviceIdsWithEligibleCures.includes(id);

				if (isEligible) {
					acc.totalPriceServicesWithEligibleCures += price;
				}

				acc._depositPrice += price * depositRateToApply;
				acc.totalPrice += price;

				return acc;
			},
			{
				totalPrice: 0,
				_depositPrice: 0,
				totalPriceServicesWithEligibleCures: 0
			}
		);

	const depositPrice = _depositPrice / 100;

	if (includeFees) {
		return {
			totalPriceWithEligibleCures: formatPrice(
				totalPrice -
					totalPriceServicesWithEligibleCures +
					computeFees({
						amount: totalPrice - totalPriceServicesWithEligibleCures,
						stripeFees
					}),
				true
			),
			totalPrice: formatPrice(
				totalPrice +
					computeFees({
						amount: totalPrice,
						stripeFees
					}),
				true
			),
			totalPriceWithoutFees: Math.round(totalPrice),
			depositPrice: formatPrice(
				depositPrice +
					computeFees({
						amount: depositPrice,
						stripeFees
					}),
				true
			),
			depositPriceWithoutFees: Math.round(depositPrice),
			depositRest: formatPrice(totalPrice - depositPrice, true),
			depositRateToApply
		};
	}

	return {
		totalPriceWithEligibleCures: formatPrice(
			totalPrice - totalPriceServicesWithEligibleCures,
			true
		),
		totalPrice: formatPrice(totalPrice, true),
		depositPrice: formatPrice(depositPrice, true),
		depositRest: formatPrice(totalPrice - depositPrice, true),
		totalPriceWithoutFees: Math.round(totalPrice),
		depositPriceWithoutFees: Math.round(depositPrice),
		depositRateToApply
	};
}
