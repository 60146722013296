import React from 'react';

export const EG = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5651)'>
				<mask
					id='mask0_11534_5651'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5651)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#F7FCFF'
					/>
					<mask
						id='mask1_11534_5651'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_11534_5651)'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V5H20V0H0Z'
							fill='#BF2714'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 10V15H20V10H0Z'
							fill='#272727'
						/>
						<path
							d='M7.6627 6.38763L8.28705 6.41626L8.13033 9.83488L7.50598 9.80626L7.6627 6.38763Z'
							fill='#C09302'
						/>
						<path
							d='M8.31505 6.59982L8.93905 6.6351L8.78233 9.40718L8.15833 9.3719L8.31505 6.59982Z'
							fill='#C09302'
						/>
						<path
							d='M8.96777 6.80743L9.59068 6.85854L9.43396 8.76855L8.81105 8.71744L8.96777 6.80743Z'
							fill='#C09302'
						/>
						<path
							d='M12.3373 6.38763L11.7129 6.41626L11.8697 9.83488L12.494 9.80626L12.3373 6.38763Z'
							fill='#C09302'
						/>
						<path
							d='M11.6849 6.59982L11.0609 6.6351L11.2177 9.40718L11.8417 9.3719L11.6849 6.59982Z'
							fill='#C09302'
						/>
						<path
							d='M11.0322 6.80743L10.4093 6.85854L10.566 8.76855L11.1889 8.71744L11.0322 6.80743Z'
							fill='#C09302'
						/>
						<path
							d='M9.98334 9.57917L9.37412 9.43959L9.63198 8.31415L10.2412 8.45373L9.98334 9.57917Z'
							fill='#C09302'
						/>
						<path
							d='M10.4497 9.57917L11.0589 9.43959L10.801 8.31415L10.1918 8.45373L10.4497 9.57917Z'
							fill='#C09302'
						/>
						<path
							d='M10.2951 9.56885H9.6701V8.4639H10.2951V9.56885Z'
							fill='#C09302'
						/>
						<path
							d='M9.14672 6.82077L9.77153 6.8361L9.73503 8.32416L9.11021 8.30883L9.14672 6.82077Z'
							fill='#C09302'
						/>
						<path
							d='M10.2337 6.82077L10.8585 6.8361L10.822 8.32416L10.1972 8.30883L10.2337 6.82077Z'
							fill='#C09302'
						/>
						<path
							d='M8.33623 9.95176L8.49854 9.34821C9.1617 9.52655 9.67089 9.61475 10.0181 9.61475C10.3653 9.61475 10.8745 9.52655 11.5377 9.34821L11.7 9.95176C10.9885 10.1431 10.4305 10.2397 10.0181 10.2397C9.60567 10.2397 9.04772 10.1431 8.33623 9.95176Z'
							fill='#C09302'
						/>
						<path
							d='M7.67644 6.38919C7.63478 5.97631 7.87643 5.69683 8.28597 5.73247C8.42063 5.74419 8.57187 5.78403 8.77332 5.85238C8.83217 5.87257 8.83217 5.87257 8.88938 5.89282C9.16631 5.99132 9.2415 6.01663 9.25711 6.01997C9.19575 6.00607 9.37648 5.67934 9.41794 5.62427C9.42284 5.65924 9.40204 5.65426 9.35208 5.64426L8.7883 5.53143L9.18963 5.11971C9.3299 4.9758 9.51671 4.90444 9.72989 4.90444C9.75199 4.90444 9.77261 4.90341 9.84034 4.89924C9.89042 4.8962 9.89042 4.8962 9.94673 4.89387C10.3367 4.88123 10.6164 4.99151 10.6164 5.39254C10.6164 5.53601 10.6345 5.76673 10.6644 5.94176C10.6745 6.00064 10.6939 6.05575 10.6366 6.07996C10.6389 6.05907 10.7755 6.00517 10.9879 5.91634L11.0134 5.90568C11.6306 5.64809 11.7027 5.6242 11.9016 5.7451C12.0342 5.82567 12.1604 5.97059 12.3019 6.18666L11.7789 6.52897C11.6877 6.3896 11.6151 6.30504 11.5805 6.28142C11.6591 6.33605 11.3943 6.42398 11.2542 6.48246L11.2291 6.49295C10.7486 6.69389 10.6853 6.71787 10.5479 6.71787C10.174 6.71787 10.0397 6.33489 10.003 5.78642C9.88764 6.16616 9.54347 6.64537 9.25987 6.64537C9.13571 6.64537 9.1954 6.66502 8.67993 6.48168C8.62593 6.46256 8.62593 6.46256 8.57252 6.44424C8.41635 6.39126 8.3037 6.36154 8.23262 6.35519C8.23556 6.35184 8.24602 6.34058 8.25776 6.33053L7.67644 6.38919Z'
							fill='#C09302'
						/>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5651'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
