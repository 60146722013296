import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './breadcrumbs.module.scss';
import { Icon } from '@planity/ui';
import { Link } from 'react-router-dom';

export function Breadcrumbs({
	className,
	items = [
		{
			label: 'lorem',
			to: '/',
			iconLeft: false,
			iconRight: false
		}
	]
}) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		breadcrumbs: true,
		[className]: className !== undefined
	});

	const renderContent = item => (
		<>
			<Icon className={styles.iconLeft} icon={item.iconLeft} />
			<span>{item.label}</span>
			<Icon className={styles.iconRight} icon={item.iconRight} />
		</>
	);

	const renderLink = (item, i) => (
		<Link
			to={item.to}
			className={styles.link}
			aria-current={i === items.length - 1}
		>
			{renderContent(item)}
		</Link>
	);

	const renderSpan = (item, i) => (
		<span className={styles.link} aria-current={i === items.length - 1}>
			{renderContent(item)}
		</span>
	);

	return (
		<div className={classes}>
			<div className={styles.items}>
				{items.map((item, i) => (
					<div className={styles.item} key={item.label}>
						{item.to ? renderLink(item, i) : renderSpan(item, i)}
						{i !== items.length - 1 && <Icon icon='ChevronRight' />}
					</div>
				))}
			</div>
		</div>
	);
}
