import React from 'react';
import ItemProductsList from '../generic_item/item_products_list';
import { OrderProduct } from '@planity/ui';

export default function ClickAndCollectProductsList({ products, business }) {
	return (
		<ItemProductsList
			productItemComponent={({ product }) => (
				<OrderProduct pictures={business.pictures} product={product} />
			)}
			products={products}
		/>
	);
}
