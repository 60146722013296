import { APPOINTMENT_SOURCE, useAppointmentSource } from '@planity/components';
import { prepareSequence } from '@planity/helpers';
import { useTranslation } from '@planity/localization';
import {
	AppointmentDate,
	Button,
	StepDescription,
	AppointmentSequence
} from '@planity/ui';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useMemo } from 'react';
import styles from './business_appointment.module.scss';

export function BusinessAppointment({
	business,
	appointment,
	appointmentStart,
	canBeCancelled,
	isBookable,
	linkToBookAppointment,
	isVisible,
	sequence,
	seeMore
}) {
	useStyles(styles);
	const { changeSource } = useAppointmentSource();
	const { t } = useTranslation();
	const preparedSequence = useMemo(
		() => prepareSequence(appointment)?.sequence,
		[appointment]
	);

	const handleOnBookAgainClick = changeSource => {
		changeSource(APPOINTMENT_SOURCE.BOOK_AGAIN.PROFILE);
		linkToBookAppointment({
			business: { ...business, isBooking: true },
			sequence: Object.keys(sequence || {}).map(id => sequence[id])
		});
	};
	const classes = classNames.bind(styles);
	return (
		<div className={classes({ appointment: true })}>
			<div className={classes({ upperContainer: true })}>
				<AppointmentDate
					appointmentStart={appointmentStart}
					className={styles.date}
				/>
				<ul className={classes({ items: true })}>
					<AppointmentSequence
						appointment={appointment}
						onSeeMoreClick={seeMore}
						isVisible={isVisible}
						collapseSteps
					>
						{preparedSequence?.map((step, index) => (
							<StepDescription
								canBeCancelled={canBeCancelled}
								business={business}
								key={step.stepId}
								step={step}
								twoRows
								withEllipsis
								index={index}
							/>
						))}
					</AppointmentSequence>
				</ul>
			</div>

			<div className={classes({ bookAgainContainer: true })}>
				{!!linkToBookAppointment && (
					<Button
						label={t('myAccount.appointment.bookAgain')}
						isFullMobile
						size='small'
						onClick={() => handleOnBookAgainClick(changeSource)}
						isDisabled={!isBookable}
						id={'appointment-book-again-or-move-button'}
						className={classes({ bookAgainButton: true })}
					/>
				)}
			</div>
		</div>
	);
}
