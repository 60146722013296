import React, { createContext, useContext } from 'react';

export const ThemeContext = createContext({});
export const useTheme = () => useContext(ThemeContext);

export const withTheme = Component => props =>
	(
		<ThemeContext.Consumer>
			{({ isDark, singlePageApp }) => (
				<Component
					{...props}
					hasDarkBackground={isDark}
					singlePageApp={singlePageApp}
				/>
			)}
		</ThemeContext.Consumer>
	);

export const GIFT_VOUCHER_BLOC = 'giftVoucher';
export const MY_ACCOUNT_BLOC = 'myAccount';
export const BOOK_APPOINTMENT_BLOC = 'bookAppointment';
export const ONLINE_SHOP_BLOC = 'onlineShop';
export const DESCRIPTION_BLOC = 'description';
