import { useOverlay } from '@planity/context';
import { Menu } from '@planity/ui';
import React, { useEffect, useRef, useReducer, useState } from 'react';
import styles from './menu_overlay.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import {
	getScrollPosition,
	mEasings as easings,
	mTween as tween,
	scrollTo
} from '@planity/helpers';

/**
	The menu and overlay animations could be achieved in CSS using transition
	which is notoriously janky in Chrome IOS :
	- https://bugs.chromium.org/p/chromium/issues/detail?id=899130
	- https://bugs.chromium.org/p/chromium/issues/detail?id=1249723
	- https://bugs.chromium.org/p/chromium/issues/detail?id=1231712
	That is why the animation is done in JS
*/

export const MenuOverlay = ({ navItems }) => {
	useStyles(styles);
	const { isOpen, setIsOpen } = useOverlay();
	const [bodyStyle, setBodyStyle] = useState({});

	useEffect(() => {
		if (isOpen) {
			preventBodyScroll(setBodyStyle);
		}
		return () => allowBodyScroll(bodyStyle);
	}, [isOpen]);

	/**
	 * We have two values to animate : overlay opacity and menu translation
	 * To avoid complex parallel tweenings we'll just use one range 0 -> 1
	 * and compute opacity and translation from that with :
	 * translation = value * -311
	 * opacity = 1 + (value * -1)
	 * Closed is 1 : translation = -311px / opacity = 0
	 * Open is 0 : translation = 0 / opacity = 1
	 */
	const animValue = useRef(1);
	/**
	 * Keep a ref to isOpen to read it in animation effect
	 * Without depending on it which would rerun the effect
	 */
	const currentIsOpen = useRef(isOpen);
	/**
	 * Hook equivalent of this.forceUpdate
	 */
	const [, forceUpdate] = useReducer(x => x + 1, 0);

	const classes = classNames.bind(styles)({
		menuOverlay: true,
		isOpen
	});

	useEffect(() => {
		// animate when isOpen is toggled
		currentIsOpen.current = isOpen; // update our currentIsOpen ref
		tween(
			// interpolate between current anim value and target
			200, // tweening duration
			// tweening callback -> yield animation effect
			state => {
				// we only want to yield effect if isOpen hasn't been toggled in the meantime
				if (currentIsOpen.current === isOpen) {
					animValue.current = state; // update anim value ref
					requestAnimationFrame(() => {
						forceUpdate(); // forceUpdate when the UI is responsive. allows batching if needed
					});
				}
			},
			() => {}, // end of tweening callback
			easings.swingTo, // easing function
			animValue.current, // tweening start value
			isOpen ? 0 : 1 // tweening target value
		);
		return () => {
			cancelAnimationFrame(animValue.current);
		};
	}, [isOpen]);

	return (
		<div
			className={classes}
			onClick={() => setIsOpen(false)}
			style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
		>
			<div
				className={styles.menu}
				style={{ transform: `translateX(${animValue.current * -311}px)` }}
			>
				<Menu
					isOpen={isOpen}
					items={navItems}
					menuIsOpen={() => setIsOpen(!isOpen)}
				/>
			</div>
			<div
				className={styles.overlay}
				style={{ opacity: 1 + animValue.current * -1 }}
			/>
		</div>
	);
};

function allowBodyScroll(bodyStyle) {
	const { body } = document;
	const isPrevented = !!body.dataset.hasPreventedScroll;
	if (isPrevented) {
		const top = Math.abs(parseInt(body.style.top) || 0);
		body.style = bodyStyle;

		scrollTo({ top });
		delete body.dataset.hasPreventedScroll;
	}
}

function preventBodyScroll(setBodyStyle) {
	const { body } = document;
	setBodyStyle(body.style);
	body.style.top = `${getScrollPosition() * -1}px`;
	body.style.overflow = 'hidden';
	body.style.left = '0';
	body.style['-webkit-overflow-scrolling'] = 'touch';
	body.style.position = 'fixed';
	body.style.width = '100%';
	body.dataset.hasPreventedScroll = 'true';
}
