import React, { Children, Component, createContext, useContext } from 'react';

const LastSuccessfulAppointmentContext = createContext();
export const useLastSuccessfulAppointment = () =>
	useContext(LastSuccessfulAppointmentContext);

export class LastSuccessfulAppointmentProvider extends Component {
	setLastSuccessfulAppointment = (
		{ lastSuccessfulAppointmentId, appointmentMoved, products },
		cb
	) => {
		this.setState(
			{
				lastSuccessfulAppointmentId,
				appointmentMoved,
				products
			},
			cb
		);
	};
	state = {
		appointmentMoved: false,
		lastSuccessfulAppointmentId: null,
		setLastSuccessfulAppointment: this.setLastSuccessfulAppointment
	};
	render() {
		return (
			<LastSuccessfulAppointmentContext.Provider value={this.state}>
				{Children.only(this.props.children)}
			</LastSuccessfulAppointmentContext.Provider>
		);
	}
}

export const WithLastSuccessfulAppointment =
	LastSuccessfulAppointmentContext.Consumer;

export const withLastSuccessfulAppointment = WrappedComponent => props => {
	return (
		<WithLastSuccessfulAppointment>
			{appointmentProps => (
				<WrappedComponent {...props} {...appointmentProps} />
			)}
		</WithLastSuccessfulAppointment>
	);
};
