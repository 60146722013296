// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".past_appointments-module_allLoaded-58AWJ{opacity:.6}.past_appointments-module_footer-KcOdd{display:grid;place-items:center;margin-top:32px}", "",{"version":3,"sources":["webpack://./../../packages/components/my_account_page/past_appointments.module.scss"],"names":[],"mappings":"AAAA,0CACC,UAAA,CAGD,uCACC,YAAA,CACA,kBAAA,CACA,eAAA","sourcesContent":[".allLoaded {\n\topacity: 0.6;\n}\n\n.footer {\n\tdisplay: grid;\n\tplace-items: center;\n\tmargin-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"allLoaded": "past_appointments-module_allLoaded-58AWJ",
	"footer": "past_appointments-module_footer-KcOdd"
};
module.exports = ___CSS_LOADER_EXPORT___;
