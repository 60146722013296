import React, { Component, createContext, useContext } from 'react';

const CacheContext = createContext({});

export class CacheContextProvider extends Component {
	constructor(props) {
		super(props);
		this.state = {
			get: this.get,
			set: this.set,
			...this.hydrate()
		};
	}
	render() {
		return (
			<CacheContext.Provider value={this.state}>
				{this.props.children}
			</CacheContext.Provider>
		);
	}
	hydrate() {
		if (
			typeof window !== 'undefined' &&
			window._planity_isHydrating &&
			window._planity_localStates
		) {
			return window._planity_localStates;
		} else {
			return {};
		}
	}
	get = key => this.state[key];
	set = (key, value) => this.setState({ [key]: value });
}

export const WithCache = CacheContext.Consumer;
export const useCache = () => useContext(CacheContext);
