import React from 'react';

export const CY = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5631)'>
				<mask
					id='mask0_11534_5631'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5631)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#F7FCFF'
					/>
					<mask
						id='mask1_11534_5631'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_11534_5631)'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M15.3546 2.9745C15.3546 2.9745 12.0532 4.79827 11.6377 4.91367C11.2221 5.02903 11.4994 5.09835 10.7835 5.05222C10.068 5.00604 8.65973 5.30969 8.33656 5.10192C8.01332 4.89401 7.89788 4.52471 7.87495 5.14815C7.85179 5.77131 8.3599 6.30229 7.71326 6.34852C7.0671 6.39461 6.99767 5.79425 6.55923 6.16384C6.12055 6.53305 5.75114 7.36422 5.47399 7.15645C5.19701 6.94854 4.80467 6.39461 4.92011 6.92559C5.03531 7.45648 5.38165 8.79373 6.4898 8.81687C7.59805 8.84005 7.8287 8.44752 7.87495 8.77078C7.92097 9.09386 8.4059 9.13999 8.52119 8.72446C8.6368 8.30893 9.58326 9.18789 10.0911 8.74926C10.5989 8.31073 11.0144 7.94129 11.1762 7.68734C11.3377 7.43339 11.8918 7.47967 12.4227 7.47967C12.9537 7.47967 13.7384 7.71043 13.3229 7.20268C12.9076 6.69459 12.1918 6.46388 12.6075 5.95598C13.0228 5.44814 16.5494 3.75944 16.7111 3.57471C16.8726 3.39004 15.8393 2.69751 15.3546 2.9745Z'
							fill='#F57A01'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M15.3546 2.9745C15.3546 2.9745 12.0532 4.79827 11.6377 4.91367C11.2221 5.02903 11.4994 5.09835 10.7835 5.05222C10.068 5.00604 8.65973 5.30969 8.33656 5.10192C8.01332 4.89401 7.89788 4.52471 7.87495 5.14815C7.85179 5.77131 8.3599 6.30229 7.71326 6.34852C7.0671 6.39461 6.99767 5.79425 6.55923 6.16384C6.12055 6.53305 5.75114 7.36422 5.47399 7.15645C5.19701 6.94854 4.80467 6.39461 4.92011 6.92559C5.03531 7.45648 5.38165 8.79373 6.4898 8.81687C7.59805 8.84005 7.8287 8.44752 7.87495 8.77078C7.92097 9.09386 8.4059 9.13999 8.52119 8.72446C8.6368 8.30893 9.58326 9.18789 10.0911 8.74926C10.5989 8.31073 11.0144 7.94129 11.1762 7.68734C11.3377 7.43339 11.8918 7.47967 12.4227 7.47967C12.9537 7.47967 13.7384 7.71043 13.3229 7.20268C12.9076 6.69459 12.1918 6.46388 12.6075 5.95598C13.0228 5.44814 16.5494 3.75944 16.7111 3.57471C16.8726 3.39004 15.8393 2.69751 15.3546 2.9745Z'
							fill='url(#paint0_linear_11534_5631)'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M6.8201 10.0651C8.45898 10.0651 9.86729 11.8889 9.86729 11.8889C9.89098 11.9126 9.91286 11.9356 9.93303 11.958C9.95321 11.9356 9.97509 11.9126 9.99878 11.8889C9.99878 11.8889 11.6414 10.0651 13.2804 10.0651C14.9194 10.0651 12.0994 12.72 10.2758 12.72C10.2758 12.72 10.0883 12.7029 9.93303 12.6249C9.77776 12.7029 9.59032 12.72 9.59032 12.72C7.76657 12.72 5.1809 10.0651 6.8201 10.0651Z'
							fill='#006B49'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M6.8201 10.0651C8.45898 10.0651 9.86729 11.8889 9.86729 11.8889C9.89098 11.9126 9.91286 11.9356 9.93303 11.958C9.95321 11.9356 9.97509 11.9126 9.99878 11.8889C9.99878 11.8889 11.6414 10.0651 13.2804 10.0651C14.9194 10.0651 12.0994 12.72 10.2758 12.72C10.2758 12.72 10.0883 12.7029 9.93303 12.6249C9.77776 12.7029 9.59032 12.72 9.59032 12.72C7.76657 12.72 5.1809 10.0651 6.8201 10.0651Z'
							fill='url(#paint1_linear_11534_5631)'
						/>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<linearGradient
					id='paint0_linear_11534_5631'
					x1='18.75'
					y1='11.25'
					x2='18.75'
					y2='1.25'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#EA5113' />
					<stop offset='1' stopColor='#FC9B58' />
				</linearGradient>
				<linearGradient
					id='paint1_linear_11534_5631'
					x1='13.7736'
					y1='12.72'
					x2='13.7736'
					y2='10.0651'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#008057' />
					<stop offset='1' stopColor='#00B77C' />
				</linearGradient>
				<clipPath id='clip0_11534_5631'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
