import { assign, createMachine } from 'xstate';
import buyGiftVoucher from './buy_gift_voucher';

const initialData = {
	giftVoucherPrice: 0,
	totalPrice: 0,
	totalPriceInCents: 0,
	selectedServices: [],
	selectedTemplate: null,
	typeSelected: null,
	targetMail: '',
	message: '',
	variant: null
};

export const giftVoucherMachine = createMachine(
	{
		id: 'giftVouchers',
		initial: 'servicesSelection',
		context: {
			completedSteps: ['INIT'],
			data: initialData,
			errors: null
		},
		states: {
			servicesSelection: {
				initial: 'normal',
				states: {
					normal: {},
					error: {}
				},
				on: {
					SERVICES_SELECTED: [
						{
							target: 'personalization',
							cond: 'servicesSelectionIsValid'
						},
						{
							target: '.error',
							actions: ['onError']
						}
					]
				}
			},
			personalization: {
				onEntry: ['setCompletedSteps'],
				on: {
					PERSONALIZATION_DONE: 'authentification',
					AUTH_SUCCESS: 'creditCard'
				}
			},
			authentification: {
				onEntry: ['setCompletedSteps'],
				on: {
					AUTH_SUCCESS: 'creditCard'
				}
			},
			creditCard: {
				onEntry: ['setCompletedSteps'],
				on: {
					CREDIT_CARD_SUCCESS: 'pay',
					LOGOUT: 'authentification'
				}
			},
			pay: {
				onEntry: ['setCompletedSteps'],
				initial: 'normal',
				states: {
					normal: {},
					error: {}
				},
				on: {
					SUBMIT: {
						target: 'paying',
						actions: ['savePaymentIntentId']
					},
					LOGOUT: 'authentification',
					AUTH_FAIL: {
						target: '.error',
						actions: ['onError']
					}
				}
			},
			paying: {
				onEntry: ['setCompletedSteps'],
				invoke: {
					src: (context, event) =>
						buyGiftVoucher({
							...context.data,
							...event.data
						}),
					onDone: {
						target: 'confirmed'
					},
					onError: [
						{
							target: 'pay.error',
							actions: ['onError', 'savePaymentIntentId']
						}
					]
				}
			},
			confirmed: {
				onEntry: ['setCompletedSteps'],
				type: 'final'
			}
		},
		on: {
			RESET: {
				target: 'servicesSelection',
				actions: ['resetCompletedSteps']
			},
			SOFT_RESET: {
				target: 'servicesSelection',
				actions: ['softResetCompletedSteps']
			},
			ON_CHANGE: {
				actions: ['onChange'],
				internal: true
			},
			TEMPLATE_CHOSE: {
				actions: ['setCompletedSteps']
			},
			REINJECT_DATA: {
				target: 'pay',
				actions: ['setCompletedSteps', 'onChange']
			},
			RESET_PAYMENT_INTENT_ID: {
				actions: ['savePaymentIntentId']
			},
			RESET_PAYMENT_ERROR: {
				target: 'pay.normal'
			}
		}
	},
	{
		actions: {
			onChange: assign({
				data: (context, event) => {
					return {
						...context.data,
						...event.data
					};
				}
			}),
			onError: assign({
				errors: (ctx, e) => e.data
			}),
			setCompletedSteps: assign({
				completedSteps: (_, event) => {
					_.completedSteps.indexOf(event.type) === -1 &&
						_.completedSteps.push(event.type);

					if (event.type === 'LOGOUT') {
						_.completedSteps.splice(4);
						//_.completedSteps.splice(3);
					}

					return _.completedSteps;
				}
			}),
			resetCompletedSteps: assign({
				completedSteps: () => {
					return ['INIT'];
				},
				data: (context, event) => {
					return {
						...initialData,
						...event.data
					};
				}
			}),
			softResetCompletedSteps: assign({
				completedSteps: () => {
					return ['INIT'];
				}
			}),
			savePaymentIntentId: assign({
				data: (context, event) => ({
					...context.data,
					paymentIntentId: event.data ? event.data.paymentIntentId : null
				})
			})
		},
		guards: {
			servicesSelectionIsValid: context => {
				return context.data.selectedServices.length > 0;
			}
		}
	}
);
