import credentials from '@planity/credentials';
import { AVAILABLE_LANGUAGES, useLocalization } from '@planity/localization';
import React, { createContext, useContext, useMemo } from 'react';
import capitalize from 'lodash/capitalize';
import AlgoliaSearch from './algolia_search';

const CATEGORIES_HIGHLIGHT_ATTRIBUTES = [];
const LOCALIZED_ATTRIBUTES = [
	'name',
	'slug',
	'singular',
	'isFeminine',
	'texts',
	'landing',
	'title',
	'gender',
	'demonstrativeAdjective'
];

export default function WithCategories({ children }) {
	const { language } = useLocalization();

	// Take "not my languages" and mark every attribute "not to retrieve"
	// eg: '-name_de', '-slug_de'...
	const attributesToNotRetrieve = useMemo(
		() =>
			AVAILABLE_LANGUAGES.reduce(
				(all, lng) =>
					lng === language
						? all
						: all.concat(
								...LOCALIZED_ATTRIBUTES.map(attr => `-${attr}_${lng}`)
						  ),
				[]
			),
		[language, AVAILABLE_LANGUAGES]
	);

	return (
		<AlgoliaSearch
			attributesToHighlight={CATEGORIES_HIGHLIGHT_ATTRIBUTES}
			attributesToRetrieve={['*'].concat(attributesToNotRetrieve)}
			filters={'depth:1'}
			format={formatCategories}
			index={credentials.CATEGORIES_INDEX}
			localizeResults
			useCache
		>
			{({ data }) => children(data)}
		</AlgoliaSearch>
	);
}

export function withCategories(WrappedComponent) {
	return props => (
		<WithCategories>
			{categories => (
				<WrappedComponent categories={categories || []} {...props} />
			)}
		</WithCategories>
	);
}

function formatCategories(categories) {
	return categories
		.sort((x, y) => x.sort - y.sort)
		.map(category => ({
			...category,
			name: capitalize(category.singular)
		}));
}

const CategoriesContext = createContext();

export function CategoriesContextProvider({ children }) {
	return (
		<WithCategories>
			{categories => (
				<CategoriesContext.Provider value={{ categories }}>
					{children}
				</CategoriesContext.Provider>
			)}
		</WithCategories>
	);
}

export const useCategories = () => useContext(CategoriesContext);
