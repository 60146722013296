import { useStripeFees } from '@planity/components/app_settings/stripeFeesProvider';
import { firebase } from '@planity/datastores';
import {
	businessOnlinePaymentStatus,
	computeFees,
	getFinalFees,
	IS_DEPOSIT,
	noop,
	PAYMENT_TYPE_CARD,
	prepareSequence
} from '@planity/helpers';
import { invokeLambda } from '@planity/helpers/browser';
import { uglyPriceComputation } from '@planity/ui';
import { useAuth } from '@planity/components';
import { useEffect, useState } from 'react';
import chunk from 'lodash/chunk';

const NUMBER_OF_ONLINE_PAYMENTS = 5;
export const useOnlinePaymentFetcher = ({
	appointments,
	businesses,
	hasLoaded
}) => {
	const [appointmentsWithOnlinePayment, setAppointmentsWithOnlinePayment] =
		useState({});
	const { userId } = useAuth();

	const [isLoading, setIsLoading] = useState(true);
	const { allStripeFees } = useStripeFees();

	useEffect(() => {
		if (hasLoaded && !Object.values(appointments || {}).length) {
			setIsLoading(false);
		}
	}, [hasLoaded, appointments]);

	useEffect(() => {
		// Cleanup effect
		setAppointmentsWithOnlinePayment({});
	}, [userId]);
	useEffect(() => {
		const filteredAppointments = Object.fromEntries(
			Object.entries(appointments || {}).filter(
				([id, _]) => !appointmentsWithOnlinePayment[id]
			)
		);
		const sanitizedAppointments = sanitizeAppointments(filteredAppointments);
		if (hasLoaded) {
			setIsLoading(true);
			withOnlinePaymentData({
				appointments: sanitizedAppointments,
				allStripeFees,
				businesses
			}).then(res => {
				if (Object.keys(res).length) {
					setAppointmentsWithOnlinePayment(prev => ({ ...prev, ...res }));
					setIsLoading(false);
				}
			});
		}
	}, [
		JSON.stringify(appointments),
		allStripeFees,
		JSON.stringify(businesses),
		hasLoaded
	]);

	return {
		onlinePaymentData: appointmentsWithOnlinePayment,
		onlinePaymentDataIsLoading: isLoading
	};
};

/**
 * Takes appointments as input and makes the computation for onlinePayment stuff.
 * Fetches onlinePayment, computes, ask stripe etc
 * @param appointments {Object}
 * @param allStripeFees {Object}
 * @param businesses {Object}
 * @return {Promise<Object>} appointments, with online payment data.
 */
export const withOnlinePaymentData = async ({
	appointments,
	allStripeFees,
	businesses
}) => {
	// Split appointments that have a chargeId vs those that do not.
	// Those with chargeId will call lambda `onlinePayment` whereas those that do not already have data
	const { withChargeId, withoutChargeId } = Object.entries(appointments).reduce(
		(all, [id, appointment]) => {
			const chargeId = extractChargeId(appointment);
			if (chargeId) {
				all.withChargeId.push({
					...appointment,
					id,
					chargeId
				});
			} else {
				all.withoutChargeId.push({ id, ...appointment });
			}
			return all;
		},
		{ withChargeId: [], withoutChargeId: [] }
	);

	const firebaseToken = await firebase.auth().currentUser?.getIdToken();

	if (!firebaseToken) return Promise.resolve(appointments);
	const chunks = chunk(withChargeId || [], NUMBER_OF_ONLINE_PAYMENTS);
	const onlinePaymentResponse = (
		await Promise.all(
			chunks.map(async chunk => {
				return await invokeLambda('onlinePayment', {
					firebaseToken,
					actions: chunk.map(formatOnlinePaymentPayload)
				});
			})
		)
	).flat();

	// Map onlinePayment response to appointment. `chargeId` is the primary key.
	const withOnlinePaymentCall = withChargeId.reduce((all, appointment) => {
		const onlinePaymentData = onlinePaymentResponse.find(
			({ response }) => response?.id === appointment.chargeId
		);
		if (onlinePaymentData)
			all[appointment.id] = {
				...formatOnlinePaymentResponse(onlinePaymentData.response)
				// we should only return online payment data and not the full appointment because it won't be up to date if changes happen in the appointment
				// and onlinePaymentData overrides all data about appointments in mergeAppointmentsData
				//...appointment,
			};
		return all;
	}, {});

	// These appointments already have everything to know fees, price etc.
	const withoutOnlinePaymentCall = withoutChargeId.reduce(
		(all, appointment) => {
			const { businessId, id, userPaysFees = null } = appointment;
			const business = businesses[businessId];

			// compute price of each step
			const stepsPrice = computeStepsPrice(appointment, business);

			// when taking an appointment with imprint,
			// chargeId only exists after payment, so we need to get stripe fees
			const stripeFeesForImprint = getFinalFees(
				allStripeFees,
				business?.countryCode,
				PAYMENT_TYPE_CARD
			);

			const feesAmount = userPaysFees
				? computeFees({
						amount: stepsPrice,
						stripeFees: stripeFeesForImprint
				  })
				: 0;

			all[id] = {
				// we should only return online payment data and not the full appointment because it won't be up to date if changes happen in the appointment
				// and onlinePaymentData overrides all data about appointments in mergeAppointmentsData
				//...appointment,
				paidAmount: stepsPrice + feesAmount,
				feesAmount
			};
			return all;
		},
		{}
	);

	return {
		...withOnlinePaymentCall,
		...withoutOnlinePaymentCall
	};
};

function computeStepsPrice(appointment, business) {
	const status = businessOnlinePaymentStatus(business);
	const preparedAppointment = prepareSequence(appointment);

	return (
		(preparedAppointment.sequence || []).reduce(
			(total, { price = 0, serviceOriginPrice = 0, cancelRate, isDeposit }) => {
				const finalPrice = serviceOriginPrice || price;
				// seems to be an old fix for appointments with 2 or more services
				// where one has online payment and the other one has not
				if (status === IS_DEPOSIT && isDeposit === 'partial') {
					total += (finalPrice * cancelRate) / 100;
					return total;
				}
				return total + finalPrice;
			},
			0
		) || uglyPriceComputation(preparedAppointment, business, noop)
	);
}

function formatOnlinePaymentPayload(appointment) {
	return {
		chargeId: appointment.chargeId,
		businessId: appointment.businessId,
		type: 'charge.retrieve_actual_charge'
	};
}

const formatOnlinePaymentResponse = response => {
	const paidAmount = response?.amount || null;
	const feesAmount = response?.application_fee_amount || null;
	const paymentCreatedAt = response?.created || null;
	const depositType = (response?.metadata?.transactionType || '').match(
		/Deposit/
	)
		? response?.metadata?.transactionType.split('Deposit')[0]
		: null;

	return { paidAmount, feesAmount, paymentCreatedAt, depositType };
};

function extractChargeId({ chargeId, sequence }) {
	const appointmentSequenceData = Object.values(sequence || {})?.[0];
	return appointmentSequenceData?.chargeId || chargeId;
}

function sanitizeAppointments(appointments) {
	return Object.entries(appointments).reduce((all, [id, cur]) => {
		if (!cur) return all;
		if (!cur.sequence) return all;
		all[id] = cur;
		return all;
	}, {});
}
