// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".highlight-module_highlight-xCrJI{margin-bottom:0}", "",{"version":3,"sources":["webpack://./app/components/category_page/highlight.module.scss"],"names":[],"mappings":"AAAA,kCACC,eAAA","sourcesContent":[".highlight {\n\tmargin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlight": "highlight-module_highlight-xCrJI"
};
module.exports = ___CSS_LOADER_EXPORT___;
