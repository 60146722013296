import { withLocalizedRoutes } from '@planity/localization';
import { withAuth, withLastSuccessfulAppointment } from '@planity/components';
import { Children, Component } from 'react';
import { withRouter } from 'react-router-dom';

export const CleanLastSuccessfulAppointmentOnAuthChange = withRouter(
	withLocalizedRoutes(
		withAuth(
			withLastSuccessfulAppointment(
				class extends Component {
					componentDidUpdate(prevProps) {
						if (
							this.props.userId !== prevProps.userId ||
							(prevProps.location.pathname === this.props.routes.myAccount &&
								this.props.location.pathname !== prevProps.location.pathname)
						) {
							this.props.setLastSuccessfulAppointment({
								lastSuccessfulAppointmentId: null,
								appointmentMoved: false
							});
						}
					}

					render() {
						return Children.only(this.props.children);
					}
				}
			)
		)
	)
);
