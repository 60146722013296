import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './nav_button.module.scss';
import { Icon } from '@planity/ui';

export function NavButton({
	progress,
	label,
	icon = 'ChevronRight',
	isMirrored = false,
	onClick,
	className,
	isWhite,
	isDisabled
}) {
	useStyles(styles);
	// const [progress, setProgress] = useState(0);

	const classes = classNames.bind(styles)({
		navButton: true,
		isMirrored,
		isWhite,
		[className]: className !== undefined
	});

	return (
		<button
			className={classes}
			aria-label={label}
			onClick={onClick}
			disabled={isDisabled}
		>
			<svg
				className={styles.circle}
				width='36'
				height='36'
				viewBox='0 0 36 36'
				version='1.1'
				xmlns='http://www.w3.org/2000/svg'
			>
				<circle
					className={styles.contour}
					r='17'
					cx='18'
					cy='18'
					pathLength='98'
				></circle>
				<circle
					className={`${styles.progress} ${progress && styles.progressNext}`}
					r='17'
					cx='18'
					cy='18'
					pathLength='98'
				></circle>
			</svg>
			<Icon className={styles.icon} icon={icon} size={'medium'} />
		</button>
	);
}
