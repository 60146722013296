import React from 'react';
import { centsToPrice } from '@planity/helpers';
import { DeliverySentence, Localize } from '@planity/components';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './cart.module.scss';

export function Cart({
	articles,
	itemComponent: ItemComponent,
	isClickAndShop,
	shippingFees,
	explanationSentence
}) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		container: true
	});
	if (articles.length <= 0) return null;
	return (
		<div className={classes}>
			<div>
				{articles.map((article, index) => (
					<ItemComponent
						id={`article-${index}`}
						index={index}
						item={article}
						key={`${article.id}-i`}
					/>
				))}
			</div>
			<div className={styles.shippingContainer}>
				<div
					className={styles.shippingDetails}
					id={'cart-shipping-time-details'}
				>
					<p id='cart-shipping-details'>
						<Localize
							text={
								isClickAndShop
									? 'clickAndCollect.clickAndShop.shippingPrice'
									: 'clickAndCollect.clickAndCollect.shippingPrice'
							}
						/>
					</p>
					{isClickAndShop ? (
						<p className={styles.shippingSeparator}>
							<DeliverySentence
								shippingExplanationSentence={explanationSentence}
							/>
						</p>
					) : null}
				</div>
				<span id={'cart-shipping-price'}>{centsToPrice(shippingFees)}</span>
			</div>
		</div>
	);
}
