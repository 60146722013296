import React from 'react';
import { Localize } from '@planity/components';
import { Button } from '@planity/ui';

export function DownloadPdfButton({
	text,
	onClick,
	isLoading,
	id,
	isTertiary,
	isFullMobile
}) {
	return (
		<Button
			className={'download-pdf-button'}
			iconLeft={'Download'}
			id={id}
			isDisabled={isLoading === id}
			isFullMobile={isFullMobile}
			isLoading={isLoading === id}
			label={<Localize text={text} />}
			normalWhiteSpace
			type={isTertiary ? 'tertiary' : 'primary'}
			onClick={() => {
				onClick();
			}}
		/>
	);
}
