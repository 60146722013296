// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app_banner-module_app_banner-kW44C{position:relative;top:0;padding:10px 16px;border-bottom:1px solid var(--grey-200)}@media only screen and (min-width: 768px){.app_banner-module_app_banner-kW44C{display:none}}.app_banner-module_app_banner-kW44C .app_banner-module_close-NHjl5{display:flex}.app_banner-module_app_banner-kW44C .app_banner-module_content-pI0Cs{display:grid;grid:1fr/min-content min-content auto min-content;gap:8px;align-items:center}.app_banner-module_app_banner-kW44C .app_banner-module_info-mZOIC{display:flex;flex-direction:column;flex-grow:1}.app_banner-module_app_banner-kW44C .app_banner-module_title-rY5Fn{font:var(--medium) var(--body-4)}.app_banner-module_app_banner-kW44C .app_banner-module_label-9pRuB{font:var(--regular) var(--body-5);color:var(--grey-600)}", "",{"version":3,"sources":["webpack://./../../packages/ui/uikit/indication/app_banner/app_banner.module.scss"],"names":[],"mappings":"AAEA,oCAIC,iBAAA,CACA,KAAA,CACA,iBAAA,CACA,uCAAA,CANA,0CADD,oCAEE,YAAA,CAAA,CAOD,mEACC,YAAA,CAGD,qEACC,YAAA,CACA,iDAAA,CACA,OAAA,CACA,kBAAA,CAGD,kEACC,YAAA,CACA,qBAAA,CACA,WAAA,CAGD,mEACC,gCAAA,CAGD,mEACC,iCAAA,CACA,qBAAA","sourcesContent":["@use '@planity/ui/styles/utilities/variables.scss';\n\n.app_banner {\n\t@media only screen and (min-width: variables.$tablet) {\n\t\tdisplay: none;\n\t}\n\tposition: relative;\n\ttop: 0;\n\tpadding: 10px 16px;\n\tborder-bottom: 1px solid var(--grey-200);\n\n\t.close {\n\t\tdisplay: flex;\n\t}\n\n\t.content {\n\t\tdisplay: grid;\n\t\tgrid: 1fr / min-content min-content auto min-content;\n\t\tgap: 8px;\n\t\talign-items: center;\n\t}\n\n\t.info {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tflex-grow: 1;\n\t}\n\n\t.title {\n\t\tfont: var(--medium) var(--body-4);\n\t}\n\n\t.label {\n\t\tfont: var(--regular) var(--body-5);\n\t\tcolor: var(--grey-600);\n\t}\n\n\t.icon {\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"app_banner": "app_banner-module_app_banner-kW44C",
	"close": "app_banner-module_close-NHjl5",
	"content": "app_banner-module_content-pI0Cs",
	"info": "app_banner-module_info-mZOIC",
	"title": "app_banner-module_title-rY5Fn",
	"label": "app_banner-module_label-9pRuB"
};
module.exports = ___CSS_LOADER_EXPORT___;
