import { useTranslation } from '@planity/localization';
import React from 'react';
import { getStepInfo, sanitizeName } from '@planity/helpers';
import { Localize } from '@planity/components';
import { Icon } from '@planity/ui';
import styles from './step_description.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';

export function StepDescription({
	canBeCancelled,
	step,
	business,
	withEllipsis,
	twoRows,
	noBorder
}) {
	useStyles(styles);
	const { t } = useTranslation();
	const { serviceName, duration, price, calendars } = getStepInfo(
		step,
		business,
		t,
		canBeCancelled
	);
	const classes = classNames.bind(styles);

	return (
		<li
			className={classes({
				item: true,
				twoRows,
				noBorder
			})}
		>
			<div
				className={classes({ itemService: true })}
				id='step-desc-service-name'
			>
				<Icon icon='Service' id='step-desc-service-name-icon' size={'small'} />
				<span
					className={classes({
						itemStepText: true,
						withEllipsis
					})}
					title={sanitizeName(serviceName)}
					id='step-desc-service-name-text'
				>
					{sanitizeName(serviceName)}
				</span>
			</div>
			{duration && (
				<span
					className={classes({
						itemDuration: true,
						twoRows
					})}
					id='step-desc-duration'
				>
					<Icon icon='Timer' id='step-desc-duration-icon' size={'small'} />
					<span id='step-desc-duration-text' className={styles.itemStepText}>
						{duration}
					</span>
				</span>
			)}
			{price && (
				<span id='step-desc-price'>
					<Icon
						className={classes({ bankNote: true })}
						icon='BankNote'
						id='step-desc-price-icon'
						size={'small'}
					/>
					<br />
					<p id='step-desc-price-text'>{price}</p>
				</span>
			)}
			{calendars && (
				<span
					className={classes({ itemCalendar: true })}
					id='step-desc-with-calendar'
				>
					<Icon
						icon='Calendar'
						id='step-desc-with-calendar-icon'
						size={'small'}
						strokeWidth={'1.25'}
					/>
					<span
						className={styles.itemStepText}
						id='step-desc-with-calendar-text'
					>
						<Localize
							args={{ calendars }}
							text={'myAccount.appointment.withCalendar'}
						/>
					</span>
				</span>
			)}
		</li>
	);
}
