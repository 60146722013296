import React from 'react';
import { capitalize } from '@planity/helpers';
import { useLocalization, useTranslation } from '@planity/localization';
import { withCategories } from 'planity-website/app/components/data/with_categories';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import classNames from 'classnames/bind';
import styles from './home_slideshow.module.scss';
import { Container, Image, Button, NavButton, useSwiper } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';

const RELEVANT_CATEGORIES_IDS = [
	'hair_care',
	'barber_shop',
	'nails',
	'beauty_salon'
];

export const HomeSlideshow = withCategories(({ categories, categoriesRef }) => {
	/**
	 show only well_being feature if process.env.WELL_BEING is true
	 */
	if (process.env.WELL_BEING) {
		RELEVANT_CATEGORIES_IDS.push('well_being');
	}

	useStyles(styles);
	const cx = classNames.bind(styles);
	const classes = classNames.bind(styles)({
		homeSlideshow: true
	});

	const relevantCategories = useMemo(
		() =>
			categories.filter(({ objectID }) =>
				RELEVANT_CATEGORIES_IDS.includes(objectID)
			),
		[categories]
	);

	const [progress, setProgress] = useState(false);
	const [current, setCurrent] = useState({ index: 0, reversed: false });
	const [isExtended, setIsExtended] = useState(null);
	const [slidingBlocked, setSlidingBlocked] = useState(false);
	const { localizedBasePath } = useLocalization();
	const { t } = useTranslation();
	const { onTouchEnd, onTouchMove, onTouchStart } = useSwiper();

	const content = useRef(null);
	const previousImage = useRef(null);
	const currentImage = useRef(null);
	const nextImage = useRef(null);

	const seeMore = useCallback(id => {
		setIsExtended(prev => (prev === id ? null : id));
		setProgress(false);
	}, []);

	const goTo = useCallback(
		(index = 0, reversed) => {
			const total = relevantCategories.length;
			index = ((index % total) + total) % total;
			setCurrent({ index, reversed });
			setProgress(false);
			setSlidingBlocked(true);

			setTimeout(() => {
				setSlidingBlocked(false);
			}, 1000);
		},
		[relevantCategories.length]
	);

	const next = useCallback(() => {
		goTo(current.index + 1, false);
	}, [goTo, current]);

	const previous = useCallback(() => {
		goTo(current.index - 1, true);
	}, [current, goTo]);

	useEffect(() => {
		requestAnimationFrame(() => {
			setProgress(true);
		});

		const timer = setTimeout(() => {
			next();
		}, 8000);

		return () => clearTimeout(timer);
	}, [current, next, progress, previous, seeMore]);

	const renderImage = ref => (
		<div
			ref={ref}
			className={styles.images}
			onTouchStart={onTouchStart}
			onTouchMove={onTouchMove}
			onTouchEnd={() => onTouchEnd(next, previous)}
		>
			{relevantCategories.map(
				({ landing, slug: categorySlug, objectID }, i) => {
					const slug = categorySlug === 'spa' || !landing ? '' : categorySlug;
					const link = `${localizedBasePath}/${slug}`;

					const currentImg = i === current.index && ref === currentImage;
					const nextImg =
						i ===
							(current.index + relevantCategories.length + 1) %
								relevantCategories.length && ref === nextImage;
					const prevImg =
						i ===
							(current.index + relevantCategories.length - 1) %
								relevantCategories.length && ref === previousImage;

					return (
						<Image
							key={objectID}
							fit='cover'
							url={`https://res.cloudinary.com/planity/image/upload/f_auto,q_auto/v1701273308/portail/illustrations/HOMEPAGE/MAIN_2023/SLIDER/${objectID}.jpg`}
							href={link}
							className={cx({
								imageSlide: true,
								currentImage: currentImg || nextImg || prevImg,
								isReversed: current.reversed
							})}
						/>
					);
				}
			)}
		</div>
	);

	return (
		<div className={classes} ref={categoriesRef}>
			<Container noStupidMarginBottom>
				<div className={styles.columns}>
					<div>
						<div className={styles.left}>
							{renderImage(previousImage)}
							{renderImage(nextImage)}
							{renderImage(currentImage)}
						</div>
						<div className={`${styles.actions} ${styles.tablet}`}>
							<NavButton
								onClick={previous}
								label={t('common.previous')}
								isMirrored={true}
								isDisabled={slidingBlocked}
							/>
							<NavButton
								onClick={next}
								label={t('common.next')}
								progress={progress}
								isDisabled={slidingBlocked}
							/>
						</div>
					</div>

					<div className={styles.right}>
						<div ref={content} className={styles.relevantCategories}>
							{relevantCategories.map(
								(
									{
										landing,
										name: categoryName,
										singular,
										slug: categorySlug,
										objectID
									},
									i
								) => {
									const name = capitalize(singular || categoryName || '') || '';
									const slug =
										categorySlug === 'spa' || !landing ? '' : categorySlug;
									const link = `${localizedBasePath}/${slug}`;
									const currentContent = i === current.index;

									return (
										<a
											href={link}
											key={objectID}
											className={cx({
												item: true,
												currentItemWrapper: currentContent
											})}
										>
											<span className={styles.title}>
												{t('landing.categories.title')}
											</span>
											<div
												className={cx({
													content: true,
													currentItem: currentContent
												})}
											>
												<span className={styles.subtitle}>{name}</span>
												<p className={styles.text}>{landing?.highlight}</p>
												<p
													className={cx({
														text: true,
														isExtended: isExtended === objectID
													})}
													dangerouslySetInnerHTML={{ __html: landing?.text }}
												></p>
												<Button
													className={styles.seeMore}
													type='linked'
													size='large'
													label={t(
														`landing.categories.${
															isExtended === objectID ? 'readLess' : 'readMore'
														}`
													)}
													noFocus
													onClick={e => {
														e.preventDefault();
														seeMore(objectID);
													}}
												/>
											</div>
										</a>
									);
								}
							)}
						</div>

						<div
							className={cx({
								actions: true,
								mobile: true
							})}
						>
							<NavButton
								onClick={previous}
								label={t('common.previous')}
								isMirrored={true}
								isDisabled={slidingBlocked}
							/>
							<NavButton
								onClick={next}
								label={t('common.next')}
								progress={progress}
								isDisabled={slidingBlocked}
							/>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
});
