import React from 'react';
import styles from './country_code_alert_modal_content.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';

export const CountryCodeAlertModalContent = ({
	businessCountryCode,
	localeCountryCode
}) => {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		countryCodeAlertModal: true
	});

	const { t } = useTranslation();

	return (
		<div className={classes}>
			<h1 className={styles.title} id='country-code-alert-title'>
				{t('countryChange.title.alert', {
					businessCountry: t(`countries.${businessCountryCode}.name`),
					country: t(`countries.${localeCountryCode}.name`)
				})}
			</h1>
			<p className={styles.text} id='country-code-alert-text'>
				{t('countryChange.body.alert', {
					country: t(`countries.${businessCountryCode}.name`)
				})}
			</p>
		</div>
	);
};
