export function EmptyStar() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='25'
			viewBox='0 0 24 25'
			fill='none'
		>
			<path
				d='M12.6 4.75L14.35 10.25H19.85L15.35 13.75L16.85 19.25L12.6 15.75L8.34998 19.25L9.84998 13.75L5.34998 10.25H10.85L12.6 4.75Z'
				fill='currentColor'
				stroke='currentColor'
				strokeWidth='1'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
