import React, { useEffect, useState } from 'react';
import { fetchExposedProductsForClickAndCollect } from './fetchExposedProductsForClickAndCollect';
import { computeSortedProducts } from '@planity/helpers';
import { BusinessProducts, Spinner } from '@planity/ui';

export const ProductsList = ({
	clickAndCollectProducts = {},
	clickAndShopProducts = {},
	isClickAndCollect = false,
	isClickAndShop = true,
	businessId,
	onAddProduct,
	cart = [], // use only if it is in the Click & Collect Flow,
	needToFetchProducts = false,
	isCollapsed,
	semiCollapsedSets = [], // array of sets that must be semi-collapsed
	isDark
}) => {
	const [products, setProducts] = useState(
		isClickAndCollect
			? clickAndCollectProducts
			: isClickAndShop
			? clickAndShopProducts
			: {}
	);
	useEffect(() => {
		setProducts(
			isClickAndCollect ? clickAndCollectProducts : clickAndShopProducts
		);
	}, [isClickAndCollect, isClickAndShop]);

	useEffect(() => {
		if (window.ga) {
			window.ga('send', {
				hitType: 'event',
				eventLabel: businessId,
				eventCategory: 'ClickAndCollect',
				eventAction: 'display_click_and_collect'
			});
		}
		const fetchProducts = async (isClickAndCollect, isClickAndShop) => {
			const fetchedProducts = await fetchExposedProductsForClickAndCollect(
				businessId
			);
			const sortedProducts = computeSortedProducts(
				isClickAndCollect
					? fetchedProducts.clickAndCollectProducts
					: isClickAndShop
					? fetchedProducts.clickAndShopProducts
					: {}
			);
			setProducts(sortedProducts);
		};

		if (needToFetchProducts) {
			fetchProducts(isClickAndCollect, isClickAndShop);
		} else {
			const sortedProducts = computeSortedProducts(
				isClickAndCollect
					? clickAndCollectProducts
					: isClickAndShop
					? clickAndShopProducts
					: {}
			);
			setProducts(sortedProducts);
		}
	}, [isClickAndCollect, isClickAndShop]);

	return (
		<>
			{Object.keys(products).length > 0 ? (
				Object.entries(products).map(([productSetId, productSet], i) => {
					const setIsSemiCollapsed = semiCollapsedSets.includes(i);
					return (
						<BusinessProducts
							cart={cart}
							description={products[productSetId].description}
							initIsCollapsed={isCollapsed}
							isDark={isDark}
							items={products[productSetId].children}
							key={products[productSetId].name}
							mobileSemiCollapsed={setIsSemiCollapsed}
							productSetIndex={i}
							productSetSize={Object.values(productSet.children || {}).length}
							title={products[productSetId].name}
							onAddProduct={onAddProduct}
						/>
					);
				})
			) : (
				<div css={styles.activityContainer}>
					<Spinner />
				</div>
			)}
		</>
	);
};

const styles = {
	activityContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 300
	}
};
