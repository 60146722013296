export function Maximize({ outlined }) {
	return (
		<svg
			width='25'
			height='24'
			viewBox='0 0 25 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M5.54999 14.75V19.25H10.05'
				stroke={!outlined ? 'currentColor' : 'none'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M20.05 9.25V4.75H15.55'
				stroke={!outlined ? 'currentColor' : 'none'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M5.79999 19L11.05 13.75'
				stroke={!outlined ? 'currentColor' : 'none'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M19.8 5L14.55 10.25'
				stroke={!outlined ? 'currentColor' : 'none'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
