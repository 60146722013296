import React from 'react';
import styles from './native_app_landing_page.module.scss';
import { HomeSearchBar } from '@planity/ui';
import { useLocalization, useTranslation } from '@planity/localization';
import classNames from 'classnames/bind';

const randomDuoFR = [
	'duo1',
	'duo2',
	'duo3',
	'duo4',
	'duo5',
	'duo6',
	'duo1',
	'duo2',
	'duo3',
	'duo4',
	'duo5',
	'duo6'
][Math.floor(Math.random() * 12)];
const randomDuoDE = [
	'duo2',
	'duo4',
	'duo5',
	'duo2',
	'duo4',
	'duo5',
	'duo2',
	'duo4',
	'duo5',
	'duo2',
	'duo4',
	'duo5'
][Math.floor(Math.random() * 12)];

export const NativeAppLandingPageSignedOut = () => {
	const cx = classNames.bind(styles);
	const { t } = useTranslation();
	const { language } = useLocalization();
	return (
		<div
			className={cx(
				`planity-background-${language === 'de' ? randomDuoDE : randomDuoFR}`,
				{
					nativeAppBg: true
				}
			)}
		>
			<div
				className={cx({
					nativeLandingPage: true,
					nativeConnected: false
				})}
			>
				<h1
					className={cx({
						title: true,
						titleNotConnected: true
					})}
				>
					{t('landing.catchLine.title')}
				</h1>
				<div className={styles.slogan}>
					<span>{t('landing.catchLine.subtitle')}</span>
				</div>
				<HomeSearchBar className={styles.searchbar} instantRefreshOnSelect />
			</div>
		</div>
	);
};
