import React from 'react';

export function Filter() {
	return (
		<svg
			width='25'
			height='24'
			viewBox='0 0 25 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M5.34998 8H7.84998'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M13.35 8H19.85'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M5.34998 16H12.85'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M18.35 16H19.85'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M12.85 8C12.85 9.24264 11.8426 10.25 10.6 10.25C9.35733 10.25 8.34998 9.24264 8.34998 8C8.34998 6.75736 9.35733 5.75 10.6 5.75C11.8426 5.75 12.85 6.75736 12.85 8Z'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M17.85 16C17.85 17.2426 16.8426 18.25 15.6 18.25C14.3573 18.25 13.35 17.2426 13.35 16C13.35 14.7574 14.3573 13.75 15.6 13.75C16.8426 13.75 17.85 14.7574 17.85 16Z'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
