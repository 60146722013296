import React from 'react';

export const BH = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5563)'>
				<mask
					id='mask0_11534_5563'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5563)'>
					<rect width='20' height='15' fill='#E31D1C' />
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0H4.24999L7.5 1.25L4.24999 2.5L7.5 3.75L4.24999 5L7.5 6.25L4.24999 7.5L7.5 8.75L4.24999 10L7.5 11.25L4.24999 12.5L7.5 13.75L4.24999 15H0V0Z'
						fill='#F7FCFF'
					/>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5563'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
