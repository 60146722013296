import { format, parse } from 'libphonenumber-js';

export function formatPhoneNumber(number, numberFormat = 'National') {
	if (number) {
		try {
			const phone = parse(number);
			if (!phone.phone) return '';
			return format(phone, numberFormat);
		} catch (e) {
			if (process.env.NODE_ENV === 'development') {
				console.warn('formatPhoneNumber', e);
				return '';
			}
		}
	} else {
		return '';
	}
}

export function isValidPhoneNumber(number, defaultCountry = 'FR') {
	if (number) {
		try {
			const phone = parse(number, defaultCountry);
			return !!(phone && phone.phone);
		} catch (e) {
			return false;
		}
	} else {
		return true;
	}
}
