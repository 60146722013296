import React from 'react';

export const PF = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g>
				<mask
					style={{ maskType: 'alpha' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#F7FCFF'
					/>
					<mask
						style={{ maskType: 'alpha' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V3.75H20V0H0Z'
							fill='#BF2714'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 11.25V15H20V11.25H0Z'
							fill='#BF2714'
						/>
						<mask
							style={{ maskType: 'alpha' }}
							maskUnits='userSpaceOnUse'
							x='6'
							y='3'
							width='8'
							height='9'
						>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M10 11.25C12.0711 11.25 13.75 9.57107 13.75 7.5C13.75 5.42893 12.0711 3.75 10 3.75C7.92893 3.75 6.25 5.42893 6.25 7.5C6.25 9.57107 7.92893 11.25 10 11.25Z'
								fill='white'
							/>
						</mask>
						<g mask='url(#mask2_3616_2070)'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M6.5929 8.60299C6.55869 8.49583 6.67444 8.40279 6.77174 8.45926L7.0053 8.59479C7.04013 8.61501 7.08267 8.61688 7.11914 8.5998L7.69568 8.32985C7.73609 8.31093 7.78359 8.31539 7.81977 8.3415L8.44258 8.791L9.30349 8.32748C9.33666 8.30962 9.3762 8.30796 9.41076 8.32297L10.0592 8.60464C10.0919 8.61888 10.1293 8.61815 10.1615 8.60266L10.7432 8.32265C10.7742 8.3077 10.8101 8.30647 10.8421 8.31925L11.5617 8.60671C11.5942 8.61966 11.6306 8.61822 11.6619 8.60273L12.2193 8.32695C12.2546 8.30951 12.296 8.30999 12.3309 8.32823L12.8422 8.59598C12.8792 8.61536 12.9235 8.61463 12.9598 8.59404L13.2257 8.44341C13.3205 8.38972 13.4331 8.47669 13.4052 8.58197L13.3496 8.791H6.65292L6.5929 8.60299Z'
								fill='#5277B9'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M6.59056 9.34417C6.55734 9.23634 6.67475 9.14446 6.77143 9.20261L6.98392 9.33042C7.01954 9.35184 7.06357 9.35382 7.10097 9.33567L7.64924 9.06959C7.69061 9.04951 7.73974 9.0542 7.77657 9.08175L8.37404 9.5287L9.20133 9.06703C9.2353 9.04807 9.27625 9.04631 9.31172 9.06229L9.93032 9.34082C9.96397 9.35597 10.0027 9.3552 10.0357 9.33873L10.5905 9.06192C10.6223 9.04602 10.6595 9.04472 10.6924 9.05833L11.38 9.34301C11.4133 9.35681 11.451 9.35528 11.4831 9.33882L12.0142 9.0665C12.0503 9.04797 12.0933 9.04848 12.129 9.06785L12.615 9.33164C12.6529 9.3522 12.6987 9.35143 12.7359 9.32961L12.9797 9.18645C13.074 9.1311 13.1882 9.21711 13.161 9.32302L13.1083 9.5287H6.64739L6.59056 9.34417Z'
								fill='#5277B9'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M6.7135 9.83599C6.68028 9.72817 6.79769 9.63628 6.89437 9.69444L7.10686 9.82224C7.14248 9.84366 7.18651 9.84564 7.22391 9.82749L7.77218 9.56141C7.81356 9.54133 7.86268 9.54602 7.89951 9.57357L8.49698 10.0205L9.32427 9.55885C9.35824 9.53989 9.39919 9.53813 9.43466 9.55411L10.0533 9.83264C10.0869 9.84779 10.1256 9.84703 10.1586 9.83055L10.7134 9.55374C10.7453 9.53784 10.7825 9.53654 10.8153 9.55015L11.503 9.83483C11.5363 9.84863 11.574 9.8471 11.6061 9.83064L12.1372 9.55832C12.1733 9.5398 12.2162 9.5403 12.2519 9.55967L12.7379 9.82346C12.7758 9.84402 12.8217 9.84325 12.8588 9.82143L13.1026 9.67828C13.1969 9.62292 13.3111 9.70893 13.284 9.81484L13.2312 10.0205H6.77033L6.7135 9.83599Z'
								fill='#5277B9'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M7.45121 10.3278C7.418 10.22 7.53541 10.1281 7.63209 10.1862L7.84457 10.314C7.88019 10.3355 7.92423 10.3374 7.96163 10.3193L8.50989 10.0532C8.55127 10.0331 8.6004 10.0378 8.63722 10.0654L9.2347 10.5123L10.062 10.0506C10.096 10.0317 10.1369 10.0299 10.1724 10.0459L10.791 10.3244C10.8246 10.3396 10.8633 10.3388 10.8963 10.3223L11.4511 10.0455C11.483 10.0296 11.5202 10.0283 11.5531 10.0419L12.2407 10.3266C12.274 10.3404 12.3117 10.3389 12.3438 10.3224L12.8749 10.0501C12.911 10.0316 12.9539 10.0321 12.9896 10.0515L13.4756 10.3153C13.5135 10.3358 13.5594 10.335 13.5965 10.3132L13.8404 10.1701C13.9346 10.1147 14.0489 10.2007 14.0217 10.3066L13.969 10.5123H7.50805L7.45121 10.3278Z'
								fill='#5277B9'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M6.4676 10.9425C6.43439 10.8347 6.55179 10.7428 6.64847 10.801L6.86096 10.9288C6.89658 10.9502 6.94062 10.9522 6.97801 10.934L7.52628 10.6679C7.56766 10.6479 7.61678 10.6526 7.65361 10.6801L8.25108 11.1271L9.07837 10.6654C9.11235 10.6464 9.15329 10.6447 9.18877 10.6606L9.80736 10.9392C9.84102 10.9543 9.8797 10.9536 9.91273 10.9371L10.4675 10.6603C10.4994 10.6444 10.5366 10.6431 10.5695 10.6567L11.2571 10.9414C11.2904 10.9552 11.3281 10.9536 11.3602 10.9372L11.8913 10.6649C11.9274 10.6463 11.9703 10.6468 12.006 10.6662L12.492 10.93C12.5299 10.9506 12.5758 10.9498 12.6129 10.928L12.8567 10.7848C12.951 10.7295 13.0652 10.8155 13.0381 10.9214L12.9854 11.1271H6.52444L6.4676 10.9425Z'
								fill='#5277B9'
							/>
							<path
								d='M7.91701 5.90363H8.54201L8.52753 8.37369C8.63245 8.70626 8.78215 8.8362 9.01408 8.8362V9.4612C8.48637 9.4612 8.11303 9.13714 7.91701 8.46772V5.90363Z'
								fill='#FF0000'
							/>
							<path
								d='M12.1419 5.90363H11.5169L11.5314 8.37369C11.4265 8.70626 11.2768 8.8362 11.0449 8.8362V9.4612C11.5726 9.4612 11.9459 9.13714 12.1419 8.46772V5.90363Z'
								fill='#FF0000'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M10.453 4.31789C10.453 4.31789 9.41615 4.04678 9.41615 4.73424C9.41615 5.42171 9.41615 6.89809 9.41615 6.89809L10.6249 7.25705V5.48842C10.6249 5.48842 10.0205 5.11913 10.0205 4.83034C10.0205 4.54155 10.453 4.31789 10.453 4.31789Z'
								fill='#FF0000'
							/>
							<path
								d='M8.70854 8.05669L9.10272 7.55298'
								stroke='black'
								strokeWidth='0.625'
							/>
							<path
								d='M9.068 8.04007L8.69031 7.53336'
								stroke='black'
								strokeWidth='0.625'
							/>
							<path
								d='M9.27411 8.05669L9.66829 7.55298'
								stroke='black'
								strokeWidth='0.625'
							/>
							<path
								d='M9.63357 8.04007L9.25587 7.53336'
								stroke='black'
								strokeWidth='0.625'
							/>
							<path
								d='M9.81509 8.05669L10.2093 7.55298'
								stroke='black'
								strokeWidth='0.625'
							/>
							<path
								d='M10.1746 8.04007L9.79686 7.53336'
								stroke='black'
								strokeWidth='0.625'
							/>
							<path
								d='M10.3807 8.05669L10.7748 7.55298'
								stroke='black'
								strokeWidth='0.625'
							/>
							<path
								d='M10.7401 8.04007L10.3624 7.53336'
								stroke='black'
								strokeWidth='0.625'
							/>
							<path
								d='M10.9462 8.05669L11.3404 7.55298'
								stroke='black'
								strokeWidth='0.625'
							/>
							<path
								d='M11.3057 8.04007L10.928 7.53336'
								stroke='black'
								strokeWidth='0.625'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M8.40899 4.43426L9.15703 5.89568L9.24912 4.43426L9.15703 4.14639L8.40899 4.43426ZM8.03424 5.68139L7.49531 5.10996L8.03424 4.46774L9.16703 6.27858V6.73886L8.62233 6.27858V5.68139H8.03424ZM6.84776 6.12164L7.83078 6.59224L7.90759 5.86517L7.08915 5.16586L6.84776 6.12164ZM7.88038 7.32851L6.58732 7.19624V6.2843L7.88038 6.78168V7.32851ZM6.55228 8.22429L6.39882 7.44886L7.85632 7.52154V8.22429H6.55228ZM8.60447 6.95018V6.5125L9.14957 6.95018L9.0904 7.18128L8.60447 6.95018ZM13.6754 7.44886L13.5219 8.22429H12.2178V7.52154L13.6754 7.44886ZM12.1938 7.32851L13.4869 7.19624V6.2843L12.1938 6.78168V7.32851ZM13.2264 6.12164L12.2434 6.59224L12.1666 5.86517L12.985 5.16586L13.2264 6.12164ZM12.0399 5.68139L12.5789 5.10996L12.0399 4.46774L10.9071 6.27858V6.73886L11.4518 6.27858V5.68139H12.0399ZM11.6652 4.43426L10.9171 5.89568L10.825 4.43426L10.9171 4.14639L11.6652 4.43426ZM11.4697 6.95018V6.5125L10.9246 6.95018L10.9838 7.18128L11.4697 6.95018Z'
								fill='#FA8F21'
							/>
						</g>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
				style={{ mixBlendMode: 'multiply' }}
			/>
			<defs>
				<clipPath>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
