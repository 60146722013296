export function Timer() {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M15.2084 10.8333C15.2084 13.7098 12.8766 16.0417 10.0001 16.0417C7.1236 16.0417 4.79175 13.7098 4.79175 10.8333C4.79175 7.95685 7.1236 5.625 10.0001 5.625C12.8766 5.625 15.2084 7.95685 15.2084 10.8333Z'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M13.75 7.08331L14.375 6.45831'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M10 5.41665V3.95831M10 3.95831H8.125M10 3.95831H11.875'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M10 8.125V11.0417'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
