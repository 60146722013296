import React from 'react';
import { Localize } from '@planity/components';
import { noop, UseHotJar } from '@planity/helpers';
import { Button, Modal } from '@planity/ui';
import { useTranslation } from '@planity/localization';
import styles from './edit_profile_modal.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';

const EditProfileModal = ({
	onSuccess = noop,
	dismiss = noop,
	message = '',
	showEditProfileModal
}) => {
	useStyles(styles);
	const { t } = useTranslation();

	const classes = classNames.bind(styles)({
		editProfileModal: true
	});

	return (
		<UseHotJar feature={'editAccount'}>
			<Modal
				closeModal={dismiss}
				hasCloseBtn={false}
				isOpen={showEditProfileModal}
			>
				<div className={classes}>
					<div className={styles.title}>
						<Localize text={'myAccount.editModalTitle'} />
					</div>
					<div className={styles.message}>
						<Localize text={message} />
						<br />
						<Localize text={'myAccount.editModalMessage'} />
					</div>
					<div className={styles.actions}>
						<Button
							id={'edit-profile-dismiss-button'}
							isFullMobile
							label={t('myAccount.cancelEdit')}
							size={'medium'}
							type={'secondary'}
							onClick={dismiss}
						/>
						<Button
							id={'edit-profile-success-button'}
							isFullMobile
							label={t('myAccount.iUnderstood')}
							size={'medium'}
							type={'primary'}
							onClick={onSuccess}
						/>
					</div>
				</div>
			</Modal>
		</UseHotJar>
	);
};

export { EditProfileModal };
