import React, { Component, createRef, forwardRef } from 'react';
import Downshift from 'downshift';
import ReactList from 'react-list';
import {
	AsYouType,
	format,
	parse,
	isSupportedCountry,
	getCountryCallingCode
} from 'libphonenumber-js';
import classNames from 'classnames/bind';
import uniq from 'lodash/uniq';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Localize } from '@planity/components';
import { countriesCodes, getPreferredCountries } from '@planity/helpers';
import { Phone, Input, Icon, Flag } from '@planity/ui';
import { withTranslation, withLocalization } from '@planity/localization';
import styles from './index.module.scss';
import { checkInputError } from '../authentication/utils';

export const PhoneInput = withTranslation()(
	withLocalization(
		withStyles(styles)(
			forwardRef((props, forwardedRef) => (
				<PhoneInputComponent forwardedRef={forwardedRef} {...props} />
			))
		)
	)
);

class PhoneInputComponent extends Component {
	static defaultProps = {
		defaultCountry: 'FR',
		isRequired: true,
		dropdownPlacement: 'bottom'
	};
	state = {
		prevValue: null,
		showPhoneIndicator: false,
		isFocused: false,
		isEditing: false
	};
	input = createRef();
	countries = uniq(countriesCodes.filter(c => isSupportedCountry(c)));
	preferredCountries = uniq(getPreferredCountries(this.props.locale));

	reset() {
		this.setState({ prevValue: null });
	}
	static getDerivedStateFromProps(props, state) {
		if (
			(state.prevValue !== props.value &&
				(state.prevValue === null ||
					e164Value(state.inputText) !== props.value)) ||
			(!state.isFocused &&
				state.isEditing &&
				!!props.value &&
				props.value === state.prevValue) // cancel flow
		) {
			let country = props.defaultCountry;
			let inputText = '';
			if (props.value) {
				try {
					const number = parse(props.value);
					if (number) {
						if (number.country) country = number.country;
						inputText = format(number, 'National') || '';
					}
				} catch (e) {
					console.log('erreur');
				}
			}
			return {
				prevValue: props.value,
				country,
				inputText,
				isEditing: !props.value
			};
		} else if (!props.value) {
			// while editing we receive props.value == ''
			return {
				isEditing: true
			};
		} else {
			return null;
		}
	}
	render() {
		const { isRequired } = this.props;
		const error = checkInputError(
			'phoneNumber',
			this.props.t,
			this.props.data
		)?.format;
		const errorMessage = checkInputError(
			'phoneNumber',
			this.props.t,
			this.props.data
		)?.message;
		const hasError = error;

		const cx = classNames.bind(styles);
		const classes = classNames.bind(styles)({
			phoneInput: true
		});

		const preferredCountriesLocalized = this.preferredCountries.map(country => {
			return {
				countryCode: country,
				name: this.props.t(`countries.${country}.name`)
			};
		});

		const orderedOthersCountries = this.countries
			.filter(country => !this.preferredCountries.includes(country))
			.map(country => {
				return {
					countryCode: country,
					// Retrieve localized country name in order to sort them alphabetically
					name: this.props.t(`countries.${country}.name`)
				};
			})
			// sort alphabetically localized countries's name
			.sort((a, b) => a.name.localeCompare(b.name));

		const countriesList = preferredCountriesLocalized.concat(
			orderedOthersCountries
		);

		return (
			<>
				<Phone
					id='phone-input'
					isRequired={isRequired}
					label={
						this.props.localizedText &&
						this.props.localizedText.labels.phoneNumber
					}
					name='phone_number'
					phoneInput={
						<Downshift
							itemToString={item => item}
							render={({ getButtonProps, getItemProps, isOpen }) => (
								<div className={classes}>
									<div className={styles.row}>
										<div
											{...getButtonProps({
												disabled: !!this.props.isDisabled
											})}
											className={cx({
												country: !this.props.styleError,
												countryError: this.props.styleError,
												disabledCountry: this.props.isDisabled,
												isFocused: this.state.isFocused,
												hasError: hasError
											})}
											id='countries-list-btn'
										>
											<Flag country={this.state.country} />
											{this.state.showPhoneIndicator && (
												<span className={styles.phoneIndicator}>
													<Localize text='punctuation.plus' />
													{this.state.country
														? getCountryCallingCode(this.state.country)
														: '33'}
												</span>
											)}
											<Icon
												className={'chevronBottom'}
												icon={'ChevronBottom'}
											/>
										</div>
										<Input
											autoFocus={this.props.autoFocus}
											className={'padding'}
											error={error}
											errorMessage={errorMessage}
											forwardedRef={this.props.forwardedRef || this.input}
											id={this.props.id}
											isDisabled={this.props.isDisabled}
											isRequired={isRequired}
											label={this.props.label}
											name='phone_number'
											placeholder={
												this.props.localizedText
													? this.props.localizedText.placeholders.phoneNumber
													: this.props.localizedPlaceholder
											}
											type={'tel'}
											value={this.state.inputText}
											onBlur={event => this.handleFocusInput(event, false)}
											onChange={this.onInputTextChange}
											onFocus={event => this.handleFocusInput(event, true)}
										></Input>
									</div>
									{isOpen && (
										<div
											className={
												styles[`dropdown-${this.props.dropdownPlacement}`]
											}
											id='countries-list'
										>
											<ReactList
												itemRenderer={index =>
													this.renderCountryAtIndex({
														index,
														getItemProps,
														countriesList
													})
												}
												length={countriesList.length}
												type={'uniform'}
												useStaticSize
											/>
										</div>
									)}
								</div>
							)}
							selectedItem={this.state.country}
							onChange={this.onCountryChange}
						/>
					}
					placeholder={
						this.props.localizedText
							? this.props.localizedText.placeholders.phoneNumber
							: this.props.localizedPlaceholder
					}
					// error={error}
					// errorMessage={errorMessage }
					value={this.props.data && this.props.data.phoneNumber}
				/>
			</>
		);
	}
	handleFocusInput(event, value) {
		event.target.parentNode.setAttribute('focused', value);
		this.setState({
			isFocused: value
		});
	}
	onChange(inputText, country) {
		const value = e164Value(inputText, country);
		this.props.onChange(value, !!(!inputText || value));
	}
	onInputTextChange = newValue => {
		const inputText = new AsYouType(this.state.country).input(newValue);
		const selectionRange = this.nextSelectionRange();
		this.setState({ inputText }, () => {
			const node = this.input && this.input.current;
			if (selectionRange && node && node.setSelectionRange) {
				node.setSelectionRange.apply(node, selectionRange);
			}
			this.onChange(inputText, this.state.country);
		});
	};
	onCountryChange = country => {
		this.setState(
			({ inputText: inputTextWas }) => {
				const inputText =
					nationalValue(inputTextWas.replace(/[^\d]/g, ''), country) ||
					inputTextWas;
				return {
					country,
					inputText
				};
			},
			() => {
				this.onChange(this.state.inputText, country);
			}
		);
	};
	nextSelectionRange() {
		const input = this.input && this.input.current;
		if (input) {
			const nextValue = new AsYouType(this.state.country).input(input.value);
			const nextSubValue = new AsYouType(this.state.country).input(
				input.value.substring(0, input.selectionStart)
			);
			if (
				nextValue.substring(0, nextSubValue.length) === nextSubValue ||
				nextValue.match(/\s/)
			) {
				return [nextSubValue.length, nextSubValue.length, 'none'];
			} else {
				const char = input.value
					.substring(0, input.selectionStart)
					.replace(/\s/g, '').length;
				return [char, char, 'none'];
			}
		}
	}
	renderCountryAtIndex({ index, getItemProps, countriesList }) {
		const country = countriesList[index];
		const cx = classNames.bind(styles);

		return (
			<div
				className={cx({
					dropdownCountry: true
				})}
				{...getItemProps({ item: country.countryCode })}
				key={`${country.countryCode}-${index}`}
			>
				<Flag country={country.countryCode} />
				<span>{country.name}</span>
			</div>
		);
	}
}

function e164Value(input, country) {
	const number = parse(input || '', country);
	if (!number.phone) return '';
	return format(number, 'E.164');
}

function nationalValue(input) {
	const number = parse(input);
	if (!number.phone) return '';
	return format(number, 'NATIONAL');
}
