import { AlgoliaSearch, Localize } from '@planity/components';
import { useSearch } from '@planity/context';
import credentials from '@planity/credentials';
import {
	useLocalization,
	useLocalizedRoutes,
	useTranslation
} from '@planity/localization';
import { Icon } from '@planity/ui';
import { UserQueries } from './user_queries';
import { SearchItem } from './search_item';
import { SearchHeader } from '../ui/search_header';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { isNativeApp } from '@planity/webview';
import styles from './search_business_page.module.scss';
import { WithCache } from '../data';

export const SearchBusinessPage = () => {
	const history = useHistory();
	const { keepPlacesOnSubmit } = history?.location?.state ?? {};
	useStyles(styles);
	const [whatQuery, setWhatQuery] = useState('');
	const [whatInputContent, setWhatInputContent] = useState('');
	const { routes } = useLocalizedRoutes();
	const { countries, language } = useLocalization();
	const { t } = useTranslation();
	const { setSearch, place, parentPlace, googlePlace, userLocation } =
		useSearch();
	const classes = classNames.bind(styles);

	const status = isNativeApp ? 'plStatus >= 2' : 'plStatus > 0';

	const whatQueries = useMemo(
		() => ({
			businesses: {
				index: credentials.BUSINESSES_INDEX,
				params: {
					hitsPerPage: whatQuery?.length ? 10 : 0, // 🤡
					page: 0,
					filters: `${status} AND countryCode:${countries
						.map(country => country.toUpperCase())
						.join(' OR countryCode:')}`
				}
			},
			categories: {
				index: credentials.CATEGORIES_INDEX,
				params: {
					hitsPerPage: 5,
					page: 0,
					filters: `NOT disabled:true`
				}
			}
		}),
		[countries, whatQuery?.length]
	);

	const onWhatSelect = what => {
		if (what?.isBusiness) {
			return history.push(routes.catchAll({ business: what }));
		}
		if (!keepPlacesOnSubmit) {
			setSearch({
				category: what?.isCategory ? what : null,
				googlePlace: null,
				place: null,
				parentPlace: null
			});
			return history.replace(routes.searchPlace);
		}
		return setSearch(
			{
				category: what?.isCategory ? what : null,
				place,
				parentPlace,
				googlePlace,
				userLocation
			},
			null,
			true,
			'push'
		);
	};

	const onWhatChange = what => {
		setWhatInputContent(what || '');
		setWhatQuery(what || '');
	};

	function formatWhat({ businesses, categories }) {
		return [
			categories?.data?.slice(0, 5)?.map(formatCategory),
			businesses?.data?.slice(0, 10)?.map(formatBusiness)
		];
	}

	function formatCategory(category) {
		const { isBrand } = category;

		return {
			...category,
			dangerousLabel: category?._highlightResult?.name?.value
				?.replace(/<em>/gi, '<strong><u>')
				?.replace(/<\/em>/gi, '</u></strong>'),
			description: isBrand && t('business.allBusiness'),
			isCategory: true,
			isInlined: true
		};
	}

	function formatBusiness(business) {
		return {
			...business,
			description: [business?.address?.postalCode, business?.address?.locality]
				.filter(x => x)
				.join(' '),
			isBusiness: true,
			dangerousLabel: business?._highlightResult?.name?.value
				?.replace(/<em>/gi, '<strong><u>')
				?.replace(/<\/em>/gi, '</u></strong>'),
			isInlined: true,
			picture: business?.pictures?.at(0),
			avatar: {
				icon: !business?.pictures?.at(0) && 'Store',
				size: 'large',
				sizeIcon: '32',
				shape: 'square'
			}
		};
	}

	return (
		<div className={classes({ container: true })}>
			<Helmet>
				<meta content={'noindex,nofollow'} name={'robots'} />
			</Helmet>
			<div className={classes({ fixedHeader: true })}>
				<SearchHeader />
				<div className={classes({ inputContainer: true })}>
					<input
						autoComplete={'off'}
						autoFocus={false}
						className={classes({
							input: true
						})}
						placeholder={t('search.placeholders.business')}
						ref={input => {
							// https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
							input?.focus();
						}}
						type='text'
						value={whatInputContent || ''}
						onChange={e => onWhatChange(e?.currentTarget?.value || '')}
					/>
					<div className={classes({ inputRightItem: true })}>
						{whatInputContent?.length ? (
							<p
								className={classes({ eraseLabel: true })}
								onClick={() => onWhatChange('')}
							>
								<Localize text={'search.erase'} />
							</p>
						) : (
							<Icon icon='Search' size='medium' />
						)}
					</div>
				</div>
			</div>
			<div className={classes({ body: true })}>
				{!whatQuery && <UserQueries />}
				{!whatQuery && (
					<h3 className={classes({ title: true })}>
						<Localize text={'category.frequentSearches'} />
					</h3>
				)}
				<WithCache>
					{cacheProvider => (
						<AlgoliaSearch
							cacheProvider={cacheProvider}
							format={formatWhat}
							language={language}
							localizeResults
							queries={whatQueries}
							query={whatQuery}
						>
							{({ data }) => {
								if (!Array.isArray(data)) return null;
								const [categories, businesses] = data;
								return (
									<>
										{categories?.map((item, i) => (
											<SearchItem item={item} key={i} onSelect={onWhatSelect} />
										)) || null}
										{!!(categories.length || businesses.length) &&
											whatQuery && (
												<div className={classes({ separator: true })} />
											)}
										{businesses?.map((item, i) => (
											<SearchItem item={item} key={i} onSelect={onWhatSelect} />
										)) || null}
									</>
								);
							}}
						</AlgoliaSearch>
					)}
				</WithCache>
			</div>
		</div>
	);
};
