import { safeRead } from '../functions';

export function parseFormula(formula, calendars, fromRecursion = false) {
	if (!formula) return [0, 0];

	if ('PAUSE'.localeCompare(formula || '') === 0) return [0, 0];

	if (typeof formula === 'string') {
		return [1, 0];
	}

	if (formula['and']) {
		const { and: andFormula } = formula;

		if (checkIfItsOnlyString(andFormula)) {
			return andFormula.map(x => 1);
		}

		const [peopleRequirement, resourceRequirement] = andFormula;

		if (checkIfItsHumanXor(peopleRequirement, calendars)) {
			return [
				parseFormula(peopleRequirement, calendars, true)[0],
				parseFormula(resourceRequirement, calendars, true)[0]
			];
		} else {
			return [
				parseFormula(resourceRequirement, calendars, true)[0],
				parseFormula(peopleRequirement, calendars, true)[0]
			];
		}
	}

	if (formula['xor']) {
		const { qty } = formula.xor;
		const isHuman = checkIfItsHumanXor(formula.xor, calendars);
		return isHuman
			? [qty || 1, 0]
			: fromRecursion
			? [qty || 1, 0]
			: [0, qty || 1];
	}

	return [1, 0];
}

function checkIfItsOnlyString(immutableObject) {
	return immutableObject.every(x => typeof x === 'string');
}

function checkIfItsHumanXor(xor, allCalendars) {
	const calendarsIds =
		typeof xor === 'string'
			? [xor]
			: safeRead(xor, ['from']) || safeRead(xor, ['xor', 'from']) || [];
	if (!calendarsIds.length) return false;
	const computedCalendar = Object.keys(allCalendars).reduce((all, setId) => {
		const { children, system } = allCalendars[setId];
		for (const calendarId in children) {
			all[calendarId] = system;
		}
		return all;
	}, {});
	return computedCalendar[calendarsIds[0]] === 'people';
}

export function getHumanCalendars(allCalendars) {
	return Object.keys(allCalendars || {}).reduce((list, setId) => {
		const { system, children, deletedAt } = allCalendars[setId];

		if (system && system === 'people' && !deletedAt && children) {
			const filtered = Object.values(children).filter(
				({ deletedAt, webHidden }) => !deletedAt && !webHidden
			);
			return { ...list, ...filtered };
		}
		return list;
	}, {});
}

export const toCamelCase = str =>
	str.replace(/_([a-z])/g, function (g) {
		return g[1].toUpperCase();
	});
