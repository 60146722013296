import { Localize } from '@planity/components';
import { formatPrice } from '@planity/helpers';
import { Icon, Spinner } from '@planity/ui';
import React from 'react';

export const PriceDescription = ({
	paidAmount,
	totalPrice,
	appointment,
	isPrePayment,
	feesAmount = 0,
	className
}) => {
	if (!paidAmount)
		return (
			<div className={className}>
				<Spinner />
			</div>
		);
	if (appointment.isDeposit === 'partial') {
		/* Plural in French works this way :
		 * https://www.projet-voltaire.fr/regles-orthographe/1-5-kilometre-ou-1-5-kilometres/
		 * That's why we truncate paidAmount for pluralization
		 */
		return (
			<div className={className}>
				<Icon icon='CreditCard' size={'small'} />
				<span id='appointment-price-deposit'>
					<Localize
						args={{
							price: formatPrice(paidAmount, true),
							count: Math.round(paidAmount / 100)
						}}
						text='myAccount.appointment.amountPaid'
					/>
					<span id='appointment-price-out-of'>
						<Localize
							args={{ totalPrice: formatPrice(totalPrice + feesAmount, true) }}
							text='myAccount.appointment.outOf'
						/>
					</span>
				</span>
			</div>
		);
	}

	return (
		<div className={className}>
			<Icon icon='CreditCard' size={'small'} />
			<span id='appointment-price-total'>
				{isPrePayment ? (
					<Localize
						args={{ price: formatPrice(paidAmount, true) }}
						text='myAccount.appointment.totalPaid'
					/>
				) : (
					<Localize
						args={{ price: formatPrice(paidAmount, true) }}
						text='myAccount.appointment.totalToPay'
					/>
				)}
			</span>
		</div>
	);
};
