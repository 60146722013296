import React, {
	useState,
	useMemo,
	useContext,
	useEffect,
	useCallback
} from 'react';
import classNames from 'classnames/bind';
import styles from './search_bar_category.module.scss';
import { Drawer } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { AlgoliaSearch } from '@planity/components';
import credentials from '@planity/credentials';
import { SearchBarInput } from '../search_bar_input';
import {
	useLocalization,
	useLocalizedRoutes,
	useTranslation
} from '@planity/localization';
import { SearchContext, useSearch } from '@planity/context';
import { withRouter } from 'react-router-dom';
import { AppointmentSourceConsumer } from '@planity/components/satisfaction_measurement/with_appointment_source';
import APPOINTMENT_SOURCE from '@planity/components/satisfaction_measurement/appointment_source';

export const SearchBarCategory = withRouter(
	({
		history,
		className,
		setWhatResult,
		isExpanded,
		setIsExpanded,
		isRedirected
	}) => {
		const classes = classNames.bind(styles)({
			SearchBarCategory: true,
			[className]: !!className
		});
		useStyles(styles);
		const {
			category,
			googlePlace,
			place,
			parentPlace,
			setSearch,
			userLocation
		} = useContext(SearchContext);
		const { submitSearch } = useSearch();
		const [whatQuery, setWhatQuery] = useState('');
		const [isWhatDrawerOpen, setIsWhatDrawerOpen] = useState(false);
		const [currentCategory, setCurrentCategory] = useState(category);
		const { countries } = useLocalization();
		const { t } = useTranslation();
		const { routes } = useLocalizedRoutes();
		const handleChangeWhatQuery = useCallback(category => {
			setWhatQuery(category);
			setWhatResult(category);
		}, []);

		useEffect(() => {
			handleChangeWhatQuery(category?.name);
		}, [category?.name]);

		const onWhatBlur = event => {
			event.preventDefault();
			setIsWhatDrawerOpen(false);
			if (!whatQuery) setCurrentCategory(category?.name);
		};

		const onWhatFocus = event => {
			event.preventDefault();
			setIsWhatDrawerOpen(true);
			if (!whatQuery) setCurrentCategory(category?.name);
		};

		const onWhatSelect = (what, changeSource) => {
			if (what?.isCategory) {
				changeSource(APPOINTMENT_SOURCE.SEARCH.CATEGORY);
				setSearch(
					{
						category: what,
						place: place || null,
						parentPlace: parentPlace || null,
						userLocation: userLocation || null,
						googlePlace: googlePlace || null
					},
					false,
					isRedirected
				);
				setWhatQuery(what?.name || '');
				setWhatResult(what?.name || '');
			} else {
				changeSource(APPOINTMENT_SOURCE.SEARCH.BUSINESS);
				history.push(routes.catchAll({ business: what }));
			}
			isRedirected && submitSearch('push');
			isRedirected && setIsExpanded(false);
		};
		const { language } = useLocalization();
		const whatQueries = useMemo(
			() => ({
				businesses: {
					index: credentials.BUSINESSES_INDEX,
					params: {
						hitsPerPage: whatQuery?.length ? 5 : 0,
						page: 0,
						filters: `plStatus > 0  AND countryCode:${countries
							.map(country => country.toUpperCase())
							.join(' OR countryCode:')}`
					}
				},
				categories: {
					index: credentials.CATEGORIES_INDEX,
					params: {
						hitsPerPage: 5,
						page: 0,
						filters: 'NOT isAdjective:true AND NOT disabled:true'
					}
				}
			}),
			[countries, whatQuery?.length]
		);

		function formatWhat({ businesses, categories }) {
			return categories?.data
				?.slice(0, 5)
				?.map(formatCategory)
				?.concat(businesses?.data?.slice(0, 5)?.map(formatBusiness));
		}

		function formatCategory(category) {
			const { isBrand } = category;

			return {
				...category,
				dangerousLabel: category?._highlightResult?.name?.value
					?.replace(/<em>/gi, '<strong><u>')
					?.replace(/<\/em>/gi, '</u></strong>'),
				description: isBrand && t('business.allBusiness'),
				isCategory: true
			};
		}

		function formatBusiness(business) {
			return {
				...business,
				description: [
					business?.address?.postalCode,
					business?.address?.locality
				]
					.filter(x => x)
					.join(' '),
				isBusiness: true,
				dangerousLabel: business?._highlightResult?.name?.value
					?.replace(/<em>/gi, '<strong><u>')
					?.replace(/<\/em>/gi, '</u></strong>')
			};
		}

		return (
			<AppointmentSourceConsumer>
				{({ changeSource }) => (
					<div className={classes}>
						<SearchBarInput
							className={styles.mobile}
							hasAClearInput
							icon={'Search'}
							isExpanded={isExpanded}
							label={t('search.placeholders.category')}
							placeholder={
								currentCategory ?? t('search.placeholders.businessSmall')
							}
							type={'what'}
							value={whatQuery}
							onChange={handleChangeWhatQuery}
							onClick={() =>
								isRedirected &&
								history.push(routes.searchBusiness, {
									keepPlacesOnSubmit: true
								})
							}
							onBlur={onWhatBlur}
							onFocus={onWhatFocus}
						/>
						{!isRedirected && (
							<AlgoliaSearch
								queries={whatQueries}
								query={whatQuery}
								format={formatWhat}
								localizeResults
								language={language}
							>
								{({ data }) => (
									<Drawer
										header={{
											label: whatQuery?.length
												? t('common.results')
												: t('category.frequentSearches')
										}}
										onSelect={what => onWhatSelect(what, changeSource)}
										className={styles.whatDrawer}
										items={data || []}
										id={'Algolia'}
										isOpen={isWhatDrawerOpen}
										hasArrowKeysNavigation
									/>
								)}
							</AlgoliaSearch>
						)}
					</div>
				)}
			</AppointmentSourceConsumer>
		);
	}
);
