// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".search_bar_button-module_searchBarButton-6iKq8{border-radius:var(--border-radius-medium);background-color:var(--grey-900);color:var(--white);display:inline-flex;align-items:center;justify-content:center;text-align:center;white-space:nowrap;height:fit-content}@media(min-width: 1080px){.search_bar_button-module_searchBarButton-6iKq8{padding:12px 20px}}@media(max-width: 1079px){.search_bar_button-module_searchBarButton-6iKq8{padding:4px}}@media(min-width: 1080px){.search_bar_button-module_icon-l5827{display:none}}.search_bar_button-module_text-tBZg1{font:var(--medium) var(--body-4)}@media(max-width: 1079px){.search_bar_button-module_text-tBZg1{display:none}}", "",{"version":3,"sources":["webpack://./../../packages/ui/components/main_search/search_bar_button/search_bar_button.module.scss"],"names":[],"mappings":"AAEA,gDACC,yCAAA,CACA,gCAAA,CACA,kBAAA,CACA,mBAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,kBAAA,CACA,kBAAA,CAEA,0BAXD,gDAYE,iBAAA,CAAA,CAGD,0BAfD,gDAgBE,WAAA,CAAA,CAKD,0BADD,qCAEE,YAAA,CAAA,CAIF,qCACC,gCAAA,CACA,0BAFD,qCAGE,YAAA,CAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.searchBarButton {\n\tborder-radius: var(--border-radius-medium);\n\tbackground-color: var(--grey-900);\n\tcolor: var(--white);\n\tdisplay: inline-flex;\n\talign-items: center;\n\tjustify-content: center;\n\ttext-align: center;\n\twhite-space: nowrap;\n\theight: fit-content;\n\n\t@media (min-width: styles.$desktop) {\n\t\tpadding: 12px 20px;\n\t}\n\n\t@media (max-width: #{styles.$desktop - 1px}) {\n\t\tpadding: 4px;\n\t}\n}\n\n.icon {\n\t@media (min-width: styles.$desktop) {\n\t\tdisplay: none;\n\t}\n}\n\n.text {\n\tfont: var(--medium) var(--body-4);\n\t@media (max-width: #{styles.$desktop - 1px}) {\n\t\tdisplay: none;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"searchBarButton": "search_bar_button-module_searchBarButton-6iKq8",
	"icon": "search_bar_button-module_icon-l5827",
	"text": "search_bar_button-module_text-tBZg1"
};
module.exports = ___CSS_LOADER_EXPORT___;
