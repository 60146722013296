import React from 'react';
import styles from './cure_details.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { noop } from '@planity/helpers';
import { Icon } from '@planity/ui';
import { CloudinaryPicture, Localize, Address } from '@planity/components';

export function CureDetails({ cureName, price, business, linkToBusiness }) {
	useStyles(styles);

	return (
		<div className={styles.cureItem}>
			<div
				className={styles.image}
				id='cure-product-image'
				onClick={() => (process.env.WIDGET ? noop() : linkToBusiness(business))}
			>
				{business?.pictures?.[0] ? (
					<CloudinaryPicture
						alt={`image-${business?.name}`}
						src={business?.pictures?.at(0)}
						transformation='c_thumb,g_center,h_128,w_128'
					/>
				) : (
					<div className={styles.noPicture} />
				)}
			</div>

			<div className={styles.content} id='cure-product-content'>
				<span
					className={styles.businessName}
					id='cure-item-businessName'
					onClick={() =>
						process.env.WIDGET ? noop() : linkToBusiness(business)
					}
				>
					{business?.name}
				</span>

				<div className={styles.address} id='cure-linkToBusiness'>
					<Address
						{...business?.address}
						location={business?.location}
						shouldOpenMap
						title={business?.name}
					/>
				</div>

				<div className={styles.name} id='cure-item-name'>
					<span>
						<Icon icon='Cure' size='medium' />
						{cureName}
					</span>
				</div>

				<div className={styles.price}>
					<Icon icon='CreditCard' size='medium' />
					<span>
						<span>
							<Localize text={'myAccount.cures.totalPrice'} />
						</span>
						<span id='cure-price'>{price}</span>
					</span>
				</div>
			</div>
		</div>
	);
}
