import classNames from 'classnames/bind';
import { useLocalizedRoutes, useTranslation } from '@planity/localization';
import { Image } from '@planity/ui';
import { Link } from 'react-router-dom';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import styles from './business_category.module.scss';

export function BusinessCategory({
	category,
	dataHide1,
	dataHide2,
	dataHide3,
	index
}) {
	const { routes } = useLocalizedRoutes();
	const { t } = useTranslation();
	const route =
		category &&
		routes.catchAll({
			search: {
				parentCategory: category.parent || category
			}
		});
	useStyles(styles);
	const classes = classNames.bind(styles)({
		city: true
	});

	return (
		<div className={styles.wrapper} data-hasimage={!!category.coverUrl}>
			<Link className={classes} to={route || '/'}>
				<div className={styles.image}>
					<Image
						alt={category.name}
						isCloudinary
						url={category.coverUrl}
						transformation={'fl_strip_profile,f_auto,q_auto,fl_lossy,c_thumb'}
					/>
				</div>
				<div className={styles.text}>
					<span className={styles.label}>{t('category.discover')}</span>
					<h2 className={styles.h2} id={`place-title-${index}-category-page`}>
						{category.name}
					</h2>
				</div>
			</Link>
		</div>
	);
}
