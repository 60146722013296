import React, { useState } from 'react';
import { Localize } from '@planity/components';
import { useModal } from '@planity/ui/context';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';
import classNames from 'classnames/bind';
import { Button } from '@planity/ui';
import styles from './delete_child_confirmation_modal.module.scss';

export const DeleteChildConfirmationModal = ({
	canDeleteChild,
	hasPlanityAccount,
	childId,
	deleteChild,
	redirectToMyAppointments
}) => {
	useStyles(styles);
	const { closeModal } = useModal();
	const { t } = useTranslation();
	const [isDeleting, setIsDeleting] = useState(false);

	const classes = classNames.bind(styles)({
		remove: true
	});

	const title = hasPlanityAccount
		? 'children.modal.removeChild.title'
		: canDeleteChild
		? 'children.modal.deleteChild.title'
		: 'children.modal.cantDeleteChild.title';

	const text = hasPlanityAccount
		? 'children.modal.removeChild.text'
		: canDeleteChild
		? 'children.modal.deleteChild.text'
		: 'children.modal.cantDeleteChild.text';

	const confirmButtonTitle = hasPlanityAccount
		? t('children.modal.removeChild.confirmRemove')
		: canDeleteChild
		? t('children.modal.deleteChild.confirmDelete')
		: t('children.modal.cantDeleteChild.confirmDelete');

	const contactMailPlanity = t(
		'myAccount.myProfile.deleteAccountModal.planityContactMail'
	);

	const handleChildDeletion = async childId => {
		setIsDeleting(true);
		await deleteChild(childId);
		setIsDeleting(false);
		closeModal();
	};

	return (
		<div className={classes}>
			<div className={styles.title}>
				<Localize text={title} />
			</div>

			<p className={styles.text}>
				<Localize text={text} />
				{!hasPlanityAccount && canDeleteChild && (
					<>
						<a
							className={styles.contactMail}
							href={`mailto:${contactMailPlanity}`}
						>
							{contactMailPlanity}
						</a>
						<Localize text='punctuation.period' />
					</>
				)}
			</p>

			<div className={styles.actions}>
				<Button
					className={styles.cancel}
					id={`cancel-cta`}
					isFullMobile
					label={t('actions.cancel')}
					type='secondary'
					onClick={() => closeModal()}
				/>
				{!hasPlanityAccount && !canDeleteChild ? (
					<Button
						className={styles.remove}
						id={`confirm-cta`}
						isFullMobile
						label={confirmButtonTitle}
						onClick={() => {
							redirectToMyAppointments();
							closeModal();
						}}
					/>
				) : (
					<Button
						className={styles.remove}
						id={`confirm-cta`}
						isFullMobile
						isLoading={isDeleting}
						label={confirmButtonTitle}
						type='danger'
						onClick={() => handleChildDeletion(childId)}
					/>
				)}
			</div>
		</div>
	);
};
