import React, { Component, createContext, useEffect, useState } from 'react';
import { publicShard } from '@planity/datastores';

export const FirebaseStatusContext = createContext({
	app: true,
	bookAppointment: true,
	website: true
});

export const FirebaseStatusProvider = ({ children }) => {
	const [appStatus, setDatabaseStatus] = useState({
		app: true,
		bookAppointment: true,
		website: true
	});

	const watchFirebaseNode = () => {
		publicShard
			.database()
			.ref(`app_is_available/`)
			.on('value', snap => {
				if (snap.exists()) {
					const { app, bookAppointment, website } = snap.val();
					setDatabaseStatus({
						app,
						bookAppointment,
						website
					});
				}
			});
	};

	useEffect(() => {
		watchFirebaseNode();
	}, []);

	return (
		<FirebaseStatusContext.Provider value={{ ...appStatus }}>
			{children}
		</FirebaseStatusContext.Provider>
	);
};

export function withAppStatus() {
	return BaseComponent => {
		class WithAppStatus extends Component {
			render() {
				return (
					<FirebaseStatusContext.Consumer>
						{({ setAppIsReady }) => (
							<BaseComponent setAppIsReady={setAppIsReady} {...this.props} />
						)}
					</FirebaseStatusContext.Consumer>
				);
			}
		}

		return WithAppStatus;
	};
}
