// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select_modal-module_modal-XYmqx .select_modal-module_modalContainer-lFp7A:not(.select_modal-module_hasNoRightPadding-ymjFO){padding:32px 16px}@media(min-width: 768px){.select_modal-module_modal-XYmqx .select_modal-module_modalContainer-lFp7A{padding:0 32px 32px}}@media(max-width: 768px){.select_modal-module_modal-XYmqx .select_modal-module_modalContainer-lFp7A.select_modal-module_hasNoRightPadding-ymjFO{height:100%;padding:32px 0 32px 16px}}", "",{"version":3,"sources":["webpack://./../../packages/ui/uikit/forms/select_modal/select_modal.module.scss"],"names":[],"mappings":"AAIE,6HACC,iBAAA,CAGD,yBALD,2EAME,mBAAA,CAAA,CAIA,yBADD,uHAEE,WAAA,CACA,wBAAA,CAAA","sourcesContent":["@use '@planity/ui/styles/utilities/variables.scss';\n\n.modal {\n\t.modalContainer {\n\t\t&:not(.hasNoRightPadding) {\n\t\t\tpadding: 32px 16px;\n\t\t}\n\n\t\t@media (min-width: variables.$tablet) {\n\t\t\tpadding: 0 32px 32px;\n\t\t}\n\n\t\t&.hasNoRightPadding {\n\t\t\t@media (max-width: variables.$tablet) {\n\t\t\t\theight: 100%;\n\t\t\t\tpadding: 32px 0 32px 16px;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"modal": "select_modal-module_modal-XYmqx",
	"modalContainer": "select_modal-module_modalContainer-lFp7A",
	"hasNoRightPadding": "select_modal-module_hasNoRightPadding-ymjFO"
};
module.exports = ___CSS_LOADER_EXPORT___;
