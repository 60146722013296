import { easings, tween } from 'morpheus';
import { noop } from '../functions';
import fetchPonyfill from 'fetch-ponyfill';
import credentials from '@planity/credentials';
import MobileDetect from 'mobile-detect';

const { fetch } = fetchPonyfill();

export function getDocument() {
	return typeof document === 'undefined' ? null : document.documentElement;
}

export function scrollTo(options = {}) {
	const doc = getDocument();
	let top;
	if (options.node) {
		const rect = options.node.getBoundingClientRect();
		if (options.ifNeeded) {
			const height = viewportHeight();
			if (process.env.NODE_ENV === 'development') {
				if (options.ifNeededDebug) {
					console.warn('should scroll: skipping', {
						nodeTop: rect.top,
						viewportHeight: height
					});
				}
			}
			if (rect.top > 0 && rect.top < height) {
				return;
			}
		}
		top =
			(window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0) + rect.top;
		if (options.offset) top = top - options.offset;
	} else {
		top = options.top || 0;
	}
	const host = options.host || window;
	if (options.animated) {
		tween(
			options.animationDuration || 300,
			state => host.scroll(0, state),
			options.callback || noop,
			easings.swingTo,
			(window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0),
			top
		);
	} else {
		host.scroll(0, top);
	}
}

export function getScrollPosition() {
	return window.pageYOffset || getDocument().scrollTop;
}

export function stopPropagation(e) {
	if (e && e.stopPropagation) {
		e.stopPropagation();
	}
}

export function viewportHeight() {
	return Math.max(
		document.documentElement.clientHeight,
		window.innerHeight || 0
	);
}

export function viewportWidth() {
	return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

export function invokeLambda(name, payload) {
	return fetch(`${credentials.AWS_API_ENDPOINT}/${name}`, {
		method: 'post',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	}).then(res => res.json());
}

export function isMobileSafari() {
	const { userAgent } = navigator;
	return (
		userAgent.match(/(iPod|iPhone|iPad)/) && userAgent.match(/AppleWebKit/)
	);
}

export function isMobile() {
	const { userAgent } = navigator;
	const isMobileBrowser =
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			userAgent
		);
	return isMobileBrowser;
}

export function getFormFactor() {
	const { userAgent, maxTouchPoints } = navigator;
	const device = new MobileDetect(userAgent);
	// iPadOS 13 Safari hack
	if (
		device.os() === null &&
		device.ua.match(/Macintosh/) &&
		maxTouchPoints > 1
	) {
		return 'tablet';
	}
	return device.phone() ? 'phone' : device.tablet() ? 'tablet' : 'desktop';
}

export function scrollToNode(node, options) {
	scrollTo({ node, ...options });
}

export const mEasings = easings;
export const mTween = tween;

/**
 * Parses the user agent to detect browser
 * Params can be retrieved thanks to the hook `useViewerHeaders`
 * @param isIOSViewer {boolean} Whether it's an iOS device. Detected by the CloudFront Function
 *   `enhanceRequestWithCloudfront`.
 * @param isAndroidViewer {boolean} Whether it's an Android device. Detected by the CloudFront
 *   Function `enhanceRequestWithCloudfront`
 * @return {FIREFOX|SAMSUNG_INTERNET|CHROME_ANDROID|EDGE|CHROME_IOS|SAFARI_IOS|UNKNOWN_BROWSER}
 */
export function getBrowser({ isIOSViewer, isAndroidViewer }) {
	if (!navigator) return null;
	const { userAgent } = navigator;
	const isFirefox =
		typeof InstallTrigger !== 'undefined' || userAgent.match('FxiOS');
	const isSamsungInternet = userAgent.match(/samsungbrowser/i);
	const isOpera =
		!!window.opr || !!window.opera || userAgent.indexOf('OP') >= 0;

	const isChrome = !isOpera && !!window.chrome && !!userAgent.match(/chrome/i);
	const isSafari =
		!isOpera && !!userAgent.match(/safari/i) && !userAgent.match(/chrome/i);
	const isEdge = userAgent.match(/edg/i);

	if (isFirefox) return FIREFOX;
	if (isSamsungInternet) return SAMSUNG_INTERNET;
	// Microsoft Edge is also on Chromium, so we need to verify it's definitely not EDGE (damn microsoft, you've done it again)
	if (isAndroidViewer && isChrome && !isEdge) return CHROME_ANDROID;
	if (isIOSViewer && userAgent.match(/criOS/i) && !isEdge) return CHROME_IOS;
	if (isSafari && !isEdge) return SAFARI_IOS;
	if (isEdge) return EDGE;

	return UNKNOWN_BROWSER;
}

export const FIREFOX = 'FIREFOX';
export const SAMSUNG_INTERNET = 'SAMSUNG_INTERNET';
export const CHROME_ANDROID = 'CHROME_ANDROID';
export const EDGE = 'EDGE';
export const CHROME_IOS = 'CHROME_IOS';
export const SAFARI_IOS = 'SAFARI_IOS';
export const UNKNOWN_BROWSER = 'UNKNOWN_BROWSER';
