// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text-module_text-cU9wn{font:var(--regular) var(--body-1)}@media(min-width: 769px){.text-module_text-cU9wn{font:var(--regular) var(--body-2)}}.text-module_text-cU9wn>ul{list-style:disc;padding-left:var(--gutter)}.text-module_text-cU9wn a[href]{text-decoration:underline}", "",{"version":3,"sources":["webpack://./app/components/category_page/text.module.scss"],"names":[],"mappings":"AAEA,wBACC,iCAAA,CACA,yBAFD,wBAGE,iCAAA,CAAA,CAED,2BACC,eAAA,CACA,0BAAA,CAGD,gCACC,yBAAA","sourcesContent":["@use '@planity/ui/styles/utilities/variables';\n\n.text {\n\tfont: var(--regular) var(--body-1);\n\t@media (min-width: #{variables.$tablet + 1px}) {\n\t\tfont: var(--regular) var(--body-2);\n\t}\n\t> ul {\n\t\tlist-style: disc;\n\t\tpadding-left: var(--gutter);\n\t}\n\n\ta[href] {\n\t\ttext-decoration: underline;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"text": "text-module_text-cU9wn"
};
module.exports = ___CSS_LOADER_EXPORT___;
