import { format as formatDate } from 'date-fns';
import { invokeLambda, usePrevious } from '@planity/helpers';
import React, { useContext, useEffect, useState } from 'react';
import { SearchContext } from '@planity/context';

export const AvailabilitiesProvider = ({
	businessesFromAlgolia,
	date,
	isLoadingAlgolia,
	pageSearch,
	setPageSearch,
	pagesCount,
	children
}) => {
	if (!process.env.BROWSER) {
		return children({
			businesses: businessesFromAlgolia,
			isLoading: false,
			availabilities: {}
		});
	}

	const { category } = useContext(SearchContext);
	const [isHydrationRender, setIsHydrationRender] = useState(
		window._planity_isHydrating
	);
	const [isCategoryLoaded, setIsCategoryLoaded] = useState(!!category);
	const [availabilitiesByBusinessId, setAvailabilities] = useState({});
	const [businesses, setBusinesses] = useState(
		isHydrationRender ? businessesFromAlgolia : []
	);
	// need to be "loading" when lauching the page from client-side nav
	const [isLoading, setIsLoading] = useState(!isHydrationRender);
	const previousDate = usePrevious(date);
	const userHasPickedDate = !!date;

	useEffect(() => {
		// way to have "continuous" loading (to true) while calling availabilities lambda
		if (isLoadingAlgolia) {
			setIsLoading(isLoadingAlgolia);
		}
	}, [isLoadingAlgolia]);

	// sometimes businesses are loaded before the category: need to trigger lambda when category is loaded
	useEffect(() => {
		if (!category) {
			setIsCategoryLoaded(true);
		}
	}, [category]);

	useEffect(() => {
		// date has changed but the page 'reset' comes after, so we must ignore the first search with wrong page
		const isDateChangeReady = !(
			userHasPickedDate &&
			previousDate !== date &&
			pageSearch > 1
		);

		if (
			isDateChangeReady &&
			!isLoadingAlgolia &&
			businessesFromAlgolia &&
			category
		) {
			// stop "continuous" loading if no businesses found
			// avoid making call to lambda if no business in results for performance issues
			if (!businessesFromAlgolia.length) {
				setAvailabilities({});
				setBusinesses([]);
				setIsLoading(false);
				return;
			}
			if (isHydrationRender) {
				setIsHydrationRender(false);
			} else {
				setIsLoading(!window._planity_isHydrating);
			}
			invokeLambda('fetchAvailabilitiesByDate', {
				businesses: businessesFromAlgolia.map(({ objectID }) => objectID),
				category: category.isBrand ? category.parentId : category.objectID,
				date: userHasPickedDate ? formatDate(date, 'yyyy-MM-dd') : null
			})
				.then(response => {
					const availabilitiesByBusinessId = businessesFromAlgolia.reduce(
						(all, business) => {
							all[business.objectID] = response[business.objectID] || {};
							return all;
						},
						response
					);
					if (userHasPickedDate) {
						const filteredBusinesses = businessesFromAlgolia.filter(
							business => {
								const availabilities =
									availabilitiesByBusinessId[business.objectID];
								return !!availabilities && availabilities.isAvailableSoon;
							}
						);
						// concat new results to previous ones
						if (pageSearch > 1) {
							setAvailabilities(prevAvailabilitiesByBusinessId => ({
								...prevAvailabilitiesByBusinessId,
								...availabilitiesByBusinessId
							}));
							setBusinesses(prevFinalBusiness => [
								...new Set(prevFinalBusiness.concat(filteredBusinesses))
							]);
						} else {
							// new date
							setAvailabilities(availabilitiesByBusinessId);
							setBusinesses(filteredBusinesses);
						}
						// if we had less than 3 businesses available, we directly update next page for new algolia search
						if (filteredBusinesses.length < 3 && pageSearch < pagesCount) {
							setPageSearch(pageSearch + 1);
						}
					} else {
						// no date, regular pagination
						setAvailabilities(availabilitiesByBusinessId);
						setBusinesses(businessesFromAlgolia);
					}
				})
				.catch(e => console.warn(e))
				.finally(() => setIsLoading(false));
		}
	}, [businessesFromAlgolia, isCategoryLoaded, date]);

	return children({
		businesses,
		availabilities: availabilitiesByBusinessId,
		isLoading
	});
};
