export const handleGoogleRedirect = async ({
	business,
	history,
	routes,
	initialServiceId,
	rwgTokenValue
}) => {
	if (rwgTokenValue) {
		// store the token and timestamp in localStorage to validate google coversion
		localStorage.setItem(
			'rwgToken',
			JSON.stringify({
				value: rwgTokenValue,
				createdAt: Date.now()
			})
		);
	}
	if (initialServiceId) {
		// handle redirection from google to reserve a service
		return history.replace(
			routes.catchAll({
				reservation: { business },
				state: {
					business: { ...business, isBooking: true },
					initialServiceId
				}
			})
		);
	}
	// redirect to buisness page
	return history.replace(
		routes.catchAll({
			business
		})
	);
};
