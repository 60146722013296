import classNames from 'classnames/bind';
import styles from './order_product.module.scss';
import { Image, Icon } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';
import { centsToPrice } from '@planity/helpers';
import { Localize } from '@planity/components';
import { breakpoints } from '@planity/theme';

const transformations = {
	default: 'b_white,c_pad,h_256,w_256',
	breakpoints: [
		{
			query: breakpoints.phoneQuery,
			transformation: 'b_white,c_pad,h_256,w_256'
		},
		{
			query: breakpoints.tabletQuery,
			transformation: 'b_white,c_pad,h_256,w_256'
		}
	]
};

export function OrderProduct({ product }) {
	useStyles(styles);
	const { t } = useTranslation();
	const image = product?.images?.[0];

	const classes = classNames.bind(styles)({
		orderProduct: true
	});

	return (
		<div className={classes}>
			<div className={styles.image} id='order-product-image'>
				{image ? (
					<Image
						alt={product?.name}
						url={image}
						transformations={transformations}
					/>
				) : (
					<img
						src={
							'https://d2skjte8udjqxw.cloudfront.net/pics/product_placeholder.png'
						}
						alt={product?.name}
					/>
				)}
			</div>

			<div className={styles.content}>
				<span className={styles.brand} id='order-product-brand'>
					{product.brand}
				</span>
				<span className={styles.label} id='order-product-name'>
					{product.name}
				</span>

				<div className={styles.infos}>
					<span className={styles.info} id='order-product-quantity'>
						<Icon icon='Box' size='medium' />
						{t('myAccount.orders.quantity')} {product.quantity}
					</span>
					<span className={styles.info} id='order-product-price'>
						<Icon icon='Euro' size='medium' />
						<Localize
							args={{
								price: centsToPrice(product.price * product.quantity)
							}}
							text={'myAccount.orders.price'}
						/>
					</span>
				</div>
			</div>
		</div>
	);
}
