import React, { Component, createContext, useContext } from 'react';
import { withAuth } from '@planity/components';

const GoalEventsContext = createContext();

const STEPS = [
	{
		google: { eventCategory: 'PageViews', eventAction: 'home' },
		facebookCustom: 'USER_VISITED_HOME_PAGE'
	},
	{
		google: { eventCategory: 'PageViews', eventAction: 'search' },
		facebookCustom: 'USER_VISITED_SEARCH_PAGE',
		facebook: 'Search'
	},
	{
		google: { eventCategory: 'PageViews', eventAction: 'business' },
		facebookCustom: 'USER_VISITED_BUSINESS_PAGE',
		facebook: 'FindLocation'
	},
	{
		google: {
			eventCategory: 'Appointments',
			eventAction: 'selectService'
		},
		facebookCustom: 'USER_SELECTED_SERVICE',
		facebook: 'AddToCart'
	},
	{
		google: {
			eventCategory: 'Appointments',
			eventAction: 'signIn'
		},
		facebookCustom: 'USER_SIGNED_IN',
		facebook: 'CompleteRegistration'
	},
	{
		google: {
			eventCategory: 'Appointments',
			eventAction: 'book'
		},
		facebookCustom: 'USER_BOOKED_AN_APPOINTMENT',
		facebookCustomByType: {
			hair_care: 'USER_BOOKED_A_HAIRDRESSER_APPOINTMENT',
			beauty_salon: 'USER_BOOKED_A_BEAUTY_SALON_APPOINTMENT',
			nails: 'USER_BOOKED_A_NAILS_APPOINTMENT',
			barber_shop: 'USER_BOOKED_A_BARBER_APPOINTMENT',
			spa: 'USER_BOOKED_A_SPA_APPOINTMENT',
			fitness: 'USER_BOOKED_A_FITNESS_APPOINTMENT',
			tatoo: 'USER_BOOKED_A_TATOO_APPOINTMENT',
			naturopathe: 'USER_BOOKED_A_NATUROPATHY_APPOINTMENT',
			sophrologue: 'USER_BOOKED_A_SOPHROLOGY_APPOINTMENT',
			reflexologue: 'USER_BOOKED_A_REFLEXOLOGY_APPOINTMENT',
			hypnotherapeute: 'USER_BOOKED_A_HYPNOTHERAPY_APPOINTMENT'
		},
		pinterest: 'checkout',
		facebook: 'Schedule'
	},
	{
		google: {
			eventCategory: 'Appointments',
			eventAction: 'signUp'
		},
		facebookCustom: 'USER_SIGNED_UP'
	}
];

export const TrackGoalEvents = withAuth(
	class GoalEventsTracker extends Component {
		state = {
			hasBeenThroughSearch: false,
			hasBeenThroughLanding: false,
			hasBeenThroughBusiness: false,
			hasBeenThroughBookingStep: false,
			hasBeenThroughUserSignup: false,
			hasBeenThroughBookingSuccess: false,
			prevPublishedStep: null,
			publishLandingPageEvent: () => {
				this.setState({
					hasBeenThroughLanding: true
				});
				return this.publish({ stepIndex: 0 });
			},
			publishSearchPageEvent: () => {
				this.setState({
					hasBeenThroughSearch: true
				});
				return this.publish({ stepIndex: 1 });
			},
			publishBusinessPageEvent: () => {
				this.setState({
					hasBeenThroughBusiness: true
				});
				return this.publish({ stepIndex: 2 });
			},
			publishBookingStepEvent: () => {
				this.setState({
					hasBeenThroughBookingStep: true
				});
				return this.publish({ stepIndex: 3 });
			},
			publishUserSignedUp: () => {
				this.setState({
					hasBeenThroughUserSignup: true
				});
				return this.publish({ stepIndex: 6 });
			},
			publishBookingSuccessEvent: (businessId, businessType) => {
				this.setState({
					hasBeenThroughBookingSuccess: true
				});
				this.publish({
					stepIndex: 5,
					alwaysPublish: true,
					googlePayload: { eventLabel: businessId },
					businessType
				});
			}
		};
		componentDidUpdate(prevProps) {
			if (this.props.isSignedIn && !prevProps.isSignedIn) {
				this.publish({ stepIndex: 4, strict: true });
			}
		}
		render() {
			return (
				<GoalEventsContext.Provider value={this.state}>
					{this.props.children}
				</GoalEventsContext.Provider>
			);
		}
		publish({ stepIndex, alwaysPublish, strict, googlePayload, businessType }) {
			const { prevPublishedStep } = this.state;
			if (
				strict
					? prevPublishedStep === stepIndex - 1
					: prevPublishedStep === null ||
					  prevPublishedStep < stepIndex ||
					  alwaysPublish
			) {
				this.setState(
					{
						prevPublishedStep: stepIndex
					},
					() => {
						const step = STEPS[stepIndex];
						if (process.env.USE_GOOGLE_ANALYTICS) {
							if (window.ga) {
								window.ga('send', {
									hitType: 'event',
									...(googlePayload || {}),
									...step.google
								});
							}
						}
						if (process.env.USE_GOOGLE_ANALYTICS_V4) {
							if (window.gtag) {
								// window.gtag('event', step.google.eventCategory, {
								// 	...(googlePayload || {}),
								// 	...step.google
								// });
								window.gtag('event', step.facebookCustom);
							}
						}
						if (process.env.USE_FACEBOOK_TRACKING) {
							if (window.fbq) {
								if (step.facebookCustom) {
									window.fbq('trackCustom', step.facebookCustom);
									if (businessType && step.facebookCustomByType[businessType]) {
										window.fbq(
											'trackCustom',
											step.facebookCustomByType[businessType]
										);
									}
								}
								if (step.facebook) {
									window.fbq('track', step.facebook);
								}
							}
						}
						if (process.env.USE_PINTEREST_TRACKING && !!step.pinterest) {
							if (window.pintrk) {
								window.pintrk('track', step.pinterest);
							}
						}
						if (process.env.DEBUG_ANALYTICS) {
							console.log(
								`%c# Tracking google analytics event: ${JSON.stringify(step)}`,
								'color:#fb8c00;font-weight:bold;'
							);
						}
					}
				);
			}
		}
	}
);

export const withGoalEvents = WrappedComponent => props =>
	(
		<GoalEventsContext.Consumer>
			{goalEventsProps => <WrappedComponent {...goalEventsProps} {...props} />}
		</GoalEventsContext.Consumer>
	);
export const useGoalEvents = () => useContext(GoalEventsContext);
