/* eslint-disable no-undef */
import React, { useEffect } from 'react';

/**
 * Tag a Hotjar recording
 * @param tag {String} the name of the tag
 */
const setTagInHotjar = tag => {
	try {
		if (process.env.USE_HOTJAR) {
			window.hj =
				window.hj ||
				function () {
					(hj.q = hj.q || []).push(arguments);
				};
			tag && hj('event', tag);
		}
	} catch (e) {
		if (process.env.NODE_ENV === 'development') {
			console.error('HOTJAR ERROR', e);
		}
	}
};

/**
 * Tag a Hotjar recording
 * @param tag {String} the name of the tag
 */
const useHotjarTag = tag => {
	useEffect(() => {
		setTagInHotjar(tag);
	}, [tag]);
};

/**
 * Lets you wrap your component so you have access to hotjar tagging method
 * @param tag {String} the name of the tag
 * @param Component
 * @returns {function(*): *}
 */
const withHotjarTag = (tag, Component) => props => {
	useHotjarTag(tag);
	return <Component {...props} />;
};

const UseHotJar = ({ feature, children }) => {
	useHotjarTag(feature);
	return children;
};

export { useHotjarTag, withHotjarTag, UseHotJar, setTagInHotjar };
