// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rejected_review-module_alert-UNX3K{width:100%;background-color:var(--grey-100);margin:10px 0 11px;padding:10px 7px 14px}.rejected_review-module_alert-UNX3K>div:first-child{margin-bottom:6px}.rejected_review-module_alert-UNX3K>div>span{font-weight:var(--bold)}.rejected_review-module_alert-UNX3K .rejected_review-module_spaceMe-wTOvm{margin-left:4px}", "",{"version":3,"sources":["webpack://./../../packages/components/my_account_page/appointment_preview/review/rejected_review.module.scss"],"names":[],"mappings":"AAAA,oCACC,UAAA,CACA,gCAAA,CACA,kBAAA,CACA,qBAAA,CAGC,oDACC,iBAAA,CAGD,6CACC,uBAAA,CAIF,0EACC,eAAA","sourcesContent":[".alert {\n\twidth: 100%;\n\tbackground-color: var(--grey-100);\n\tmargin: 10px 0 11px;\n\tpadding: 10px 7px 14px;\n\n\t& > div {\n\t\t&:first-child {\n\t\t\tmargin-bottom: 6px;\n\t\t}\n\n\t\t& > span {\n\t\t\tfont-weight: var(--bold);\n\t\t}\n\t}\n\n\t.spaceMe {\n\t\tmargin-left: 4px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "rejected_review-module_alert-UNX3K",
	"spaceMe": "rejected_review-module_spaceMe-wTOvm"
};
module.exports = ___CSS_LOADER_EXPORT___;
