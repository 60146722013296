import React from 'react';

export const MD = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5809)'>
				<mask
					id='mask0_11534_5809'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5809)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M13 0H20V15H13V0Z'
						fill='#D9071E'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0H7V15H0V0Z'
						fill='#3D58DB'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M6 0H14V15H6V0Z'
						fill='#FBCD17'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M13.574 12.2588L12.9158 12.6184L10.7342 8.62545L11.3924 8.26585L13.574 12.2588ZM6.57091 12.3549L7.22909 12.7145L9.41068 8.72146L8.75251 8.36187L6.57091 12.3549Z'
						fill='#FD1900'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M7.11129 5.86814V9.33771C7.11129 10.1085 6.44764 11.1129 6.11293 11.4866C5.77822 11.8603 5.11345 12.7164 5.11345 12.7164V3.3802C5.11345 2.33297 6.11293 1.97323 6.11293 1.97323C6.11293 1.97323 6.94282 2.13741 7.11129 3.3802V4.59143C7.36816 4.79371 8.22588 5.41407 8.77997 5.15219C9.43786 4.84125 9.6651 3.9494 9.6651 3.9494C9.6651 3.9494 9.60358 3.04947 9.33354 3.04947C9.06351 3.04947 9.04194 1.7818 10.0761 1.7818C11.1102 1.7818 11.2308 2.27044 11.2308 2.65995C11.2308 2.86413 11.0671 3.19182 10.9113 3.50365C10.77 3.78669 10.6351 4.05668 10.6351 4.20938C10.6351 4.53031 10.8769 5.15219 11.2308 5.15219C11.4789 5.15219 12.6726 4.68844 13.3635 4.41143V3.3802C13.5319 2.13741 14.3618 1.97323 14.3618 1.97323C14.3618 1.97323 15.3613 2.33297 15.3613 3.3802V12.7164C15.3613 12.7164 14.6965 11.8603 14.3618 11.4866C14.0271 11.1129 13.3635 10.1085 13.3635 9.33771V5.86814H7.11129ZM8.21887 12.3951C8.89486 11.955 9.8837 9.98134 9.8837 9.98134L10.212 10.0576C10.212 10.0576 10.7133 11.3649 12.1873 12.3951C10.6162 12.8144 10.212 13.6563 10.212 13.6563C10.212 12.9828 8.21887 12.3951 8.21887 12.3951ZM8.11001 11.1458C8.56564 11.1458 8.93501 10.7961 8.93501 10.3646C8.93501 9.93311 8.56564 9.58333 8.11001 9.58333C7.65437 9.58333 7.28501 9.93311 7.28501 10.3646C7.28501 10.7961 7.65437 11.1458 8.11001 11.1458ZM12.95 10.3646C12.95 10.7961 12.5806 11.1458 12.125 11.1458C11.6694 11.1458 11.3 10.7961 11.3 10.3646C11.3 9.93311 11.6694 9.58333 12.125 9.58333C12.5806 9.58333 12.95 9.93311 12.95 10.3646Z'
						fill='#A77B3B'
					/>
					<path
						d='M15.7125 6.50478L16.2862 6.75275L13.949 12.1602L13.3753 11.9122L15.7125 6.50478Z'
						fill='#FDFF00'
					/>
					<path
						opacity='0.3'
						fillRule='evenodd'
						clipRule='evenodd'
						d='M6.9 4.89584H13.5V9.67014C13.5 9.67014 11.8206 10.176 10.2 11.0061C9.00373 10.1186 6.9 9.67014 6.9 9.67014V4.89584Z'
						fill='#E1E5E8'
					/>
					<mask
						id='mask1_11534_5809'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='6'
						y='4'
						width='8'
						height='8'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M6.9 4.89584H13.5V9.67014C13.5 9.67014 11.8206 10.176 10.2 11.0061C9.00373 10.1186 6.9 9.67014 6.9 9.67014V4.89584Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_11534_5809)'>
						<rect
							x='6.9'
							y='8.02084'
							width='6.6'
							height='3.125'
							fill='#3D58DB'
						/>
						<rect
							x='6.9'
							y='4.89584'
							width='6.6'
							height='3.125'
							fill='#FD1900'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M9.86666 6.30134L9.36645 6.34273L9.65495 5.95357L9.34751 5.55054L9.85026 5.61528L10.043 5.17086L10.2573 5.62475L10.7575 5.58336L10.469 5.97252L10.7764 6.37554L10.2737 6.31081L10.0809 6.75523L9.86666 6.30134Z'
							fill='#FDFF00'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M8.96876 5.41981C8.96876 5.41981 7.62507 6.23731 8.07296 6.82414C8.52086 7.41097 9.08466 7.48609 9.08466 7.48609C9.08466 7.48609 7.71016 9.54816 10.1293 10.7262C12.7193 9.58897 11.3572 7.48609 11.3572 7.48609C11.3572 7.48609 12.1545 7.30971 12.3141 6.6752C12.4738 6.04068 11.272 5.31292 11.272 5.31292C11.272 5.31292 12.0218 6.39967 11.8506 6.6752C11.6795 6.95073 10.7625 7.23124 10.1293 7.23124C9.49616 7.23124 8.5184 7.05345 8.36061 6.57199C8.20281 6.09053 8.96876 5.41981 8.96876 5.41981Z'
							fill='#FDFF00'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M7.725 7.5L8.55 8.02083L7.725 8.54167L6.9 8.02083L7.725 7.5Z'
							fill='#FDFF00'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M12.675 7.5L13.5 8.02083L12.675 8.54167L11.85 8.02083L12.675 7.5Z'
							fill='#FDFF00'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M7.7 9.5625C7.83807 9.5625 7.95 9.45057 7.95 9.3125C7.95 9.17443 7.83807 9.0625 7.7 9.0625C7.56193 9.0625 7.45 9.17443 7.45 9.3125C7.45 9.45057 7.56193 9.5625 7.7 9.5625Z'
							fill='#FDFF00'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M13.0188 9.08924C12.8042 8.91546 12.4752 8.96595 12.2841 9.20201C12.0929 9.43807 12.1119 9.77032 12.3265 9.9441C12.4431 10.0386 12.3823 9.66328 12.5588 9.42451C12.7071 9.22396 13.1167 9.16857 13.0188 9.08924Z'
							fill='#FDFF00'
						/>
					</g>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M2.9604 7.20561C2.9604 7.20561 3.61923 7.9845 4.23958 7.72983C4.85994 7.47517 4.70953 6.56216 4.70953 6.56216L4.23958 6.98849H3.83955V7.20561L3.52711 6.98849V7.20561H2.9604ZM3.40598 8.40253C3.40598 8.40253 4.20897 9.10286 4.74997 8.90495C5.29096 8.70705 5.15511 7.75908 5.15511 7.75908L4.68516 8.18541H4.28512V8.40253L3.97269 8.18541V8.40253H3.40598ZM5.56836 9.99183C5.02737 10.1897 4.22438 9.4894 4.22438 9.4894H4.79108V9.27228L5.10352 9.4894V9.27228H5.50356L5.97351 8.84595C5.97351 8.84595 6.10936 9.79392 5.56836 9.99183Z'
						fill='#048F02'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M8.73709 1.4053H8.11209V1.77755H7.81791V2.40255H8.11209V3.49294H8.73709V2.40255H9.09469V1.77755H8.73709V1.4053Z'
						fill='#FDFF00'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.47382 2.11534L8.02623 2.48825L9.47382 2.94567V2.11534Z'
						fill='#DB4400'
					/>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5809'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
