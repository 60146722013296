import { Localize } from '@planity/components';
import classNames from 'classnames/bind';
import React from 'react';
import styles from './account_tab.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';

export function AccountTab({
	setCurrent,
	isCurrent,
	localizedText,
	text,
	onClick,
	id,
	tabName,
	isDark
}) {
	useStyles(styles);
	const classes = classNames.bind(styles);

	return (
		<li
			onClick={onClick || setCurrent}
			className={classes({
				tab: true,
				activeTab: isCurrent,
				[tabName]: tabName,
				widget: process.env.WIDGET,
				isDark
			})}
			id={id || tabName}
		>
			{localizedText ? <Localize text={localizedText} /> : text}
		</li>
	);
}
