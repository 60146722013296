// Mapbox is loaded in <script> tag
/* eslint-disable no-undef */
// noinspection JSUnresolvedVariable

import {
	AVAILABLE_LANGUAGES,
	DEFAULT_LANGUAGE,
	withLocalization
} from '@planity/localization';
import React, { Children, Component, cloneElement, createRef } from 'react';
import credentials from '@planity/credentials';
import { withScriptMap } from './provider';
import { Spinner } from '@planity/ui';

export const MapboxMap = withScriptMap(
	withLocalization(
		class MapboxMap extends Component {
			mapRef = createRef();
			state = {
				map: null,
				zoomIn: null,
				zoomOut: null
			};
			componentDidMount() {
				this.props.loadMap();
				if (this.props.readyToRender) {
					this.defineComponent();
				}
			}

			defineComponent() {
				const { language, customTheme } = this.props;
				const theme =
					customTheme ||
					'mapbox://styles/planity/ckiobzyqt31so17l2hp7nsuqw?optimize=true';
				if (typeof mapboxgl !== 'undefined') {
					mapboxgl.accessToken = credentials.MAPBOX_API_KEY;
					if (this.mapRef.current) {
						const map = new mapboxgl.Map({
							container: this.mapRef.current,
							style: theme,
							zoom: this.props.zoom,
							center: [this.props.longitude, this.props.latitude],
							pitchWithRotate: false,
							scrollZoom: false,
							dragRotate: false,
							doubleClickZoom: false,
							touchZoomRotate: true
						});
						if (!this.props.isMobile) {
							map.addControl(
								new mapboxgl.NavigationControl({ showCompass: false }),
								'bottom-right'
							);
							this.setState(
								{
									zoomIn: document.getElementsByClassName(
										'mapboxgl-ctrl-zoom-in'
									)?.[0],
									zoomOut: document.getElementsByClassName(
										'mapboxgl-ctrl-zoom-out'
									)?.[0]
								},
								() => this.applyNavigationControlStyle()
							);
						}
						if (map.touchZoomRotate) {
							map.touchZoomRotate.disableRotation();
						}
						map.on('dragend', this.onDragEnd);
						map.on('zoomend', this.onZoomEnd);
						if (typeof MapboxLanguage !== 'undefined') {
							map.addControl(
								new MapboxLanguage({
									supportedLanguages: AVAILABLE_LANGUAGES,
									defaultLanguage: language || DEFAULT_LANGUAGE
								})
							);
						}
						this.setState({ map });
					}
				}
			}
			shouldComponentUpdate(prevProps) {
				return (
					!this.state.map ||
					!this.state.map.isZooming() ||
					this.props.width !== prevProps.width ||
					this.props.height !== prevProps.height ||
					this.props.readyToRender !== prevProps.readyToRender
				);
			}
			componentDidUpdate(prevProps) {
				if (!prevProps.readyToRender && this.props.readyToRender) {
					return this.defineComponent();
				}
				const { map } = this.state;
				const { latitude, longitude, zoom, boundsContext } = this.props;
				const {
					latitude: latitudeWas,
					longitude: longitudeWas,
					zoom: zoomWas
				} = prevProps;
				if (shouldJump(boundsContext, map)) {
					if (
						latitude !== latitudeWas ||
						longitude !== longitudeWas ||
						zoom !== zoomWas
					) {
						if (map && map.jumpTo) {
							map.jumpTo({
								zoom,
								center: [longitude, latitude]
							});
						}
					}
				}
			}
			componentWillUnmount() {
				const { map, zoomIn, zoomOut } = this.state;
				if (map) {
					map.off('dragend', this.onDragEnd);
					map.off('zoomend', this.onZoomEnd);
					map.remove();
				}
				if (zoomIn) {
					zoomIn.removeEventListener('mouseover', this.handleOver);
				}
				if (zoomOut) {
					zoomOut.removeEventListener('mouseover', this.handleOver);
				}
			}
			render() {
				const { children, height } = this.props;
				if (!this.props.readyToRender) {
					return (
						<div
							css={{
								display: 'flex',
								justifyContent: 'center',
								marginTop: 25
							}}
						>
							<Spinner />
						</div>
					);
				}
				return (
					<div
						css={{
							width: '100%',
							height,
							overflow: 'hidden',
							position: 'relative'
						}}
						ref={this.mapRef}
					>
						{Children.map(children, child =>
							child ? cloneElement(child, { map: this.state.map }) : child
						)}
					</div>
				);
			}
			handleOver = event => {
				event.target.style.backgroundColor = 'unset';
			};
			applyNavigationControlStyle = () => {
				const { zoomIn, zoomOut } = this.state;
				const zoomIconContainer = document.getElementsByClassName(
					'mapboxgl-ctrl mapboxgl-ctrl-group'
				)?.[0];

				if (zoomIconContainer) {
					zoomIconContainer.style.position = 'absolute';
					zoomIconContainer.style.bottom = '48px';
					zoomIconContainer.style.right = '24px';
					zoomIconContainer.style.margin = 0;
					zoomIconContainer.style.boxShadow = 'unset';
					zoomIconContainer.style.backgroundColor = 'unset';
				}

				if (zoomIn) {
					const zoomInIcon =
						zoomIn.getElementsByClassName('mapboxgl-ctrl-icon')?.[0];
					if (zoomInIcon) zoomInIcon.style.backgroundImage = 'unset';

					zoomIn.style.background =
						'50% 10% url("https://res.cloudinary.com/planity/image/upload/v1670855250/icon/Zoom_.svg")';
					zoomIn.style.height = '32px';
					zoomIn.style.width = '32px';
					zoomIn.style.borderRadius = '50%';
					zoomIn.style.marginBottom = '8px';
					zoomIn.addEventListener('mouseover', this.handleOver);
				}

				if (zoomOut) {
					const zoomOutIcon =
						zoomOut.getElementsByClassName('mapboxgl-ctrl-icon')?.[0];
					if (zoomOutIcon) zoomOutIcon.style.backgroundImage = 'unset';

					zoomOut.style.background =
						'50% 10% url("https://res.cloudinary.com/planity/image/upload/v1670855250/icon/Zoom_-.svg")';
					zoomOut.style.height = '32px';
					zoomOut.style.width = '32px';
					zoomOut.style.borderRadius = '50%';
					zoomOut.style.border = 'unset';
					zoomOut.addEventListener('mouseover', this.handleOver);
				}
			};
			onDragEnd = () => {
				requestAnimationFrame(() => {
					this.onViewportChange();
				});
			};
			onZoomEnd = () => {
				requestAnimationFrame(() => {
					if (!this.state.map.isZooming()) {
						this.onViewportChange();
					}
				});
			};
			onViewportChange() {
				if (this.props.onViewportChange) {
					const { map } = this.state;
					if (map) {
						const center = map.getCenter();
						const { latitude, longitude, zoom } = this.props;
						if (
							zoom !== map.getZoom() ||
							withPrecision(latitude) !== withPrecision(center.lat) ||
							withPrecision(longitude) !== withPrecision(center.lng)
						) {
							requestAnimationFrame(() => {
								this.props.onViewportChange(map.getBounds());
							});
						}
					}
				}
			}
		}
	)
);

function withPrecision(coord) {
	if (!coord) {
		return coord;
	}
	return coord.toFixed(6);
}

function shouldJump(bounds, map) {
	if (!bounds) {
		return true;
	}
	const mapBounds = map && map.getBounds();
	if (!mapBounds) {
		return true;
	}
	const ne = mapBounds.getNorthEast();
	const sw = mapBounds.getSouthWest();
	return (
		withPrecision(ne.lat) !== withPrecision(bounds.ne.lat) ||
		withPrecision(ne.lng) !== withPrecision(bounds.ne.lng) ||
		withPrecision(sw.lat) !== withPrecision(bounds.sw.lat) ||
		withPrecision(sw.lng) !== withPrecision(bounds.sw.lng)
	);
}

export MapMarker from './marker';
export MapUserMarker from './userMarker';

export Map from './map';
