export function Pin() {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M15.2084 9.16665C15.2084 12.5 10.0001 16.0416 10.0001 16.0416C10.0001 16.0416 4.79175 12.5 4.79175 9.16665C4.79175 6.24998 7.23866 3.95831 10.0001 3.95831C12.7615 3.95831 15.2084 6.24998 15.2084 9.16665Z'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M11.875 9.16669C11.875 10.2022 11.0355 11.0417 10 11.0417C8.96447 11.0417 8.125 10.2022 8.125 9.16669C8.125 8.13115 8.96447 7.29169 10 7.29169C11.0355 7.29169 11.875 8.13115 11.875 9.16669Z'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
