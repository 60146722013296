import { invokeLambda } from '@planity/helpers';
import { firebase } from '@planity/datastores';

export default ({
	userId,
	paymentMethod,
	last4,
	selectedServices,
	totalPrice,
	message,
	selectedTemplate,
	businessId,
	singlePageApp,
	typeSelected,
	currentIntentId,
	paymentIntentId,
	variant
}) => {
	const isWhiteLabel = process.env.WIDGET;
	return new Promise((resolve, reject) => {
		firebase
			.auth()
			.currentUser.getIdToken()
			.then(userToken => {
				invokeLambda('buyGiftVoucher', {
					businessId,
					singlePageApp,
					userId,
					firebaseToken: userToken,
					type: typeSelected,
					paymentMethod,
					totalPrice: Math.round(
						parseFloat(totalPrice.replace(',', '.')) * 100
					),
					selectedServices: selectedServices,
					message,
					last4,
					isWhiteLabel,
					chargeId: currentIntentId,
					paymentIntentId,
					newPaymentFlow: true,
					variant: variant
				})
					.then(response => {
						if (response.errorMessage) {
							throw response.errorMessage;
						}

						if (JSON.stringify(response) === JSON.stringify({}))
							resolve(response);
					})
					.catch(e => {
						console.warn(e);
						let objectError = null;

						try {
							objectError = JSON.parse(e);
						} catch (e) {
							console.error(e);
						}

						if (
							!!objectError &&
							objectError.errorMessage === 'REQUIRE_NEXT_ACTION_ERROR'
						) {
							reject(objectError);
						}

						reject(e);
					});
			});
	});
};
