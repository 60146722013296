import React from 'react';
import { Button, Form, Inputs, Link, PasswordInput } from '@planity/components';
import {
	PASSWORD_CHANGE,
	RESET_MACHINE,
	USER_PASSWORD_SUBMIT
} from '../../auth_actions';
import { breakpoints } from '@planity/theme';
import { FORGOTTEN_PASSWORD } from '../../form';
import { Localize } from '@planity/components';
import { useFormHooks } from '../useFormHooks';

export const LoginWithSetPhoneInDatabase = () => {
	const { state, dispatch, formValues } = useFormHooks();
	const { pending, initialSignInWithError, children, handleChangeAuthType } =
		formValues;
	return (
		<Form>
			{children}
			<p>
				<Localize text={'auth.texts.usePasswordAuth'} />
			</p>
			<Inputs>
				<PasswordInput
					autoFocus={!!initialSignInWithError}
					localizedPlaceholder={'auth.form.placeholders.password'}
					value={state?.context?.password || ''}
					onChange={passwordInput =>
						dispatch({
							type: PASSWORD_CHANGE,
							password: passwordInput
						})
					}
				/>
			</Inputs>
			<div>
				<Button
					css={css.button}
					isActive={pending}
					localizedText={
						pending ? 'auth.form.buttons.pending' : 'auth.form.buttons.submit'
					}
					onPress={() => dispatch({ type: USER_PASSWORD_SUBMIT })}
				/>
			</div>
			<div css={css.bottomLinksContainer}>
				<Link
					css={css.backToSignInLink}
					localizedText={`auth.form.links.BACK_TO_SIGN_IN`}
					onPress={() => dispatch({ type: RESET_MACHINE })}
				/>
				<Link
					css={css.forgottenPasswordLink}
					localizedText={`auth.form.links.${FORGOTTEN_PASSWORD}`}
					onPress={() => handleChangeAuthType(FORGOTTEN_PASSWORD)}
				/>
			</div>
		</Form>
	);
};
const css = {
	button: {
		marginBottom: 18,
		width: '100%'
	},
	bottomLink: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'center',
		fontSize: 16,
		lineHeight: 1.13
	},
	bottomLinksContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'center',
		fontSize: 16,
		lineHeight: 1.13,
		[breakpoints.tabletQuery]: {
			flexDirection: 'row',
			justifyContent: 'space-between'
		}
	},
	backToSignInLink: {
		marginBottom: 18,
		[breakpoints.tabletQuery]: {
			marginBottom: 0
		}
	},
	forgottenPasswordLink: {
		lineHeight: 1.5
	}
};
