export function AirplayToTv() {
	return (
		<svg
			fill='none'
			height='24'
			viewBox='0 0 24 24'
			width='24'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.5'
			>
				<path d='m16.75 17.25h.5c1.1046 0 2-.8954 2-2v-8.5c0-1.10457-.8954-2-2-2h-10.5c-1.10457 0-2 .89543-2 2v8.5c0 1.1046.89543 2 2 2h.5' />
				<path d='m12 15.75-3 3.5h6z' />
			</g>
		</svg>
	);
}
