import React from 'react';
import IconWrapper from './icon_wrapper';
import { colors } from '@planity/theme';

export default ({ viewBox, size, color, ...props }) => (
	<IconWrapper
		{...props}
		code={
			'M10 0C4.477 0 0 4.505 0 10.061 0 15.083 3.657 19.245 8.438 20v-7.03h-2.54V10.06h2.54V7.845c0-2.522 1.492-3.915 3.777-3.915 1.094 0 2.238.197 2.238.197v2.476h-1.26c-1.243 0-1.63.775-1.63 1.57v1.888h2.773l-.443 2.908h-2.33V20c4.78-.755 8.437-4.917 8.437-9.939C20 4.505 15.523 0 10 0z'
		}
		color={color || colors.white}
		size={size || 20}
		viewBox={viewBox || '0 0 20 20'}
	/>
);
