import React from 'react';
import { Layout } from '../layout';
import { useTranslation, useLocalization } from '@planity/localization';
import { BusinessTitle, Container } from '@planity/ui';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './legal_page.module.scss';

export function LegalPage() {
	const { t } = useTranslation();
	useStyles(styles);
	const classes = classNames.bind(styles)({
		legal: true
	});

	const { locale } = useLocalization();

	return (
		<div className={classes}>
			<Layout
				breadcrumbsAtTop
				crumbName={'legal'}
				isDark
				showAppTeaser
				skipCoronavirusModal
			>
				<Container>
					<BusinessTitle path={'common.cguTitle'} />
					<div className={styles.container}>
						<iframe src={t(locale === 'fr-BE' ? 'links.cguBE' : 'links.cgu')} />
					</div>
				</Container>
			</Layout>
		</div>
	);
}
