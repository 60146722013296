import React from 'react';

export const LogOutIcon = () => {
	return (
		<svg
			fill='none'
			height='24'
			viewBox='0 0 25 24'
			width='25'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M16.55 8.75L20.05 12L16.55 15.25'
				stroke={'currentColor'}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.5'
			/>
			<path
				d='M19.8 12H11.55'
				stroke={'currentColor'}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.5'
			/>
			<path
				d='M16.05 4.75H7.54999C6.44542 4.75 5.54999 5.64543 5.54999 6.75V17.25C5.54999 18.3546 6.44542 19.25 7.54999 19.25H16.05'
				stroke={'currentColor'}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.5'
			/>
		</svg>
	);
};
