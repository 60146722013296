/**
 * Creates an object sorted based on the comparator function
 * @param {*} obj object to sort
 * @param {function} comparator comparator function
 */
export function sortObj(obj, comparator) {
	return Object.keys(obj)
		.sort(comparator)
		.reduce((acc, key) => ({ ...acc, [key]: obj[key] }), {});
}

/**
 * Compute a new array based on the maxSize and the tolerance
 * @param {any[]} array
 * @param {number} maxSize
 * @param {number} tolerance
 * @returns an array of maxSize + tolerance elements
 */
export function computeNewArrayWithFixedSize(array, maxSize, tolerance = 0) {
	const currentArray = [...array]; // make a copy of the array
	let initialList = currentArray.splice(0, maxSize);
	const rest = array.length - maxSize;

	if (rest > 0 && rest === tolerance) {
		initialList = [...array];
	}

	return initialList;
}
