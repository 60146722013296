import classNames from 'classnames/bind';
import styles from './order_header.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';
import { Address } from '@planity/components';
import { noop } from '@planity/helpers';

export function OrderHeader({
	boughtAt,
	orderId,
	orderStatus,
	localizedStatus,
	businessName,
	trackingNumber,
	linkToBusiness,
	trackingUrl,
	business
}) {
	useStyles(styles);
	const { t } = useTranslation();
	const classes = classNames.bind(styles)({
		orderHeader: true
	});

	return (
		<div className={classes}>
			<div className={styles.top}>
				<div className={styles.topLeft}>
					<span className={styles.state} id='order-header-status'>
						{localizedStatus ? t(localizedStatus) : `${orderStatus}`}
					</span>
					<span
						className={styles.title}
						id='order-header-businessName'
						onClick={() =>
							process.env.WIDGET ? noop() : linkToBusiness(business)
						}
					>
						{businessName}
					</span>
					<span className={styles.iconText} id='order-header-linkToBusiness'>
						<Address
							{...business.address}
							location={business.location}
							shouldOpenMap
							title={business.name}
						/>
					</span>
				</div>
				<div className={styles.topRight}>
					<div className={styles.info}>
						<span>{t('myAccount.orders.creationDate')}</span>
						<span id='order-header-boughtAt'>{boughtAt}</span>
					</div>
					<div className={styles.info}>
						<span>{t('myAccount.orders.orderId')}</span>
						<span id='order-header-orderId'>{orderId}</span>
					</div>
					{!!trackingNumber && (
						<div className={styles.info}>
							<span>{t('myAccount.orders.trackingNumber')}</span>
							<a
								href={trackingUrl}
								id='order-header-trackingNumber'
								target='blank'
							>
								{trackingNumber}
							</a>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
