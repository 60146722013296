// from https://github.com/Sembiance/email-validator/blob/master/index.js
const emailRE =
	/^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
export function isValidEmail(email, isRequired) {
	if (email) {
		const isValid = emailRE.test(email);
		if (!isValid) return false;
		const parts = email.split('@');
		if (parts[0].length > 64) return false;
		const domainParts = parts[1].split('.');
		if (domainParts.some(part => part.length > 63)) return false;
		return true;
	} else {
		return !isRequired;
	}
}
