import React, { useEffect, useState } from 'react';
import { useAuth } from '@planity/components';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import { BookAppointmentTitle } from '@planity/components';
import { noop } from '@planity/helpers';
import { SignedIn } from './signed_in';
import { SignedOut } from './signed_out';
import styles from './appointment_user.module.scss';
import { useAllChildren } from '@planity/helpers/hooks/use_all_children';

export const AppointmentUser = ({
	appointment,
	bookedByToDelete,
	bookedForToDelete,
	business,
	conditionsLink,
	isBookAppointment = false,
	isPending,
	onSignOut,
	onSignUp,
	setHasConfirmableUser,
	style,
	title,
	userContainerRef,
	isPrePaymentToDelete,
	moveAppointment,
	paymentCreatedDateToDelete,
	totalPriceToDelete,
	totalPrice,
	isOnline,
	isDark,
	phoneError,
	setSelectedUser = noop,
	selectedUser
}) => {
	useStyles(styles);
	const [refetchChildren, setRefetchChildren] = useState(true);

	const { isSignedIn, isVerified, user, userId, isPro } = useAuth();

	// When parent update his profile info, it might have an impact on children info too
	// therefore we need to load again all of his children with a delay because DB is slow to udpate :(
	useEffect(() => {
		if (refetchChildren) return;
		const timer = setTimeout(() => {
			setRefetchChildren(true);
		}, 5000);
		return () => clearTimeout(timer);
	}, [refetchChildren]);

	const {
		children: userChildren,
		setChildren,
		loading: isLoadingChildren
	} = useAllChildren(
		userId,
		user?.hasChildren && refetchChildren && isBookAppointment
	);

	const resetSelectedUser = () => setSelectedUser(null);

	const date = appointment?.date;

	const classes = classNames.bind(styles);

	// appointment with no selected date, don't display user informations yet
	if (isBookAppointment && !date) {
		return null;
	}

	const titleSection = isBookAppointment ? (
		// book appointment
		<BookAppointmentTitle
			index={'3.'}
			isDark={isDark}
			text={title || 'bookAppointment.userTitle'}
		/>
	) : (
		// gift voucher or click and collect
		<BookAppointmentTitle
			index={title?.index || '4.'}
			isDark={isDark}
			text={title?.title || 'bookAppointment.giftUserTitle'}
		/>
	);

	return (
		<div
			className={classes({ appointmentUser: isBookAppointment })}
			ref={userContainerRef}
		>
			{titleSection}
			{isSignedIn ? (
				<SignedIn
					authBooking
					bookedByToDelete={bookedByToDelete}
					bookedForToDelete={bookedForToDelete}
					countryCode={business?.countryCode || 'FR'}
					hasPhoneError={phoneError}
					isBookAppointment={isBookAppointment}
					isLoadingChildren={isLoadingChildren}
					isOnline={isOnline}
					isPending={isPending}
					isPrePaymentToDelete={isPrePaymentToDelete}
					isPro={isPro}
					isVerified={isVerified}
					moveAppointment={moveAppointment}
					paymentCreatedDateToDelete={paymentCreatedDateToDelete}
					selectedUser={selectedUser}
					setChildren={setChildren}
					setHasConfirmableUser={setHasConfirmableUser}
					setRefetchChildren={setRefetchChildren}
					setSelectedUser={setSelectedUser}
					totalPrice={totalPrice}
					totalPriceToDelete={totalPriceToDelete}
					user={user}
					userChildren={userChildren}
					userId={userId}
					onSignOut={isBookAppointment ? resetSelectedUser : onSignOut}
				/>
			) : (
				<SignedOut
					authBooking
					business={business}
					conditionsLink={conditionsLink}
					defaultCountry={(business && business.countryCode) || 'FR'}
					style={style}
					user={user}
					userId={userId}
					onBooking
					onSignUp={onSignUp}
				/>
			)}
		</div>
	);
};
