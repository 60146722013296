import styles from './highlight.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { Text } from './text';

export function Highlight({ highlight, className, style }) {
	const classes = classNames.bind(styles)({
		highlight: true,
		[className]: className
	});
	useStyles(styles);

	if (!highlight) return null;
	return (
		<div className={classes}>
			<Text dangerouslyHTML={{ __html: highlight }} style={style} />
		</div>
	);
}
