import { sendToUserApp } from '@planity/webview';
import { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { getLocalizedBasePath } from '@planity/localization';
import { removeQueryFromParams } from '@planity/helpers';

//* Any change in this file needs to be adjusted in its middleware counterpart : redirect_reset_password.js
const langQuery = 'lang';

export const WebviewRedirectResetPassword = () => {
	const location = useLocation();

	const searchParams = new URLSearchParams(location.search);
	const lang = searchParams.get(langQuery);
	const localePath = getLocalizedBasePath({ language: lang });
	const valideLocalePath = localePath === 'fr-FR' ? '' : `/${localePath}`;

	const cleanedSearchParam = removeQueryFromParams(searchParams, langQuery);

	useEffect(() => {
		sendToUserApp({
			type: '300_REDIRECTION',
			payload: {
				to: `${window.location.origin}${valideLocalePath}${location.pathname}?${cleanedSearchParam}`
			}
		});
	}, []);
	return (
		<Redirect
			to={`${valideLocalePath}${location.pathname}?${cleanedSearchParam}`}
		/>
	);
};
