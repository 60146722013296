// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".no_availabilities-module_container-YLxHl{margin:18px 0;padding:20px 16px 20px 12px;width:max-content;display:flex;justify-content:center;align-items:center;background-color:var(--grey-200);text-align:center}@media(max-width: 767px){.no_availabilities-module_container-YLxHl{padding:20px 10px;background-color:var(--grey-200);border-radius:8px}}.no_availabilities-module_iconContainer-4bB6v{margin-right:4px}.no_availabilities-module_noAvailabilityText-vm7XW{border-radius:8px;font:var(--medium) var(--body-4);color:var(--grey-600)}.no_availabilities-module_icon-0goqa{display:block;color:var(--grey-600);font:var(--medium) var(--body-4)}", "",{"version":3,"sources":["webpack://./app/components/search_page/days_availabilities/no_availabilities.module.scss"],"names":[],"mappings":"AAGA,0CACC,aAAA,CACA,2BAAA,CACA,iBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,gCAAA,CAEA,iBAAA,CACA,yBAVD,0CAWE,iBAAA,CACA,gCAAA,CACA,iBAAA,CAAA,CAIF,8CACC,gBAAA,CAGD,mDACC,iBAAA,CACA,gCAAA,CACA,qBAAA,CAGD,qCACC,aAAA,CACA,qBAAA,CACA,gCAAA","sourcesContent":["@use '@planity/ui/styles/utilities/mixins';\n@use '@planity/ui/styles/utilities/variables';\n\n.container {\n\tmargin: 18px 0;\n\tpadding: 20px 16px 20px 12px;\n\twidth: max-content;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tbackground-color: var(--grey-200);\n\n\ttext-align: center;\n\t@media (max-width: #{variables.$tablet - 1px}) {\n\t\tpadding: 20px 10px;\n\t\tbackground-color: var(--grey-200);\n\t\tborder-radius: 8px;\n\t}\n}\n\n.iconContainer {\n\tmargin-right: 4px;\n}\n\n.noAvailabilityText {\n\tborder-radius: 8px;\n\tfont: var(--medium) var(--body-4);\n\tcolor: var(--grey-600);\n}\n\n.icon {\n\tdisplay: block;\n\tcolor: var(--grey-600);\n\tfont: var(--medium) var(--body-4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"container": "no_availabilities-module_container-YLxHl",
	"iconContainer": "no_availabilities-module_iconContainer-4bB6v",
	"noAvailabilityText": "no_availabilities-module_noAvailabilityText-vm7XW",
	"icon": "no_availabilities-module_icon-0goqa"
};
module.exports = ___CSS_LOADER_EXPORT___;
