import React from 'react';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './pagination.module.scss';
import { Icon } from '@planity/ui';
import { noop } from '@planity/helpers';
import { useTranslation } from '@planity/localization';

export function Pagination({
	className,
	total = 0,
	current,
	handleEventClick = noop
}) {
	useStyles(styles);
	const { t } = useTranslation();
	const pageCount = Math.ceil(total / 5);

	const classes = classNames.bind(styles);

	const hasPreviousResults = current > 1;
	const hasNextResults = current < pageCount;
	return (
		<div
			className={classes({
				pagination: true,
				[className]: className !== undefined
			})}
		>
			<div
				className={classes({ navItem: true, disabled: !hasPreviousResults })}
			>
				<Icon
					icon='ArrowLeft'
					disabled={!hasPreviousResults}
					onClick={() => handleEventClick('prev')}
					isClickable={hasPreviousResults}
				/>
				<span
					onClick={() => (hasPreviousResults ? handleEventClick('prev') : noop)}
				>
					{t('search.pagination.prevPage')}
				</span>
			</div>

			<div className={classes({ navItem: true, disabled: !hasNextResults })}>
				<span
					onClick={() => (hasNextResults ? handleEventClick('next') : noop)}
				>
					{t('search.pagination.nextPage')}
				</span>
				<Icon
					icon='ArrowRight'
					isClickable={hasNextResults}
					disabled={!hasNextResults}
					onClick={() => handleEventClick('next')}
				/>
			</div>
		</div>
	);
}
