import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
	useCallback
} from 'react';
import { format } from 'date-fns';
import { useFutureAppointments } from './use_future_appointments';
import { useConfirmedAppointment } from './use_confirmed_appointment';
import { useUserBuyings } from './use_user_buyings';
import { usePastAppointments } from './use_past_appointments';
import { getBusinesses } from './get_businesses';
import { mergeAppointmentsData } from './helpers';
import { useAuth, useLastSuccessfulAppointment } from '@planity/components';
import { useOnlinePaymentFetcher } from './use_online_appointment_fetcher';
import { useCuresUserFetcher } from './use_cures_user_fetcher';
import { filterDataForWidget } from '@planity/helpers';
import { isNativeApp, sendToUserApp } from '@planity/webview';

/*
 * This context loads all user's appointments and buyings (and businesses attached to)
 * and split them by past - futur - confirmed
 */
const UserDataContext = createContext({});

export const useUserData = () => useContext(UserDataContext);

const NOW = format(new Date(), 'yyyy-MM-dd HH:mm');
const APPOINTMENT_PAGE_SIZE = 5;
const INITIAL_APPOINTMENT_PAGE_SIZE = process.env.WEBVIEW ? 3 : 5;
const INITIAL_IS_ALLOWED_TO_FETCH_DATA = !!process.env.WEBVIEW;

export const UserDataProvider = ({ children, businessId }) => {
	const { lastSuccessfulAppointmentId } = useLastSuccessfulAppointment();
	const { isLoading: userIsLoading, userId } = useAuth();
	const [isAllowedToFetchData, setIsAllowedToFetchData] = useState(
		INITIAL_IS_ALLOWED_TO_FETCH_DATA
	);
	const [pageSize, setPageSize] = useState(INITIAL_APPOINTMENT_PAGE_SIZE + 1);
	const [wasOnceLoading, setWasOnceLoading] = useState(false);
	const { curesData, curesDataAreLoading } = useCuresUserFetcher({
		userId,
		userIsLoading,
		businessId
	});

	useEffect(() => {
		// Cleanup effect
		setPageSize(INITIAL_APPOINTMENT_PAGE_SIZE + 1);
	}, [userId]);

	const { futureAppointments: futurApp, futureAppointmentsLoading } =
		useFutureAppointments({
			isAllowedToFetchData,
			startAt: NOW
		});

	const { pastAppointments: pastApp, pastAppointmentsLoading } =
		usePastAppointments({
			isAllowedToFetchData,
			pageSize,
			endAt: NOW
		});

	const { userBuyings: buyings, userBuyingsAreLoading } = useUserBuyings({
		isAllowedToFetchData
	});

	const {
		confirmedAppointment: confirmedApp,
		confirmedAppointmentLoading,
		confirmedAppointmentId
	} = useConfirmedAppointment({
		isAllowedToFetchData
	});

	const { businesses, businessesAreLoading } = getBusinesses({
		isAllowedToFetchData,
		data: {
			...curesData,
			...futurApp,
			...pastApp,
			...buyings,
			...confirmedApp
		}
	});
	const { onlinePaymentData, onlinePaymentDataIsLoading } =
		useOnlinePaymentFetcher({
			appointments: filterDataForWidget(
				confirmedApp
					? {
							...futurApp,
							...pastApp,
							...confirmedApp
					  }
					: { ...futurApp, ...pastApp }
			),
			businesses,
			hasLoaded:
				isAllowedToFetchData &&
				!confirmedAppointmentLoading &&
				!futureAppointmentsLoading &&
				!pastAppointmentsLoading &&
				!businessesAreLoading
		});

	const [allFutureAppointments, pastAppointments, userBuyings, userCures] = [
		futurApp,
		pastApp,
		buyings,
		curesData
	].map(appointments =>
		mergeAppointmentsData(appointments, onlinePaymentData, businesses)
	);

	const confirmedAppointment = !confirmedApp
		? {}
		: {
				...confirmedApp,
				...onlinePaymentData?.[confirmedAppointmentId],
				business: businesses[confirmedApp?.businessId]
		  };

	const futureAppointments = lastSuccessfulAppointmentId
		? Object.keys(allFutureAppointments || {}).reduce(
				(appointments, appointmentId) => {
					if (appointmentId !== lastSuccessfulAppointmentId) {
						appointments[appointmentId] = allFutureAppointments[appointmentId];
					}
					return appointments;
				},
				{}
		  )
		: allFutureAppointments;

	const hasLoaded =
		!userIsLoading &&
		isAllowedToFetchData &&
		wasOnceLoading &&
		!confirmedAppointmentLoading &&
		!futureAppointmentsLoading &&
		!userBuyingsAreLoading &&
		!curesDataAreLoading;

	const nativeAppHomeAppointmentsHasLoaded =
		!userIsLoading &&
		isAllowedToFetchData &&
		wasOnceLoading &&
		!confirmedAppointmentLoading &&
		!futureAppointmentsLoading &&
		!pastAppointmentsLoading;

	/* Effect to avoid blurps during first render */
	useEffect(() => {
		if (
			!(
				confirmedAppointmentLoading ||
				futureAppointmentsLoading ||
				userBuyingsAreLoading ||
				curesDataAreLoading ||
				businessesAreLoading ||
				pastAppointmentsLoading ||
				onlinePaymentDataIsLoading
			)
		) {
			setWasOnceLoading(true);
		}
	}, [
		confirmedAppointmentLoading ||
			futureAppointmentsLoading ||
			userBuyingsAreLoading ||
			curesDataAreLoading ||
			businessesAreLoading ||
			onlinePaymentDataIsLoading ||
			pastAppointmentsLoading
	]);

	useEffect(() => {
		if (isNativeApp && nativeAppHomeAppointmentsHasLoaded) {
			sendToUserApp({ type: 'USER_DATA_HAS_FULLY_LOADED' });
		}
	}, [nativeAppHomeAppointmentsHasLoaded, isNativeApp]);

	const fetchUserData = useCallback(
		options => {
			if (!isAllowedToFetchData) {
				if (options?.pageSize) setPageSize(options?.pageSize);
				setIsAllowedToFetchData(true);
			}
		},
		[isAllowedToFetchData]
	);

	const state = useMemo(
		() => ({
			allFutureAppointments,
			businessesAreLoading,
			confirmedAppointment,
			fetchUserData,
			futureAppointments,
			hasLoaded,
			loadMoreAppointments: () =>
				setPageSize(_pageSize => _pageSize + APPOINTMENT_PAGE_SIZE),
			nativeAppHomeAppointmentsHasLoaded,
			pageSize,
			pastAppointments,
			pastAppointmentsLoading,
			userBuyings,
			userCures
		}),
		[
			allFutureAppointments,
			businessesAreLoading,
			confirmedAppointment,
			futureAppointments,
			hasLoaded,
			nativeAppHomeAppointmentsHasLoaded,
			pageSize,
			pastAppointments,
			pastAppointmentsLoading,
			userBuyings,
			userCures
		]
	);

	return (
		<UserDataContext.Provider value={state}>
			{children}
		</UserDataContext.Provider>
	);
};
