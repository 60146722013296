import React from 'react';

export const AO = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5533)'>
				<mask
					id='mask0_11534_5533'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5533)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#1D1D1D'
					/>
					<mask
						id='mask1_11534_5533'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_11534_5533)'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V7.5H20V0H0Z'
							fill='#F50100'
						/>
						<g filter='url(#filter0_d_11534_5533)'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M9.71871 8.37008C10.2032 7.77132 10.355 6.99729 10.355 6.51428C10.355 3.94053 7.14378 2.51678 7.14378 2.51678C9.35153 2.51678 11.8105 4.30656 11.8105 6.51428C11.8105 7.44909 11.4896 8.31645 10.952 9.00589C11.9359 9.49668 12.7686 9.87968 12.7686 9.87968C13.0775 10.0823 13.1639 10.6225 12.9613 10.9316C12.7587 11.2405 12.3438 11.3267 12.035 11.1243C12.035 11.1243 11.2449 10.657 10.7635 10.3414C10.5429 10.1968 10.2877 10.04 10.0162 9.87936C9.38447 10.3046 8.62709 10.5537 7.81287 10.5537C7.81287 10.5537 4.87718 10.1941 4.9612 8.68875C4.9612 8.68875 5.6719 9.2324 7.7626 9.2324C8.09531 9.2324 8.38777 9.1858 8.64438 9.10391C7.73883 8.60976 6.97086 8.21808 6.97086 8.21808C6.66173 8.01555 5.85788 6.98862 6.06047 6.67953C6.26313 6.3706 7.48835 6.71982 7.79723 6.9223C7.79723 6.9223 8.22686 7.51232 8.83317 7.87306C9.10107 8.03243 9.4035 8.20103 9.71871 8.37008ZM8.23773 6.12651L7.60658 6.54706L7.80826 5.82676L7.34493 5.36664L7.9723 5.34176L8.23773 4.6295L8.50316 5.34176H9.12946L8.6672 5.82676L8.89871 6.50454L8.23773 6.12651Z'
								fill='#FCFF01'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M9.71871 8.37008C10.2032 7.77132 10.355 6.99729 10.355 6.51428C10.355 3.94053 7.14378 2.51678 7.14378 2.51678C9.35153 2.51678 11.8105 4.30656 11.8105 6.51428C11.8105 7.44909 11.4896 8.31645 10.952 9.00589C11.9359 9.49668 12.7686 9.87968 12.7686 9.87968C13.0775 10.0823 13.1639 10.6225 12.9613 10.9316C12.7587 11.2405 12.3438 11.3267 12.035 11.1243C12.035 11.1243 11.2449 10.657 10.7635 10.3414C10.5429 10.1968 10.2877 10.04 10.0162 9.87936C9.38447 10.3046 8.62709 10.5537 7.81287 10.5537C7.81287 10.5537 4.87718 10.1941 4.9612 8.68875C4.9612 8.68875 5.6719 9.2324 7.7626 9.2324C8.09531 9.2324 8.38777 9.1858 8.64438 9.10391C7.73883 8.60976 6.97086 8.21808 6.97086 8.21808C6.66173 8.01555 5.85788 6.98862 6.06047 6.67953C6.26313 6.3706 7.48835 6.71982 7.79723 6.9223C7.79723 6.9223 8.22686 7.51232 8.83317 7.87306C9.10107 8.03243 9.4035 8.20103 9.71871 8.37008ZM8.23773 6.12651L7.60658 6.54706L7.80826 5.82676L7.34493 5.36664L7.9723 5.34176L8.23773 4.6295L8.50316 5.34176H9.12946L8.6672 5.82676L8.89871 6.50454L8.23773 6.12651Z'
								fill='#FFEA42'
							/>
						</g>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<filter
					id='filter0_d_11534_5533'
					x='4.95943'
					y='2.51678'
					width='8.11145'
					height='8.71698'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset />
					<feColorMatrix
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
					/>
					<feBlend
						mode='normal'
						in2='BackgroundImageFix'
						result='effect1_dropShadow_11534_5533'
					/>
					<feBlend
						mode='normal'
						in='SourceGraphic'
						in2='effect1_dropShadow_11534_5533'
						result='shape'
					/>
				</filter>
				<clipPath id='clip0_11534_5533'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
