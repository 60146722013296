import { noop } from '@planity/helpers';
import { forwardRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './main_search_input.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';

export const MainSearchInput = forwardRef(
	(
		{
			label = '',
			placeholder = '',
			className,
			value,
			onChange = noop,
			onBlur = noop,
			onFocus = noop,
			onClick = noop,
			children,
			id,
			autoComplete,
			autoCorrect
		},
		ref
	) => {
		useStyles(styles);

		const [isFocused, setIsFocused] = useState(false);

		const classes = classNames.bind(styles);

		return (
			<div
				className={classes({
					mainSearchInput: true,
					[className]: className !== undefined
				})}
				onClick={onClick}
			>
				<label className={classes({ container: true, focused: isFocused })}>
					<span className={styles.label}>{label}</span>
					<input
						ref={ref}
						id={id}
						value={value || ''}
						onChange={e => onChange(e?.currentTarget?.value || '')}
						className={styles.input}
						type='text'
						placeholder={placeholder}
						onFocus={event => {
							onFocus(event);
							setIsFocused(true);
						}}
						onBlur={event => {
							onBlur(event);
							setIsFocused(false);
						}}
						autoComplete={autoComplete || 'off'}
						autoCorrect={autoCorrect}
					/>
				</label>
				{children}
			</div>
		);
	}
);
