// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".business_description-module_description-s8Nr9{font:var(--regular) var(--body-4);color:var(--grey-600)}.business_description-module_about-i0Nr3 .business_description-module_subtitle-wbrV\\+{display:flex;font:var(--semibold) var(--body-3);color:var(--grey-700);margin-top:24px;margin-bottom:4px}", "",{"version":3,"sources":["webpack://./../../packages/ui/components/business/business_description/business_description.module.scss"],"names":[],"mappings":"AAAA,+CACC,iCAAA,CACA,qBAAA,CAGA,sFACC,YAAA,CACA,kCAAA,CACA,qBAAA,CACA,eAAA,CACA,iBAAA","sourcesContent":[".description {\n\tfont: var(--regular) var(--body-4);\n\tcolor: var(--grey-600);\n}\n.about {\n\t.subtitle {\n\t\tdisplay: flex;\n\t\tfont: var(--semibold) var(--body-3);\n\t\tcolor: var(--grey-700);\n\t\tmargin-top: 24px;\n\t\tmargin-bottom: 4px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "business_description-module_description-s8Nr9",
	"about": "business_description-module_about-i0Nr3",
	"subtitle": "business_description-module_subtitle-wbrV+"
};
module.exports = ___CSS_LOADER_EXPORT___;
