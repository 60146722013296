import React from 'react';

export const GooglePlaceSuggestionText = ({ suggestion }) => {
	if (!suggestion) return null;
	const { structured_formatting } = suggestion;
	const { main_text, secondary_text, main_text_matched_substrings } =
		structured_formatting;

	// Bold the matched text
	const main_text_formatted = main_text.split('').reduce((str, char, i) => {
		const needToOpenBracket = main_text_matched_substrings.find(
			({ offset }) => offset === i
		);
		const needToCloseBracket = main_text_matched_substrings.find(
			({ offset, length }) => offset + length === i
		);
		if (needToOpenBracket) {
			return str + '<strong><u>' + char;
		}
		if (needToCloseBracket) return str + '</u></strong>' + char;
		return str + char;
	}, '');
	return (
		<div css={styles.innerSuggestion}>
			<span
				css={styles.left}
				dangerouslySetInnerHTML={{ __html: main_text_formatted + ',' }}
			/>
			&nbsp;{' '}
			{!!secondary_text && <span css={styles.right}>{secondary_text}</span>}
		</div>
	);
};

const styles = {
	innerSuggestion: {
		display: 'flex',
		flexWrap: 'wrap',
		font: 'var(--regular) var(--body-3)'
	}
};
