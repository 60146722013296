import React, { Component } from 'react';
import { firebase } from '@planity/datastores';
import { ErrorMessage } from '../error_message';
import { Localize } from '@planity/components';
import {
	MISSING_PASSWORD_ERROR,
	NETWORK_ERROR,
	SocialButton,
	TOO_MANY_ATTEMPTS_ERROR,
	WRONG_PASSWORD_ERROR
} from '@planity/components';
import credentials from '@planity/credentials';
import { withTranslation } from '@planity/localization';
import { InputPassword, Button } from '@planity/ui';
import classNames from 'classnames/bind';
import styles from './reauth_modal.module.scss';
import withStyles from 'isomorphic-style-loader/withStyles';

const WHITELISTED_ERRORS = [
	MISSING_PASSWORD_ERROR,
	WRONG_PASSWORD_ERROR,
	NETWORK_ERROR,
	MISSING_PASSWORD_ERROR,
	TOO_MANY_ATTEMPTS_ERROR
].map(e => `userProfileForm.errors.${e}`);

export default withStyles(styles)(
	withTranslation()(
		class UserProfileFormReauthModal extends Component {
			state = {
				password: '',
				isPending: false,
				error: null
			};

			render() {
				const { password, isPending, error } = this.state;
				const { dismiss, onSuccess, t, toggle, toggleIcon } = this.props;
				const { AVAILABLE_PROVIDERS } = credentials;
				const classes = classNames.bind(styles)({
					reauthModal: true
				});
				// From Firebase Documentation, providerData[0] is the user's preferred provider
				// I don't know how they decide it but, do I care ?
				const { providerId: preferredProviderId } = firebase
					.auth()
					.currentUser.providerData.filter(({ providerId }) =>
						AVAILABLE_PROVIDERS.includes(providerId)
					)[0];

				const reauthenticateUserWithFacebook = () => {
					this.setState({
						isPending: true,
						error: null
					});

					return firebase
						.auth()
						.currentUser.reauthenticateWithPopup(
							new firebase.auth.FacebookAuthProvider()
						)
						.then(() => typeof onSuccess === 'function' && onSuccess())
						.catch(error => {
							const IGNORED_ERRORS_CODES = [
								'auth/popup-closed-by-user',
								'auth/user-cancelled'
							];
							if (!IGNORED_ERRORS_CODES.includes(error.code))
								this.setState({
									isPending: false,
									error: error.code || error
								});
						});
				};

				const email = firebase.auth().currentUser.email;

				return (
					<div className={classes}>
						<div>
							<ErrorMessage
								defaultMessage={'userProfileForm.errors.defaultError'}
								error={error && `userProfileForm.errors.${error}`}
								whitelist={WHITELISTED_ERRORS}
							/>
							{/* modal pour l'authentification facebook => C'est du code mort mais nous le garderons pour le futur. */}
							{preferredProviderId === 'facebook.com' ? (
								<div>
									<div className={styles.alert}>
										<Localize text={'userProfileForm.reauth.pleaseReauth'} />
									</div>
									<SocialButton
										isAlone
										isDisabled={isPending}
										provider={'facebook'}
										onPress={reauthenticateUserWithFacebook}
									/>
									<div className={styles.actions}>
										<Button
											id={'reauth-cancel-button'}
											isDisabled={isPending}
											label={t('actions.cancel')}
											type={'tertiary'}
											onClick={dismiss}
										/>
									</div>
								</div>
							) : (
								<div>
									<h2 className={styles.title}>
										<Localize text={'userProfileForm.reauth.title'} />
									</h2>
									<div className={styles.alert}>
										<Localize
											args={{ email }}
											text={'userProfileForm.reauth.'.concat(
												email ? 'alertMail' : 'alertNoMail'
											)}
										/>
									</div>
									<div className={styles.input}>
										<InputPassword
											//autoFocus
											icon={toggle ? 'Eye' : 'EyeOff'}
											id={'reauth-pwd-input'}
											isDisabled={isPending}
											isRequired
											label={t('userProfileForm.reauth.label')}
											placeholder={t('userProfileForm.reauth.placeholder')}
											toggle={toggle}
											value={password}
											onChange={this.setPassword}
											onToggleIcon={toggleIcon}
										/>
									</div>
									<div className={styles.actions}>
										<Button
											id={'reauth-cancel-button'}
											isDisabled={isPending}
											isFullMobile
											label={t('actions.cancel')}
											size={'medium'}
											type={'secondary'}
											onClick={dismiss}
										/>
										<Button
											id={'reauth-submit-button'}
											isDisabled={isPending}
											isFullMobile
											label={
												isPending
													? t('actions.submitting')
													: t('actions.submit')
											}
											size={'medium'}
											type={'primary'}
											onClick={this.onSubmit}
										/>
									</div>
								</div>
							)}
						</div>
					</div>
				);
			}

			setPassword = password => {
				this.setState(({ error }) => ({
					password,
					error:
						!!password &&
						(error === MISSING_PASSWORD_ERROR || error === WRONG_PASSWORD_ERROR)
							? null
							: error
				}));
			};
			onSubmit = e => {
				if (e && e.preventDefault) e.preventDefault();
				const { password, isPending, error } = this.state;
				if (!isPending && (!error || error === NETWORK_ERROR)) {
					if (password) {
						this.setState({ isPending: true, error: null }, () => {
							const { currentUser } = firebase.auth();
							const credential = firebase.auth.EmailAuthProvider.credential(
								currentUser.email,
								password
							);
							currentUser
								.reauthenticateWithCredential(credential)
								.then(() => {
									this.props.onSuccess();
								})
								.catch(error => {
									this.setState({
										isPending: false,
										error: error.code || error
									});
								});
						});
					} else {
						this.setState({ isPending: false, error: MISSING_PASSWORD_ERROR });
					}
				}
			};
		}
	)
);
