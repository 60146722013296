import React from 'react';
import { colors } from '@planity/theme';
import { Localize } from '@planity/components';

export default function Link({
	onPress,
	text,
	localizedText,
	localizedArgs,
	className,
	isDisabled,
	children,
	customClassName,
	...props
}) {
	return (
		<a
			className={customClassName}
			css={[css.link, isDisabled && css.disabledLink, className]}
			onClick={isDisabled ? undefined : onPress}
			{...props}
		>
			{localizedText ? (
				<Localize args={localizedArgs} text={localizedText} />
			) : (
				text || children
			)}
		</a>
	);
}

const css = {
	link: {
		flexShrink: 0,
		color: colors.main.primary,
		cursor: 'pointer',
		textDecoration: 'underline'
	},
	disabledLink: {
		cursor: 'not-allowed',
		color: colors.main.disabled
	}
};
