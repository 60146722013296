// @ts-check
import credentials from '@planity/credentials';
import { filter, remove, values } from './functions';
import { PAYMENT_TYPE_CARD } from './onlinePayment';

export function centsToPrice(cents) {
	return `${((cents || 0) / 100).toFixed(2).replace('.', ',')} €`;
}

/**
 * this helper was copied from the legacy website
 * all localized text logic should be moved to translations
 * this should just return a structured object
 */
export function formatPrices(prices) {
	if (prices) {
		if (prices.onQuotation)
			return [{ oldText: 'Sur devis', text: 'common.prices.onQuotation' }];
		if (prices.default === 0) return [];
		if (prices.max === Infinity) prices = remove(prices, 'max');
		if (prices.min && !prices.max)
			return [
				{ oldText: 'à partir de', text: 'common.prices.startingAt' },
				{ price: formatPrice(prices.min) }
			];
		if (prices.max && !prices.min)
			return [
				{ oldText: "jusqu'à", text: 'common.prices.upTo' },
				{ price: formatPrice(prices.max) }
			];
		if (prices.max && prices.min) {
			if (prices.min < prices.max) {
				return [
					{ oldText: 'de', text: 'common.prices.from' },
					{ price: formatPrice(prices.min) },
					{ oldText: 'à', text: 'common.prices.to' },
					{ price: formatPrice(prices.max) }
				];
			} else {
				return [];
			}
		}
		let allPrices = Array.from(new Set(values(filter(prices, cents => cents))));
		if (!allPrices.length) return null; // maybe distinguish free and 'on quotation'
		if (allPrices.length === 1) return [{ price: formatPrice(allPrices[0]) }];
		return [
			{ oldText: 'à partir de', text: 'common.prices.startingAt' },
			{
				price: formatPrice(
					allPrices.reduce((minCents, cents) =>
						minCents ? Math.min(minCents, cents) : cents
					)
				)
			}
		];
	} else {
		return null;
	}
}

export function formatPrice(cents, fillWithZeros = false) {
	if (fillWithZeros) return centsToPrice(cents);
	if (cents % 100 === 0) return `${cents / 100} €`;
	return `${(cents / 100).toFixed(2).replace('.', ',')} €`;
}

export function getFixedPrice(prices) {
	const formattedPrices = formatPrices(prices);
	const price =
		formattedPrices && formattedPrices.map(p => p.text || p.price).join(' ');
	// No need to translate price.text above thanks to the following line
	if (isNaN(parseFloat(price))) {
		return null;
	}

	return price;
}

/**
 * NOT RELIABLE AT ALL
 * Really in case of emergency if the firebase fee nodes are corrupted
 * because the fees might have changes sinces and are probably not right for the country/payment method
 */
export const getDefaultFees = () => {
	const { PLANITY_FEE, PLANITY_FLAT, STRIPE_FEE, STRIPE_FLAT } = credentials;
	return {
		planity_fee: PLANITY_FEE,
		planity_flat: PLANITY_FLAT,
		stripe_fee: STRIPE_FEE,
		stripe_flat: STRIPE_FLAT
	};
};

/**
 * Computes fees for an amount
 * @param payload { Object } the amount you want to charge, in cents
 * @param payload.amount { Number } the amount you want to charge, in cents
 * @param payload.stripeFees { Object } fees applied by Planity and Stripe on each online payment by country and payment method type
 * @return { Number } the fees for your charge, in cents
 */
export const computeFees = ({ amount, stripeFees }) => {
	const { planity_fee, planity_flat, stripe_fee, stripe_flat } =
		stripeFees || {};
	return Math.round(
		(amount * planity_fee) / 100 +
			planity_flat +
			((amount * stripe_fee) / 100 + stripe_flat * 100)
	);
};

/**
 * Retrieve the right fees for the dedicated country with the dedicated payment method
 * @param allStripeFees
 * @param {string} countryCode
 * @param {string} paymentMethodType - should be card|giropay|klarna|bancontact
 * @returns {{planity_fee, planity_flat, stripe_fee, stripe_flat}}
 */
export const getFinalFees = (
	allStripeFees,
	countryCode = 'FR',
	paymentMethodType
) => {
	// defaultFees is NOT RELIABLE / EMERGENCY USE ONLY
	const defaultFees = getDefaultFees();
	// this should really not happen
	if (!allStripeFees) {
		return defaultFees;
	}
	const countryStripeFees =
		allStripeFees[countryCode.toUpperCase()] || allStripeFees.FR || {};
	const paymentMethodFees =
		countryStripeFees[paymentMethodType.toLowerCase()] ||
		countryStripeFees[PAYMENT_TYPE_CARD] ||
		defaultFees;
	return paymentMethodFees;
};
