import { LoadingPage } from './loading_page';
import { NativeAppHomeAppointments } from './native_app_home_appointments';
import React, { useEffect } from 'react';
import { useUserQueries, Localize } from '@planity/components';
import { useTranslation } from '@planity/localization';
import classNames from 'classnames/bind';
import styles from './native_app_landing_page.module.scss';
import { BusinessPreview, HomeSearchBar } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useUserData } from '@planity/context';
import { sendToUserApp } from '@planity/webview';
import { NativeAppLandingPageSignedOut } from './native_app_landing_page_signed_out';

export const NativeAppLandingPageSignedIn = () => {
	useStyles(styles);
	const cx = classNames.bind(styles);
	const { t } = useTranslation();

	const {
		allFutureAppointments,
		pastAppointments,
		confirmedAppointment,
		nativeAppHomeAppointmentsHasLoaded
	} = useUserData();

	const hasNoAppointments =
		!Object.keys(pastAppointments || {}).length &&
		!Object.keys(allFutureAppointments || {}).length &&
		!Object.values(confirmedAppointment || {}).length;

	// useEffect(() => {
	// 	if (nativeAppHomeAppointmentsHasLoaded) {
	// 		sendToUserApp({ type: 'USER_DATA_HAS_FULLY_LOADED' });
	// 	}
	// }, [nativeAppHomeAppointmentsHasLoaded]);

	if (!nativeAppHomeAppointmentsHasLoaded) {
		return <LoadingPage />;
	}
	if (hasNoAppointments) {
		return <NativeAppLandingPageSignedOut />;
	}

	return (
		<div
			className={cx({
				nativeLandingPage: true,
				nativeConnected: true
			})}
		>
			<div className={styles.connected}>
				<div className={styles.homeUpperBloc}>
					<h1 className={styles.titleConnected}>
						{t('landing.catchLine.titleNative')}
						<span> {t('landing.catchLine.titleNative2')}</span>
					</h1>

					<HomeSearchBar
						className={styles.searchbarConnected}
						instantRefreshOnSelect
					/>
				</div>
				<div className={cx({ bottomConnected: true })}>
					<NativeAppHomeAppointments
						allFutureAppointments={allFutureAppointments}
						pastAppointments={pastAppointments}
					/>
					<BusinessPreview Title={<BusinessPreviewTitle />} />
				</div>
			</div>
		</div>
	);
};

const BusinessPreviewTitle = () => {
	const { beautyTeam } = useUserQueries();
	return (
		<h2 className={styles.businessPreviewTitle}>
			<Localize
				args={{ count: beautyTeam.length }}
				text={'landing.beautyTeam'}
			/>
		</h2>
	);
};
