import React, { createContext, useEffect, useState, useContext } from 'react';
import { publicShard } from '@planity/datastores';

const StripeFeesContext = createContext({});

export function StripeFeesProvider({ children }) {
	const [allStripeFees, setAllStripeFees] = useState(null);

	async function fetchStripeFees() {
		const allStripeFees = await publicShard
			.database()
			.ref('app_settings/stripe_fees')
			.once('value')
			.then(snap => snap.val())
			.catch(e => console.log('stripe provider error => ', { e }));
		if (allStripeFees) {
			setAllStripeFees(allStripeFees);
		}
	}

	useEffect(() => {
		if (!allStripeFees) {
			fetchStripeFees();
			// in case the fees are not loaded the first time
			const intervalId = setInterval(fetchStripeFees, 3000);
			return () => {
				clearInterval(intervalId);
			};
		}
	}, [allStripeFees]);

	return (
		<StripeFeesContext.Provider value={{ allStripeFees }}>
			{children}
		</StripeFeesContext.Provider>
	);
}

export const withStripeFees = Component => props =>
	(
		<StripeFeesContext.Consumer>
			{data => <Component {...props} allStripeFees={data.allStripeFees} />}
		</StripeFeesContext.Consumer>
	);

export const useStripeFees = () => useContext(StripeFeesContext);
