import React from 'react';

export const GI = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g>
				<mask
					style={{ maskType: 'alpha' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g>
					<rect width='20' height='15' fill='#F7FCFF' />
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 10H20V15H0V10Z'
						fill='#C51918'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.8377 2.04166H8.91665V2.95832H9.37128L9.33884 5.47916H7.9956V4.33332H8.45612V3.41666H7.53507V3.87499H7.07454V3.41666H6.15349V4.33332H6.61402V5.47916H5.64284V6.39582L6.10336 6.39582V9.14582H5.64284V10.0625H14.3928V9.14582H13.4718V6.39582L14.3928 6.39582V5.47916H13.49L13.5128 4.33332H13.9824V3.41666H13.0614V3.87499H12.6009V3.41666H11.6798V4.33332H12.1312L12.1084 5.47916H10.7204L10.7529 2.95832H11.2193V2.04166H10.2982V2.49999H9.8377V2.04166Z'
						fill='#DB000B'
					/>
					<path
						d='M7.13158 5.48026C7.13158 5.35309 7.23467 5.25 7.36184 5.25V5.25C7.48901 5.25 7.5921 5.35309 7.5921 5.48026V6.625H7.13158V5.48026Z'
						fill='#272727'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M6.75992 8.55645C6.75992 8.17494 7.0692 7.86566 7.45071 7.86566V7.86566C7.83222 7.86566 8.1415 8.17494 8.1415 8.55645V9.69899H6.75992V8.55645Z'
						fill='#272727'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M11.8257 8.55645C11.8257 8.17494 12.135 7.86566 12.5165 7.86566V7.86566C12.898 7.86566 13.2073 8.17494 13.2073 8.55645V9.69899H11.8257V8.55645Z'
						fill='#272727'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.06255 8.32837C9.06255 7.81969 9.47492 7.40732 9.9836 7.40732V7.40732C10.4923 7.40732 10.9047 7.81969 10.9047 8.32837V10.1573H9.06255V8.32837Z'
						fill='#272727'
					/>
					<path
						d='M9.89473 5.48026C9.89473 5.35309 9.99782 5.25 10.125 5.25V5.25C10.2522 5.25 10.3553 5.35309 10.3553 5.48026V6.625H9.89473V5.48026Z'
						fill='#272727'
					/>
					<path
						d='M12.6579 5.48026C12.6579 5.35309 12.761 5.25 12.8882 5.25V5.25C13.0153 5.25 13.1184 5.35309 13.1184 5.48026V6.625H12.6579V5.48026Z'
						fill='#272727'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M10.3265 9.4983L10.2135 9.70577L10.003 9.39175L8.27872 10.5473L10.2011 11.4622L11.8966 10.3535L10.3265 9.4983ZM9.53913 10.455L10.0938 10.0833L10.6798 10.4025L10.1528 10.7471L9.53913 10.455Z'
						fill='#E8AA00'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M8.97368 12.8333H9.89473V13.75H8.97368V12.8333Z'
						fill='#E8AA00'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.5 11.25H10.593V13.75H9.5V11.25Z'
						fill='#E8AA00'
					/>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
				style={{ mixBlendMode: 'multiply' }}
			/>
			<defs>
				<clipPath>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
