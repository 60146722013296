import React from 'react';

export const PR = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5899)'>
				<mask
					id='mask0_11534_5899'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5899)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#EF0000'
					/>
					<mask
						id='mask1_11534_5899'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_11534_5899)'>
						<path
							d='M0 5H-1.25V6.25V8.75V10H0H20H21.25V8.75V6.25V5H20H0Z'
							fill='#EF0000'
							stroke='#F7FCFF'
							strokeWidth='2.5'
						/>
					</g>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15L11.25 7.5L0 0Z'
						fill='#3D58DB'
					/>
					<mask
						id='mask2_11534_5899'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='12'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15L11.25 7.5L0 0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask2_11534_5899)'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M4.17987 8.88553L1.88491 10.0968L3.0022 7.66307L1.57846 6.36299L3.33626 6.29811L4.17987 4.09769L4.82365 6.29811H6.57846L5.40884 7.66307L6.33918 10.0968L4.17987 8.88553Z'
							fill='#F7FCFF'
						/>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5899'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
