import React from 'react';
import { hydrate } from 'react-dom';
import { rehydrate } from 'glamor';
import { BrowserRouter } from 'react-router-dom';
import { App } from '../app';
import { getFormFactor } from '@planity/helpers';
import * as Sentry from '@sentry/browser';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import { inspect } from '@xstate/inspect';

if (window.location.host === 'loverinvite.com') {
	window.location.href = 'https://www.planity.com';
}

if (process.env.PLANITY_ENV === 'production') {
	Sentry.init({
		dsn: 'https://e23ec7952f374525ab7b8a5656d2a3ff@sentry.io/1796443'
	});
}

if (typeof history !== 'undefined' && history.replaceState) {
	history.replaceState(null, document.title);
}

if (window._planity_glamorIds) {
	rehydrate(window._planity_glamorIds);
}

//to open xstate inspector
// if (process.env.PLANITY_ENV === 'lab') {
// 	inspect({ iframe: false });
// }
console.log(
	"Si tu voulais l'inspecteur XState, fallait pas me forcer à le retirer"
);

const root = document.getElementById('planity');

const insertCss = {
	insertCss: (...styles) => {
		const removeCss = styles.map(style => style._insertCss());
		return () => removeCss.forEach(dispose => dispose());
	}
};

window._planity_isHydrating = true;
hydrate(
	<StyleContext.Provider value={insertCss}>
		<BrowserRouter>
			<App
				{...window._planity_locals}
				countryCode={window._planity_countryCode}
				formFactor={getFormFactor()}
				language={window._planity_language}
				locale={window._planity_locale}
			/>
		</BrowserRouter>
	</StyleContext.Provider>,
	root,
	() => {
		window._planity_isHydrating = false;
		window._planity_localStates = null;
		window._planity_language = null;
	}
);
