import React, { useEffect, useState } from 'react';
import { getGooglePlace } from '@planity/datastores';
import { useForm } from 'react-hook-form';
import { invokeLambda } from '@planity/helpers';
import { GooglePlacesSearch, Localize } from '@planity/components';
import { Button, Card, Checkbox, Drawer, Input } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './address_page.module.scss';
import { useLocalization, useTranslation } from '@planity/localization';
import { useRandomId, useSearch } from '@planity/context';
import { FormControlledTextInput } from './form_controlled_text_input';

const addName = object => ({
	...object,
	name: `${object.firstName} ${object.lastName}`
});

export function AddressPage({
	onValidate,
	personalInfosRef,
	userId,
	setShopifyCustomerId,
	deliveryAddress,
	invoiceAddress,
	userFirstName,
	userLastName
}) {
	useStyles(styles);

	const classes = classNames.bind(styles)({
		address: true
	});

	const { t } = useTranslation();

	const [deliveryValues, setDeliveryValues] = useState({
		...deliveryAddress,
		firstName: deliveryAddress.firstName || userFirstName,
		lastName: deliveryAddress.lastName || userLastName
	});
	const [invoiceValues, setInvoiceValues] = useState({
		...invoiceAddress,
		firstName: invoiceAddress.firstName || userFirstName,
		lastName: invoiceAddress.lastName || userLastName
	});
	const [sameAddresses, setSameAddresses] = useState(
		!deliveryValues.address1
			? true
			: deliveryValues.address1 === invoiceValues.address1
	);
	const [submitForm, setSubmitForm] = useState(false);
	const [formValid, setFormValid] = useState({
		invoice: true,
		delivery: false
	});

	const [shopifyAddresses, setShopifyAddresses] = useState([]);

	const handleSubmit = () => {
		setSubmitForm(true);
		if (formValid.invoice && formValid.delivery) {
			onValidate({
				deliveryAddress: addName(deliveryValues),
				invoiceAddress: sameAddresses
					? addName(deliveryValues)
					: addName(invoiceValues)
			});
		}
	};

	useEffect(() => {
		const fetchUserInfo = async () => {
			const shopify = await invokeLambda('getShopifyCustomerAddresses', {
				planityCustomerId: userId
			});

			setShopifyCustomerId(shopify.shopifyCustomerId);

			if (shopify?.customer?.addresses?.length <= 0) {
				return undefined;
			}
			const shopifyDefaultId = shopify.customer.defaultAddress.id;

			setShopifyAddresses(
				shopify.customer.addresses.sort(a => {
					if (a.id === shopifyDefaultId) return -1;
					return 0;
				})
			);
		};

		fetchUserInfo();
	}, []);

	useEffect(() => {
		if (submitForm) {
			setSubmitForm(false);
		}
	}, [submitForm]);

	return (
		<Card className={classes}>
			<div className={styles.formContainer} ref={personalInfosRef}>
				<Form
					addresses={shopifyAddresses}
					formValid={formValid.delivery}
					isSubmitted={submitForm}
					setFormValid={value => setFormValid(x => ({ ...x, delivery: value }))}
					setValues={setDeliveryValues}
					title='eshop.address.shippingTitle'
					values={deliveryValues}
				/>
			</div>
			<div className={styles.invoiceContainer}>
				<div>
					<p className={styles.title}>
						<Localize text={'eshop.address.invoiceTitle'} />
					</p>
					<Checkbox
						checked={sameAddresses}
						label={<Localize text={'eshop.address.sameAddresses'} />}
						onChange={() => setSameAddresses(!sameAddresses)}
					/>
				</div>
				{!sameAddresses ? (
					<Form
						addresses={shopifyAddresses}
						formValid={formValid.invoice}
						isSubmitted={submitForm}
						setFormValid={value =>
							setFormValid(x => ({ ...x, invoice: value }))
						}
						setValues={setInvoiceValues}
						values={invoiceValues}
					/>
				) : null}
			</div>

			<Button
				id={'form-save-address'}
				isDisabled={!(formValid.invoice && formValid.delivery)}
				isFullWidth={true}
				label={t('clickAndCollect.saveAddress')}
				onClick={handleSubmit}
			/>
		</Card>
	);
}

function Form({ values, setValues, title, isSubmitted, setFormValid }) {
	useStyles(styles);

	const cx = classNames.bind(styles);
	const randomId = useRandomId();

	const [displayFields, setDisplayFields] = useState(!!values.address1);
	const [whereQuery, setWhereQuery] = useState('');
	const [isWhereDrawerOpen, setIsWhereDrawerOpen] = useState(false);

	const { category, setSearch, submitSearch } = useSearch() || {};

	const { handleSubmit, errors, setValue, formState, control } = useForm({
		mode: 'onChange'
	});

	const formRef = React.createRef();

	useEffect(() => {
		setFormValid(formState.isValid && !!values.address1);
	}, [formState.isValid, values.address1]);

	useEffect(() => {
		if (isSubmitted) {
			formRef.current.dispatchEvent(new Event('submit'));
		}
	}, [isSubmitted]);

	useEffect(() => {
		Object.entries(values).forEach(x => {
			setValue(x[0], x[1], { shouldValidate: true });
		});
	}, [values]);

	useEffect(() => {
		return () => {
			setFormValid(true);
		};
	}, []);

	const setAlgoliaAddress = address => {
		setValues(x => ({ ...x, ...address, address1: address.address1 }));
		Object.entries(address).map(x =>
			setValue(x[0], x[1], { shouldValidate: true })
		);
		setDisplayFields(true);
		setFormValid(x => ({ ...x, delivery: true }));
	};
	const { t } = useTranslation();
	const { googleSearchableCountries: countries } = useLocalization();

	const onWhereBlur = event => {
		event.preventDefault();
		setIsWhereDrawerOpen(false);
	};
	const onWhereFocus = event => {
		event.preventDefault();
		setIsWhereDrawerOpen(true);
	};
	const formatAddress = place => {
		if (!place) {
			return {
				city: '',
				address1: '',
				country: '',
				zip: ''
			};
		}
		const { address_components } = place;
		const streetNumber = address_components.find(({ types }) =>
			types.includes('street_number')
		);
		const route = address_components.find(({ types }) =>
			types.includes('route')
		);
		const city = address_components.find(({ types }) =>
			types.includes('locality')
		);
		const country = address_components.find(({ types }) =>
			types.includes('country')
		);
		const zipCode = address_components.find(({ types }) =>
			types.includes('postal_code')
		);
		const isDeutchAddress = ['BE', 'DE', 'CH'].includes(country.short_name);

		return {
			address1:
				streetNumber && route
					? isDeutchAddress
						? `${route.long_name} ${streetNumber.long_name} `
						: ` ${streetNumber.long_name} ${route.long_name}`
					: '',

			city: city?.long_name || '',
			country: country ? country.long_name : '',
			zip: zipCode ? zipCode.long_name : ''
		};
	};
	const onWhereSelect = async prediction => {
		if (prediction) {
			getGooglePlace({
				placeId: prediction.place_id,
				prediction,
				countries
			}).then(e => {
				onWhereChange(e?.name || '');
				const address = formatAddress(e);
				setAlgoliaAddress(address);
				setSearch(
					{
						category: category?.isCategory ? category : null,
						googlePlace: e,
						place: null,
						parentPlace: null
					},
					null
				);
				submitSearch('push');
			});
		}
	};
	const onWhereChange = address => {
		setValues({ ...values, address1: address });
		setWhereQuery(address);
	};
	return (
		<div className={styles.selectFormContainer}>
			<div className={styles.titleContainer}>
				<span className={styles.title}>
					<Localize text={title} />
				</span>
			</div>
			<form ref={formRef} onSubmit={handleSubmit}>
				<div className={styles.mb}>
					<div className={cx({ input: true, leftInput: true })}>
						<FormControlledTextInput
							control={control}
							errors={formState.dirtyFields['firstName'] && errors}
							field='firstName'
							label={t('addressForm.firstName')}
							localizedError='addressForm.errors.firstNameRequired'
							localizedPlaceholder={t('addressForm.firstName')}
							setValues={setValues}
							values={values}
						/>
					</div>
					<div className={cx({ input: true, rightInput: true })}>
						<FormControlledTextInput
							control={control}
							errors={formState.dirtyFields['lastName'] && errors}
							field='lastName'
							label={t('addressForm.lastName')}
							localizedError='addressForm.errors.lastNameRequired'
							localizedPlaceholder={t('addressForm.lastName')}
							setValues={setValues}
							values={values}
						/>
					</div>
				</div>

				<div className={styles.inputAddress}>
					<>
						<Input
							field='address'
							icon='Pin'
							id={`form-search-address_${randomId}`}
							label={t('addressForm.search.placeholder')}
							placeholder={t('addressForm.search.placeholder')}
							type='text'
							value={whereQuery}
							onBlur={onWhereBlur}
							onChange={onWhereChange}
							onFocus={onWhereFocus}
						/>
						<GooglePlacesSearch
							countries={countries}
							format={formatWhere}
							query={whereQuery}
							types={['address']}
						>
							{({ places }) => (
								<Drawer
									className={styles.whereDrawer}
									id={'Google'}
									isOpen={isWhereDrawerOpen}
									items={places || []}
									onSelect={onWhereSelect}
								/>
							)}
						</GooglePlacesSearch>
					</>
				</div>

				{!displayFields && (
					<div>
						<span
							className={styles.manuallyEnter}
							onClick={() => setDisplayFields(true)}
						>
							<Localize text='eshop.address.manuallyEnter' />
						</span>
					</div>
				)}
				<div className={cx({ fields: true, displayFields })}>
					<FormControlledTextInput
						control={control}
						customStyle={styles.inputAddress}
						field='address2'
						localizedPlaceholder={t('addressForm.address2')}
						rules={{ required: false }}
						setValues={setValues}
						values={values}
					/>
					<div className={styles.addressContainer}>
						<div className={styles.postalCode}>
							<FormControlledTextInput
								control={control}
								errors={formState.dirtyFields['zip'] && errors}
								field='zip'
								localizedError='addressForm.errors.zipCodeLength'
								localizedPlaceholder={t('addressForm.zip')}
								rules={{ required: true, pattern: /^\d{4,5}$/ }}
								setValues={setValues}
								values={values}
							/>
						</div>
						<div className={styles.city}>
							<FormControlledTextInput
								control={control}
								errors={formState.dirtyFields['city'] && errors}
								field='city'
								localizedError='addressForm.errors.cityRequired'
								localizedPlaceholder={t('addressForm.city')}
								setValues={setValues}
								values={values}
							/>
						</div>
						<div className={styles.country}>
							<FormControlledTextInput
								control={control}
								errors={formState.dirtyFields['country'] && errors}
								field='country'
								localizedError='addressForm.errors.countryRequired'
								localizedPlaceholder={t('addressForm.country')}
								setValues={setValues}
								values={values}
							/>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}

function formatWhere(places) {
	return places?.map(formatPlace);
}

function formatPlace(place) {
	if (!place) return null;
	const { structured_formatting } = place;
	const { main_text, secondary_text, main_text_matched_substrings } =
		structured_formatting;

	// Bold the matched text
	const main_text_formatted = main_text.split('').reduce((str, char, i) => {
		const needToOpenBracket = main_text_matched_substrings.find(
			({ offset }) => offset === i
		);
		const needToCloseBracket = main_text_matched_substrings.find(
			({ offset, length }) => offset + length === i
		);
		if (needToOpenBracket) {
			return str + '<b>' + char;
		}
		if (needToCloseBracket) return str + '</b>' + char;
		return str + char;
	}, '');
	return {
		...place,
		dangerousLabel: main_text_formatted,
		description: secondary_text
	};
}
