import useStyles from 'isomorphic-style-loader/useStyles';
import { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './tabs.module.scss';

export function Tabs({
	className,
	items = ['Lorem', 'Ipsum'],
	index = 0,
	onChange,
	isFullWidth = false
}) {
	useStyles(styles);

	const [active, setActive] = useState(index);

	// Classes

	const classes = classNames.bind(styles)({
		tabs: true,
		isFullWidth,
		[className]: className !== undefined
	});

	const handleClick = i => {
		setActive(i);
		if (onChange) onChange(i);
	};

	// Render

	const renderItems = items.map((label, i) => (
		<button
			key={i}
			className={styles.item}
			aria-current={i === active}
			onClick={() => handleClick(i)}
			onMouseDown={e => e.preventDefault()} // Prevent focus state on click
		>
			{label}
		</button>
	));

	return (
		<div className={classes}>
			<div className={styles.items}>{renderItems}</div>
		</div>
	);
}
