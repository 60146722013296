import { AppointmentAvailabilitiesSlider } from '../appointment_availabilities_slider';
import React, { Component } from 'react';
import { formatPhoneNumber, safeRead } from '@planity/helpers';
import { Localize } from '@planity/components';
import { addDays, format, isBefore, isSameDay, parseISO } from 'date-fns';

export const DesktopAvailabilitiesPicker = class extends Component {
	state = {
		days: null,
		currentDay: null,
		prevAvailabilities: null,
		searchDate: null
	};

	static getDerivedStateFromProps(props, state) {
		if (props.availabilities !== state.prevAvailabilities) {
			const nextDays = availabilitiesDays(props.availabilities);
			if (!nextDays && !state.days) {
				return {
					prevAvailabilities: props.availabilities
				};
			} else if (!nextDays && state.days) {
				return {
					prevAvailabilities: props.availabilities
				};
			} else {
				return {
					prevAvailabilities: props.availabilities,
					days: nextDays,
					searchDate: state.searchDate,
					currentDay:
						state.currentDay &&
						nextDays.find(day => isSameDay(day, state.currentDay))
							? state.currentDay
							: nextDays[0]
				};
			}
		} else {
			return null;
		}
	}

	componentDidMount() {
		if (!process.env.WIDGET) {
			const { userFilter } = this.props;
			this.setState({ searchDate: userFilter.data.date });
		}
	}

	render() {
		const { availabilities, today, business } = this.props;
		const { currentDay, days, searchDate } = this.state;
		const hasAvailabilities = !!(
			days &&
			days.find(
				day => !!safeRead(availabilities, [format(day, 'yyyy-MM-dd'), 'length'])
			)
		);

		const phoneNumber = formatPhoneNumber(business.phoneNumber);
		if (hasAvailabilities) {
			return (
				<AppointmentAvailabilitiesSlider
					availabilities={availabilities}
					chooseDate={this.chooseDate}
					currentDay={currentDay}
					days={days}
					navToDay={currentDay => this.setState({ currentDay })}
					pageSize={7}
					searchDate={searchDate}
					setSearchDate={searchDate => this.setState({ searchDate })}
					today={today}
				/>
			);
		} else {
			return (
				<Localize
					args={{ phoneNumber }}
					text={'bookAppointment.noAvailabilities.'.concat(
						phoneNumber ? 'withPhone' : 'withoutPhone'
					)}
				>
					{sentence => (
						<div css={styles.alert}>
							<div>{sentence}</div>
						</div>
					)}
				</Localize>
			);
		}
	}

	chooseDate = (day, hour) => {
		this.props.chooseDate(parseISO(`${day} ${hour}`));
	};
};

const styles = {
	alert: {
		font: 'var(--medium) var(--body-4)',
		color: 'var(--grey-700)'
	}
};

function availabilitiesDays(availabilities) {
	const availableDays = Object.keys(availabilities || {}).sort();
	if (availableDays.length) {
		let cursor = parseISO(availableDays[0]);
		const lastDay = parseISO(availableDays[availableDays.length - 1]);
		const days = [];
		do {
			days.push(cursor);
			cursor = addDays(cursor, 1);
		} while (isBefore(cursor, lastDay) || isSameDay(cursor, lastDay));
		return days;
	} else {
		return null;
	}
}
