import React from 'react';
import classNames from 'classnames/bind';
import styles from './remove_card.module.scss';
import { Button } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useModal } from '@planity/ui/context';
import { useTranslation } from '@planity/localization';

/**
 * @component
 *
 * @param {string} countryCode
 * @param {Function} deleteCardFromBookAppointment
 * @param {Function} deleteCardFromMyAccount
 * @param {string} paymentMethod
 *
 * @returns {React.Component}
 */

export function RemoveCard({
	deleteCardFromBookAppointment,
	deleteCardFromMyAccount,
	countryCode,
	paymentMethod
}) {
	useStyles(styles);
	const { t } = useTranslation();

	const { closeModal } = useModal();

	const classes = classNames.bind(styles)({
		removeCard: true
	});

	const handleDeleteClick = e => {
		e.stopPropagation();
		if (typeof deleteCardFromBookAppointment === 'function') {
			deleteCardFromBookAppointment(paymentMethod, countryCode);
		}
		if (typeof deleteCardFromMyAccount === 'function') {
			deleteCardFromMyAccount(paymentMethod, false);
		}
		closeModal();
	};

	return (
		<div className={classes}>
			<span className={styles.title}>{t('bookAppointment.deleteCard')}</span>
			<p className={styles.text}>{t('myAccount.cards.askConfirmDelete')}</p>
			<div className={styles.actions}>
				<Button
					className={styles.cancel}
					isFullMobile
					label={t('actions.cancel')}
					type='secondary'
					onClick={closeModal}
				/>
				<Button
					className={styles.remove}
					isFullMobile
					label={t('myAccount.cards.confirmDeletion')}
					type='danger'
					onClick={handleDeleteClick}
				/>
			</div>
		</div>
	);
}
