import React from 'react';

export const LI = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g>
				<mask
					id='mask0_3616_2008'
					style={{ maskType: 'alpha' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#E31D1C'
					/>
					<mask
						style={{ maskType: 'alpha' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V7.5H20V0H0Z'
							fill='#2E42A5'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M2.99902 6.90825C2.99902 6.90825 3.75319 7.37768 5.5008 7.37768C7.2484 7.37768 8.17603 6.90825 8.17603 6.90825C8.17603 6.90825 6.8055 6.11154 5.58008 6.11154C4.35465 6.11154 2.99902 6.90825 2.99902 6.90825Z'
							fill='black'
						/>
						<path
							d='M3.04414 4.21387L2.82806 4.3396L2.90037 4.46387H3.04414H8.24742H8.4306L8.4858 4.2892L8.24742 4.21387C8.4858 4.2892 8.48582 4.28912 8.48585 4.28904L8.48591 4.28885L8.48605 4.28839L8.48645 4.28711L8.48766 4.28317L8.49163 4.26989C8.49492 4.25871 8.49943 4.24291 8.5048 4.22298C8.51552 4.18317 8.52971 4.12665 8.54436 4.05734C8.57353 3.91937 8.60523 3.72743 8.61407 3.5138C8.63117 3.10073 8.5651 2.53477 8.13857 2.16398C7.70007 1.78278 7.14888 1.84873 6.70656 1.95928C6.55485 1.9972 6.39806 2.04547 6.25425 2.08975C6.1877 2.11024 6.12393 2.12988 6.06473 2.14723C5.86393 2.2061 5.70807 2.24139 5.58816 2.24139C5.46929 2.24139 5.33305 2.2092 5.16093 2.15502C5.1112 2.13936 5.05696 2.1213 4.99975 2.10226C4.87353 2.06025 4.73288 2.01343 4.59436 1.97697C4.16912 1.86506 3.66623 1.82039 3.1105 2.13512C2.81509 2.30242 2.64299 2.53806 2.56172 2.7974C2.48279 3.04932 2.49367 3.30921 2.53402 3.53124C2.57464 3.75471 2.64798 3.95545 2.70961 4.09831C2.74069 4.17035 2.76945 4.22917 2.79077 4.27058C2.80144 4.29131 2.8103 4.30777 2.81672 4.31943L2.82444 4.33328L2.82679 4.3374L2.82757 4.33875L2.82785 4.33923L2.82797 4.33943C2.82802 4.33952 2.82806 4.3396 3.04414 4.21387Z'
							fill='#FFD83D'
							stroke='black'
							strokeWidth='0.5'
						/>
						<path
							d='M2.50169 4.45448L2.38514 4.23331L2.19218 4.335L2.26677 4.53997L2.98737 6.5202L3.06787 6.74143L3.29346 6.67436L3.29349 6.67436L3.2935 6.67435L3.29354 6.67434L3.29389 6.67424L3.29595 6.67363L3.3051 6.67098L3.34274 6.66037C3.37615 6.6511 3.42576 6.6377 3.48929 6.62154C3.6164 6.58919 3.79882 6.54588 4.01824 6.50254C4.45866 6.41553 5.04095 6.32984 5.62133 6.32984C6.20147 6.32984 6.77257 6.41547 7.20144 6.5023C7.41515 6.54557 7.59188 6.5888 7.71468 6.62106C7.77605 6.63718 7.82385 6.65053 7.85597 6.65975L7.89208 6.6703L7.90079 6.67292L7.90272 6.6735L7.90301 6.67359L7.90306 6.67361L7.90307 6.67361L7.90309 6.67362L8.12829 6.74311L8.21088 6.52228L8.95144 4.54205L9.02853 4.33591L8.83384 4.23331L8.71728 4.45448C8.83384 4.23331 8.83374 4.23326 8.83364 4.23321L8.83342 4.23309L8.83292 4.23283L8.83162 4.23215L8.8279 4.23024L8.81593 4.22424C8.80593 4.21931 8.7919 4.21256 8.77382 4.20425C8.73768 4.18763 8.68536 4.16479 8.61678 4.13785C8.4796 4.08395 8.27742 4.01367 8.0095 3.94392C7.47347 3.80437 6.67536 3.66724 5.60949 3.66724C4.54361 3.66724 3.74551 3.80437 3.20947 3.94392C2.94155 4.01367 2.73937 4.08395 2.6022 4.13785C2.53362 4.16479 2.4813 4.18763 2.44515 4.20425C2.42708 4.21256 2.41305 4.21931 2.40305 4.22424L2.39108 4.23024L2.38735 4.23215L2.38606 4.23283L2.38555 4.23309L2.38533 4.23321C2.38523 4.23326 2.38514 4.23331 2.50169 4.45448Z'
							fill='#FFD83D'
							stroke='black'
							strokeWidth='0.5'
						/>
						<path
							d='M5.54621 2.75942C5.96669 2.75942 6.21637 2.35453 6.21637 1.98112C6.21637 1.60771 5.96669 1.20282 5.54621 1.20282C5.12572 1.20282 4.87604 1.60771 4.87604 1.98112C4.87604 2.35453 5.12572 2.75942 5.54621 2.75942Z'
							fill='#FFD83D'
							stroke='black'
							strokeWidth='0.5'
						/>
						<mask
							maskUnits='userSpaceOnUse'
							x='4.07352'
							y='-1'
							width='3'
							height='4'
							fill='black'
						>
							<rect fill='white' x='4.07352' y='-1' width='3' height='4' />
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M5.70377 0H5.38864L5.42921 0.576701L5.07352 0.528302V0.924528L5.43467 0.874782L5.38864 1.78302H5.70377L5.65712 0.873254L6.01889 0.924528V0.528302L5.66367 0.579291L5.70377 0Z'
							/>
						</mask>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M5.70377 0H5.38864L5.42921 0.576701L5.07352 0.528302V0.924528L5.43467 0.874782L5.38864 1.78302H5.70377L5.65712 0.873254L6.01889 0.924528V0.528302L5.66367 0.579291L5.70377 0Z'
							fill='#FFD83D'
						/>
						<path
							d='M5.38864 0V-0.5H4.85224L4.88988 0.0350833L5.38864 0ZM5.70377 0L6.20258 0.0345249L6.23957 -0.5H5.70377V0ZM5.42921 0.576701L5.36179 1.07214L5.97112 1.15505L5.92798 0.541618L5.42921 0.576701ZM5.07352 0.528302L5.14093 0.0328674L4.57352 -0.0443408V0.528302H5.07352ZM5.07352 0.924528H4.57352V1.49812L5.14174 1.41985L5.07352 0.924528ZM5.43467 0.874782L5.93403 0.900089L5.96459 0.297069L5.36644 0.379459L5.43467 0.874782ZM5.38864 1.78302L4.88928 1.75771L4.86266 2.28302H5.38864V1.78302ZM5.70377 1.78302V2.28302H6.23007L6.20311 1.75741L5.70377 1.78302ZM5.65712 0.873254L5.72728 0.378201L5.12671 0.293084L5.15777 0.89886L5.65712 0.873254ZM6.01889 0.924528L5.94873 1.41958L6.51889 1.50039V0.924528H6.01889ZM6.01889 0.528302H6.51889V-0.048594L5.94785 0.0333747L6.01889 0.528302ZM5.66367 0.579291L5.16487 0.544766L5.12213 1.16215L5.73472 1.07422L5.66367 0.579291ZM5.38864 0.5H5.70377V-0.5H5.38864V0.5ZM5.92798 0.541618L5.88741 -0.0350833L4.88988 0.0350833L4.93044 0.611784L5.92798 0.541618ZM5.0061 1.02374L5.36179 1.07214L5.49662 0.0812664L5.14093 0.0328674L5.0061 1.02374ZM5.57352 0.924528V0.528302H4.57352V0.924528H5.57352ZM5.36644 0.379459L5.00529 0.429205L5.14174 1.41985L5.5029 1.37011L5.36644 0.379459ZM5.888 1.80833L5.93403 0.900089L4.93531 0.849475L4.88928 1.75771L5.888 1.80833ZM5.70377 1.28302H5.38864V2.28302H5.70377V1.28302ZM5.15777 0.89886L5.20443 1.80862L6.20311 1.75741L6.15646 0.847648L5.15777 0.89886ZM6.08906 0.429476L5.72728 0.378201L5.58695 1.36831L5.94873 1.41958L6.08906 0.429476ZM5.51889 0.528302V0.924528H6.51889V0.528302H5.51889ZM5.73472 1.07422L6.08994 1.02323L5.94785 0.0333747L5.59263 0.084364L5.73472 1.07422ZM5.20496 -0.0345249L5.16487 0.544766L6.16248 0.613816L6.20258 0.0345249L5.20496 -0.0345249Z'
							fill='black'
						/>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
				style={{ mixBlendMode: 'multiply' }}
			/>
			<defs>
				<clipPath>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
