/* eslint-disable no-undef */
import { Component } from 'react';
import ReactDOM from 'react-dom';

const MARKER_ZINDEX = 1;
const TOP_MARKER_ZINDEX = 10;

export default class MapMarker extends Component {
	marker = null;
	svg = null;
	popupDiv = null;
	popup = null;
	componentDidMount() {
		this.createMarker();
	}
	componentDidUpdate(prevProps) {
		if (!this.marker) {
			this.createMarker();
		}
		const { latitude, longitude, color, size, popup, onTop } = this.props;
		const {
			latitude: latitudeWas,
			longitude: longitudeWas,
			color: colorWas,
			size: sizeWas,
			popup: popupWas,
			onTop: onTopWas
		} = prevProps;
		if (
			(this.marker &&
				(latitude !== latitudeWas ||
					longitude !== longitudeWas ||
					color !== colorWas ||
					size !== sizeWas)) ||
			popup !== popupWas ||
			onTop !== onTopWas
		) {
			this.marker.setLngLat([this.props.longitude, this.props.latitude]);
			if (this.svg) {
				this.applySvgStyle();
			}
			this.setPopup();
		}
	}
	componentWillUnmount() {
		if (this.popupDiv) {
			ReactDOM.unmountComponentAtNode(this.popupDiv);
			this.popupDiv = null;
		}
		if (this.popup) {
			this.popup.remove();
		}
		if (this.marker) {
			this.marker.remove();
		}
		if (this.svg) {
			this.svg.remove();
		}
	}
	render() {
		return null;
	}
	createMarker() {
		if (this.props.map) {
			this.marker = new mapboxgl.Marker({
				element: this.getElement(),
				anchor: 'bottom'
			});
			this.marker.setLngLat([this.props.longitude, this.props.latitude]);
			this.marker.addTo(this.props.map);
			if (this.props.popup) {
				this.setPopup();
			}
		}
	}
	applySvgStyle() {
		const { color, size, onTop } = this.props;
		this.svg.style.cursor = 'pointer';
		this.svg.style.fill = 'none';
		this.svg.style.stroke = 'none';
		this.svg.style.zIndex = onTop ? TOP_MARKER_ZINDEX : MARKER_ZINDEX;
		this.svg.setAttribute('viewBox', '0 0 60 60');
		this.svg.setAttribute('height', size);
		this.svg.firstChild.firstElementChild.style.fill = color;
		this.svg.firstChild.children[1].style.stroke = onTop
			? 'white'
			: 'currentColor';
		this.svg.firstChild.children[2].style.stroke = onTop
			? 'white'
			: 'currentColor';
		if (this.props.onClick) {
			this.svg.addEventListener('click', this.props.onClick);
		}
	}
	getElement() {
		this.svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		this.svg.innerHTML = `<g filter="url(#filter0_d_4131_671)">
				<rect x="8" y="2" width="44" height="44" rx="22" fill="white"/>
				<path d="M36.25 23C36.25 27 30 31.25 30 31.25C30 31.25 23.75 27 23.75 23C23.75 19.5 26.6863 16.75 30 16.75C33.3137 16.75 36.25 19.5 36.25 23Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M32.25 23C32.25 24.2426 31.2426 25.25 30 25.25C28.7574 25.25 27.75 24.2426 27.75 23C27.75 21.7574 28.7574 20.75 30 20.75C31.2426 20.75 32.25 21.7574 32.25 23Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			</g>
			<filter id="filter0_d_4131_671" x="0" y="0" width="60" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
				<feFlood flood-opacity="0" result="BackgroundImageFix"/>
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
				<feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_dropShadow_4131_671"/>
				<feOffset dy="6"/>
				<feGaussianBlur stdDeviation="6"/>
				<feColorMatrix type="matrix" values="0 0 0 0 0.101961 0 0 0 0 0.105882 0 0 0 0 0.121569 0 0 0 0.12 0"/>
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4131_671"/>
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4131_671" result="shape"/>
			</filter>`;

		this.applySvgStyle();
		return this.svg;
	}
	setPopup() {
		if (this.popupDiv) {
			ReactDOM.unmountComponentAtNode(this.popupDiv);
			this.popupDiv = null;
		}
		if (this.popup) {
			this.popup.remove();
			this.popup = null;
		}
		if (this.props.popup) {
			this.popup = new mapboxgl.Popup();
			this.popupDiv = document.createElement('div');
			ReactDOM.render(this.props.popup, this.popupDiv);
			this.popup.setDOMContent(this.popupDiv);
			this.marker.setPopup(this.popup);
		}
	}
}
