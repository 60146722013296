import { Localize } from '@planity/components';
import { useModal } from '@planity/ui';
import { Button } from '@planity/ui';
import React from 'react';
import styles from './cancellation_modal_buttons.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';

export const CancellationModalButtons = ({
	confirmButtonLabel,
	onCancelClick,
	onSubmitClick
}) => {
	useStyles(styles);
	const cx = classNames.bind(styles);
	const { closeModal } = useModal();

	return (
		<>
			<Button
				className={cx({ cancelButton: true })}
				id='nocancel-appointment-button'
				isFullMobile
				label={<Localize text={'myAccount.appointment.noCancel'} />}
				type='secondary'
				onClick={() => closeModal(onCancelClick)}
			/>
			<Button
				className={cx({ confirmButton: true })}
				id='cancel-appointment-confirm-button'
				isFullMobile
				label={confirmButtonLabel}
				type='danger'
				onClick={() => closeModal(onSubmitClick)}
			/>
		</>
	);
};
