export colors from './colors';
import * as breakpointsExports from './breakpoints';
export const breakpoints = breakpointsExports;
export dimensions from './dimensions';
export layers from './layers';
import MatchModule from './match';
export const Match = MatchModule();
export * from './function';
export fontWeight from './fontWeight';
export fontSize from './fontSize';
export * as refonte_dimensions from './refonte_dimensions';
export {
	withScreenDimensions,
	default as WithScreenDimensions
} from './with_screen_dimensions';
export fonts from './font';
