import React, { useEffect, useRef, useState } from 'react';
import { noop } from '@planity/helpers';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './textarea.module.scss';
import { Localize } from '@planity/components';

export function Textarea({
	label,
	placeholder,
	defaultValue,
	isDisabled = false,
	isRequired = false,
	errorMessage,
	autoResize = true,
	characterLimit,
	minHeight = 0,
	value,
	onChange = noop,
	className,
	maxLength,
	id,
	isSubstractingCharacters = false
}) {
	useStyles(styles);
	const input = useRef();

	const [isFocused, setIsFocused] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [commentValue, setCommentValue] = useState(value);

	const classes = classNames.bind(styles)({
		textarea: true,
		[className]: className !== undefined
	});

	const handleChange = e => {
		if (e.currentTarget.value.length <= characterLimit) {
			onChange(e.currentTarget.value);
			setCommentValue(e.currentTarget.value);
			input.current.style.height = '';
			if (autoResize)
				input.current.style.height = `${Math.max(
					input.current.scrollHeight,
					minHeight - 32
				)}px`;
		}
	};

	useEffect(() => {
		input.current.style.height = characterLimit
			? minHeight - 32
			: minHeight + 'px';
	}, [input, characterLimit, minHeight]);

	const renderLabel = label && (
		<span className={styles.label}>
			{label} {isRequired && '*'}
		</span>
	);
	const remainingChars = isSubstractingCharacters
		? commentValue?.length || 0
		: characterLimit - (commentValue?.length || 0);

	const renderField = (
		<>
			<div
				className={classNames.bind(styles)({
					container: true,
					isDisabled: isDisabled,
					isFocused: isFocused,
					isInvalid: hasError
				})}
			>
				<textarea
					id={id || 'textarea-ui'}
					ref={input}
					onFocus={() => setIsFocused(true)}
					onBlur={() => {
						setIsFocused(false);
						setHasError(isRequired || value?.length > characterLimit); // Todo: error checking
					}}
					onChange={e => handleChange(e)}
					disabled={isDisabled}
					required={isRequired}
					maxLength={maxLength}
					className={styles.field}
					placeholder={placeholder}
					value={commentValue || ''}
				/>
				{characterLimit !== 0 && (
					<span className={styles.characterCount}>
						<Localize
							args={{
								remainingChars: remainingChars,
								count: isSubstractingCharacters
									? commentValue?.length || 0
									: characterLimit - (commentValue?.length || 0)
							}}
							text={
								isSubstractingCharacters
									? 'reviewForm.remainingChars'
									: 'comment.commentRemainingChars'
							}
						/>
					</span>
				)}
			</div>
		</>
	);

	const renderError = !isFocused && !isDisabled && hasError && errorMessage && (
		<span className={styles.error}>{errorMessage}</span>
	);

	return (
		<div className={classes}>
			<label>
				{renderLabel}
				{renderField}
				{renderError}
			</label>
		</div>
	);
}
