import { useLocalizedRoutes } from '@planity/localization';
import styles from './places.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { Link } from 'react-router-dom';
import { Localize } from '@planity/components';
import { Place } from '@planity/ui';

export function Places({ category, places, className }) {
	const { routes } = useLocalizedRoutes();
	const lastIndex1 = places.length - (places.length % 1);
	const lastIndex2 = places.length - (places.length % 2);
	const lastIndex3 = places.length - (places.length % 3);

	const classes = classNames.bind(styles)({
		places: true,
		[className]: className
	});
	useStyles(styles);

	return (
		<section className={classes}>
			<nav className={styles.content}>
				<div className={styles.placesWrapper}>
					{(places || []).map((place, i) => (
						<Place
							category={category}
							dataHide1={i >= lastIndex1}
							dataHide2={i >= lastIndex2}
							dataHide3={i >= lastIndex3}
							index={i}
							key={i}
							place={place}
						/>
					))}
				</div>
			</nav>
			<Link
				className={styles.seeMore}
				to={routes.catchAll({ category, inWholeCountry: true })}
			>
				<Localize text={'nav.seeMorePlaces'} />
			</Link>
		</section>
	);
}
