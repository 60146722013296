import { Button, Card } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './booking_auth.module.scss';
import { Localize, useFormHooks } from '@planity/components';
import { useTranslation } from '@planity/localization';

export function BookingAuth() {
	const { formValues } = useFormHooks();
	const { navigateLogState } = formValues;

	const { t } = useTranslation();
	const { authBooking } = formValues;
	useStyles(styles);
	const classes = classNames.bind(styles)({
		auth: true,
		connection: authBooking
	});
	return (
		<div id='login-comp' className={classes}>
			<div id='member-connect-comp'>
				<h2 className={styles.title}>
					{t(`${process.env.WIDGET ? 'widget.' : ''}auth.form.buttons.new`)}
				</h2>
				<Button
					id={'login-comp__memberBtn'}
					label={t(`auth.form.buttons.SIGN_UP`)}
					isFullMobile
					isFullWidth
					type='tertiary'
					size='large'
					onClick={() => {
						navigateLogState('signup');
					}}
				/>
				<span className={styles.or}>
					<Localize text={'myAccount.or'} />
				</span>
			</div>

			<h1 className={styles.title}>
				{t(
					`${process.env.WIDGET ? 'widget.' : ''}auth.form.buttons.already_used`
				)}
			</h1>
			<form className={styles.form}>
				<div className={styles.linksButton}>
					<Button
						id={'login-comp__submit'}
						label={t(`auth.form.buttons.SIGN_IN`)}
						isFullMobile
						isFullWidth
						size='large'
						onClick={() => {
							navigateLogState('login');
						}}
					/>
				</div>
			</form>
		</div>
	);
}
