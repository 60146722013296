export const layers = [
	'base',
	'cookiesWarning',
	'searchBar',
	'dropdown',
	'mobileMenu'
].reduce((l, k, i) => {
	l[k] = i + 1;
	return l;
}, {});

export const maxSearchBarWidth = 1030;

export const asideWidths = {
	small: 315,
	large: 365
};
/**
 * GLOBAL LAYOUT
 */
export const GLOBAL_PADDING = 15;
export const GLOBAL_PADDING_LARGE = 40;
export const GLOBAL_HEADER_HEIGHT = 63;
/**
 * SEARCHPAGE LAYOUT
 */
// width in viewport
export const MAIN_WIDTH_DESKTOP = 58;
export const ASIDE_WIDTH_DESKTOP = 42;
// width in pixel
export const MAIN_SIZE_LARGE_DESKTOP = 850;
// height in pixel
export const SEARCHBAR_HEIGHT = 54;
export const TAGSBAR_HEIGHT = 70;
export const FULL_SEARCHBAR_HEADER_HEIGHT =
	GLOBAL_HEADER_HEIGHT + TAGSBAR_HEIGHT;
export const OFFSET_SCROLLTO_BUSINESS = 18;
export const MAP_HEIGHT_INCREASE_BECAUSE_LOREAL = 50;
export const HEIGHT_HEADER_SEARCH_MOBILE = 56;
export const HEIGHT_INPUT_SEARCH_MOBILE = 54;
