import React, { useState } from 'react';
import { firebase } from '@planity/datastores';
import { ErrorMessage, Localize } from '@planity/components';
import Link from '../link';
import colors from '@planity/theme/colors';

const ERROR_WHITELIST = [
	'auth/credential-already-in-use',
	'auth/email-already-in-use'
].map(e => `auth.errors.${e}`);

/**
 * @param providerName { String } Provider name to display
 * @param providerId { String } Provider id such as 'facebook.com' or 'google.com'
 * @param authProvider { firebase.auth.Provider }
 * @param providerIcon { React.Component } the provider icon
 * @param providers { Array } a list of linked providers
 * @param setProviders { function }
 */
export const SocialAccountLink = ({
	providerName,
	providerId,
	authProvider,
	providerIcon,
	providers,
	setProviders
}) => {
	if (!firebase.auth().currentUser) {
		console.error(
			'No user logged in. Not displaying social buttons to link accounts'
		);
		return null;
	}

	const [error, setError] = useState(null);

	// This is not real time so we have to do it like that
	const [isLinkedWithProvider, setIsLinkedWithProvider] = useState(
		providers.includes(providerId)
	);

	const unlinkProvider = () =>
		firebase
			.auth()
			.currentUser.unlink(providerId)
			.then(() => {
				setProviders(providers.filter(provider => provider !== providerId));
				setIsLinkedWithProvider(false);
			})
			.catch(e => {
				console.error(e);
				setError(e.code);
			});

	const linkProvider = () =>
		firebase
			.auth()
			.currentUser.linkWithPopup(authProvider)
			.then(() => {
				setProviders(providers.concat(providerId));
				setIsLinkedWithProvider(true);
			})
			.catch(e => {
				console.error(e);
				setError(e.code);
			});

	/**
	 * @type {Boolean}
	 * When the user is logged only with one provider, forbid him to unlink his
	 * account because it's difficult to reconnect him afterwards
	 */
	const canUnlinkProvider =
		providers.length === 1 && providers[0] === providerId;

	return (
		<div>
			<ErrorMessage
				defaultMessage={'auth.errors.defaultError'}
				error={error && `auth.errors.${error}`}
				whitelist={ERROR_WHITELIST}
			/>
			<div css={styles.container}>
				<div css={styles.leftContainer}>
					{/* The next div is a trick so Facebook (20*20) and Google (24*24) icons are aligned */}
					<div css={styles.socialButton}>{providerIcon}</div>
					<div css={styles.providerStatusBlock}>
						<p css={styles.providerText}>{providerName}</p>
						<p css={styles.statusText}>
							{isLinkedWithProvider ? 'Connecté' : 'Non connecté'}
						</p>
						{canUnlinkProvider && (
							<p css={styles.statusText}>
								<Localize
									args={providerName}
									text='myAccount.social.disconnect'
								/>
							</p>
						)}
					</div>
				</div>

				{isLinkedWithProvider ? (
					<Link
						isDisabled={canUnlinkProvider}
						text={'Déconnecter'}
						onPress={unlinkProvider}
					/>
				) : (
					<Link text={'Connexion'} onPress={linkProvider} />
				)}
			</div>
		</div>
	);
};

const styles = {
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	leftContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	providerStatusBlock: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
		alignContent: 'center',
		marginRight: 12
	},
	providerText: {
		fontSize: 14,
		lineHeight: 1.29,
		margin: '0 0 0 12px',
		color: colors.black.text
	},
	statusText: {
		fontSize: 14,
		margin: '0 0 0 12px',
		color: colors.grey.brownGrey,
		lineHeight: 1.29
	},
	socialButton: {
		width: 24,
		height: 24,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'center'
	}
};
