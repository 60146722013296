// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tag_bar-module_tagBar-QIG03{z-index:1}@media(min-width: 768px){.tag_bar-module_tagBar-QIG03{display:flex;flex-direction:row;align-items:center;padding:15px 0px 15px 24px;position:absolute;left:0px;top:64px;background-color:var(--white);box-shadow:inset 0px -1px 0px #eef0f2;width:100%}}", "",{"version":3,"sources":["webpack://./../../packages/ui/uikit/filter/tag_bar/tag_bar.module.scss"],"names":[],"mappings":"AAEA,6BACC,SAAA,CACA,yBAFD,6BAGE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,0BAAA,CACA,iBAAA,CACA,QAAA,CACA,QAAA,CACA,6BAAA,CACA,qCAAA,CACA,UAAA,CAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.tagBar {\n\tz-index: 1;\n\t@media (min-width: #{styles.$tablet }) {\n\t\tdisplay: flex;\n\t\tflex-direction: row;\n\t\talign-items: center;\n\t\tpadding: 15px 0px 15px 24px;\n\t\tposition: absolute;\n\t\tleft: 0px;\n\t\ttop: 64px;\n\t\tbackground-color: var(--white);\n\t\tbox-shadow: inset 0px -1px 0px #eef0f2;\n\t\twidth: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"tagBar": "tag_bar-module_tagBar-QIG03"
};
module.exports = ___CSS_LOADER_EXPORT___;
