// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (min-width: 768px){.around_me-module_aroundMe-Ny3tG{display:none}}", "",{"version":3,"sources":["webpack://./app/components/category_page/around_me.module.scss"],"names":[],"mappings":"AAGC,0CADD,iCAEE,YAAA,CAAA","sourcesContent":["@use '@planity/ui/styles/utilities/variables';\n\n.aroundMe {\n\t@media only screen and (min-width: variables.$tablet) {\n\t\tdisplay: none;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"aroundMe": "around_me-module_aroundMe-Ny3tG"
};
module.exports = ___CSS_LOADER_EXPORT___;
