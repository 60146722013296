import React, { useRef, useEffect } from 'react';
import { PhoneInput } from '@planity/components';
import { PHONE_NUMBER_CHANGE } from '../auth_actions';
import { Authentication } from '@planity/ui';
import { useTranslation } from '@planity/localization';
import { useFormHooks } from './useFormHooks';
import styles from '../authentication.module.scss';

export const AskingMissingDataForm = () => {
	const { t } = useTranslation();
	const phoneInput = useRef(null);

	const { state, dispatch, formValues, errors } = useFormHooks();
	const { defaultCountry, pending, machineState, navigateLogState } =
		formValues;

	useEffect(() => {
		if (phoneInput && phoneInput.current && phoneInput.current.focus)
			phoneInput.current.focus();
	}, [machineState]);
	const data = state && state.context;

	const localizedFinishSignUpText = {
		title: t(`${process.env.WIDGET ? 'widget.' : ''}auth.form.texts.finish`),
		labels: {
			phoneNumber: t('auth.form.labels.phone'),
			lastName: t('auth.form.labels.lastName'),
			firstName: t('auth.form.labels.firstName'),
			button: {
				confirm: t(`auth.form.buttons.confirmSignUp`),
				deconnect: t(`auth.form.links.SIGN_OUT`)
			}
		},
		placeholders: {
			phoneNumber: t(`auth.form.placeholders.phone`),
			lastName: t(`auth.form.placeholders.lastName`),
			firstName: t(`auth.form.placeholders.firstName`)
		}
	};
	const css = {
		phoneInput: {
			width: '100%'
		}
	};
	const phoneInputField = (
		<PhoneInput
			css={css.phoneInput}
			data={data}
			defaultCountry={defaultCountry}
			errors={errors}
			forwardedRef={phoneInput}
			id='finish-signup-comp__input-phone'
			isDisabled={true}
			localizedPlaceholder={'auth.form.placeholders.phoneNumber'}
			localizedText={localizedFinishSignUpText}
			value={state?.context?.phoneNumber}
			onChange={phoneNumberInput =>
				dispatch({
					type: PHONE_NUMBER_CHANGE,
					phoneNumber: phoneNumberInput
				})
			}
		/>
	);

	return (
		<React.Fragment>
			<div className={styles.finish} id='finish-signup-form'>
				<Authentication
					data={data}
					localizedText={localizedFinishSignUpText}
					logState={'finishsignup'}
					navigateLogState={navigateLogState}
					pending={pending}
					phoneInput={phoneInputField}
				/>
			</div>
		</React.Fragment>
	);
};
