import { useLocalizedRoutes } from '@planity/localization';
import { AroundMe } from './around_me';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { safeRead } from '@planity/helpers';
import { AlgoliaSearch } from '../data';
import { Layout, PageSection } from '../layout';
import { BottomLinks } from '../ui';
import Metadata from './metadata';
import { SearchBar } from './search_bar';
import credentials from '@planity/credentials';
import styles from './category_directory_page.module.scss';

export function CategoryDirectoryPage({ parentCategory, category, location }) {
	const { routes } = useLocalizedRoutes();
	const classes = classNames.bind(styles)({
		directory: true
	});
	useStyles(styles);
	const currentCategory = category || parentCategory;
	return (
		<div className={classes}>
			<AlgoliaSearch
				attributesToHighlight={[]}
				filters={`objectID:${location.pathname}`}
				index={credentials.SEO_DATA}
				localizeResults
			>
				{({ data }) => {
					const seoData = (data && data[0]) || {};
					return (
						<Layout
							hasSearchBar
							headerIsCollapsible
							hideBreadcrumbs
							isDark
							showAppTeaser
							skipCoronavirusModal
						>
							<PageSection className={styles.wrapper}>
								<div className={styles.headerWrapper}>
									<Metadata category={currentCategory} isFrance />
									<div className={styles.placeInput}>
										<SearchBar
											isFrance
											search={{ parentCategory: currentCategory }}
										/>
										<AroundMe category={parentCategory} />
									</div>
								</div>
								<div className={styles.bodyWrapper}>
									<BottomLinks
										getPath={parentPlace =>
											routes.catchAll({
												search: {
													parentCategory,
													category,
													parentPlace
												}
											})
										}
										getText={place =>
											`${currentCategory.name} ${place.preposition} ${place.name}`
										}
										links={safeRead(seoData, ['places', 'children'])}
									/>
								</div>
							</PageSection>
						</Layout>
					);
				}}
			</AlgoliaSearch>
		</div>
	);
}
