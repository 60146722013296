import classNames from 'classnames/bind';
import { Icon } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './icon_button.module.scss';

export function IconButton({
	label,
	icon = 'ChevronRight',
	onClick,
	onMouseDown,
	className
}) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		iconButton: true,
		[className]: className !== undefined
	});

	return (
		<button
			className={classes}
			aria-label={label}
			onClick={onClick}
			onMouseDown={onMouseDown}
		>
			<Icon className={styles.icon} icon={icon} size={'medium'} />
		</button>
	);
}
