import { sendAnalytics } from './helper';

const APP_BANNER_HAS_BEEN_DISPLAYED = 'APP_BANNER_HAS_BEEN_DISPLAYED';
const APP_BANNER_HAS_BEEN_CLICKED = 'APP_BANNER_HAS_BEEN_CLICKED';

export const sendAppBannerHasBeenDisplayed = sendAnalytics(
	APP_BANNER_HAS_BEEN_DISPLAYED,
	'HomePage',
	'app_banner'
);
export const sendAppBannerHasBeenClicked = sendAnalytics(
	APP_BANNER_HAS_BEEN_CLICKED,
	'HomePage',
	'app_banner'
);
