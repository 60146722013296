let smallCompatibleWithWidget = '.875rem';

if (process.env.WIDGET) {
	smallCompatibleWithWidget = '14px';
}

export default {
	smallCompatibleWithWidget: smallCompatibleWithWidget,
	extraSmall: 10, // '0.625rem', // 10px
	small: 12, // '0.75rem', // 12px
	search: 13, // '0.8125rem', // 13px
	regular: 14, // '0.875rem', // 14px
	extraRegular: 18,
	medium: 25, // '1.5625rem', // 25px
	extraMedium: 30, // '1.875rem', // 30px
	large: 40, // '2.85rem', // 40px
	extraLarge: 50 // '3.125rem' // 50px
};
