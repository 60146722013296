export function PIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
		>
			<path
				d='M14.2497 0.223602H0.118012V23.7764H4.82857V19.0658H14.2497C19.4522 19.0658 23.6708 14.8473 23.6708 9.64472C23.6708 4.44217 19.4522 0.223602 14.2497 0.223602ZM14.2497 14.3553H4.82857V4.93416H14.2497C16.851 4.93416 18.9602 7.04344 18.9602 9.64472C18.9602 12.246 16.851 14.3553 14.2497 14.3553Z'
				fill='#202020'
			/>
		</svg>
	);
}
