import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './tooltip.module.scss';

export function Tooltip({ text }) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		tooltip: true
	});

	return (
		<div className={classes}>
			<span>{text}</span>
		</div>
	);
}
