import memoize from 'fast-memoize';
// import powerset from 'powerset';
import { safeRead } from '../functions';
import { businessCalendar, businessWebVisibleCalendarsById } from './calendars';
import { businessService, canChooseServiceSubsteps } from './services';

export function calendarsFromFormula(formula, keep) {
	if (formula) {
		const subFormula = Array.from(
			new Set(formula.and || safeRead(formula, ['xor', 'from']))
		);
		if (subFormula.length) {
			return subFormula.reduce((parts, token) => {
				calendarsFromFormula(token).forEach(calendar => {
					if (!keep || keep(calendar)) {
						parts.push(calendar);
					}
				});
				return parts;
			}, []);
		} else {
			return [formula];
		}
	} else {
		return [];
	}
}

export const businessServiceWebSelectableCalendars = memoize(
	(business, serviceId) => {
		const service = businessService(business, serviceId);
		const isComplex = !!service.sequence;
		if (isComplex) {
			if (canChooseServiceSubsteps(service, business)) {
				return null;
			} else if (service.formula) {
				return simpleServiceWebSelectableCalendars(business, service.formula);
			} else {
				return complexServiceWebSelectableCalendars(business, service.sequence);
			}
		} else {
			return simpleServiceWebSelectableCalendars(business, service.formula);
		}
	}
);

const simpleServiceWebSelectableCalendars = memoize((business, formula) => {
	const calendars = businessWebVisibleCalendarsById(business);
	return calendarsFromFormula(formula, calendarId => !!calendars[calendarId])
		.map(calendarId => calendars[calendarId])
		.filter(x => !!x)
		.sort((x, y) => x.sort - y.sort);
});

const complexServiceWebSelectableCalendars = memoize((business, sequence) => {
	return (sequence || [])
		.reduce((calendars, step) => {
			if (step.serviceId !== 'PAUSE') {
				const service = businessService(business, step.serviceId);
				const stepCalendars = simpleServiceWebSelectableCalendars(
					business,
					service.formula
				);
				stepCalendars.forEach(calendar => {
					if (!calendars.find(({ id }) => id === calendar.id)) {
						calendars.push(calendar);
					}
				});
			}
			return calendars;
		}, [])
		.sort((x, y) => x.sort - y.sort);
});

/**
 * For now we support
 * 'a'
 * {xor: {qty: n, from: []}}
 * {and: ['a', 'b']}
 * {and: ['a', { xor }]}
 * {and: [{ xor }, 'a']}
 * {and: [{ xor }, { xor }]}
 */
export const expandFormula = memoize(formula => {
	if (formula) {
		if (formula.and) {
			const xorSize = formula.and.filter(part => part.xor).length;
			if (xorSize === 0) {
				return [formula.and];
			} else if (xorSize === 1) {
				const xorIndex = formula.and.findIndex(part => part.xor);
				const simpleIndex = xorIndex === 0 ? 1 : 0;
				return expandFormula(safeRead(formula, ['and', xorIndex])).map(
					candidate => [...candidate, safeRead(formula, ['and', simpleIndex])]
				);
			} else {
				const parts = formula.and.map(part => expandFormula(part));
				return parts[0].reduce((candidates, candidate) => {
					parts[1].forEach(part => {
						candidates.push(part.concat(candidate));
					});
					return candidates;
				}, []);
			}
		} else if (formula.xor) {
			return subsets(
				safeRead(formula, ['xor', 'from']),
				safeRead(formula, ['xor', 'qty'])
			);
		} else {
			return [[formula]];
		}
	} else {
		throw [];
	}
});

function subsets(set, length = 1) {
	if (length === 1) {
		return set.map(s => [s]);
	}

	return powerset(set, length).filter(
		x => x.length === length && x.every(y => !!y)
	);
}

/**
 * This helper exists to prevent a bad user experience
 * when a service is bookable but is configured in such a way
 * that it CANNOT have availabilities
 * eg. all selectable calendars are webHidden
 *
 * - A simple service is selectable if it has some selectable calendar
 * - A complex service is selectable if all of its substeps have some selectable calendar
 *
 * In this context, a calendar is considered selectable if either:
 * - it is human, has the skill and is webVisible
 * - it is a resource, has the skill, is webVisible and the service does not need
 *   any human calendar
 */
export const serviceHasSelectableCalendars = memoize((business, serviceId) => {
	const service = businessService(business, serviceId);
	if (!service) {
		return false;
	}
	if (service.sequence) {
		return !service.sequence.some(
			step =>
				step.serviceId !== 'PAUSE' &&
				!serviceHasSelectableCalendars(business, step.serviceId)
		);
	} else {
		const skilledCalendars = calendarsFromFormula(service.formula).reduce(
			(calendars, calendarId) => {
				const calendar = businessCalendar(business, calendarId);
				if (calendar) {
					calendars[calendar.calendarSetType].push(calendar);
				}
				return calendars;
			},
			{ people: [], resources: [] }
		);
		const someTypeHasCalendars = !!(
			skilledCalendars.people.length || skilledCalendars.resources.length
		);
		return (
			someTypeHasCalendars &&
			!['people', 'resources'].find(type => {
				const typeCalendars = skilledCalendars[type];
				return !!(
					typeCalendars.length &&
					!typeCalendars.filter(calendar => !calendar.webHidden).length
				);
			})
		);
	}
});

function powerset(set, size) {
	const l = set.length;
	if (size > l) return [];
	if (size === 0) return [[]];
	return set.reduce((subsets, item, i) => {
		for (const s of powerset(set.slice(i + 1, l), size - 1)) {
			s.push(item);
			subsets.push(s);
		}
		return subsets;
	}, []);
}
