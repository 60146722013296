import React from 'react';

export const JO = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5757)'>
				<mask
					id='mask0_11534_5757'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5757)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#F7FCFF'
					/>
					<mask
						id='mask1_11534_5757'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_11534_5757)'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V5H20V0H0Z'
							fill='#272727'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 10V15H20V10H0Z'
							fill='#009933'
						/>
					</g>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15L12.5 7.5L0 0Z'
						fill='#E31D1C'
					/>
					<mask
						id='mask2_11534_5757'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='13'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15L12.5 7.5L0 0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask2_11534_5757)'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M5.62612 8.66917L4.45695 9.375L4.72363 7.99765L3.75 6.96684L5.06835 6.9111L5.62612 5.625L6.18389 6.9111H7.5L6.52861 7.99765L6.82106 9.375L5.62612 8.66917Z'
							fill='#F7FCFF'
						/>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5757'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
