import React, { useMemo } from 'react';

const IMAGES_URLS = [
	'https://res.cloudinary.com/planity/image/upload/v1701340648/portail/illustrations/LOGIN/2023/1.jpg',
	'https://res.cloudinary.com/planity/image/upload/v1701340648/portail/illustrations/LOGIN/2023/2.jpg',
	'https://res.cloudinary.com/planity/image/upload/v1701340648/portail/illustrations/LOGIN/2023/3.jpg',
	'https://res.cloudinary.com/planity/image/upload/v1701340649/portail/illustrations/LOGIN/2023/4.jpg'
];

export const useRandomImages = () => {
	return useMemo(
		() => IMAGES_URLS[Math.floor(Math.random() * IMAGES_URLS.length)],
		[]
	);
};

export const withRandomImages = WrappedComponent => props => {
	const imageUrl = useRandomImages();

	return <WrappedComponent imageUrl={imageUrl} {...props} />;
};
