import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { noop } from '@planity/helpers';
import isEqualWith from 'lodash/isEqualWith';
import hash from 'object-hash';

const defer = cb =>
	(
		window.requestIdleCallback ||
		window.requestAnimationFrame ||
		window.setImmediate
	)(cb);
const cancelDeferred = deferred =>
	(
		window.cancelIdleCallback ||
		window.cancelAnimationFrame ||
		window.cancelImmediate
	)(deferred);

export default class CreateFetcher extends PureComponent {
	static defaultProps = {
		queryHasChanged: (props, prevProps) =>
			!isEqualWith(
				props,
				prevProps,
				(x, y) =>
					(typeof x === 'function' && typeof y === 'function') ||
					(x === undefined && y === null) ||
					(x === null && y === undefined) ||
					undefined
			)
	};

	constructor(props) {
		super(props);
		if (process.env.BROWSER) {
			this.debug('setting initial state', {
				initialState: this.initialState(),
				fromProps: this.props.initialState
			});
		}
		this.state = {
			prevQuery: null,
			...this.initialState()
		};
	}

	static getDerivedStateFromProps(props, state) {
		let newState = null;
		const queryHasChanged = props.queryHasChanged(props.query, state.prevQuery);
		if (queryHasChanged) {
			newState = { prevQuery: props.query };
			if (state.prevQuery && !props.isDisabled) {
				newState.isLoading = true;
			}
		}
		debug(props, 'getDerivedStateFromProps', {
			state,
			props,
			newState
		});
		return newState;
	}

	componentDidMount() {
		this._isMounted = true;
		if (this.state.isLoading) {
			this.deferred = defer(() => this.subscribe());
		} else if (this.props.isPermanent) {
			this.deferred = defer(() => this.subscribe(true));
		}
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.queryHasChanged(this.props.query, prevProps.query) ||
			(!this.props.cacheProvider && prevProps.cacheProvider)
		) {
			this.debug('query has changed', {
				query: this.props.query,
				prevQuery: prevProps.query
			});
			this.cancelDeferred();
			this.deferred = defer(() => this.subscribe());
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		if (this.unsubscribe) {
			this.unsubscribe();
		}
		this.cancelDeferred();
	}

	render() {
		this.debug('render');
		if (process.env.BROWSER) {
			return this.props.children ? this.props.children(this.state) : null;
		} else {
			return (
				<Route
					render={({ staticContext }) => {
						if (staticContext && staticContext.dependencies) {
							const queryHash = hash(this.props.query);
							const data = staticContext.dependencies.get(queryHash);
							if (data) {
								return this.props.children ? this.props.children(data) : null;
							} else {
								staticContext.dependencies.request(
									queryHash,
									this.queryFromCacheOrFetchOnce
								);
								return null;
							}
						} else {
							return null;
						}
					}}
				/>
			);
		}
	}
	initialState() {
		if (!process.env.BROWSER) {
			return {
				isLoading: false
			};
		}
		const queryHash = hash(this.props.query);
		if (
			typeof window !== 'undefined' &&
			window._planity_isHydrating &&
			window._planity_localStates
		) {
			if (window._planity_localStates[queryHash]) {
				return {
					isLoading: false,
					...window._planity_localStates[queryHash]
				};
			} else {
				return {
					isLoading: true,
					...this.props.initialState
				};
			}
		} else if (this.props.cacheProvider) {
			const value = this.props.cacheProvider.get(queryHash, this.props.query);
			if (value) {
				return {
					isLoading: false,
					...value
				};
			} else {
				return {
					isLoading: true,
					...this.props.initialState
				};
			}
		} else {
			return {
				isLoading: true,
				...this.props.initialState
			};
		}
	}
	subscribe(skipSetLoading) {
		if (this.unsubscribe) this.unsubscribe();
		const queryHash = this.props.cacheProvider && hash(this.props.query);
		if (this.props.cacheProvider && this.props.cacheProvider.get(queryHash)) {
			this.debug(`reading from cache`);
			this.set({
				isLoading: false,
				...this.props.cacheProvider.get(queryHash)
			});
		} else {
			(skipSetLoading
				? cb => cb()
				: cb => this.set({ isLoading: !this.props.isDisabled }, cb))(() => {
				this.debug('subscribing');
				this.unsubscribe = this.props.subscribe({
					emit: data => {
						if (this._isMounted) {
							this.debug('received data', data);
							this.set({
								isLoading: false,
								...data
							});
						}
						if (this.props.cacheProvider) {
							this.debug('setting cache');
							this.props.cacheProvider.set(queryHash, data);
						}
					}
				});
			});
		}
	}
	queryFromCacheOrFetchOnce = async () => {
		const { cacheProvider, query } = this.props;
		if (
			cacheProvider &&
			cacheProvider.isServerCacheProvider &&
			(!cacheProvider.useCache || !!cacheProvider.useCache(query))
		) {
			const cacheKey = this.serverCacheProviderKey();
			let value = await cacheProvider.get(cacheKey, JSON.stringify(query));
			if (value === undefined) {
				value = await this.fetchOnce();
				await cacheProvider.set(cacheKey, value);
			}
			return value;
		} else {
			return await this.fetchOnce();
		}
	};
	fetchOnce = () => {
		return new Promise(resolve => {
			this.props.subscribe({
				emit: data => resolve(data),
				once: true
			});
		});
	};
	set = (data, cb) => {
		const isControlled = !!this.props.onChange;
		if (isControlled) {
			this.props.onChange(data);
			if (cb) {
				// dirty workaround for now
				setImmediate(cb);
			}
		} else {
			this.setState(data, cb);
		}
	};
	debug(...args) {
		debug(this.props, ...args);
	}
	cancelDeferred = () => {
		if (this.deferred) {
			cancelDeferred(this.deferred);
			this.deferred = null;
		}
	};
	serverCacheProviderKey = () => {
		const queryHash = hash(this.props.query);
		if (this.props.getServerCacheProviderKey) {
			return this.props.getServerCacheProviderKey(queryHash);
		} else {
			return queryHash;
		}
	};
}

const debug =
	process.env.NODE_ENV === 'development'
		? (props, message, ...data) => {
				if (props.debug) {
					const messages = [
						process.env.BROWSER
							? `%c${props.debug}%c ${message}`
							: `${props.debug} ${message}`,
						process.env.BROWSER &&
							'background-color:rgb(1,22,39);color:rgb(127,219,202);',
						process.env.BROWSER && 'color:rgb(97,175,255);',
						...data
					].filter(x => !!x);
					console.log(...messages);
				}
		  }
		: noop;
