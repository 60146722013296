// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tooltip-module_tooltip-jyxiz{display:inline-flex;border-radius:var(--border-radius-small);padding:8px 12px;background-color:var(--grey-700);font:var(--regular) var(--body-4);color:var(--white);max-width:254px}", "",{"version":3,"sources":["webpack://./../../packages/ui/uikit/filter/tooltip/tooltip.module.scss"],"names":[],"mappings":"AAAA,8BACC,mBAAA,CACA,wCAAA,CACA,gBAAA,CACA,gCAAA,CACA,iCAAA,CAEA,kBAAA,CACA,eAAA","sourcesContent":[".tooltip {\n\tdisplay: inline-flex;\n\tborder-radius: var(--border-radius-small);\n\tpadding: 8px 12px;\n\tbackground-color: var(--grey-700);\n\tfont: var(--regular) var(--body-4);\n\n\tcolor: var(--white);\n\tmax-width: 254px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "tooltip-module_tooltip-jyxiz"
};
module.exports = ___CSS_LOADER_EXPORT___;
