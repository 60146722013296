import React from 'react';

export const DZ = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5644)'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M10 0H20V15H10V0Z'
					fill='#F7FCFF'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0 0H10V15H0V0Z'
					fill='#36A400'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M11.6375 3.90785C12.5374 3.90785 13.3755 4.15502 14.0784 4.58067C13.1021 3.43373 11.6478 2.70621 10.0236 2.70621C7.08411 2.70621 4.70118 5.08914 4.70118 8.02864C4.70118 10.9681 7.08411 13.3511 10.0236 13.3511C11.4879 13.3511 12.814 12.7598 13.7763 11.803C13.1403 12.1252 12.4118 12.3082 11.6375 12.3082C9.15532 12.3082 7.14312 10.4277 7.14312 8.10801C7.14312 5.78833 9.15532 3.90785 11.6375 3.90785ZM13.3973 5.51618L12.1048 6.98107L10.2483 6.46955L11.2775 8.07941L10.2483 9.79313L12.1957 9.09206L13.2603 10.7539V8.82512L15 8.07941L13.2603 7.45624L13.3973 5.51618Z'
					fill='#FF0000'
				/>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5644'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
