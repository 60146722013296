import React from 'react';
import { AuthForm } from '@planity/components';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './signed_out.module.scss';

export const SignedOut = ({ conditionsLink, defaultCountry, authBooking }) => {
	useStyles(styles);
	return (
		<div className={styles.booking}>
			<AuthForm
				authBooking
				conditionsLink={conditionsLink}
				defaultCountry={defaultCountry}
				fromAccount={authBooking}
				fullWidth
				scrollOnError={false}
				skipPasswordConfirmation
			/>
		</div>
	);
};
