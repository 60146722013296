import React, { forwardRef } from 'react';
import { noop } from '@planity/helpers';
import { colors, dimensions, fontSize, fontWeight } from '@planity/theme';
import Localize from '../localize/helper';

export default forwardRef(function TextInput(
	{
		autoComplete,
		className,
		onChange = noop,
		unit,
		style,
		value = '',
		placeholder,
		localizedPlaceholder,
		isDisabled,
		name,
		type,
		...props
	},
	ref
) {
	if (process.env.NODE_ENV === 'development' && !!style) {
		console.warn(
			'Using style prop on TextInput component. Use the css prop instead.'
		);
	}
	// TODO unit
	return (
		<Localize localizedText={localizedPlaceholder} text={placeholder}>
			{placeholder => (
				<input
					name={name || 'default-input-name'}
					ref={ref}
					type={type || 'text'}
					onChange={e => onChange(e.currentTarget.value)}
					{...(autoComplete
						? {
								autoComplete: 'on'
						  }
						: autoComplete === false
						? {
								autoComplete: 'off'
						  }
						: {})}
					css={[
						{
							'WebkitAppearance': 'none',
							'MozAppearance': 'none',
							'appearance': 'none',
							'display': 'block',
							'width': '100%',
							'height': dimensions.inputHeight,
							'padding': 12,
							'borderTopRightRadius': unit ? 0 : dimensions.borderRadius,
							'borderBottomRightRadius': unit ? 0 : dimensions.borderRadius,
							'borderBottomLeftRadius': dimensions.borderRadius,
							'borderTopLeftRadius': dimensions.borderRadius,
							'border': `solid ${colors.grey.border} 1px`,
							'outline': 'none',
							'backgroundColor': props.disabled
								? colors.grey.disabledText
								: 'white',
							// 'color': props.disabled ? colors.grey.text : colors.black.text,
							'cursor': props.disabled ? 'not-allowed' : 'text',
							'fontFamily': 'inherit',
							'fontSize': fontSize.search,
							'lineHeight': 'inherit',
							'fontWeight': fontWeight.normal,
							'transition': 'border-color ease-out .1s',
							'&:focus': {
								borderColor: colors.grey.lightPeach
							}
						},
						className
					]}
					disabled={!!isDisabled}
					placeholder={placeholder}
					value={value || ''}
					{...props}
				/>
			)}
		</Localize>
	);
});
