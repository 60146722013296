import React from 'react';

export const MT = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5839)'>
				<rect width='20' height='15' fill='#F7FCFF' />
				<mask
					id='mask0_11534_5839'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5839)'>
					<mask id='path-5-inside-1_11534_5839' fill='white'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M6.25 3.8H3.75V5.67034C3.47464 5.73566 3.2698 5.98315 3.2698 6.27848C3.2698 6.28568 3.26992 6.29286 3.27016 6.3H1.25V8.8H3.33171C3.41244 8.96711 3.56537 9.09281 3.75 9.1366V11.3H6.25V9.14465C6.45225 9.11036 6.62144 8.97891 6.70788 8.8H8.75V6.3H6.76942C6.76966 6.29286 6.76979 6.28568 6.76979 6.27848C6.76979 5.96916 6.54508 5.71232 6.25 5.6623V3.8Z'
						/>
					</mask>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M6.25 3.8H3.75V5.67034C3.47464 5.73566 3.2698 5.98315 3.2698 6.27848C3.2698 6.28568 3.26992 6.29286 3.27016 6.3H1.25V8.8H3.33171C3.41244 8.96711 3.56537 9.09281 3.75 9.1366V11.3H6.25V9.14465C6.45225 9.11036 6.62144 8.97891 6.70788 8.8H8.75V6.3H6.76942C6.76966 6.29286 6.76979 6.28568 6.76979 6.27848C6.76979 5.96916 6.54508 5.71232 6.25 5.6623V3.8Z'
						fill='#A0A0A0'
					/>
					<path
						d='M3.75 3.8V3.175H3.125V3.8H3.75ZM6.25 3.8H6.875V3.175H6.25V3.8ZM3.75 5.67034L3.89426 6.27846L4.375 6.16442V5.67034H3.75ZM3.27016 6.3V6.925H3.91667L3.89481 6.27887L3.27016 6.3ZM1.25 6.3V5.675H0.625V6.3H1.25ZM1.25 8.8H0.625V9.425H1.25V8.8ZM3.33171 8.8L3.89448 8.52812L3.72388 8.175H3.33171V8.8ZM3.75 9.1366H4.375V8.64252L3.89426 8.52848L3.75 9.1366ZM3.75 11.3H3.125V11.925H3.75V11.3ZM6.25 11.3V11.925H6.875V11.3H6.25ZM6.25 9.14465L6.14554 8.52844L5.625 8.61668V9.14465H6.25ZM6.70788 8.8V8.175H6.31571L6.14511 8.52812L6.70788 8.8ZM8.75 8.8V9.425H9.375V8.8H8.75ZM8.75 6.3H9.375V5.675H8.75V6.3ZM6.76942 6.3L6.14478 6.27887L6.12292 6.925H6.76942V6.3ZM6.25 5.6623H5.625V6.19026L6.14554 6.27851L6.25 5.6623ZM3.75 4.425H6.25V3.175H3.75V4.425ZM4.375 5.67034V3.8H3.125V5.67034H4.375ZM3.8948 6.27848L3.89426 6.27846L3.60574 5.06221C3.05501 5.19286 2.6448 5.687 2.6448 6.27848H3.8948ZM3.89481 6.27887L3.8948 6.27848H2.6448C2.6448 6.29273 2.64504 6.30695 2.64552 6.32114L3.89481 6.27887ZM1.25 6.925H3.27016V5.675H1.25V6.925ZM1.875 8.8V6.3H0.625V8.8H1.875ZM3.33171 8.175H1.25V9.425H3.33171V8.175ZM3.89426 8.52848L3.89448 8.52812L2.76894 9.07188C2.93005 9.40535 3.23493 9.65677 3.60574 9.74473L3.89426 8.52848ZM4.375 11.3V9.1366H3.125V11.3H4.375ZM6.25 10.675H3.75V11.925H6.25V10.675ZM5.625 9.14465V11.3H6.875V9.14465H5.625ZM6.35446 9.76085C6.76086 9.69196 7.09825 9.42871 7.27064 9.07188L6.14511 8.52812L6.14554 8.52844L6.35446 9.76085ZM8.75 8.175H6.70788V9.425H8.75V8.175ZM8.125 6.3V8.8H9.375V6.3H8.125ZM6.76942 6.925H8.75V5.675H6.76942V6.925ZM6.14479 6.27848L6.14478 6.27887L7.39406 6.32114C7.39454 6.30697 7.39479 6.29274 7.39479 6.27848H6.14479ZM6.14554 6.27851L6.14479 6.27848H7.39479C7.39479 5.65921 6.94509 5.14621 6.35446 5.04609L6.14554 6.27851ZM5.625 3.8V5.6623H6.875V3.8H5.625Z'
						fill='#FECA00'
						mask='url(#path-5-inside-1_11534_5839)'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M10 0H20V15H10V0Z'
						fill='#E31D1C'
					/>
					<mask
						id='mask1_11534_5839'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='10'
						y='0'
						width='10'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M10 0H20V15H10V0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_11534_5839)'></g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5839'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
