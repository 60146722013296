import { withLocalization, withLocalizedRoutes } from '@planity/localization';
import { Spinner } from '@planity/ui';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { safeRead, scrollTo, invokeLambda } from '@planity/helpers';
import { FirebaseSubscription, ErrorMessage } from '@planity/components';
import { colors } from '@planity/theme';
import { Layout } from '../layout';
import { WithCategories } from '../data';
import WithPlace from './with_place';
import { BusinessPageMetadata } from './business_page_metadata';
import capitalize from 'lodash/capitalize';
import { schemaType } from '../search_page/business';
import { BusinessPageMain } from './main';
import { withTranslation } from '@planity/localization';
import {
	redirectToBusiness,
	handlePaymentRedirect,
	handleWaitingListRedirect,
	handleGoogleRedirect
} from './helpers';

export default withTranslation()(
	withLocalizedRoutes(
		withLocalization(
			class BusinessPage extends Component {
				state = {
					hasStartedBooking: false,
					hasSuccessfulBooking: false,
					prevHasStartedBooking: null,
					isRedirectLoading: verifyWaitingListRedirectionToken({
						location: this.props.location
					})
				};
				static getDerivedStateFromProps(props, state) {
					if (props.hasStartedBooking !== state.prevHasStartedBooking) {
						return {
							hasStartedBooking:
								!!props.hasStartedBooking ||
								!!props.hasStartedBookingGiftVoucher,
							prevHasStartedBooking:
								props.hasStartedBooking || !!props.hasStartedBookingGiftVoucher
						};
					}
					return null;
				}

				async componentDidMount() {
					const { business, history, location, routes } = this.props;

					if (location?.search?.length > 0) {
						await handleRedirect({ business, history, location, routes });
						this.setState({ isRedirectLoading: false });
					}

					const scrollToPresta = safeRead(this.props, [
						'location',
						'state',
						'state',
						'scrollToPresta'
					]);

					if (scrollToPresta) {
						const servicesContainer =
							document.getElementById('services-container');
						const scrollToPosition =
							servicesContainer && servicesContainer.offsetTop
								? servicesContainer.offsetTop
								: 645;
						setTimeout(() => {
							scrollTo({ top: scrollToPosition, animated: true });
						}, 400);
					}
					if (process.env.USE_FACEBOOK_TRACKING) {
						if (window.fbq) {
							const { business } = this.props;
							if (business) {
								const { objectID, name } = business;
								if (objectID && name) {
									window.fbq('track', 'ViewContent', {
										content_name: name,
										content_ids: [objectID],
										content_type: 'product'
									});
								}
							}
						}
					}
				}

				render() {
					const {
						business: algoliaBusiness,
						location,
						warnOnLeave,
						initialServiceId,
						localizedBasePath,
						hasStartedBookingGiftVoucher,
						routes,
						t
					} = this.props;
					const { hasStartedBooking, hasSuccessfulBooking, isRedirectLoading } =
						this.state;
					const businessId = algoliaBusiness.objectID;

					const globalSearch = safeRead(this.props, [
						'location',
						'state',
						'state',
						'search'
					]);

					const highlightServices = safeRead(this.props, [
						'location',
						'state',
						'state',
						'highlightServices'
					]);

					const highlightedServices = [];
					/**
					 * Sort services to fit previous structure
					 * [{name:'Homme',children:[{...}]}]
					 *
					 * */

					highlightServices &&
						highlightServices.forEach(curr => {
							/*Check if object with category name exist*/
							var found = highlightedServices.find(function (element) {
								return element.name === curr.parentName;
							});

							if (typeof found == 'undefined') {
								/*!Exist : push new object*/
								highlightedServices.push({
									name: curr.parentName,
									description: curr.parentDescription,
									children: [curr]
								});
							} else {
								/*Exist : push in object*/
								found.children.push(curr);
							}
						});

					// this is useful only for localhost
					// on planitylab/planitytest/planity the cloud front function handlePaymentRedirection handles it
					if (
						!process.env.BROWSER &&
						hasStartedBooking &&
						location?.search?.length === 0 &&
						process.env.NODE_ENV === 'development'
					) {
						return (
							<Redirect to={`${localizedBasePath}/${algoliaBusiness.slug}`} />
						);
					}

					return (
						<WithCategories>
							{categories => (
								<FirebaseSubscription
									key={businessId}
									path={`businesses/${businessId}`}
									shard={'public'}
								>
									{({
										data: firebaseBusiness,
										isLoading: firebaseIsLoading
									}) => {
										// when the user have a bad network, firebase loading state is falsy but firebaseBusiness is still null
										const isLoading = firebaseIsLoading || !firebaseBusiness;
										const business = isLoading
											? algoliaBusiness
											: firebaseBusiness;
										return (
											<WithPlace business={business}>
												{({ parentPlace, place }) => {
													const { crumbs, search } = businessCrumbs({
														business,
														categories,
														parentPlace,
														place,
														routes
													});

													return (
														<Layout
															covidMessage={
																business.ica && business.covidMessage
															}
															crumbs={crumbs}
															hasBreadcrumbsSeparator
															hideBreadcrumbs={
																hasStartedBooking ||
																hasStartedBookingGiftVoucher ||
																isRedirectLoading
															}
															hideFooter={
																hasStartedBooking ||
																hasStartedBookingGiftVoucher ||
																isRedirectLoading
															}
															hideNavigationItems={
																hasStartedBooking || business.plStatus >= 3
															}
															isHiddenOnMobile
															showAppTeaser
															stickyOnDesktop={false}
														>
															{isRedirectLoading ? (
																<div
																	css={{
																		display: 'grid',
																		placeItems: 'center',
																		height: '100%',
																		backgroundColor: colors.grey.light
																	}}
																>
																	<Spinner />
																</div>
															) : (
																<>
																	<ErrorMessage
																		error={
																			location?.state?.state?.error &&
																			t('gift.errors.CRITICAL_ERROR')
																		}
																	/>
																	<BusinessPageMetadata
																		business={business}
																		businessId={businessId}
																		categories={categories}
																		place={place || parentPlace}
																	/>
																	<div
																		css={{
																			backgroundColor: colors.grey.light
																		}}
																		itemScope
																		itemType={`https://schema.org/${schemaType(
																			business
																		)}`}
																	>
																		<BusinessPageMain
																			business={business}
																			businessId={businessId}
																			categories={categories}
																			crumbs={crumbs}
																			globalSearch={globalSearch}
																			hasStartedBooking={hasStartedBooking}
																			hasStartedBookingGiftVoucher={
																				hasStartedBookingGiftVoucher
																			}
																			hasSuccessfulBooking={
																				hasSuccessfulBooking
																			}
																			highlightedServices={highlightedServices}
																			initialServiceId={
																				firebaseBusiness && initialServiceId
																			}
																			isLoading={
																				isLoading ||
																				location?.search?.length > 0
																			}
																			location={location}
																			pageContext={
																				!!this.props.hasStartedBooking ||
																				!!hasStartedBookingGiftVoucher
																			}
																			parentPlace={parentPlace}
																			parentSearch={search}
																			place={place}
																			warnOnLeave={warnOnLeave}
																			onBookingStart={() => {
																				this.setState({
																					hasStartedBooking: true
																				});
																			}}
																			onBookingSuccess={cb =>
																				this.setState(
																					{
																						hasSuccessfulBooking: true
																					},
																					cb
																				)
																			}
																		/>
																	</div>
																</>
															)}
														</Layout>
													);
												}}
											</WithPlace>
										);
									}}
								</FirebaseSubscription>
							)}
						</WithCategories>
					);
				}
			}
		)
	)
);

function businessCrumbs({ business, categories, parentPlace, place, routes }) {
	if (parentPlace && parentPlace.type === 'department') {
		parentPlace = null;
	}
	const crumbs = [{ name: business.name, path: routes.catchAll({ business }) }];
	const parentCategoryId = (business.types || '')
		.split(',')
		.filter(x => !!x)[0];
	if (parentCategoryId && categories) {
		const parentCategory = categories.find(
			c => c.objectID === parentCategoryId
		);
		const search = { parentCategory, parentPlace, place };
		return {
			crumbs: ['parentCategory', 'parentPlace', 'place']
				.reduce(
					(data, key) => {
						if (search[key]) {
							data.search[key] = search[key];
							data.crumbs.push({
								name: capitalize(
									safeRead(search, [key, 'singular'], {
										path: [key, 'name']
									})
								),
								path: routes.catchAll({ search: data.search })
							});
						}
						return data;
					},
					{ crumbs: [], search: {} }
				)
				.crumbs.concat(crumbs),
			search
		};
	} else {
		return { crumbs };
	}
}

const handleRedirect = async ({ business, history, location, routes }) => {
	const searchParams = new URLSearchParams(location?.search);
	switch (true) {
		case searchParams.has('tokenData'):
			return handlePaymentRedirect({
				tokenData: searchParams.get('tokenData'),
				error: searchParams.get('error'),
				business,
				history,
				routes
			});
		case searchParams.has('waitingListToken'):
			return await handleWaitingListRedirect({
				waitingListToken: searchParams.get('waitingListToken'),
				business,
				history,
				routes
			});
		/**
		 * reservation from google is only possible in planity site
		 * Thus, it is not applicable to white-label sites
		 */
		case searchParams.has('rwg_token'):
		case searchParams.has('serviceId'):
			return handleGoogleRedirect({
				business,
				history,
				routes,
				initialServiceId: searchParams.get('serviceId'),
				rwgTokenValue: searchParams.get('rwg_token')
			});
		default:
			await redirectToBusiness({
				error: searchParams.get('error'),
				business,
				history,
				routes
			});
	}
};

const verifyWaitingListRedirectionToken = ({ location }) => {
	const searchParams = new URLSearchParams(location?.search);
	return !!searchParams.get('waitingListToken');
};
