import React from 'react';
import { OpeningHours } from '@planity/components';
import { HowTo } from './how_to';
import { parseOpeningHours } from '@planity/helpers';
import { Modal } from '@planity/ui';
import styles from './how_to_modal.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';

export function HowToModal({
	displayOpeningHours = true,
	business,
	onClose,
	displayClickAndCollect,
	displayClickAndShop,
	howToIsVisible
}) {
	useStyles(styles);
	const openingHours =
		!!displayOpeningHours &&
		parseOpeningHours(business.openingHours, {
			convertToMinutes: false
		});

	// In the case of a confirmed appointment in a business (with C&C) where no appointment has ever been made,
	// when cancelling the appointment, the lastSuccessfulAppointmentId is not cleaned.
	// The confirmed appointment no longer exists, and associated data, such as business information, neither.
	// Attempting to clean up the lastSuccessfulAppointment has resulted in multiple regressions, so here is the dirty fix.
	if (!business) return null;

	return (
		<Modal closeModal={onClose} hasCloseBtn isOpen={howToIsVisible}>
			<div className={styles.howToModal}>
				<HowTo
					countryCode={business.countryCode}
					displayClickAndCollect={displayClickAndCollect}
					displayClickAndShop={displayClickAndShop}
					isModal
				/>
				{!!openingHours && (
					<OpeningHours
						business={business}
						className={styles.openingHours}
						openingHours={openingHours}
					/>
				)}
			</div>
		</Modal>
	);
}
