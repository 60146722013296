import credentials from '@planity/credentials';
import React, { Component, createContext, useContext } from 'react';
import { withAuth } from '../authentication/provider';
import { algolia, firebase } from '@planity/datastores';
import { getDateFromString, getNewestBeautyTeam } from '@planity/helpers';

const defaultState = {
	userQueries: {},
	beautyTeam: [],
	isLoadingUserQueries: false
};
export const UserQueriesContext = createContext(defaultState);
export const useUserQueries = () => useContext(UserQueriesContext);
export const BEAUTY_TEAM_NUMBER = 9;

export default withAuth(
	class UserQueriesProvider extends Component {
		constructor(props) {
			super(props);
			this.state = {
				userQueries: {},
				beautyTeam: [],
				hasDisplayedUserQueries: false,
				isLoadingUserQueries: true,
				setHasDisplayedUserQueries: this.setHasDisplayedUserQueries
			};
		}

		componentDidMount() {
			if (this.props.userId) {
				this.startRealTimeRefreshUserQueries();
			}
		}

		componentWillUnmount() {
			this.stopRealTimeRefreshUserQueries();
		}

		componentDidUpdate(prevProps) {
			if (
				prevProps.isSignedIn === undefined &&
				this.props.isSignedIn === false
			) {
				this.setState({
					isLoadingUserQueries: false
				});
			}
			if (this.props.userId && prevProps.userId !== this.props.userId) {
				this.startRealTimeRefreshUserQueries();
			}

			if (prevProps.userId && !this.props.userId) {
				this.stopRealTimeRefreshUserQueries(prevProps.userId);
				this.setState({ ...defaultState, isLoadingUserQueries: false });
			}
		}

		render() {
			return (
				<UserQueriesContext.Provider value={{ ...this.state }}>
					{this.props.children}
				</UserQueriesContext.Provider>
			);
		}
		setHasDisplayedUserQueries = () =>
			this.setState({ hasDisplayedUserQueries: true });

		startRealTimeRefreshUserQueries = () => {
			const { userId } = this.props;
			this.setState(
				{
					isLoadingUserQueries: true
				},
				() => {
					if (userId) {
						firebase
							.database()
							.ref(`user_queries/${userId}`)
							.on('value', this.onUserQueriesChange);
					}
				}
			);
		};

		fetchBusinessInfo = ({ businessId, vevents }) => {
			const client = algolia({ index: credentials.BUSINESSES_INDEX });
			return client
				.getObject(businessId)
				.then(business => {
					return {
						businessId,
						business,
						vevents
					};
				})
				.catch(e => {
					switch (e?.statusCode) {
						case 404:
							console.log(`${businessId} not found in user_queries. Ignoring`);
							break;
						default:
							console.error(e);
					}
					return null;
				});
		};

		onUserQueriesChange = async snapshot => {
			const userQueries = snapshot.val();

			if (userQueries) {
				const businesses = await Promise.all(
					Object.keys(userQueries || {}).map(businessId =>
						this.fetchBusinessInfo({
							businessId,
							vevents: Object.keys(userQueries[businessId] || {})
								.sort((x, y) => {
									const xStart = getDateFromString(
										userQueries[businessId][x].start
									);
									const yStart = getDateFromString(
										userQueries[businessId][y].start
									);
									return yStart - xStart;
								})
								.reduce((all, id) => {
									all[id] = userQueries[businessId][id];
									return all;
								}, {})
						})
					)
				);

				const filteredUserQueries = businesses.reduce((all, business) => {
					if (business?.businessId) all[business.businessId] = business;
					return all;
				}, {});
				const beautyTeam = getNewestBeautyTeam(filteredUserQueries);

				return this.setState({
					userQueries: filteredUserQueries,
					beautyTeam,
					isLoadingUserQueries: false
				});
			} else {
				this.setState({ isLoadingUserQueries: false });
			}
		};

		stopRealTimeRefreshUserQueries = (prevUserId = undefined) => {
			const { userId } = this.state;
			if (userId || prevUserId) {
				firebase
					.database()
					.ref(`user_queries/${userId || prevUserId}`)
					.off('value', this.onUserQueriesChange);
			}
		};
	}
);

export const withUserQueries = Component => props =>
	(
		<UserQueriesContext.Consumer>
			{userQueries => <Component {...props} {...userQueries} />}
		</UserQueriesContext.Consumer>
	);
