import { reduce, getStepInfo } from '@planity/helpers';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import { addMinutes, format, parseISO } from 'date-fns';

export function addToCalendarDate(date, timeZone = 'Europe/Paris') {
	return format(zonedTimeToUtc(date, timeZone), "yyyy-MM-dd'T'HH:mm:00xxx");
}

export function appointmentStartTime(appointment, business) {
	return addToCalendarDate(parseISO(appointment.start), business.timeZone);
}

export function appointmentEndTime(appointment, business, t) {
	const start = parseISO(appointment.start);
	const appointmentSequence = appointment.sequence;
	const fullDurationInMins = getFullSequenceDuration(
		appointmentSequence,
		business,
		t
	);
	return addToCalendarDate(
		addMinutes(start, fullDurationInMins),
		business.timeZone
	);
}

export function businessAddress({ address }) {
	if (address) {
		const street = address.street?.replace(',', '');
		const locality = [address.postalCode, address.locality]
			.filter(x => !!x)
			.join(' ');
		return [street, locality].filter(x => !!x).join(', ');
	} else {
		return '';
	}
}

export function getFullSequenceDuration(sequence, business, t) {
	try {
		return reduce(
			sequence,
			(currentDuration, step) => {
				const { durationInMins } = getStepInfo(step, business, t);
				return currentDuration + durationInMins;
			},
			0
		);
	} catch (e) {
		console.warn(e);
		return 0;
	}
}

export const APPOINTMENT_IS_IMPRINT = 'APPOINTMENT_IS_IMPRINT';
export const APPOINTMENT_IS_FULL_DEPOSIT = 'APPOINTMENT_IS_FULL_DEPOSIT';
export const APPOINTMENT_IS_PARTIAL_DEPOSIT = 'APPOINTMENT_IS_PARTIAL_DEPOSIT';
export const APPOINTMENT_IS_PREPAYMENT = 'APPOINTMENT_IS_PREPAYMENT';

export const getAppointmentOnlinePaymentType = ({
	chargeId,
	isDeposit,
	isPrePayment,
	...appointment
}) => {
	if (!chargeId) return APPOINTMENT_IS_IMPRINT;
	if (isDeposit === 'partial') return APPOINTMENT_IS_PARTIAL_DEPOSIT;
	if (isDeposit === 'full') return APPOINTMENT_IS_FULL_DEPOSIT;
	if (isPrePayment) return APPOINTMENT_IS_PREPAYMENT;
	console.log('UNKNOWN PAYMENT TYPE', appointment);
	return null;
};
