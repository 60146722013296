import React from 'react';

export function Check({ color }) {
	return (
		<svg
			width='32'
			height='32'
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M7.66602 17.1553L11.1193 21.8851C12.2016 23.3675 14.4232 23.3387 15.4668 21.8287L24.3327 9'
				stroke={color}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
