export function Mastercard() {
	return (
		<svg
			width='50'
			height='34'
			viewBox='0 0 50 34'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<rect
				x='0.75'
				y='0.75'
				width='48.5'
				height='32.5'
				rx='4.25'
				fill='#F7F7F7'
				stroke='#C7CFD7'
				strokeWidth='0.5'
			/>
			<path
				d='M28.9655 9.87274H21.0342V24.124H28.9655V9.87274Z'
				fill='#FF5F00'
			/>
			<path
				d='M21.5378 16.9982C21.5378 14.1027 22.8975 11.5344 24.9873 9.87261C23.4514 8.66403 21.5126 7.93384 19.3976 7.93384C14.387 7.93384 10.3333 11.9876 10.3333 16.9982C10.3333 22.0088 14.387 26.0626 19.3976 26.0626C21.5126 26.0626 23.4514 25.3324 24.9873 24.1238C22.8975 22.4872 21.5378 19.8938 21.5378 16.9982Z'
				fill='#EB001B'
			/>
			<path
				d='M39.6665 16.9982C39.6665 22.0088 35.6127 26.0626 30.6022 26.0626C28.4871 26.0626 26.5484 25.3324 25.0125 24.1238C27.1275 22.462 28.462 19.8938 28.462 16.9982C28.462 14.1027 27.1023 11.5344 25.0125 9.87261C26.5484 8.66403 28.4871 7.93384 30.6022 7.93384C35.6127 7.93384 39.6665 12.0128 39.6665 16.9982Z'
				fill='#F79E1B'
			/>
		</svg>
	);
}
