import { useFirebaseSubscription } from '@planity/datastores';
import { useAuth } from '@planity/components';

export const usePastAppointments = ({
	isAllowedToFetchData,
	pageSize,
	endAt
}) => {
	const { userId } = useAuth();

	const [pastAppointments, pastAppointmentsError, pastAppointmentsLoading] =
		useFirebaseSubscription(
			isAllowedToFetchData && {
				path: userId && `user_vevents/${userId}`,
				endAt,
				orderByChild: 'start',
				limitToLast: process.env.WIDGET ? null : pageSize
			}
		);

	if (process.env.NODE_ENV === 'development' && pastAppointmentsError) {
		console.warn({ pastAppointmentsError });
	}
	return { pastAppointments, pastAppointmentsLoading };
};
