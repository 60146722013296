import { Buffer } from 'buffer';
import { getUserId, signInWithCustomToken } from '@planity/components';
import { isNativeApp } from '@planity/webview';

export const handlePaymentRedirect = async ({
	tokenData,
	error,
	business,
	history,
	routes
}) => {
	const data = JSON.parse(Buffer.from(tokenData, 'base64').toString('utf-8'));
	const {
		appointment,
		businessId,
		isDeposit,
		isPrePayment,
		paymentIntentId,
		paymentMethod,
		veventToDelete,
		steps,
		userId: onlinePaymentUserId,
		tokenId,
		giftData,
		customToken
	} = data;
	let userId = onlinePaymentUserId;

	if (isNativeApp && customToken) {
		await signInWithCustomToken(customToken);
		userId = getUserId();
	}

	if (data && data?.transactionType === 'giftVoucher') {
		console.log('TRANSACTIONTYPE VAUT GIFTVOUCHER ET ON VA REPLACE AVEC => ', {
			giftData
		});
		return history.replace(
			routes.catchAll({
				reservationGiftVoucher: {
					business
				},
				state: {
					giftData,
					isRedirect: true,
					error,
					tokenId,
					hasTokenStatus: false
				}
			})
		);
	} else if (
		data &&
		data?.transactionType &&
		data?.transactionType !== 'giftVoucher' &&
		data?.transactionType !== 'onlineShop'
	) {
		const sequence = steps
			? JSON.parse(steps).isOnline.map(step => ({
					...step,
					serviceId: step.id
			  }))
			: null;
		return history.replace(
			routes.catchAll({
				reservation: { business },
				state: {
					error,
					sequence,
					businessId,
					paymentIntentId,
					isPrePayment,
					isDeposit,
					business,
					date: appointment && new Date(appointment.date),
					commentToBusiness: appointment && appointment.commentToBusiness,
					paymentMethod,
					userId,
					paymentError: error,
					isRedirect: true,
					veventToDelete,
					tokenId,
					hasTokenStatus: false
				}
			})
		);
	}
};
