import { Card } from '@planity/ui';
import React from 'react';
import { Alert } from '../ui';
import styles from './no_results.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';

export function NoResults({ bookable, className }) {
	const classes = classNames.bind(styles)({
		noResultsPage: true,
		noResultsPageNotBookable: !bookable
	});
	useStyles(styles);
	return (
		<div className={classes}>
			<Card hideBorders isVisibleMobile={false}>
				<Alert
					className={classNames.bind(styles)({
						noResults: true,
						[className]: className !== undefined
					})}
					localizedText={`search.no${bookable ? 'Bookable' : ''}Results`}
				/>
			</Card>
		</div>
	);
}
