import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './title.module.scss';

export function Title({ label, title, align = 'left', isInverted, type }) {
	useStyles(styles);
	const cx = classNames.bind(styles);
	const classes = cx({
		title: true,
		isCentered: align === 'center',
		isInverted
	});

	return (
		<div className={classes}>
			<span className={styles.label}>{label}</span>
			<hr className={styles.hr} />
			<h2
				className={cx({
					[type]: true,
					title: true
				})}
			>
				{title}
			</h2>
		</div>
	);
}
