import { FacebookIconRound, GithubIcon, GoogleIcon, Localize } from '../index';
import React from 'react';
import { breakpoints, colors, fontSize, fontWeight } from '@planity/theme';
import { SocialAccountLink } from './social_account_link';
import { firebase } from '@planity/datastores';
import credentials from '@planity/credentials';

export const SocialAccountsLink = ({ providers, setProviders }) => {
	const { ENABLE_FACEBOOK_LOGIN, ENABLE_GOOGLE_LOGIN, ENABLE_GITHUB_LOGIN } =
		credentials;

	if (!ENABLE_GOOGLE_LOGIN && !ENABLE_FACEBOOK_LOGIN && !ENABLE_GITHUB_LOGIN) {
		return null;
	}
	return (
		<div>
			<div css={styles.title}>
				<Localize text={`myAccount.myProfile.title.SOCIAL_ACCOUNTS`} />
			</div>
			<div css={styles.content}>
				{ENABLE_FACEBOOK_LOGIN && (
					<div css={styles.block}>
						<SocialAccountLink
							authProvider={new firebase.auth.FacebookAuthProvider()}
							providerIcon={<FacebookIconRound color={'#1877f2'} />}
							providerId={'facebook.com'}
							providerName={'Facebook'}
							providers={providers}
							setProviders={setProviders}
						/>
					</div>
				)}
				{ENABLE_GOOGLE_LOGIN && (
					<div css={styles.block}>
						<SocialAccountLink
							authProvider={new firebase.auth.GoogleAuthProvider()}
							providerIcon={<GoogleIcon />}
							providerId={'google.com'}
							providerName={'Google'}
							providers={providers}
							setProviders={setProviders}
						/>
					</div>
				)}
				{ENABLE_GITHUB_LOGIN && (
					<div css={styles.block}>
						<SocialAccountLink
							authProvider={new firebase.auth.GithubAuthProvider()}
							providerIcon={<GithubIcon />}
							providerId={'github.com'}
							providerName={'Github'}
							providers={providers}
						/>
					</div>
				)}
				<div css={[styles.block, styles.lastBlock]}>
					<Localize text={'myAccount.myProfile.socialMediaWarningText'} />
				</div>
			</div>
		</div>
	);
};

const styles = {
	title: {
		fontSize: fontSize.extraRegular,
		fontWeight: fontWeight.bold,
		marginBottom: 11,
		marginLeft: 15,
		[breakpoints.tabletQuery]: {
			marginBottom: 5,
			marginLeft: 0
		}
	},
	content: {
		backgroundColor: colors.white,
		[breakpoints.tabletQuery]: {
			borderRadius: 4,
			boxShadow: '0 1px 4px 1px rgba(0, 0, 0, 0.08)'
		}
	},
	block: {
		fontSize: fontSize.regular,
		color: colors.grey.brownGrey,
		padding: '18px 29px',
		borderBottom: '1px solid #e5e5e5'
	},
	lastBlock: {
		borderBottom: 'none'
	}
};
