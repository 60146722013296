import React from 'react';
import { useHistory } from 'react-router-dom';
import { Layout } from '../layout';
import { Button } from '@planity/ui';
import { Localize } from '@planity/components';
import styles from './unmovable_appointment_page.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';

import {
	localizedGlobalRoutes,
	useLocalization,
	useTranslation
} from '@planity/localization';
import { breakpoints, WithScreenDimensions } from '@planity/theme';

export const UnmovableAppointmentPage = () => {
	const history = useHistory();
	const { locale } = useLocalization();
	const localizedAccount = localizedGlobalRoutes(locale);
	const { t } = useTranslation();

	useStyles(styles);
	const classes = classNames.bind(styles)({
		unmovableAppointmentPage: true
	});

	const { phoneNumber, delayExpired } = history?.location?.state || {};

	const getMessage = () => {
		const messagePrefix = delayExpired
			? 'waitingList.cantMoveAppointmentPage'
			: 'waitingList.cantMoveAppointmentPage2';
		const messageSuffix = phoneNumber
			? '.messageWithPhoneNumber'
			: '.messageWithoutPhoneNumber';
		return `${messagePrefix}${messageSuffix}`;
	};

	return (
		<Layout hasFullWidth hideBreadcrumbs isDark withHeaderMarginTop>
			<div className={classes}>
				<div className={styles.ctn}>
					<div className={styles.containerInfo} id={'containerINfo'}>
						<p className={styles.title}>
							<Localize text={'waitingList.cantMoveAppointmentPage.title'} />
						</p>
						<p className={styles.text}>
							<Localize
								args={{ businessNumber: phoneNumber }}
								text={getMessage()}
							/>
						</p>
						<WithScreenDimensions>
							{({ windowWidth }) => (
								<Button
									isFullWidth={true}
									isLoading={!localizedAccount}
									label={t('waitingList.cantMoveAppointmentPage.button')}
									size={
										windowWidth < breakpoints.homeTablet ? 'mobile' : 'large'
									}
									to={localizedAccount.myAccountPastAppointments}
								/>
							)}
						</WithScreenDimensions>
					</div>
				</div>
			</div>
		</Layout>
	);
};
