import { useLocalizedRoutes, useTranslation } from '@planity/localization';
import { useClickAndCollectContext } from './clickAndCollectContext';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { Button, Container } from '@planity/ui';
import {
	AppointmentUser,
	BookAppointmentTitle,
	ErrorMessage,
	useErrorMessages,
	FirebaseSubscription,
	Localize,
	OnlinePaymentConsumer,
	OnlinePaymentProvider,
	WaitingPage,
	useAuth
} from '@planity/components';
import { Address } from './address';
import {
	AddressPage,
	EshopConfirmation,
	MainInfo
} from 'planity-website/app/components';
import { useActor } from '@xstate/react';
import { CLICK_AND_COLLECT_STEPS } from './machines/CLICK_AND_COLLECT_STEPS';
import { CAC_FLOW_MACHINE_STATES } from './machines/cacFlowMachineStates';
import { AppointmentPayment } from '../business_booking/online_payment';
import {
	centsToPrice,
	scrollToNode,
	computeFees,
	usePrevious,
	getFinalFees
} from '@planity/helpers';
import { ProductsList } from './products_list';
import { CLICK_AND_COLLECT_MACHINE_STATES } from './machines/clickAndCollectMachineStates';
import credentials from '@planity/credentials';
import { ProductArticleMinimized } from './product_article';
import styles from './click_and_collect_flow.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import { Cart } from './cart';
import { useStripeFees } from '../app_settings/stripeFeesProvider';
import { Buffer } from 'buffer';
import { ONLINE_SHOP_BLOC, useTheme } from '@planity/context/theme_context';
import { ThemeContext } from '@planity/context/theme_context';

const DEFAULT_ERROR = 'clickAndCollect.errors.buyingFailed';

export const ClickAndCollectFlow = ({
	business,
	isCACShopping,
	isClickAndCollect,
	isClickAndShop,
	onSuccess,
	shippingExplanationSentence
}) => {
	const { isDark: hasDarkBackground } = useTheme();
	const isDark = hasDarkBackground?.[ONLINE_SHOP_BLOC];

	const [displayConfirmation, setDisplayConfirmation] = useState(false);
	if (displayConfirmation)
		return <EshopConfirmation isDark={isDark} onClick={onSuccess} />;

	return (
		<FirebaseSubscription
			path={`businesses/${business.objectID}`}
			shard={'public'}
		>
			{({ data: firebaseBusiness }) => (
				<OnlinePaymentProvider>
					<CACFlowComponent
						business={business}
						businessSettings={firebaseBusiness && firebaseBusiness.settings}
						isCACShopping={isCACShopping}
						isClickAndCollect={isClickAndCollect}
						isClickAndShop={isClickAndShop}
						isDark={isDark}
						shippingExplanationSentence={shippingExplanationSentence}
						onSuccess={() => {
							setDisplayConfirmation(true);
						}}
					/>
				</OnlinePaymentProvider>
			)}
		</FirebaseSubscription>
	);
};

const CACFlowComponent = OnlinePaymentConsumer(function ({
	business,
	businessSettings,
	isCACShopping,
	isClickAndCollect = false,
	isClickAndShop = false,
	getCustomerStripe,
	selectedPaymentMethod,
	customerID,
	resetPaymentMethodDatas,
	onSuccess,
	shippingExplanationSentence,
	resetPaymentIntent,
	clientSecret,
	intentID,
	updatePaymentIntent,
	isPaymentIntentUpdating,
	isDark,
	paymentMethodType,
	...props
}) {
	const { singlePageApp } = useContext(ThemeContext);
	const location = useLocation();
	const prevLocationState = usePrevious(location?.state);
	const history = useHistory();
	const { userId, user, isPro } = useAuth();
	const { state: cacMachineState, functions: cacMachineFunctions } =
		useClickAndCollectContext();
	const { routes } = useLocalizedRoutes();
	const businessId = business.objectID;
	const { t } = useTranslation();
	const { allStripeFees } = useStripeFees();
	const { pushMessage } = useErrorMessages();

	// Redirect to business page
	// if the user tries to navigate to /${business.slug}/boutique_en_ligne directly
	// this is useful only for localhost
	// on planitylab/planitytest/planity the cloud front function handlePaymentRedirection handles it
	if (!process.env.BROWSER && isCACShopping) {
		const slug = location.pathname.replace(routes.onlineShopSlug, '');
		// redirect when no token was passed as query param
		if (process.env.NODE_ENV === 'development' && !location?.search?.length) {
			return <Redirect to={slug} />;
		}
	}

	// handle redirection when a token or an error is passed as query param
	if (process.env.BROWSER && location?.search?.length > 0) {
		handleRedirect({ business, history, location, routes });
	}
	const [hasTokenStatus, setHasTokenStatus] = useState(null);
	const [error, setError] = useState(null);
	const [stateProgression, setStateProgression] = useState([]);
	const [isAddingProduct, setIsAddingProduct] = useState(false);
	const [state, send] = useActor(
		cacMachineState.children.cacFlow || {
			id: 'dummy',
			send: () => null,
			getSnapshot: () => null,
			subscribe: () => ({ unsubscribe: () => null }),
			stop: () => null
		}
	);
	const [isLoading, setIsLoading] = useState(
		isPaymentIntentUpdating || state?.matches(CAC_FLOW_MACHINE_STATES.PAYING)
	);
	const totalPrice = state?.context.data.totalPrice || 0;
	const products = state?.context.data.products || [];

	// if the cart is empty, do not display the close button and the total price
	const cartIsEmpty = state ? products.length === 0 : true;

	//shipping fees in cents
	const shippingFees =
		location?.state?.shippingFees || isClickAndShop
			? businessSettings?.clickAndShop?.deliveryFees
			: 0;
	const handleSubmit = () => {
		setIsAddingProduct(true);
	};
	useStyles(styles);

	const classes = classNames.bind(styles)({
		clickAndCollect: true,
		isAddingProduct,
		isDark
	});
	useEffect(() => {
		cacMachineFunctions.updateIsClickAndShop(send, isClickAndShop);
		return () => {
			if (!state?.matches(CLICK_AND_COLLECT_MACHINE_STATES.COMPLETED)) {
				cacMachineFunctions.cancelFlow(send);
			}
		};
	}, []);

	useEffect(() => {
		//on redirect, we first go on this page with query string so location state is undefined
		//then we get the data from the query string and set the location state
		//so at this exact moment we want to set the error from redirect
		if (
			prevLocationState === undefined &&
			location?.state?.error &&
			location?.state?.isRedirect &&
			!singlePageApp // without this, there is a double error message in SPA (one on each step)
		) {
			handleError(location?.state?.error);
		}
	}, [location?.state?.error]);

	useEffect(() => {
		setIsLoading(
			isPaymentIntentUpdating || state?.matches(CAC_FLOW_MACHINE_STATES.PAYING)
		);
	}, [isPaymentIntentUpdating, state?.matches(CAC_FLOW_MACHINE_STATES.PAYING)]);

	useEffect(() => {
		if (
			intentID &&
			!state?.matches(`${CAC_FLOW_MACHINE_STATES.CAN_PAY}.error`) &&
			totalPrice > 0
		) {
			const amount = totalPrice;
			const stripeFees = getFinalFees(
				allStripeFees,
				business?.countryCode,
				paymentMethodType
			);
			const application_fee_amount = computeFees({ amount, stripeFees });

			updatePaymentIntent({
				intentID,
				amount,
				application_fee_amount,
				metadata: { amountWithoutFees: amount },
				countryCode: business?.countryCode,
				businessId
			});
		}
	}, [totalPrice, paymentMethodType]);

	useEffect(() => {
		if (shippingFees) {
			cacMachineFunctions.updateShippingFees(send, shippingFees);
		}
	}, [shippingFees]);

	// run everytime the machine state changes
	useEffect(() => {
		if (state) {
			// used to know what to display
			const stateName =
				typeof state?.value === 'string'
					? state?.value
					: Object.keys(state?.value)[0];
			const currentStateIndex = CLICK_AND_COLLECT_STEPS.findIndex(
				state => state === stateName
			);
			const progression = CLICK_AND_COLLECT_STEPS.slice(
				0,
				currentStateIndex + 1
			);

			setStateProgression(progression);

			if (
				!!state?.context?.data?.isRedirect &&
				state?.matches(CAC_FLOW_MACHINE_STATES.DELIVERY_FORM) &&
				!!state?.context?.data?.billingAddress
			) {
				customerAddressesValidate(
					send,
					state?.context.data.deliveryAddress,
					state?.context.data.billingAddress
				);
			} else if (
				state?.matches(CAC_FLOW_MACHINE_STATES.CAN_PAY) &&
				typeof state?.context?.data?.isRedirect === 'boolean' &&
				!state.context.data.isRedirect &&
				!!state?.context.data.paymentIntentId &&
				!!state?.context.data.paymentMethod &&
				!state?.context.data.error
			) {
				cacMachineFunctions.pay(send, user);
			} else if (
				state?.matches(CAC_FLOW_MACHINE_STATES.CREDIT_CARD) &&
				!!state?.context.data.paymentMethod
			) {
				// A credit card is selected
				// Save it in the machine
				cacMachineFunctions.validateCreditCard(send);
			} else if (state?.matches(CAC_FLOW_MACHINE_STATES.CART)) {
				// There is no products in the cart
				// Must show the products list
				setIsAddingProduct(true);
			} else if (state?.matches(CAC_FLOW_MACHINE_STATES.CONFIRMED)) {
				// The user bought his products
				// Let's show them to him in the orders page
				onSuccess();
			} else if (
				state?.matches(`${CAC_FLOW_MACHINE_STATES.CAN_PAY}.error`) &&
				!error
			) {
				//* if the stock got emptied just before final step of paying with a CB
				// The selected product is out of stock
				// error generated from buyOrderClickAndCollect lambda by verifying current stock in realtime
				if (state?.event?.data === 'TRANSACTION_ABORT') {
					setHasTokenStatus(true);
					pushMessage({
						message: t('clickAndCollect.errors.noMoreStock'),
						type: 'error',
						createdAt: Date.now()
					});
					//TODO EMPTY the cart
				} else {
					handleError(DEFAULT_ERROR);
				}
			}
		}
	}, [state]);

	const paymentContainerRef = useCallback(node => {
		if (node) scrollToNode(node, { animated: true, animationDuration: 500 });
	}, []);

	const userContainerRef = useCallback(node => {
		if (node) scrollToNode(node, { animated: true, animationDuration: 500 });
	}, []);

	useEffect(() => {
		if (userId && user) {
			// The user is connected
			// Let's find out if he already registered some credit cards 💳
			getCustomerStripe({
				userId,
				user,
				paymentMethod: state?.context.data.paymentMethod,
				countryCode: business.countryCode
			});
		}
		if (customerID && !userId) {
			// Oh no, the user disconnected

			// to remove payment infos from cac machine
			resetPaymentData();

			// Remove the selected payment method (credit card)
			// if there's any
			resetPaymentMethodDatas();
		}
	}, [userId, user]);

	useEffect(() => {
		// The selected credit card changed
		// We better update the paymentMethod in the machine
		cacMachineFunctions.updatePaymentMethod(
			send,
			selectedPaymentMethod || state?.context.data.paymentMethod
		);
		if (state?.matches(`${CAC_FLOW_MACHINE_STATES.CAN_PAY}.error`)) {
			cacMachineFunctions.resetPayError(send);
		}
	}, [selectedPaymentMethod]);

	useEffect(() => {
		return () => {
			// This creates a crash when going previous and then next.
			// But it also cleans the cart. We decided to let the bug live, as
			// no one uses click and collect, and we do not have time to fix it properly
			// Reverts e540b5a453beae6adee3ac6f61215d323ea4b0fa
			cacMachineFunctions.cancelFlow(send);
			cacMachineFunctions.updateIsClickAndShop(send, isClickAndShop);
		};
	}, []);
	const onUpdateProductQty = (product, quantity) => {
		setError(null);
		cacMachineFunctions.updateProductInCart(send, state, product, quantity);
		setIsAddingProduct(false);
	};

	const onAddProduct = (productId, product) => {
		setError(null);
		cacMachineFunctions.addProductToCart(send, state, productId, product);
		setIsAddingProduct(false);
	};

	const onQuantityError = () => {
		//* if customer try to add more products than the real stock - not related to payment
		handleError('clickAndCollect.errors.noMoreStock');
	};

	const customerAddressesValidate = (send, deliveryAddress, billingAddress) => {
		cacMachineFunctions.saveAddresses(send, deliveryAddress, billingAddress);
	};

	const personalInfosRef = useCallback(
		node => {
			if (state?.matches(CAC_FLOW_MACHINE_STATES.DELIVERY_FORM) && node) {
				//scrollToNode(node, { animated: true, animationDuration: 500 });
			}
		},
		[CAC_FLOW_MACHINE_STATES.DELIVERY_FORM]
	);

	const addressActions = () => (
		<Button
			label={<Localize text='eshop.updateAddress' />}
			type={'underlined'}
			onClick={() => send('UPDATE_ADDRESS')}
		/>
	);

	const deliveryAddressIsAlsoBillingAddress =
		state?.context?.data?.deliveryAddress?.address1 ===
			state?.context?.data?.billingAddress?.address1 &&
		state?.context?.data?.deliveryAddress?.zip ===
			state?.context?.data?.billingAddress?.zip;

	const handleError = error => {
		resetPaymentData();

		// error is handled beforehand in a useEffect that triggers when there is a location state error (only in the specific case of a normal failed payment).
		// We also need to reset the pay error immediatly to avoid the user clicking on pay within the 3sec frame.
		if (
			error === DEFAULT_ERROR &&
			state?.matches(`${CAC_FLOW_MACHINE_STATES.CAN_PAY}.error`) &&
			(state?.event?.type === 'error.platform.buyCACProducts' ||
				state?.event?.type === 'UPDATE_PAYMENT_DATA')
		) {
			cacMachineFunctions.resetPayError(send);
			setError(null);
		} else {
			setError(error);

			setTimeout(() => {
				if (state?.matches(`${CAC_FLOW_MACHINE_STATES.CAN_PAY}.error`)) {
					cacMachineFunctions.resetPayError(send);
				}
				setError(null);
			}, 3000);
		}
	};

	const resetPaymentData = () => {
		if (!state?.matches(CAC_FLOW_MACHINE_STATES.CONFIRMED) && !!clientSecret) {
			cacMachineFunctions.updatePaymentData(send, {
				paymentMethod: null,
				paymentIntentId: null
			});
			resetPaymentIntent();
		}
	};

	const updateContextPaymentData = ({
		newPaymentMethodFromElement,
		paymentIntentId
	}) => {
		const paymentMethod = newPaymentMethodFromElement || selectedPaymentMethod;
		cacMachineFunctions.updatePaymentData(send, {
			paymentMethod,
			paymentIntentId
		});
	};

	// in payment redirect case we wait for token node update (this update means lambda process did end)
	// while we wait we display a waiting page
	// once we have the node upate we can continue on orders screen or on error page
	if (
		!!location?.state &&
		!!location?.state.isRedirect &&
		!location?.state?.error &&
		!hasTokenStatus &&
		(!singlePageApp || location?.state?.isFrom === ONLINE_SHOP_BLOC)
	) {
		return (
			<FirebaseSubscription
				path={`${isPro ? 'pros' : 'users'}/${userId}/redirect_tokens/${
					location.state.tokenId
				}/response`}
			>
				{({ data: redirectLambdaResponse, isLoading }) => {
					const { status, data } = redirectLambdaResponse || {};
					if (status === 'succeed') {
						if (onSuccess) {
							setHasTokenStatus(true);
							cacMachineFunctions.confirmPaymentRedirectSuccess(
								send,
								state?.context.data.paymentIntentId
							);
							onSuccess();
						}
					} else if (status === 'failed' && data) {
						// The selected product is out of stock
						// error generated from buyOrderClickAndCollect lambda by verifying current stock in realtime
						if (data.error === 'TRANSACTION_ABORT') {
							//* if the stock got emptied just before final step of paying with an exotic payment (bancontact, giro, etc...)
							setHasTokenStatus(true);
							pushMessage({
								message: t('clickAndCollect.errors.noMoreStock'),
								type: 'error',
								createdAt: Date.now()
							});
							//TODO EMPTY the cart with the machine state
						} else {
							const error =
								data.error?.code || data.error ? DEFAULT_ERROR : null;
							setHasTokenStatus(true);
							setError(error);
							resetPaymentData();
							send('RESET_PAYMENT_ERROR');
						}
					}
					return <WaitingPage />;
				}}
			</FirebaseSubscription>
		);
	}

	return (
		<div className={classes}>
			{error && (
				<ErrorMessage
					error={t([`${error}`, `stripe.errors.${error}`, DEFAULT_ERROR])}
					handleRemoveNotification={false}
				/>
			)}
			<Container noStupidMarginBottom size='medium'>
				<div className={styles.subContainer}>
					{!process.env.WIDGET && !process.env.WHITE_LABEL_WEBSITE && (
						<MainInfo
							alwaysVisible
							business={business}
							hasStartedBooking
							showRating
						/>
					)}
					<div>
						{!cartIsEmpty && (
							<BookAppointmentTitle index={'1.'} isDark={isDark}>
								<Localize text={'clickAndCollect.myCart'} />
							</BookAppointmentTitle>
						)}

						<div className={styles.cartContainer}>
							<Cart
								articles={products}
								explanationSentence={
									shippingExplanationSentence ||
									location?.state?.shippingExplanationSentence ||
									''
								}
								isClickAndShop={isClickAndShop}
								itemComponent={({ item, id, index }) => (
									<ProductArticleMinimized
										id={id}
										index={index}
										isPending={isLoading}
										product={item}
										updateArticleQuantity={onUpdateProductQty}
										onQuantityError={onQuantityError}
									/>
								)}
								shippingFees={shippingFees}
							/>
						</div>
						<div className={styles.priceAndProductsListContainer}>
							<div className={styles.totalPrice}>
								<span>
									{!cartIsEmpty && (
										<Localize
											args={{ price: centsToPrice(totalPrice) }}
											text={'clickAndCollect.totalPrice'}
										/>
									)}
								</span>
							</div>
							{products?.length > 0 && !isAddingProduct ? (
								<Button
									className={'planity_ui_button_root planity-component-button'}
									iconLeft='Add'
									iconRight={null}
									isLoading={isLoading}
									label={t('clickAndCollect.addProduct')}
									size='medium'
									type='primary'
									onClick={handleSubmit}
								/>
							) : (
								<div>
									<div className={styles.productsHeader}>
										<span className={styles.articleToAdd}>
											<Localize text='clickAndCollect.chooseAnArticle' />
										</span>
										{!cartIsEmpty && (
											<Button
												className={
													'planity_ui_action_button_root planity_ui_action_button_icon-remove'
												}
												label={<Localize text='clickAndCollect.close' />}
												type={'underlined'}
												onClick={() => setIsAddingProduct(false)}
											/>
										)}
									</div>
									<ProductsList
										business={business}
										businessId={businessId}
										cart={products}
										isClickAndCollect={isClickAndCollect}
										isClickAndShop={isClickAndShop}
										isDark={isDark}
										isPending={isLoading}
										needToFetchProducts
										onAddProduct={onAddProduct}
									/>
								</div>
							)}
						</div>
					</div>
					{stateProgression.includes(
						CAC_FLOW_MACHINE_STATES.AUTHENTICATION
					) && (
						<AppointmentUser
							appointment={null}
							business={business}
							className={styles.appointmentUserContainer}
							conditionsLink={`https://www.${credentials.HOST + routes.legal}`}
							isDark={isDark}
							isPending={isLoading}
							selectedUser={user}
							setHasConfirmableUser={hasConfirmableUser => {
								if (
									state?.matches(CAC_FLOW_MACHINE_STATES.AUTHENTICATION) &&
									!!hasConfirmableUser &&
									!!userId
								) {
									cacMachineFunctions.authenticated(
										send,
										userId,
										isClickAndShop
									);
								}
							}}
							title={{
								title: 'clickAndCollect.authentication',
								index: '2.'
							}}
							userContainerRef={userContainerRef}
							userId={userId}
							onSignOut={() => cacMachineFunctions.logout(send)}
							onSignUp={() => {
								if (userId) {
									cacMachineFunctions.authenticated(
										send,
										userId,
										isClickAndShop
									);
								}
							}}
						/>
					)}
					{stateProgression.includes(CAC_FLOW_MACHINE_STATES.DELIVERY_FORM) &&
						isClickAndShop && (
							<div>
								<BookAppointmentTitle index={'3.'} isDark={isDark}>
									<Localize text={'eshop.customerInfos'} />
								</BookAppointmentTitle>

								{state?.matches(CAC_FLOW_MACHINE_STATES.DELIVERY_FORM) ? (
									<AddressPage
										deliveryAddress={
											state?.context?.data?.deliveryAddress || {}
										}
										displaySubmit={state?.matches(
											CAC_FLOW_MACHINE_STATES.DELIVERY_FORM
										)}
										invoiceAddress={state?.context?.data?.billingAddress || {}}
										personalInfosRef={personalInfosRef}
										setShopifyCustomerId={() => {}}
										userFirstName={user?.firstName || ''}
										userId={userId}
										userLastName={user?.lastName || ''}
										onValidate={({ deliveryAddress, invoiceAddress }) => {
											customerAddressesValidate(
												send,
												deliveryAddress,
												invoiceAddress
											);
										}}
									/>
								) : (
									<div className={styles.addressesContainer}>
										<div>
											<Address
												actions={addressActions}
												address={state?.context.data.deliveryAddress || {}}
											/>
										</div>
										{!deliveryAddressIsAlsoBillingAddress && (
											<div>
												<Address
													actions={addressActions}
													address={state?.context.data.billingAddress || {}}
												/>
											</div>
										)}
									</div>
								)}
							</div>
						)}
					{
						<div className={styles.appointmentPaymentContainer}>
							{stateProgression.includes(
								CAC_FLOW_MACHINE_STATES.CREDIT_CARD
							) && (
								<AppointmentPayment
									{...props}
									business={business}
									businessId={businessId}
									cartIsEmpty={cartIsEmpty}
									clientSecret={clientSecret}
									contextData={state?.context.data}
									customerID={customerID}
									index={`${isClickAndShop ? 4 : 3}.`}
									intentID={intentID}
									isCanPayMachineStep={stateProgression.includes(
										CAC_FLOW_MACHINE_STATES.CAN_PAY
									)}
									isClickAndShop={isClickAndShop}
									isEshop
									isLoading={isLoading || !clientSecret}
									isPro={isPro}
									isWhiteLabelWebsite={process.env.WHITE_LABEL_WEBSITE}
									isWidget={process.env.WIDGET}
									onlinePaymentServices={{
										isOnline: []
									}}
									paymentContainerRef={paymentContainerRef}
									paymentMethodType={paymentMethodType}
									products={products}
									resetPaymentMethodDatas={resetPaymentMethodDatas}
									selectedPaymentMethod={selectedPaymentMethod}
									setError={setError}
									setIsLoading={newIsLoading => setIsLoading(newIsLoading)}
									setIsRedirect={isRedirectValue =>
										cacMachineFunctions.setIsRedirect(send, isRedirectValue)
									}
									shippingExplanationSentence={shippingExplanationSentence}
									shippingFees={shippingFees}
									title={'clickAndCollect.casPaymentMethod'}
									totalPrice={totalPrice}
									user={user}
									userId={userId}
									onSubmit={updateContextPaymentData}
								/>
							)}
						</div>
					}
				</div>
			</Container>
		</div>
	);
});

const handleRedirect = ({ business, history, location, routes }) => {
	const searchParams = new URLSearchParams(location?.search);
	const error = searchParams.get('error');
	const tokenData = searchParams.get('tokenData');
	const data = tokenData
		? JSON.parse(Buffer.from(tokenData, 'base64').toString('utf-8'))
		: {};
	if (tokenData && Object.keys(data || {}).length > 0) {
		const {
			tokenId,
			onlineShopData,
			shippingFees,
			shippingExplanationSentence,
			transactionType
		} = data;
		if (transactionType === 'onlineShop' && onlineShopData) {
			history.replace(
				routes.catchAll({
					cacBusiness: business,
					state: {
						business,
						onlineShopData,
						isClickAndCollect:
							onlineShopData && onlineShopData.isClickAndCollect,
						isClickAndShop: onlineShopData && onlineShopData.isClickAndShop,
						shippingFees,
						shippingExplanationSentence,
						error: error ? `clickAndCollect.errors.${error}` : null,
						isRedirect: true,
						tokenId,
						hasTokenStatus: false
					}
				})
			);
		}
	}
	if (Object.keys(data || {}).length === 0 && error) {
		history.replace(
			routes.catchAll({
				business,
				state: {
					error: DEFAULT_ERROR
				}
			})
		);
	}
};
