import { BusinessCategory } from '@planity/ui';
import styles from './business_categories.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';

export function BusinessCategories({ categories, className }) {
	const getLastIndex = (length, divisor) => length - (length % divisor);

	const classes = classNames.bind(styles)({
		businessCategories: true,
		[className]: className
	});
	useStyles(styles);

	return (
		<section className={classes}>
			<nav className={styles.content}>
				<div className={styles.placesWrapper}>
					{(categories || []).map((category, i) => (
						<BusinessCategory
							category={category}
							dataHide1={i >= getLastIndex(categories.length, 1)}
							dataHide2={i >= getLastIndex(categories.length, 2)}
							dataHide3={i >= getLastIndex(categories.length, 3)}
							index={i}
							key={i}
						/>
					))}
				</div>
			</nav>
		</section>
	);
}
