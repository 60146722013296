import { useFormHooks } from '@planity/components';
import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './authentication.module.scss';
import { Image } from '@planity/ui';
import {
	ForgetPassword,
	SignUp,
	Login,
	FinishSignUp
} from '@planity/ui/components';
import { MY_ACCOUNT_BLOC, useTheme } from '@planity/context/theme_context';

export function Authentication({
	logState,
	navigateLogState,
	data,
	localizedText,
	pending,
	phoneInput
}) {
	const { handleDispatchEvent, handleDispatchInputs, formValues } =
		useFormHooks();
	const { authBooking, imageUrl } = formValues;
	const { isDark: hasDarkBackground } = useTheme();
	const isDark = hasDarkBackground?.[MY_ACCOUNT_BLOC];
	useStyles(styles);
	const classes = classNames.bind(styles)({
		auth: true,
		connection: authBooking,
		widget: process.env.WIDGET,
		isDark
	});
	return (
		<div
			id='auth-comp'
			className={`${classes} ${
				logState !== 'finishsignup' && styles.connection__wrapper
			} ${authBooking && styles.authBooking}`}
		>
			<div className={styles.columns}>
				<div className={styles.left}>
					<div className={styles.container}>
						{logState === 'login' && (
							<Login
								navigateLogState={navigateLogState}
								handleDispatchInputs={handleDispatchInputs}
								handleDispatchEvent={handleDispatchEvent}
								data={data}
								localizedText={localizedText}
								pending={pending}
								isDark={isDark}
							/>
						)}

						{logState === 'signup' && (
							<SignUp
								navigateLogState={navigateLogState}
								handleDispatchInputs={handleDispatchInputs}
								handleDispatchEvent={handleDispatchEvent}
								data={data}
								localizedText={localizedText}
								pending={pending}
								phoneInput={phoneInput}
								isDark={isDark}
							/>
						)}

						{logState === 'reset' && (
							<ForgetPassword
								navigateLogState={navigateLogState}
								handleDispatchInputs={handleDispatchInputs}
								handleDispatchEvent={handleDispatchEvent}
								data={data}
								localizedText={localizedText}
								pending={pending}
								phoneInput={phoneInput}
								isDark={isDark}
							/>
						)}
						{logState === 'finishsignup' && (
							<FinishSignUp
								navigateLogState={navigateLogState}
								handleDispatchInputs={handleDispatchInputs}
								handleDispatchEvent={handleDispatchEvent}
								data={data}
								localizedText={localizedText}
								pending={pending}
								phoneInput={phoneInput}
								isDark={isDark}
							/>
						)}
					</div>
				</div>
				{!authBooking && !process.env.WIDGET && (
					<div className={styles.image}>
						<Image fit='cover' url={imageUrl} isCloudinary />
					</div>
				)}
			</div>
		</div>
	);
}
