import classNames from 'classnames/bind';
import styles from './business_products.module.scss';
import { BusinessProduct, Button } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useRef, useState } from 'react';
import { breakpoints } from '@planity/theme';
import { useTranslation } from '@planity/localization';
import { sanitizeName } from '@planity/helpers';

const COLLAPSED_CHILDREN_SIZE = 5; // Default max size of collapsed list

export function BusinessProducts({
	title = '',
	items = [{}, {}, {}],
	onAddProduct,
	cart,
	description,
	productSetSize,
	productSetIndex,
	isDark
}) {
	useStyles(styles);
	const { t } = useTranslation();
	const content = useRef();
	const [limit, setLimit] = useState(COLLAPSED_CHILDREN_SIZE);
	const [isLimited, setIsLimited] = useState(true);
	const [label, setLabel] = useState('');

	const classes = classNames.bind(styles)({
		businessProducts: true,
		isDark
	});

	useEffect(() => {
		if (!limit) {
			setLabel(t(`clickAndCollect.showLessProducts`));
		} else {
			setLabel(
				t('clickAndCollect.showMoreProducts', {
					size: productSetSize - limit
				})
			);
		}
	}, [limit, productSetSize, t]);

	useEffect(() => {
		const handle = () => setIsLimited(window.innerWidth >= breakpoints.tablet);
		handle();
		window.addEventListener('resize', handle);
		return () => window.removeEventListener('resize', handle);
	});

	return (
		<div className={classes}>
			<div>
				<span className={styles.title}>{title}</span>
			</div>
			<div
				className={`${styles.card} planity_appointment_step_wrapper`}
				ref={content}
			>
				{description && (
					<div className={styles.mobileDescription}>
						<span>{sanitizeName(description)}</span>
					</div>
				)}
				<div className={styles.items}>
					{Object.entries(items || {}).map(([id, props], i) => {
						const isVisible = isLimited && limit ? i < limit : true;
						return (
							isVisible && (
								<BusinessProduct
									cart={cart}
									id={id}
									key={id}
									{...props}
									index={i}
									onAddProduct={() => onAddProduct(id, props)}
									productSetIndex={productSetIndex}
								/>
							)
						);
					})}
					{isLimited && productSetSize > limit && (
						<Button
							className={styles.showMore}
							label={label}
							type='underlined'
							onClick={() => setLimit(limit ? false : COLLAPSED_CHILDREN_SIZE)}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
