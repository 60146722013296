import { GiftVouchersEvents } from '@planity/helpers/analytics';
import React from 'react';
import { OnlinePaymentProvider } from '../business_booking/online_payment/providerComponent.js';
import { GiftVoucherFlow } from './gift_voucher_flow';
import { WithAuth } from '@planity/components';

export function BookGiftVoucher(props) {
	const { onSuccess, business } = props;
	return (
		<WithAuth skipIsAdmin={true}>
			{({ userId, isVerified, user, isPro }) => (
				<OnlinePaymentProvider business={business}>
					<GiftVouchersEvents>
						<GiftVoucherFlow
							{...props}
							isPro={isPro}
							isVerified={isVerified}
							user={user}
							userId={userId}
							onSuccess={onSuccess}
						/>
					</GiftVouchersEvents>
				</OnlinePaymentProvider>
			)}
		</WithAuth>
	);
}
