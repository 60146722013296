import { withLocalization, withTranslation } from '@planity/localization';
import React, { Component } from 'react';
import { firebase } from '@planity/datastores';
import {
	FirebaseSubscription,
	Localize,
	withStripeElementsConsumer,
	StripePayment
} from '@planity/components';
import { compareAsc, endOfMonth, parseISO } from 'date-fns';
import { AccountAppointment, Button, Modal } from '@planity/ui';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames/bind';
import styles from './modal_add_card.module.scss';

class ModalAddCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			user: null,
			customerID: null,
			displayAddCard: false,
			cardIsAdding: false,
			getNewIntentId: true,
			paymentMethod: [],
			selectedPaymentMethod: null
		};
	}

	componentDidMount() {
		this.isDisplayCard();
		this.props.setSelectedPaymentMethod(null);
	}

	render() {
		const {
			appointments,
			selectedPaymentMethod,
			t,
			closeModal,
			cardAdded,
			setSelectedPaymentMethod,
			countryCode,
			isAccount
		} = this.props;

		const classes = classNames.bind(styles)({
			addCard: true
		});

		return (
			<Modal
				closeModal={closeModal}
				hasCloseBtn
				isFullHeight
				isOpen
				order={1}
				preventScrollTrap
			>
				<div className={classes}>
					<div className={styles.title}>
						<p>{t('myAccount.onlinePayment.usedCard')}</p>
						<p className={styles.description}>
							<Localize text='myAccount.onlinePayment.pickCard' />
						</p>
					</div>

					<div>
						<p className={styles.subTitle}>
							<Localize text='myAccount.onlinePayment.concerned' />
						</p>
						{Object.keys(appointments).map(appointmentId => {
							return (
								<FirebaseSubscription
									key={appointmentId}
									path={`businesses/${appointments[appointmentId].businessId}`}
									shard={'public'}
								>
									{({ data: business }) => {
										if (!business) return <div />;
										const appointment = appointments[appointmentId];
										return (
											<AccountAppointment
												appointment={appointment}
												appointmentStart={parseISO(appointment.start)}
												business={business}
												collapseAppointmentSteps
												fromModalAddCard
												isCompact
												isManyAppointment
												isMobileDate
											/>
										);
									}}
								</FirebaseSubscription>
							);
						})}
					</div>
					<div>
						<StripePayment
							{...this.props}
							canDefault={false}
							confirm={() => {
								this.props.getPaymentMethods(this.props.customerID);
								this.setState({ displayAddCard: false });
							}}
							countryCode={countryCode}
							displayAddCard={this.state.displayAddCard}
							isAccount={isAccount}
							setDisplayAddCard={displayAddCard => {
								this.setState({ displayAddCard });
							}}
						/>
					</div>
					<div className={styles.footer}>
						<Button
							isFullMobile
							label={t('myAccount.noDelete')}
							type={'secondary'}
							onClick={closeModal}
						/>
						<Button
							isDisabled={selectedPaymentMethod === null && true}
							isFullMobile
							label={t('myAccount.replaceAndDelete')}
							onClick={() => {
								const getFarthestDate = this.getFarthestDate();
								const isCardExpirationDateAfterAppointment =
									this.isExpirationDateOk(
										selectedPaymentMethod.card.exp_year,
										selectedPaymentMethod.card.exp_month,
										getFarthestDate
									);

								if (isCardExpirationDateAfterAppointment > 0) {
									if (selectedPaymentMethod) {
										cardAdded(selectedPaymentMethod);
									}
								} else {
									setSelectedPaymentMethod(null);
									alert(t('onlinePayment.lateBookingError'));
								}
							}}
						/>
					</div>
				</div>
			</Modal>
		);
	}

	isDisplayCard = () => {
		const { paymentMethod } = this.props;

		if (paymentMethod.length <= 1) {
			this.setState({
				displayAddCard: true
			});
		}
	};

	getFarthestDate = () => {
		const { appointments } = this.props;

		return appointments.sort(function (a, b) {
			return new Date(b.start) - new Date(a.start);
		})[0].start;
	};

	isExpirationDateOk = (year, month, date) => {
		return compareAsc(
			endOfMonth(parseISO(`${year}-${('0' + month).slice(-2)}-01`)),
			parseISO(date)
		);
	};

	addCard = () => {
		const {
			t,
			stripe,
			clientSecret,
			attachPaymentMethod,
			setSelectedPaymentMethod,
			countryCode
		} = this.props;
		try {
			this.setState(
				{
					cardIsAdding: true
				},
				() => {
					const farthestDate = this.getFarthestDate();
					stripe
						.handleCardSetup(clientSecret)
						.then(Intent => {
							firebase
								.auth()
								.currentUser.getIdToken()
								.then(userToken => {
									if (Intent.setupIntent) {
										attachPaymentMethod(
											Intent.setupIntent.payment_method,
											countryCode,
											userToken,
											paymentMethods => {
												if (paymentMethods.alreadyAttached) {
													setSelectedPaymentMethod(null);
													alert('Vous ne pouvez utiliser la même carte.');
													this.setState(
														{
															displayAddCard: false,
															cardIsAdding: false
														},
														() => {
															this.setState({
																displayAddCard: true
															});
														}
													);
													return true;
												}

												const isCardExpirationDateAfterAppointment =
													this.isExpirationDateOk(
														paymentMethods.PaymentMethod.card.exp_year,
														paymentMethods.PaymentMethod.card.exp_month,
														farthestDate
													);

												if (isCardExpirationDateAfterAppointment > 0) {
													this.setState({
														displayAddCard: false,
														cardIsAdding: false
													});
												} else {
													setSelectedPaymentMethod(null);
													alert(t('onlinePayment.lateBookingError2'));
													this.setState(
														{
															displayAddCard: false,
															cardIsAdding: false
														},
														() => {
															this.setState({
																displayAddCard: true
															});
														}
													);
												}
											}
										);
									} else {
										this.setState({
											cardIsAdding: false,
											stripeError: Intent.error.message
										});
									}
								});
						})
						.catch(error => {
							console.error(error);
							this.setState({
								cardIsAdding: false
							});
						});
				}
			);
		} catch (e) {
			this.setState({
				cardIsAdding: false
			});
		}
	};
}

export default withStripeElementsConsumer(
	withLocalization(withTranslation()(withStyles(styles)(ModalAddCard)))
);
