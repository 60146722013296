import { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './business_slideshow.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Icon, Image, useSwiper } from '@planity/ui';

export function BusinessSlideshow({ className, items = [] }) {
	useStyles(styles);

	const [current, setCurrent] = useState(0);
	const [translate, setTranslate] = useState(0);
	const { onTouchStart, onTouchMove, onTouchEnd } = useSwiper();

	const goTo = (index = 0) => {
		const total = items.length;
		index = ((index % total) + total) % total;
		setCurrent(index);
		index > 0 ? setTranslate(index * -100) : setTranslate(0);
	};

	const previous = e => {
		e.stopPropagation();
		goTo(current - 1);
	};

	const next = e => {
		e.stopPropagation();
		goTo(current + 1);
	};

	const classes = classNames.bind(styles)({
		businessSlideshow: true,
		[className]: className !== undefined
	});

	if (!items.length) return null;
	return (
		<div className={classes}>
			<div
				className={styles.items}
				style={{
					transform: `translateX(${translate}%)`
				}}
			>
				{items.map((item, i) => (
					<div
						className={styles.item}
						key={i}
						onTouchEnd={e => onTouchEnd(next, previous, e)}
						onTouchMove={onTouchMove}
						onTouchStart={onTouchStart}
					>
						{item.isVideo ? (
							<iframe
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
								allowFullScreen
								className={styles.video}
								frameBorder='0'
								src={item.src}
								title='YouTube video player'
							/>
						) : (
							<Image
								className={styles.image}
								fit='cover'
								isCloudinary
								url={item.src}
							/>
						)}
					</div>
				))}
			</div>

			{items.length > 1 && (
				<div className={styles.nav}>
					<button
						className={styles.previous}
						name='previous'
						onClick={e => previous(e)}
					>
						<Icon icon='ChevronLeft' size='large' />
					</button>
					<button className={styles.next} name='next' onClick={e => next(e)}>
						<Icon icon='ChevronLeft' size='large' />
					</button>

					<div className={styles.counter}>
						<span>{current + 1}</span>
						<span>
							{'/'}
							{items.length}
						</span>
					</div>
				</div>
			)}
		</div>
	);
}
