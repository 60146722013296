import { noop } from '@planity/helpers';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './profile_gallery.module.scss';
import { Image, IconButton, Container, Button, useSwiper } from '@planity/ui';
import { getTransitionDuration } from '../../shared';
import { useTranslation } from '@planity/localization';

export function ProfileGallery({
	images = [],
	closeProfileGallery,
	isOpen,
	forwardedRef,
	setChosenCalendar
}) {
	const [index, setIndex] = useState(0);
	useStyles(styles);
	const { t } = useTranslation();
	const { onTouchStart, onTouchMove, onTouchEnd } = useSwiper();

	const next = useCallback(() => {
		setIndex((index + 1) % images.length);
	}, [index, images]);

	const previous = useCallback(() => {
		setIndex((index - 1 + images.length) % images.length);
	}, [index, images]);

	const close = useCallback(() => {
		closeProfileGallery();
		setIndex(0);
	}, [closeProfileGallery]);

	useEffect(() => {
		if (isNaN(index)) {
			setIndex(0);
		}

		const bind = event => {
			switch (event.key) {
				case 'ArrowRight':
					return next();
				case 'ArrowLeft':
					return previous();
				case 'Escape':
					return close();
				default:
					return noop();
			}
		};

		document.addEventListener('keyup', bind);
		return () => document.removeEventListener('keyup', bind);
	}, [close, images, index, next, previous]);

	const classes = classNames.bind(styles)({
		profileGallery: true,
		isOpen: isOpen
	});

	// Render

	return (
		<div className={classes} ref={forwardedRef}>
			<IconButton
				className={styles.close}
				label='Fermer'
				icon='Close'
				onClick={close}
				onMouseDown={e => e.preventDefault()} // Prevent focus state on click
			/>

			<div className={styles.slideshow}>
				<Container className={styles.container}>
					<IconButton
						aria-label='previous'
						className={styles.previous}
						icon={'ChevronLeft'}
						onClick={previous}
						onMouseDown={e => e.preventDefault()} // Prevent focus state on click
					/>
					<div
						className={styles.items}
						onTouchStart={onTouchStart}
						onTouchMove={onTouchMove}
						onTouchEnd={e => onTouchEnd(next, previous, e)}
					>
						{images.map((calendar, i) => {
							const { url, alt, name, id } = calendar;
							return (
								<div
									key={id}
									className={classNames.bind(styles)({
										item: true,
										isCurrent: i === index
									})}
								>
									{url ? (
										<Image
											className={styles.image}
											url={formatUrl(url)}
											alt={alt}
										/>
									) : (
										id !== 'ANY' && (
											<span
												className={`${styles.letter} planity_ui_action_worker-name`}
											>
												{name
													.normalize('NFD')
													.replace(/[\u0300-\u036f]/g, '')
													.replace(/\W/g, '')
													.charAt(0)
													.toUpperCase()}
											</span>
										)
									)}
									<Button
										isFullWidth
										isFullMobile
										label={`${t('bookAppointment.chooseCalendar.selected', {
											name
										})}`}
										type='tertiary'
										onClick={() => {
											setChosenCalendar({ calendar, index });
											close();
										}}
										useEllipsis
									/>
								</div>
							);
						})}
					</div>
					<IconButton
						aria-label='next'
						className={styles.next}
						icon={'ChevronRight'}
						onClick={next}
						onMouseDown={e => e.preventDefault()} // Prevent focus state on click
					/>
				</Container>
			</div>
		</div>
	);
}

const ProfileGalleryContext = React.createContext();

const ProfileGalleryProvider = props => {
	const [images, setProfileGalleryImages] = useState();
	const [isOpen, setIsOpen] = useState(false);
	const [chosenCalendar, setChosenCalendar] = useState(null);

	const profileGallery = useRef();
	const { onCloseProfileGallery } = props;
	const closeProfileGallery = useCallback(() => {
		setIsOpen(false);
		document.body.style.overflow = '';
		document.body.style.height = '';

		if (typeof onCloseProfileGallery === 'function') onCloseProfileGallery();
	}, [onCloseProfileGallery]);

	const openProfileGallery = useCallback(() => {
		setIsOpen(true);

		// Prevent double scrollbars on windows
		profileGallery.current.scrollTop = 0;
		profileGallery.current.style.overflow = 'hidden';

		setTimeout(() => {
			document.body.style.overflow = 'hidden';
			document.body.style.height = '100vh';
			profileGallery.current.style.overflow = '';
		}, getTransitionDuration(profileGallery.current));
	}, [profileGallery]);

	return (
		<ProfileGalleryContext.Provider
			value={{
				openProfileGallery,
				closeProfileGallery,
				setProfileGalleryImages,
				setChosenCalendar,
				chosenCalendar,
				isOpen
			}}
			{...props}
		>
			{props.children}

			<ProfileGallery
				forwardedRef={profileGallery}
				images={images}
				closeProfileGallery={closeProfileGallery}
				isOpen={isOpen}
				chosenCalendar={chosenCalendar}
				setChosenCalendar={setChosenCalendar}
			/>
		</ProfileGalleryContext.Provider>
	);
};

const useProfileGallery = () => {
	const context = React.useContext(ProfileGalleryContext);
	if (context === undefined) {
		throw new Error('useProfileGallery must be used within a UserProvider');
	}

	return context;
};

const formatUrl = url =>
	typeof url === 'string' ? (url || '').replace('http://', 'https://') : url;

export { ProfileGalleryProvider, useProfileGallery };
