import React from 'react';
import { Localize } from '@planity/components';
import styles from './alert.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';

export function Alert({ text, localizedText, className }) {
	const classes = classNames.bind(styles)({
		alert: true,
		[className]: className !== undefined
	});
	useStyles(styles);
	return (
		<div className={classes}>
			{localizedText ? <Localize text={localizedText} /> : text}
		</div>
	);
}
