import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import styles from './title.module.scss';

export const Title = ({ children, className, dangerouslyHTML, id }) => {
	const classes = classNames.bind(styles)({
		title: true,
		[className]: className
	});
	useStyles(styles);

	return (
		<h1 className={classes} dangerouslySetInnerHTML={dangerouslyHTML} id={id}>
			{children}
		</h1>
	);
};
