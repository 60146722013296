import { AlgoliaSearch } from '@planity/components';
import credentials from '@planity/credentials';
import {
	useLocalization,
	useLocalizedRoutes,
	useTranslation
} from '@planity/localization';
import { Drawer, MainSearchInput } from '@planity/ui';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './what_search_bar.module.scss';
import { WithCache } from 'planity-website/app/components/data';

export const WhatSearchBar = ({
	initialWhatResult,
	setWhatResult,
	displayOnlyOnDesktop,
	hide
}) => {
	const history = useHistory();
	useStyles(styles);
	const value = initialWhatResult?.isBusinessCategory
		? ''
		: initialWhatResult?.name ?? '';
	const [whatQuery, setWhatQuery] = useState(value);
	const [whatInputContent, setWhatInputContent] = useState(value);
	const [currentCategory, setCurrentCategory] = useState(null);
	const [isWhatDrawerOpen, setIsWhatDrawerOpen] = useState(false);
	const [queryFilters, setQueryFilters] = useState(
		initialWhatResult?.isBusinessCategory
			? `parentId:${initialWhatResult.objectID} AND NOT disabled:true`
			: initialWhatResult?.parentIsBusinessCategory
			? `parentId:${initialWhatResult.parentId} AND NOT disabled:true`
			: `NOT isAdjective:true AND NOT disabled:true`
	);

	const { routes } = useLocalizedRoutes();
	const { countries, language } = useLocalization();
	const { t } = useTranslation();

	const classes = classNames.bind(styles);
	const onWhatBlur = event => {
		event.preventDefault();
		setIsWhatDrawerOpen(false);
		if (!currentCategory) setCurrentCategory(initialWhatResult?.name);
	};
	const onWhatFocus = event => {
		event.preventDefault();
		setWhatQuery(whatInputContent);
		setIsWhatDrawerOpen(true);
		if (!currentCategory) setCurrentCategory(initialWhatResult?.name);
	};

	const whatQueries = useMemo(() => {
		const queries = {
			categories: {
				index: credentials.CATEGORIES_INDEX,
				params: {
					hitsPerPage: process.env.WELL_BEING ? 9 : 5,
					page: 0,
					filters: queryFilters
				}
			},
			businesses: {
				index: credentials.BUSINESSES_INDEX,
				params: {
					hitsPerPage: whatQuery?.length ? 10 : 0,
					page: 0,
					filters: `plStatus > 0 AND countryCode:${countries
						.map(country => country.toUpperCase())
						.join(' OR countryCode:')}`
				}
			}
		};

		return queries;
	}, [countries, whatQuery?.length, initialWhatResult, queryFilters]);

	const onWhatSelect = what => {
		if (what.isBusiness) {
			history.push(routes.catchAll({ business: what }));
		} else {
			setWhatInputContent(what?.name || '');
			setWhatResult(what);
		}
	};

	const onWhatChange = what => {
		const isInitialBusinessCategory = initialWhatResult?.isBusinessCategory;
		const isInitialParentBusinessCategory =
			initialWhatResult?.parentIsBusinessCategory;

		if (
			(isInitialBusinessCategory || isInitialParentBusinessCategory) &&
			what === ''
		) {
			const parentId = isInitialBusinessCategory
				? initialWhatResult.objectID
				: initialWhatResult.parentId;
			setQueryFilters(`parentId:${parentId} AND NOT disabled:true`);
		} else {
			setQueryFilters(`NOT isAdjective:true AND NOT disabled:true`);
		}

		const content = what || '';
		setWhatInputContent(content);
		setWhatQuery(content);
	};

	function formatWhat({ businesses, categories }) {
		const formatCategorySlice = (data, length) => {
			if (!data) return [];
			const slicedCategories = data.slice(0, length);
			const hasBusinesses = !!businesses?.data?.length;
			return slicedCategories.map((category, index, categories) => {
				return formatCategory(category, index, categories, hasBusinesses);
			});
		};

		const formattedCategories = formatCategorySlice(
			categories?.data,
			process.env.WELL_BEING ? 9 : 5
		);

		const formattedBusinesses = businesses?.data
			?.slice(0, 10)
			?.map(formatBusiness);
		return formattedCategories.concat(formattedBusinesses);
	}

	function formatCategory(category, index, categories, hasBusinessesAfter) {
		const isLastCategory = categories.length - 1 === index;
		const { isBrand } = category;

		return {
			...category,
			dangerousLabel: category?._highlightResult?.name?.value
				?.replace(/<em>/gi, '<strong><u>')
				?.replace(/<\/em>/gi, '</u></strong>'),
			description: isBrand && t('business.allBusiness'),
			isCategory: true,
			isInlined: true,
			customStyle: hasBusinessesAfter && isLastCategory && styles.lastCategory,
			avatar: false
		};
	}

	function formatBusiness(business) {
		return {
			...business,
			description: [business?.address?.postalCode, business?.address?.locality]
				.filter(x => x)
				.join(' '),
			isBusiness: true,
			dangerousLabel: business?._highlightResult?.name?.value
				?.replace(/<em>/gi, '<strong><u>')
				?.replace(/<\/em>/gi, '</u></strong>'),
			isInlined: true,
			picture: business?.pictures?.at(0),
			label: false,
			avatar: {
				icon: !business?.pictures?.at(0) && 'Store',
				size: 'large',
				sizeIcon: '32',
				shape: 'square'
			}
		};
	}
	function getMainSearchInputPlaceholder(childrenCategories) {
		const isBusinessCategory =
			initialWhatResult?.isBusinessCategory ||
			initialWhatResult?.parentIsBusinessCategory;

		if (isBusinessCategory && childrenCategories) {
			return childrenCategories.map(category => category.name).join(', ');
		}
		return currentCategory ?? t('search.placeholders.business');
	}
	return initialWhatResult?.isBusinessCategory ||
		initialWhatResult?.parentIsBusinessCategory ? (
		<AlgoliaSearch
			filters={
				initialWhatResult?.isBusinessCategory
					? `parentId:${initialWhatResult?.objectID}`
					: `parentId:${initialWhatResult?.parentId}`
			}
			index={credentials.CATEGORIES_INDEX}
			localizeResults
			language={language}
		>
			{({ data: childrenCategoriesData }) => (
				<MainSearchInput
					id='main-what-input'
					className={classes({
						whatSearchBar: true,
						displayOnlyOnDesktop,
						hide
					})}
					value={whatInputContent}
					onChange={onWhatChange}
					onBlur={onWhatBlur}
					onFocus={onWhatFocus}
					label={t('search.placeholders.category')}
					placeholder={getMainSearchInputPlaceholder(childrenCategoriesData)}
				>
					<WithCache>
						{cacheProvider => (
							<AlgoliaSearch
								cacheProvider={cacheProvider}
								queries={whatQueries}
								query={whatQuery}
								format={formatWhat}
								localizeResults
								language={language}
							>
								{({ data }) => (
									<Drawer
										onSelect={onWhatSelect}
										className={classes({ whatDrawer: true })}
										items={data || []}
										id='Algolia'
										isOpen={isWhatDrawerOpen}
										hasArrowKeysNavigation
									/>
								)}
							</AlgoliaSearch>
						)}
					</WithCache>
				</MainSearchInput>
			)}
		</AlgoliaSearch>
	) : (
		<MainSearchInput
			id={'main-what-input'}
			className={classes({
				whatSearchBar: true,
				displayOnlyOnDesktop,
				hide
			})}
			value={whatInputContent}
			onChange={onWhatChange}
			onBlur={onWhatBlur}
			onFocus={onWhatFocus}
			label={t('search.placeholders.category')}
			placeholder={currentCategory ?? t('search.placeholders.business')}
		>
			<WithCache>
				{cacheProvider => (
					<AlgoliaSearch
						cacheProvider={cacheProvider}
						queries={whatQueries}
						query={whatQuery}
						format={formatWhat}
						localizeResults
						language={language}
					>
						{({ data }) => (
							<Drawer
								onSelect={onWhatSelect}
								className={classes({ whatDrawer: true })}
								items={data || []}
								id={'Algolia'}
								isOpen={isWhatDrawerOpen}
								hasArrowKeysNavigation
							/>
						)}
					</AlgoliaSearch>
				)}
			</WithCache>
		</MainSearchInput>
	);
};
