import React from 'react';

export const DO = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5643)'>
				<mask
					id='mask0_11534_5643'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5643)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#C51918'
					/>
					<mask
						id='mask1_11534_5643'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_11534_5643)'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M8.75 0H11.25V6.25H20V8.75H11.25V15H8.75V8.75H0V6.25H8.75V0Z'
							fill='#F7FCFF'
						/>
						<rect width='8.75' height='6.25' fill='#4257BF' />
						<rect
							x='11.25'
							y='8.75'
							width='8.75'
							height='6.25'
							fill='#4257BF'
						/>
						<path
							d='M8.52307 6.94421L9.14523 7.00376C9.03874 8.11634 9.17713 8.58513 9.40199 8.58513V9.21013C8.62425 9.21013 8.38407 8.39649 8.52307 6.94421Z'
							fill='#309404'
						/>
						<path
							d='M11.5167 6.94421L10.8945 7.00376C11.001 8.11634 10.8626 8.58513 10.6377 8.58513V9.21013C11.4155 9.21013 11.6557 8.39649 11.5167 6.94421Z'
							fill='#309404'
						/>
						<rect
							x='9.20454'
							y='6.68268'
							width='0.590909'
							height='0.923077'
							fill='#003994'
						/>
						<path
							d='M10.1136 7.77884H10.7045V8.24737C10.7045 8.49841 10.501 8.70192 10.25 8.70192H10.1136V7.77884Z'
							fill='#003994'
						/>
						<rect
							x='10.0682'
							y='6.625'
							width='0.590909'
							height='0.923077'
							fill='#DE2110'
						/>
						<path
							d='M9.20454 7.77884H9.79545V8.70192H9.65909C9.40805 8.70192 9.20454 8.49841 9.20454 8.24737V7.77884Z'
							fill='#DE2110'
						/>
						<path
							d='M8.95903 6.63085L8.54099 6.16624C8.98245 5.76902 9.44255 5.5625 9.9164 5.5625C10.3902 5.5625 10.8503 5.76902 11.2918 6.16624L10.8738 6.63085C10.5394 6.33001 10.2219 6.1875 9.9164 6.1875C9.61088 6.1875 9.29338 6.33001 8.95903 6.63085Z'
							fill='#00319C'
						/>
						<path
							d='M9.00447 9.74624L8.58643 9.28163C9.02789 8.88441 9.48799 8.67789 9.96184 8.67789C10.4357 8.67789 10.8958 8.88441 11.3372 9.28163L10.9192 9.74624C10.5849 9.4454 10.2674 9.30289 9.96184 9.30289C9.65632 9.30289 9.33882 9.4454 9.00447 9.74624Z'
							fill='#DE2110'
						/>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5643'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
