import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './footer.module.scss';

export function FooterItem({ data, dataToAppend = [] }) {
	useStyles(styles);

	const { content, title } = data;

	return (
		<div className={styles.column}>
			<span className={styles.title}>{title}</span>
			<FooterListItem content={content.concat(dataToAppend)} />
		</div>
	);
}

const FooterListItem = ({ content }) => {
	if (Array.isArray(content)) {
		return (
			<ul className={styles.list}>
				{content
					.filter(({ text }) => !!text && !!text.trim())
					.map((item, i) => (
						<li key={i} id={i}>
							<FooterLink {...item} />
						</li>
					))}
			</ul>
		);
	}
};

const FooterLink = ({ link, text, onClick }) => {
	if (link && link.startsWith('http')) {
		return (
			<a href={link} rel='noreferrer' target={'_blank'}>
				<span>{text}</span>
			</a>
		);
	}
	return (
		<a className={styles.footerLink} onClick={onClick} href={link}>
			<span>{text}</span>
		</a>
	);
};
