import { Spinner } from '@planity/ui';
import React from 'react';
import styles from './loading_page.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';

export const LoadingPage = () => {
	useStyles(styles);
	const classes = classNames.bind(styles);

	return (
		<div className={classes({ loading: true })}>
			<Spinner />
		</div>
	);
};
