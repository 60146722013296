// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cancellation_modal_buttons-module_confirmButton--2gOS{white-space:normal}@media only screen and (max-width: 767px){.cancellation_modal_buttons-module_cancelButton-z25LL{grid-area:2/1/3/2}}", "",{"version":3,"sources":["webpack://./../../packages/ui/components/cancellation_modal/cancellation_modal_buttons/cancellation_modal_buttons.module.scss"],"names":[],"mappings":"AAEA,uDACC,kBAAA,CAGA,0CADD,sDAEE,iBAAA,CAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.confirmButton {\n\twhite-space: normal;\n}\n.cancelButton {\n\t@media only screen and (max-width: #{styles.$tablet - 1px}) {\n\t\tgrid-area: 2/1/3/2;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"confirmButton": "cancellation_modal_buttons-module_confirmButton--2gOS",
	"cancelButton": "cancellation_modal_buttons-module_cancelButton-z25LL"
};
module.exports = ___CSS_LOADER_EXPORT___;
