import { reduce } from '../functions';

export function sortedTree({
	tree,
	keepSet,
	keepLeaf,
	forwardSetProps,
	addProps
}) {
	return reduce(
		tree || {},
		(list, set, setId) => {
			if (!keepSet || keepSet({ node: set, id: setId })) {
				const setProps = forwardSetProps && forwardSetProps({ set, setId });
				const children = sortedTree({
					tree: set.children,
					keepSet: keepLeaf,
					addProps: setProps
				});
				list.push({
					...set,
					...addProps,
					id: setId,
					children: children.length ? children : null
				});
			}
			return list;
		},
		[]
	).sort((x, y) => x.sort - y.sort);
}

export function leaves({ tree, keepSet, keepLeaf, forwardSetProps }) {
	return reduce(
		tree || {},
		(leaves, set, setId) => {
			if (set.children && (!keepSet || keepSet({ set, setId }))) {
				return reduce(
					set.children,
					(setLeaves, leaf, leafId) => {
						if (!keepLeaf || keepLeaf({ leaf, leafId, set, setId })) {
							const setProps =
								forwardSetProps && forwardSetProps({ set, setId });
							setLeaves.push({
								id: leafId,
								...leaf,
								...setProps
							});
						}
						return setLeaves;
					},
					leaves
				);
			} else {
				return leaves;
			}
		},
		[]
	);
}

export function byId(list) {
	return list.reduce((map, object) => {
		map[object.id] = object;
		return map;
	}, {});
}
