import { OrderHeader } from '@planity/ui';
import React from 'react';

export default function ClickAndCollectProductHeader({
	boughtAt,
	isClickAndShop,
	orderId,
	orderStatus,
	trackingUrl,
	trackingNumber,
	businessName,
	linkToBusiness,
	businessId,
	business
}) {
	const status = isClickAndShop
		? `clickAndCollect.clickAndShopStatus.${orderStatus}`
		: `clickAndCollect.status.${orderStatus}`;
	return (
		<OrderHeader
			boughtAt={boughtAt}
			business={business}
			businessId={businessId}
			businessName={businessName}
			linkToBusiness={linkToBusiness}
			localizedStatus={status}
			orderId={orderId}
			orderStatus={orderStatus}
			trackingNumber={trackingNumber}
			trackingUrl={trackingUrl}
		/>
	);
}
