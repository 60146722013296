// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pagination-module_pagination-ILiN2{display:flex;justify-content:space-between;align-items:center;color:var(--grey-500);padding-top:20px}.pagination-module_navItem-k1wf8{display:grid;grid:1fr/auto auto;gap:4px;align-items:center;font:var(--regular) var(--body-4);color:var(--grey-700);cursor:pointer}.pagination-module_navItem-k1wf8.pagination-module_disabled-4GyJc{color:var(--grey-200);cursor:not-allowed}", "",{"version":3,"sources":["webpack://./../../packages/ui/uikit/navigation/pagination/pagination.module.scss"],"names":[],"mappings":"AAAA,oCACC,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,qBAAA,CACA,gBAAA,CAGD,iCACC,YAAA,CACA,kBAAA,CACA,OAAA,CACA,kBAAA,CACA,iCAAA,CACA,qBAAA,CACA,cAAA,CAEA,kEACC,qBAAA,CACA,kBAAA","sourcesContent":[".pagination {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\tcolor: var(--grey-500);\n\tpadding-top: 20px;\n}\n\n.navItem {\n\tdisplay: grid;\n\tgrid: 1fr / auto auto;\n\tgap: 4px;\n\talign-items: center;\n\tfont: var(--regular) var(--body-4);\n\tcolor: var(--grey-700);\n\tcursor: pointer;\n\n\t&.disabled {\n\t\tcolor: var(--grey-200);\n\t\tcursor: not-allowed;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": "pagination-module_pagination-ILiN2",
	"navItem": "pagination-module_navItem-k1wf8",
	"disabled": "pagination-module_disabled-4GyJc"
};
module.exports = ___CSS_LOADER_EXPORT___;
