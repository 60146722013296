import { useLocalization } from '@planity/localization';
import React from 'react';
import { AlgoliaSearch as BaseAlgoliaSearch } from '@planity/components';
import { WithCache } from './cache_provider';

export default function AlgoliaSearch({ useCache, ...props }) {
	const { language, countryCode } = useLocalization();
	return (
		<WithCache>
			{cacheProvider => (
				<BaseAlgoliaSearch
					{...props}
					cacheProvider={useCache && cacheProvider}
					countryCode={countryCode}
					debug={false}
					language={language}
				/>
			)}
		</WithCache>
	);
}
