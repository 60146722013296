import React from 'react';

const MAX_VALUE = 4;

export default function SymbolEuro({ rating, className }) {
	const arrayOfSymbol = [];
	for (let i = 0; i < Math.min(MAX_VALUE, rating); i++) {
		arrayOfSymbol.push('€');
	}
	return <div css={className}>{arrayOfSymbol}</div>;
}
