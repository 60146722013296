import React, { forwardRef } from 'react';

const section = Tag =>
	forwardRef(({ className, children }, ref) => (
		<Tag className={className} ref={ref}>
			{children}
		</Tag>
	));

export const Wrapper = section('section');
export const Main = section('main');
export const Aside = section('aside');
