import React, { useState } from 'react';
import { Localize, useAuth } from '@planity/components';
import { useTranslation } from '@planity/localization';
import { Badge, Button, Icon, useModal } from '@planity/ui';
import { useErrorMessages } from '@planity/components';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './my_children.module.scss';
import { DeleteChildConfirmationModal, SetChildModal } from '../../children';
import { checkChildDeletion, formatPhoneNumber } from '@planity/helpers';
import { AccordionChild } from './accordion_child';
import colors from '@planity/theme/colors';

export const Child = ({
	child,
	parentId,
	deleteChild,
	handleChildUpdating,
	redirectToMyAppointments,
	userChildren
}) => {
	useStyles(styles);
	const { t } = useTranslation();
	const { setModal } = useModal();
	const { user, userId } = useAuth();
	const { pushMessage } = useErrorMessages();
	const [isLoading, setIsLoading] = useState(false);
	const parent = {
		email: user.email,
		name: user.name,
		phone: user.phone,
		parentId: userId
	};
	const { childId, name, email, phone, hasPlanityAccount } = child;

	const displayChildDeletionModal = async () => {
		setIsLoading(true);
		try {
			const canDeleteChild = await checkChildDeletion({
				parentId,
				childId
			});
			setModal({
				content: (
					<DeleteChildConfirmationModal
						canDeleteChild={canDeleteChild}
						childId={childId}
						deleteChild={deleteChild}
						hasPlanityAccount={hasPlanityAccount}
						redirectToMyAppointments={redirectToMyAppointments}
					/>
				)
			});
		} catch (e) {
			pushMessage({
				message: t('children.defaultError'),
				type: 'error',
				createdAt: Date.now()
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<AccordionChild
			email={email}
			key={email}
			name={name}
			phone={formatPhoneNumber(phone)}
		>
			<div className={styles.container}>
				<div className={styles.wrapper}>
					{hasPlanityAccount ? (
						<>
							<Badge
								className={styles.badgeStyles}
								iconLeft='CheckRound'
								id={`has-planity-account`}
								label={t(`children.hasAccount`)}
							/>
							<div>
								<div className={styles.title}>
									<Localize text={'children.rights.can'} />
								</div>
								<ul>
									{['text1', 'text2'].map(text => (
										<li className={styles.labels} key={text}>
											<Icon color={colors.alert.success} icon='Check' />
											<span>
												<Localize text={`children.rights.${text}`} />
											</span>
										</li>
									))}
								</ul>
								<div className={styles.title}>
									<Localize text={'children.rights.cannot'} />
								</div>
								<ul>
									{['text5', 'text4'].map(text => (
										<li className={styles.labels} key={text}>
											<Icon className={styles.closeIcon} icon='Close' />
											<span>
												<Localize text={`children.rights.${text}`} />
											</span>
										</li>
									))}
								</ul>
							</div>
						</>
					) : (
						<>
							<div className={styles.title}>
								<Localize text={'children.rights.can'} />
							</div>
							<ul>
								{['text1', 'text3', 'text4'].map(text => (
									<li className={styles.labels} key={text}>
										<Icon color={colors.alert.success} icon='Check' />
										<span>
											<Localize text={`children.rights.${text}`} />
										</span>
									</li>
								))}
							</ul>
						</>
					)}
				</div>
				<div className={styles.actions}>
					{!hasPlanityAccount && (
						<Button
							className={styles.update}
							id={`update-child-button`}
							isFullMobile
							label={t('children.actions.updateChild')}
							type='tertiary'
							onClick={() =>
								setModal({
									className: 'isChildModal',
									content: (
										<SetChildModal
											initialChild={child}
											parent={parent}
											userChildren={userChildren}
											onConfirmClick={handleChildUpdating}
										/>
									),
									isFullHeight: true
								})
							}
						/>
					)}
					<Button
						id={`delete-child-button`}
						isFullMobile
						isLoading={isLoading}
						label={
							hasPlanityAccount
								? t('children.actions.removeChild')
								: t('children.actions.deleteChild')
						}
						type='danger'
						onClick={displayChildDeletionModal}
					/>
				</div>
			</div>
		</AccordionChild>
	);
};
