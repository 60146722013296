import { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from './gift_voucher_choice.module.scss';
import { Button, Icon, Image } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';

export function GiftVoucherChoice({
	items,
	onTemplateChose,
	expand,
	selectedTemplate,
	reinjectData,
	isPending,
	onTemplateValidated,
	templateWasChosen,
	variant,
	continueButtonRef
}) {
	useStyles(styles);
	const { t } = useTranslation();
	const [state, setState] = useState(reinjectData ? 'selected' : 'choices');
	const [templateHasChanged, setTemplateHasChanged] = useState(false);

	useEffect(() => {
		templateWasChosen && setState('selected');
	}, [templateWasChosen]);

	useMemo(() => {
		setTemplateHasChanged(true);
	}, [selectedTemplate]);

	const classes = classNames.bind(styles)({
		giftVoucherChoice: true,
		notAllItems: items.length < 3
	});

	const renderSelection = (
		<div className={styles.selection}>
			<div className={styles.leftContainer}>
				<Image
					url={items.find(item => item.id === selectedTemplate)?.url}
					isCloudinary
					style={{
						borderRadius: '4px',
						width: 86,
						height: 56
					}}
				/>
				<span>{items.find(item => item.id === selectedTemplate)?.name}</span>
			</div>
			<Button
				className={styles.edit}
				onClick={() => {
					setState('choices');
				}}
				type='underlined'
				isLoading={isPending}
				label={t('common.edit')}
			/>
		</div>
	);

	const renderChoices = (
		<div className={styles.choices}>
			<div className={styles.alert}>
				<Icon className={styles.icon} icon='Information' />
				<span>{t('myAccount.giftVouchers.customExplained')}</span>
			</div>

			<div className={styles.grid}>
				{items &&
					items.map((item, i) => (
						<div
							key={i}
							className={styles.image}
							onClick={() => {
								if (selectedTemplate !== item.id) {
									onTemplateChose({
										selectedTemplate: item.id,
										variant: item.color
									});
								}
							}}
						>
							<Image
								alt={`gift-voucher-${i}`}
								url={item.url}
								isCloudinary
								style={{ borderRadius: 8 }}
								className={
									selectedTemplate === item.id && styles.selectedTemplate
								}
							/>
							{selectedTemplate === item.id && (
								<div className={styles.overlay}>
									<Icon icon='Check' color='white' />
								</div>
							)}
						</div>
					))}
			</div>
			{selectedTemplate !== undefined &&
				selectedTemplate ===
					items.find(item => item.id === selectedTemplate)?.id && (
					<div
						ref={continueButtonRef}
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							flexGrow: 1
						}}
					>
						<Button
							isFullWidth
							type='primary'
							label={t('myAccount.giftVouchers.continue')}
							onClick={() => {
								onTemplateValidated();
								!!templateHasChanged && setState('selected');
							}}
							className={styles.marginBottom}
						/>
						<Button
							isExternal
							label={t('myAccount.giftVouchers.previewGiftVoucher')}
							size='medium'
							onClick={() =>
								expand(items.find(item => item.id === selectedTemplate)?.id)
							}
							type='linked'
							className={styles.alignCenter}
						/>
					</div>
				)}
		</div>
	);

	return (
		<div className={classes}>
			{state === 'selected' && renderSelection}
			{state === 'choices' && renderChoices}
		</div>
	);
}
