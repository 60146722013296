import { useEffect, useState } from 'react';
import { getAllChildren as getAllChildrenHelper } from '@planity/helpers';

/**
 * Retrieve all children of a specified user
 * @param {string} parentId (userId)
 * @param {boolean} shouldFetchChildren
 */
export function useAllChildren(parentId, shouldFetchChildren = false) {
	const [children, setChildren] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const getAllChildren = async () => {
		if (!shouldFetchChildren) return;
		setLoading(true);
		setError(null);
		try {
			const res = await getAllChildrenHelper({ parentId });
			res.sort((x, y) => x.createdAt - y.createdAt);
			setChildren(res);
		} catch (e) {
			setError(e);
			setChildren([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (parentId && shouldFetchChildren) {
			getAllChildren(parentId);
		} else if (!parentId) {
			// reset children when user logs out
			setChildren([]);
		}
	}, [parentId, shouldFetchChildren]);

	return {
		children,
		loading,
		error,
		setChildren,
		getAllChildren
	};
}
