import { withAuth } from '@planity/components';
import credentials from '@planity/credentials';
import { firebase } from '@planity/datastores';
import {
	checkIfIsBookable,
	computeFees,
	formatPhoneNumber,
	formatPrice,
	getFinalFees,
	invokeLambda,
	PAYMENT_TYPE_CARD
} from '@planity/helpers';
import { withLocalizedRoutes, withTranslation } from '@planity/localization';
import { breakpoints, withResponsive } from '@planity/theme';
import {
	AccountAppointment,
	CancellationModalContent,
	CancellationModalButtons
} from '@planity/ui';
import { withModal } from '@planity/ui/context';
import classNames from 'classnames/bind';
import { differenceInHours, parseISO } from 'date-fns';
import withStyles from 'isomorphic-style-loader/withStyles';
import {
	APPOINTMENT_IS_FULL_DEPOSIT,
	APPOINTMENT_IS_IMPRINT,
	APPOINTMENT_IS_PARTIAL_DEPOSIT,
	APPOINTMENT_IS_PREPAYMENT,
	getAppointmentOnlinePaymentType
} from './helpers';
import React, { Component } from 'react';
import { withStripeFees } from '../../app_settings/stripeFeesProvider';
import styles from './appointment_preview_component.module.scss';
import {
	ConfirmCancellationModalButtons,
	ConfirmCancellationModalContent
} from './confirm_cancellation_modal';
import { isNativeApp } from '@planity/webview';

const { ENABLE_USER_PAYS_FEES, HOST } = credentials;
const NETWORK_ERROR = 'NETWORK_ERROR';
const CANCELLATION_TIME_LAPSE_OVER_ERROR = 'CANCELLATION_TIME_LAPSE_OVER_ERROR';
const UNKNOWN_ERROR = 'UNKNOWN_ERROR';

/*
 * THIS COMPONENT IS SENSITIVE TO THE "Pro app delete user_vevents field" BUG
 */
export const AppointmentPreviewComponent = withStyles(styles)(
	withLocalizedRoutes(
		withTranslation()(
			withAuth(
				withStripeFees(
					withResponsive(
						withModal(
							class extends Component {
								state = {
									cancellationIsPending: false,
									wasJustCancelled: false,
									paidAmount: null,
									feesAmount: null,
									paymentCreatedAt: null,
									depositType: null
								};

								componentDidUpdate(prevProps, prevState) {
									if (
										!prevState.cancellationIsPending &&
										this.state.cancellationIsPending
									) {
										window.addEventListener('beforeunload', onBeforeUnload);
									}
									if (
										prevState.cancellationIsPending &&
										!this.state.cancellationIsPending
									) {
										window.removeEventListener('beforeunload', onBeforeUnload);
									}
								}
								componentWillUnmount() {
									window.removeEventListener('beforeunload', onBeforeUnload);
								}

								render() {
									const {
										routes,
										isLoading,
										appointment,
										business,
										linkToBusiness,
										linkToBookAppointment,
										appointmentId,
										userId,
										user,
										sequence,
										isPast
									} = this.props;

									if (!business) return null;
									const { cancellationIsPending, wasJustCancelled } =
										this.state;
									const {
										feesAmount,
										paymentCreatedAt,
										depositType,
										paidAmount
									} = appointment;

									const appointmentStart = parseISO(appointment.start);
									const isCancelled = !appointment.start;
									const canBeCancelled =
										!isCancelled && appointmentStart > new Date();
									const googleMapURLPrefix =
										'https://www.google.com/maps/dir/?api=1';
									const googleMapURL =
										business && business.address
											? business.googlePlaceId
												? `${googleMapURLPrefix}&destination_place_id=${
														business.googlePlaceId
												  }&destination=${encodedAddress(business)}`
												: `${googleMapURLPrefix}&destination=${encodedAddress(
														business
												  )}`
											: false;
									const myAccountLink = process.env.WIDGET
										? window.location.href
										: `https://www.${HOST}${routes.myAccount}`;

									const isBookable = checkIfIsBookable({
										sequence: appointment.sequence,
										business
									});
									const appointmentData = {
										linkToBusiness,
										linkToBookAppointment,
										business,
										appointment,
										googleMapURL,
										isCancelled,
										wasJustCancelled,
										appointmentStart,
										appointmentId,
										myAccountLink,
										userId,
										user,
										cancellationIsPending,
										canBeCancelled,
										isBookable,
										sequence,
										isPast
									};

									const cx = classNames.bind(styles);

									return (
										<div
											className={cx({
												container: true
											})}
										>
											{!isLoading && (
												<AccountAppointment
													{...appointmentData}
													{...this.props}
													cancelAppointment={this.cancelAppointment}
													depositType={depositType}
													feesAmount={feesAmount}
													paidAmount={paidAmount}
													paymentCreatedAt={paymentCreatedAt}
													paymentMethod={appointment.paymentMethod}
													status={this.props.status}
												/>
											)}
										</div>
									);
								}
								error(cancellationError) {
									const { business } = this.props;
									if (!cancellationError) {
										return null;
									}
									const message = `myAccount.appointment.errors.${cancellationError}`;
									console.log(cancellationError);
									switch (cancellationError) {
										case CANCELLATION_TIME_LAPSE_OVER_ERROR:
											return {
												message,
												type: 'error',
												args: {
													phoneNumber: formatPhoneNumber(business.phoneNumber),
													rawPhoneNumber: business.phoneNumber,
													withLinkToPhoneNumber: !!business.phoneNumber
												}
											};
										case 'contactSalon':
											return {
												message,
												type: 'error'
											};
										case 'contactSalonWithPhone':
											return {
												message,
												type: 'error',
												args: {
													phoneNumber: formatPhoneNumber(business.phoneNumber),
													rawPhoneNumber: business.phoneNumber,
													withLinkToPhoneNumber: !!business.phoneNumber
												}
											};
										case UNKNOWN_ERROR:
											return {
												message: 'myAccount.appointment.errors.defaultError',
												type: 'error'
											};
										default:
											return message;
									}
								}

								/**
								 * Computes what label should be present on confirm button
								 * @return {String}
								 */
								getConfirmButtonLabel = () => {
									const { t, appointment } = this.props;
									const { sequence } = appointment;

									const totalPrice = (sequence || []).reduce(
										(total, { price = 0, cancelRate, isDeposit }) =>
											isDeposit === 'partial'
												? total + price * (cancelRate / 100)
												: total + price,
										0
									);
									// In cents
									const cancelPrice = (sequence || []).reduce(
										(total, { price = 0, cancelRate = 0 }) =>
											total + price * (cancelRate / 100),
										0
									);
									// In cents
									const cancelFees = this.computeCancelFees();

									const refundAmount = totalPrice - cancelPrice;

									const appointmentOnlinePaymentType =
										getAppointmentOnlinePaymentType(appointment);

									switch (appointmentOnlinePaymentType) {
										case 'APPOINTMENT_IS_IMPRINT':
											return t(
												'myAccount.appointment.appointmentCancellation.confirm',
												{ amount: formatPrice(cancelPrice + cancelFees, true) }
											);
										case 'APPOINTMENT_IS_PREPAYMENT':
										case 'APPOINTMENT_IS_FULL_DEPOSIT':
											return t(
												'myAccount.appointment.appointmentCancellation.prepayment.refund',
												{ amount: formatPrice(Math.round(refundAmount), true) }
											);
										case 'APPOINTMENT_IS_PARTIAL_DEPOSIT':
											return t(
												'myAccount.appointment.appointmentCancellation.deposit.confirm'
											);
										default:
											console.error(
												`UNKNOWN_ONLINE_PAYMENT_TYPE: ${appointmentOnlinePaymentType}`
											);
											return 'UNKNOWN_ONLINE_PAYMENT_TYPE';
									}
								};
								computeCancelFees = () => {
									const { appointment, allStripeFees, business } = this.props;
									const { sequence, feesAmount } = appointment;
									const userPaysFees = !!(
										ENABLE_USER_PAYS_FEES && appointment.userPaysFees
									);
									if (!userPaysFees) return 0;

									// In cents
									const cancelPrice = (sequence || []).reduce(
										(totalPrice, { price = 0, cancelRate = 0 }) =>
											totalPrice + price * (cancelRate / 100),
										0
									);
									// credit card because it's appointments with imprint that has cancelFees
									const stripeFees = getFinalFees(
										allStripeFees,
										business?.countryCode,
										PAYMENT_TYPE_CARD
									);

									const appointmentOnlinePaymentType =
										getAppointmentOnlinePaymentType(appointment);

									switch (appointmentOnlinePaymentType) {
										case APPOINTMENT_IS_IMPRINT:
											return computeFees({
												amount: cancelPrice,
												stripeFees: stripeFees
											});
										case APPOINTMENT_IS_FULL_DEPOSIT:
										case APPOINTMENT_IS_PARTIAL_DEPOSIT:
										case APPOINTMENT_IS_PREPAYMENT:
											return feesAmount;
										default:
											return 0;
									}
								};

								openConfirmationModal = () => {
									const { appointment, business, setModal } = this.props;
									setModal({
										content: (
											<ConfirmCancellationModalContent
												appointment={appointment}
												business={business}
											/>
										),
										hasCloseBtn: false,
										footerButtons: (
											<ConfirmCancellationModalButtons
												onSubmitClick={this.confirmCancelAppointment}
											/>
										)
									});
								};

								cancelAppointment = () => {
									const {
										appointment,
										business,
										appointmentId,
										setModal,
										pushMessage
									} = this.props;
									const { start, isDeposit } = appointment;
									//Check if appointment is online
									if (!appointment.paymentMethod) {
										return this.openConfirmationModal();
									}

									//Check if appointment can be canceled without fees
									let isLate = false;

									Object.keys(appointment.sequence || {}).map(stepId => {
										const { lateCancellationDelay, cancelRate } =
											appointment.sequence[stepId];
										if (
											differenceInHours(parseISO(start), new Date()) <
												lateCancellationDelay &&
											cancelRate
										) {
											isLate = true;
										}
									});

									if (isLate) {
										const isPartialDepositAppointment = isDeposit === 'partial';

										//Open cancel fee modal
										this.getAuthPayload().then(authWith => {
											invokeLambda('cancelAppointment', {
												...authWith,
												sequenceId: appointmentId,
												checkOnly: true,
												business,
												start,
												refundType: isPartialDepositAppointment
													? 'lateCancellationPartialDeposit'
													: 'lateCancellationPrepayment'
											})
												.then(response => {
													if (response) {
														setModal({
															content: (
																<CancellationModalContent
																	appointment={appointment}
																	business={business}
																	feesAmount={this.computeCancelFees()}
																/>
															),
															hasCloseBtn: false,
															footerButtons: (
																<CancellationModalButtons
																	confirmButtonLabel={this.getConfirmButtonLabel()}
																	onCancelClick={() =>
																		this.setState({
																			cancellationIsPending: false
																		})
																	}
																	onSubmitClick={this.onSubmitClick}
																/>
															)
														});
													} else {
														this.setState({
															cancellationIsPending: false,
															cancellationIsPendingFromOnline: false
														});

														pushMessage(
															this.error(
																business.phoneNumber
																	? 'contactSalonWithPhone'
																	: 'contactSalon'
															)
														);
													}
												})
												.catch(error => {
													console.error(
														'Error while invoking cancelAppointment',
														error
													);
													this.setState({
														cancellationIsPending: false,
														cancellationIsPendingFromOnline: false
													});
													pushMessage(
														this.error(
															business.phoneNumber
																? 'contactSalonWithPhone'
																: 'contactSalon'
														)
													);
												});
										});
									} else {
										//Just cancel appointment (cancel delays are still applied)
										return this.openConfirmationModal();
									}
								};

								getCancelFees = () => {
									this.setState(
										{
											cancellationIsPending: true,
											cancellationIsPendingFromOnline: true
										},
										() => {
											const {
												user,
												userId,
												business,
												appointment,
												appointmentId,
												pushMessage
											} = this.props;
											const {
												start,
												userPaysFees,
												sequence,
												paymentMethod,
												businessId
											} = appointment;

											if (!sequence || !sequence.length) {
												// That means we have a serious bug
												this.setState({
													cancellationIsPending: false,
													cancellationIsPendingFromOnline: false
												});
												pushMessage(
													this.error(
														business.phoneNumber
															? 'contactSalonWithPhone'
															: 'contactSalon'
													)
												);
											}

											// Apply all cancel rates if it's a "late cancellation"
											const amount = sequence.reduce(
												(total, { cancelRate, lateCancellationDelay, price }) =>
													total +
													(differenceInHours(parseISO(start), new Date()) <
													lateCancellationDelay
														? Math.round(price * (cancelRate / 100))
														: 0),
												0
											);

											firebase
												.auth()
												.currentUser.getIdToken()
												.then(userToken => {
													invokeLambda('onlinePayment', {
														firebaseToken: userToken,
														actions: {
															type: 'charge.cancelFee',
															amount,
															paymentMethod,
															businessId,
															noreceipt: true,
															customerName: user && user.name ? user.name : '',
															description: `Cancel fee for user : ${userId} and appointment id : ${appointmentId}`,
															userPaysFees: !!userPaysFees,
															transactionType: 'lateCancellation',
															alterationType:
																'empreinteLatecancellationFromUser'
														}
													})
														.then(response => {
															if (response.response && response.response.id) {
																this.confirmCancelAppointment(true);
															} else {
																this.setState({
																	cancellationIsPending: false,
																	cancellationIsPendingFromOnline: false
																});
																pushMessage(
																	this.error(
																		business.phoneNumber
																			? 'contactSalonWithPhone'
																			: 'contactSalon'
																	)
																);
															}
														})
														.catch(error => {
															this.setState({
																cancellationIsPending: false,
																cancellationIsPendingFromOnline: false
															});
															pushMessage(this.error(JSON.stringify(error)));
															console.error(
																'error invoking onlinePayment',
																error
															);
														});
												});
										}
									);
								};

								confirmCancelAppointment = (
									ignoreDelay = false,
									refundAmount = null,
									isLate
								) => {
									const { closeModal } = this.props;
									const {
										cancellationIsPending,
										cancellationIsPendingFromOnline
									} = this.state;
									const { appointment, appointmentId, pushMessage } =
										this.props;

									const isPartialDepositAppointment =
										appointment.isDeposit === 'partial';

									const fromOrigin = {};
									if (process.env.WIDGET) {
										fromOrigin.env = 'whitelabel';
										fromOrigin.color = window.planity.primaryColor;
									}
									if (
										!cancellationIsPending ||
										cancellationIsPendingFromOnline
									) {
										this.setState(
											{
												cancellationIsPending: true
											},
											() => {
												this.getAuthPayload().then(authWith => {
													invokeLambda('cancelAppointment', {
														...authWith,
														sequenceId: appointmentId,
														ignoreDelay,
														fromOrigin,
														refundAmount,
														refundType: isLate
															? isPartialDepositAppointment
																? 'lateCancellationPartialDeposit'
																: 'lateCancellationPrepayment'
															: 'cancellation',
														alterationType: isLate
															? 'prepaymentLatecancellationFromUser'
															: undefined
													})
														.then(response => {
															closeModal();
															const { chargeId } = appointment;

															if (!response || !response.errorMessage) {
																if (chargeId) {
																	if (refundAmount === null) {
																		pushMessage({
																			message: 'onlinePayment.refundMessage',
																			type: 'success'
																		});
																	} else if (refundAmount) {
																		pushMessage({
																			message: 'onlinePayment.partialRefund',
																			type: 'success',
																			args: {
																				refundAmount: formatPrice(refundAmount)
																			}
																		});
																	}
																} else {
																	pushMessage({
																		message:
																			'myAccount.appointment.confirmCancellation.success',
																		type: 'success'
																	});
																}
															}

															const error = response && response.errorMessage;
															this.setState({
																cancellationIsPending: false,
																wasJustCancelled: !error
															});
															if (error) {
																pushMessage(this.error(error));
															}
														})
														.catch(error => {
															closeModal();
															this.setState({
																cancellationIsPending: false
															});
															const netWorkErrorMessage = this.error(
																error && error.name === 'TypeError'
																	? `${NETWORK_ERROR}`
																	: `defaultError`
															);

															// error already handle in user app
															if (!isNativeApp) {
																pushMessage({
																	message: netWorkErrorMessage,
																	type: 'error'
																});
															}

															console.error(
																'An error occured while invoking cancelAppointment',
																error
															);
														});
												});
											}
										);
									}
								};

								getAuthPayload() {
									const { authWith, userId } = this.props;
									if (authWith) {
										return Promise.resolve(authWith);
									} else {
										const currentUser = firebase.auth().currentUser;
										if (currentUser) {
											return currentUser.getIdToken().then(userToken => ({
												userId,
												userToken
											}));
										} else {
											return Promise.resolve(null);
										}
									}
								}

								onSubmitClick = () => {
									const { appointment } = this.props;
									const { chargeId, isDeposit, sequence } = appointment;
									const totalPrice = (sequence || []).reduce(
										(total, { price = 0, cancelRate, isDeposit }) =>
											isDeposit === 'partial'
												? total + price * (cancelRate / 100)
												: total + price,
										0
									);
									// In cents
									const cancelPrice = (sequence || []).reduce(
										(totalPrice, { price = 0, cancelRate = 0 }) =>
											totalPrice + price * (cancelRate / 100),
										0
									);

									const refundAmount = totalPrice - cancelPrice;
									const isPartialDepositAppointment = isDeposit === 'partial';

									//If charge id already set, then prepayment is activated,
									// refund instead of charge
									if (appointment.chargeId) {
										this.confirmCancelAppointment(
											false,
											chargeId
												? isPartialDepositAppointment
													? 0
													: refundAmount
												: null,
											true,
											true
										);
									} else {
										this.getCancelFees();
									}
								};
							}
						)
					)
				)
			)
		)
	)
);

function encodedAddress({ address }) {
	return encodeURIComponent(
		['street', 'postalCode', 'locality', 'country']
			.reduce((a, k) => {
				if (address[k]) a.push(address[k]);
				return a;
			}, [])
			.join(' ')
	);
}
function onBeforeUnload(event) {
	event.preventDefault();

	// Included for legacy support, e.g. Chrome/Edge < 119
	event.returnValue = true;
}
