// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title-module_title-wgrT7{margin:0}@media only screen and (max-width: 767px){.title-module_title-wgrT7{font:var(--regular) var(--title-alt-2);padding-bottom:32px}}@media only screen and (min-width: 768px)and (max-width: 1079px){.title-module_title-wgrT7{font:var(--regular) var(--title-alt-1);padding-bottom:24px}}@media only screen and (min-width: 1080px){.title-module_title-wgrT7{font:var(--regular) var(--title-alt-1);padding-bottom:24px}}", "",{"version":3,"sources":["webpack://./app/components/category_page/title.module.scss"],"names":[],"mappings":"AAEA,0BACC,QAAA,CACA,0CAFD,0BAGE,sCAAA,CACA,mBAAA,CAAA,CAED,iEAND,0BAOE,sCAAA,CACA,mBAAA,CAAA,CAED,2CAVD,0BAWE,sCAAA,CACA,mBAAA,CAAA","sourcesContent":["@use '@planity/ui/styles/utilities/variables';\n\n.title {\n\tmargin: 0;\n\t@media only screen and (max-width: #{variables.$tablet - 1px}) {\n\t\tfont: var(--regular) var(--title-alt-2);\n\t\tpadding-bottom: 32px;\n\t}\n\t@media only screen and (min-width: variables.$tablet) and (max-width: #{variables.$desktop - 1px}) {\n\t\tfont: var(--regular) var(--title-alt-1);\n\t\tpadding-bottom: 24px;\n\t}\n\t@media only screen and (min-width: variables.$desktop) {\n\t\tfont: var(--regular) var(--title-alt-1);\n\t\tpadding-bottom: 24px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"title": "title-module_title-wgrT7"
};
module.exports = ___CSS_LOADER_EXPORT___;
