import React from 'react';
import { Icon, Modal, Spinner } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './waiting_page.module.scss';
import { useTranslation } from '@planity/localization';

// hideText -> simply to display the logo and spinner
export function WaitingPage({ hideText }) {
	const { t } = useTranslation();

	useStyles(styles);
	const classes = classNames.bind(styles)({
		waiting: true
	});

	return (
		<Modal hasCloseBtn={false} isOpen>
			<div className={classes}>
				<div className={styles.redirect}>
					{!process.env.WIDGET && (
						<div className={styles.iconContainer}>
							<Icon icon={'PIcon'} id='icon-p-redirect-page' size={'medium'} />
						</div>
					)}
					<div className={styles.spinner}>
						<Spinner />
					</div>
					{!hideText && (
						<div className={styles.redirectTextContainer}>
							<p className={styles.redirectMainText}>
								{t('redirectPage.mainText')}
							</p>
							<p className={styles.redirectSubText}>
								{t('redirectPage.subText')}
							</p>
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
}
