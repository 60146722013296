import React from 'react';
import { DidomiSDK } from '@didomi/react';
import credentials from '@planity/credentials';
import { useHistory } from 'react-router-dom';
import { useLocalization, useLocalizedRoutes } from '@planity/localization';

import './cookies_management.css';

export function CookiesManagement() {
	const { routes } = useLocalizedRoutes();
	const { language } = useLocalization();
	const history = useHistory();
	return (
		<DidomiSDK
			apiKey={credentials.DIDOMI_PUBLIC_KEY}
			config={{
				app: {
					privacyPolicyURL: `https://www.${
						credentials.HOST + routes.privacyPolicy
					}`
				},
				languages: {
					enabled: [language],
					default: language
				}
			}}
			noticeId={credentials.DIDOMI_NOTICE_ID}
			onPreferencesClickSaveChoices={() => history.push(routes.home)}
			onReady={() => {
				// eslint-disable-next-line no-undef
				Didomi.preferences.show();
			}}
		/>
	);
}
