// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".authentication-module_authentication-jZL8r{height:100%}.authentication-module_authentication-jZL8r .authentication-module_form-Qf1oh,.authentication-module_authentication-jZL8r .authentication-module_member-HxqQb,.authentication-module_authentication-jZL8r .authentication-module_login-27Vl3,.authentication-module_authentication-jZL8r .authentication-module_signup-VWpec,.authentication-module_authentication-jZL8r .authentication-module_finish-2JBrD,.authentication-module_authentication-jZL8r .authentication-module_forget-Df\\+80{height:100%}.authentication-module_authentication-jZL8r .authentication-module_spinner-0WPhU{display:flex;justify-content:center;align-items:center;margin-top:80px}@media(max-width: 768px){.authentication-module_authentication-jZL8r .authentication-module_spinner-0WPhU{margin-top:24px}}", "",{"version":3,"sources":["webpack://./../../packages/components/authentication/authentication.module.scss"],"names":[],"mappings":"AAEA,4CACC,WAAA,CAEA,8dAMC,WAAA,CAGD,iFACC,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA,CACA,yBALD,iFAME,eAAA,CAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.authentication {\n\theight: 100%;\n\n\t.form,\n\t.member,\n\t.login,\n\t.signup,\n\t.finish,\n\t.forget {\n\t\theight: 100%;\n\t}\n\n\t.spinner {\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tmargin-top: 80px;\n\t\t@media (max-width: #{styles.$tablet}) {\n\t\t\tmargin-top: 24px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"authentication": "authentication-module_authentication-jZL8r",
	"form": "authentication-module_form-Qf1oh",
	"member": "authentication-module_member-HxqQb",
	"login": "authentication-module_login-27Vl3",
	"signup": "authentication-module_signup-VWpec",
	"finish": "authentication-module_finish-2JBrD",
	"forget": "authentication-module_forget-Df+80",
	"spinner": "authentication-module_spinner-0WPhU"
};
module.exports = ___CSS_LOADER_EXPORT___;
