import { globalRoutes } from '@planity/credentials/globalRoute';
import { singlePageRoutes } from '@planity/credentials/singlePageRoute';
import {
	AVAILABLE_LOCALES,
	DEFAULT_LOCALE,
	useLocalization
} from '@planity/localization';
import React, { useMemo, createContext, useContext } from 'react';

const LocalizedRoutesContext = createContext();
const LocalizedSinglePageRoutesContext = createContext();

export const useLocalizedRoutes = () => useContext(LocalizedRoutesContext);
export const useLocalizedSinglePageRoutes = () =>
	useContext(LocalizedSinglePageRoutesContext);

export const WithLocalizedRoutes = LocalizedRoutesContext.Consumer;
export const WithLocalizedSinglePageRoutes =
	LocalizedSinglePageRoutesContext.Consumer;

export const withLocalizedRoutes = WrappedComponent => props =>
	(
		<WithLocalizedRoutes>
			{localizedRoutesProps => (
				<WrappedComponent {...localizedRoutesProps} {...props} />
			)}
		</WithLocalizedRoutes>
	);

export const withLocalizedSinglePageRoutes = WrappedComponent => props =>
	(
		<WithLocalizedSinglePageRoutes>
			{localizedSinglePageRoutesProps => (
				<WrappedComponent {...localizedSinglePageRoutesProps} {...props} />
			)}
		</WithLocalizedSinglePageRoutes>
	);

export const LocalizedRoutesContextProvider = ({ children }) => {
	const {
		locale,
		language,
		localizedBasePath: basePath,
		wholeCountrySlug
	} = useLocalization();
	const routes = useMemo(
		() => ({
			...localizedGlobalRoutes(locale),
			business: ({ business }) => `${basePath}/${business.slug}`,
			reservation: ({ business }) => `${basePath}/${business.slug}/reservation`,
			reservationGiftVoucher: ({ business }) =>
				`${basePath}/${business.slug}/reservation_gift_voucher`,
			giftVoucherVariable: ({ business }) =>
				`${basePath}/${business.slug}/gift_voucher_variable`,
			eshop: ({ business }) => `${basePath}/${business.slug}/eshop`,
			onlineShop: ({ business }) =>
				`${basePath}/${business.slug}/boutique_en_ligne`,
			search: ({
				parentCategory,
				category,
				googlePlace,
				parentPlace,
				place,
				bounds,
				page,
				userLocation,
				inWholeCountry
			}) => {
				const boundsSegment = bounds && {
					slug:
						'@' +
						[bounds.ne.lat, bounds.ne.lng, bounds.sw.lat, bounds.sw.lng].join(
							','
						)
				};
				const pageSegment = page && page !== 1 && { slug: `page-${page}` };
				const googlePlaceSegment = googlePlace && {
					slug: `gp_${googlePlace.place_id}`
				};
				const userLocationSegment = userLocation && {
					slug: 'around_me@' + [userLocation.lat, userLocation.lng].join(',')
				};
				const wholeCountry = inWholeCountry && {
					slug: wholeCountrySlug
				};
				return [
					parentCategory,
					category,
					googlePlaceSegment,
					parentPlace,
					place,
					boundsSegment,
					pageSegment,
					userLocationSegment,
					wholeCountry
				].reduce((path, segment) => {
					if (segment) {
						if (segment[`slug_${language}`]) {
							return `${path}/${segment[
								`slug_${language}`.toLowerCase()
							].toLowerCase()}`;
						} else return `${path}/${segment.slug}`;
					} else {
						return path;
					}
				}, basePath);
			},
			catchAll: function ({
				category,
				search,
				business,
				reservation,
				reservationGiftVoucher,
				state,
				inWholeCountry,
				eshopBusiness,
				cacBusiness,
				onlineShop
			}) {
				if (business) {
					return catchAllPath(routes.business({ business }), {
						business,
						from: state?.from ? state.from : null,
						state: state
					});
				} else if (category) {
					return catchAllPath(
						routes.search({ parentCategory: category, inWholeCountry }),
						{
							category
						}
					);
				} else if (reservation) {
					return catchAllPath(
						routes.reservation({ business: reservation.business }),
						state
					);
				} else if (reservationGiftVoucher) {
					return catchAllPath(
						routes.reservationGiftVoucher({
							business: reservationGiftVoucher.business
						}),
						state
					);
				} else if (search) {
					if (
						search.parentCategory &&
						!search.category &&
						!search.parentPlace &&
						!search.place &&
						!search.googlePlace &&
						!search.userLocation
					) {
						return catchAllPath(
							routes.search({
								parentCategory: search.parentCategory
							}),
							{ category: search.parentCategory }
						);
					} else {
						return catchAllPath(routes.search(search), { search });
					}
				} else if (eshopBusiness) {
					return catchAllPath(routes.eshop({ business: eshopBusiness }), state);
				} else if (cacBusiness) {
					// Legacy case, redirecting to onlineShop
					return catchAllPath(
						routes.onlineShop({ business: cacBusiness }),
						state
					);
				} else if (onlineShop) {
					const { business } = onlineShop;
					return catchAllPath(routes.onlineShop({ business }), state);
				}
			}
		}),
		[locale]
	);

	return (
		<LocalizedRoutesContext.Provider value={{ routes }}>
			{children}
		</LocalizedRoutesContext.Provider>
	);
};

export const LocalizedSinglePageRoutesContextProvider = ({ children }) => {
	const { locale } = useLocalization();
	const routes = useMemo(
		() => ({
			...localizedSinglePageRoutes(locale)
		}),
		[locale]
	);

	return (
		<LocalizedSinglePageRoutesContext.Provider value={{ routes }}>
			{children}
		</LocalizedSinglePageRoutesContext.Provider>
	);
};

export function localizedGlobalRoutes(locale) {
	if (!locale || !AVAILABLE_LOCALES.includes(locale))
		return globalRoutes[DEFAULT_LOCALE];

	return globalRoutes[locale] || globalRoutes[DEFAULT_LOCALE];
}

export function localizedSinglePageRoutes(locale) {
	if (!locale || !AVAILABLE_LOCALES.includes(locale))
		return singlePageRoutes[DEFAULT_LOCALE];

	return singlePageRoutes[locale] || singlePageRoutes[DEFAULT_LOCALE];
}

function catchAllPath(pathname, state) {
	const path = Object.create(structuredPath);
	path.pathname = pathname;
	path.state = state;
	return path;
}
const structuredPath = {
	toString() {
		return this.pathname;
	}
};
