import { useFirebaseSubscription } from '@planity/datastores';
import { useAuth, useLastSuccessfulAppointment } from '@planity/components';

export const useConfirmedAppointment = ({ isAllowedToFetchData }) => {
	const { userId } = useAuth();
	const { lastSuccessfulAppointmentId } = useLastSuccessfulAppointment();

	const [
		confirmedAppointment,
		confirmedAppointmentError,
		confirmedAppointmentLoading
	] = useFirebaseSubscription(
		isAllowedToFetchData && {
			path:
				userId &&
				lastSuccessfulAppointmentId &&
				`user_vevents/${userId}/${lastSuccessfulAppointmentId}`
		}
	);

	if (process.env.NODE_ENV === 'development' && confirmedAppointmentError) {
		console.warn({ confirmedAppointmentError });
	}
	return {
		confirmedAppointment,
		confirmedAppointmentLoading,
		confirmedAppointmentId: lastSuccessfulAppointmentId
	};
};
