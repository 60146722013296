export function Search() {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M18 18L14.8966 14.8966M6 11.1724C6 8.31577 8.31577 6 11.1724 6C14.0291 6 16.3448 8.31577 16.3448 11.1724C16.3448 14.0291 14.0291 16.3448 11.1724 16.3448C8.31577 16.3448 6 14.0291 6 11.1724Z'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
