import { SearchContext } from '@planity/context';
import classNames from 'classnames/bind';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Container, Tag, useModal, Select, Icon } from '@planity/ui';
import styles from './search_tags.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { AlgoliaSearch } from 'planity-website/app/components/data';
import credentials from '@planity/credentials';
import { safeRead } from '@planity/helpers';
import { sendPickServiceFromTagEvent } from '@planity/helpers/analytics';
import { useTranslation } from '@planity/localization';

export function SearchTags() {
	useStyles(styles);

	const { closeModal, isOpen } = useModal();
	const { parentCategory, category, setSearch, ...search } =
		useContext(SearchContext);
	const [currentCategory, setCurrentCategory] = useCurrentCategory(category);
	const algoliaParams = useAlgoliaParams(parentCategory, category);
	const isMounted = useRerenderOnMount();
	const { t } = useTranslation();

	const scrollLeft = useRef();
	const scrollRight = useRef();

	const [selectedCat, setSelectedCat] = useState(null);
	const [initialCat, setInitialCat] = useState(category);

	const onSelectCat = useMemo(
		() => cat => {
			setCurrentCategory(cat);
			sendPickServiceFromTagEvent();
			setTimeout(() => {
				setSearch(
					{
						...search,
						parentCategory: parentCategory,
						category: cat
					},
					undefined,
					true
				);
			}, 100);
		},
		[search, parentCategory, setCurrentCategory, setSearch]
	);

	useEffect(() => {
		setInitialCat(category);
	}, [isOpen]);

	const classes = classNames.bind(styles)({
		searchTags: true
	});

	return (
		<AlgoliaSearch {...algoliaParams}>
			{({ data, isLoading }) => {
				return (
					<WithSuggestions
						data={data}
						isLoading={isLoading}
						currentCategoryId={currentCategory?.categoryId}
					>
						{({ suggestions, parentSuggestion }) => {
							const showAllTags =
								currentCategory?.categoryId === parentSuggestion?.parentTag ||
								parentSuggestion?.searchTags.includes(
									currentCategory?.categoryId
								);
							const hasParentSuggestions = !!suggestions?.parentTags?.length;
							const catSelect = [
								!!parentCategory && { ...parentCategory, name: t('tags.all') }
							]
								.filter(x => !!x)
								.concat(suggestions?.parentTags);
							const isBrand = currentCategory?.isBrand;

							return (
								<>
									<div className={`${styles.header}`}>
										<span className={styles.title}>
											{t('search.tabs.service')}
										</span>
										<Icon
											icon={'Close'}
											size={'medium'}
											onClick={() => {
												onSelectCat(initialCat);
												setSelectedCat(null);
												setTimeout(() => {
													closeModal();
												}, 400);
											}}
											className={styles.icon}
										/>
									</div>
									<div className={classes}>
										<div className={`${styles.mobile}`}>
											<Icon
												icon={'Close'}
												size={'medium'}
												onClick={() => {
													onSelectCat(initialCat);
													setSelectedCat(null);
													setTimeout(() => {
														closeModal();
													}, 400);
												}}
												className={styles.icon}
											/>
										</div>
										<div className={`${styles.top} ${styles.mobile}`}>
											<span className={styles.title}>
												{t('search.tabs.service')}
											</span>
											<Button
												className={styles.reset}
												label={t('search.filter.reset')}
												type='linked'
												onClick={() => {
													closeModal();
													onSelectCat({
														...parentCategory,
														name: t('tags.all')
													});
												}}
											/>
										</div>

										<div className={styles.list}>
											{hasParentSuggestions && !isBrand && showAllTags && (
												<>
													<div
														className={`${styles.tablet} ${styles.selectWrapper}`}
													>
														<Select
															items={catSelect}
															value={parentSuggestion?.name || t('tags.all')}
															onSelect={onSelectCat}
															isRadio
															className={styles.selectedTag}
														/>
													</div>
													{suggestions?.childrenTags && (
														<hr className={styles.divider} />
													)}
												</>
											)}
											<div>
												{isMounted &&
													!isBrand &&
													suggestions?.childrenTags?.length > 0 && (
														<Tag
															closeModal={() => {}}
															showAllTags={showAllTags}
															currentCategoryId={currentCategory?.categoryId}
															currentCategoryName={category?.name}
															suggestions={suggestions}
															parentSuggestion={parentSuggestion}
															hasParentSuggestions={hasParentSuggestions}
															catSelect={catSelect}
															onSelect={item => {
																onSelectCat(item);
																if (item?.name) setSelectedCat(item);
															}}
															scrollLeft={scrollLeft}
															scrollRight={scrollRight}
														/>
													)}
											</div>
										</div>

										<Container className={`${styles.bottom} ${styles.desktop}`}>
											<Button
												className={`${styles.tabletOnly} ${styles.reset}`}
												label={t('search.filter.reset')}
												type='linked'
												onClick={() => {
													closeModal();
													onSelectCat({
														...parentCategory,
														name: t('tags.all')
													});
												}}
											/>
											<Button
												className={`${styles.tabletOnly} ${styles.reset}`}
												label={t('search.filter.save')}
												type='primary'
												onClick={() => {
													closeModal();
													setInitialCat(selectedCat);
												}}
											/>
											<Button
												className={`${styles.mobile} ${styles.reset}`}
												label={t('search.filter.save')}
												isFullMobile
												onClick={() => {
													closeModal();
													setInitialCat(selectedCat);
												}}
											/>
										</Container>
									</div>
								</>
							);
						}}
					</WithSuggestions>
				);
			}}
		</AlgoliaSearch>
	);
}

function useRerenderOnMount() {
	const [mounted, setMounted] = useState(false);
	useEffect(() => {
		setMounted(true);
	}, []);
	return mounted;
}

function useCurrentCategory(category) {
	const categoryId = (category && category.objectID) || null;
	const isBrand = (category && category.isBrand) || null;
	const [currentCategory, setCurrentCategory] = useState(categoryId);
	useEffect(() => {
		if (categoryId) {
			setCurrentCategory({ categoryId, isBrand });
		}
	}, [categoryId, isBrand]);
	return [
		currentCategory,
		cat =>
			setCurrentCategory({
				categoryId: (cat && cat.objectID) || null,
				isBrand: (cat && cat.isBrand) || null
			})
	];
}

function useAlgoliaParams(parentCategory, category) {
	return useMemo(() => {
		if (!category) {
			return {
				queries: {
					categories: {
						index: credentials.CATEGORIES_INDEX,
						params: {
							hitsPerPage: 10,
							page: 0,
							filters: 'NOT isAdjective:true AND NOT disabled:true'
						}
					}
				},
				localizeResults: true,
				format: response => {
					return {
						childrenTags: (
							safeRead(response, [credentials.CATEGORIES_INDEX, 'data']) || []
						)
							.filter(cat => cat.suggest)
							.sort((catA, catB) => catA.suggest - catB.suggest)
					};
				}
			};
		}
		const isBrand = category?.isBrand;
		const parentSearchTags =
			(parentCategory && parentCategory.searchTags) || [];
		const searchTags = category.searchTags || [];
		if (category.depth === 1) {
			return {
				getObjects: {
					index: credentials.CATEGORIES_INDEX,
					objectIDs: searchTags
				},
				localizeResults: true,
				format: response => ({
					childrenTags:
						safeRead(response, [credentials.CATEGORIES_INDEX, 'data']) || []
				})
			};
		}
		if (category.depth === 2) {
			return {
				getObjects: {
					index: credentials.CATEGORIES_INDEX,
					objectIDs: searchTags
						.concat(parentSearchTags)
						.concat([category.parentTag].filter(x => !!x))
				},
				localizeResults: true,
				format: response => {
					const byId = (
						safeRead(response, [credentials.CATEGORIES_INDEX, 'data']) || []
					).reduce((a, x) => {
						a[x.objectID] = x;
						return a;
					}, {});
					return {
						parentTags: parentSearchTags.map(id => byId[id]).filter(x => !!x),
						childrenTags: isBrand
							? parentSearchTags.map(id => byId[id]).filter(x => !!x)
							: searchTags.map(id => byId[id]).filter(x => !!x),
						parentTag: byId[category.parentTag] || category
					};
				}
			};
		}
	}, [parentCategory, category]);
}

function useSuggestions(data, isLoading, currentCategoryId) {
	const [suggestions, setSuggestions] = useState(null);
	useEffect(() => {
		if (!isLoading) {
			setSuggestions(data);
		}
	}, [data, isLoading]);
	const parentSuggestion = useMemo(() => {
		if (!suggestions?.parentTags?.length) {
			return undefined;
		}
		const current = suggestions.parentTags.find(
			x => x.objectID === currentCategoryId
		);
		if (current) {
			return current;
		}
		return suggestions.parentTags.find(x =>
			(x.searchTags || []).includes(currentCategoryId)
		);
	}, [suggestions, currentCategoryId]);
	return [suggestions, parentSuggestion];
}

function WithSuggestions({ data, isLoading, currentCategoryId, children }) {
	const [suggestions, parentSuggestion] = useSuggestions(
		data,
		isLoading,
		currentCategoryId
	);
	return children({ suggestions, parentSuggestion });
}
