// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title-module_title-IOU-w{padding:24px 16px}@media(min-width: 768px){.title-module_title-IOU-w{padding:24px}}.title-module_title-IOU-w .title-module_titleSearch-6Egt9{font:var(--semibold) var(--body-3);margin:0}@media only screen and (max-width: 767px){.title-module_title-IOU-w .title-module_titleSearch-6Egt9{font:var(--medium) var(--body-2)}}.title-module_title-IOU-w .title-module_subtitle-QdkKv{margin:0;font:var(--regular) var(--body-3);color:var(--grey-600)}@media only screen and (max-width: 767px){.title-module_title-IOU-w .title-module_subtitle-QdkKv{font:var(--regular) var(--body-4)}}", "",{"version":3,"sources":["webpack://./app/components/search_page/title.module.scss"],"names":[],"mappings":"AAEA,0BACC,iBAAA,CACA,yBAFD,0BAGE,YAAA,CAAA,CAGD,0DAIC,kCAAA,CACA,QAAA,CAJA,0CADD,0DAEE,gCAAA,CAAA,CAMF,uDAIC,QAAA,CACA,iCAAA,CACA,qBAAA,CALA,0CADD,uDAEE,iCAAA,CAAA","sourcesContent":["@use '@planity/ui/styles/utilities/variables';\n\n.title {\n\tpadding: 24px 16px;\n\t@media (min-width: variables.$tablet) {\n\t\tpadding: 24px;\n\t}\n\n\t.titleSearch {\n\t\t@media only screen and (max-width: #{variables.$tablet - 1px}) {\n\t\t\tfont: var(--medium) var(--body-2);\n\t\t}\n\t\tfont: var(--semibold) var(--body-3);\n\t\tmargin: 0;\n\t}\n\n\t.subtitle {\n\t\t@media only screen and (max-width: #{variables.$tablet - 1px}) {\n\t\t\tfont: var(--regular) var(--body-4);\n\t\t}\n\t\tmargin: 0;\n\t\tfont: var(--regular) var(--body-3);\n\t\tcolor: var(--grey-600);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"title": "title-module_title-IOU-w",
	"titleSearch": "title-module_titleSearch-6Egt9",
	"subtitle": "title-module_subtitle-QdkKv"
};
module.exports = ___CSS_LOADER_EXPORT___;
