import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './breadcrumbs.module.scss';
import { Icon } from '../../images';

export const BreadCrumbIcon = () => {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		breadcrumbs: true
	});

	return (
		<Icon className={`${classes} ${styles.iconLeft}`} icon={'ChevronRight'} />
	);
};
