import { actions, assign, send, createMachine } from 'xstate';
import { buyCACProducts } from '../buyCACProducts';
import { CAC_FLOW_MACHINE_STATES } from './cacFlowMachineStates';

const { escalate, choose } = actions;

export const cacFlowMachine = createMachine(
	{
		predictableActionArguments: true,
		id: 'cacFlowMachine',
		initial: CAC_FLOW_MACHINE_STATES.AUTHENTICATION,
		context: {
			data: {
				products: [],
				paymentMethod: null,
				userId: null,
				businessId: null,
				isClickAndCollect: null,
				isClickAndShop: null,
				deliveryAddress: {},
				billingAddress: {},
				shippingFees: 0,
				totalPrice: 0,
				error: null
			}
		},
		on: {
			UPDATE_PRODUCTS: {
				actions: ['updateCart', 'computePrice', 'checkCartIsEmpty']
			},
			EMPTY_CART: {
				target: CAC_FLOW_MACHINE_STATES.CART
			},
			LOGOUT: {
				target: CAC_FLOW_MACHINE_STATES.AUTHENTICATION,
				actions: ['disconnectUser']
			},
			CANCEL_FLOW: {
				target: CAC_FLOW_MACHINE_STATES.CANCEL
			},
			UPDATE_PAYMENT_METHOD: {
				actions: ['updatePaymentMethod']
			},
			UPDATE_PAYMENT_DATA: {
				actions: ['updatePaymentMethod', 'savePaymentIntentId']
			},
			UPDATE_ADDRESSES: {
				actions: ['addUserAddresses']
			},
			UPDATE_IS_CLICK_AND_SHOP: {
				actions: ['updateIsClickAndShop']
			},
			UPDATE_SHIPPING_FEES: {
				actions: ['updateShippingFees', 'computePrice']
			},
			CONFIRM_PAYMENT_REDIRECT_SUCCESS: {
				target: CAC_FLOW_MACHINE_STATES.CONFIRMED,
				actions: ['confirmPaymentRedirectSuccess']
			},
			SET_IS_REDIRECT: {
				actions: ['setIsRedirect']
			}
		},
		states: {
			[CAC_FLOW_MACHINE_STATES.CART]: {
				on: {
					ADD_PRODUCT: {
						target: CAC_FLOW_MACHINE_STATES.AUTHENTICATION,
						actions: ['updateCart', 'computePrice']
					}
				}
			},
			[CAC_FLOW_MACHINE_STATES.AUTHENTICATION]: {
				on: {
					AUTHENTICATED: [
						{
							cond: 'needDeliveryForm',
							target: CAC_FLOW_MACHINE_STATES.DELIVERY_FORM,
							actions: ['addUserId', 'updateIsClickAndShop']
						},
						{
							target: CAC_FLOW_MACHINE_STATES.CREDIT_CARD,
							actions: ['addUserId', 'updateIsClickAndShop']
						}
					]
				}
			},
			[CAC_FLOW_MACHINE_STATES.DELIVERY_FORM]: {
				on: {
					UPDATE_ADDRESSES: {
						target: CAC_FLOW_MACHINE_STATES.CREDIT_CARD,
						actions: ['addUserAddresses']
					},
					UPDATE_IS_CLICK_AND_SHOP: [
						{
							cond: 'needDeliveryForm',
							target: CAC_FLOW_MACHINE_STATES.DELIVERY_FORM
						},
						{
							target: CAC_FLOW_MACHINE_STATES.AUTHENTICATION
						}
					]
				}
			},
			[CAC_FLOW_MACHINE_STATES.CREDIT_CARD]: {
				on: {
					CREDIT_CARD_OK: CAC_FLOW_MACHINE_STATES.CAN_PAY
				}
			},
			[CAC_FLOW_MACHINE_STATES.CAN_PAY]: {
				initial: 'normal',
				states: {
					normal: {},

					// Tried to pay but something went wrong
					error: {}
				},
				on: {
					UPDATE_ADDRESS: { target: CAC_FLOW_MACHINE_STATES.DELIVERY_FORM },
					PAY: {
						target: CAC_FLOW_MACHINE_STATES.PAYING,
						actions: ['addUser']
					},
					UPDATE_IS_CLICK_AND_SHOP: [
						{
							cond: 'needDeliveryForm'
						},
						{
							target: CAC_FLOW_MACHINE_STATES.AUTHENTICATION
						}
					],
					RESET_PAY_ERROR: {
						target: `${CAC_FLOW_MACHINE_STATES.CAN_PAY}.normal`,
						actions: ['resetError']
					},
					SUBMIT: {
						target: CAC_FLOW_MACHINE_STATES.PAYING,
						actions: ['savePaymentIntentId']
					},
					LOGOUT: { target: CAC_FLOW_MACHINE_STATES.AUTHENTICATION },
					AUTH_FAIL: {
						target: '.error',
						actions: ['onError']
					}
				}
			},
			[CAC_FLOW_MACHINE_STATES.PAYING]: {
				invoke: {
					id: buyCACProducts.name,
					src: context => buyCACProducts(context.data),
					onDone: {
						target: CAC_FLOW_MACHINE_STATES.CONFIRMED
					},
					onError: [
						{
							target: `${CAC_FLOW_MACHINE_STATES.CAN_PAY}.error`,
							actions: assign({
								data: context => ({
									...context.data,
									error: { errorPaying: true },
									paymentMethod: null,
									paymentIntentId: null
								})
							})
						}
					]
				}
			},
			[CAC_FLOW_MACHINE_STATES.CONFIRMED]: {
				// Triggers a success event for the parent machine
				type: 'final'
			},
			[CAC_FLOW_MACHINE_STATES.CANCEL]: {
				// Triggers an error event for the parent machine
				entry: escalate({ message: 'CANCEL_CAC_FLOW' })
			}
		}
	},
	{
		actions: {
			updateCart: assign({
				data: (ctx, { products }) => ({
					...ctx.data,
					products
				})
			}),
			updatePaymentMethod: assign({
				data: (ctx, { paymentMethod }) => ({
					...ctx.data,
					paymentMethod
				})
			}),
			disconnectUser: assign({
				data: ctx => ({
					...ctx.data,
					user: null,
					paymentMethod: null,
					userId: null
				})
			}),
			checkCartIsEmpty: choose([
				{
					cond: ctx => ctx.data.products.length <= 0,
					// only triggers if the condition above is true
					actions: 'cartEmpty'
				}
			]),
			cartEmpty: send('EMPTY_CART'),
			cancelFlow: send('CANCEL_FLOW'),
			addUser: assign({
				data: (ctx, { user }) => ({
					...ctx.data,
					user
				})
			}),
			addUserId: assign({
				data: (ctx, { userId }) => ({
					...ctx.data,
					userId
				})
			}),
			addUserAddresses: assign({
				data: (ctx, { deliveryAddress, billingAddress }) => ({
					...ctx.data,
					deliveryAddress,
					billingAddress
				})
			}),
			updateIsClickAndShop: assign({
				data: (ctx, { isClickAndShop }) => ({
					...ctx.data,
					isClickAndShop,
					isClickAndCollect: !isClickAndShop
				})
			}),
			updateShippingFees: assign({
				data: (ctx, { shippingFees }) => ({
					...ctx.data,
					shippingFees: shippingFees || 0
				})
			}),
			computePrice: assign({
				data: ctx => {
					const { products, shippingFees } = ctx.data;
					const price = products.reduce(
						(sum, product) => sum + product.price * product.quantity,
						shippingFees || 0
					);
					return {
						...ctx.data,
						totalPrice: price
					};
				}
			}),
			handleError: assign({
				error: (context, { event }) => ({
					...context.errors,
					errorPaying: event.data
				})
			}),
			savePaymentIntentId: assign({
				data: (context, event) => ({
					...context.data,
					paymentIntentId:
						context?.data?.paymentIntentId ||
						event?.paymentIntentId ||
						event?.data?.paymentIntentId ||
						null
				})
			}),
			resetPaymentData: assign({
				data: (context, event) => ({
					...context.data,
					paymentMethod: null,
					paymentIntentId: null
				})
			}),
			resetError: assign({
				data: (context, event) => ({
					...context.data,
					error: null
				})
			}),
			confirmPaymentRedirectSuccess: assign({
				data: (context, { paymentIntentId }) => ({
					...context.data,
					paymentIntentId
				})
			}),
			setIsRedirect: assign({
				data: (context, { isRedirectValue }) => ({
					...context.data,
					isRedirect: isRedirectValue
				})
			})
		},
		guards: {
			needDeliveryForm: (ctx, event) => !!event.isClickAndShop
		}
	}
);
