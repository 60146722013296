import {
	Button,
	Form,
	Inputs,
	Link,
	Localize,
	PhoneInput
} from '@planity/components';
import React from 'react';
import {
	PHONE_NUMBER_CHANGE,
	PHONE_NUMBER_SUBMIT,
	RESET_MACHINE
} from '../../auth_actions';
import { useFormHooks } from '../useFormHooks';

export const MissingPhoneNumber = () => {
	const { state, dispatch, formValues } = useFormHooks();
	const { pending, children, defaultCountry, phoneInput } = formValues;
	return (
		<Form css={css.container}>
			{children}
			<p>
				<Localize text={'auth.texts.fillMissingData'} />
			</p>
			<Inputs css={css.inputs}>
				<PhoneInput
					className={css.inputs}
					defaultCountry={defaultCountry}
					forwardedRef={phoneInput}
					localizedPlaceholder={'auth.form.placeholders.phoneNumber'}
					value={state?.context?.phoneNumber || ''}
					onChange={(phoneNumberInput, value) =>
						dispatch({
							type: PHONE_NUMBER_CHANGE,
							phoneNumber: phoneNumberInput,
							phoneNumberFormat: value
						})
					}
				/>
			</Inputs>
			<div>
				<Button
					css={css.button}
					isActive={pending}
					localizedText={
						pending
							? 'auth.form.buttons.pending'
							: 'auth.form.buttons.acceptAndContinue'
					}
					onPress={() => dispatch({ type: PHONE_NUMBER_SUBMIT })}
				/>
			</div>
			<div css={css.bottomLink}>
				<Link
					localizedText={`auth.form.links.BACK_TO_SIGN_IN`}
					onPress={() => dispatch({ type: RESET_MACHINE })}
				/>
			</div>
		</Form>
	);
};
const css = {
	inputs: {
		flex: 1
	},
	button: {
		marginBottom: 18,
		width: '100%'
	},
	bottomLink: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'center',
		fontSize: 16,
		lineHeight: 1.13
	}
};
