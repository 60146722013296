import { useLocalization } from '@planity/localization';
import React from 'react';
import { format } from 'date-fns';
import { breakpoints, colors, fontSize } from '@planity/theme';
import { Localize } from '@planity/components';

export default function AppointmentSliderNextAvailabilityOverlay({
	availabilities,
	days,
	currentDayIndex,
	currentDays,
	pageSize,
	navToDay
}) {
	const { locale } = useLocalization();

	const currentDaysHaveAvailabilities = !!currentDays.find(day => {
		return !hasNoAvailabilities(availabilities, day);
	});
	if (currentDaysHaveAvailabilities) {
		return <div css={[styles.alert, styles.alertPlaceholder]} />;
	} else {
		const prevDays = days.slice(0, currentDayIndex);
		const nextDays = days.slice(currentDayIndex + pageSize);
		const prevAvailabilityDay = prevDays.reduceRight((prev, day) => {
			if (!prev) {
				prev = !hasNoAvailabilities(availabilities, day) ? day : null;
			}
			return prev;
		}, null);
		const nextAvailabilityDay = nextDays.find(
			day => !hasNoAvailabilities(availabilities, day)
		);
		const dateFormatter = new Intl.DateTimeFormat(locale, {
			day: '2-digit',
			month: 'long',
			year: 'numeric'
		});

		if (prevAvailabilityDay || nextAvailabilityDay) {
			return (
				<div css={[styles.alert, styles.splitAlert]}>
					<div css={styles.alertPart}>
						{prevAvailabilityDay ? (
							<div css={styles.text}>
								<Localize text={'bookAppointment.prevAvailability'} />{' '}
								<a
									css={styles.link}
									onClick={() => navToDay(prevAvailabilityDay)}
								>
									{dateFormatter.format(prevAvailabilityDay)}
								</a>
							</div>
						) : (
							<Localize text={'bookAppointment.weekOnlyStaleAvailabilities'} />
						)}
					</div>
					<div css={styles.alertPart}>
						{nextAvailabilityDay ? (
							<div css={styles.text}>
								<Localize text={'bookAppointment.nextAvailability'} />{' '}
								<a
									css={styles.link}
									onClick={() => navToDay(nextAvailabilityDay)}
								>
									{dateFormatter.format(nextAvailabilityDay)}
								</a>
							</div>
						) : (
							<Localize text={'bookAppointment.weekOnlyStaleAvailabilities'} />
						)}
					</div>
				</div>
			);
		} else {
			return (
				<div css={[styles.alert, styles.singleAlert]}>
					<Localize text={'bookAppointment.onlyStaleAvailabilities'} />
				</div>
			);
		}
	}
}

const hasNoAvailabilities = (availabilities, day) => {
	const key = format(day, 'yyyy-MM-dd');
	return !((availabilities || {})[key] || []).find(({ isStale }) => !isStale);
};

const styles = {
	alertPlaceholder: {
		opacity: 0,
		pointerEvents: 'none',
		transition: 'opacity .5s'
	},
	alert: {
		borderBottom: `1px solid ${colors.grey.border}`,
		position: 'absolute',
		top: 80,
		right: 0,
		left: 0,
		fontSize: fontSize.regular,
		opacity: 1,
		pointerEvents: 'auto',
		backgroundColor: 'white'
	},
	singleAlert: {
		padding: 20,
		textAlign: 'center'
	},
	splitAlert: {
		display: 'flex'
	},
	alertPart: {
		'display': 'flex',
		'alignItems': 'center',
		'width': '50%',
		'padding': 20,
		'justifyContent': 'center',
		'& > div': {
			display: 'flex',
			flexDirection: 'column',
			[breakpoints.tabletQuery]: {
				'flexDirection': 'row',
				'& > a': {
					paddingLeft: 5
				}
			}
		}
	},
	link: {
		textDecoration: 'underline',
		cursor: 'pointer',
		color: 'var(--primary-200)'
	},
	text: {
		font: 'var(--medium) var(--body-4)'
	}
};
