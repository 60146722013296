import React from 'react';

export const NZ = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5875)'>
				<mask
					id='mask0_11534_5875'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5875)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#2E42A5'
					/>
					<mask
						id='mask1_11534_5875'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_11534_5875)'>
						<rect width='11' height='9' fill='#2E42A5' />
						<mask
							id='mask2_11534_5875'
							style={{ maskType: 'luminance' }}
							maskUnits='userSpaceOnUse'
							x='0'
							y='0'
							width='11'
							height='9'
						>
							<rect width='11' height='9' fill='white' />
						</mask>
						<g mask='url(#mask2_11534_5875)'>
							<path
								d='M-1.25267 8.12491L1.22292 9.21066L11.3062 1.18049L12.612 -0.432968L9.9647 -0.795857L5.85198 2.66457L2.54167 4.9961L-1.25267 8.12491Z'
								fill='#F7FCFF'
							/>
							<path
								d='M-0.913757 8.88561L0.347444 9.51569L12.1431 -0.582886H10.3722L-0.913757 8.88561Z'
								fill='#F50100'
							/>
							<path
								d='M12.5027 8.1249L10.3056 9.5206L-0.0561714 1.18047L-1.36199 -0.432984L1.2853 -0.795875L5.39802 2.66456L8.70833 4.99608L12.5027 8.1249Z'
								fill='#F7FCFF'
							/>
							<path
								d='M12.4182 8.67085L11.157 9.30093L6.13429 4.97721L4.64517 4.49413L-1.48764 -0.427451H0.283231L6.4126 4.37731L8.04069 4.95658L12.4182 8.67085Z'
								fill='#F50100'
							/>
							<mask
								id='path-12-outside-1_11534_5875'
								maskUnits='userSpaceOnUse'
								x='-1'
								y='-1'
								width='13'
								height='11'
								fill='black'
							>
								<rect fill='white' x='-1' y='-1' width='13' height='11' />
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M6 0H5V4H0V5H5V9H6V5H11V4H6V0Z'
								/>
							</mask>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M6 0H5V4H0V5H5V9H6V5H11V4H6V0Z'
								fill='#F50100'
							/>
							<path
								d='M5 0V-0.9375H4.0625V0H5ZM6 0H6.9375V-0.9375H6V0ZM5 4V4.9375H5.9375V4H5ZM0 4V3.0625H-0.9375V4H0ZM0 5H-0.9375V5.9375H0V5ZM5 5H5.9375V4.0625H5V5ZM5 9H4.0625V9.9375H5V9ZM6 9V9.9375H6.9375V9H6ZM6 5V4.0625H5.0625V5H6ZM11 5V5.9375H11.9375V5H11ZM11 4H11.9375V3.0625H11V4ZM6 4H5.0625V4.9375H6V4ZM5 0.9375H6V-0.9375H5V0.9375ZM5.9375 4V0H4.0625V4H5.9375ZM0 4.9375H5V3.0625H0V4.9375ZM0.9375 5V4H-0.9375V5H0.9375ZM5 4.0625H0V5.9375H5V4.0625ZM5.9375 9V5H4.0625V9H5.9375ZM6 8.0625H5V9.9375H6V8.0625ZM5.0625 5V9H6.9375V5H5.0625ZM11 4.0625H6V5.9375H11V4.0625ZM10.0625 4V5H11.9375V4H10.0625ZM6 4.9375H11V3.0625H6V4.9375ZM5.0625 0V4H6.9375V0H5.0625Z'
								fill='#F7FCFF'
								mask='url(#path-12-outside-1_11534_5875)'
							/>
						</g>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M15.5332 12.8125L14.4311 13.3919L14.6416 12.1647L13.75 11.2956L14.9822 11.1165L15.5332 10L16.0843 11.1165L17.3165 11.2956L16.4248 12.1647L16.6353 13.3919L15.5332 12.8125Z'
							fill='#F50100'
							stroke='#F7FCFF'
							strokeWidth='0.5'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M13.25 8.66864L12.4002 9.11541L12.5625 8.16914L11.875 7.499L12.8251 7.36094L13.25 6.5L13.6749 7.36094L14.625 7.499L13.9375 8.16914L14.0998 9.11541L13.25 8.66864Z'
							fill='#F50100'
							stroke='#F7FCFF'
							strokeWidth='0.5'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M17.6235 8.66623L16.7746 9.1125L16.9367 8.16729L16.25 7.49789L17.199 7.35998L17.6235 6.5L18.0479 7.35998L18.9969 7.49789L18.3102 8.16729L18.4723 9.1125L17.6235 8.66623Z'
							fill='#F50100'
							stroke='#F7FCFF'
							strokeWidth='0.5'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M15.75 5.16864L14.9002 5.61541L15.0625 4.66914L14.375 3.999L15.3251 3.86094L15.75 3L16.1749 3.86094L17.125 3.999L16.4375 4.66914L16.5998 5.61541L15.75 5.16864Z'
							fill='#F50100'
							stroke='#F7FCFF'
							strokeWidth='0.5'
						/>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5875'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
