import { getStepInfo } from '@planity/helpers';

/**
 * Get CSS transition time of an element
 * @param {node} $el
 * @returns float
 */

export function getTransitionDuration($el) {
	var properties = [
		'transition',
		'WebkitTransition',
		'msTransition',
		'MozTransition',
		'OTransition'
	];
	var p;
	while ((p = properties.shift())) {
		if (typeof $el.style[p] != 'undefined') {
			const d = window.getComputedStyle($el)[p + '-duration'];
			return d.includes('ms') ? parseFloat(d) : parseFloat(d) * 1000;
		}
	}
	return 0;
}
export const realIndex = (index, length) => {
	const i = index % length;
	return i < 0 ? i + length : i;
};
/**
 *  That's a legacy way of computing price.
 *  It's because user_vevents/{uid} gets partially deleted by pro-app (that's
 *  a bug). So we can't rely on it. So we check in the business services to have
 *  the price, and duration etc
 * @param appointment
 * @param business
 * @param t { function } the translation function
 * @return {number} in cents
 */

export const uglyPriceComputation = (appointment, business, t) =>
	Object.values(appointment.sequence || {}).reduce((total, sequence) => {
		const { price } = getStepInfo(sequence, business, t);
		if (!price) return total;
		// first method should work.
		// Second method should work for prices "between x and y €". Stolen from user-app
		const parsedPrice = parseFloat(
			price
				.replace('€', '')
				.replace('&euro;', '')
				.replace(/,/g, '.')
				.match(/([\d.]+)/) && RegExp.$1
		);
		return price && !isNaN(parsedPrice) ? total + parsedPrice : total;
	}, 0) * 100;
