import React, { forwardRef } from 'react';
import { colors } from '@planity/theme';
import { EmailIcon, TextInput } from '@planity/components';

const EmailInput = forwardRef(
	(
		{
			value,
			onChange,
			localizedPlaceholder,
			autofocus,
			className,
			disableIcon,
			...props
		},
		ref
	) => (
		<div css={[css.emailInputContainer]}>
			{!disableIcon && (
				<EmailIcon color={colors.grey.brownGrey} css={css.emailIcon} />
			)}
			<TextInput
				autoFocus={autofocus}
				css={css.emailInput}
				inputMode={'email'}
				localizedPlaceholder={localizedPlaceholder}
				ref={ref}
				value={value}
				onChange={onChange}
				{...props}
			/>
		</div>
	)
);

const css = {
	emailInputContainer: {
		position: 'relative',
		width: '100%'
	},
	emailInput: {
		padding: '12px 50px 12px 12px !important'
	},
	emailIcon: {
		position: 'absolute',
		top: 9,
		right: 9,
		width: 24,
		height: 24,
		border: 'none',
		outline: 'none',
		backgroundColor: 'transparent',
		WebkitAppearance: 'initial !important'
	}
};

export default EmailInput;
