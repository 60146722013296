import React from 'react';
import classNames from 'classnames/bind';
import styles from './search_filters.module.scss';
import { Accordion, Button, Container, Radio, useModal } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import {
	CLEAN,
	DATE,
	dateIdMap,
	getDateByDateId,
	kindOfFilter,
	PUSH
} from '@planity/helpers';
import { useTranslation } from '@planity/localization';
import {
	sendPickDateFromLabelEvent,
	sendPickDateFromNumberEvent
} from '@planity/helpers/analytics';
import { DatePicker } from '@planity/components';
import { useSearchFilter } from '@planity/ui';

export function SearchFilters({ onlyDate }) {
	useStyles(styles);

	const { closeModal } = useModal();
	const { t } = useTranslation();

	const { dispatch, setLocalFilters, localFilters } = useSearchFilter();

	const classes = classNames.bind(styles)({
		searchFilters: true,
		onlyDate
	});
	const filtersList = onlyDate
		? Object.entries(kindOfFilter).filter(([key, { type }]) => type === DATE)
		: Object.entries(kindOfFilter);
	const accordionList = [
		{
			isCompact: true,
			canToggle: true,
			isMobile: true,
			hasRadio: false
		},
		{
			isCompact: true,
			canToggle: false,
			isMobile: false,
			hasRadio: true
		}
	];
	return (
		<div className={classes}>
			<Container className={styles.container}>
				<div className={`${styles.top} ${styles.mobile}`}>
					<span className={styles.title}>
						{t('search.filter.titleResponsive')}
					</span>
					<Button
						className={styles.reset}
						label={t('search.filter.reset')}
						type='linked'
						onClick={() => {
							setLocalFilters({ type: CLEAN });
							dispatch({
								type: PUSH,
								payload: {
									byDate: 0,
									sort: 0,
									data: {
										date: null
									}
								}
							});
							closeModal();
						}}
					/>
				</div>

				<ul className={styles.filters}>
					{filtersList.map(([key, { label, type, range }], index) => {
						const currentSelectedDate = localFilters.data.date;
						const currentSelectedId = localFilters[key];
						const onPickDate = date => {
							setLocalFilters({ type: type, id: range[3].id, date });
						};

						return (
							<li
								key={index}
								className={classNames.bind(styles)({
									bloc: true,
									currentSelected: currentSelectedId > 0
								})}
							>
								{accordionList.map((accordion, i) => (
									<Accordion
										key={i}
										title={!onlyDate && t(label)}
										isCompact={accordion.isCompact}
										canToggle={accordion.canToggle}
										isVisible={onlyDate}
										className={classNames.bind(styles)({
											mobile: accordion.isMobile,
											tablet: !accordion.isMobile,
											filter: true
										})}
									>
										<div className={styles.list}>
											{range.map(({ id, name }, i) => {
												const isDateFilter = type === DATE;
												const isDatePickerField =
													isDateFilter && id === dateIdMap.openDatepicker;

												const dateFilterCallback = () => {
													// send GA event when date has been chosen via the labels and not via the datepicker
													if (!isDatePickerField) {
														sendPickDateFromLabelEvent();
													}
													setLocalFilters({
														type: type,
														id,
														date: getDateByDateId(id)
													});
												};

												const onClick = () => {
													// if date picker is opened, but you validate without picking a value: date filter is reset
													isDateFilter
														? dateFilterCallback()
														: setLocalFilters({ type: type, id });
												};

												return accordion.hasRadio ? (
													<Radio
														key={name}
														name={type}
														label={t(name)}
														isChecked={currentSelectedId === id}
														onChange={() => onClick()}
														labelClassName={styles.range}
													/>
												) : (
													<button
														className={classNames.bind(styles)({
															item: true,
															isSelected: currentSelectedId === id
														})}
														key={i}
														onMouseDown={() => onClick()}
													>
														<span className={styles.radio}></span>
														<span className={styles.label}>{t(name)}</span>
													</button>
												);
											})}
										</div>
										{label === 'search.filter.availabilities' &&
											currentSelectedId > 0 && (
												<div
													className={classNames.bind(styles)({
														calendar: true,
														isOpen: false
													})}
												>
													<DatePicker
														className={styles.calendar}
														handleChange={onPickDate}
														hideQuickFilters
														selectedDate={currentSelectedDate}
														sendPickDateFromLabelEvent={
															sendPickDateFromLabelEvent
														}
														sendPickDateFromNumberEvent={
															sendPickDateFromNumberEvent
														}
													/>
												</div>
											)}
									</Accordion>
								))}
							</li>
						);
					})}
				</ul>
				<Container className={styles.bottom}>
					<Button
						className={classNames.bind(styles)({
							tablet: true,
							reset: true,
							onlyDate
						})}
						label={t('search.filter.reset')}
						type='linked'
						onClick={() => {
							setLocalFilters({ type: CLEAN });
							dispatch({
								type: PUSH,
								payload: {
									byDate: 0,
									sort: 0,
									data: {
										date: null
									}
								}
							});
							closeModal();
						}}
					/>
					<Button
						className={classNames.bind(styles)({
							tablet: true,
							save: true,
							onlyDate
						})}
						label={t('search.filter.save')}
						type='primary'
						isFullWidth={onlyDate}
						onClick={() => {
							closeModal();
							dispatch({ type: PUSH, payload: localFilters });
						}}
					/>

					<Button
						className={classNames.bind(styles)({
							mobile: true,
							save: true,
							onlyDate
						})}
						label={t('search.filter.save')}
						isFullMobile
						onClick={() => {
							closeModal();
							dispatch({ type: PUSH, payload: localFilters });
						}}
					/>
				</Container>
			</Container>
		</div>
	);
}
