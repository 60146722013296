export function Euro() {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M15.889 10.0038L15.2768 11.3346H6.60001L7.07909 10.0038H15.889ZM14.7445 12.6654L14.1323 13.9962H6.60001L7.07909 12.6654H14.7445ZM17.4593 6.41065L16.8205 7.79468C16.5366 7.53739 16.2372 7.31559 15.9222 7.12928C15.6073 6.94297 15.2768 6.7988 14.9308 6.69677C14.5848 6.59474 14.221 6.54373 13.8396 6.54373C13.0854 6.54373 12.4067 6.75222 11.8034 7.1692C11.2001 7.58619 10.7233 8.20057 10.3728 9.01236C10.0224 9.82414 9.84715 10.82 9.84715 12C9.84715 13.18 10.0224 14.1759 10.3728 14.9876C10.7233 15.7994 11.2001 16.4138 11.8034 16.8308C12.4067 17.2478 13.0854 17.4563 13.8396 17.4563C14.2299 17.4563 14.5981 17.4053 14.9441 17.3032C15.2946 17.2012 15.6206 17.0615 15.9222 16.884C16.2283 16.7066 16.51 16.507 16.7673 16.2852L17.4327 17.6692C16.9669 18.1084 16.4213 18.4411 15.7958 18.6673C15.1703 18.8891 14.5183 19 13.8396 19C12.7483 19 11.7812 18.7161 10.9384 18.1483C10.1 17.5805 9.44126 16.7731 8.96217 15.7262C8.48752 14.6793 8.2502 13.4373 8.2502 12C8.2502 10.5627 8.48752 9.32066 8.96217 8.27376C9.44126 7.22687 10.1 6.41952 10.9384 5.85171C11.7812 5.2839 12.7483 5 13.8396 5C14.5449 5 15.2036 5.12199 15.8158 5.36597C16.4324 5.60995 16.9802 5.95817 17.4593 6.41065Z'
				fill='currentColor'
			/>
		</svg>
	);
}
