import { useFirebaseSubscriptions } from '@planity/datastores';
import { formatBusinessesQueries } from './helpers';
export const getBusinesses = ({ isAllowedToFetchData, data }) => {
	const queries = formatBusinessesQueries(data);

	const {
		data: businesses,
		areLoading: businessesAreLoading,
		errors: businessesErrors
	} = useFirebaseSubscriptions(
		isAllowedToFetchData && Object.keys(queries || {}).length && queries
	);

	if (
		process.env.NODE_ENV === 'development' &&
		Object.values(businessesErrors || {}).some(x => !!x)
	) {
		console.warn({ businessesErrors });
	}
	return { businesses, businessesAreLoading };
};
