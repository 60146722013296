import React from 'react';
import Prompt from 'react-router-navigation-prompt';
import { breakpoints, colors, fontSize, fontWeight } from '@planity/theme';
import Button from '../button';
import { Localize } from '@planity/components';
import Modal from '../modal';

export default function AppointmentOnLeaveWarning() {
	return (
		<Prompt allowGoBack when={true}>
			{({ onCancel, onConfirm }) => (
				<Localize text={'bookAppointment.onLeaveWarning'}>
					{texts => (
						<Modal>
							{({ overlayStyle, modalStyle, renderModal }) => (
								<div css={[overlayStyle, styles.overlay]}>
									<div
										className={'planity_appointment_on_leave_warning'}
										css={[modalStyle, styles.modal]}
									>
										{renderModal(
											<div>
												<div css={styles.title}>{texts.title}</div>
												<div css={styles.alert}>{texts.alert}</div>
												<div css={styles.actions}>
													<Button
														isSecondary
														text={texts.cancel}
														onPress={onCancel}
													/>
													<Button text={texts.confirm} onPress={onConfirm} />
												</div>
											</div>
										)}
									</div>
								</div>
							)}
						</Modal>
					)}
				</Localize>
			)}
		</Prompt>
	);
}

const styles = {
	overlay: {
		backgroundColor: 'var(--white) !important',
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 1001,
		[breakpoints.tabletQuery]: {
			backgroundColor: 'rgba(0,0,0,.69)'
		}
	},
	modal: {
		backgroundColor: colors.grey.light,
		width: 'calc(100vw - 30px)',
		height: 'auto',
		borderRadius: 4,
		padding: '17px 12px',
		textAlign: 'left',
		boxShadow: '0 1px 6px 1px rgba(0, 0, 0, 0.15)',
		[breakpoints.tabletQuery]: {
			boxShadow: 'none',
			textAlign: 'center',
			width: 556,
			padding: '34px 112px'
		}
	},
	title: {
		fontSize: fontSize.extraRegular,
		lineHeight: 1.33,
		fontWeight: fontWeight.bold
	},
	alert: {
		margin: '16px 0 21px',
		lineHeight: 1.13,
		fontSize: '1rem',
		[breakpoints.tabletQuery]: {
			marginTop: '7px'
		}
	},
	actions: {
		'display': 'flex',
		'alignItems': 'center',
		'justifyContent': 'center',
		'& > button:first-child': {
			backgroundColor: colors.grey.light
		},
		'& > button:last-child': {
			marginLeft: 30,
			[breakpoints.tabletQuery]: {
				marginLeft: 33
			}
		}
	}
};
