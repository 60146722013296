import React, { Component } from 'react';

export default class WithScreenDimensions extends Component {
	state = {
		windowHeight: undefined,
		windowWidth: undefined
	};

	handleResize = () =>
		this.setState({
			windowHeight: window.innerHeight,
			windowWidth: window.innerWidth
		});

	componentDidMount() {
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	render() {
		const { windowHeight, windowWidth } = this.state;
		return this.props.children({
			windowHeight,
			windowWidth,
			getWidth: this.getWidth,
			windowPadding: this.getWindowPadding()
		});
	}

	getWidth = (nbOfColumns, nbOfInterColumns) => {
		const width = this.state.windowWidth;
		const numberOfColumns = width > 1170 ? 12 : width > 650 ? 6 : 4;
		const interColumn = 15;
		const column = Math.min(width, 1170) / numberOfColumns - 2 * interColumn;
		return nbOfColumns * column + nbOfInterColumns * interColumn;
	};

	getWindowPadding = () => {
		const width = this.state.windowWidth;
		return width > 1170 ? (width - 1170) / 2 : 15;
	};
}

export const withScreenDimensions = Component => props => {
	return (
		<WithScreenDimensions>
			{dataFromContext => <Component {...props} {...dataFromContext} />}
		</WithScreenDimensions>
	);
};
