import React from 'react';

export const World = ({ color }) => {
	return (
		<svg
			width='28'
			height='28'
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M22.4583 14C22.4583 18.6714 18.6714 22.4584 14 22.4584C9.3286 22.4584 5.54167 18.6714 5.54167 14C5.54167 9.32861 9.3286 5.54169 14 5.54169C18.6714 5.54169 22.4583 9.32861 22.4583 14Z'
				stroke={color || 'currentColor'}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M10.7917 6.41669V10.7917C10.7917 12.7247 9.22466 14.2917 7.29166 14.2917H5.83333'
				stroke={color || 'currentColor'}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M11.6667 21.5833C11.6667 21.5833 12.5417 19.8333 12.5417 17.5C12.5417 14.9917 15.3458 16.3661 16.1311 12.5338C16.3898 11.2714 17.378 10.2083 18.6667 10.2083H21'
				stroke={color || 'currentColor'}
				strokeWidth='1.75'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
