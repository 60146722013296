import React from 'react';

export const PT = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g>
				<mask
					style={{ maskType: 'alpha' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5 0H20V15H5V0Z'
						fill='#EF0000'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0H7.5V15H0V0Z'
						fill='#2F8D00'
					/>
					<mask
						style={{ maskType: 'alpha' }}
						maskUnits='userSpaceOnUse'
						x='2'
						y='2'
						width='11'
						height='11'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M7.44048 12.3795C4.77768 12.3795 2.61905 10.1949 2.61905 7.5C2.61905 4.80512 4.77768 2.62048 7.44048 2.62048C10.1033 2.62048 12.2619 4.80512 12.2619 7.5C12.2619 10.1949 10.1033 12.3795 7.44048 12.3795Z'
							fill='white'
						/>
					</mask>
					<g>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M7.14285 2.5H7.76785V4.18675H7.14285V2.5Z'
							fill='#FFE017'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M7.14285 10.9337H7.76785V12.5H7.14285V10.9337Z'
							fill='#FFE017'
						/>
						<path
							d='M2.69653 7.75236L2.52179 7.30405C4.45003 6.53425 5.90538 6.03781 6.89287 5.81408C8.3417 5.48582 9.93362 5.24296 11.6687 5.0853L11.7113 5.56529C9.99611 5.72114 8.4246 5.9609 6.9969 6.28437C6.03881 6.50144 4.60367 6.99099 2.69653 7.75236Z'
							fill='#FFE017'
						/>
						<path
							d='M11.963 4.78993L11.8212 5.25C10.3639 4.78993 9.17678 4.59216 8.26426 4.65342L8.23274 4.17255C9.21249 4.10678 10.4544 4.31367 11.963 4.78993Z'
							fill='#FFE017'
						/>
						<path
							d='M12.4673 7.36236L12.1979 7.75976C11.455 7.24397 10.5397 6.98489 9.44583 6.98489V6.50296C10.6317 6.50296 11.6409 6.78864 12.4673 7.36236Z'
							fill='#FFE017'
						/>
						<path
							d='M12.0638 7.50659L12.1869 7.97213C10.5852 8.40597 9.08152 8.65211 7.67561 8.71007C6.26001 8.76843 4.6101 8.52141 2.72446 7.97089L2.85645 7.50784C4.69626 8.04499 6.29666 8.28459 7.65623 8.22854C9.02549 8.17209 10.4947 7.9316 12.0638 7.50659Z'
							fill='#FFE017'
						/>
						<path
							d='M11.8823 9.77243L11.1296 10.5441C9.69919 9.115 8.43986 8.09314 7.35938 7.47916C6.26043 6.85469 4.82732 6.22058 3.06224 5.57939L3.42421 4.55881C5.24134 5.2189 6.72717 5.87634 7.88393 6.53367C9.05916 7.20149 10.3894 8.28087 11.8823 9.77243Z'
							fill='#FFE017'
						/>
						<path
							d='M3.41144 10.2096L3.12653 9.82341C3.16631 9.79334 3.2373 9.7432 3.33512 9.67966C3.49662 9.57474 3.67595 9.46999 3.86891 9.37211C4.40035 9.10252 4.93343 8.9342 5.44188 8.91874C5.46675 8.91798 5.49151 8.9176 5.51616 8.9176V9.39953C5.4963 9.39953 5.47631 9.39984 5.45618 9.40045C5.0277 9.41348 4.55716 9.56205 4.08229 9.80294C3.90529 9.89273 3.74037 9.98906 3.59236 10.0852C3.54084 10.1187 3.49526 10.1496 3.4562 10.1772C3.43342 10.1933 3.41831 10.2044 3.41144 10.2096Z'
							fill='#FFE017'
						/>
						<path
							d='M3.6326 10.3766L3.75252 9.91023C5.34009 10.3283 6.56402 10.5367 7.4182 10.5367C8.26919 10.5367 9.53581 10.2909 11.2098 9.7977L11.3429 10.2604C9.62932 10.7653 8.32382 11.0186 7.4182 11.0186C6.51578 11.0186 5.25594 10.8041 3.6326 10.3766Z'
							fill='#FFE017'
						/>
						<path
							d='M3.01402 6.05902L3.76845 5.28906L4.87302 6.39759L4.1186 7.16755L3.01402 6.05902Z'
							fill='#FFE017'
						/>
						<path
							d='M9.27246 9.90483L9.83361 8.98111L11.5859 10.0714L11.0247 10.9951L9.27246 9.90483Z'
							fill='#FFE017'
						/>
					</g>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M2.61905 7.5C2.61905 10.1949 4.77768 12.3795 7.44048 12.3795C10.1033 12.3795 12.2619 10.1949 12.2619 7.5C12.2619 4.80512 10.1033 2.62048 7.44048 2.62048C4.77768 2.62048 2.61905 4.80512 2.61905 7.5ZM11.6667 7.56024C11.6667 9.88915 9.80119 11.7771 7.5 11.7771C5.19881 11.7771 3.33333 9.88915 3.33333 7.56024C3.33333 5.23133 5.19881 3.34337 7.5 3.34337C9.80119 3.34337 11.6667 5.23133 11.6667 7.56024Z'
						fill='#F9E813'
					/>
					<path
						d='M4.78545 4.49078V8.40373C5.04303 9.95253 5.91082 10.7269 7.38881 10.7269C8.86681 10.7269 9.7346 9.95253 9.99218 8.40373V4.49078H4.78545Z'
						fill='white'
					/>
					<path
						d='M4.4283 4.12933H10.3493V8.43393L10.3444 8.46372C10.0583 10.1835 9.04436 11.0884 7.38881 11.0884C5.73325 11.0884 4.71928 10.1835 4.43325 8.46372L4.4283 8.43393V4.12933Z'
						fill='#C51918'
					/>
					<path
						d='M5.78264 5.44418H8.99497V8.21974C8.99497 8.21974 8.75659 9.65306 7.3888 9.65306C6.02102 9.65306 5.78264 8.21974 5.78264 8.21974V5.44418Z'
						fill='#F7FCFF'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.00002 4.4277H5.45182V4.72891H5.00002V4.4277Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M4.76192 4.54819H5.59525V4.69879H4.76192V4.54819Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M4.76192 4.66867H5.21372V4.81927H4.76192V4.66867Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.23811 4.66867H5.68992V4.81927H5.23811V4.66867Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M4.76192 5.03012H5.06312V5.48193H4.76192V5.03012Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.35716 5.03012H5.65837V5.48193H5.35716V5.03012Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.11906 5.03012H5.26967V5.48193H5.11906V5.03012Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M4.76192 4.90964H5.59525V5.06024H4.76192V4.90964Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M4.76192 5.27109H5.59525V5.42169H4.76192V5.27109Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M4.88097 4.78915H5.03157V4.93976H4.88097V4.78915Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.35716 4.78915H5.50776V4.93976H5.35716V4.78915Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.11906 4.66867H5.26967V4.96988H5.11906V4.66867Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M7.26192 4.4277H7.71372V4.72891H7.26192V4.4277Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M7.02382 4.54819H7.85715V4.69879H7.02382V4.54819Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M7.02382 4.66867H7.47563V4.81927H7.02382V4.66867Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M7.50002 4.66867H7.95182V4.81927H7.50002V4.66867Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M7.02382 5.03012H7.32502V5.48193H7.02382V5.03012Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M7.61906 5.03012H7.92027V5.48193H7.61906V5.03012Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M7.38097 5.03012H7.53157V5.48193H7.38097V5.03012Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M7.02382 4.90964H7.85715V5.06024H7.02382V4.90964Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M7.02382 5.27109H7.85715V5.42169H7.02382V5.27109Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M7.14287 4.78915H7.29347V4.93976H7.14287V4.78915Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M7.61906 4.78915H7.76967V4.93976H7.61906V4.78915Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M7.38097 4.66867H7.53157V4.96988H7.38097V4.66867Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.40477 4.4277H9.85658V4.72891H9.40477V4.4277Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.16667 4.54819H10V4.69879H9.16667V4.54819Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.16667 4.66867H9.61848V4.81927H9.16667V4.66867Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.64287 4.66867H10.0947V4.81927H9.64287V4.66867Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.16667 5.03012H9.46788V5.48193H9.16667V5.03012Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.76192 5.03012H10.0631V5.48193H9.76192V5.03012Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.52382 5.03012H9.67442V5.48193H9.52382V5.03012Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.16667 4.90964H10V5.06024H9.16667V4.90964Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.16667 5.27109H10V5.42169H9.16667V5.27109Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.28572 4.78915H9.43632V4.93976H9.28572V4.78915Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.76192 4.78915H9.91252V4.93976H9.76192V4.78915Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.52382 4.66867H9.67442V4.96988H9.52382V4.66867Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.40477 6.59637H9.85658V6.89758H9.40477V6.59637Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.16667 6.71686H10V6.86746H9.16667V6.71686Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.16667 6.83734H9.61848V6.98794H9.16667V6.83734Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.64287 6.83734H10.0947V6.98794H9.64287V6.83734Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.16667 7.19879H9.46788V7.6506H9.16667V7.19879Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.76192 7.19879H10.0631V7.6506H9.76192V7.19879Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.52382 7.19879H9.67442V7.6506H9.52382V7.19879Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.16667 7.07831H10V7.22891H9.16667V7.07831Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.16667 7.43976H10V7.59036H9.16667V7.43976Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.28572 6.95782H9.43632V7.10843H9.28572V6.95782Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.76192 6.95782H9.91252V7.10843H9.76192V6.95782Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.52382 6.83734H9.67442V7.13855H9.52382V6.83734Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.00002 6.59637H5.45182V6.89758H5.00002V6.59637Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M4.76192 6.71686H5.59525V6.86746H4.76192V6.71686Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M4.76192 6.83734H5.21372V6.98794H4.76192V6.83734Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.23811 6.83734H5.68992V6.98794H5.23811V6.83734Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M4.76192 7.19879H5.06312V7.6506H4.76192V7.19879Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.35716 7.19879H5.65837V7.6506H5.35716V7.19879Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.11906 7.19879H5.26967V7.6506H5.11906V7.19879Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M4.76192 7.07831H5.59525V7.22891H4.76192V7.07831Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M4.76192 7.43976H5.59525V7.59036H4.76192V7.43976Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M4.88097 6.95782H5.03157V7.10843H4.88097V6.95782Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.35716 6.95782H5.50776V7.10843H5.35716V6.95782Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.11906 6.83734H5.26967V7.13855H5.11906V6.83734Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.28986 9.31494L5.58027 8.96884L5.81101 9.16245L5.52059 9.50855L5.28986 9.31494Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.22911 9.5748L5.76477 8.93643L5.88014 9.03323L5.34448 9.6716L5.22911 9.5748Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.3214 9.65222L5.61181 9.30612L5.72718 9.40292L5.43676 9.74903L5.3214 9.65222Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.62749 9.28745L5.9179 8.94134L6.03327 9.03815L5.74286 9.38425L5.62749 9.28745Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.59828 9.88455L5.79189 9.65382L6.138 9.94423L5.94439 10.175L5.59828 9.88455Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.9809 9.42859L6.17451 9.19785L6.52061 9.48827L6.327 9.719L5.9809 9.42859Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.82785 9.61099L5.92466 9.49562L6.27076 9.78604L6.17395 9.90141L5.82785 9.61099Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.50598 9.80713L6.04164 9.16876L6.15701 9.26556L5.62135 9.90393L5.50598 9.80713Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.78287 10.0395L6.31852 9.40109L6.43389 9.49789L5.89824 10.1363L5.78287 10.0395Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.49022 9.63849L5.58702 9.52312L5.70239 9.61993L5.60559 9.73529L5.49022 9.63849Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.79631 9.27371L5.89312 9.15834L6.00848 9.25515L5.91168 9.37052L5.79631 9.27371Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.55096 9.37863L5.64777 9.26326L5.87851 9.45687L5.7817 9.57224L5.55096 9.37863Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.36156 9.04138L9.65197 9.38749L9.42124 9.5811L9.13082 9.23499L9.36156 9.04138Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.11621 8.93643L9.65187 9.5748L9.5365 9.67161L9.00084 9.03324L9.11621 8.93643Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.02393 9.01385L9.31434 9.35996L9.19897 9.45676L8.90856 9.11066L9.02393 9.01385Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.33002 9.37866L9.62043 9.72477L9.50507 9.82157L9.21465 9.47547L9.33002 9.37866Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M8.74704 9.24619L8.94065 9.47692L8.59455 9.76734L8.40094 9.5366L8.74704 9.24619Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.12965 9.70218L9.32326 9.93292L8.97716 10.2233L8.78355 9.9926L9.12965 9.70218Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M8.97661 9.51978L9.07341 9.63514L8.72731 9.92556L8.6305 9.81019L8.97661 9.51978Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M8.83934 9.16876L9.375 9.80713L9.25963 9.90394L8.72397 9.26557L8.83934 9.16876Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M8.56245 9.40109L9.09811 10.0395L8.98274 10.1363L8.44709 9.4979L8.56245 9.40109Z'
						fill='#060101'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.00815 9.1825L9.10495 9.29786L8.98959 9.39467L8.89278 9.2793L9.00815 9.1825Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.31424 9.5473L9.41104 9.66267L9.29568 9.75948L9.19887 9.64411L9.31424 9.5473Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M9.25349 9.28745L9.3503 9.40281L9.11956 9.59642L9.02276 9.48106L9.25349 9.28745Z'
						fill='#F9E813'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M7.84903 5.75299H7.02382V6.35391C7.02382 6.35391 7.08506 6.66423 7.43642 6.66423C7.78779 6.66423 7.84903 6.35391 7.84903 6.35391V5.75299ZM7.38096 5.99395C7.38096 6.06049 7.32766 6.11444 7.26191 6.11444C7.19617 6.11444 7.14287 6.06049 7.14287 5.99395C7.14287 5.92741 7.19617 5.87347 7.26191 5.87347C7.32766 5.87347 7.38096 5.92741 7.38096 5.99395ZM7.26191 6.59636C7.32766 6.59636 7.38096 6.54242 7.38096 6.47588C7.38096 6.40934 7.32766 6.3554 7.26191 6.3554C7.19617 6.3554 7.14287 6.40934 7.14287 6.47588C7.14287 6.54242 7.19617 6.59636 7.26191 6.59636ZM7.7381 6.47588C7.7381 6.54242 7.68481 6.59636 7.61906 6.59636C7.55331 6.59636 7.50001 6.54242 7.50001 6.47588C7.50001 6.40934 7.55331 6.3554 7.61906 6.3554C7.68481 6.3554 7.7381 6.40934 7.7381 6.47588ZM7.44049 6.3554C7.50623 6.3554 7.55953 6.30146 7.55953 6.23492C7.55953 6.16838 7.50623 6.11444 7.44049 6.11444C7.37474 6.11444 7.32144 6.16838 7.32144 6.23492C7.32144 6.30146 7.37474 6.3554 7.44049 6.3554ZM7.7381 5.99395C7.7381 6.06049 7.68481 6.11444 7.61906 6.11444C7.55331 6.11444 7.50001 6.06049 7.50001 5.99395C7.50001 5.92741 7.55331 5.87347 7.61906 5.87347C7.68481 5.87347 7.7381 5.92741 7.7381 5.99395Z'
						fill='#013399'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M7.84903 7.07831H7.02382V7.67923C7.02382 7.67923 7.08506 7.98955 7.43642 7.98955C7.78779 7.98955 7.84903 7.67923 7.84903 7.67923V7.07831ZM7.38096 7.31927C7.38096 7.38581 7.32766 7.43975 7.26191 7.43975C7.19617 7.43975 7.14287 7.38581 7.14287 7.31927C7.14287 7.25273 7.19617 7.19879 7.26191 7.19879C7.32766 7.19879 7.38096 7.25273 7.38096 7.31927ZM7.26191 7.92168C7.32766 7.92168 7.38096 7.86774 7.38096 7.8012C7.38096 7.73466 7.32766 7.68072 7.26191 7.68072C7.19617 7.68072 7.14287 7.73466 7.14287 7.8012C7.14287 7.86774 7.19617 7.92168 7.26191 7.92168ZM7.7381 7.8012C7.7381 7.86774 7.68481 7.92168 7.61906 7.92168C7.55331 7.92168 7.50001 7.86774 7.50001 7.8012C7.50001 7.73466 7.55331 7.68072 7.61906 7.68072C7.68481 7.68072 7.7381 7.73466 7.7381 7.8012ZM7.44049 7.68072C7.50623 7.68072 7.55953 7.62678 7.55953 7.56024C7.55953 7.4937 7.50623 7.43975 7.44049 7.43975C7.37474 7.43975 7.32144 7.4937 7.32144 7.56024C7.32144 7.62678 7.37474 7.68072 7.44049 7.68072ZM7.7381 7.31927C7.7381 7.38581 7.68481 7.43975 7.61906 7.43975C7.55331 7.43975 7.50001 7.38581 7.50001 7.31927C7.50001 7.25273 7.55331 7.19879 7.61906 7.19879C7.68481 7.19879 7.7381 7.25273 7.7381 7.31927Z'
						fill='#013399'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M8.92045 7.07831H8.09525V7.67923C8.09525 7.67923 8.15648 7.98955 8.50785 7.98955C8.85922 7.98955 8.92045 7.67923 8.92045 7.67923V7.07831ZM8.45239 7.31927C8.45239 7.38581 8.39909 7.43975 8.33334 7.43975C8.26759 7.43975 8.21429 7.38581 8.21429 7.31927C8.21429 7.25273 8.26759 7.19879 8.33334 7.19879C8.39909 7.19879 8.45239 7.25273 8.45239 7.31927ZM8.33334 7.92168C8.39909 7.92168 8.45239 7.86774 8.45239 7.8012C8.45239 7.73466 8.39909 7.68072 8.33334 7.68072C8.26759 7.68072 8.21429 7.73466 8.21429 7.8012C8.21429 7.86774 8.26759 7.92168 8.33334 7.92168ZM8.80953 7.8012C8.80953 7.86774 8.75623 7.92168 8.69048 7.92168C8.62474 7.92168 8.57144 7.86774 8.57144 7.8012C8.57144 7.73466 8.62474 7.68072 8.69048 7.68072C8.75623 7.68072 8.80953 7.73466 8.80953 7.8012ZM8.51191 7.68072C8.57766 7.68072 8.63096 7.62678 8.63096 7.56024C8.63096 7.4937 8.57766 7.43975 8.51191 7.43975C8.44616 7.43975 8.39286 7.4937 8.39286 7.56024C8.39286 7.62678 8.44616 7.68072 8.51191 7.68072ZM8.80953 7.31927C8.80953 7.38581 8.75623 7.43975 8.69048 7.43975C8.62474 7.43975 8.57144 7.38581 8.57144 7.31927C8.57144 7.25273 8.62474 7.19879 8.69048 7.19879C8.75623 7.19879 8.80953 7.25273 8.80953 7.31927Z'
						fill='#013399'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M6.7776 7.07831H5.95239V7.67923C5.95239 7.67923 6.01363 7.98955 6.365 7.98955C6.71636 7.98955 6.7776 7.67923 6.7776 7.67923V7.07831ZM6.30954 7.31927C6.30954 7.38581 6.25624 7.43975 6.19049 7.43975C6.12474 7.43975 6.07144 7.38581 6.07144 7.31927C6.07144 7.25273 6.12474 7.19879 6.19049 7.19879C6.25624 7.19879 6.30954 7.25273 6.30954 7.31927ZM6.19049 7.92168C6.25624 7.92168 6.30954 7.86774 6.30954 7.8012C6.30954 7.73466 6.25624 7.68072 6.19049 7.68072C6.12474 7.68072 6.07144 7.73466 6.07144 7.8012C6.07144 7.86774 6.12474 7.92168 6.19049 7.92168ZM6.66668 7.8012C6.66668 7.86774 6.61338 7.92168 6.54763 7.92168C6.48188 7.92168 6.42858 7.86774 6.42858 7.8012C6.42858 7.73466 6.48188 7.68072 6.54763 7.68072C6.61338 7.68072 6.66668 7.73466 6.66668 7.8012ZM6.36906 7.68072C6.43481 7.68072 6.48811 7.62678 6.48811 7.56024C6.48811 7.4937 6.43481 7.43975 6.36906 7.43975C6.30331 7.43975 6.25001 7.4937 6.25001 7.56024C6.25001 7.62678 6.30331 7.68072 6.36906 7.68072ZM6.66668 7.31927C6.66668 7.38581 6.61338 7.43975 6.54763 7.43975C6.48188 7.43975 6.42858 7.38581 6.42858 7.31927C6.42858 7.25273 6.48188 7.19879 6.54763 7.19879C6.61338 7.19879 6.66668 7.25273 6.66668 7.31927Z'
						fill='#013399'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M7.84903 8.40359H7.02382V9.00452C7.02382 9.00452 7.08506 9.31483 7.43642 9.31483C7.78779 9.31483 7.84903 9.00452 7.84903 9.00452V8.40359ZM7.38096 8.64456C7.38096 8.7111 7.32766 8.76504 7.26191 8.76504C7.19617 8.76504 7.14287 8.7111 7.14287 8.64456C7.14287 8.57802 7.19617 8.52408 7.26191 8.52408C7.32766 8.52408 7.38096 8.57802 7.38096 8.64456ZM7.26191 9.24697C7.32766 9.24697 7.38096 9.19303 7.38096 9.12649C7.38096 9.05995 7.32766 9.006 7.26191 9.006C7.19617 9.006 7.14287 9.05995 7.14287 9.12649C7.14287 9.19303 7.19617 9.24697 7.26191 9.24697ZM7.7381 9.12649C7.7381 9.19303 7.68481 9.24697 7.61906 9.24697C7.55331 9.24697 7.50001 9.19303 7.50001 9.12649C7.50001 9.05995 7.55331 9.006 7.61906 9.006C7.68481 9.006 7.7381 9.05995 7.7381 9.12649ZM7.44049 9.006C7.50623 9.006 7.55953 8.95206 7.55953 8.88552C7.55953 8.81898 7.50623 8.76504 7.44049 8.76504C7.37474 8.76504 7.32144 8.81898 7.32144 8.88552C7.32144 8.95206 7.37474 9.006 7.44049 9.006ZM7.7381 8.64456C7.7381 8.7111 7.68481 8.76504 7.61906 8.76504C7.55331 8.76504 7.50001 8.7111 7.50001 8.64456C7.50001 8.57802 7.55331 8.52408 7.61906 8.52408C7.68481 8.52408 7.7381 8.57802 7.7381 8.64456Z'
						fill='#013399'
					/>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
				style={{ mixBlendMode: 'multiply' }}
			/>
			<defs>
				<clipPath>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
