import React, { Fragment, useEffect, useState } from 'react';
import { FirebaseSubscription } from '@planity/components';

export default function PaginatedFirebaseSubscriptionWrapper(props) {
	const [page, setPage] = useState(0);
	useEffect(() => {
		setPage(0);
	}, [!!props.renderPagination]);
	return (
		<PaginatedFirebaseSubscription
			currentPageNumber={page}
			navToPage={setPage}
			pageNumber={0}
			total={0}
			{...props}
		/>
	);
}

function PaginatedFirebaseSubscription({
	renderLoadMore,
	renderPagination,
	renderData,
	orderByChild,
	limitToFirst,
	limitToLast,
	pageNumber,
	currentPageNumber,
	navToPage,
	...props
}) {
	return (
		<FirebaseSubscription
			{...props}
			limitToFirst={limitToFirst}
			limitToLast={limitToLast}
			orderByChild={orderByChild}
		>
			{({ data, isLoading }) => {
				const hasMore = hasMoreData(limitToFirst || limitToLast, data);
				const nextCursor = Object.keys(data || {}).reduce((cursor, id) => {
					const chunk = data[id];
					if (
						!cursor ||
						(limitToLast
							? chunk[orderByChild] < cursor
							: chunk[orderByChild] > cursor)
					) {
						return chunk[orderByChild];
					} else {
						return cursor;
					}
				}, null);
				return (
					<Fragment>
						{renderData({
							data,
							isLoading,
							hasMore,
							isCurrent: pageNumber === currentPageNumber
						})}
						{!!(hasMore || renderPagination) && (
							<LoadMore
								{...props}
								currentPageNumber={currentPageNumber}
								endAt={limitToLast && nextCursor}
								hasMore={hasMore}
								limitToFirst={limitToFirst}
								limitToLast={limitToLast}
								navToPage={navToPage}
								orderByChild={orderByChild}
								pageNumber={pageNumber + 1}
								renderData={renderData}
								renderLoadMore={renderLoadMore}
								renderPagination={renderPagination}
								startAt={limitToFirst && nextCursor}
							/>
						)}
					</Fragment>
				);
			}}
		</FirebaseSubscription>
	);
}

/**
 * A recursive component that calls PaginatedFirebaseSubscription
 * (which calls LoadMore in the end), as a sibling.
 * That make a chained list, where every node takes care of the next one.
 */
function LoadMore({ hasMore, ...props }) {
	const [loading, setLoading] = useState(false);
	const renderControls = props.renderLoadMore
		? !loading
		: props.currentPageNumber === props.pageNumber - 1;
	// We load the current page and the next one, so we always have some prefetched stuff.
	return (
		<Fragment>
			{props.pageNumber - props.currentPageNumber <= 1 && (
				<PaginatedFirebaseSubscription {...props} />
			)}
			{renderControls
				? props.renderLoadMore
					? props.renderLoadMore(() => {
							setLoading(true);
					  })
					: props.renderPagination({
							navToPrev: () => {
								if (props.currentPageNumber > 0) {
									props.navToPage(n => n - 1);
								}
							},
							navToNext: () => {
								setLoading(true);
								props.navToPage(n => n + 1);
							},
							pageNumber: props.pageNumber,
							hasMore,
							total: props.total
					  })
				: null}
		</Fragment>
	);
}

const hasMoreData = (pageSize, data) => {
	return Object.keys(data || {}).length > pageSize - 1;
};
