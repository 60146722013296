import React, { createContext, useContext, useState } from 'react';

const OverlayContext = createContext({});

export const useOverlay = () => useContext(OverlayContext);

export function OverlayContextProvider({ children }) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<OverlayContext.Provider value={{ isOpen, setIsOpen }}>
			{children}
		</OverlayContext.Provider>
	);
}

export const WithOverlay = OverlayContext.Consumer;
export const withOverlay = WrappedComponent => props =>
	(
		<WithOverlay>
			{contextProps => <WrappedComponent {...contextProps} {...props} />}
		</WithOverlay>
	);
