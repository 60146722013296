// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module_text-Zdy0i{font-family:var(--font-1);font-weight:var(--regular);font-size:14px;line-height:24px;color:var(--grey-600);text-align:justify;margin-top:12px}.styles-module_text-Zdy0i>a{font-weight:var(--semibold);text-decoration:underline;color:var(--grey-600)}", "",{"version":3,"sources":["webpack://./../../packages/components/business_booking/online_payment/policies/styles.module.scss"],"names":[],"mappings":"AAAA,0BACC,yBAAA,CACA,0BAAA,CACA,cAAA,CACA,gBAAA,CACA,qBAAA,CACA,kBAAA,CACA,eAAA,CAEA,4BACC,2BAAA,CACA,yBAAA,CACA,qBAAA","sourcesContent":[".text {\n\tfont-family: var(--font-1);\n\tfont-weight: var(--regular);\n\tfont-size: 14px;\n\tline-height: 24px;\n\tcolor: var(--grey-600);\n\ttext-align: justify;\n\tmargin-top: 12px;\n\n\t> a {\n\t\tfont-weight: var(--semibold);\n\t\ttext-decoration: underline;\n\t\tcolor: var(--grey-600);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "styles-module_text-Zdy0i"
};
module.exports = ___CSS_LOADER_EXPORT___;
