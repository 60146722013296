export function Lock({ outlined }) {
	return (
		<svg
			width='25'
			height='24'
			viewBox='0 0 25 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M6.54999 11.75C6.54999 11.1977 6.9977 10.75 7.54999 10.75H18.05C18.6023 10.75 19.05 11.1977 19.05 11.75V17.25C19.05 18.3546 18.1546 19.25 17.05 19.25H8.54999C7.44542 19.25 6.54999 18.3546 6.54999 17.25V11.75Z'
				stroke={!outlined ? 'currentColor' : 'none'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M8.55 10.5V10.3427C8.55 8.78147 8.45608 7.04125 9.54646 5.9239C10.1683 5.2867 11.1745 4.75 12.8 4.75C14.4255 4.75 15.4317 5.2867 16.0535 5.9239C17.1439 7.04125 17.05 8.78147 17.05 10.3427V10.5'
				stroke={!outlined ? 'currentColor' : 'none'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
