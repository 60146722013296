import Slideshow from './slideshow';
import MainInfo from './main_info';
import { CTA } from './cta';
import React from 'react';
import styles from './business_top_info.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Spinner } from '@planity/ui';

export const BusinessTopInfo = ({
	hasStartedBooking,
	business,
	showRating,
	displayClickAndCollect,
	displayClickAndShop,
	giftVoucherOptionEnabled,
	navigateToTab,
	onClickAndCollectClick,
	onGiftVoucherClick,
	displayBusinessInfoAndCTAOnMobile,
	displayBusinessInfoOnTablet,
	onBookClick,
	showSlideshow = true,
	businessIsFullyLoaded,
	isMoveAppointment = false
}) => {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		businessInfo: true,
		displayBusinessInfoAndCTAOnMobile,
		displayBusinessInfoOnTablet,
		hasStartedBooking
	});

	return (
		<div className={classes}>
			{!hasStartedBooking && showSlideshow && <Slideshow business={business} />}
			<div className={styles.infos}>
				<MainInfo
					business={business}
					hasStartedBooking={hasStartedBooking}
					showRating={showRating}
				/>
				{!isMoveAppointment &&
					(businessIsFullyLoaded ? (
						hasStartedBooking ? null : (
							<CTA
								displayClickAndCollect={displayClickAndCollect}
								displayClickAndShop={displayClickAndShop}
								giftVoucherEnabled={giftVoucherOptionEnabled}
								navigateToTab={navigateToTab}
								onBookClick={onBookClick}
								onClickAndCollectClick={onClickAndCollectClick}
								onGiftVoucherClick={onGiftVoucherClick}
							/>
						)
					) : (
						<div className={styles.spinner}>
							<Spinner />
						</div>
					))}
			</div>
		</div>
	);
};
