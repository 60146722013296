import Color from 'color';

function getPrimaryColors() {
	if (!process.env.WIDGET) {
		return { primary: '#0d0d0d', disabled: '#979797' };
	} else {
		const primary = getPrimaryColor(
			typeof window !== 'undefined' &&
				window.planity &&
				window.planity.primaryColor
		);
		return {
			primary: primary.string(),
			disabled: primary[primary.isLight() ? 'darken' : 'lighten'](0.3)
				.rgb()
				.string()
		};
	}
}
function getPrimaryColor(color) {
	try {
		return Color(color);
	} catch (e) {
		return Color('#000');
	}
}

export default {
	black: {
		text: '#0d0d0d',
		background: '#090a0b'
	},
	grey: {
		/** @deprecated old color system */
		alertBackground: '#e2e9ed',
		/** @deprecated old color system */
		background: '#F2F5F7',
		/** @deprecated old color system */
		lightBorder: '#F0F0F0',
		/** @deprecated old color system */
		text: '#96A2AA',
		/** @deprecated old color system */
		placeholderText: '#BDC4C9',
		/** @deprecated old color system */
		disabledText: '#F0F0F0',
		// refonte
		light: '#f7f7f7',
		border: '#e5e5e5',
		lightPeach: '#d7d3cf',
		brownGrey: '#979797',
		lightBackground: '#f6f6f8',
		inactive: '#cdcbc7',
		overLightGrey: '#ababab',
		peach: '#D7D2CE',
		dark: '#262524',
		grey200: '#eef0f2'
	},
	error: '#FF6866',
	warning: '#ED8936',
	success: '#48BB78',
	white: '#ffffff',
	main: getPrimaryColors(),
	alert: {
		success: '#07CEC4',
		danger: '#F84F4F'
	}
};
