/* Firebase errors */
export const INVALID_EMAIL_ERROR = 'auth/invalid-email';
export const USER_NOT_FOUND_ERROR = 'auth/user-not-found';
export const WRONG_PASSWORD_ERROR = 'auth/wrong-password';
export const NETWORK_ERROR = 'auth/network-request-failed';
export const EMAIL_IN_USE_ERROR = 'auth/email-already-in-use';
export const INVALID_PASSWORD_ERROR = 'auth/weak-password';
export const MISSING_PASSWORD_ERROR = 'auth/missing-password';
export const TOO_MANY_ATTEMPTS_ERROR = 'auth/too-many-requests';

/* Custom errors */
export const MISSING_FIRST_NAME_ERROR = 'MISSING_FIRST_NAME_ERROR';
export const MISSING_LAST_NAME_ERROR = 'MISSING_LAST_NAME_ERROR';
export const MISSING_PHONE_NUMBER_ERROR = 'MISSING_PHONE_NUMBER_ERROR';
export const MISSING_EMAIL_ERROR = 'MISSING_EMAIL_ERROR';
export const INVALID_FORMAT_ERROR = 'INVALID_FORMAT_ERROR';
export const INVALID_PHONE_NUMBER_ERROR = 'INVALID_PHONE_NUMBER_ERROR';
export const MUST_ACCEPT_CONDITIONS_ERROR = 'MUST_ACCEPT_CONDITIONS_ERROR';
export const PASSWORD_CONFIRMATION_ERROR = 'PASSWORD_CONFIRMATION_ERROR';
export const AUTHORIZATION_ERROR = 'AUTHORIZATION_ERROR';
export const PREVIOUSLY_SIGNED_UP_WITH_FACEBOOK_ERROR =
	'PREVIOUSLY_SIGNED_UP_WITH_FACEBOOK_ERROR';
export const MISSING_ALL_INPUTS_ERROR = 'MISSING_ALL_INPUTS_ERROR';

const WHITELISTED_ERRORS = [
	INVALID_FORMAT_ERROR,
	INVALID_EMAIL_ERROR,
	USER_NOT_FOUND_ERROR,
	MISSING_PASSWORD_ERROR,
	WRONG_PASSWORD_ERROR,
	NETWORK_ERROR,
	MISSING_FIRST_NAME_ERROR,
	MISSING_LAST_NAME_ERROR,
	MISSING_PHONE_NUMBER_ERROR,
	INVALID_PHONE_NUMBER_ERROR,
	MUST_ACCEPT_CONDITIONS_ERROR,
	MISSING_EMAIL_ERROR,
	EMAIL_IN_USE_ERROR,
	INVALID_PASSWORD_ERROR,
	PASSWORD_CONFIRMATION_ERROR,
	AUTHORIZATION_ERROR,
	TOO_MANY_ATTEMPTS_ERROR,
	PREVIOUSLY_SIGNED_UP_WITH_FACEBOOK_ERROR
];

export { WHITELISTED_ERRORS };
