import { useEffect } from 'react';
/**
 * Inject a script in an effect. Feel free to add your own props.
 * @param params.src {string}
 * @param params.innerText {string} javascript to inject inside the script tags
 * @param params.func {function} function that will be attached to onload and onreadystatechange
 * @param params.type {string} script type
 * @param params.location {HTMLElement} where to append script. Default is <head>
 * @param params.datasetVendor {string} dataset.vendor
 * @return {null}
 */
export const useInjectScript = params => {
	useEffect(() => {
		if (params) {
			const {
				src,
				innerText,
				func,
				type,
				location = document.head,
				datasetVendor = null
			} = params;

			const script = document.createElement('script');
			if (src) script.src = src;
			if (innerText) script.innerText = innerText;
			if (datasetVendor) script.dataset.vendor = datasetVendor;
			if (type) script.type = type;
			if (func) {
				script.onload = func;
				script.onreadystatechange = func;
			}
			location.appendChild(script);
		}
	}, [JSON.stringify(params)]);

	return null;
};
