// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".appointment_preview_component-module_container-jwUX5{margin-bottom:20px;height:100%}", "",{"version":3,"sources":["webpack://./../../packages/components/my_account_page/appointment_preview/appointment_preview_component.module.scss"],"names":[],"mappings":"AAEA,sDACC,kBAAA,CACA,WAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.container {\n\tmargin-bottom: 20px;\n\theight: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"container": "appointment_preview_component-module_container-jwUX5"
};
module.exports = ___CSS_LOADER_EXPORT___;
