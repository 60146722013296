import classNames from 'classnames/bind';
import { useLocalizedRoutes } from '@planity/localization';
import styles from './place.module.scss';
import { Image } from '@planity/ui';
import { Link } from 'react-router-dom';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';

/**
 * We hide rows that do not have every item set. No holes
 * @param place
 * @param category
 * @param dataHide1 {boolean} true if we should hide element on mobile
 * @param dataHide2 {boolean} true if we should hide element on tablet
 * @param dataHide3 {boolean} true if we should hide element on desktop
 * @param index {number} item's index
 */
export function Place({
	place,
	category,
	dataHide1,
	dataHide2,
	dataHide3,
	index
}) {
	const { routes } = useLocalizedRoutes();
	const { t } = useTranslation();
	const route =
		place &&
		routes.catchAll({
			search: {
				parentCategory: category,
				parentPlace: place.parent || place,
				place: place.parent ? place : undefined
			}
		});
	useStyles(styles);
	const classes = classNames.bind(styles)({
		city: true
	});

	return (
		<div
			className={styles.wrapper}
			data-hide-1={dataHide1}
			data-hide-2={dataHide2}
			data-hide-3={dataHide3}
			data-hasimage={!!place.coverUrl}
		>
			<Link className={classes} to={route || '/'}>
				<div className={styles.image}>
					<Image
						alt={place.name}
						isCloudinary
						url={place.coverUrl}
						transformation={'fl_strip_profile,f_auto,q_auto,fl_lossy,c_thumb'}
					/>
				</div>
				<div className={styles.text}>
					<span className={styles.label}>{t('category.discover')}</span>
					<h2 className={styles.h2} id={`place-title-${index}-category-page`}>
						{category.name} {place.preposition} {place.name}
					</h2>
				</div>
			</Link>
		</div>
	);
}
