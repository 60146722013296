export function CreditCard() {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M3.95825 6.45835C3.95825 5.53788 4.70444 4.79169 5.62492 4.79169H14.3749C15.2954 4.79169 16.0416 5.53788 16.0416 6.45835V13.5417C16.0416 14.4622 15.2954 15.2084 14.3749 15.2084H5.62492C4.70444 15.2084 3.95825 14.4622 3.95825 13.5417V6.45835Z'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M4.16675 8.54169H15.8334'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M6.45825 11.875H8.54159'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M13.125 11.875H13.5417'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
