import { useLocation } from 'react-router-dom';
import { getPathnameWithoutLocale } from '@planity/helpers';
import { WebviewTinyUrlRedirection } from './webview_tiny_url_redirection';
import { WebviewBusinessOldSlugRedirection } from './webview_business_old_slug_redirection';
import { WebviewRedirectResetPassword } from './webview_redirect_reset_password';
import { UnknownURLPage } from '../unknown_url_page';
import { Spinner } from '@planity/ui';
import { useFirebaseSubscription } from '@planity/datastores';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './webview_redirections.module.scss';
import { useLocalizedRoutes, AVAILABLE_LANGUAGES } from '@planity/localization';

/**
 * replace server middlewares for the webview when user use deep links
 * old business slug => new business slug
 * campaign sms tiny url => business slug
 **/
export const WebviewRedirections = () => {
	const location = useLocation();
	const routes = useLocalizedRoutes();
	useStyles(styles);

	const pathname = getPathnameWithoutLocale(location.pathname);
	const code = pathname.match(/\/t\/([a-zA-Z0-9]{2,4})$/);

	const [businessId, , isLoading] = useFirebaseSubscription({
		path: !code && `slugs/businesses/${location.pathname}`
	});

	if (isLoading) {
		return (
			<div className={styles.spinner}>
				<Spinner />
			</div>
		);
	}

	if (code && code[1]) {
		return <WebviewTinyUrlRedirection code={code[1]} />;
	}
	const searchParams = new URLSearchParams(location?.search);
	const lang = searchParams?.get('lang');

	if (
		lang &&
		pathname.match(new RegExp(routes.resetPassword)) &&
		AVAILABLE_LANGUAGES.includes(lang.toLowerCase())
	) {
		return <WebviewRedirectResetPassword />;
	}

	if (businessId) {
		return <WebviewBusinessOldSlugRedirection businessId={businessId} />;
	}

	return <UnknownURLPage />;
};
