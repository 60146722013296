import React from 'react';
import { CloudinaryPicture } from '@planity/components';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './image.module.scss';
import { Link } from 'react-router-dom';

/**
 * An image component. It's way too complicated for what it does.
 * @param url {String} The URL of your picture
 * @param desk {String} A ratio for testing purposes. Eg : '720/720'
 * @param fit {String} One of 'cover'|'contain'|'default'
 * @param alt {String} Alternative text
 * @param className
 * @param isCloudinary {boolean} needed to avoid loading a high resolution pic where we need a
 *   little thumbnail
 * @param transformations {Object} img transformations by media queries
 * @param loading {boolean}
 * @param href {string}
 * @param transformation
 * @param onClick {function|null}
 * @param props {Object} props
 */
export function Image({
	url = '',
	desk = '720/720',
	fit = 'default',
	alt = '',
	className = undefined,
	isCloudinary = false,
	transformations,
	loading,
	href,
	transformation,
	onClick,
	cloudinaryClassName,
	style,
	...props
}) {
	useStyles(styles);
	const cx = classNames.bind(styles);
	const classes = classNames.bind(styles)({
		image: true,
		isCover: fit === 'cover',
		isContain: fit === 'contain',
		[className]: className !== undefined,
		canBeClicked: typeof onClick === 'function'
	});

	const { breakpoints = [] } = transformations || {};
	const transformedPictures =
		transformations &&
		Array.from(
			breakpoints.reduce((pics, { transformation }) => {
				pics.add(transformation);
				return pics;
			}, new Set([transformations.default]))
		);

	if (!url) return <div className={classes} onClick={onClick} />;

	if (transformedPictures) {
		return (
			<CloudinaryPicture
				src={formatUrl(url)}
				transformations={transformedPictures}
			>
				{urls => {
					const backgroundImage = breakpoints.reduce(
						(style, { query, transformation }) => {
							style[query] = {
								backgroundImage: `url('${urls[transformation]}')`,
								border: '0px solid'
							};
							return style;
						},
						{}
					);
					return (
						<div
							className={cx({
								picture: true,
								canBeClicked: typeof onClick === 'function',
								[cloudinaryClassName]: cloudinaryClassName !== undefined
							})}
							content={urls[transformations.default]}
							css={backgroundImage}
							itemProp={'image'}
							role={'img'}
							title={alt}
							onClick={onClick}
						/>
					);
				}}
			</CloudinaryPicture>
		);
	}
	if (isCloudinary)
		return (
			<div className={classes}>
				<CloudinaryPicture
					src={formatUrl(url)}
					alt={alt}
					transformation={transformation}
					onClick={onClick}
					style={style}
					{...props}
				/>
			</div>
		);

	if (href) {
		return (
			<Link to={href} className={classes}>
				<img src={formatUrl(url)} alt={alt} />
			</Link>
		);
	}

	return <img className={classes} src={url} alt={alt} />;
}

const formatUrl = url =>
	typeof url === 'string' ? (url || '').replace('http://', 'https://') : url;
