export function Crown({ outlined }) {
	return (
		<svg
			width='25'
			height='24'
			viewBox='0 0 25 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M7.14999 16.75L5.14999 6.75L9.39999 9.25L12.4 4.75L15.4 9.25L19.65 6.75L17.65 16.75H7.14999Z'
				stroke={!outlined ? 'currentColor' : 'none'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M17.65 16.75H7.14999C6.04542 16.75 5.14999 17.6454 5.14999 18.75V19.25H19.65V18.75C19.65 17.6454 18.7546 16.75 17.65 16.75Z'
				stroke={!outlined ? 'currentColor' : 'none'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
