import useStyles from 'isomorphic-style-loader/useStyles';
import { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './toggle.module.scss';

export function Toggle({ isDisabled = false }) {
	useStyles(styles);

	const [isChecked, setIsChecked] = useState(false);

	const classes = classNames.bind(styles)({
		toggle: true,
		knob: true
	});

	return (
		<button
			disabled={isDisabled}
			data-checked={isChecked}
			className={classes}
			onClick={() => setIsChecked(!isChecked)}
			onMouseDown={e => e.preventDefault()} // Prevent focus state on click
		>
			<span className={styles.knob}></span>
		</button>
	);
}
