import { CAC_FLOW_MACHINE_STATES } from './cacFlowMachineStates';

export const CLICK_AND_COLLECT_STEPS = [
	CAC_FLOW_MACHINE_STATES.CART,
	CAC_FLOW_MACHINE_STATES.AUTHENTICATION,
	CAC_FLOW_MACHINE_STATES.DELIVERY_FORM,
	CAC_FLOW_MACHINE_STATES.CREDIT_CARD,
	CAC_FLOW_MACHINE_STATES.CAN_PAY,
	CAC_FLOW_MACHINE_STATES.PAYING,
	CAC_FLOW_MACHINE_STATES.SECURE_FLOW,
	CAC_FLOW_MACHINE_STATES.CONFIRMED
];
