import React, { useEffect, useState } from 'react';
import UserProfileForm from '../../user_profile_form';
import { PhoneVerificationForm } from '../../phone_verification_form';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './signed_in.module.scss';
import { Localize } from '../../localize';
import { AddChildButton } from '../../children';
import { SelectableUser } from './selectable_user';
import { SelectedUser } from './selected_user';
import { Spinner } from '@planity/ui';
import { useErrorMessages } from '@planity/components';
import { useTranslation } from '@planity/localization';
import { noop, createChild, updateChild, useHotjarTag } from '@planity/helpers';
import { ChildrenLimitWarning } from '../../children';

const MAX_CHILDREN = 10;

export const SignedIn = ({
	user,
	userId,
	isVerified,
	isPro,
	isLoadingChildren,
	isPending,
	onSignOut,
	isPrePaymentToDelete,
	bookedByToDelete,
	bookedForToDelete,
	moveAppointment,
	paymentCreatedDateToDelete,
	setHasConfirmableUser,
	setRefetchChildren,
	totalPriceToDelete,
	totalPrice,
	isOnline,
	isBookAppointment,
	setSelectedUser = noop,
	selectedUser,
	userChildren,
	setChildren,
	countryCode,
	hasPhoneError
}) => {
	useStyles(styles);

	const { t } = useTranslation();
	const { pushMessage } = useErrorMessages();
	const isBelgium = countryCode === 'BE';

	const [hasEditedProfile, setHasEditedProfile] = useState(false);
	const [isEditingProfile, setIsEditingProfile] = useState(false);

	const hasUserPickedAChild =
		isBookAppointment && !!selectedUser && selectedUser !== user;

	// add hotjar tag when user has selected one of its children for the appointment
	useHotjarTag(hasUserPickedAChild ? 'bookForSomeone-SelectChild' : '');

	const classes = classNames.bind(styles)({
		user: true
	});

	const parent = {
		email: user.email,
		name: user.name,
		phone: user.phone,
		parentId: userId
	};

	const handleChildCreation = async child => {
		try {
			const childAdded = await createChild(child);
			setChildren(prevChildren => [...prevChildren, childAdded]);
			setSelectedUser(childAdded);
		} catch (e) {
			handleErrorMessage(pushMessage, t);
			console.error(e);
		}
	};

	const handleChildUpdating = async updatedChild => {
		try {
			const childUpdated = await updateChild(updatedChild);
			setChildren(prevChildren =>
				prevChildren.map(child =>
					child.childId === updatedChild.childId ? childUpdated : child
				)
			);
		} catch (e) {
			handleErrorMessage(pushMessage, t);
			console.error(e);
		}
	};

	useEffect(() => {
		const isConfirmable = !!isVerified && !isEditingProfile && !!selectedUser;
		setHasConfirmableUser(isConfirmable);
	}, [isVerified, isEditingProfile, selectedUser]);

	useEffect(() => {
		// back to initial state if component is unmounted
		return () => {
			setHasConfirmableUser(false);
		};
	}, []);

	useEffect(() => {
		if (hasPhoneError && !isEditingProfile) {
			setIsEditingProfile(true);
			setHasEditedProfile(true);
		}
	}, [hasPhoneError, isEditingProfile]);

	// not sure about using this or user.hasChildren
	const hasChildren = !!userChildren.length;

	useEffect(() => {
		if (!isBookAppointment) return;

		if (isBelgium) {
			setSelectedUser(user);
			return;
		}

		// if an existing appointment is moved
		if (moveAppointment) {
			// select the user or the child depending on who's the appointment for (including case where user is moving appt booked by parent)
			const finalSelectedUser = bookedForToDelete?.childId
				? userChildren.find(
						child => child.childId === bookedForToDelete.childId
				  )
				: user;
			!!finalSelectedUser && setSelectedUser(finalSelectedUser);
		} else if (!user?.hasChildren) {
			// if a user is logged & has no children for appointment, select the user
			setSelectedUser(user);
		}
		// otherwise we let the user select the person he wants the appointment for
	}, [user, isBookAppointment, moveAppointment, userChildren, isBelgium]);

	const addChildrenLabel = userChildren.length
		? t('bookAppointment.children.takeAppointmentForAnotherChildren')
		: t('bookAppointment.children.takeAppointmentForChildren');

	const hasMaxChildren = userChildren.length >= MAX_CHILDREN;

	const canAddFirstChild =
		!isBelgium && isBookAppointment && !hasChildren && !bookedByToDelete;

	return (
		<div className={classes}>
			<div className={styles.card}>
				{isEditingProfile ? (
					<div>
						<UserProfileForm
							isPro={isPro}
							isVerified={isVerified}
							requestPhoneError={hasPhoneError}
							showCancelButton
							user={user}
							userId={userId}
							onBooking
							onCancel={() => {
								setIsEditingProfile(false);
							}}
							onSignOut={onSignOut}
							onSuccess={() => {
								setIsEditingProfile(false);
								user?.hasChildren && setRefetchChildren(false);
							}}
						/>
					</div>
				) : (
					<div className={`${styles.logged} planity_appointment_user`}>
						{selectedUser ? (
							<>
								<SelectedUser
									bookedByToDelete={bookedByToDelete}
									canAddFirstChild={canAddFirstChild}
									hasChildren={hasChildren}
									isBelgium={isBelgium}
									isBookAppointment={isBookAppointment}
									isPending={isPending}
									isVerified={isVerified}
									selectedUser={selectedUser}
									setHasEditedProfile={setHasEditedProfile}
									setIsEditingProfile={setIsEditingProfile}
									setSelectedUser={setSelectedUser}
									user={user}
								/>
								{canAddFirstChild && (
									<div className={styles.addChild}>
										<AddChildButton
											handleChildCreation={handleChildCreation}
											label={addChildrenLabel}
											parent={parent}
											userChildren={userChildren}
										/>
									</div>
								)}
							</>
						) : (
							<>
								<div className={styles.chooseTitle}>
									<Localize text={'bookAppointment.children.chooseUserTitle'} />
								</div>
								{isLoadingChildren ? (
									<div className={styles.spinner}>
										<Spinner />
									</div>
								) : (
									<>
										<SelectableUser
											displayBadge={hasChildren}
											editParent={() => {
												setIsEditingProfile(true);
												setHasEditedProfile(true);
											}}
											isParent={true}
											isPending={isPending}
											isVerified={isVerified}
											setSelectedUser={setSelectedUser}
											user={user}
											userChildren={userChildren}
										/>
										{userChildren.map(child => (
											<SelectableUser
												handleChildUpdating={handleChildUpdating}
												isParent={false}
												isPending={isPending}
												key={child.childId}
												parent={parent}
												setSelectedUser={setSelectedUser}
												user={child}
												userChildren={userChildren}
											/>
										))}
									</>
								)}
								{hasMaxChildren && (
									<ChildrenLimitWarning
										className={styles.childrenLimitWarning}
									/>
								)}
								<div className={styles.addChild}>
									<AddChildButton
										handleChildCreation={handleChildCreation}
										label={addChildrenLabel}
										parent={parent}
										userChildren={userChildren}
									/>
								</div>
							</>
						)}
						<div>
							{(isVerified === null || isVerified === false) && (
								<PhoneVerificationForm sendSMSOnMount={!hasEditedProfile} />
							)}
						</div>
						{!isOnline && isPrePaymentToDelete && moveAppointment && (
							<div className={styles.errorMessage}>
								{!totalPrice ? (
									<Localize
										args={{
											date: paymentCreatedDateToDelete,
											priceToDelete: totalPriceToDelete
										}}
										text={'bookAppointment.errors.MOVED_TO_DEVIS'}
									/>
								) : (
									<Localize
										args={{
											date: paymentCreatedDateToDelete,
											priceToDelete: totalPriceToDelete,
											price: totalPrice
										}}
										text={'bookAppointment.errors.MOVED_TO_NO_ONLINE_PAYMENT'}
									/>
								)}
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

const handleErrorMessage = (pushMessage, t) => {
	pushMessage({
		message: t('children.defaultError'),
		type: 'error',
		createdAt: Date.now()
	});
};
