import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './booking_service.module.scss';
import { Button, RadioPicture, Select, useProfileGallery } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';
import AppointmentStep from '@planity/components/book_appointment/steps/step';
import { USER_REMOVED_STEP } from '@planity/components/book_appointment/events';
import { sanitizeName } from '@planity/helpers';
import { breakpoints, Match } from '@planity/theme';

/**
 * 3 ways to present calendars
 * simple: when there is more than one service || for sequence subSteps
 * complex: for sequence
 * images: when there is only one service
 */
export function BookingService({
	appointment,
	business,
	calendarChoose,
	renderCalendarName,
	calendars,
	canChooseCalendar,
	dispatch,
	isLimited,
	isMultiServices,
	isNested,
	isPending,
	path,
	pictures,
	price,
	service,
	setIsLimited,
	showMoreButton,
	step,
	stepIndex,
	duration,
	onSelect,
	type,
	hasAtLeastOnePicture
}) {
	useStyles(styles);
	const cx = classNames.bind(styles);
	const { t } = useTranslation();
	const {
		setProfileGalleryImages,
		openProfileGallery,
		chosenCalendar,
		setChosenCalendar,
		isOpen: profileGalleryIsOpen
	} = useProfileGallery();
	const [openedModal, setOpenedModal] = useState(null);
	const [calendarModal, setCalendarModal] = useState(null);
	const container = useRef();

	useEffect(() => {
		// This acts as a callback in useProfileGallery, when a calendar
		// has been selected. It could have been "onChosenCalendar"
		if (!profileGalleryIsOpen && chosenCalendar) {
			setOpenedModal(null);
		}
	}, [chosenCalendar, profileGalleryIsOpen]);
	useEffect(() => {
		if (chosenCalendar && calendarModal) {
			onSelect(chosenCalendar.calendar, calendarModal.index);
			setCalendarModal(null);
		}
		setChosenCalendar(null);
	}, [calendarChoose, chosenCalendar, onSelect, setChosenCalendar]);

	const isComplex = type === 'complex';

	const open = index => {
		setCalendarModal({ index });
		setProfileGalleryImages(pictures);
		openProfileGallery();
	};

	return (
		<div
			key={stepIndex}
			className={cx({
				bookingService: !isNested,
				showMore: showMoreButton,
				isComplex
			})}
		>
			<div className={isNested ? styles.subStep : styles.top}>
				<div className={isNested ? styles.nestedInfo : styles.info}>
					<span className={styles.name}>{sanitizeName(service.name)}</span>
					<span className={styles.details}>
						{!isNested && (
							<span>
								<span className={styles.duration}>{duration}</span>
								{price && <span className={styles.price}>{price}</span>}
							</span>
						)}
						<span
							className={
								canChooseCalendar || isComplex || !renderCalendarName()
									? styles.duration
									: styles.calendar
							}
						>
							{!!renderCalendarName && renderCalendarName()}
						</span>
					</span>
				</div>

				{(type === 'simple' || isMultiServices) && (
					<div
						className={
							isNested || isMultiServices
								? `${styles.multiService} ${styles.simpleSelect}`
								: `${styles.simpleSelect}`
						}
					>
						{canChooseCalendar &&
							calendarChoose.map((selectedCalendar, index) => (
								<Select
									key={index}
									className={styles.simpleSelect}
									defaultValue={t('bookAppointment.chooseCalendar.unselected')}
									onSelect={calendar => onSelect(calendar, index)}
									value={selectedCalendar?.name || ''}
									items={calendars}
									openModal={openedModal === index}
									setOpenModal={() =>
										setOpenedModal(openedModal !== null ? null : index)
									}
									hasAtLeastOnePicture={hasAtLeastOnePicture}
									openGallery={() => open(index)}
									isAttached
								/>
							))}
					</div>
				)}
				{!isNested && (
					<Button
						className={`${styles.remove} planity_ui_action_button_icon-remove planity_ui_action_button_root`}
						type='underlined'
						label={t('common.delete')}
						isLoading={isPending}
						onClick={() => dispatch(USER_REMOVED_STEP, { stepIndex })}
					/>
				)}
			</div>

			{/*Mobile*/}
			{(type === 'simple' || isMultiServices) && canChooseCalendar && (
				<div className={styles.simple}>
					{calendarChoose.map((selectedCalendar, index) => (
						<Select
							key={index}
							className={styles.select}
							defaultValue={t('bookAppointment.chooseCalendar.unselected')}
							isMobile
							isFullHeight
							value={selectedCalendar?.name || ''}
							onSelect={calendar => onSelect(calendar, index)}
							items={calendars}
							openModal={openedModal === index}
							setOpenModal={() =>
								setOpenedModal(openedModal !== null ? null : index)
							}
							hasNoRightPadding
							preventScroll
						>
							<div
								ref={container}
								className={cx({
									container: true,
									hasAtLeastOnePicture
								})}
							>
								<div className={styles.close}>
									<div className={styles.title}>
										{t('bookAppointment.chooseCalendar.unselected')}
									</div>
								</div>
								<div className={styles.radio}>
									{calendars.map((c, i) => (
										<RadioPicture
											key={i}
											name={c.id}
											label={c.name}
											imageUrl={c?.picture}
											value={selectedCalendar?.id || 'ANY'}
											onChange={() => {
												onSelect(c, index);
												setOpenedModal(null);
											}}
											hidePicture={!c.avatar} // hide ANY calendar pic
										/>
									))}
								</div>
								<div className={styles.actions}>
									{hasAtLeastOnePicture && (
										<Button
											label={t('bookAppointment.enlargePhotos')}
											type='primary'
											iconLeft='Maximize'
											isFullMobile
											onClick={() => open(index)}
										/>
									)}
								</div>
							</div>
						</Select>
					))}
				</div>
			)}

			{type === 'images' && !isMultiServices && canChooseCalendar && (
				<div className={styles.bottom}>
					<span className={styles.imagesTitle}>
						{t('bookAppointment.chooseCalendar.unselected')}
					</span>
					<div
						className={classNames.bind(styles)({
							profiles: true,
							isLimited,
							showMoreButton
						})}
					>
						{calendarChoose.map((selectedCalendar, index) => {
							return (
								<React.Fragment key={index}>
									{calendars.map((calendar, i) => (
										<RadioPicture
											key={i}
											name={calendar.id}
											label={calendar.name}
											imageUrl={calendar?.picture}
											value={selectedCalendar?.id || 'ANY'}
											onChange={() => onSelect(calendar, index)}
											hidePicture={!calendar.avatar} // hide ANY calendar pic
										/>
									))}
									{hasAtLeastOnePicture && (
										<div
											className={classNames.bind(styles)({
												maximize: true,
												isLimited
											})}
										>
											<Button
												label={t('bookAppointment.enlargePhotos')}
												type='underlined'
												iconLeft='Maximize'
												onClick={() => open(index)}
												className={'maximize'}
											/>
										</div>
									)}
								</React.Fragment>
							);
						})}
					</div>
					{showMoreButton && (
						<div className={styles.more}>
							<button
								onClick={() => setIsLimited(!isLimited)}
								onMouseDown={e => e.preventDefault()} // Prevent focus state on click
							>
								{isLimited
									? t('bookAppointment.expand')
									: t('bookAppointment.collapse')}
							</button>
						</div>
					)}
				</div>
			)}

			{type === 'complex' &&
				step?.sequence?.map((subStep, subStepIndex) => {
					return (
						<Match query={breakpoints.simpleTabletQuery} key={subStepIndex}>
							{isTablet => (
								<div className={styles.complex}>
									<AppointmentStep
										appointment={appointment}
										business={business}
										dispatch={dispatch}
										isMobile={!isTablet}
										isNested
										isPending={isPending}
										isSubstep
										path={[...path, subStepIndex]}
										step={subStep}
										stepIndex={stepIndex}
										synchronous={service.synchronous}
									/>
								</div>
							)}
						</Match>
					);
				})}
		</div>
	);
}
