import { useMemo, useState } from 'react';

const INITIAL_STATE = {
	isFocused: false,
	isPressed: false,
	isHovered: false
};

export function useInteractive({
	onMouseEnter: extOnMouseEnter,
	onMouseLeave: extOnMouseLeave,
	desktopOnly
} = {}) {
	const [state, setState] = useState(INITIAL_STATE);
	function handlePressDown() {
		setState(s => ({
			...s,
			isPressed: true
		}));
	}
	function handlePressUp() {
		setState(s => ({
			...s,
			isPressed: false
		}));
	}
	function handleHoverIn() {
		setState(s => ({
			...s,
			isHovered: true
		}));
	}
	function handleHoverOut() {
		setState(s => ({
			...s,
			isHovered: false
		}));
	}
	function onBlur() {
		setState(s => ({
			...s,
			isFocused: false
		}));
	}
	function onFocus() {
		setState(s => ({
			...s,
			isFocused: true
		}));
	}
	function onMouseDown(e) {
		handlePressDown(e);
	}
	function onMouseEnter(e) {
		handleHoverIn(e);
		if (extOnMouseEnter) {
			extOnMouseEnter();
		}
	}
	function onMouseLeave(e) {
		handlePressUp(e);
		handleHoverOut(e);
		if (extOnMouseLeave) {
			extOnMouseLeave();
		}
	}
	function onMouseUp(e) {
		handlePressUp(e);
	}
	function onTouchStart(e) {
		handlePressDown(e);
	}
	function onTouchEnd(e) {
		handlePressUp(e);
	}
	return useMemo(
		() => ({
			...state,
			hasInteraction: !!(state.isFocused || state.isPressed || state.isHovered),
			handlers: Object.assign(
				{
					onBlur,
					onFocus,
					onMouseDown,
					onMouseEnter,
					onMouseLeave,
					onMouseUp
				},
				desktopOnly
					? {}
					: {
							onTouchStart,
							onTouchEnd
					  }
			)
		}),
		[state, desktopOnly, extOnMouseEnter, extOnMouseLeave]
	);
}

export function Interactive({ children, ...props }) {
	const value = useInteractive(props);
	return children(value);
}
