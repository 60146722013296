import { AVAILABLE_LOCALES } from '@planity/localization';

/**
 * This function is used to remove the locale in url pathname before redirection
 * Used in multipage white-label website to handle redirection url with locales
 * @param {String} urlPathname url pathname with/without locale
 * @returns {String}
 * @example
 * // returns '/mon-compte'
 * removeLocaleFromPathname('/mon-compte')
 *
 * @example
 * // returns '/mein-konto'
 * removeLocaleFromPathname('/de-DE/mein-konto')
 */
export const removeLocaleFromPathname = urlPathname => {
	if (!urlPathname || urlPathname === '/') return '/';

	const splitPathname = urlPathname.split('/');
	if (AVAILABLE_LOCALES.includes(splitPathname[1])) {
		splitPathname.splice(1, 1);
	}

	return splitPathname.join('/');
};
