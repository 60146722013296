// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".business_reviews-module_reviews-F0Q30{width:100%}.business_reviews-module_reviews-F0Q30 .business_reviews-module_spinner-ucnTA{display:flex;justify-content:center;margin-top:15px;height:200px;align-items:center}.business_reviews-module_reviewsChunk-Fqw\\+l{display:none}.business_reviews-module_reviewsChunk-Fqw\\+l.business_reviews-module_isCurrent-w6Gum{display:block}", "",{"version":3,"sources":["webpack://./../../packages/ui/components/business/business_reviews/business_reviews.module.scss"],"names":[],"mappings":"AAAA,uCACC,UAAA,CACA,8EACC,YAAA,CACA,sBAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CAIF,6CACC,YAAA,CACA,qFACC,aAAA","sourcesContent":[".reviews {\n\twidth: 100%;\n\t.spinner {\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\tmargin-top: 15px;\n\t\theight: 200px;\n\t\talign-items: center;\n\t}\n}\n\n.reviewsChunk {\n\tdisplay: none;\n\t&.isCurrent {\n\t\tdisplay: block;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reviews": "business_reviews-module_reviews-F0Q30",
	"spinner": "business_reviews-module_spinner-ucnTA",
	"reviewsChunk": "business_reviews-module_reviewsChunk-Fqw+l",
	"isCurrent": "business_reviews-module_isCurrent-w6Gum"
};
module.exports = ___CSS_LOADER_EXPORT___;
