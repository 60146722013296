import { Localize } from '@planity/components';
import React from 'react';

export const DeliverySentence = ({ shippingExplanationSentence }) => {
	// 😡 😡 😡 😡
	const hardCodedDeliverySentence = new RegExp(
		/Envoyé sous (\d*) à (\d*) jours via (.*)/
	);
	const [
		,
		firstDayDeliveryEstimation,
		lastDayDeliveryEstimation,
		parcelCarrier
	] = shippingExplanationSentence.match(hardCodedDeliverySentence) || [];

	if (shippingExplanationSentence.match(hardCodedDeliverySentence)) {
		return (
			<Localize
				args={{
					firstDayDeliveryEstimation,
					lastDayDeliveryEstimation,
					parcelCarrier
				}}
				text={'clickAndCollect.deliverySentence'}
			/>
		);
	}
	return shippingExplanationSentence;
};
