import React, { Component, createContext } from 'react';
import { css } from 'glamor';

const MapContext = createContext({
	readyToRender: false,
	loadMap: () => {}
});

export class MapLoaderContextProvider extends Component {
	loadMapBox = () => {
		const { scripts } = document;
		const numberOfMapboxScript = Object.keys(scripts)
			.map(item => {
				const { src } = scripts[item];
				return src.includes('mapbox');
			})
			.filter(x => !!x);
		if (numberOfMapboxScript.length < 2) {
			const mapBoxScript = document.createElement('script');
			const mapBoxLanguage = document.createElement('script');
			const mapBoxStyle = document.createElement('link');
			mapBoxStyle.href =
				'https://api.tiles.mapbox.com/mapbox-gl-js/v2.11.0/mapbox-gl.css';
			mapBoxStyle.rel = 'stylesheet';
			mapBoxScript.src =
				'https://api.tiles.mapbox.com/mapbox-gl-js/v2.11.0/mapbox-gl.js';
			mapBoxLanguage.src =
				'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-language/v1.0.0/mapbox-gl-language.js';
			mapBoxScript.addEventListener('load', this.onLoadScript);
			mapBoxLanguage.addEventListener('load', this.onLoadLanguage);
			mapBoxStyle.addEventListener('load', this.onLoadStyle);
			mapBoxScript.rel = 'preconnect';
			document.body.appendChild(mapBoxScript);
			document.body.appendChild(mapBoxLanguage);
			document.body.appendChild(mapBoxStyle);
		} else if (typeof mapboxgl !== 'undefined') {
			this.setState({ readyToRender: true });
		}
	};

	state = {
		scriptIsLoaded: false,
		languageIsLoaded: false,
		styleIsLoaded: false,
		loadMap: isIE() ? () => {} : this.loadMapBox,
		readyToRender: false
	};

	componentWillUnmount() {
		document.removeEventListener('mapBoxScript', this.onLoadScript);
		document.removeEventListener('mapBoxLanguage', this.onLoadLanguage);
		document.removeEventListener('mapBoxScript', this.onLoadStyle);
	}

	onLoadStyle = () => {
		this.onLoad({ styleIsLoaded: true });
	};

	onLoadScript = () => {
		this.onLoad({ scriptIsLoaded: true });
	};
	onLoadLanguage = () => {
		this.onLoad({ languageIsLoaded: true });
	};

	onLoad = type => {
		this.setState(state => {
			const newState = { ...state, ...type };
			const { scriptIsLoaded, languageIsLoaded, styleIsLoaded } = newState;
			return {
				...newState,
				readyToRender: scriptIsLoaded && languageIsLoaded && styleIsLoaded
			};
		});
	};

	render() {
		return (
			<MapContext.Provider value={this.state}>
				{this.props.children}
			</MapContext.Provider>
		);
	}
}

export const withScriptMap = Component => props => {
	return (
		<MapContext.Consumer>
			{({ readyToRender, loadMap }) => (
				<Component loadMap={loadMap} readyToRender={readyToRender} {...props} />
			)}
		</MapContext.Consumer>
	);
};

const overrideStylePopup = {
	transform: 'none !important',
	top: 'unset !important',
	bottom: '24px !important',
	width: 'calc(100% - 30px) !important',
	left: '15px !important',
	backgroundColor: 'white'
};

css.global('.mapboxgl-popup-tip', { display: 'none' });
css.global('.mapboxgl-popup-content', {
	width: '100% !important',
	boxShadow: '0px 12px 24px -6px rgba(26, 27, 31, 0.12) !important',
	padding: '0px !important',
	minHeight: 92,
	height: 'unset !important',
	maxHeight: 'unset !important',
	border: 'solid 1px #e5e5e5 !important',
	maxWidth: 450,
	margin: 'auto',
	borderRadius: '8px !important'
});
css.global('.mapboxgl-popup-content > div', {
	height: '100%',
	display: 'flex',
	minHeight: 'inherit'
});
css.global('.mapboxgl-popup', overrideStylePopup);
css.global('. mapboxgl-popup-anchor-bottom', { transform: 'none !important' });
css.global('.mapboxgl-popup-close-button', { display: 'none' });
css.global('.mapboxgl-popup, .mapboxgl-popup-anchor-bottom', {
	backgroundColor: 'transparent !important',
	maxWidth: 'unset !important'
});

function isIE() {
	if (process.env.BROWSER) {
		var ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
		var msie = ua.indexOf('MSIE '); // IE 10 or older
		var trident = ua.indexOf('Trident/'); //IE 11
		return msie > 0 || trident > 0;
	} else {
		return false;
	}
}
