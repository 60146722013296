import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from '@planity/localization';
import { Accordion, Icon } from '@planity/ui';
import styles from './tag.module.scss';

export const Tag = ({
	currentCategoryId,
	suggestions,
	parentSuggestion,
	hasParentSuggestions,
	catSelect,
	onSelect,
	scrollLeft,
	scrollRight,
	closeModal,
	showAllTags
}) => {
	const container = useRef();
	const arrowLeft = useRef();
	const arrowRight = useRef();
	useStyles(styles);
	const [hasScroll, setHasScroll] = useState(false);

	const handleScrollOnClick = direction => {
		const SCROLL_STEP = direction === -1 ? -200 : 200;
		const element = container?.current;

		element.scrollTo({
			left: element?.scrollLeft + SCROLL_STEP,
			behavior: 'smooth'
		});
	};

	useEffect(() => {
		const el = container?.current;
		setHasScroll(el?.scrollWidth > el?.offsetWidth);
	}, [setHasScroll, container]);

	useEffect(() => {
		if (
			[container?.current, arrowLeft?.current, arrowRight?.current].every(
				x => !!x
			)
		) {
			const el = container?.current;
			const checkScroll = () => {
				const hasScrollLeft = el?.scrollLeft / 50;
				const hasScrollRight =
					(el?.scrollWidth - (el?.offsetWidth + el?.scrollLeft)) / 50 > 0.1;
				const isVisible = el?.scrollWidth <= el?.offsetWidth + 10;
				if (arrowRight.current && arrowLeft.current) {
					arrowLeft.current.style.display =
						!hasScrollLeft || isVisible ? 'none' : '';
					arrowRight.current.style.display =
						!hasScrollRight || isVisible ? 'none' : '';
					(!hasScrollLeft || isVisible) && setHasScroll(true);
				}
			};
			checkScroll();
			el?.addEventListener('scroll', checkScroll);
			return () => el?.removeEventListener('scroll', checkScroll);
		}
	}, [container?.current?.scrollWidth]);

	const { t } = useTranslation();
	const catParentTagName = { ...suggestions?.parentTag, name: t('tags.all') };

	const onTagSelect = useMemo(
		() => tag => {
			if (window && window.ga) {
				window.ga('send', {
					hitType: 'event',
					eventLabel: 'tag',
					eventCategory: 'click_on_tag_in_tag_bar',
					eventAction: tag.name
				});
			}

			if (tag.objectID === currentCategoryId) {
				if (suggestions?.parentTag?.objectID !== tag.objectID) {
					onSelect(suggestions.parentTag);
				}
			} else {
				onSelect(tag);
			}
			if (tag.objectID !== tag.parentTag) {
				closeModal();
			}
		},
		[suggestions, currentCategoryId, onSelect, closeModal]
	);

	const classes = classNames.bind(styles);

	return (
		<div ref={container} className={styles.tags}>
			{hasParentSuggestions && (
				<div className={`${styles.mobile} ${styles.selectMobile}`}>
					<Accordion
						title={parentSuggestion?.name || catParentTagName?.name}
						isCompact
						canToggle
						className={`${styles.mobile} ${styles.accordion}`}
					>
						<div className={styles.list}>
							{catSelect?.map((item, i) => (
								<button
									className={classNames.bind(styles)({
										item: true,
										isSelected: item.name === parentSuggestion?.name
									})}
									key={i}
									onMouseDown={() => onSelect(item, i)}
								>
									<span className={styles.radio} />
									<span className={styles.label}>{item.name}</span>
								</button>
							))}
						</div>
					</Accordion>
				</div>
			)}
			<div className={classes({ buttonsContainer: true })}>
				{!!suggestions?.parentTag && showAllTags && (
					<button
						className={classes({
							tag: true,
							catParent: currentCategoryId === suggestions?.parentTag?.objectID
						})}
						onClick={() => {
							onTagSelect(catParentTagName);
							closeModal();
						}}
					>
						<span>{catParentTagName?.name}</span>
					</button>
				)}
				{suggestions?.childrenTags.map(cat => {
					return (
						<button
							className={classes({
								tag: true,
								catParent: cat?.objectID === currentCategoryId
							})}
							key={cat.objectID}
							onClick={() => {
								onTagSelect(cat);
								closeModal();
							}}
						>
							<span>{cat.name}</span>
						</button>
					);
				})}
			</div>

			<div
				ref={arrowLeft}
				className={`${styles.fadeLeft} ${!hasScroll && styles.hasScroll}`}
			>
				<div
					ref={scrollLeft}
					className={styles.scrollLeft}
					aria-label='Scroll to left'
					onClick={() => handleScrollOnClick(-1)}
				>
					<Icon icon='ChevronLeft' size='medium' />
				</div>
			</div>

			<div
				ref={arrowRight}
				className={`${styles.fadeRight} ${!hasScroll && styles.hasScroll}`}
			>
				<div
					ref={scrollRight}
					className={styles.scrollRight}
					aria-label='Scroll to right'
					onClick={() => handleScrollOnClick(1)}
				>
					<Icon icon='ChevronRight' size='medium' />
				</div>
			</div>
		</div>
	);
};
