import { ErrorMessage } from '@planity/components';
import React from 'react';

export const AuthErrors = ({ hideWarnings, success, warning, error }) => {
	return (
		<React.Fragment>
			<ErrorMessage
				defaultMessage={'auth.errors.defaultError'}
				error={
					error
						? `auth.errors.${error}`
						: success
						? `auth.form.success.${success}`
						: warning && !hideWarnings && `auth.warnings.${warning}`
				}
				errorType={error ? 'error' : success ? 'success' : 'warning'}
			/>
		</React.Fragment>
	);
};
