import React from 'react';

export function Clock() {
	return (
		<svg
			width='25'
			height='24'
			viewBox='0 0 25 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M19.65 12C19.65 16.0041 16.4041 19.25 12.4 19.25C8.39593 19.25 5.14999 16.0041 5.14999 12C5.14999 7.99594 8.39593 4.75 12.4 4.75C16.4041 4.75 19.65 7.99594 19.65 12Z'
				stroke='currentColor'
				strokeWidth='1.5'
			/>
			<path
				d='M12.4 8V12L14.4 14'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
			/>
		</svg>
	);
}
