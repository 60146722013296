import credentials from '@planity/credentials';

export const useRecaptcha = onTokenResponse => {
	const { getRecaptchaToken } = initlializeRecaptcha(onTokenResponse);
	return { getRecaptchaToken };
};

export const initlializeRecaptcha = onTokenResponse => {
	if (!process.env.BROWSER)
		return { getRecaptchaToken: () => onTokenResponse() };
	const RECAPTCHA_SITE_KEY = credentials.RECAPTCHA_SITE_KEY;
	const recaptchaId = 'recaptcha-key';
	const recaptchaAPI = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_SITE_KEY}`;
	const recaptcha =
		document.getElementsByClassName('grecaptcha-badge') &&
		document.getElementsByClassName('grecaptcha-badge')[0];
	const scriptExists = document.getElementById(recaptchaId);

	if (!scriptExists) {
		let script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = recaptchaAPI;
		script.id = recaptchaId;
		document.body.appendChild(script);
	}

	//hide recaptcha
	if (recaptcha) {
		recaptcha.style = {};
		recaptcha.style.visibility = 'hidden';
		recaptcha.style.top = '0';
		recaptcha.style.position = 'absolute';
	}
	const getRecaptchaToken = callback => {
		window.grecaptcha.enterprise.ready(() => {
			window.grecaptcha.enterprise
				.execute(credentials.RECAPTCHA_SITE_KEY, { action: 'submit' })
				.then(callback);
		});
	};
	return { getRecaptchaToken: () => getRecaptchaToken(onTokenResponse) };
};
