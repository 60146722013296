import React, { useContext } from 'react';
import { ForgottenPasswordForm } from '../forms/forgotten_password_form';
import { ContextForm } from '../context_form';
import { Login } from '../forms/login/login';
import { SignUp } from '../forms/sign_up/sign_up';
import styles from '../authentication.module.scss';
import { BookingAuth } from '@planity/ui/components/authentication/booking_auth';

export const MemberConnection = () => {
	const SIGN_IN = 'SIGN_IN';
	const SIGN_UP = 'SIGN_UP';
	const FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD';
	const { authType } = useContext(ContextForm);
	return (
		<div className={styles.member} id='auth-form-emp'>
			{authType === 'AUTH_BOOKING' && <BookingAuth />}
			{authType === SIGN_IN && <Login />}
			{authType === FORGOTTEN_PASSWORD && <ForgottenPasswordForm />}
			{authType === SIGN_UP && <SignUp />}
		</div>
	);
};
