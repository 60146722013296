import React from 'react';
import { CloudinaryPicture } from '@planity/components';
import { Pin, Star } from '@planity/ui';
import Address from '@planity/components/address';
import { useTranslation } from '@planity/localization';
import SymbolEuro from '@planity/components/symbol_euro';

export function InfoWindow({ business, history, localizedRoutes: routes }) {
	const { t } = useTranslation();
	const locality = business?.address?.locality;
	const globalRating = business?.globalRating;
	const globalAverage = business?.globalAverage;
	const ratingsCount = business?.ratingsCount; //business['ratingsCount'] || 0;
	const globalBusinessRating = business?.rating?.globalRating || globalRating;
	const globalRatingValue =
		globalBusinessRating && globalBusinessRating % 1 !== 0
			? globalBusinessRating.toFixed(1)
			: globalBusinessRating;

	return (
		<div
			css={styles.window}
			id={`business-${business.objectID}`}
			onClick={() => {
				history.push(routes.catchAll({ business }));
			}}
		>
			{business?.pictures?.length ? (
				<div css={styles.image}>
					<CloudinaryPicture
						alt={`image-${business.name}`}
						css={styles.img}
						src={business?.pictures?.at(0)}
						transformation={'c_thumb,g_center,h_256,w_256'}
					/>
				</div>
			) : (
				<div css={[styles.img, styles.empty]}>
					<img
						alt={`image-${business.name}`}
						css={styles.noImage}
						src={'https://d2skjte8udjqxw.cloudfront.net/pics/logo.svg'}
					/>
				</div>
			)}
			<section css={styles.infoContent}>
				<span css={styles.title}>{business.name}</span>
				<section css={styles.row}>
					{!!locality && (
						<div css={[styles.infoFlex, styles.infoRating]}>
							<div css={styles.icon}>
								<Pin />
							</div>
							<Address
								{...business?.address}
								distance={business?._rankingInfo?.geoDistance}
								isSearch={true}
							/>
						</div>
					)}
					<div css={styles.infoFlex}>
						{!!globalRating && (
							<div css={styles.infoFlex}>
								<div css={[styles.icon, styles.star]}>
									<Star outlined />
								</div>
								<span css={styles.infoDetail}>
									{globalRatingValue +
										(ratingsCount
											? ' (' +
											  t('search.reviewsNumber', { number: ratingsCount }) +
											  ')'
											: '')}
								</span>
							</div>
						)}
						{!!globalAverage && (
							<div css={styles.infoFlex}>
								{!!globalRating && <span css={styles.dotSeparator}>{'•'}</span>}
								<span css={styles.infoDetail}>
									<SymbolEuro rating={globalAverage} />
								</span>
							</div>
						)}
					</div>
				</section>
			</section>
		</div>
	);
}

const styles = {
	window: {
		width: '100%',
		cursor: 'pointer',
		borderRadius: 8,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: 'var(--white)',
		font: 'var(--regular) var(--label-1)',
		color: 'var(--grey-600)'
	},
	icon: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 16,
		height: 16
	},
	title: {
		fontWeight: 500,
		fontSize: 14,
		lineHeight: '24px',
		font: 'var(--medium) var(--body-4)',
		color: 'var(--grey-700)'
	},
	row: {
		width: '100%',
		fontWeight: 400,
		fontSize: 12,
		lineHeight: '16px'
	},
	infoContent: {
		marginLeft: 12,
		flex: 1
	},
	infoFlex: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	infoDetail: {
		lineHeight: '24px'
	},
	infoRating: {
		marginBottom: 2,
		alignItems: 'flex-start'
	},
	image: {
		height: '100%'
	},
	img: {
		width: 80,
		height: '100%',
		objectFit: 'cover',
		borderRadius: '8px 0 0 8px'
	},
	empty: {
		backgroundColor: 'var(--grey-700)',
		position: 'relative'
	},
	noImage: {
		width: 65,
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	},
	dotSeparator: {
		color: 'var(--grey-400)',
		margin: '0 8px'
	}
};
