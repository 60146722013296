import { useLocalization } from '@planity/localization';
import React from 'react';
import capitalize from 'lodash/capitalize';

const NOW = new Date();

export const AppointmentDate = ({ appointmentStart, className }) => {
	const { locale } = useLocalization();
	// This is sooooooo powerful <3 <3 <3 <3
	const optionsUpper = {
		year:
			appointmentStart.getFullYear() === NOW.getFullYear()
				? undefined
				: 'numeric',
		month: 'short',
		day: '2-digit'
	};
	const optionBottom = { weekday: 'long' };
	const optionTime = { hour: 'numeric', minute: 'numeric' };

	const upperDate = new Intl.DateTimeFormat(locale, optionsUpper).format(
		appointmentStart
	);
	const bottomDate = new Intl.DateTimeFormat(locale, optionBottom).format(
		appointmentStart
	);
	const time = new Intl.DateTimeFormat(locale, optionTime).format(
		appointmentStart
	);
	return (
		<span className={className}>
			<span id='appointment-date'>
				{capitalize(bottomDate)} {upperDate}&nbsp;
			</span>
			<span id='appointment-time'>{time}</span>
		</span>
	);
};
