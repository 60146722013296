export function EyeOff() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='25'
			height='24'
			viewBox='0 0 25 24'
			fill='none'
		>
			<path
				d='M19.0247 10C19.4645 10.8986 19.65 11.6745 19.65 12C19.65 13 17.9 18.25 12.4 18.25C11.6686 18.25 11.0035 18.1572 10.4 17.9938M7.39999 16.2686C5.76209 14.6693 5.14999 12.5914 5.14999 12C5.14999 11 6.89999 5.75 12.4 5.75C14.1947 5.75 15.5901 6.30902 16.6557 7.09698'
				stroke='#08080B'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M19.65 4.75L5.14999 19.25'
				stroke='#08080B'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M10.809 13.591C9.93032 12.7123 9.93032 11.2877 10.809 10.409C11.6877 9.53033 13.1123 9.53033 13.991 10.409'
				stroke='#08080B'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
