export function Visa() {
	return (
		<svg
			width='50'
			height='34'
			viewBox='0 0 50 34'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<rect
				x='0.75'
				y='0.75'
				width='48.5'
				height='32.5'
				rx='4.25'
				fill='#F7F7F7'
				stroke='#C7CFD7'
				strokeWidth='0.5'
			/>
			<path
				d='M22.8646 22.207H20.2725L21.8938 12.1818H24.4858L22.8646 22.207Z'
				fill='#00579F'
			/>
			<path
				d='M32.2641 12.4268C31.7528 12.224 30.9419 12 29.9391 12C27.3793 12 25.5766 13.3651 25.5656 15.3167C25.5443 16.7566 26.8562 17.5563 27.8374 18.0364C28.8403 18.527 29.1812 18.8472 29.1812 19.2845C29.171 19.9561 28.3708 20.2657 27.6245 20.2657C26.5896 20.2657 26.0351 20.1061 25.1924 19.7325L24.851 19.5723L24.4883 21.8227C25.0962 22.0997 26.2164 22.3455 27.3793 22.3563C30.0992 22.3563 31.8699 21.0123 31.8909 18.9326C31.9012 17.7913 31.2085 16.9169 29.715 16.2023C28.8084 15.7436 28.2532 15.4343 28.2532 14.965C28.2638 14.5383 28.7228 14.1013 29.7462 14.1013C30.5889 14.0799 31.2081 14.2825 31.6771 14.4852L31.9116 14.5916L32.2641 12.4268Z'
				fill='#00579F'
			/>
			<path
				d='M35.711 18.6554C35.9244 18.0795 36.7459 15.8505 36.7459 15.8505C36.7351 15.8719 36.9589 15.2639 37.0869 14.8907L37.2681 15.7545C37.2681 15.7545 37.759 18.1542 37.8656 18.6554C37.4605 18.6554 36.223 18.6554 35.711 18.6554ZM38.9107 12.1818H36.9056C36.2873 12.1818 35.8176 12.363 35.5508 13.0136L31.7004 22.2068H34.4203C34.4203 22.2068 34.8681 20.9695 34.9644 20.703C35.2628 20.703 37.9087 20.703 38.2925 20.703C38.3669 21.0549 38.6018 22.2068 38.6018 22.2068H41.0019L38.9107 12.1818Z'
				fill='#00579F'
			/>
			<path
				d='M18.112 12.1818L15.5734 19.018L15.296 17.6315C14.8267 16.0318 13.3548 14.2936 11.7122 13.4294L14.0374 22.1963H16.7786L20.853 12.1818H18.112Z'
				fill='#00579F'
			/>
			<path
				d='M13.2132 12.1818H9.04267L9 12.3844C12.2533 13.2163 14.4079 15.2217 15.2931 17.632L14.3865 13.0245C14.2372 12.3842 13.7785 12.2029 13.2132 12.1818Z'
				fill='#FAA61A'
			/>
		</svg>
	);
}
