import React, { useEffect, useState } from 'react';
import { Localize } from '@planity/components';
import classNames from 'classnames/bind';
import styles from './service_item.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { noop } from '@planity/helpers';
import { useTranslation } from '@planity/localization';
import { Button, Input } from '@planity/ui';
import { formatPrice } from '@planity/helpers';

const maxVariableAmount = 1000;

export default function VoucherItem({
	voucher,
	onRemove,
	updatePrice,
	totalPrice,
	giftVoucherPrice,
	variablePriceValidation,
	onSelect = noop,
	hideChose,
	index,
	hasSelectedVariableVoucher,
	onBooking,
	reinjectData,
	isPending
}) {
	useStyles(styles);
	const { t } = useTranslation();
	const isVariable = voucher.isVariableAmount;
	const classes = classNames.bind(styles)({
		bookingCart: true,
		isVariable,
		isVariableSelected: hasSelectedVariableVoucher,
		onBooking
	});

	const [variablePrice, setVariablePrice] = useState(totalPrice || '');
	const [isMin, setIsMin] = useState(false);
	const price = voucher.defaultAmount;
	const updateVariablePrice = isVariable && isMin && giftVoucherPrice;

	useEffect(() => {
		updatePrice && isVariable && updatePrice(variablePrice);
	}, [variablePrice]);

	useEffect(() => {
		setIsMin(reinjectData);
	}, [reinjectData]);

	return (
		<div
			className={classes}
			itemProp={'itemListElement'}
			itemScope
			itemType={'https://schema.org/Offer'}
			onClick={() => onSelect(voucher)}
		>
			<div className={onBooking ? styles.card : styles.voucherContainer}>
				<div className={styles.voucher}>
					<div className={styles.info}>
						<div>
							<span
								className={styles.name}
								id={'voucher-name'}
								itemProp={'name'}
							>
								{voucher.name}
							</span>
							{!!voucher.restrict && voucher.restrict !== 'none' && (
								<span
									className={styles.restrict}
									id={'voucher-restrict'}
									itemProp={'restrict'}
								>
									{t(`gift.restrict.${voucher.restrict}`)}
								</span>
							)}
						</div>

						<span className={styles.simplePrice}>
							{!isVariable
								? formatPrice(price)
								: parseFloat(giftVoucherPrice) > 0
								? giftVoucherPrice
								: t('gift.variableAmount')}
						</span>
					</div>
					{!hideChose && (
						<Button
							className={
								'planity-gift-voucher-button-choose planity-secondary-color planity-secondary-border'
							}
							id={`voucher-choose-${index}`}
							label={t('gift.choose')}
							size='small'
						/>
					)}
					{(updateVariablePrice || !!onRemove) && (
						<div className={styles.actions}>
							{updateVariablePrice && (
								<Button
									isLoading={isPending}
									label={t('myAccount.giftVouchers.modify')}
									type={'underlined'}
									onClick={() => setIsMin(false)}
								/>
							)}
							{!!onRemove && (
								<Button
									className={
										'planity_ui_action_button_root planity_ui_action_button_icon-remove'
									}
									isLoading={isPending}
									label={t('myAccount.giftVouchers.delete')}
									type={'underlined'}
									onClick={() => {
										onRemove({
											giftVoucherPrice: isVariable
												? 0
												: parseFloat(giftVoucherPrice),
											index,
											price
										});
									}}
								/>
							)}
						</div>
					)}
				</div>
				{hasSelectedVariableVoucher && !isMin && (
					<div className={styles.variable}>
						<Localize text='myAccount.giftVouchers.verify' />
						<Input
							autoFocus
							pattern={'[0-9]*'}
							placeholder={t('gift.variable')}
							value={variablePrice}
							onChange={value => {
								setVariablePrice(
									value.replace(/\D/, '') <= maxVariableAmount
										? value.replace(/\D/, '')
										: variablePrice
								);
							}}
						/>
						<Button
							id={'variable-price-validation'}
							isDisabled={variablePrice <= 0}
							isFullWidth
							label={t('gift.validate')}
							onClick={() => {
								setIsMin(true);
								variablePriceValidation();
							}}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
