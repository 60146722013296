// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu_overlay-module_menuOverlay-N5Btr{z-index:1000;position:fixed;display:grid;grid-template-rows:1fr;grid-template-columns:1fr 1fr;top:0;left:0;right:0;bottom:0;height:100%}.menu_overlay-module_menuOverlay-N5Btr .menu_overlay-module_menu-Ap7b8{display:flex;flex:1;z-index:1002;grid-area:1/1/1/1;max-width:311px}.menu_overlay-module_menuOverlay-N5Btr .menu_overlay-module_overlay-\\+RmSB{z-index:1001;background-color:rgba(0,0,0,.69);grid-area:1/1/1/3}", "",{"version":3,"sources":["webpack://./../../packages/ui/uikit/navigation/menu_overlay/menu_overlay.module.scss"],"names":[],"mappings":"AAAA,uCACC,YAAA,CACA,cAAA,CAEA,YAAA,CACA,sBAAA,CACA,6BAAA,CAEA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CAEA,WAAA,CAEA,uEACC,YAAA,CACA,MAAA,CACA,YAAA,CACA,iBAAA,CACA,eAAA,CAGD,2EACC,YAAA,CACA,gCAAA,CACA,iBAAA","sourcesContent":[".menuOverlay {\n\tz-index: 1000;\n\tposition: fixed;\n\n\tdisplay: grid;\n\tgrid-template-rows: 1fr;\n\tgrid-template-columns: 1fr 1fr;\n\n\ttop: 0;\n\tleft: 0;\n\tright: 0;\n\tbottom: 0;\n\n\theight: 100%;\n\n\t.menu {\n\t\tdisplay: flex;\n\t\tflex: 1;\n\t\tz-index: 1002;\n\t\tgrid-area: 1/1/1/1;\n\t\tmax-width: 311px;\n\t}\n\n\t.overlay {\n\t\tz-index: 1001;\n\t\tbackground-color: rgba(0, 0, 0, 0.69);\n\t\tgrid-area: 1/1/1/3;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuOverlay": "menu_overlay-module_menuOverlay-N5Btr",
	"menu": "menu_overlay-module_menu-Ap7b8",
	"overlay": "menu_overlay-module_overlay-+RmSB"
};
module.exports = ___CSS_LOADER_EXPORT___;
