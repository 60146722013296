import React from 'react';
import { breakpoints } from '@planity/theme';
import { BusinessGallery } from '@planity/ui';

const transformations = {
	default: 't_d_main',
	breakpoints: [
		{ query: breakpoints.desktopQuery, transformation: 't_d_main' },
		{ query: breakpoints.tabletQuery, transformation: 't_d_main' },
		{ query: breakpoints.phoneQuery, transformation: 't_m_main' }
	]
};

export default function BusinessSlideshow({ business, className }) {
	const { pictures } = business;

	return (
		<div css={[styles.wrapper, className]}>
			<BusinessGallery images={pictures} transformations={transformations} />
		</div>
	);
}

const styles = {
	wrapper: {
		marginTop: 0,
		[breakpoints.phoneQuery]: {
			borderRadius: 0,
			margin: '0 -16px'
		}
	}
};
