import {
	capitalize,
	formatCategoryTitle,
	formatPlaceTitle,
	safeRead
} from '@planity/helpers';
import { useLocalization, useTranslation } from '@planity/localization';
import React from 'react';
import Helmet from 'react-helmet';

export default function SearchPageMetadata({ search }) {
	const { t } = useTranslation();
	const { language } = useLocalization();
	const { category, parentCategory, parentPlace, place } = search;

	const isParisBeautySalon =
		parentCategory &&
		parentCategory.objectID === 'beauty_salon' &&
		!category &&
		parentPlace &&
		parentPlace.objectID === 'hw7' &&
		!place;
	const title = isParisBeautySalon
		? t('search.meta.parisBeautySalon.title')
		: metaTitle(search, t, language);
	const description = metaDescription(search, t, language);

	return (
		<Helmet>
			<title>{title}</title>
			<meta content={description} name={'description'} />
			<meta content={description} property={'og:description'} />
		</Helmet>
	);
}

// This is the adaptation of the search.js file
// https://github.com/planity/next/blob/5adde22a9b58b05cf1fa885d2afb8f63e3f07438/packages/translations/fr/search.js
function metaTitle(
	{ parentCategory, category, parentPlace, place, googlePlace, page },
	t,
	language
) {
	const MAXIMUM_TITLE_SIZE = 60;
	const searchCategory = category || parentCategory;
	const searchPlace = googlePlace || place || parentPlace;

	const categorySuffix =
		searchCategory &&
		formatCategoryTitle(searchCategory, t, language, !!googlePlace);
	const placeTitle = formatPlaceTitle(
		{
			leafPlace: place || parentPlace,
			googlePlace,
			userLocation: null,
			searchPlace
		},
		t
	);

	const placeSuffix2 = searchPlace && safeRead(searchPlace, ['postalCodes', 0]);
	const title = [
		page && t('search.meta.page', { page }),
		categorySuffix,
		categorySuffix && placeTitle,
		categorySuffix && placeSuffix2,
		' - Planity'
	]
		.reduce((title, token) => {
			if (!!token && title.length + token.length + 1 < MAXIMUM_TITLE_SIZE) {
				title += token + ' ';
			}
			return title;
		}, '')
		.trim();
	return correctSpacing(title);
}

function metaDescription(
	{ parentCategory, category, parentPlace, place, googlePlace },
	t,
	language
) {
	const searchCategory = category || parentCategory;
	const searchPlace = googlePlace || place || parentPlace;
	const categoryName = capitalize(
		(
			(searchCategory && (searchCategory.singular || searchCategory.name)) ||
			t('search.meta.category.defaultName.description')
		).toLowerCase(),
		['de'].includes(language)
	);

	const isFeminine = searchCategory && searchCategory.isFeminine;

	// Yes, "startingWithAVowel" is a gender
	const categoryGender = getCategoryGender(
		searchCategory,
		categoryName,
		language,
		false
	);
	const categoryGenderIncludingVowelStart = getCategoryGender(
		searchCategory,
		categoryName,
		language,
		true
	);

	if (searchPlace) {
		// Vous recherchez un/une ${categoryName} ${placePrep} ${searchPlace.name} ?
		// Trouvez en quelques clics le/la/l' ${categoryName} le/la plus proche de chez vous.....
		const placeTitle = formatPlaceTitle(
			{
				leafPlace: place || parentPlace,
				googlePlace,
				userLocation: null,
				searchPlace
			},
			t
		);
		return t('search.meta.description.withPlace', {
			placeTitle,
			undefinedCategoryName: t(
				`search.meta.category.undefined.${categoryGender}`,
				{ categoryName }
			),
			definedCategoryName: t(
				`search.meta.category.defined.${categoryGenderIncludingVowelStart}`,
				{
					categoryName,
					definedArticle: t(`search.meta.article.defined.${categoryGender}`)
				}
			),
			definedArticle: t(`search.meta.article.defined.${categoryGender}`)
		});
	}
	// Vous recherchez un/une ${categoryName} ?
	// Trouvez en quelques clics le/la/l' ${categoryName} le/la plus proche de chez vous.....
	return t('search.meta.description.withoutPlace', {
		undefinedCategoryName: t(
			`search.meta.category.undefined.${isFeminine ? 'feminine' : 'masculine'}`,
			{ categoryName }
		),
		definedCategoryName: t(`search.meta.category.defined.${categoryGender}`, {
			categoryName,
			definedArticle: t(
				`search.meta.article.defined.${isFeminine ? 'feminine' : 'masculine'}`
			)
		}),
		definedArticle: t(
			`search.meta.article.defined.${isFeminine ? 'feminine' : 'masculine'}`
		)
	});
}

function correctSpacing(str) {
	if (!str) {
		return str;
	}
	return str.replace(/\s+/g, ' ');
}

/**
 * That's a mess, but it makes us have the correct sentences.
 * Yes "startingWithAVowel" is a gender in french
 * @param category
 * @param categoryName
 * @param language
 * @param includeVowelStartCheck do you want "l'" instead of "le" or "la"
 * @return {string} one of "masculine", "feminine", "neutral" and "startingWithAVowel"
 */
function getCategoryGender(
	category,
	categoryName,
	language,
	includeVowelStartCheck
) {
	if (!category) return 'masculine';
	const { gender, isFeminine } = category;
	// If a gender is set, use it \o/
	if (gender) return gender;

	// If it starts with a vowel and we're in french and
	// we should use "l'" instead of "le" or "la"...
	if (
		includeVowelStartCheck &&
		!['de'].includes(language) &&
		categoryName
			.charAt(0)
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.match(/^[aeiouyh]/i)
	)
		return 'startingWithAVowel';

	// If the "old" way of doing is here
	if (isFeminine) return 'feminine';

	// 🍆 🍆 🍆
	return 'masculine';
}
