import { useLocalizedRoutes } from '@planity/localization';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Localize } from '@planity/components';
import { InlineLink } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './more_infos.module.scss';
import classNames from 'classnames/bind';

const MOBILE_COLLAPSED_CHILDREN_SIZE = 2;

export function MoreInfos({
	business,
	services,
	search,
	isCollapsing,
	setIsCollapsed,
	isCollapsed
}) {
	useStyles(styles);
	return (
		<div className={styles.moreInfos}>
			{(business.reviews || business.description) && (
				<CollapsibleContainer
					business={business}
					isCollapsed={isCollapsed}
					isCollapsing={isCollapsing}
					search={search}
					services={services}
					setIsCollapsed={setIsCollapsed}
				/>
			)}
		</div>
	);
}

const CollapsibleContainer = ({
	business,
	services,
	search,
	isCollapsing,
	setIsCollapsed,
	isCollapsed
}) => {
	useStyles(styles);
	const setSize = 10;
	const cx = classNames.bind(styles);
	const history = useHistory();
	const { routes } = useLocalizedRoutes();

	return (
		<Fragment>
			<div
				className={cx({
					moreInfosWrapper: true,
					isCollapsed
				})}
				onClick={() => {
					history.push(
						routes.catchAll({
							business: business,
							state: {
								highlightServices: services,
								search: search,
								scrollToPresta: !!search.category
							}
						})
					);
				}}
			/>
			{setSize > MOBILE_COLLAPSED_CHILDREN_SIZE && (
				<div className={styles.actions}>
					<CollapsedLink
						isCollapsed={isCollapsed}
						isCollapsing={isCollapsing}
						maxSize={MOBILE_COLLAPSED_CHILDREN_SIZE}
						setIsCollapsed={() => setIsCollapsed(!isCollapsed)}
						setSize={setSize}
					/>
				</div>
			)}
		</Fragment>
	);
};

const CollapsedLink = ({
	setIsCollapsed,
	isCollapsed,
	setSize,
	maxSize,
	isCollapsing
}) => (
	<InlineLink
		className={'planity_ui_showMoreservices'}
		label={
			<Localize
				args={{
					size: setSize - maxSize
				}}
				text={isCollapsed ? 'business.moreInfosCta' : 'business.lessInfosCta'}
			/>
		}
		onClick={() => {
			isCollapsing(true);
			setIsCollapsed(!isCollapsed);
			setTimeout(() => isCollapsing(false), 450);
		}}
	/>
);
