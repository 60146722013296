import React from 'react';
import { Layout } from '../layout';
import { useTranslation, useLocalization } from '@planity/localization';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './legal_page.module.scss';
import { BusinessTitle, Container } from '@planity/ui';

export function IllicitContentPolicy() {
	const { t } = useTranslation();
	useStyles(styles);
	const classes = classNames.bind(styles)({
		legal: true
	});
	return (
		<div className={classes}>
			<Layout
				breadcrumbsAtTop
				crumbName={'illicitContentPolicy'}
				isDark
				showAppTeaser
				skipCoronavirusModal
			>
				<Container>
					<BusinessTitle path={'common.illicitContentPolicy'} />
					<div className={styles.container}>
						<iframe src={t('links.illicitContentPolicy')} />
					</div>
				</Container>
			</Layout>
		</div>
	);
}
