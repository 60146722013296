import { Input, InputPassword, Button, InlineLink } from '@planity/ui';
import {
	EMAIL_CHANGE,
	PASSWORD_CHANGE,
	useFormHooks
} from '@planity/components';
import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from '../authentication/authentication.module.scss';
import { MemberConnection } from '@planity/ui/components';
import { useUikit } from '@planity/ui/shared/useUikit';
import { checkInputError } from '@planity/components';
import { useTranslation } from '@planity/localization';
import { useTheme } from '@planity/context';

export function Login({
	navigateLogState,
	data,
	localizedText,
	pending,
	handleDispatchEvent,
	handleDispatchInputs,
	isDark
}) {
	const { formValues } = useFormHooks();
	const { authBooking } = formValues;
	const classes = classNames.bind(styles)({
		auth: true,
		connection: authBooking,
		isDark,
		login: true
	});
	useStyles(styles);
	const { isPreview } = useTheme();
	const { toggle, toggleIcon } = useUikit();
	const { t } = useTranslation();

	return (
		<div id='login-comp' className={classes}>
			{!authBooking && <h1 className={styles.title}>{localizedText.title}</h1>}

			<form className={styles.form}>
				<div className={styles.fields}>
					<Input
						id={'login-comp__input-email'}
						autoFocus={!isPreview}
						label={localizedText.labels.email}
						isRequired
						name='email'
						value={data && data.email}
						placeholder={localizedText.placeholders.email}
						onChange={email =>
							handleDispatchInputs({ type: EMAIL_CHANGE, email })
						}
						error={checkInputError('email', t, data)?.format}
						errorMessage={checkInputError('email', t, data)?.message}
						type='email'
					/>
					<InputPassword
						id={'login-comp__input-password'}
						label={localizedText.labels.password}
						isRequired
						name='password'
						icon={toggle ? 'Eye' : 'EyeOff'}
						value={data && data.password}
						placeholder={localizedText.placeholders.password}
						onChange={password =>
							handleDispatchInputs({ type: PASSWORD_CHANGE, password })
						}
						onToggleIcon={toggleIcon}
						toggle={toggle}
						error={checkInputError('password', t, data)?.format}
						errorMessage={checkInputError('password', t, data)?.message}
					/>
				</div>
				<div className={styles.linksButton}>
					<Button
						className={styles.submit}
						size='large'
						label={localizedText.labels.button}
						isFullMobile
						onClick={event => {
							handleDispatchEvent({ event: event, type: 'login' });
						}}
						isLoading={pending}
						isDisabled={pending}
						id={'login-comp__submit'}
					/>
					<div className={styles.reset}>
						<InlineLink
							id={'login-comp__inline-link'}
							label={localizedText.labels.forgetPassword}
							onClick={() => {
								navigateLogState('reset');
							}}
						/>
					</div>
				</div>
			</form>

			<MemberConnection
				id={'login-comp__memberBtn'}
				title={localizedText.memberConnection.title}
				label={localizedText.memberConnection.label}
				navigateLogState={() => navigateLogState('signup')}
				isDark={isDark && !authBooking}
			/>
		</div>
	);
}
