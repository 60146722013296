import React, { useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Icon } from '@planity/ui';
import styles from './accordion_child.module.scss';

export function AccordionChild({ name, phone, email, children }) {
	const [isCollapsed, setIsCollapsed] = useState(true);
	useStyles(styles);
	return (
		<div className={styles.container}>
			<section
				className={styles.headerSection}
				onClick={() => setIsCollapsed(prev => !prev)}
			>
				<div className={styles.headerInfoBox}>
					<div className={styles.name}>{name}</div>
					<div className={styles.infoBox}>
						{email && (
							<div className={styles.infoItem}>
								<Icon icon='Email' size={'small'} />
								{/* chrome automatically converts numbers and emails into links for text tags,
								so we use <a/> and disable style/functionality [PLAN-14890] ⛔️ */}
								<a className={styles.infoText} href={`mailto:${email}`}>
									{email}
								</a>
							</div>
						)}
						{email && phone && <Icon className={styles.dot} icon='Dot' />}
						{phone && (
							<div className={styles.infoItem}>
								<Icon icon='PhoneIcon' size={'small'} stroke='#4E5860' />
								<a className={styles.infoText} href={`tel:${phone}`}>
									{phone}
								</a>
							</div>
						)}
					</div>
				</div>
				<div className={styles.action}>
					<Icon
						className={
							isCollapsed ? styles.collapsedActionIcon : styles.extendedAction
						}
						color='#202020'
						icon='ChevronLeft'
						size='medium'
					/>
				</div>
			</section>
			<section
				className={
					isCollapsed ? styles.collapsedContent : styles.extendedContent
				}
			>
				{children}
			</section>
		</div>
	);
}
