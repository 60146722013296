import { useLocalizedRoutes, useTranslation } from '@planity/localization';
import {
	FakeSearchBar,
	SearchBarButton,
	WhatSearchBar,
	WhereSearchBar
} from '@planity/ui';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useSearch } from '@planity/context';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './category_page_search_bar.module.scss';

export const CategoryPageSearchBar = withRouter(({ history, category }) => {
	useStyles(styles);
	const [whatResult, setWhatResult] = useState(category);
	const [whereResult, setWhereResult] = useState(null);

	const whereSearchBarRef = useRef(null);

	const { setSearch } = useSearch();
	const { t } = useTranslation();
	const { routes } = useLocalizedRoutes();
	const classes = classNames.bind(styles);
	const onSubmit = useCallback(() => {
		if (whereResult || whatResult) {
			setSearch(
				{
					category: whatResult || null,
					place: whereResult || null,
					parentPlace: null,
					userLocation: null,
					googlePlace: whereResult || null
				},
				true,
				'push'
			);
		}
	}, [whereResult, whatResult, setSearch]);
	useEffect(() => {
		if (whatResult) whereSearchBarRef?.current?.focus();
	}, [whatResult]);
	useEffect(() => {
		if (whereResult && whatResult) {
			onSubmit();
		}
	}, [whereResult, whatResult, onSubmit]);
	return (
		<div className={classes({ categoryPageSearchBar: true })}>
			<div className={styles.container}>
				<WhatSearchBar
					initialWhatResult={category}
					onSelect={onSubmit}
					setWhatResult={setWhatResult}
					displayOnlyOnDesktop
				/>
				<FakeSearchBar
					label={t('search.placeholders.placeShort')}
					placeholder={t('search.placeholders.place')}
					displayOnlyOnMobileAndTablet
					onClick={() => history.push(routes.searchPlace)}
				/>
				<div className={classes({ separator: true })} />
				<WhereSearchBar
					ref={whereSearchBarRef}
					displayOnlyOnDesktop
					setWhereResult={setWhereResult}
				/>
				<SearchBarButton onClick={onSubmit} id={'categoryPage-search-button'} />
			</div>
		</div>
	);
});
