import { useTranslation } from '@planity/localization';
import React, { useEffect, useMemo, useState } from 'react';
import {
	businessCalendarsById,
	businessService,
	businessServiceWebSelectableCalendars,
	canChooseServiceSubsteps,
	formatDuration,
	formatPrices,
	safeRead
} from '@planity/helpers';
import { withFormFactor } from '../../provider';
import { USER_CHOSE_STEP_CALENDAR_NEW } from '../events';
import { BookingService } from '@planity/ui';
import { Localize } from '../../index';

export default withFormFactor(function AppointmentStep({
	appointment,
	business,
	dispatch,
	hasOnlyOneSimpleService,
	isMobile,
	isNested,
	isPending,
	path,
	step,
	stepIndex
}) {
	const { t } = useTranslation();
	const { hideServiceDurations, hideServicePrices } = business;
	const calendars = businessServiceWebSelectableCalendars(
		business,
		step.serviceId
	);
	const hasAtLeastOnePicture = (calendars || []).some(
		calendar => calendar.picture
	);
	const LIMIT = useMemo(
		() => (hasAtLeastOnePicture ? 7 : 8),
		[hasAtLeastOnePicture]
	);
	const [isLimited, setIsLimited] = useState(calendars?.length + 1 > LIMIT);
	const [calendarChoose, setCalendarChoose] = useState([]);
	const [showMoreButton, setShowMoreButton] = useState(false);

	const service = businessService(business, step.serviceId);
	const isComplex = !!service.sequence;
	const duration = !hideServiceDurations && formatDuration(service.duration, t);
	const prices = !hideServicePrices && formatPrices(service.prices);
	const price =
		prices &&
		prices.map(({ price, text }) => (text ? t(text) : price)).join(' ');
	const ANY_CALENDAR = { name: t('common.anyCalendar'), id: 'ANY' };

	const type = useMemo(() => {
		if (isComplex) {
			if (business?.settings?.forbidCalendarSelection) return 'simple';

			// Watch out for undefined and null values
			if (
				(business?.settings?.chooseSubstepsCalendars &&
					service?.chooseSubstepsCalendars !== false) ||
				service?.chooseSubstepsCalendars
			) {
				return 'complex';
			}
			return 'simple';
		}
		if (hasOnlyOneSimpleService && !isMobile) {
			return 'images';
		}
		return 'simple';
	}, [business, service, isComplex, hasOnlyOneSimpleService, isMobile]);

	const calendarsAsObject = (calendars || [])
		.concat(ANY_CALENDAR)
		.reduce((all, cur) => {
			all[cur.id] = cur;
			return all;
		}, {});

	useEffect(() => {
		const newCalendar = (step.calendarId || []).map(
			calendarId => calendarsAsObject[calendarId]
		);
		setCalendarChoose(newCalendar);
	}, [step.calendarId]);

	useEffect(() => {
		setShowMoreButton(calendars?.length + 1 > LIMIT);
	}, [calendars]);

	const selectCalendar = (calendar, position) => {
		const { id: calendarId } = calendar;
		isSelectable(calendar) &&
			dispatch(USER_CHOSE_STEP_CALENDAR_NEW, {
				calendarId,
				path,
				synchronous: service.synchronous,
				calendarPosition: position
			});
	};
	const steps = { sequence: appointment.steps };

	const calendarUsage = getSteps(business, steps, 0).map(step => {
		const initialStep = step.ancestors.reduce((all, index) => {
			if (all.sequence) {
				return all.sequence[index];
			} else {
				return all[index];
			}
		}, steps);
		step.calendarId = [...(safeRead(initialStep, ['calendarId']) || [])];
		return step;
	});

	const stepUsage = calendarUsage.find(
		u => u.ancestors.join(',') === path.join(',')
	);

	const validCalendars = (calendars || []).filter(({ id: calendarId }) => {
		return !calendarUsage.some(usage => {
			const { calendarId: calendarIdUsed } = usage;
			return (
				(overlaps(stepUsage, usage) &&
					calendarIdUsed.includes(calendarId) &&
					usage.calendarId !== step.calendarId) ||
				(step.calendarId || []).includes(usage.calendarId)
			);
		});
	});

	const isSelectable = calendarNode => {
		const isValid = validCalendars.find(cal => cal.id === calendarNode.id);
		const isAny = calendarNode.id === ANY_CALENDAR.id;
		return isValid || isAny;
	};

	const hasSubSteps = isComplex && canChooseServiceSubsteps(service, business);

	const couldChooseCalendar =
		!appointment.date &&
		!safeRead(business, ['settings', 'forbidCalendarSelection']) &&
		!hasSubSteps;

	const canChooseCalendar = couldChooseCalendar && calendars.length > 1;

	const displayCalendarName =
		(!!appointment.date ||
			(couldChooseCalendar && calendars.length === 1 && step.calendarId)) &&
		!safeRead(business, ['settings', 'forbidCalendarSelection']) &&
		!!step.calendarId;

	const calendarName =
		displayCalendarName &&
		safeRead(businessCalendarsById(business), [step.calendarId, 'name']);

	const renderCalendarName = () =>
		!canChooseCalendar &&
		calendarName && (
			<Localize args={{ calendarName }} text={'bookAppointment.withCalendar'} />
		);
	const isMultiServices = calendarChoose.length > 1;
	const pictures = useMemo(
		() =>
			(calendars || []).map(pic => ({
				url: pic.picture,
				name: pic.name,
				alt: pic.name,
				id: pic.id
			})),
		[]
	);

	return (
		<BookingService
			appointment={appointment}
			business={business}
			calendarChoose={calendarChoose}
			calendars={formatCalendars(ANY_CALENDAR, calendars)}
			canChooseCalendar={canChooseCalendar}
			dispatch={dispatch}
			duration={duration}
			hasAtLeastOnePicture={hasAtLeastOnePicture}
			isLimited={isLimited}
			isMultiServices={isMultiServices}
			isNested={isNested}
			isPending={isPending}
			path={path}
			pictures={pictures}
			price={price}
			renderCalendarName={() => renderCalendarName()}
			service={service}
			setIsLimited={setIsLimited}
			showMoreButton={showMoreButton}
			step={step}
			stepIndex={stepIndex}
			type={type}
			onSelect={selectCalendar}
		/>
	);
});

function overlaps(x, y) {
	if (!x || !y) return false;
	return (
		(x.start < y.start && x.to > y.start) ||
		(x.start >= y.start && x.start < y.to)
	);
}

function getSteps(business, step, initialStart, ancestors = [], id) {
	const start = (initialStart || 0) + (step.offset || 0);
	if (step.sequence) {
		return step.sequence.reduce(
			(steps, subStep) => {
				const service = businessService(business, subStep.serviceId);
				if (!service) {
					return {
						start: steps.start + subStep.defaultDuration,
						steps: steps.steps,
						index: steps.index
					};
				}
				const nextSteps = getSteps(
					business,
					service,
					steps.start + (subStep.offset || 0),
					[...ancestors, steps.index + 1],
					subStep.serviceId
				);
				return {
					steps: steps.steps.concat(nextSteps),
					start: step.synchronous ? start : nextSteps[nextSteps.length - 1].to,
					index: steps.index + 1
				};
			},
			{ start, steps: [], index: -1 }
		).steps;
	} else {
		return [
			{
				start: start,
				to: start + step.duration,
				service: id,
				calendarId: step.calendarId,
				ancestors: ancestors
			}
		];
	}
}

function formatCalendars(ANY_CALENDAR, calendars) {
	return [
		...new Map(
			[ANY_CALENDAR].concat(calendars || []).map(item => [
				item['id'],
				{
					...item,
					label: item.name,
					value: item.name,
					avatar: item.id !== 'ANY'
				}
			])
		).values()
	];
}
