import React from 'react';

export const PlayRound = () => (
	<svg
		width='25'
		height='24'
		viewBox='0 0 25 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M15.7 12L9.69995 9V15L15.7 12Z'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M4.94995 12C4.94995 7.99594 8.19589 4.75 12.2 4.75C16.204 4.75 19.45 7.99594 19.45 12C19.45 16.0041 16.204 19.25 12.2 19.25C8.19589 19.25 4.94995 16.0041 4.94995 12Z'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
