import React from 'react';

export function CheckRound() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
		>
			<path
				d='M3.16663 8.00002C3.16663 5.33064 5.33058 3.16669 7.99996 3.16669C10.6693 3.16669 12.8333 5.33064 12.8333 8.00002C12.8333 10.6694 10.6693 12.8334 7.99996 12.8334C5.33058 12.8334 3.16663 10.6694 3.16663 8.00002Z'
				stroke='#625DF5'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M6.5 8.5L6.78915 9.11625C7.01831 9.60464 7.70243 9.63278 7.97092 9.16486L9.5 6.5'
				stroke='#625DF5'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
