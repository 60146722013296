// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".webview_redirections-module_spinner-2LvyN{height:100vh;display:flex;justify-content:center;align-items:center}", "",{"version":3,"sources":["webpack://./app/components/webview_redirections/webview_redirections.module.scss"],"names":[],"mappings":"AAAA,2CACC,YAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".spinner {\n\theight: 100vh;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "webview_redirections-module_spinner-2LvyN"
};
module.exports = ___CSS_LOADER_EXPORT___;
