import { firebase } from '@planity/datastores';
import {
	comeFromNewAvailabilitiesEmail,
	invokeLambda,
	userVeventToDeleteConstructor
} from '@planity/helpers';

const redirectToBookAppointment = ({
	routes,
	history,
	appointment,
	business,
	paymentCreatedAt,
	appointmentTotalAmount,
	hasWaitingListActivated
}) => {
	const bookingBusiness = { ...business, isBooking: true };
	const sequence = Object.keys(appointment?.sequence || {}).map(
		id => appointment.sequence[id]
	);

	return history.push(
		routes.catchAll({
			reservation: { business: bookingBusiness },
			state: {
				sequence,
				business: bookingBusiness,
				isDeposit: appointment.isDeposit,
				// canBeCancelled is verified in lambda side
				veventToDelete: userVeventToDeleteConstructor({
					appointment,
					paymentCreatedAt,
					totalPrice: appointmentTotalAmount,
					hasWaitingListActivated,
					fromWaitingListMail: true
				})
			}
		})
	);
};

const redirectToHome = ({ routes, history, error }) =>
	history.replace(routes.home, { error });

const redirectToMyAccount = ({ routes, history, state }) =>
	history.replace(routes.myAccount, state);

const redirectToUnmovableAppointment = ({ routes, history, phoneNumber }) =>
	history.replace(routes.unmovableAppointment, {
		phoneNumber
	});

/**
 * @param {String} waitingListToken encrypted token in waitingList email
 * @param business
 * @param history
 * @param routes
 * @description waitingListToken sent to lambda manageWaitingListFromEmail and the decrypted lambda along with a token is fetched.
 * 				The token is used to auto-login the user and then move the RDV or cancel the alert.
 * 				If there is an error from the lambda, display the respective error.
 * 				Verify the field action to execute the desired user action.
 * @return Promise<void>
 */
export const handleWaitingListRedirect = async ({
	waitingListToken,
	business,
	history,
	routes
}) => {
	const router = { routes, history };
	try {
		const lambdaResult = await invokeLambda('manageWaitingListFromMail', {
			code: waitingListToken
		});

		const { statusCode, body } = lambdaResult;

		if (statusCode === 200) {
			const { token, action, ...rest } = body;
			await firebase.auth().signInWithCustomToken(token);

			if (action === 'move') {
				comeFromNewAvailabilitiesEmail();
				return redirectToBookAppointment({ ...router, business, ...rest });
			}
			if (action === 'unsubscribe')
				return redirectToMyAccount({
					...router,
					state: { unsubscribeWaitingList: true }
				});
			throw new Error('UNKNOWN_ACTION');
		}

		if (body?.message === 'APPOINTMENT_DELETED')
			return redirectToMyAccount({
				...router,
				state: { deletedAppointment: 'APPOINTMENT_DELETED' }
			});
		if (
			body?.message &&
			[
				'APPOINTMENT_CANCELLATION_DELAY_PASSED',
				'APPOINTMENT_NOT_MOVABLE'
			].includes(body.message)
		) {
			return redirectToUnmovableAppointment({
				...router,
				phoneNumber: business.phoneNumber,
				delayExpired: body.message === 'APPOINTMENT_CANCELLATION_DELAY_PASSED'
			});
		}

		throw new Error('UNHANDLED_ERROR');
	} catch (error) {
		console.error('error of lambda: ', error);
		redirectToHome({ ...router, error });
	}
};
