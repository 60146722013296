import React from 'react';
import Helmet from 'react-helmet';
import { Localize } from '@planity/components';

export default function CategoryPageMetadata({ category, isFrance }) {
	return (
		<Localize args={{ category, isFrance }} text={'category.meta'}>
			{({ title, description }) => (
				<Helmet>
					<title>{title}</title>
					<meta content={description} name={'description'} />
					<meta content={description} property={'og:description'} />
				</Helmet>
			)}
		</Localize>
	);
}
