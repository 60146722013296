import { Localize } from '@planity/components';
import { breakpoints } from '@planity/theme';
import React, { Component } from 'react';
import Stars from './stars';

export default class StarsContainer extends Component {
	state = {
		hoveredIndex: 0
	};

	clicked = index => {
		this.props.onChange(index);
	};

	onMouseEnter = index => {
		this.clearTimeout();
		this.timeout = setTimeout(() => {
			if (this.props.value !== index) {
				this.setState({
					hoveredIndex: index
				});
			}
		}, 100);
	};

	onMouseLeave = () => {
		this.clearTimeout();
		this.setState({
			hoveredIndex: null
		});
	};

	clearTimeout() {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = null;
		}
	}

	componentWillUnmount() {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = null;
		}
	}

	render() {
		const {
			starsStyle,
			showEvaluation,
			value,
			isDisabled,
			className,
			withMargin
		} = this.props;
		const { hoveredIndex } = this.state;
		return (
			<div css={[styles.review, className]}>
				<div css={[styles.stars, starsStyle]} onMouseLeave={this.onMouseLeave}>
					<Stars
						hoveredIndex={hoveredIndex}
						isDisabled={isDisabled}
						value={value}
						withMargin={withMargin}
						onClick={this.clicked}
						onMouseEnter={this.onMouseEnter}
					/>
				</div>
				{showEvaluation && (
					<div css={styles.evaluation}>
						{!!(hoveredIndex || value) && (
							<Localize text={`reviewForm.stars.${hoveredIndex || value}`} />
						)}
					</div>
				)}
			</div>
		);
	}
}

const styles = {
	review: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center'
	},
	evaluation: {
		display: 'none',
		[breakpoints.tabletQuery]: {
			display: 'block',
			textAlign: 'left',
			width: 80,
			font: 'var(--regular) var(--body-4)',
			color: 'var(--grey-700)',
			marginLeft: 18
		}
	},
	stars: {
		display: 'flex'
	}
};
