export const computeFirstLineText = (brand, range, isSoldOut, t) => {
	let text = [];

	if (range) {
		text.push(range);
	}
	if (isSoldOut) {
		text.push(t('clickAndCollect.soldOut'));
	}

	text = text.join(' - ');
	if (!!brand && text.length > 0) {
		// Add a - before if there's a brand
		return ` - ${text}`;
	}

	return text;
};

export const computeSecondLineText = (name, format) => {
	let text = [];
	if (name) {
		text.push(name);
	}
	if (format) {
		text.push(format);
	}

	return text.join(' - ');
};
