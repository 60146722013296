import React from 'react';
import { CureHeader } from './cure_header';
import { CureDetails } from './cure_details';
import styles from './cure_item.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';

export function CureItem({
	cureName,
	business,
	price,
	boughtAt,
	expires,
	quantity,
	originalQuantity,
	linkToBusiness
}) {
	useStyles(styles);

	return (
		<div
			className={`planity_my-account-profile-form-wrapper ${styles.cureItem}`}
		>
			<CureHeader
				boughtAt={boughtAt}
				business={business}
				expires={expires}
				linkToBusiness={linkToBusiness}
				originalQuantity={originalQuantity}
				quantity={quantity}
			/>
			<div className={`${styles.bodyContainer} ${styles.cureContainer}`}>
				<CureDetails
					business={business}
					cureName={cureName}
					linkToBusiness={linkToBusiness}
					price={price}
				/>
			</div>
		</div>
	);
}
