import { useIsomorphicLayoutEffect } from '@planity/helpers';
import React, { createContext, useContext, useState } from 'react';
import { breakpoints } from '@planity/theme';

export const matchAsFunction = query => {
	let result = false;
	if (!process.env.BROWSER) {
		return true;
	}
	// let container = document.getElementById('planity').offsetWidth;
	let [{ offsetWidth: container }] = document.querySelectorAll(
		'#planity, #planity-account, #planity-appointment, #planity-gift-voucher'
	);
	const mqNumber = query.match(/\d+/);
	if (
		(query.includes('max') && container <= mqNumber) ||
		(query.includes('min') && container >= mqNumber)
	) {
		result = true;
	}
	return result;
};

const initialValue = {
	isTablet: false,
	isDesktop: false,
	container: 576
};
export const ResponsiveContext = createContext(initialValue);
export const useResponsive = () => useContext(ResponsiveContext);
export const ResponsiveContextProvider = ({ children }) => {
	const [state, setState] = useState(initialValue);

	const debouncedEventListener = (fn, delay) => {
		let time;
		return function (...args) {
			if (time) {
				clearTimeout(time);
			}
			time = setTimeout(() => {
				fn(...args);
				time = null;
			}, delay);
		};
	};

	const getResponsive = () => {
		let [{ offsetWidth: container }] = document.querySelectorAll(
			'#planity, #planity-account, #planity-appointment, #planity-gift-voucher'
		);
		setState({
			isTablet: container >= breakpoints.tablet,
			isDesktop: container >= breakpoints.desktop,
			isMobile: container < breakpoints.tablet,
			isLargeDesktop: container >= 1170,
			isHomeTablet: container >= breakpoints.homeTablet,
			container
		});
	};

	const getResponsiveDebounce = debouncedEventListener(
		() => getResponsive(),
		300
	);
	useIsomorphicLayoutEffect(() => {
		getResponsive();
		window.addEventListener('resize', () => getResponsive());
		return () => window.removeEventListener('resize', () => getResponsive());
	}, []);
	return (
		<ResponsiveContext.Provider value={state}>
			{children}
		</ResponsiveContext.Provider>
	);
};

export const withResponsive = Component => props =>
	(
		<ResponsiveContext.Consumer>
			{data => <Component responsive={data} {...props} />}
		</ResponsiveContext.Consumer>
	);
