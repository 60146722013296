import { sleep } from '@planity/helpers';
import { PLACE_IDS_OVERRIDES } from './google_places_ids/google_places_ids_overrides';

let sessionToken;
const DEBUG_SESSION_TOKEN = true;

const getLocationOverrideByPlace = place => {
	const locationOverride = PLACE_IDS_OVERRIDES[place.place_id]?.location;
	return (
		locationOverride || {
			lat: place.geometry.location.lat(),
			lng: place.geometry.location.lng()
		}
	);
};

export async function autocompletePlaces(query, countries, types = []) {
	if (
		!process.env.BROWSER ||
		!query ||
		query.length < 2 ||
		typeof google === 'undefined'
	) {
		return [];
	}
	try {
		// It's loaded within the html
		// eslint-disable-next-line no-undef
		const client = new google.maps.places.AutocompleteService();
		const data = await client.getPlacePredictions({
			input: query,
			componentRestrictions: {
				country: countries
			},
			types,
			sessionToken: sessionToken || generateSessionToken()
		});
		return data.predictions;
	} catch (e) {
		if (process.env.NODE_ENV === 'development') {
			throw e;
		}
		return [];
	}
}

export async function getGooglePlace(
	{ placeId, countries, prediction: _prediction },
	retries = 3
) {
	if (!process.env.BROWSER || !placeId) {
		return null;
	}
	try {
		// It's loaded within the html
		// eslint-disable-next-line no-undef
		const geocoder = new google.maps.Geocoder();
		const { results } = await geocoder.geocode({ placeId });
		const [place] = results;
		if (!place) {
			return null;
		}
		const geometry = {
			location: getLocationOverrideByPlace(place)
		};
		const prediction =
			_prediction || (await fetchFirstPrediction(place, countries));
		// generateSessionToken();
		sessionToken = null;
		return {
			name: prediction
				? prediction.structured_formatting.main_text
				: place.formatted_address,
			place_id: placeId,
			geometry,
			address_components: place.address_components
		};
	} catch (e) {
		if (
			(e.message === "Can't find variable: google" ||
				typeof google === 'undefined') &&
			retries > 0
		) {
			await sleep(500);
			return getGooglePlace(
				{
					placeId,
					countries,
					prediction: _prediction
				},
				retries - 1
			);
		}
		if (process.env.NODE_ENV === 'development') {
			throw e;
		}
		return null;
	}
}

async function fetchFirstPrediction(place, countries) {
	if (!process.env.BROWSER || !place) {
		return null;
	}
	try {
		if (DEBUG_SESSION_TOKEN) console.log('Fetched first prediction');
		const predictions = await autocompletePlaces(
			place.formatted_address,
			countries
		);
		return predictions && Array.isArray(predictions) ? predictions[0] : null;
	} catch (e) {
		console.error(e);
		return null;
	}
}

/**
 * Generates a session token.
 * To be honest, I'm not even sure it's relevant, as we use Geocoder and not getPlaceDetail
 * @link https://developers.google.com/maps/documentation/javascript/places-autocomplete#session_tokens
 * @link https://developers.google.com/maps/documentation/places/web-service/session-tokens
 * @see Optimizing Quota Usage When Geocoding https://developers.google.com/maps/documentation/geocoding/geocoding-strategies#quota-limits
 * @see Has the token been properly implemented : https://stackoverflow.com/a/59265284/5721251
 * @return
 */
function generateSessionToken() {
	const token = process.env.BROWSER
		? // It's loaded within the html
		  // eslint-disable-next-line no-undef
		  new google.maps.places.AutocompleteSessionToken()
		: null;
	if (DEBUG_SESSION_TOKEN)
		console.log(
			'%cGenerating a new AutocompleteSessionToken',
			'background:#6c96b3;color:white',
			token
		);
	sessionToken = token;
	return token;
}
