import sortBy from 'lodash/sortBy';
import { getSEOCatToPushFromCountryCode } from '../config/seoTags';

const buildPath = ({ category, parentCategory, parentPlace, place, routes }) =>
	routes.catchAll({
		search: {
			category,
			parentCategory,
			parentPlace: parentPlace?.type === 'department' ? null : parentPlace,
			place:
				isHairCareCategory(category) || parentPlace?.type === 'department'
					? place
					: null
		}
	});
const buildName = ({ category, parentPlace, place }) => {
	const categoryName = category.seoName || category.name;

	if (!parentPlace || !place) {
		return categoryName;
	}
	if (category.objectID === 'hair_care' || parentPlace?.type === 'department') {
		return categoryName + ' ' + place?.name;
	}
	return categoryName + ' ' + parentPlace?.name;
};

const isHairCareCategory = category => category.objectID === 'hair_care';

export const buildSEOTags = ({
	categories,
	countryCode,
	crumbs,
	parentPlace,
	place,
	routes,
	seoTagsScores
}) => {
	const seoTagsMap = {};
	const existingPathOnPage = crumbs.map(crumb => crumb.path.pathname);
	const seoCatIds = getSEOCatToPushFromCountryCode(countryCode);

	seoTagsScores.forEach(({ score, tagId }) => {
		const associatedCategory = categories.find(
			({ objectID }) => objectID === tagId
		);

		if (!associatedCategory) {
			return;
		}

		const parentId = associatedCategory.parentId;
		const parentCategory = categories.find(
			({ objectID }) => objectID === parentId
		);

		const path = buildPath({
			category: associatedCategory,
			parentCategory,
			parentPlace,
			place,
			routes
		});

		if (!existingPathOnPage.includes(path.pathname)) {
			seoTagsMap[tagId] = {
				name: buildName({ category: associatedCategory, parentPlace, place }),
				score,
				path
			};
		}

		if (!seoCatIds.includes(parentId)) {
			return;
		}

		const parentPath = buildPath({
			category: parentCategory,
			parentCategory: null,
			parentPlace,
			place,
			routes
		});

		if (existingPathOnPage.includes(parentPath.pathname)) {
			return;
		}

		if (!seoTagsMap[parentId]) {
			seoTagsMap[parentId] = {
				score,
				name: buildName({ category: parentCategory, parentPlace, place }),
				path: parentPath
			};
		} else {
			seoTagsMap[parentId] = {
				...seoTagsMap[parentId],
				score: seoTagsMap[parentId].score + score
			};
		}
	});

	const seoTags = Object.entries(seoTagsMap).map(([tagId, attributes]) => ({
		tagId,
		...attributes
	}));

	return sortBy(seoTags, ['score']).reverse().slice(0, 10);
};
