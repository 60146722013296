import { noop, useKeyPress } from '@planity/helpers';
import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useReducer } from 'react';
import classNames from 'classnames/bind';
import styles from './drawer.module.scss';
import { Icon, Flag, Avatar, Button } from '@planity/ui';
import { useTranslation } from '@planity/localization';

export function Drawer({
	className,
	isOpen = false,
	isAttached = false,
	items = [],
	selected = '',
	onSelect = noop,
	isRadio = false,
	header,
	hasAtLeastOnePicture,
	openGallery,
	hideDescription,
	hasArrowKeysNavigation
}) {
	useStyles(styles);
	const { t } = useTranslation();
	const cx = classNames.bind(styles);

	const initialState = null;
	const reducer = (state, action) => {
		if (!hasArrowKeysNavigation) return state;

		if (!items?.length || !isOpen) return state;
		switch (action.type) {
			case 'arrowUp':
				if (Object.is(null, state)) return items.length - 1;
				if (state === 0) return items.length - 1;
				return state - 1;
			case 'arrowDown':
				if (Object.is(null, state)) return 0;
				if (state === items.length - 1) return 0;
				return state + 1;
			case 'select':
				return state;
			case 'reset':
				return initialState;
			default:
				console.error('Unhandled action in drawer reducer', action?.type);
		}
	};
	const arrowUpPressed = useKeyPress(!hasArrowKeysNavigation, 'ArrowUp');
	const arrowDownPressed = useKeyPress(!hasArrowKeysNavigation, 'ArrowDown');
	const enterPressed = useKeyPress(!hasArrowKeysNavigation, 'Enter');

	const [selectedIndex, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		dispatch({ type: 'reset' });
	}, [items]);
	useEffect(() => {
		if (arrowUpPressed) {
			dispatch({ type: 'arrowUp' });
		}
	}, [arrowUpPressed]);

	useEffect(() => {
		if (arrowDownPressed) {
			dispatch({ type: 'arrowDown' });
		}
	}, [arrowDownPressed]);
	useEffect(() => {
		if (enterPressed) {
			dispatch({ type: 'select' });
			if (items?.[selectedIndex]) onSelect(items[selectedIndex], selectedIndex);
		}
	}, [enterPressed, items, onSelect, selectedIndex]);

	const classes = cx({
		drawer: true,
		[className]: !!className,
		open: isOpen,
		attached: isAttached,
		isRadio,
		hasHeader: header
	});
	if (!items?.length) return null;
	return (
		<div onClick={e => e.stopPropagation()} className={classes}>
			<ul className={cx({ items: true })}>
				{header && (
					<li>
						<div className={cx({ header: true })}>
							<span className={styles.content}>
								<span>{header.label}</span>
								{header.description && (
									<span className={styles.description}>
										{header.description}
									</span>
								)}
							</span>
						</div>
					</li>
				)}
				{items?.map((item, i) => (
					<li
						className={`${item?.customStyle || null} ${
							item.avatar && 'planity_ui_action_worker-name'
						}`}
						key={i}
						id={`drawer-item-${i}`}
					>
						<button
							type={'button'}
							className={cx({
								item: true,
								isSelected:
									item.value === selected ||
									item.label === selected ||
									item.name === selected ||
									selectedIndex === i,
								isInlined: item.isInlined,
								devConsole: process.env.DEV_CONSOLE
							})}
							onMouseDown={() => onSelect(item, i)}
						>
							{isRadio && (
								// Radio
								<span className={styles.radio}></span>
							)}

							{item.iconLeft && (
								// Icon left
								<Icon className={styles.iconLeft} icon={item.iconLeft} />
							)}

							{item.flag && (
								// FLag
								<Flag className={styles.flag} country={item.flag} />
							)}

							{item.avatar && (
								<Avatar
									imageUrl={item.picture}
									initial={item?.label[0]}
									{...item.avatar}
									className={styles.avatar}
									isCloudinary
								/>
							)}

							<span
								className={cx({
									content: true,
									isInlined: !item.avatar && item.isInlined
								})}
							>
								{item.dangerousLabel && (
									<span
										className={cx({
											label: true,
											isInlined: item.isInlined
										})}
										dangerouslySetInnerHTML={{
											__html: item.dangerousLabel
										}}
									/>
								)}
								<span
									className={cx({
										label: true,
										isInlined: item.isInlined
									})}
								>
									{item.dangerousLabel || !item.name ? item.label : item.name}
								</span>
								{item.description && !hideDescription && (
									<span
										className={cx({
											description: true,
											isInlined: item.isInlined
										})}
									>
										{item.description || ''}
									</span>
								)}
							</span>

							{item.iconRight && (
								// Icon right
								<Icon className={styles.iconRight} icon={item.iconRight} />
							)}
						</button>
					</li>
				))}
				{hasAtLeastOnePicture && (
					<Button
						className={styles.enlargePhotoButton}
						label={t('bookAppointment.enlargePhotos')}
						type='underlined'
						iconLeft='Maximize'
						onClick={openGallery}
					/>
				)}
			</ul>
		</div>
	);
}
