import { useEffect, useState } from 'react';

export const useActiveElement = () => {
	const [activeElement, setActiveElement] = useState(null);
	const handleFocusIn = () => setActiveElement(document.activeElement);

	useEffect(() => {
		document.addEventListener('focusin', handleFocusIn);
		return () => {
			document.removeEventListener('focusin', handleFocusIn);
		};
	}, []);

	return activeElement;
};
