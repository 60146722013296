import React from 'react';

export const KE = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<mask
				id='mask0_270_60878'
				style={{ maskType: 'luminance' }}
				maskUnits='userSpaceOnUse'
				x='0'
				y='0'
				width='20'
				height='15'
			>
				<rect width='20' height='15' fill='white' />
			</mask>
			<g mask='url(#mask0_270_60878)'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0 0V5H20V0H0Z'
					fill='#272727'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0 10V15H20V10H0Z'
					fill='#4E8B1D'
				/>
				<path
					d='M0 4.375H-0.625V5V10V10.625H0H20H20.625V10V5V4.375H20H0Z'
					fill='#E31D1C'
					stroke='#F7FCFF'
					strokeWidth='1.25'
				/>
			</g>
			<path
				d='M11.8117 2.4183C11.9259 2.17186 12.2329 1.90275 12.7327 1.61097C12.7499 1.60094 12.7706 1.60037 12.7883 1.60943C12.8181 1.62477 12.8305 1.66269 12.8158 1.69405L7.63704 12.871L7.3674 12.7325L11.9325 2.88017C11.7257 2.84472 11.6855 2.69077 11.8117 2.4183Z'
				fill='white'
			/>
			<path
				d='M8.18277 2.4183C8.30901 2.69077 8.26877 2.84472 8.06205 2.88017L12.6271 12.7325L12.3575 12.871L7.17869 1.69405C7.16396 1.66269 7.17637 1.62477 7.20624 1.60943C7.22389 1.60037 7.24463 1.60094 7.26181 1.61097C7.76159 1.90275 8.06858 2.17186 8.18277 2.4183Z'
				fill='white'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10 12.5684C10.598 12.5684 13 10.3063 13 7.51579C13 4.7253 10.598 2.46316 10 2.46316C9.40197 2.46316 7 4.7253 7 7.51579C7 10.3063 9.40197 12.5684 10 12.5684Z'
				fill='#E31D1C'
			/>
			<mask
				id='mask1_270_60878'
				style={{ maskType: 'luminance' }}
				maskUnits='userSpaceOnUse'
				x='7'
				y='2'
				width='6'
				height='11'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M10 12.5684C10.598 12.5684 13 10.3063 13 7.51579C13 4.7253 10.598 2.46316 10 2.46316C9.40197 2.46316 7 4.7253 7 7.51579C7 10.3063 9.40197 12.5684 10 12.5684Z'
					fill='white'
				/>
			</mask>
			<g mask='url(#mask1_270_60878)'>
				<path
					d='M4.30001 11.9368C6.6196 11.9368 8.50001 9.95747 8.50001 7.51579C8.50001 5.07411 6.6196 3.09474 4.30001 3.09474C1.98041 3.09474 0.100006 5.07411 0.100006 7.51579C0.100006 9.95747 1.98041 11.9368 4.30001 11.9368Z'
					fill='#272727'
				/>
				<path
					d='M15.7 11.9368C18.0196 11.9368 19.9 9.95747 19.9 7.51579C19.9 5.07411 18.0196 3.09474 15.7 3.09474C13.3804 3.09474 11.5 5.07411 11.5 7.51579C11.5 9.95747 13.3804 11.9368 15.7 11.9368Z'
					fill='#272727'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M10.12 6.83999C10.3939 6.63518 10.6 5.74312 10.6 4.67369C10.6 3.60425 10.3939 2.71219 10.12 2.50738V6.83999ZM9.81999 6.78301C9.57657 6.50133 9.39999 5.66348 9.39999 4.67369C9.39999 3.68389 9.57657 2.84604 9.81999 2.56436V6.78301ZM9.81999 8.24857V12.4672C9.57657 12.1855 9.39999 11.3477 9.39999 10.3579C9.39999 9.3681 9.57657 8.53025 9.81999 8.24857ZM10.12 12.5242V8.19159C10.3939 8.3964 10.6 9.28846 10.6 10.3579C10.6 11.4273 10.3939 12.3194 10.12 12.5242Z'
					fill='#F7FCFF'
				/>
				<path
					d='M9.99999 6.88421C10.3314 6.88421 10.6 7.16698 10.6 7.51579C10.6 7.8646 10.3314 8.14737 9.99999 8.14737C9.66862 8.14737 9.39999 7.8646 9.39999 7.51579C9.39999 7.16698 9.66862 6.88421 9.99999 6.88421Z'
					fill='#F7FCFF'
				/>
			</g>
		</svg>
	);
};
