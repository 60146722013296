import { useLocalization } from '@planity/localization';
import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useRef, useState } from 'react';
import { parseISO } from 'date-fns';
import {
	capitalize,
	formatPhoneNumber,
	getDateFromString
} from '@planity/helpers';
import classNames from 'classnames/bind';
import styles from './mobile_availabilities_picker.module.scss';
import { Icon } from '@planity/ui';
import { Localize } from '@planity/components';

// slot state >
// 0: hidden
// 1: show
// 2: show and expend hours

export function MobileAvailabilitiesPicker({
	availabilities,
	business,
	chooseDate
}) {
	useStyles(styles);
	const cx = classNames.bind(styles);
	const classes = cx({
		'mobile-timetable': true
	});

	const [stopIndex, setStopIndex] = useState(7);
	const [toggledDays, setToggledDays] = useState({});
	const [height, setHeight] = useState({});

	useEffect(() => {
		if (Object.keys(availabilities || {}).length > 0) {
			const sortedAvailabilities = Object.keys(availabilities || {}).sort(
				(a, b) => getDateFromString(a) - getDateFromString(b)
			);
			setToggledDays({
				[sortedAvailabilities[0]]: 1
			});
		}
	}, [availabilities]);

	const cleanAvailabilities = Object.keys(availabilities || {}).reduce(
		(all, day) => {
			const a = availabilities[day]
				.map(({ hour, isStale }) => {
					if (!isStale) {
						return hour;
					} else return false;
				})
				.filter(x => !!x);
			if (a.length > 0) {
				all[day] = a;
			}
			return all;
		},
		{}
	);

	const showMoreDays =
		Object.keys(cleanAvailabilities || {}).length > stopIndex;

	const handleClick = (day, showMoreHours) => {
		if (showMoreHours) {
			return setToggledDays({ ...toggledDays, [day]: 2 });
		}
		if (!toggledDays[day]) {
			setToggledDays({ ...toggledDays, [day]: 1 });
		} else {
			if (toggledDays[day] === 0) {
				setToggledDays({ ...toggledDays, [day]: 1 });
			} else {
				setToggledDays({ ...toggledDays, [day]: 0 });
			}
		}
	};

	const handleShowMoreDays = () => {
		setStopIndex(stopIndex + 7);
	};

	if (Object.keys(availabilities || {}).length === 0) {
		return (
			<div className={classes}>
				<div className={styles.text}>
					<Localize
						args={{
							phoneNumber: formatPhoneNumber(business.phoneNumber)
						}}
						text={`bookAppointment.noAvailabilities.${
							business.phoneNumber ? 'withPhone' : 'withoutPhone'
						}`}
					/>
				</div>
			</div>
		);
	}

	return (
		<div className={classes}>
			{Object.keys(cleanAvailabilities || {})
				.sort((a, b) => getDateFromString(a) - getDateFromString(b))
				.map((day, dayIndex) => {
					if (cleanAvailabilities[day]) {
						const isToggled = toggledDays[day] === 2;
						const isPartiallyToggled = toggledDays[day] === 1;
						const showMore =
							toggledDays[day] === 1 && cleanAvailabilities[day].length > 9;
						return (
							<div
								className={cx({
									selection: true,
									planity_appointment_steps_step: true,
									showMore
								})}
								key={day}
							>
								<span
									className={styles.toggle}
									id='mobile-availabilities-toggle'
									onClick={() => handleClick(day)}
								>
									<Day day={day} />
									<Icon
										className={styles.icon}
										icon={
											isToggled || isPartiallyToggled
												? 'ChevronUp'
												: 'ChevronDown'
										}
										size={'medium'}
									/>
								</span>
								<Timeslots
									chooseDate={chooseDate}
									cleanAvailabilities={cleanAvailabilities}
									cx={cx}
									day={day}
									dayIndex={dayIndex}
									handleClick={handleClick}
									height={height}
									isPartiallyToggled={toggledDays[day] === 1}
									isToggled={toggledDays[day] === 2}
									setHeight={setHeight}
								/>
							</div>
						);
					}
				})
				.slice(0, stopIndex)}
			{showMoreDays && (
				<div
					className={`${styles.toggle} ${styles.selection} ${styles.moreAvailabilities}`}
				>
					<button onClick={handleShowMoreDays}>
						<Localize text='business.displayMoreAvailability' />
					</button>
				</div>
			)}
			{Object.keys(cleanAvailabilities || {}).length === 0 && (
				<div>
					<Localize text={'bookAppointment.onlyStaleAvailabilities'} />
				</div>
			)}
		</div>
	);
}

function Day({ day, className }) {
	const date = parseISO(day);
	const options = {
		month: 'long',
		day: 'numeric',
		weekday: 'long'
	};
	const { locale } = useLocalization();
	const formattedDate = capitalize(
		new Intl.DateTimeFormat(locale, options).format(date)
	);
	return <span className={className}>{formattedDate}</span>;
}

function Timeslots({
	day,
	dayIndex,
	cleanAvailabilities,
	chooseDate,
	handleClick,
	cx,
	isToggled,
	isPartiallyToggled,
	height,
	setHeight
}) {
	const ref = useRef(null);
	const hasMoreAvailabilities = cleanAvailabilities[day].length > 9;

	useEffect(() => {
		if (!ref.current) return null;
		if (isToggled) {
			setHeight({ ...height, [day]: ref.current.scrollHeight });
		}
		if (isPartiallyToggled && !isToggled) {
			setHeight({
				...height,
				[day]: hasMoreAvailabilities ? 200 : ref.current.scrollHeight
			});
		}
	}, [isPartiallyToggled, isToggled, day, ref, hasMoreAvailabilities]);

	const getHeight = () => {
		if (isPartiallyToggled && hasMoreAvailabilities) {
			return 200;
		} else if (isToggled || isPartiallyToggled) {
			return height[day];
		} else return 0;
	};

	return (
		<div
			className={cx({
				content: true
			})}
			css={{ height: getHeight() }}
		>
			<div className={styles.items} ref={ref}>
				{cleanAvailabilities[day]
					.map((hour, i) => (
						<div
							className={`${styles.item} planity_appointment_days_slider_hour_availability`}
							id={`mobile-availabilities-${dayIndex}-${i}`}
							key={`${day}-${hour}`}
							onClick={() => chooseDate(parseISO(`${day} ${hour}`))}
						>
							{hour}
						</div>
					))
					.slice(0, isToggled ? cleanAvailabilities[day].length : 9)}
			</div>
			{!isToggled && isPartiallyToggled && hasMoreAvailabilities && (
				<button
					className={styles.more}
					onClick={() => {
						handleClick(day, true);
					}}
				>
					<Localize text='bookAppointment.expand' />
				</button>
			)}
		</div>
	);
}
