// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cures_list-module_cures-MjxWH{font:var(--regular) var(--body-1)}@media(min-width: 768px){.cures_list-module_cures-MjxWH{box-shadow:var(--shadow-regular);background-color:var(--white);border-radius:var(--border-radius-large);border:1px solid var(--grey-200);padding:32px}}.cures_list-module_title-spJuP{display:flex;margin-bottom:12px;font:var(--medium) var(--body-1)}.cures_list-module_cureContainer-UuwsB{margin-bottom:12px}@media(min-width: 768px){.cures_list-module_cureContainer-UuwsB{margin-bottom:0}}", "",{"version":3,"sources":["webpack://./../../packages/components/user_buying/cures_list.module.scss"],"names":[],"mappings":"AAEA,+BACC,iCAAA,CAEA,yBAHD,+BAIE,gCAAA,CACA,6BAAA,CACA,wCAAA,CACA,gCAAA,CACA,YAAA,CAAA,CAIF,+BACC,YAAA,CACA,kBAAA,CACA,gCAAA,CAGD,uCACC,kBAAA,CAEA,yBAHD,uCAIE,eAAA,CAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.cures {\n\tfont: var(--regular) var(--body-1);\n\n\t@media (min-width: styles.$tablet) {\n\t\tbox-shadow: var(--shadow-regular);\n\t\tbackground-color: var(--white);\n\t\tborder-radius: var(--border-radius-large);\n\t\tborder: 1px solid var(--grey-200);\n\t\tpadding: 32px;\n\t}\n}\n\n.title {\n\tdisplay: flex;\n\tmargin-bottom: 12px;\n\tfont: var(--medium) var(--body-1);\n}\n\n.cureContainer {\n\tmargin-bottom: 12px;\n\n\t@media (min-width: styles.$tablet) {\n\t\tmargin-bottom: 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"cures": "cures_list-module_cures-MjxWH",
	"title": "cures_list-module_title-spJuP",
	"cureContainer": "cures_list-module_cureContainer-UuwsB"
};
module.exports = ___CSS_LOADER_EXPORT___;
