import memoize from 'fast-memoize';
import cloneDeep from 'lodash/cloneDeep';
import { getFixedPrice } from '@planity/helpers';
import { safeRead } from '../functions';
import { byId, leaves, sortedTree } from './helpers';

export const FORBIDDEN_STATUSES = ['FORBIDDEN'];

export const serviceIsForbidden = service =>
	service && FORBIDDEN_STATUSES.includes(service.moderationStatus);

export const businessServices = memoize(business =>
	leaves({
		tree: business.services,
		keepLeaf: ({ leaf, set }) => {
			/**
			 * We should skip deleted sets and deleted leaves
			 * But there seems to be a bug in pro-app allowing to
			 * delete a set holding services used by complex services
			 * in other sets.
			 * In order to prevent breaking legit complex services using such services
			 * we keep for now webHidden services belonging to a deleted set as they
			 * might be used by a complex service
			 * see https://github.com/planity/next/issues/93
			 */
			return (
				!leaf.deletedAt &&
				(!set.deletedAt || leaf.webHidden) &&
				!serviceIsForbidden(leaf)
			);
		},
		forwardSetProps: ({ setId }) => ({
			serviceSetId: setId
		})
	})
);

export const businessServicesById = memoize(business =>
	byId(businessServices(business))
);

export const businessService = memoize(
	(business, serviceId) => businessServicesById(business)[serviceId]
);

export const businessWebVisibleServices = memoize(business =>
	leaves({
		tree: business.services,
		keepSet: ({ set }) => !set.deletedAt && !!set.children,
		keepLeaf: ({ leaf }) =>
			!leaf.deletedAt && !leaf.webHidden && !serviceIsForbidden(leaf),
		forwardProps: ({ setId }) => ({
			serviceSetId: setId
		})
	})
);

/**
 * I need every service to know if it has been filtered or not
 * eg: Complex service includes filtered service => filter complex service too
 */
export const businessAllServicesById = memoize(business =>
	byId(
		leaves({
			tree: business.services,
			keepSet: x => x,
			keepLeaf: x => x,
			forwardProps: ({ setId }) => ({
				serviceSetId: setId
			})
		})
	)
);

export const businessWebVisibleServicesById = memoize(business =>
	byId(businessWebVisibleServices(business))
);

export const businessSortedServiceSets = memoize(business =>
	sortedTree({
		tree: business.services,
		keepSet: ({ node }) => !node.deletedAt,
		keepLeaf: ({ node }) => !node.deletedAt,
		forwardProps: ({ setId }) => ({ serviceSetId: setId })
	})
);

export const businessSortedWebVisibleServiceSets = memoize(business =>
	sortedTree({
		tree: business.services,
		keepSet: ({ node }) => !node.deletedAt,
		keepLeaf: ({ node }) => {
			if (node && Array.isArray(node.sequence)) {
				const unfilteredServices = businessAllServicesById(business);
				const subSteps = node.sequence.map(
					({ serviceId }) => unfilteredServices[serviceId]
				);
				if (subSteps.some(serviceIsForbidden)) return false;
			}

			return !node.deletedAt && !node.webHidden && !serviceIsForbidden(node);
		},
		forwardProps: ({ setId }) => ({ serviceSetId: setId })
	})
);

export const canChooseServiceSubsteps = memoize((service, business) => {
	if (service.hasOwnProperty('chooseSubstepsCalendars')) {
		return !!service.chooseSubstepsCalendars;
	} else {
		return !!safeRead(business, ['settings', 'chooseSubstepsCalendars']);
	}
});

export const checkIfIsBookable = ({ sequence, business }) =>
	business.plStatus === 4 &&
	sequence.every(({ serviceId, serviceOrigin }) => {
		const service = businessService(business, serviceOrigin || serviceId);
		// Could have been recursive, but I don't want to risk anything
		const subService =
			!service ||
			(service.sequence || [])
				.filter(x => x.serviceId !== 'PAUSE')
				.every(subService =>
					businessService(
						business,
						subService.serviceOrigin || subService.serviceId
					)
				);
		return service && subService;
	});

export const getServiceSetByServiceSetId = memoize((business, serviceSetId) => {
	return business.services[
		Object.keys(business.services).find(serviceSet => {
			return serviceSet === serviceSetId;
		})
	];
});

export const getServicesWithGiftVouchersEnabled = services => {
	let giftVouchersEnabledServices = [];
	services.forEach(set => {
		if (!!set.children && set.children.length > 0) {
			let setCopy = cloneDeep(set);
			setCopy.children = [];
			set.children.forEach(child => {
				const hasFixedPrice = getFixedPrice(child.prices);
				const hasGiftVoucherEnabled = child.giftVoucher;

				if (!!hasFixedPrice && !!hasGiftVoucherEnabled) {
					setCopy.children.push(child);
				}
			});

			if (setCopy.children.length > 0) {
				giftVouchersEnabledServices.push(setCopy);
			}
		}
	});
	return giftVouchersEnabledServices;
};
