import React from 'react';

export const CM = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5615)'>
				<mask
					id='mask0_11534_5615'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5615)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M6 0H14V15H6V0Z'
						fill='#E11C1B'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M10.0471 9.05648L7.86861 10.5675L8.56473 7.97945L6.96547 6.32618L9.13095 6.23679L10.0471 3.67755L10.9633 6.23679H13.1251L11.5295 7.97945L12.3286 10.4148L10.0471 9.05648Z'
						fill='#FECA00'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M14 0H20V15H14V0Z'
						fill='#FBCD17'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0H6V15H0V0Z'
						fill='#0B9E7A'
					/>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5615'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
