import React from 'react';

export function Cure() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
		>
			<path
				d='M14.1667 3.9585C14.1667 4.91437 13.2475 5.8335 12.2917 5.8335C13.2475 5.8335 14.1667 6.75262 14.1667 7.7085C14.1667 6.75262 15.0858 5.8335 16.0417 5.8335C15.0858 5.8335 14.1667 4.91437 14.1667 3.9585Z'
				stroke='#4E5860'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M14.1667 12.2915C14.1667 13.2474 13.2475 14.1665 12.2917 14.1665C13.2475 14.1665 14.1667 15.0856 14.1667 16.0415C14.1667 15.0856 15.0858 14.1665 16.0417 14.1665C15.0858 14.1665 14.1667 13.2474 14.1667 12.2915Z'
				stroke='#4E5860'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M7.50001 6.4585C7.50001 8.26404 5.76389 10.0002 3.95834 10.0002C5.76389 10.0002 7.50001 11.7363 7.50001 13.5418C7.50001 11.7363 9.23613 10.0002 11.0417 10.0002C9.23613 10.0002 7.50001 8.26404 7.50001 6.4585Z'
				stroke='#4E5860'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
