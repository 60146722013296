import {
	Localize,
	useAppointmentSource,
	useUserQueries
} from '@planity/components';
import { BusinessPreview } from '@planity/ui';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import styles from './user_queries.module.scss';
import APPOINTMENT_SOURCE from '@planity/components/satisfaction_measurement/appointment_source';

export function UserQueries() {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		userQueries: true
	});
	const { changeSource } = useAppointmentSource();
	return (
		<div className={classes}>
			<BusinessPreview
				fromSearchBusinesses
				Title={<BusinessPreviewTitle />}
				onBusinessSelect={() => {
					changeSource(APPOINTMENT_SOURCE.BOOK_AGAIN.MOBILE_SEARCH);
				}}
			/>
		</div>
	);
}

const BusinessPreviewTitle = () => {
	const { beautyTeam } = useUserQueries();
	return (
		<h2 className={styles.businessPreviewTitle}>
			<Localize
				args={{ count: beautyTeam.length }}
				text={'landing.beautyTeam'}
			/>
		</h2>
	);
};
