import { Localize } from '@planity/components';
import { useLocalization, useTranslation } from '@planity/localization';
import React from 'react';
import { format } from 'date-fns';
import { withFormFactor } from '../../provider';
import { USER_REMOVED_DATE } from '../events';
import { Button } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './step.module.scss';

export default withFormFactor(function AppointmentChosenDate({
	date,
	dispatch,
	isPending
}) {
	useStyles(styles);
	const { t } = useTranslation();

	const classes = classNames.bind(styles)({
		timetable: true,
		hasChosenDate: true
	});

	const { dateLocale } = useLocalization();
	return (
		<div className={classes}>
			<div className={styles.selection}>
				<div className={styles.dateInfos}>
					<span className={styles.date}>
						{format(date, 'PPPP', { locale: dateLocale })}
					</span>
					<span className={styles.time}>
						<Localize
							args={{
								time: format(date, 'HH:mm', { locale: dateLocale })
							}}
							text={'bookAppointment.date'}
						/>
					</span>
				</div>
				<Button
					className={styles.edit}
					isLoading={isPending}
					label={t('common.edit')}
					type='underlined'
					onClick={() => dispatch(USER_REMOVED_DATE)}
				/>
			</div>
		</div>
	);
});
