/**
 * TODO
 *
 * This should rather be provided from context
 * by the host app
 */

export default ['hackSearchInput', 'dropdown', 'modalOverlay'].reduce(
	(l, k, i) => {
		l[k] = i + 100;
		return l;
	},
	{}
);
