import React, { useState } from 'react';
import { FirebaseSubscriptionsContext } from '@planity/components';

function WithHydration({ children }) {
	const [isInitialRender] = useState(
		typeof window === 'undefined' || window._planity_isHydrating
	);
	if (isInitialRender) {
		return children;
	} else {
		return (
			<FirebaseSubscriptionsContext>{children}</FirebaseSubscriptionsContext>
		);
	}
}

export default WithHydration;
