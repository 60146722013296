import React from 'react';

export const IL = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_4657_1972)'>
				<mask
					id='mask0_4657_1972'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_4657_1972)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#F7FCFF'
					/>
					<mask
						id='mask1_4657_1972'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_4657_1972)'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 2.5V4.72843H20V2.5H0Z'
							fill='#3D58DB'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 10.2175V12.5H20V10.2175H0Z'
							fill='#3D58DB'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M7.11325 9.33671H12.9739L10.0724 4.32391L7.11325 9.33671ZM11.7816 8.64921H8.31746L10.0666 5.68622L11.7816 8.64921Z'
							fill='#3D58DB'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M6.93222 5.57834H12.9255L10.0849 10.6069L6.93222 5.57834ZM11.7475 6.26584H8.1747L10.0541 9.26354L11.7475 6.26584Z'
							fill='#3D58DB'
						/>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
				style={{ mixBlendMode: 'multiply' }}
			/>
			<defs>
				<clipPath id='clip0_4657_1972'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
