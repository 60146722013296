import styles from './text.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';

export const Text = ({ children, dangerouslyHTML, className, style }) => {
	const classes = classNames.bind(styles)({
		text: true,
		[className]: className
	});
	useStyles(styles);
	return (
		<div
			className={classes}
			dangerouslySetInnerHTML={dangerouslyHTML}
			style={style}
		>
			{children}
		</div>
	);
};
