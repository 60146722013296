export const redirectToBusiness = ({ error, business, history, routes }) => {
	return history.replace(
		routes.catchAll({
			business,
			state: {
				error
			}
		})
	);
};
