export function Unknown() {
	return (
		<svg
			width='50'
			height='34'
			viewBox='0 0 50 34'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<rect
				x='0.75'
				y='0.75'
				width='48.5'
				height='32.5'
				rx='4.25'
				fill='#146C9C'
				stroke='#E9EAF0'
				strokeWidth='0.5'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M26.3243 10.0098C26.3155 10.0181 26.3083 11.4444 26.3083 13.1793V16.3338L32.732 16.3278L39.1558 16.3219L39.4241 16.253C41.0062 15.8471 41.9804 14.6695 41.9793 13.1643C41.9789 12.5985 41.956 12.484 41.7296 11.9145C41.3865 11.0516 40.4053 10.2992 39.3074 10.0569L39.0857 10.008L32.713 10.0013C29.2081 9.99774 26.3331 10.0016 26.3243 10.0098ZM13.3792 10.0831C10.9564 10.376 8.97592 11.9458 8.26096 14.1402C8.02851 14.8536 8.00021 15.1176 8 16.5743C7.99979 18.016 8.01358 18.1671 8.21161 18.8878C8.73549 20.7944 10.4525 22.4125 12.504 22.9331C12.8331 23.0167 12.8614 23.0225 13.1519 23.0674C13.9265 23.1873 20.0946 23.1946 20.8473 23.0765C23.9014 22.5975 25.9806 20.31 26.0444 17.3583L26.0576 16.7498L21.5329 16.7443L17.0082 16.7388V16.5415V16.3441L21.5329 16.3385L26.0576 16.333L26.0454 15.7903C26.026 14.9352 25.8867 14.2717 25.5864 13.6029C24.717 11.6674 22.8797 10.3574 20.6489 10.0827C20.1423 10.0203 13.8955 10.0206 13.3792 10.0831ZM26.3193 16.7767C26.3131 16.792 26.3107 18.2207 26.314 19.9513L26.32 23.0981H32.5745C39.2668 23.0981 39.0547 23.1014 39.4942 22.99C41.4884 22.484 42.542 20.4315 41.718 18.6576C41.3753 17.9199 40.6487 17.249 39.9142 16.992C39.6731 16.9077 39.483 16.8526 39.2841 16.8097L39.0623 16.7617L32.6964 16.7553C27.5731 16.7502 26.3283 16.7544 26.3193 16.7767Z'
				fill='white'
			/>
		</svg>
	);
}
