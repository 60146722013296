export const DefaultBrowserSvg = () => {
	return (
		<svg
			fill='none'
			width='28'
			height='28'
			viewBox='0 0 28 28'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14ZM13.0741 3.16109C13.595 2.69015 13.9197 2.625 14 2.625C14.0803 2.625 14.405 2.69015 14.9259 3.16109C15.4201 3.60788 15.9755 4.32099 16.4975 5.31389C17.4133 7.05584 18.1716 9.55671 18.3401 12.6875H9.65993C9.82841 9.55671 10.5867 7.05584 11.5025 5.31389C12.0245 4.32099 12.5799 3.60788 13.0741 3.16109ZM7.03153 12.6875C7.20284 9.15304 8.05559 6.22927 9.17903 4.09236C9.2747 3.9104 9.37274 3.73336 9.47296 3.5615C5.82603 5.1453 3.17131 8.58433 2.6999 12.6875H7.03153ZM2.6999 15.3125H7.03153C7.20284 18.847 8.05559 21.7707 9.17903 23.9076C9.2747 24.0896 9.37274 24.2666 9.47296 24.4385C5.82603 22.8547 3.17131 19.4157 2.6999 15.3125ZM9.65993 15.3125H18.3401C18.1716 18.4433 17.4133 20.9442 16.4975 22.6861C15.9755 23.679 15.4201 24.3921 14.9259 24.8389C14.405 25.3098 14.0803 25.375 14 25.375C13.9197 25.375 13.595 25.3098 13.0741 24.8389C12.5799 24.3921 12.0245 23.679 11.5025 22.6861C10.5867 20.9442 9.82841 18.4433 9.65993 15.3125ZM20.9685 15.3125C20.7972 18.847 19.9444 21.7707 18.821 23.9076C18.7253 24.0896 18.6273 24.2666 18.527 24.4385C22.174 22.8547 24.8287 19.4157 25.3001 15.3125H20.9685ZM25.3001 12.6875C24.8287 8.58433 22.174 5.1453 18.527 3.5615C18.6273 3.73336 18.7253 3.9104 18.821 4.09236C19.9444 6.22927 20.7972 9.15304 20.9685 12.6875H25.3001Z'
				fill='#202020'
			/>
		</svg>
	);
};
