import React, { Fragment, useContext } from 'react';
import TabsContext from './context';

export function Panel({ id, children, needsSetCurrent }) {
	const { setCurrent, current } = useContext(TabsContext);
	if (id !== current) {
		return null;
	}
	return (
		<Fragment>
			{needsSetCurrent
				? React.cloneElement(children, { setCurrent })
				: children}
		</Fragment>
	);
}
