export function WarningTriangle() {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M4.95203 16.3535L10.215 5.85653C10.953 4.38476 13.0537 4.38515 13.7911 5.85718L19.0493 16.3542C19.7155 17.6841 18.7485 19.25 17.2611 19.25H6.73989C5.25216 19.25 4.28523 17.6835 4.95203 16.3535Z'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M12 10V12'
				stroke='currentColor'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M12.5 16C12.5 16.2761 12.2761 16.5 12 16.5C11.7239 16.5 11.5 16.2761 11.5 16C11.5 15.7239 11.7239 15.5 12 15.5C12.2761 15.5 12.5 15.7239 12.5 16Z'
				stroke='currentColor'
			/>
		</svg>
	);
}
