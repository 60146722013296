export function Trash() {
	return (
		<svg
			width='25'
			height='24'
			viewBox='0 0 25 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M7.34998 7.75L8.19113 17.4233C8.281 18.4568 9.14619 19.25 10.1836 19.25H15.0163C16.0538 19.25 16.919 18.4568 17.0088 17.4233L17.85 7.75'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M10.35 7.5V6.75C10.35 5.64543 11.2454 4.75 12.35 4.75H12.85C13.9545 4.75 14.85 5.64543 14.85 6.75V7.5'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M5.59998 7.75H19.6'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
