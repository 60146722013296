// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".localization_denied_modal-module_localizationDeniedModal-cE7hB{padding:16px}.localization_denied_modal-module_localizationDeniedModal-cE7hB .localization_denied_modal-module_modalTitle-x2j9u{font:var(--medium) var(--body-1);margin-top:32px}.localization_denied_modal-module_localizationDeniedModal-cE7hB .localization_denied_modal-module_modalContent-LqDl-{font-size:var(--body-3);margin-top:8px}.localization_denied_modal-module_localizationDeniedModal-cE7hB .localization_denied_modal-module_modalButtons-KkcCZ{display:flex;flex-direction:column}.localization_denied_modal-module_localizationDeniedModal-cE7hB .localization_denied_modal-module_modalButtons-KkcCZ button{margin-top:24px}", "",{"version":3,"sources":["webpack://./app/components/localization_denied_modal/localization_denied_modal.module.scss"],"names":[],"mappings":"AAAA,gEACC,YAAA,CACA,mHACC,gCAAA,CACA,eAAA,CAED,qHACC,uBAAA,CACA,cAAA,CAED,qHACC,YAAA,CACA,qBAAA,CACA,4HACC,eAAA","sourcesContent":[".localizationDeniedModal {\n\tpadding: 16px;\n\t.modalTitle {\n\t\tfont: var(--medium) var(--body-1);\n\t\tmargin-top: 32px;\n\t}\n\t.modalContent {\n\t\tfont-size: var(--body-3);\n\t\tmargin-top: 8px;\n\t}\n\t.modalButtons {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tbutton {\n\t\t\tmargin-top: 24px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"localizationDeniedModal": "localization_denied_modal-module_localizationDeniedModal-cE7hB",
	"modalTitle": "localization_denied_modal-module_modalTitle-x2j9u",
	"modalContent": "localization_denied_modal-module_modalContent-LqDl-",
	"modalButtons": "localization_denied_modal-module_modalButtons-KkcCZ"
};
module.exports = ___CSS_LOADER_EXPORT___;
