import { useState } from 'react';
import { sendOpenDatePickerEvent } from '../analytics';
import { DATE, getDateIdByDate } from '../date_filter_helpers';

// translation keys
const defaultPlaceholder = 'search.placeholders.when.default';
const dateReset = 'search.placeholders.when.dateReset';

/**
 * Hook to handle Date field and DatePicker interactions (value, display, clicks) (in search page)
 * @param {Function} updateFilter function coming from filters context in order to update the date picked
 * @returns
 */
export const useDateFilter = updateFilter => {
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
	const toggleIsDatePickerOpen = () => setIsDatePickerOpen(!isDatePickerOpen);
	const closeDatePicker = () => setIsDatePickerOpen(false);

	const [textValueField, setTextValueField] = useState(defaultPlaceholder);
	const isDateChosenOnce = textValueField === dateReset;

	// Hande input click to open DatePicker
	const handleInputClick = () => {
		sendOpenDatePickerEvent();
		toggleIsDatePickerOpen();
	};

	// Handle choice click on date in DatePicker
	const handleDateChange = date => {
		toggleIsDatePickerOpen();
		setTextValueField(dateReset); // text value when a date has been chosen once
		// update date in filter context
		updateFilter({ type: DATE, id: getDateIdByDate(date), date });
	};

	return {
		isDatePickerOpen,
		isDateChosenOnce,
		closeDatePicker,
		textValueField,
		handleInputClick,
		handleDateChange
	};
};
