import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './bottom_links.module.scss';

export function BottomLinks({ title, links, getText, getPath, className }) {
	const classes = classNames.bind(styles)({
		bottomLinks: true,
		[className]: className
	});
	useStyles(styles);

	if (!links?.length) {
		return null;
	}
	return (
		<section className={classes}>
			<h2 className={styles.title}>{title}</h2>
			<ul>
				{links.map((link, i) => (
					<li key={i}>
						<Link to={getPath(link)}>{getText(link)}</Link>
					</li>
				))}
			</ul>
		</section>
	);
}
