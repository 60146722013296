import { fetchUserCures } from '@planity/helpers';
import { useEffect, useState } from 'react';

export const useCuresUserFetcher = ({
	userId,
	userIsLoading,
	businessId
} = {}) => {
	const [curesData, setCuresData] = useState([]);
	const [curesDataAreLoading, setCuresDataAreLoading] = useState(true);

	useEffect(() => {
		const getUserCures = async () => {
			setCuresDataAreLoading(true);
			try {
				if (!userIsLoading && userId) {
					const userCuresData = await fetchUserCures(businessId);
					setCuresData(userCuresData);
				}
			} catch (error) {
				console.error(
					'An error occurred while fetching userCures Data:',
					error
				);
				setCuresData([]);
			} finally {
				setCuresDataAreLoading(false);
			}
		};

		getUserCures();
	}, [userId, userIsLoading, businessId]);

	return {
		curesData,
		curesDataAreLoading
	};
};
