import React, { useEffect } from 'react';
import { useLocalizedRoutes } from '@planity/localization';
import { MyAccountGate, useErrorMessages, withAuth } from '@planity/components';
import { useTranslation } from '@planity/localization';
import styles from './my_account_page.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import Helmet from 'react-helmet';
import { Layout, Breadcrumbs } from '../layout';

export const MyAccountPage = withAuth(
	({ history, match, isSignedIn, className, location }) => {
		const { routes } = useLocalizedRoutes();
		const { t } = useTranslation();
		const classes = classNames.bind(styles)({
			myAccountPage: true,
			[className]: className
		});
		useStyles(styles);
		const { pushMessage } = useErrorMessages();

		// WAITING LIST: display DELETED_APPOINTMENT error message
		useEffect(() => {
			location?.state?.deletedAppointment &&
				pushMessage({
					message: t('bookAppointment.errors.DELETED_APPOINTMENT'),
					type: 'error'
				});
		}, [location?.state?.deletedAppointment]);

		// WAITING LIST: display message when waiting list is deactived (from mail)
		useEffect(() => {
			location?.state?.unsubscribeWaitingList &&
				pushMessage({
					message: t('myAccount.appointment.unsubscribeWaitingList.success'),
					type: 'success'
				});
		}, [location?.state?.unsubscribeWaitingList]);

		const linkToBusiness = business =>
			history.push(routes.catchAll({ business }));
		const linkToBookAppointment = ({
			business,
			sequence,
			isDeposit,
			veventToDelete
		}) =>
			history.push(
				routes.catchAll({
					reservation: { business },
					state: { sequence, business, isDeposit, veventToDelete }
				})
			);
		const linkToEshop = ({ business }) =>
			history.push(
				routes.catchAll({
					eshopBusiness: business,
					state: { business }
				})
			);

		const linkToClickAndCollect = ({
			business,
			isClickAndCollect = false,
			isClickAndShop = false,
			shippingExplanationSentence = ''
		}) =>
			history.push(
				routes.catchAll({
					cacBusiness: business,
					state: {
						business,
						isClickAndCollect,
						isClickAndShop,
						shippingExplanationSentence
					}
				})
			);

		return (
			<Layout
				className={classes}
				hasBackgroundColor
				hideBreadcrumbs
				isDark
				showAppTeaser={!match.params.key}
				skipCoronavirusModal
				withHeaderMarginTop
			>
				<div>
					<Helmet>
						<title>{t('myAccount.metaTitle')}</title>
						<meta content={'noindex,nofollow'} name={'robots'} />
					</Helmet>
					{isSignedIn && (
						<Breadcrumbs
							className={styles.breadcrumbsContainer}
							crumbs={[
								{
									name: t('nav.myAccount'),
									path: routes.myAccount
								}
							]}
							isHiddenOnMobile
							isHiddenOnTablet
						/>
					)}
					<MyAccountGate
						history={history}
						linkToBookAppointment={linkToBookAppointment}
						linkToBusiness={linkToBusiness}
						linkToClickAndCollect={linkToClickAndCollect}
						linkToEshop={linkToEshop}
						reviewKey={match.params.key}
					/>
				</div>
			</Layout>
		);
	}
);
