import React from 'react';
import classNames from 'classnames/bind';
import styles from './date_picker_header.module.scss';
import { Icon, DatePickerFilters } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { format as formatDate, isThisMonth } from 'date-fns';
import capitalize from 'lodash/capitalize';
import { useLocalization } from '@planity/localization';

export function DatePickerHeader({
	className,
	date,
	decreaseMonth,
	increaseMonth,
	prevMonthButtonDisabled: prevMonthDisabled,
	nextMonthButtonDisabled: nextMonthDisabled,
	selectedDate,
	hideQuickFilters,
	handleChange,
	sendPickDateFromLabelEvent
}) {
	useStyles(styles);

	const { dateLocale } = useLocalization();
	const classes = classNames.bind(styles)({
		DatePickerHeader: true,
		[className]: className !== undefined
	});
	const isCurrentMonth = isThisMonth(date);
	const onFilterClick = date => {
		sendPickDateFromLabelEvent();
		handleChange(date);
	};

	return (
		<div className={classes}>
			<div className={` ${isCurrentMonth ? 'current-month--today' : ''}`}>
				{!hideQuickFilters && (
					<DatePickerFilters
						selectedDate={selectedDate}
						onFilterClick={onFilterClick}
					/>
				)}
				<div className={styles.header}>
					<button
						disabled={prevMonthDisabled}
						className={styles.previous}
						onClick={decreaseMonth}
					>
						<Icon
							disabled={prevMonthDisabled}
							icon='ChevronLeft'
							className={styles.icon}
						/>
					</button>
					<span className={styles.month}>
						{capitalize(
							formatDate(date || new Date(), 'MMMM yyyy', {
								locale: dateLocale
							})
						)}
					</span>
					<button
						disabled={nextMonthDisabled}
						className={styles.next}
						onClick={increaseMonth}
					>
						<Icon
							disabled={nextMonthDisabled}
							icon='ChevronRight'
							className={styles.icon}
						/>
					</button>
				</div>
			</div>
		</div>
	);
}
