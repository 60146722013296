export function Information({ color, width = '24', height = '24' }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M12.8 13V15'
				stroke={color ? color : 'currentColor'}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M13.3 9C13.3 9.27614 13.0761 9.5 12.8 9.5C12.5238 9.5 12.3 9.27614 12.3 9C12.3 8.72386 12.5238 8.5 12.8 8.5C13.0761 8.5 13.3 8.72386 13.3 9Z'
				stroke='currentColor'
			/>
			<path
				d='M20.05 12C20.05 16.0041 16.8041 19.25 12.8 19.25C8.79592 19.25 5.54999 16.0041 5.54999 12C5.54999 7.99594 8.79592 4.75 12.8 4.75C16.8041 4.75 20.05 7.99594 20.05 12Z'
				stroke={color ? color : 'currentColor'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
