import { invokePhoneNumberVerificationRecaptchaProtected } from '@planity/components';
import { initlializeRecaptcha } from './hooks';

//get recaptcha token and invoke step function
export const sendPhoneNumberVerificationCodeRecaptchaProtected = ({
	phoneNumber,
	locale
}) => {
	const onTokenResponse = async token => {
		await invokePhoneNumberVerificationRecaptchaProtected(
			{
				phoneNumber,
				token
			},
			null,
			{ locale }
		);
	};
	const { getRecaptchaToken } = initlializeRecaptcha(onTokenResponse);
	getRecaptchaToken();
};
