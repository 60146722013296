import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './appointment_sequence.scss';
import { Icon, InlineLink } from '@planity/ui';
import { useTranslation } from '@planity/localization';
import useStyles from 'isomorphic-style-loader/useStyles';
import { noop, prepareSequence } from '@planity/helpers';

export const AppointmentSequence = ({
	appointment,
	onSeeMoreClick = noop,
	isVisible = false,
	collapseSteps = false,
	children
}) => {
	useStyles(styles);

	const classes = classNames.bind(styles);
	const [isCollapsed, setIsCollapsed] = useState(!!collapseSteps);

	const preparedSequence = useMemo(
		() => prepareSequence(appointment)?.sequence,
		[appointment]
	);
	const allAppointmentSequence = (preparedSequence || []).map(
		step => step.stepId
	);

	const { t } = useTranslation();

	useEffect(() => {
		if (!collapseSteps) return;
		if (!isCollapsed && !isVisible) {
			setIsCollapsed(true);
		}
	}, [isVisible, isCollapsed, collapseSteps]);

	const collapsedChildren = children?.slice(1);

	return (
		<>
			<div>{children?.[0]}</div>
			<div
				className={classes({
					stepsDescriptionWrapper: true,
					isCollapsed
				})}
			>
				<div
					className={classes({
						stepsDescription: true
					})}
				>
					{collapsedChildren}
				</div>
			</div>
			{collapseSteps && (
				<div
					className={classes({
						collapseContainer: true,
						isVisible: allAppointmentSequence?.length > 1,
						isCollapsed
					})}
					onClick={() => {
						setIsCollapsed(!isCollapsed);
						onSeeMoreClick();
					}}
				>
					<Icon
						size={'medium'}
						icon={isCollapsed ? 'ChevronDown' : 'ChevronUp'}
					/>
					<InlineLink
						label={
							<>
								{isCollapsed ? '+' : '-'}{' '}
								{t('business.bookAppointment.count', {
									count: allAppointmentSequence?.length - 1
								})}
							</>
						}
					/>
				</div>
			)}
		</>
	);
};
