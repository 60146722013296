import classNames from 'classnames/bind';
import styles from './business_review.module.scss';
import { Icon } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';

export function BusinessReview({
	note = 0,
	text = '',
	date = '',
	reply,
	replyFrom,
	outlined = false,
	className,
	isFiveStars
}) {
	useStyles(styles);

	const { t } = useTranslation();
	const cx = classNames.bind(styles);

	const Star = ({ star }) => (
		<div
			className={cx({
				alignStars: true
			})}
			id='review-star'
		>
			{star}
		</div>
	);

	const stars = [];
	for (let i = 1; i < 6; i++) {
		const star =
			i <= note ? (
				<Star
					key={i}
					star={
						<Icon
							icon='Star'
							fill={'var(--grey-900)'}
							strokeWidth={'1.5'}
							outlined
							size={'small'}
						/>
					}
				/>
			) : !Number.isInteger(note) && i === Math.ceil(note) ? (
				<Star
					key={i}
					star={
						<Icon
							icon='HalfEmptyStar'
							fill='white'
							strokeWidth={'1.5'}
							outlined
							size={'small'}
						/>
					}
				/>
			) : (
				<Star
					key={i}
					star={
						<Icon icon='Star' strokeWidth={'1.5'} outlined size={'small'} />
					}
				/>
			);

		stars.push(<Star key={i} star={star} />);
	}

	return (
		<div
			className={cx({
				review: true,
				[className]: true
			})}
		>
			{note && (
				<div className={styles.note}>
					{note}
					{isFiveStars ? (
						<div className={styles.stars}>{stars}</div>
					) : (
						<Icon
							icon={outlined ? 'Star' : 'FullStar'}
							className={styles.icon}
							size={'small'}
							outlined={outlined}
						/>
					)}
				</div>
			)}

			{text && <span>{text}</span>}
			{date && <span className={styles.date}>{date}</span>}

			{reply && (
				<div
					className={cx({
						reply: true,
						isSearch: outlined
					})}
				>
					<span className={styles.replyFrom}>
						{t('business.reviews.answerOf')} {replyFrom}
					</span>
					<span className={styles.replyMsg}>{reply}</span>
				</div>
			)}
		</div>
	);
}
