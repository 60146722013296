import { addDays, isSameDay } from 'date-fns';

export const SORT = 'sort';
export const DATE = 'date';
export const PUSH = 'push';
export const CLEAN = 'clean';
export const GETGLOBAL = 'GETGLOBAL';

export const filtersdDefaultState = {
	byDate: 0,
	sort: 0,
	data: { date: null }
};

export const dateIdMap = {
	dayReset: 0,
	today: 1,
	tomorrow: 2,
	openDatepicker: 3
};

export const kindOfFilter = {
	byDate: {
		label: 'search.filter.availabilities',
		type: DATE,
		range: [
			{ name: 'search.filter.dateReset', id: dateIdMap.dayReset },
			{ name: 'search.filter.today', id: dateIdMap.today },
			{ name: 'search.filter.tomorrow', id: dateIdMap.tomorrow },
			{
				name: 'search.filter.openDatepicker',
				id: dateIdMap.openDatepicker
			}
		],
		hasDesktopDisplay: false
	},
	sort: {
		label: 'search.filter.sort',
		type: SORT,
		range: [
			{ name: 'common.filters.other', id: 0 },
			{ name: 'common.filters.bestReviewed', id: 3 },
			{ name: 'common.filters.mostExpensiveToCheapest', id: 1 },
			{ name: 'common.filters.cheapestToMostExpensive', id: 2 }
		],
		hasDesktopDisplay: true
	}
};

export const getDateByDateId = id => {
	switch (id) {
		case dateIdMap.today:
			return new Date();
		case dateIdMap.tomorrow:
			return addDays(new Date(), 1);
		// when we open the date picker for the first time the date is not selected yet
		case dateIdMap.dayReset:
		case dateIdMap.openDatepicker:
		default:
			return null;
	}
};

export const getDateIdByDate = date => {
	if (!date) {
		return dateIdMap.dayReset;
	}
	if (isSameDay(date, new Date())) {
		return dateIdMap.today;
	}

	if (isSameDay(date, addDays(new Date(), 1))) {
		return dateIdMap.tomorrow;
	}
	return dateIdMap.openDatepicker;
};
