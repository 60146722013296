import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { format as formatDate } from 'date-fns';
import { colors, fontWeight } from '@planity/theme';
import {
	GooglePlaceSuggestionText,
	GooglePlacesAutocomplete,
	Localize,
	withUserQueries
} from '@planity/components';
import { withSearch } from '@planity/context';
import { SearchHeader } from '../ui/search_header';
import { Icon, Spinner, withModal } from '@planity/ui';
import {
	decodeMessageFromUserApp,
	isNativeApp,
	sendToUserApp
} from '@planity/webview';
import { LocalizationDeniedModal } from '../localization_denied_modal';

/**
 * Should be refactored like the components here
 * `apps/website/app/components/search_business_page`
 */
export const SearchPlacePage = withSearch(
	withUserQueries(
		withModal(
			class extends Component {
				state = {
					error: null,
					isMounted: false,
					isLoading: false,
					isSearchingAroundMe: false
				};

				componentDidMount() {
					this.setState({ isMounted: true });
					if (isNativeApp) {
						window.addEventListener('message', this.userAppCurrentLocation);
					}
				}

				componentWillUnmount() {
					if (isNativeApp) {
						window.removeEventListener('message', this.userAppCurrentLocation);
					}
				}

				render() {
					// hack to avoid autofill on inputs
					const inputIdsSuffix = formatDate(
						new Date(),
						this.state.isMounted ? 'yyyyMMddHHmmss' : 'yyyyMMdd'
					);
					const { googlePlace, place, parentPlace } = this.props;
					return (
						<div css={styles.searchPlace}>
							<Helmet>
								<meta content={'noindex,nofollow'} name={'robots'} />
							</Helmet>
							<div css={styles.fixedHeader}>
								<SearchHeader isSearchPlace />
								<Localize text={'search.placeholders'}>
									{placeholders => (
										<GooglePlacesAutocomplete
											autoFocus
											css={styles.input}
											iconBlank={{
												code: 'M15.579 14.557L20 18.978 18.978 20l-4.421-4.421a6.5 6.5 0 1 1 1.022-1.022zm-5.079.999a5.056 5.056 0 1 0 0-10.112 5.056 5.056 0 0 0 0 10.112z',
												color: '#979797'
											}}
											iconFilled={{
												code: 'M12 10.429l6.285-6.286 1.572 1.572L13.57 12l6.286 6.285-1.572 1.572L12 13.57l-6.285 6.286-1.572-1.572L10.43 12 4.143 5.715l1.572-1.572L12 10.43z',
												color: '#979797'
											}}
											inputId={`searchBar-places-${inputIdsSuffix}`}
											isSearchingAroundMe={this.state.isSearchingAroundMe}
											placeholder={
												placeholders.placeShort +
												'  (' +
												placeholders.place +
												')'
											}
											renderSuggestion={this.renderPlaceSuggestion}
											selectedItem={
												this.state.isSearchingAroundMe
													? null
													: googlePlace || place || parentPlace
											}
											textFilled
											onSelect={this.onSelect}
										/>
									)}
								</Localize>
							</div>
							<div css={styles.body}>
								<div
									css={[
										styles.aroundMe,
										this.state.isLoading && styles.suggestionSelected
									]}
									onClick={this.getCurrentLocation}
								>
									<Icon icon='Pin' size={'medium'} />
									<span>
										<Localize text={'search.aroundMe'} />
									</span>
								</div>
								{this.state.error && (
									<p css={styles.geolocationError}>
										<Localize text={this.state.error} />
									</p>
								)}
								{this.state.isLoading && (
									<div css={styles.spinner}>
										<Spinner />
									</div>
								)}
							</div>
						</div>
					);
				}

				renderPlaceSuggestion = ({
					suggestion,
					isHighlighted,
					suggestions
				}) => {
					if (!suggestion) return null;
					return (
						<div
							css={[
								styles.suggestion,
								isHighlighted && styles.highlightedSuggestion
							]}
						>
							<GooglePlaceSuggestionText
								suggestion={suggestion}
								suggestions={suggestions}
							/>
						</div>
					);
				};

				onSelect = googlePlace => {
					const { category, setSearch } = this.props;

					setSearch(
						{
							googlePlace,
							place: null,
							parentPlace: null,
							category: category || null,
							userLocation: null
						},
						null,
						true
					);
				};

				userAppCurrentLocation = event => {
					const decodedMessage = decodeMessageFromUserApp(event.data);
					if (!decodedMessage) return;

					const { type, params } = decodedMessage;

					switch (type) {
						case 'DATA_FROM_USER_APP': {
							const { location } = params;

							if (!location?.coords) {
								return this.getCurrentLocationFailure({
									openLocationDeniedModal: false
								});
							}
							return this.getCurrentLocationSuccess(location);
						}
						case 'ERROR_MESSAGE': {
							return this.getCurrentLocationFailure();
						}
						default:
							console.log(
								'Unhandled message received from user-app. Ignoring.',
								{
									type,
									params
								}
							);
					}
				};

				getCurrentLocationSuccess = position => {
					const { category, setSearch } = this.props;

					this.setState(
						{
							isLoading: false,
							isSearchingAroundMe: true
						},
						() => {
							setSearch(
								{
									googlePlace: null,
									place: null,
									parentPlace: null,
									category,
									userLocation: {
										lat: position.coords.latitude,
										lng: position.coords.longitude
									}
								},
								null,
								true
							);
						}
					);
				};

				getCurrentLocationFailure = ({ openLocationDeniedModal = true }) => {
					this.setState({
						isLoading: false,
						isSearchingAroundMe: false,
						error: 'search.geolocationError'
					});
					if (openLocationDeniedModal) {
						this.props.setModal({
							content: <LocalizationDeniedModal />,
							hasCloseBtn: true
						});
					}
				};

				getCurrentLocation = () => {
					this.setState(
						{
							isLoading: true
						},
						() => {
							if (isNativeApp) {
								sendToUserApp({ type: 'GEOLOCATION', payload: null });
								return;
							}

							if (navigator.geolocation) {
								navigator.geolocation.getCurrentPosition(
									this.getCurrentLocationSuccess,
									this.getCurrentLocationFailure
								);
							} else {
								this.getCurrentLocationFailure();
							}
						}
					);
				};
			}
		)
	)
);

const orSize = 26;
const styles = {
	searchPlace: {
		height: '100vh',
		backgroundColor: 'var(--grey-100)'
	},
	input: {
		'& > input': {
			'width': '100%',
			'border': 'none',
			'borderRadius': 0,
			'padding': 16,
			'position': 'relative',
			'WebkitAppearance': 'none',
			'font': 'var(--regular) var(--body-3)',
			'boxShadow': '0px 2px 4px -1px rgba(26, 27, 31, 0.05)',
			':focus': {
				outline: 0
			}
		},
		'& > svg': {
			color: 'rgb(205, 206, 207)'
		}
	},
	businessesInput: {
		':after': {
			content: '"ou"',
			backgroundColor: colors.main.primary,
			color: 'white',
			width: orSize,
			height: orSize,
			borderRadius: orSize / 2,
			textAlign: 'center',
			lineHeight: `${orSize - 2}px`,
			fontSize: '.75em',
			fontWeight: fontWeight.normal,
			position: 'absolute',
			top: '50%',
			right: (orSize * -1) / 2,
			marginTop: (orSize * -1) / 2,
			zIndex: 1,
			display: 'none'
		}
	},
	suggestion: {
		padding: '14px 16px',
		fontSize: '13px',
		whiteSpace: 'nowrap',
		display: 'inline-table',
		height: 42,
		width: '100%',
		backgroundColor: 'var(--grey-100)'
	},
	suggestionSelected: {
		backgroundColor: 'var(--grey-900)',
		color: 'var(--white)'
	},
	highlightedSuggestion: {
		backgroundColor: colors.grey.light
	},
	aroundMe: {
		'display': 'flex',
		'alignItems': 'center',
		'padding': '14px 16px',
		'cursor': 'pointer',
		'userSelect': 'none',
		'font': 'var(--regular) var(--body-5)',
		'textDecoration': 'underline',
		'color': 'var(--grey-700)',
		'gap': 4,
		':focus': {
			backgroundColor: 'var(--grey-700)',
			color: 'white',
			outline: 0
		}
	},
	suggestionPlaces: {
		font: 'var(--regular) var(--body-3)',
		color: 'var(--grey-700)',
		padding: '14px 16px'
	},
	geolocationError: {
		margin: '0px 20px',
		textAlign: 'center',
		fontSize: '.75em',
		fontStyle: 'italic',
		fontWeight: fontWeight.normal,
		color: colors.grey.text,
		padding: '0.6em 1em'
	},
	spinner: {
		paddingTop: '1em',
		display: 'flex',
		justifyContent: 'center'
	},
	title: {
		padding: '24px 16px 4px',
		font: 'var(--medium) var(--body-4)',
		color: 'var(--grey-600)'
	},
	fixedHeader: {
		position: 'sticky',
		top: 0,
		zIndex: 1000
	},
	body: {}
};
