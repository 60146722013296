import React from 'react';
import { Localize } from '@planity/components';
import { Button } from '@planity/ui';

export default function ClickAndCollectCTA({ id, onPress, size }) {
	return (
		<Button
			iconLeft={'Cart'}
			id={id}
			label={<Localize text={'clickAndCollect.shop'} />}
			size={size}
			type={'tertiary'}
			onClick={onPress}
		/>
	);
}
