import { MainSearchInput } from '@planity/ui';
import React from 'react';
import styles from './fake_search_bar.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';

/**
 * The FakeSearchBar is that component that looks like a searchbar but is, in
 * fact, just a link to another place 🤷
 * @param id {string | null}
 * @param label {string} the text displayed in the upper part of the input
 * @param placeholder {string} the placeholder, like, for real
 * @param onClick {function}
 * @param displayOnlyOnMobileAndTablet {boolean}
 * @param hide {boolean}
 */
export const FakeSearchBar = ({
	id,
	label,
	placeholder,
	onClick,
	displayOnlyOnMobileAndTablet,
	hide
}) => {
	useStyles(styles);
	const classes = classNames.bind(styles);

	return (
		<MainSearchInput
			id={id}
			className={classes({
				fakeSearchBar: true,
				displayOnlyOnMobileAndTablet,
				hide
			})}
			label={label}
			onClick={onClick}
			placeholder={placeholder}
		/>
	);
};
