import { useEffect, useLayoutEffect } from 'react';

/**
 * Isomorphic is just a fancy word for "it works on server and on client".
 * useLayoutEffect is not defined in server. useEffect is defined but do not run in server.
 * So, for a server, useLayoutEffect is like useEffect. This way we avoid the
 * warning "useLayoutEffect does nothing on the server, because its effect
 * cannot be encoded into the server renderer's output format"
 */
export const useIsomorphicLayoutEffect = process.env.BROWSER
	? useLayoutEffect
	: useEffect;
