import credentials from '@planity/credentials';

const log2 = Math.log2 ? Math.log2 : x => Math.log(x) / Math.LN2;

const MAPBOX_TILE_SIZE = 512;
// const GOOGLE_TILE_SIZE = 256;

function latLng2World({ lat, lng }) {
	const sin = Math.sin((lat * Math.PI) / 180);
	const x = lng / 360 + 0.5;
	let y = 0.5 - (0.25 * Math.log((1 + sin) / (1 - sin))) / Math.PI;

	y =
		y < 0 // eslint-disable-line
			? 0
			: y > 1
			? 1
			: y;
	return { x, y };
}

function world2LatLng({ x, y }) {
	const n = Math.PI - 2 * Math.PI * y;

	// TODO test that this is faster
	// 360 * Math.atan(Math.exp((180 - y * 360) * Math.PI / 180)) / Math.PI - 90;
	return {
		lat: (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))),
		lng: x * 360 - 180
	};
}

// Thank you wiki https://en.wikipedia.org/wiki/Geographic_coordinate_system
function latLng2MetersPerDegree({ lat }) {
	const phi = (lat * Math.PI) / 180;
	const metersPerLatDegree =
		111132.92 -
		559.82 * Math.cos(2 * phi) +
		1.175 * Math.cos(4 * phi) -
		0.0023 * Math.cos(6 * phi);
	const metersPerLngDegree =
		111412.84 * Math.cos(phi) -
		93.5 * Math.cos(3 * phi) +
		0.118 * Math.cos(5 * phi);
	return { metersPerLatDegree, metersPerLngDegree };
}

function meters2LatLngBounds(meters, { lat, lng }) {
	const { metersPerLatDegree, metersPerLngDegree } = latLng2MetersPerDegree({
		lat
	});

	const latDelta = (0.5 * meters) / metersPerLatDegree;
	const lngDelta = (0.5 * meters) / metersPerLngDegree;

	return {
		nw: {
			lat: lat - latDelta,
			lng: lng - lngDelta
		},
		se: {
			lat: lat + latDelta,
			lng: lng + lngDelta
		}
	};
}

function meters2WorldSize(meters, { lat, lng }) {
	const { nw, se } = meters2LatLngBounds(meters, { lat, lng });
	const nwWorld = latLng2World(nw);
	const seWorld = latLng2World(se);
	const w = Math.abs(seWorld.x - nwWorld.x);
	const h = Math.abs(seWorld.y - nwWorld.y);

	return { w, h };
}

function fitNwSe(nw, se, width, height) {
	const EPS = 0.000000001;
	const nwWorld = latLng2World(nw);
	const seWorld = latLng2World(se);
	const dx =
		nwWorld.x < seWorld.x ? seWorld.x - nwWorld.x : 1 - nwWorld.x + seWorld.x;
	const dy = seWorld.y - nwWorld.y;

	if (dx <= 0 && dy <= 0) {
		return null;
	}

	const zoomX = log2(width / MAPBOX_TILE_SIZE / dx);
	const zoomY = log2(height / MAPBOX_TILE_SIZE / dy);
	const zoom = Math.floor(EPS + Math.min(zoomX, zoomY));

	// TODO find center just unproject middle world point
	const middle = {
		x:
			nwWorld.x < seWorld.x // eslint-disable-line
				? 0.5 * (nwWorld.x + seWorld.x)
				: nwWorld.x + seWorld.x - 1 > 0
				? 0.5 * (nwWorld.x + seWorld.x - 1)
				: 0.5 * (1 + nwWorld.x + seWorld.x),
		y: 0.5 * (nwWorld.y + seWorld.y)
	};

	const scale = Math.pow(2, zoom);
	const halfW = width / scale / MAPBOX_TILE_SIZE / 2;
	const halfH = height / scale / MAPBOX_TILE_SIZE / 2;

	const newNW = world2LatLng({
		x: middle.x - halfW,
		y: middle.y - halfH
	});

	const newSE = world2LatLng({
		x: middle.x + halfW,
		y: middle.y + halfH
	});

	return {
		center: world2LatLng(middle),
		zoom,
		newBounds: {
			nw: newNW,
			se: newSE
		}
	};
}

export function convertNeSwToNwSe({ ne, sw }) {
	return {
		nw: {
			lat: ne.lat,
			lng: sw.lng
		},
		se: {
			lat: sw.lat,
			lng: ne.lng
		}
	};
}

export function convertNwSeToNeSw({ nw, se }) {
	return {
		ne: {
			lat: nw.lat,
			lng: se.lng
		},
		sw: {
			lat: se.lat,
			lng: nw.lng
		}
	};
}

export function fitBounds({ nw, se, ne, sw }, { width, height }) {
	let fittedData;

	if (nw && se) {
		fittedData = fitNwSe(nw, se, width, height);
	} else {
		const calculatedNwSe = convertNeSwToNwSe({ ne, sw });
		fittedData = fitNwSe(calculatedNwSe.nw, calculatedNwSe.se, width, height);
	}

	return {
		...fittedData,
		newBounds: {
			...fittedData.newBounds,
			...convertNwSeToNeSw(fittedData.newBounds)
		}
	};
}

// -------------------------------------------------------------------
// Helpers to calc some markers size

export function meters2ScreenPixels(meters, { lat, lng }, zoom) {
	const { w, h } = meters2WorldSize(meters, { lat, lng });
	const scale = Math.pow(2, zoom);
	const wScreen = w * scale * MAPBOX_TILE_SIZE;
	const hScreen = h * scale * MAPBOX_TILE_SIZE;
	return {
		w: wScreen,
		h: hScreen
	};
}

// --------------------------------------------------
// Helper functions for working with svg tiles, (examples coming soon)

export function tile2LatLng({ x, y }, zoom) {
	const n = Math.PI - (2 * Math.PI * y) / Math.pow(2, zoom);

	return {
		lat: (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))),
		lng: (x / Math.pow(2, zoom)) * 360 - 180
	};
}

export function latLng2Tile({ lat, lng }, zoom) {
	const worldCoords = latLng2World({ lat, lng });
	const scale = Math.pow(2, zoom);

	return {
		x: Math.floor(worldCoords.x * scale),
		y: Math.floor(worldCoords.y * scale)
	};
}

export function getTilesIds({ from, to }, zoom) {
	const scale = Math.pow(2, zoom);

	const ids = [];
	for (let x = from.x; x !== (to.x + 1) % scale; x = (x + 1) % scale) {
		for (let y = from.y; y !== (to.y + 1) % scale; y = (y + 1) % scale) {
			ids.push([zoom, x, y]);
		}
	}

	return ids;
}

function wrap(n, min, max) {
	const d = max - min;
	const w = ((((n - min) % d) + d) % d) + min;
	return w === min ? max : w;
}

export class LngLat {
	lng;
	lat;

	constructor(lng, lat) {
		if (isNaN(lng) || isNaN(lat)) {
			throw new Error(`Invalid LngLat object: (${lng}, ${lat})`);
		}
		this.lng = +lng;
		this.lat = +lat;
		if (this.lat > 90 || this.lat < -90) {
			throw new Error(
				'Invalid LngLat latitude value: must be between -90 and 90'
			);
		}
	}

	wrap() {
		return new LngLat(wrap(this.lng, -180, 180), this.lat);
	}

	toArray() {
		return [this.lng, this.lat];
	}

	toString() {
		return `LngLat(${this.lng}, ${this.lat})`;
	}

	toBounds(radius) {
		const earthCircumferenceInMetersAtEquator = 40075017;
		const latAccuracy = (360 * radius) / earthCircumferenceInMetersAtEquator,
			lngAccuracy = latAccuracy / Math.cos((Math.PI / 180) * this.lat);

		return new LngLatBounds(
			new LngLat(this.lng - lngAccuracy, this.lat - latAccuracy),
			new LngLat(this.lng + lngAccuracy, this.lat + latAccuracy)
		);
	}

	static convert(input) {
		if (input instanceof LngLat) {
			return input;
		}
		if (Array.isArray(input) && (input.length === 2 || input.length === 3)) {
			return new LngLat(Number(input[0]), Number(input[1]));
		}
		if (!Array.isArray(input) && typeof input === 'object' && input !== null) {
			return new LngLat(
				// flow can't refine this to have one of lng or lat, so we have to cast to any
				Number('lng' in input ? input.lng : input.lon),
				Number(input.lat)
			);
		}
		throw new Error(
			'`LngLatLike` argument must be specified as a LngLat instance, an object {lng: <lng>, lat: <lat>}, an object {lon: <lng>, lat: <lat>}, or an array of [<lng>, <lat>]'
		);
	}
}

export class LngLatBounds {
	_ne;
	_sw;

	// This constructor is too flexible to type. It should not be so flexible.
	constructor(sw, ne) {
		if (!sw) {
			// noop
		} else if (ne) {
			this.setSouthWest(sw).setNorthEast(ne);
		} else if (sw.length === 4) {
			this.setSouthWest([sw[0], sw[1]]).setNorthEast([sw[2], sw[3]]);
		} else {
			this.setSouthWest(sw[0]).setNorthEast(sw[1]);
		}
	}

	setNorthEast(ne) {
		this._ne =
			ne instanceof LngLat ? new LngLat(ne.lng, ne.lat) : LngLat.convert(ne);
		return this;
	}

	setSouthWest(sw) {
		this._sw =
			sw instanceof LngLat ? new LngLat(sw.lng, sw.lat) : LngLat.convert(sw);
		return this;
	}

	extend(obj) {
		const sw = this._sw,
			ne = this._ne;
		let sw2, ne2;

		if (obj instanceof LngLat) {
			sw2 = obj;
			ne2 = obj;
		} else if (obj instanceof LngLatBounds) {
			sw2 = obj._sw;
			ne2 = obj._ne;

			if (!sw2 || !ne2) return this;
		} else {
			if (Array.isArray(obj)) {
				if (obj.every(Array.isArray)) {
					return this.extend(LngLatBounds.convert(obj));
				} else {
					return this.extend(LngLat.convert(obj));
				}
			}
			return this;
		}

		if (!sw && !ne) {
			this._sw = new LngLat(sw2.lng, sw2.lat);
			this._ne = new LngLat(ne2.lng, ne2.lat);
		} else {
			sw.lng = Math.min(sw2.lng, sw.lng);
			sw.lat = Math.min(sw2.lat, sw.lat);
			ne.lng = Math.max(ne2.lng, ne.lng);
			ne.lat = Math.max(ne2.lat, ne.lat);
		}
		return this;
	}

	getCenter() {
		return new LngLat(
			(this._sw.lng + this._ne.lng) / 2,
			(this._sw.lat + this._ne.lat) / 2
		);
	}

	getSouthWest() {
		return this._sw;
	}

	getNorthEast() {
		return this._ne;
	}

	getNorthWest() {
		return new LngLat(this.getWest(), this.getNorth());
	}

	getSouthEast() {
		return new LngLat(this.getEast(), this.getSouth());
	}

	getWest() {
		return this._sw.lng;
	}

	getSouth() {
		return this._sw.lat;
	}

	getEast() {
		return this._ne.lng;
	}

	getNorth() {
		return this._ne.lat;
	}

	toArray() {
		return [this._sw.toArray(), this._ne.toArray()];
	}

	toString() {
		return `LngLatBounds(${this._sw.toString()}, ${this._ne.toString()})`;
	}

	isEmpty() {
		return !(this._sw && this._ne);
	}

	static convert(input) {
		if (!input || input instanceof LngLatBounds) return input;
		return new LngLatBounds(input);
	}
}

export function staticMapboxMap({ lat, lng }, { width, height }) {
	return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/url-https%3A%2F%2Fres.cloudinary.com%2Fplanity%2Fimage%2Fupload%2Fv1561466059%2Fportail%2Fposition_3x.png(${lng},${lat})/${lng},${lat},16.0,0,0/${width}x${height}@2x/?access_token=${credentials.MAPBOX_API_KEY}`;
}

export function staticGmap({ lat, lng }, { width, height }) {
	return `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=16&key=${credentials.GOOGLE_API_BROWSER_KEY}&size=${width}x${height}&language=fr&markers=icon:https://d2skjte8udjqxw.cloudfront.net/pics/marker-active.png|${lat},${lng}`;
}
