import { noop } from '@planity/helpers';
import React, {
	Fragment,
	useContext,
	useEffect,
	useState,
	useMemo
} from 'react';
import { Localize } from '@planity/components';
import { Toast } from '@planity/ui';
import { useTranslation } from '@planity/localization';
import { decodeMessageFromUserApp, isNativeApp } from '@planity/webview';

const DEFAULT_ERROR_TYPE = 'error';
const DEFAULT_TIMEOUT = 6000;

const ErrorMessagesContext = React.createContext({
	messages: [],
	pushMessage: noop,
	dismissMessage: noop
});

export const useErrorMessages = () => useContext(ErrorMessagesContext);

export const ErrorMessagesProvider = ({ children }) => {
	const [messages, setMessages] = useState([]);
	const [timeoutIds, setTimeoutIds] = useState([]);

	const pushMessage = message => {
		setMessages(messages => {
			// Avoid duplicates created by many re-renders due to "ErrorMessage" implementation
			const hasDuplicates = messages.find(
				({ createdAt: _createdAt, message: _message }) =>
					message?.createdAt === _createdAt || message.message === _message
			);
			if (!hasDuplicates) return messages.concat(message);
			else return messages;
		});

		const timeoutId = setTimeout(() => {
			dismissMessage(message);
		}, message.timeout || DEFAULT_TIMEOUT);

		setTimeoutIds(ids => [...ids, timeoutId]);
	};

	/**
	 * Dismiss error message
	 * Could have been dismissed already (by timeout), but that's just an array
	 * filter, so it's ok.
	 * @param {object} message
	 */
	const dismissMessage = ({ createdAt }) => {
		setMessages(messages =>
			messages.filter(({ createdAt: _createdAt }) => _createdAt !== createdAt)
		);
	};

	const state = { messages, pushMessage, dismissMessage };

	useEffect(() => {
		if (isNativeApp) {
			const handleMessage = event => {
				const decodedMessage = decodeMessageFromUserApp(event.data);
				if (!decodedMessage) return;
				const { type, params } = decodedMessage;

				const { message, args } = params;
				if (type === 'ERROR_MESSAGE') {
					return pushMessage({
						message: 'webview.errors.' + message,
						type: 'error',
						args,
						timeout: 3000
					});
				}
				if (type === 'SUCCESS_MESSAGE') {
					pushMessage({
						message: 'webview.success.' + message,
						type: 'success',
						args,
						timeout: 3000
					});
				}
			};

			window.addEventListener('message', handleMessage);
			return () => {
				window.removeEventListener('message', handleMessage);
				timeoutIds.forEach(timeoutId => clearTimeout(timeoutId));
			};
		}
	}, []);

	return (
		<ErrorMessagesContext.Provider value={state}>
			{children}
		</ErrorMessagesContext.Provider>
	);
};

/**
 * This component was made for errors, but now it can also be used for success
 * and warning states. The variable name "error" has been kept because it was
 * risky to change it (I was lazy)
 * @deprecated This is a bad idea to use a component as a side effect. Juste use an effect if you
 *   need an effect. The hook useErrorMessages has now everything you need ! In every render, it
 *   tries to add a new message to the stack. That's why we have to base ourselves
 *   on message text and not on its id or something
 * @param error { String } the message to display
 * @param whitelist { Array } allowed messages
 * @param defaultMessage { String }
 * @param errorType { String } one of "error" (default), "warning" or "success"
 * @param args { Object } args to pass to translations
 * @param timeout {number} in ms, timeout before auto dismissal.
 */
export function ErrorMessage({
	error,
	whitelist,
	defaultMessage,
	errorType = DEFAULT_ERROR_TYPE,
	args,
	timeout = DEFAULT_TIMEOUT
}) {
	const { pushMessage } = useErrorMessages();
	const errorMessage = useMemo(
		() =>
			error && whitelist && !whitelist.includes(error) ? defaultMessage : error,
		[error, whitelist]
	);

	useEffect(() => {
		if (errorMessage) {
			pushMessage({
				message: errorMessage?.message || errorMessage,
				type: errorType,
				args: errorMessage?.args || args,
				createdAt: Date.now(),
				timeout
			});
		}
	}, [errorMessage]);

	return null;
}

export const ErrorMessages = ({ className }) => {
	const { messages, dismissMessage } = useErrorMessages();
	const { t } = useTranslation();

	const Messages = messages.map(({ createdAt, message, type, args }) => (
		<Toast
			closeToast={() => dismissMessage({ createdAt })}
			id={'error-message-toast'}
			key={createdAt}
			text={
				args?.withLinkToPhoneNumber ? (
					<MessageWithPhoneNumber args={args} message={errorMessage(message)} />
				) : (
					t(errorMessage(message), args)
				)
			}
			type={
				type === 'error' ? 'danger' : type === 'success' ? 'success' : 'warning'
			}
		/>
	));

	return className ? (
		<div css={className}>{Messages}</div>
	) : (
		<Fragment>{Messages}</Fragment>
	);
};

/**
 * Special case to handle when the phone number is clickable
 */
const MessageWithPhoneNumber = ({ message, args }) => {
	return (
		<Fragment>
			<Localize args={{ ...args }} text={errorMessage(message)} />
			<Localize text={'punctuation.space'} />
			<Localize text={'myAccount.appointment.errors.youShouldCall'} />
			<Localize text={'punctuation.space'} />
			<a
				href={`tel:${args.rawPhoneNumber}`}
				style={{ color: 'inherit', pointerEvents: 'all' }}
			>
				{args.phoneNumber}
			</a>
		</Fragment>
	);
};

const errorMessage = error => (error ? error.message || error : null);
