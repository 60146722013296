import React from 'react';
import { Localize } from '@planity/components';
import { colors } from '@planity/theme';

export const QuantitySelect = ({
	quantity,
	setQuantity,
	style,
	height,
	id
}) => {
	return (
		<div css={[styles.container, style]}>
			<div
				css={[
					styles.block,
					styles.button,
					{
						height: height
					}
				]}
				onClick={() => setQuantity(quantity - 1)}
			>
				<Localize text='punctuation.hyphen' />
			</div>
			<div
				css={[
					styles.block,
					styles.quantity,
					{
						height: height
					}
				]}
				id={`cart-item-quantity-${id}`}
			>
				{quantity}
			</div>
			<div
				css={[
					styles.block,
					styles.button,
					{
						height: height
					}
				]}
				onClick={() => setQuantity(quantity + 1)}
			>
				<Localize text='punctuation.plus' />
			</div>
		</div>
	);
};

const styles = {
	container: {
		width: 128,
		// height: 42,
		borderRadius: 4,
		border: `solid 1px ${colors.grey.border}`,
		backgroundColor: 'white',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	block: {
		width: 42,
		height: 42,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: colors.grey.brownGrey
	},
	button: {
		cursor: 'pointer'
	},
	quantity: {
		color: '#080808',
		fontSize: 14,
		borderRight: `1px solid ${colors.grey.border}`,
		borderLeft: `1px solid ${colors.grey.border}`
	},
	separator: {
		width: 1,
		height: 42,
		backgroundColor: colors.grey.border
	}
};
