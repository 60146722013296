// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".image-module_image-D3cKk.image-module_canBeClicked-opH9Q{cursor:pointer}.image-module_image-D3cKk.image-module_isCover-LKmvC,.image-module_image-D3cKk.image-module_isContain-ee8l7{position:absolute;top:0;left:0;right:0;width:100%;height:100%;display:flex;align-items:center}.image-module_image-D3cKk.image-module_isCover-LKmvC{object-fit:cover}.image-module_image-D3cKk.image-module_isCover-LKmvC img{object-fit:cover}.image-module_image-D3cKk.image-module_isContain-ee8l7 img{object-fit:contain}.image-module_picture-L0zsK{width:100%;height:100%;background-repeat:no-repeat;background-size:cover;background-position:50% 50%;transition-property:transform;float:left;position:relative}.image-module_picture-L0zsK.image-module_canBeClicked-opH9Q{cursor:pointer}", "",{"version":3,"sources":["webpack://./../../packages/ui/uikit/images/image/image.module.scss"],"names":[],"mappings":"AAGC,0DACC,cAAA,CAGD,4GAEC,iBAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CAGD,qDACC,gBAAA,CAEA,yDACC,gBAAA,CAIF,2DACC,kBAAA,CAIF,4BACC,UAAA,CACA,WAAA,CACA,2BAAA,CACA,qBAAA,CACA,2BAAA,CACA,6BAAA,CACA,UAAA,CACA,iBAAA,CAEA,4DACC,cAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.image {\n\t&.canBeClicked {\n\t\tcursor: pointer;\n\t}\n\n\t&.isCover,\n\t&.isContain {\n\t\tposition: absolute;\n\t\ttop: 0;\n\t\tleft: 0;\n\t\tright: 0;\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t}\n\n\t&.isCover {\n\t\tobject-fit: cover;\n\n\t\timg {\n\t\t\tobject-fit: cover;\n\t\t}\n\t}\n\n\t&.isContain img {\n\t\tobject-fit: contain;\n\t}\n}\n\n.picture {\n\twidth: 100%;\n\theight: 100%;\n\tbackground-repeat: no-repeat;\n\tbackground-size: cover;\n\tbackground-position: 50% 50%;\n\ttransition-property: transform;\n\tfloat: left;\n\tposition: relative;\n\n\t&.canBeClicked {\n\t\tcursor: pointer;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"image": "image-module_image-D3cKk",
	"canBeClicked": "image-module_canBeClicked-opH9Q",
	"isCover": "image-module_isCover-LKmvC",
	"isContain": "image-module_isContain-ee8l7",
	"picture": "image-module_picture-L0zsK"
};
module.exports = ___CSS_LOADER_EXPORT___;
