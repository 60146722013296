import React, { Component, createRef } from 'react';
import {
	formatPhoneNumber,
	invokeLambda,
	isValidEmail
} from '@planity/helpers';
import { masterShard } from '@planity/datastores/firebase';
import { firebase } from '@planity/datastores';
import { ErrorMessage } from '../error_message';
import ReauthModal from './reauth_modal';
import { EditProfileModal } from '../my_account_page/edit_profile_modal';
import {
	EMAIL_IN_USE_ERROR,
	INVALID_EMAIL_ERROR,
	INVALID_PHONE_NUMBER_ERROR,
	MISSING_EMAIL_ERROR,
	MISSING_FIRST_NAME_ERROR,
	MISSING_LAST_NAME_ERROR,
	MISSING_PHONE_NUMBER_ERROR,
	NETWORK_ERROR,
	PhoneInput,
	signOut
} from '@planity/components';
import { withModal } from '@planity/ui/context';
import { Input, Button } from '@planity/ui';
import { withLocalization, withTranslation } from '@planity/localization';
import styles from './index.module.scss';
import withStyles from 'isomorphic-style-loader/withStyles';
import { sendPhoneNumberVerificationCodeRecaptchaProtected } from '@planity/helpers/recaptcha';

const WHITELISTED_ERRORS = [
	MISSING_FIRST_NAME_ERROR,
	MISSING_LAST_NAME_ERROR,
	MISSING_EMAIL_ERROR,
	INVALID_EMAIL_ERROR,
	EMAIL_IN_USE_ERROR,
	MISSING_PHONE_NUMBER_ERROR,
	INVALID_PHONE_NUMBER_ERROR,
	NETWORK_ERROR
].map(e => `userProfileForm.errors.${e}`);

export default withLocalization(
	withTranslation()(
		withStyles(styles)(
			withModal(
				class UserProfileForm extends Component {
					state = {
						...this.initialProfile(),
						hasPhoneError: false,
						error: null,
						profileUpdateIsPending: false,
						showEditProfileModal: false,
						onSuccessEditProfileModal: () => {},
						editProfileModalMessage: '',
						openModal: false,
						profileHasBeenUpdated: false,
						errors: []
					};

					phoneInput = createRef();

					static getDerivedStateFromProps(props) {
						if (props.requestPhoneError) {
							return {
								errors: [
									{
										name: 'phoneNumber'
									}
								]
							};
						}
						return null;
					}

					render() {
						const { onCancel, onBooking, t, isVerified, requestPhoneError } =
							this.props;
						const {
							firstName,
							lastName,
							phoneNumber,
							email,
							error,
							profileUpdateIsPending,
							showEditProfileModal,
							editProfileModalMessage,
							onSuccessEditProfileModal,
							profileHasBeenUpdated,
							errors
						} = this.state;

						const initialProfile = this.initialProfile();
						const isEditing = Object.entries(initialProfile).some(
							([key, initialProfileValue]) =>
								// initialProfileValue will always be trimmed
								initialProfileValue !== this.state[key].trim()
						);
						const localizedSignUpText = {
							labels: {
								phoneNumber: t('userProfileForm.placeholders.phoneNumber')
							},
							placeholders: {
								phoneNumber: t(`auth.form.placeholders.phone`)
							}
						};
						return (
							<div className={styles.userInfo}>
								<EditProfileModal
									dismiss={this.dismissEditProfileModale}
									message={editProfileModalMessage}
									showEditProfileModal={showEditProfileModal}
									onSuccess={onSuccessEditProfileModal}
								/>
								<div>
									<form noValidate onSubmit={this.onSubmit}>
										<ErrorMessage
											defaultMessage={'userProfileForm.errors.defaultError'}
											error={error && `userProfileForm.errors.${error}`}
											whitelist={WHITELISTED_ERRORS}
										/>
										<ErrorMessage
											error={
												profileUpdateIsPending
													? false
													: profileHasBeenUpdated &&
													  isVerified &&
													  `userProfileForm.errors.updateUserSuccess`
											}
											errorType={'success'}
											id={'profileFormUpdate'}
										/>
										<div className={styles.inputsContainer}>
											<Input
												id={'profile-firstname-input'}
												isDisabled={profileUpdateIsPending}
												isRequired={true}
												label={t('userProfileForm.placeholders.firstName')}
												placeholder={t(
													'userProfileForm.placeholders.firstName'
												)}
												value={firstName}
												onChange={this.setFirstName}
											/>
											<Input
												id={'profile-lastname-input'}
												isDisabled={profileUpdateIsPending}
												isRequired={true}
												label={t('userProfileForm.placeholders.lastName')}
												placeholder={t('userProfileForm.placeholders.lastName')}
												value={lastName}
												onChange={text => this.setLastName(text)}
											/>
										</div>
										<div
											className={`${styles.inputsContainer} ${styles.withMargin}`}
										>
											<Input
												id={'profile-email-input'}
												isDisabled={profileUpdateIsPending}
												isRequired={true}
												label={t('userProfileForm.placeholders.email')}
												placeholder={t('userProfileForm.placeholders.email')}
												type='email'
												value={email}
												onChange={text => this.setEmail(text)}
											/>
											<PhoneInput
												data={{ errors }}
												defaultCountry={this.props.countryCode}
												forwardedRef={this.phoneInput}
												id='profile-phone-input'
												isDisabled={profileUpdateIsPending}
												localizedText={localizedSignUpText}
												placeholder={t(
													'userProfileForm.placeholders.phoneNumber'
												)}
												value={phoneNumber}
												onChange={this.setPhoneNumber}
											/>
										</div>
										<div className={styles.buttons}>
											<div className={styles.actions}>
												<Button
													id={'profile-cancel-button'}
													isDisabled={
														onBooking
															? profileUpdateIsPending || requestPhoneError
															: !!onCancel ||
															  !isEditing ||
															  profileUpdateIsPending
													}
													isFullMobile
													label={t('actions.cancel')}
													type={'secondary'}
													onClick={() => {
														this.setState({
															...this.initialProfile()
														});
														if (onCancel) onCancel();
													}}
												/>
												<Button
													id={'profile-update-button'}
													isDisabled={!isEditing || profileUpdateIsPending}
													isFullMobile
													isLoading={profileUpdateIsPending}
													label={t('actions.submit')}
													loadingLabel={t('actions.submitting')}
													type={'primary'}
												/>
											</div>
											<Button
												className={`planity_appointment_user_alert ${
													!onBooking && styles.signOut
												}`}
												id={'other-account-button'}
												isFullMobile
												label={t('bookAppointment.signOut')}
												normalWhiteSpace
												type={'tertiary'}
												onClick={async () => {
													await signOut();
													this.props.onSignOut && this.props.onSignOut();
												}}
											/>
										</div>
									</form>
								</div>
							</div>
						);
					}

					initialProfile() {
						const { user } = this.props;
						return {
							firstName: user.firstName || '',
							lastName: user.lastName || '',
							phoneNumber: user.phone || '',
							email: user.email || ''
						};
					}

					setFirstName = firstName => {
						this.setState(({ error }) => ({
							firstName,
							error:
								firstName && error === MISSING_FIRST_NAME_ERROR ? null : error
						}));
					};
					setLastName = lastName => {
						this.setState(({ error }) => ({
							lastName,
							error:
								lastName && error === MISSING_LAST_NAME_ERROR ? null : error
						}));
					};
					setEmail = email => {
						this.setState(({ error, email: emailWas }) => ({
							email,
							error:
								email && error === MISSING_EMAIL_ERROR
									? null
									: isValidEmail(email, false) && error === INVALID_EMAIL_ERROR
									? null
									: email !== emailWas && error === EMAIL_IN_USE_ERROR
									? null
									: error
						}));
					};
					setPhoneNumber = (phoneNumber, isValid) => {
						this.setState(({ error }) => ({
							phoneNumber,
							hasPhoneError: !isValid,
							error:
								(phoneNumber || !isValid) &&
								error === MISSING_PHONE_NUMBER_ERROR
									? null
									: isValid && error === INVALID_PHONE_NUMBER_ERROR
									? null
									: error
						}));
					};
					onSubmit = e => {
						if (e && e.preventDefault) e.preventDefault();
						const { onSuccess, toggle, toggleIcon, setModal, closeModal } =
							this.props;
						const {
							firstName,
							lastName,
							phoneNumber,
							email,
							hasPhoneError,
							error,
							profileUpdateIsPending
						} = this.state;
						if (
							!profileUpdateIsPending &&
							(!error || error === NETWORK_ERROR)
						) {
							const {
								firstName: firstNameWas,
								lastName: lastNameWas,
								phoneNumber: phoneNumberWas,
								email: emailWas
							} = this.initialProfile();
							const isPristine =
								firstName === firstNameWas &&
								lastName === lastNameWas &&
								phoneNumber === phoneNumberWas &&
								email === emailWas;
							if (isPristine) {
								if (onSuccess) {
									onSuccess();
								}
							} else {
								const nextError = this.nextError({
									firstName,
									lastName,
									phoneNumber,
									email,
									hasPhoneError
								});
								if (nextError) {
									this.setState({
										error: nextError
									});
								} else {
									// The condition "firebase.auth().currentUser.email" was set here
									// because I havent time to merge accounts and stuff and so on.
									if (
										email !== emailWas &&
										!!firebase.auth().currentUser.email
									) {
										let editProfileModalMessage;
										if (
											firstName !== firstNameWas ||
											lastName !== lastNameWas ||
											phoneNumber !== phoneNumberWas
										) {
											editProfileModalMessage =
												'myAccount.editModalMessageEmailAndUserInfoChange';
										} else {
											editProfileModalMessage =
												'myAccount.editModalMessageEmailChange';
										}
										this.setState({
											onSuccessEditProfileModal: () => {
												this.setState({
													showEditProfileModal: false,
													editProfileModalMessage: ''
												});
												setModal({
													content: (
														<ReauthModal
															dismiss={closeModal}
															toggle={toggle}
															toggleIcon={toggleIcon}
															onSuccess={this.onReauth}
														/>
													),
													hasCloseBtn: true
												});
											},
											showEditProfileModal: true,
											editProfileModalMessage
										});
									} else {
										this.setState({
											onSuccessEditProfileModal: this.updateProfile,
											showEditProfileModal: true,
											editProfileModalMessage:
												'myAccount.editModalMessageUserInfoChange'
										});
									}
								}
							}
						}
					};
					onReauth = () => {
						this.setState(
							{
								profileUpdateIsPending: true,
								showEditProfileModal: false,
								editProfileModalMessage: ''
							},
							() => {
								const { email } = this.state;
								masterShard
									.auth()
									.currentUser.updateEmail(email)
									.then(() => this.updateProfile())
									.catch(error => {
										this.setState({
											profileUpdateIsPending: false,
											error: error.code || error
										});
									});
							}
						);
						this.props.closeModal();
					};
					updateProfile = () => {
						this.setState(
							{
								profileUpdateIsPending: true,
								showEditProfileModal: false,
								profileHasBeenUpdated: false,
								editProfileModalMessage: ''
							},
							() => {
								const { userId, isPro, onSuccess, locale } = this.props;
								const { firstName, lastName, phoneNumber, email } = this.state;
								const { phoneNumber: phoneNumberWas } = this.initialProfile();
								this.handlePhoneVerification({
									phoneNumber,
									phoneNumberWas,
									userId,
									isPro,
									locale
								})
									.then(() => {
										const profile = {
											firstName: firstName.trim(),
											lastName: lastName.trim(),
											name: [firstName, lastName].map(x => x.trim()).join(' '),
											email: email.replace(/\s/g, '').toLowerCase(),
											phone: phoneNumber,
											phoneFormatted: formatPhoneNumber(
												phoneNumber,
												'International'
											)
										};
										masterShard
											.auth()
											.currentUser.getIdToken()
											.then(userToken => {
												return invokeLambda('updateUserProfile', {
													userId,
													userToken,
													profile
												});
											})
											.then(() => {
												if (onSuccess) onSuccess();
												this.setState({
													profileUpdateIsPending: false,
													profileHasBeenUpdated: true
												});
											})
											.catch(error => {
												this.setState({
													profileUpdateIsPending: false,
													error: error.code || error,
													profileHasBeenUpdated: false
												});
											});
									})
									.catch(error => {
										this.setState({
											profileUpdateIsPending: false,
											error: error.code || error,
											profileHasBeenUpdated: false
										});
									});
							}
						);
					};

					handlePhoneVerification({
						phoneNumber,
						phoneNumberWas,
						userId,
						isPro,
						locale
					}) {
						if (phoneNumber !== phoneNumberWas && !isPro) {
							return masterShard
								.database()
								.ref(`verified_users/${userId}/${phoneNumber}`)
								.once('value')
								.then(snapshot => {
									const isVerified = !!snapshot.val();
									if (!isVerified) {
										sendPhoneNumberVerificationCodeRecaptchaProtected({
											phoneNumber,
											locale
										});
									}
								});
						} else {
							return Promise.resolve();
						}
					}

					nextError({
						firstName,
						lastName,
						phoneNumber,
						email,
						hasPhoneError
					}) {
						if (firstName) {
							if (lastName) {
								if (email) {
									if (isValidEmail(email)) {
										if (hasPhoneError) {
											return INVALID_PHONE_NUMBER_ERROR;
										} else {
											return phoneNumber ? null : MISSING_PHONE_NUMBER_ERROR;
										}
									} else {
										return INVALID_EMAIL_ERROR;
									}
								} else {
									return MISSING_EMAIL_ERROR;
								}
							} else {
								return MISSING_LAST_NAME_ERROR;
							}
						} else {
							return MISSING_FIRST_NAME_ERROR;
						}
					}

					dismissEditProfileModale = () => {
						const { onCancel } = this.props;
						this.setState({
							showEditProfileModal: false,
							...this.initialProfile()
						});
						if (onCancel && typeof onCancel === 'function') onCancel();
					};
				}
			)
		)
	)
);
