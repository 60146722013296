import { isBefore, parseISO } from 'date-fns';

export const checkHasAvailabilitiesBeforeAppointment = (
	availabilities,
	appointmentStart
) => {
	if (!availabilities || !appointmentStart) return false;
	return Object.keys(availabilities || {}).some(day => {
		return availabilities[day].some(hour => {
			const date = parseISO(`${day} ${hour.hour}`);
			return isBefore(date, new Date(appointmentStart));
		});
	});
};
