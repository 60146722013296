import React from 'react';

export function Service() {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M8.33325 8.00002C9.17616 7.0318 9.99992 5.16669 9.99992 5.16669C9.99992 5.16669 10.8237 7.0318 11.6666 8.00002C12.4875 8.94302 14.1666 10 14.1666 10C14.1666 10 12.4875 11.057 11.6666 12C10.8237 12.9682 9.99992 14.8334 9.99992 14.8334C9.99992 14.8334 9.17616 12.9682 8.33325 12C7.5123 11.057 5.83325 10 5.83325 10C5.83325 10 7.5123 8.94302 8.33325 8.00002Z'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
