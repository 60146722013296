// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".review_block-module_footer-dJ1XZ{display:flex;justify-content:center;align-items:center}@media(min-width: 768px){.review_block-module_footer-dJ1XZ{border-top:none}}.review_block-module_footer-dJ1XZ .review_block-module_footerLink-pqMP6{display:flex;justify-content:center;align-items:center;font-size:var(--body-4);cursor:pointer;white-space:nowrap;height:24px}@media(min-width: 768px){.review_block-module_footer-dJ1XZ .review_block-module_footerLink-pqMP6{margin-bottom:0}}@media(max-width: 767px){.review_block-module_footer-dJ1XZ .review_block-module_footerLink-pqMP6{width:100%}}", "",{"version":3,"sources":["webpack://./../../packages/components/my_account_page/appointment_preview/review/review_block.module.scss"],"names":[],"mappings":"AAEA,kCACC,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,yBALD,kCAME,eAAA,CAAA,CAGD,wEACC,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,uBAAA,CACA,cAAA,CACA,kBAAA,CACA,WAAA,CAEA,yBATD,wEAUE,eAAA,CAAA,CAGD,yBAbD,wEAcE,UAAA,CAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.footer {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\n\t@media (min-width: styles.$tablet) {\n\t\tborder-top: none;\n\t}\n\n\t.footerLink {\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tfont-size: var(--body-4);\n\t\tcursor: pointer;\n\t\twhite-space: nowrap;\n\t\theight: 24px;\n\n\t\t@media (min-width: styles.$tablet) {\n\t\t\tmargin-bottom: 0;\n\t\t}\n\n\t\t@media (max-width: #{styles.$tablet - 1px}) {\n\t\t\twidth: 100%;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"footer": "review_block-module_footer-dJ1XZ",
	"footerLink": "review_block-module_footerLink-pqMP6"
};
module.exports = ___CSS_LOADER_EXPORT___;
