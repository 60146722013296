// represent focus pages regarding the position of current page
const RIGHT_SPAN = 2; // [13], 14, 15
const LEFT_SPAN = 2; // 11, 12, [13]

export function buildCompactPages(currentPage, nbPages) {
	let pages = [];

	// add the current page to list
	pages.push({
		page: currentPage
	});

	// add pages we want to display in the left of currentPage
	let leftRightSpan = 0;
	for (let i = 1; i <= RIGHT_SPAN; i += 1) {
		if (currentPage + i <= nbPages) {
			pages.push({ page: currentPage + i });
		} else {
			leftRightSpan += 1;
		}
	}

	// add pages we want to display in the right of currentPage
	let leftLeftSpan = 0;
	for (let i = 1; i <= LEFT_SPAN; i += 1) {
		if (currentPage - i > 0) {
			pages.unshift({ page: currentPage - i });
		} else {
			leftLeftSpan += 1;
		}
	}

	// maintain focus area length by adding what is left in left-span to the right - keep always the length of focus area
	// ex: current page is 2 out of 50, add 5
	if (leftRightSpan && !leftLeftSpan) {
		const leftestPage = pages[0].page;
		for (let i = 1; i <= leftRightSpan; i += 1) {
			if (leftestPage - i > 0) {
				pages.unshift({ page: leftestPage - i });
			}
		}
	}

	// maintain focus area length by adding what is left in right-span to the left - keep always the length of focus area
	// ex: current page is 49 out of 50, add 46
	if (!leftRightSpan && leftLeftSpan) {
		const rightestPage = pages[pages.length - 1].page;
		for (let i = 1; i <= leftLeftSpan; i += 1) {
			if (rightestPage + i <= nbPages) {
				pages.push({ page: rightestPage + i });
			}
		}
	}

	// add the other pages that represent the tens cuts
	const nbRanges = Math.ceil(nbPages / 10);
	const leftRanges = [];
	const rightRanges = [];
	for (let i = 0; i < nbRanges; i += 1) {
		const maxRange = (i + 1) * 10;
		const maxValue = maxRange > nbPages ? nbPages : maxRange;
		const idx = pages.findIndex(({ page }) => page === maxValue);
		if (idx !== -1) {
			pages[idx].isRange = true;
		} else {
			if (maxValue > currentPage) {
				rightRanges.push({ page: maxValue, isRange: true });
			} else {
				leftRanges.push({ page: maxValue, isRange: true });
			}
		}
	}

	pages = [...leftRanges, ...pages, ...rightRanges];

	//  Add manually 1st page as range if needed
	if (currentPage >= 4 && !pages.find(({ page }) => page === 1)) {
		pages.unshift({
			page: 1,
			isRange: true
		});
	}

	// put separators in place
	const pagesWithSeparator = [];
	for (let i = 0; i < pages.length; i += 1) {
		const current = pages[i];
		const next = pages[i + 1];
		// add separators based on difference of the position of the page && if it is a range or not
		const diff = next ? next.page - current.page : 0;
		pagesWithSeparator.push(current);
		if (diff > 1 && !(current.isRange && next.isRange)) {
			pagesWithSeparator.push({
				isSeparator: true
			});
		}
	}
	return pagesWithSeparator;
}
