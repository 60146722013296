import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './inline_link.module.scss';

export function InlineLink({ className, onClick, to, label = '', target, id }) {
	useStyles(styles);
	const classes = classNames.bind(styles)(className, {
		inlineLink: true
	});

	if (to) {
		return (
			<a
				id={id}
				href={to}
				target={target}
				className={classes}
				onClick={onClick}
			>
				{label}
			</a>
		);
	} else {
		return (
			<button id={id} className={classes} onClick={onClick}>
				{label}
			</button>
		);
	}
}
