export const sendToUserApp = ({ type, payload }) => {
	if (!process.env.WEBVIEW) return;

	const msg = JSON.stringify({ type, payload });
	console.info(
		`%c${type}`,
		'background:MediumPurple;color:white;font-weight:bold;',
		payload
	);
	window?.ReactNativeWebView?.postMessage(msg);
};

export const isNativeApp = !!process.env.WEBVIEW;

/**
 * Parse message from user-app and decode it
 * @param message { String } the message from user-app, as a stringified object
 * @return { Object|null } the decoded message or null if not from user-app
 */
export const decodeMessageFromUserApp = message => {
	try {
		const data = JSON.parse(message);
		if (data?.origin !== 'USER_APP') return null;

		return { type: data.type, params: data.params };
	} catch (e) {
		return null;
	}
};
