import React from 'react';

export function Facebook() {
	return (
		<svg
			width='32'
			height='32'
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17.3417 25V16.7894H19.9953L20.3925 13.5895H17.3417V11.5466C17.3417 10.6201 17.5894 9.9888 18.8685 9.9888L20.5 9.988V7.12607C20.2178 7.08715 19.2494 7 18.1227 7C15.7705 7 14.1603 8.49118 14.1603 11.2297V13.5895H11.5V16.7894H14.1603V25H17.3417Z'
				fill='#202020'
			/>
		</svg>
	);
}
