import classNames from 'classnames/bind';
import styles from './business_info.module.scss';
import { Icon } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';

export function BusinessInfo({
	className = undefined,
	title = undefined,
	address = undefined,
	note = undefined,
	distance = undefined,
	price = undefined,
	type = undefined,
	count = undefined,
	average = undefined,
	isSearch = false
}) {
	useStyles(styles);

	const classes = classNames.bind(styles)({
		businessInfo: true,
		[className]: className !== undefined,
		isSearch
	});
	const { t } = useTranslation();
	return (
		<div className={classes}>
			{title && <h1 className={styles.title}>{title}</h1>}

			<div className={styles.infos}>
				{address && (
					<span className={styles.info}>
						<Icon className={styles.icon} icon='Pin' size='small' />
						<span className={styles.address}>
							{address}
							{distance && (
								<span>
									{t('punctuation.inParenthesis', { value: distance })}
								</span>
							)}
						</span>
					</span>
				)}
				{!!note && (
					<span className={styles.info}>
						<Icon className={styles.icon} icon='Star' outlined size='small' />

						<span>
							{`${note} ${
								count ? `(${t(`search.reviewsNumber`, { number: count })})` : ''
							}`}
						</span>
					</span>
				)}
				{!!price && (
					<span className={styles.info}>
						<Icon className={styles.icon} icon='Star' size='small' />
						<span>{price}</span>
					</span>
				)}
				{!!average && <div className={styles.info}>{average}</div>}
			</div>
		</div>
	);
}
