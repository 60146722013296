import { useEffect, useState } from 'react';

export const useKeyPress = (isDisabled, targetKey) => {
	const [keyPressed, setKeyPressed] = useState(false);

	useEffect(() => {
		if (!process.env.BROWSER || isDisabled) return;
		const downHandler = e => {
			if (e.key === targetKey) {
				e.preventDefault();
				setKeyPressed(true);
			}
		};

		const upHandler = ({ key }) => {
			if (key === targetKey) {
				setKeyPressed(false);
			}
		};

		window.addEventListener('keydown', downHandler);
		window.addEventListener('keyup', upHandler);

		return () => {
			window.removeEventListener('keydown', downHandler);
			window.removeEventListener('keyup', upHandler);
		};
	}, [targetKey, isDisabled]);

	return keyPressed;
};
