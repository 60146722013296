import { useViewerHeaders } from '@planity/components';
import { noop } from '@planity/helpers';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import french from 'date-fns/locale/fr';
import german from 'date-fns/locale/de';
import dutch from 'date-fns/locale/nl';

export const DEFAULT_LOCALE = 'fr-FR';
export const DEFAULT_LANGUAGE = 'fr';
export const DEFAULT_COUNTRY_CODE = 'FR';

export const AVAILABLE_LOCALES = ['fr-FR', 'de-DE', 'fr-BE', 'nl-BE'];
export const AVAILABLE_LANGUAGES = process.env.DEV_CONSOLE
	? ['fr', 'en']
	: ['fr', 'de', 'nl'];

export const AVAILABLE_COUNTRY_CODES = ['FR', 'BE', 'DE'];

const LocalizationContext = createContext({
	language: DEFAULT_LOCALE
});

export const useLocalization = () => useContext(LocalizationContext);

/**
 * Get filters for Algolia requests on businesses
 * @see  https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements
 * @param countryCode {String}
 * @returns {string[]} an array of searchable country codes
 */
function getAlgoliaCountries(countryCode) {
	switch (countryCode) {
		case 'DE':
			return ['DE'];
		case 'BE':
			return ['BE'];
		default:
		case 'FR':
		case 'MC':
		case 'RE':
		case 'LU':
		case 'AD':
		case 'CH':
		case 'GP':
		case 'MQ':
		case 'GF':
		case 'PM':
		case 'YT':
		case 'NC':
		case 'PF':
		case 'MF':
		case 'TF':
		case 'WF':
		case 'BL':
		case 'IL':
			return [
				'FR',
				'MC',
				'RE',
				'LU',
				'AD',
				'CH',
				'GP',
				'MQ',
				'GF',
				'PM',
				'YT',
				'NC',
				'PF',
				'MF',
				'TF',
				'WF',
				'BL',
				'PT',
				'IL'
			];
	}
}

/**
 * Get filters for Google Maps requests. Limited to 5, be careful !
 * @see  https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements
 * @param countryCode {String} Can be a viewer country, which is a country code, based on user
 *   location, or a regular countryCode
 * @returns {string[]} an array of searchable country codes (max length is 5)
 */
function getGoogleSearchableCountries(countryCode) {
	switch (countryCode) {
		// France Métropolitaine
		default:
		case 'FR':
			return ['FR', 'LU', 'MC', 'CH'];
		// Monaco
		case 'MC':
			return ['MC', 'FR'];
		// Réunion
		case 'RE':
			return ['RE'];
		// Luxembourg
		case 'LU':
			return ['LU', 'FR', 'CH'];
		// Belgique
		case 'BE':
			return ['BE'];
		// Andorre
		case 'AD':
			return ['AD', 'FR'];
		// Suisse
		case 'CH':
			return ['CH', 'FR', 'DE'];
		// Guadeloupe
		case 'GP':
			return ['GP'];
		// Martinique
		case 'MQ':
			return ['MQ'];
		// Guyane Française
		case 'GF':
			return ['GF'];
		// St. Pierre & Miquelon
		case 'PM':
			return ['PM'];
		// Mayotte
		case 'YT':
			return ['YT'];
		// Nouvelle-Calédonie
		case 'NC':
			return ['NC'];
		// Polynésie Française
		case 'PF':
			return ['PF'];
		// St. Martin
		case 'MF':
			return ['MF'];
		// Terres australes et antarctiques françaises (important ça !)
		case 'TF':
			return ['TF'];
		// Wallis-et-Futuna
		case 'WF':
			return ['WF'];
		// Saint Barthélémy
		case 'BL':
			return ['BL'];
		// Maroc
		case 'MA':
			return ['MA'];
		// DEUTSCHLAND FRAULEIN !
		case 'DE':
			return ['DE'];
		// ISRAEL !
		case 'IL':
			return ['IL'];
	}
}

function getDateLocale(language) {
	if (typeof language !== 'string') return french;
	switch (language.toLowerCase()) {
		default:
		case 'fr':
			return french;
		case 'de':
			return german;
		case 'nl':
			return dutch;
	}
}

/**
 * Used to recognize links like "coiffeur/france" or "friseur/deutschland"
 * Depends on locale because some countries speak many language (hello belgium).
 * Don't forget to also edit the `isWholeCountry` function in
 * `apps/website/app/components/catch_all_page.js`
 * @param locale {string}
 * @returns {string}
 */
function getWholeCountrySlug(locale) {
	switch (locale) {
		default:
		case 'fr-FR':
			return 'france';
		case 'de-DE':
			return 'deutschland';
		case 'fr-BE':
			return 'belgique';
		case 'nl-BE':
			return 'belgie';
	}
}

/**
 * Only used in webview, to display the current website version
 * @param locale {string} website's locale
 * @return {string} a string that represent the website's locale, in a user-friendly way
 */
function getLanguageName(locale) {
	switch (locale) {
		case 'de-DE':
			return 'DE';
		case 'fr-BE':
			return 'BE (FR)';
		case 'nl-BE':
			return 'BE (NL)';
		default:
		case 'fr-FR':
			return 'FR';
	}
}

const useInitialLocale = initialLocale => {
	const location = useLocation();
	if (initialLocale)
		return AVAILABLE_LOCALES.includes(initialLocale)
			? initialLocale
			: DEFAULT_LOCALE;

	const localeMatcher = new RegExp(/^\/?([a-z]{2})-([A-Z]{2})(\/|$)/);

	if (location && location.pathname?.match(localeMatcher)) {
		const [, language, countryCode] = location.pathname.match(localeMatcher);
		const locale = `${language}-${countryCode}`;
		return AVAILABLE_LOCALES.includes(locale) ? locale : DEFAULT_LOCALE;
	}

	return DEFAULT_LOCALE;
};

export const LocalizationContextProvider = ({ children, initialLocale }) => {
	const { i18n } = useTranslation();
	const { viewerCountry, viewerHeadersHaveLoaded } = useViewerHeaders();

	const locale = useInitialLocale(initialLocale);

	const [language, countryCode] = locale?.replace('/', '')?.split('-') || [];

	useEffect(() => {
		if (language) i18n.changeLanguage(language).then(noop);
	}, [language]);

	const localizedBasePath = locale && locale !== 'fr-FR' ? `/${locale}` : '';
	const wholeCountrySlug = useMemo(() => getWholeCountrySlug(locale), [locale]);
	const languageName = useMemo(() => getLanguageName(locale), [locale]);

	const algoliaSearchableCountries = useMemo(
		() => getAlgoliaCountries(countryCode),
		[countryCode]
	);
	const googleSearchableCountries = useMemo(() => {
		if (['DE', 'BE'].includes(countryCode))
			return getGoogleSearchableCountries(countryCode);

		// Being intentionally on the "wrong" version of the website
		// (eg: being in Germany, but wishing to stay in French website)
		if (['DE', 'BE'].includes(viewerCountry))
			return getGoogleSearchableCountries(DEFAULT_COUNTRY_CODE);

		return getGoogleSearchableCountries(viewerCountry);
	}, [viewerCountry, countryCode]);

	useEffect(() => {
		if (viewerHeadersHaveLoaded) {
			const colors = generateColors();
			console.log('🌏✈️💻');
			console.log(
				`Website version : %c${locale}`,
				`color:${colors.next().value};font-weight:bold`
			);
			console.log(
				`Businesses searched in : %c${algoliaSearchableCountries.join(', %c')}`,
				...algoliaSearchableCountries.map(
					() => `color:${colors.next().value};font-weight:bold`
				)
			);
			console.log(
				`Places searched in : %c${googleSearchableCountries.join(', %c')}`,
				...googleSearchableCountries.map(
					() => `color:${colors.next().value};font-weight:bold`
				)
			);
		}
	}, [locale, googleSearchableCountries, algoliaSearchableCountries]);
	return (
		<LocalizationContext.Provider
			value={{
				locale,
				language,
				countries: algoliaSearchableCountries,
				googleSearchableCountries,
				countryCode,
				dateLocale: getDateLocale(language),
				localizedBasePath,
				wholeCountrySlug,
				viewerCountry,
				languageName
			}}
		>
			{children}
		</LocalizationContext.Provider>
	);
};

/**
 * WARNING : This method returns `fr-FR` as a valid basePath. It is not ! Even if it is correctly handled by the
 * server, it should not have happened. Unfortunately, some features already relies on this behaviour...
 * @param {string} language a language {"fr"|"de"|"nl"}
 * @param {string} countryCode a country code (FR, MC, LU, DE, BE....)
 * @return {string} {"fr-BE"|"fr-FR"|"de-DE"|"nl-BE"}
 */
export const getLocalizedBasePath = ({ language, countryCode }) => {
	switch (language) {
		case 'fr':
			if (countryCode && ['BE'].includes(countryCode)) return 'fr-BE';
			// This includes every countryCode (like EVERY one, not only french ones)
			return 'fr-FR';
		case 'de':
			console.log('Returning de-DE because language is ' + language);
			return 'de-DE';
		case 'nl':
			console.log('Returning nl-BE because language is ' + language);
			return 'nl-BE';
		default:
			console.log(
				'No localizedBasePath because language is ' + language + ' return fr-FR'
			);
			return 'fr-FR';
	}
};

/**
 * Given a business, returns on the countryCode to which it is attached.
 * @param business {Object} a business
 * @return {string} a "website" countryCode, eg 'FR', 'BE', 'DE'
 */
export const getBusinessWebsiteCountryCode = business => {
	switch (business?.countryCode) {
		case 'DE':
			return 'DE';
		case 'BE':
			return 'BE';
		// Default case includes FR but also every DROM-COM, Monaco, Luxembourg... And every other country
		default:
			return DEFAULT_COUNTRY_CODE;
	}
};

export const WithLocalization = LocalizationContext.Consumer;
export const withLocalization = WrappedComponent => props =>
	(
		<WithLocalization>
			{localizationProps => (
				<WrappedComponent {...localizationProps} {...props} />
			)}
		</WithLocalization>
	);

function* generateColors() {
	// prettier-ignore
	const NICE_COLORS = [
		'#F6AD55', '#ED8936', '#DD6B20', '#C05621', '#9C4221',
		'#F6E05E', '#ECC94B', '#D69E2E', '#B7791F', '#975A16',
		'#B794F4', '#9F7AEA', '#805AD5', '#6B46C1', '#553C9A',
		'#63B3ED', '#4299E1', '#3182CE', '#2B6CB0', '#2C5282',
		'#F687B3', '#ED64A6', '#D53F8C', '#B83280', '#97266D',
		'#FC8181', '#F56565', '#E53E3E', '#C53030', '#9B2C2C',
		'#4FD1C5', '#38B2AC', '#319795', '#2C7A7B', '#285E61',
		'#68D391', '#48BB78', '#38A169', '#2F855A', '#276749'
	];

	let currentIndex = Math.floor(Math.random() * NICE_COLORS.length);
	while (true) {
		yield NICE_COLORS[currentIndex];
		currentIndex = (currentIndex + 1) % NICE_COLORS.length;
	}
}
