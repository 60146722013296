import { EMAIL_CHANGE, useFormHooks } from '@planity/components';
import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Input, Button, InlineLink } from '@planity/ui';
import classNames from 'classnames/bind';
import styles from '../authentication/authentication.module.scss';
import { MemberConnection } from '@planity/ui/components';
import { checkInputError } from '@planity/components';
import { useTranslation } from '@planity/localization';
export function ForgetPassword({
	navigateLogState,
	data,
	localizedText,
	pending,
	handleDispatchEvent,
	handleDispatchInputs
}) {
	const classes = classNames.bind(styles)({
		auth: true
	});
	useStyles(styles);
	const { formValues } = useFormHooks();
	const { authBooking } = formValues;
	const { t } = useTranslation();
	return (
		<div id='forget-password-comp' className={classes}>
			{!authBooking && <h1 className={styles.title}>{localizedText.title}</h1>}

			<form className={styles.form}>
				<div className={styles.fields}>
					<Input
						id={'forget-password-comp__input-email'}
						autoFocus={true}
						label={localizedText.labels.email}
						isRequired={true}
						name='email'
						value={data && data.email}
						placeholder={localizedText.placeholders.email}
						onChange={email =>
							handleDispatchInputs({ type: EMAIL_CHANGE, email })
						}
						error={checkInputError('email', t, data)?.format}
						errorMessage={checkInputError('email', t, data)?.message}
					/>
				</div>
				<Button
					className={`${styles.submit} ${authBooking && styles.forget}`}
					isFullMobile
					size='large'
					label={localizedText.labels.button}
					onClick={event =>
						handleDispatchEvent({ event: event, type: 'reset' })
					}
					isLoading={pending}
					isDisabled={pending}
					id={'forget-password-comp__submit'}
				/>
				<div className={styles.extra}>
					<InlineLink
						id={'forget-password-comp__inline-link'}
						label={localizedText.labels.login}
						onClick={() => navigateLogState('login')}
					/>
				</div>
			</form>

			<MemberConnection
				id={'forget-password-comp__memberBtn'}
				title={localizedText.memberConnection.title}
				label={localizedText.memberConnection.label}
				navigateLogState={() => navigateLogState('signup')} //authBooking &&
			/>
		</div>
	);
}
