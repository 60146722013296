// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".user_queries-module_userQueries-vu7bq{margin:24px 16px 0}.user_queries-module_businessPreviewTitle-iwwVX{font:var(--medium) var(--body-4);color:var(--grey-600);margin-bottom:4px}", "",{"version":3,"sources":["webpack://./app/components/search_business_page/user_queries.module.scss"],"names":[],"mappings":"AAEA,uCACC,kBAAA,CAGD,gDACC,gCAAA,CACA,qBAAA,CACA,iBAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.userQueries {\n\tmargin: 24px 16px 0;\n}\n\n.businessPreviewTitle {\n\tfont: var(--medium) var(--body-4);\n\tcolor: var(--grey-600);\n\tmargin-bottom: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"userQueries": "user_queries-module_userQueries-vu7bq",
	"businessPreviewTitle": "user_queries-module_businessPreviewTitle-iwwVX"
};
module.exports = ___CSS_LOADER_EXPORT___;
