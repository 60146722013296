import React from 'react';

export const CI = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5609)'>
				<mask
					id='mask0_11534_5609'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5609)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M13.75 0H20V15H13.75V0Z'
						fill='#67BD38'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0H6.25V15H0V0Z'
						fill='#E47E00'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M6.25 0H13.75V15H6.25V0Z'
						fill='#F7FCFF'
					/>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5609'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
