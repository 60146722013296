import { Redirect, useLocation } from 'react-router-dom';
import { localeFromURL, localizedBasePath } from '@planity/helpers';
import { UnknownURLPage } from '../unknown_url_page';
import { Spinner } from '@planity/ui';
import { useFirebaseSubscription } from '@planity/datastores';
import styles from './webview_redirections.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';

export const WebviewTinyUrlRedirection = ({ code }) => {
	const location = useLocation();
	useStyles(styles);

	// fr-FR ou de-DE ou fr-BE....
	const locale = localeFromURL(location.pathname);

	// "/" for frenchies, "/de-DE" for german, "/fr-BE" for belgians, etc
	const basePath = localizedBasePath(locale);

	const [slug, , isLoading] = useFirebaseSubscription({
		path: `tiny_urls/codes/${code}`
	});

	if (isLoading)
		return (
			<div className={styles.spinner}>
				<Spinner />
			</div>
		);
	if (slug) {
		return <Redirect to={`${basePath}/${slug}${location.search || ''}`} />;
	} else {
		return <UnknownURLPage />;
	}
};
