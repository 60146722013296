import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './phone.module.scss';
import { Icon, Flag, Drawer } from '@planity/ui';
import { useUikit } from '@planity/ui/shared/useUikit';
import { noop } from '@planity/helpers';

export function Phone({
	label,
	placeholder = '00 00 00 00 00',
	defaultValue,
	defaultCountry = 'FR',
	isDisabled = false,
	isRequired = false,
	errorMessage = 'Invalid field',
	error = false,
	name,
	onChange = noop,
	value,
	type = 'number',
	phoneInput,
	autoFocus = false
}) {
	useStyles(styles);
	const input = useRef();
	const button = useRef();

	// States

	const [isSelecting, setIsSelecting] = useState(false);
	const [isValid] = useState(false);
	const [country] = useState(defaultCountry);
	const { dispatchError, isFocused, hasError, setIsFocused } = useUikit();
	// Classes

	const classes = classNames.bind(styles)({
		phone: true
	});

	// useEffect
	useEffect(() => {
		dispatchError(error);
	}, [dispatchError, error]);

	// Events

	// document.addEventListener("click", (e)=>{
	//   if(e.target != button.current) setIsSelecting(false)
	// }, false);

	// Render

	const renderLabel = label && (
		<label
			id='label-input-phone-field'
			htmlFor={name}
			onClick={() => input?.current?.focus()}
			className={styles.label}
		>
			{label} {isRequired && '*'}
		</label>
	);

	const renderField = (
		<div
			id='input-phone-field'
			className={classNames.bind(styles)({
				container: true,
				isDisabled: isDisabled,
				isFocused: isFocused,
				isInvalid: hasError
			})}
		>
			<button
				id='button-phone'
				className={styles.country}
				tabIndex={isDisabled ? -1 : 0}
				ref={button}
				onClick={() => setIsSelecting(isDisabled ? false : !isSelecting)}
			>
				<Flag className={styles.flag} country={country} />
				<svg
					className={styles.chevron}
					width='6'
					height='4'
					viewBox='0 0 6 4'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M3 4L-8.26528e-07 5.24537e-07L6 0L3 4Z'
						fill='currentColor'
					/>
				</svg>
			</button>
			<input
				autoFocus={autoFocus}
				id='input-phone'
				type={type}
				ref={input}
				name={name}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
				disabled={isDisabled}
				required={isRequired}
				className={styles.field}
				placeholder={placeholder}
				defaultValue={defaultValue}
				onChange={onChange}
				value={value || ''}
			/>
			{isValid && (
				<Icon className={styles.icon} icon={'Close'} size={'medium'} />
			)}
		</div>
	);

	const renderPhoneInput = phoneInput;

	const renderError = !isDisabled &&
		hasError &&
		errorMessage && ( // !isFocused &&
			<span id='error-input-phone-field' className={styles.error}>
				{errorMessage}
			</span>
		);

	return (
		<div className={classes} id='input-phone-field-comp'>
			{renderLabel}
			{renderPhoneInput ? (
				renderPhoneInput
			) : (
				<div className={styles.rel} id='input-phone-field-drawer'>
					{renderField}
					<Drawer
						className={styles.drawer}
						isOpen={isSelecting}
						selected={country}
						items={[
							{ label: 'France', flag: 'FR', value: 'FR' },
							{ label: 'Allemagne', flag: 'FR', value: 'DE' }
						]}
					/>
				</div>
			)}
			{renderError}
		</div>
	);
}
