import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './reset_password.module.scss';
import { Button, InputPassword } from '@planity/ui';
import { useUikit } from '@planity/ui/shared/useUikit';

export function ResetPassword({
	error,
	password,
	localizedText,
	pending,
	handleDispatchEvent,
	handleDispatchInputs
}) {
	useStyles(styles);
	const { toggle, toggleIcon } = useUikit();
	const classes = classNames.bind(styles)({
		resetPassword: true
	});
	const errorPassword = error
		? error.toLowerCase().indexOf('password') > -1
		: false;

	const renderForm = (
		<form className={styles.container} onSubmit={e => e.preventDefault()}>
			<h1 className={styles.title}>{localizedText.title}</h1>
			<p className={styles.text}>{localizedText.alert}</p>
			<div className={styles.fields}>
				<InputPassword
					id={'reset-password-comp__input-password'}
					autoFocus
					label={localizedText.label}
					isRequired
					name='password'
					icon={toggle ? 'Eye' : 'EyeOff'}
					value={password || ''}
					placeholder={localizedText.placeholder}
					onChange={password => handleDispatchInputs(password)}
					onToggleIcon={toggleIcon}
					toggle={toggle}
					error={errorPassword && error}
					errorMessage={errorPassword && localizedText.errorMessage}
				/>
			</div>
			<div className={styles.actions}>
				<Button
					id={'reset-password-comp__submit'}
					isFullMobile
					label={localizedText.submit}
					onClick={event =>
						handleDispatchEvent({ event: event, type: 'resetPassword' })
					}
					isFullWidth
					isLoading={pending}
					isDisabled={pending}
				/>
			</div>
		</form>
	);

	return (
		<div id='reset-password-comp' className={classes}>
			{renderForm}
		</div>
	);
}
