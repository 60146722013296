import React, { Component, createContext } from 'react';

const SubscriptionsContext = createContext(null);

export class FirebaseSubscriptionsContext extends Component {
	register = query => {
		this.setState(({ queries }) => ({
			queries: [...queries, query]
		}));
	};
	release = query => {
		this.setState(({ queries }) => ({
			queries: queries.filter(q => q !== query)
		}));
	};
	state = {
		queries: [],
		register: this.register,
		release: this.release
	};
	render() {
		return (
			<SubscriptionsContext.Provider value={this.state}>
				{this.props.children}
			</SubscriptionsContext.Provider>
		);
	}
}

export const WithFirebaseSubscriptions = SubscriptionsContext.Consumer;
