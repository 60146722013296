// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".badge-module_badge-nF31U{display:inline-flex;padding:4px 8px;white-space:nowrap;background-color:var(--primary-100);border-radius:12px;font:var(--semibold) var(--label-1);color:var(--primary-200)}.badge-module_badge-nF31U .badge-module_iconLeft-gD\\+KH{margin-right:4px}.badge-module_badge-nF31U .badge-module_iconRight-VpFTY{margin-left:4px}.badge-module_badge-nF31U .badge-module_iconLeft-gD\\+KH,.badge-module_badge-nF31U .badge-module_iconRight-VpFTY{width:16px;height:16px}", "",{"version":3,"sources":["webpack://./../../packages/ui/uikit/indication/badge/badge.module.scss"],"names":[],"mappings":"AAAA,0BACC,mBAAA,CACA,eAAA,CACA,kBAAA,CACA,mCAAA,CACA,kBAAA,CAEA,mCAAA,CACA,wBAAA,CAEA,wDACC,gBAAA,CAGD,wDACC,eAAA,CAQD,gHAEC,UAAA,CACA,WAAA","sourcesContent":[".badge {\n\tdisplay: inline-flex;\n\tpadding: 4px 8px;\n\twhite-space: nowrap;\n\tbackground-color: var(--primary-100);\n\tborder-radius: 12px;\n\n\tfont: var(--semibold) var(--label-1);\n\tcolor: var(--primary-200);\n\n\t.iconLeft {\n\t\tmargin-right: 4px;\n\t}\n\n\t.iconRight {\n\t\tmargin-left: 4px;\n\t}\n\n\t// .label{\n\t//   margin-left: 4px;\n\t//   margin-right: 4px;\n\t// }\n\n\t.iconLeft,\n\t.iconRight {\n\t\twidth: 16px;\n\t\theight: 16px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": "badge-module_badge-nF31U",
	"iconLeft": "badge-module_iconLeft-gD+KH",
	"iconRight": "badge-module_iconRight-VpFTY"
};
module.exports = ___CSS_LOADER_EXPORT___;
