import React from 'react';
import classNames from 'classnames/bind';
import styles from './saved_cards.module.scss';
import {
	Button,
	Card,
	CardDetails,
	Container,
	Icon,
	RemoveCard,
	useModal
} from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation, useLocalization } from '@planity/localization';

export function SavedCards({
	className,
	items = [],
	defaultCard,
	makeDefault,
	deleteCard,
	deletingCard
}) {
	useStyles(styles);
	const { t } = useTranslation();
	// countryCode from website locale since this component is used only in my account page for now
	const { countryCode } = useLocalization();
	const { setModal, closeModal } = useModal();

	const classes = classNames.bind(styles)({
		savedCards: true,
		[className]: className !== undefined
	});

	const renderCheckbox = (item, isDisabled) => (
		<>
			<input
				checked={item.id === defaultCard}
				className={styles.radio}
				name='card'
				type='radio'
				onChange={() => makeDefault(item, countryCode)}
				disabled={isDisabled}
			/>
			<span />
		</>
	);

	const renderCardModal = item => (
		<Container>
			<Card
				title={t('myAccount.myProfile.title.SAVED_CARD')}
				titleClassName={styles.cardTitle}
			>
				<CardDetails {...item} />
				<Button
					label={t('bookAppointment.deleteCard')}
					type='danger'
					onClick={() => {
						setModal({
							content: (
								<RemoveCard
									deleteCardFromMyAccount={deleteCard}
									paymentMethod={item}
								/>
							),
							title: null,
							isFullHeight: false,
							onCloseModal: closeModal
						});
					}}
				/>
			</Card>
		</Container>
	);

	return (
		<div className={classes}>
			{items && (
				<div className={styles.items}>
					{items.map((item, i) => {
						const isDeleting = item.id === deletingCard && deletingCard;
						return (
							<div className={styles.item} key={i}>
								<label className={styles.infos} key={i}>
									{items.length > 1 ? renderCheckbox(item, isDeleting) : null}
									<CardDetails
										{...item}
										isCompact
										isDefault={defaultCard === item.id}
										isClickable={
											items.length && defaultCard !== item.id && !isDeleting
										}
									/>
								</label>
								<Button
									className={styles.delete}
									isFullMobile
									label={t('bookAppointment.deleteCard')}
									type='danger'
									onClick={() => {
										setModal({
											content: (
												<RemoveCard
													deleteCardFromMyAccount={deleteCard}
													paymentMethod={item}
												/>
											),
											title: null,
											order: 2
										});
									}}
									isLoading={isDeleting}
								/>
								<button
									className={styles.more}
									onClick={() => {
										setModal({
											content: renderCardModal(item),
											title: t('myAccount.myProfile.title.SAVED_CARD'),
											isFullHeight: true,
											hasCloseBtn: true,
											showBackBtnOnMobile: true
										});
									}}
								>
									<Icon icon='ChevronRight' size={'medium'} />
								</button>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
}
