import React from 'react';
import { RichText } from '@planity/components';
import { Card } from '@planity/ui';
import { useTranslation } from '@planity/localization';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './business_description.module.scss';
import classNames from 'classnames/bind';

export function BusinessDescription({ name, description, isSearch }) {
	const { t } = useTranslation();
	useStyles(styles);
	const classes = classNames.bind(styles)({
		description: true
	});

	const getDescription = () => {
		if (typeof description === 'string') {
			const text = description.replace(/\s/g, '');
			return text.length ? description : null;
		} else {
			const text = (description?.text || []).join('').replace(/\s/g, '');
			return text.length ? description : null;
		}
	};

	const sanitizedDescription = getDescription();

	if (!sanitizedDescription) return null;

	const schemaProps = isSearch
		? {}
		: {
				itemProp: 'description'
		  };

	return (
		<div className={classes}>
			{isSearch ? (
				<div className={styles.about}>
					<span className={styles.subtitle}>
						{t('search.title.moreDetails', { businessName: name })}
					</span>
					<RichText
						text={sanitizedDescription}
						transform={withoutBotox}
						{...schemaProps}
					/>
				</div>
			) : (
				<Card title={t('business.labels.about')}>
					<RichText
						text={sanitizedDescription}
						transform={withoutBotox}
						{...schemaProps}
					/>
				</Card>
			)}
		</div>
	);
}

// Google Ads prohibits the use of "botox" word
function withoutBotox(text) {
	return (text || '').replace(
		/(b)(o)(t)(o)(x)/gi,
		function (_, b, o1, t, o2, x) {
			return (
				b + o1 + t + '<span style="display: none">avoid censore</span>' + o2 + x
			);
		}
	);
}
