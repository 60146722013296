import { invokeLambda } from '@planity/helpers';
import { firebase } from '@planity/datastores';

const DOWNLOAD_PDF_LAMBDA = 'downloadGiftVoucherPdf';

/**
 * Determine which text to show
 * @param {*} services
 * @param {*} businessOnlinePaymentEnabled
 * @param {*} businessOnlinePaymentSetup
 * @param {*} giftCards
 * @returns 'giftVoucher' | 'giftService' | 'giftAll' | null
 */
export function showGiftButton(
	services = [],
	businessOnlinePaymentEnabled = false,
	businessOnlinePaymentSetup = false,
	giftCards = []
) {
	const giftCardsCanBeGifted = Object.values(giftCards || []).reduce(
		(canBeGifted, giftCard) => {
			//Si une carte cadeaux a déjà été detecté comme offrable en ligne : return canBeGifted (true)
			//Si la carte cadeau est onlinePurchasable et n'a pas été supprimé : return true;
			return (
				canBeGifted || (!!giftCard.onlinePurchasable && !giftCard.deletedAt)
			);
		},
		false
	);

	if (
		(services.length <= 0 && !giftCardsCanBeGifted) ||
		!businessOnlinePaymentSetup
	) {
		return null;
	} else if (services.length > 0) {
		return 'giftService';
	} else if (giftCardsCanBeGifted) {
		return 'giftVoucher';
	} else {
		return 'giftAll';
	}
}

/**
 * Download the voucher pdf file
 * @param {string} userId user ID
 * @param {string} id voucher ID
 * @param {string} last4 last 4 credit card digits
 */
export function downloadPdf(userId, id) {
	return new Promise((resolve, reject) => {
		firebase
			.auth()
			.currentUser.getIdToken()
			.then(userToken => {
				invokeLambda(DOWNLOAD_PDF_LAMBDA, {
					userId,
					firebaseToken: userToken,
					voucherId: id
				})
					.then(response => {
						if (response.url) resolve(response);
						else {
							reject(response);
						}
					})
					.catch(e => {
						reject(e);
					});
			});
	});
}

export function filterGiftVoucher(giftVouchers) {
	return !giftVouchers
		? giftVouchers
		: Object.keys(giftVouchers)
				.filter(
					key =>
						!giftVouchers[key].deletedAt && giftVouchers[key].onlinePurchasable
				)
				.sort((a, b) => {
					if (giftVouchers[a].sort && giftVouchers[b].sort) {
						return giftVouchers[a].sort - giftVouchers[b].sort;
					} else {
						return giftVouchers[a].name.localeCompare(giftVouchers[b].name);
					}
				})
				.reduce((res, key) => ((res[key] = giftVouchers[key]), res), {});
}
