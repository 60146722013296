import { sortObj } from '../itemsList';

/**
 * Compute a sorted list of products sets
 * @param {object} fetchedProducts map of products sets
 * @returns map of products sets
 */
export function computeSortedProducts(fetchedProducts) {
	// Sort the product sets
	// Products with pictures come first
	// Then sort using the sort property
	const sortedProductSet = sortObj(fetchedProducts, (a, b) => {
		return clickAndCollectProductsSetComparator(fetchedProducts, a, b);
	});

	// Sort the products using the quantity property
	const sortedProducts = Object.keys(sortedProductSet).reduce((acc, setKey) => {
		let set = sortProductSet(sortedProductSet[setKey]);

		// Adds id property to every children
		const children = Object.keys(set.children).reduce(
			(childrenAcc, childKey) => {
				const formattedChild = {
					...set.children[childKey],
					id: childKey
				};
				return { ...childrenAcc, [childKey]: formattedChild };
			},
			{}
		);

		return { ...acc, [setKey]: { ...set, children } };
	}, {});

	return sortedProducts;
}

/**
 * Comparator used to sort click and collect products sets
 * the sets are sorted using the sort property
 * @param {*} fetchedProducts map of products sets
 * @param {*} a first product set id
 * @param {*} b second product set id
 */
export function clickAndCollectProductsSetComparator(fetchedProducts, a, b) {
	const productA = fetchedProducts[a];
	const productB = fetchedProducts[b];

	if (productA.sort > productB.sort) {
		return 1;
	}
	if (productA.sort < productB.sort) {
		return -1;
	}

	return 0;
}

/**
 * Sorts a product set children.
 * The children with quantity = 0 must be at the end of the array.
 * @param {Object} productSet A product set
 */
export function sortProductSet(productSet) {
	const { children } = productSet;

	const sortedChildren = sortObj(children, (a, b) => {
		return productsComparator(children, a, b);
	});

	return { ...productSet, children: sortedChildren };
}

export function productsComparator(children, a, b) {
	const productA = children[a];
	const productB = children[b];

	const aIsSoldOut = productA.quantity <= 0;
	const bIsSoldOut = productB.quantity <= 0;
	const bothAreSoldOut = aIsSoldOut && bIsSoldOut;
	const bothHaveStock = !aIsSoldOut && !bIsSoldOut;
	const bothAreSoldOutOrNeitherAreSoldOut = bothAreSoldOut || bothHaveStock;

	const aHasImages = !!productA.images;
	const bHasImages = !!productB.images;
	const bothHaveImages = aHasImages && bHasImages;
	const bothDontHaveImages = !aHasImages && !bHasImages;
	const bothHaveImagesOrNeitherHaveImages =
		bothHaveImages || bothDontHaveImages;

	if (bothAreSoldOutOrNeitherAreSoldOut) {
		// Both are sold out or both have stock
		if (bothHaveImagesOrNeitherHaveImages) {
			// Both have images or both do not have images
			if (productA.sort > productB.sort) {
				return 1;
			}
			if (productA.sort < productB.sort) {
				return -1;
			}
		} else {
			// One has images
			if (aHasImages) {
				return -1;
			} else if (bHasImages) {
				return 1;
			}
		}
	} else {
		// One is sold out
		if (aIsSoldOut) {
			return 1;
		} else if (bIsSoldOut) {
			return -1;
		}
	}

	return 0;
}
