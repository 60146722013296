import React, { useEffect } from 'react';
import {
	addMonths,
	addDays,
	isToday,
	isSameDay,
	isWithinInterval,
	isPast
} from 'date-fns';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { DatePickerHeader } from '@planity/ui';
import { useLocalization } from '@planity/localization';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import scssStyles from './datepicker.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

const minDate = new Date();
const maxDate = addMonths(new Date(), 5);

export const DatePicker = ({
	handleChange,
	selectedDate,
	onClickOutside,
	// Google Analytics events
	sendPickDateFromNumberEvent,
	sendPickDateFromLabelEvent,
	hideQuickFilters = false,
	hasFixedWidth = false,
	hasBackground = false
}) => {
	useStyles(scssStyles);
	const cx = classNames.bind(scssStyles);

	const { dateLocale, language } = useLocalization();
	useEffect(() => {
		registerLocale(language, dateLocale);
	}, [dateLocale, language]);

	const onChange = date => {
		sendPickDateFromNumberEvent();
		handleChange(date);
	};

	return (
		<ReactDatePicker
			calendarContainer={({ children }) => (
				<div
					className={cx({
						DatePicker: true,
						hasBackground
					})}
					css={[styles.calendarInnerContainer]}
				>
					{children}
				</div>
			)}
			disabled={date => isPast(date)}
			disabledKeyboardNavigation
			inline
			locale={dateLocale}
			maxDate={maxDate}
			minDate={minDate}
			renderCustomHeader={props => (
				<DatePickerHeader
					className={cx({
						datePickerMonth: true
					})}
					{...props}
					handleChange={handleChange}
					hideQuickFilters={hideQuickFilters}
					sendPickDateFromLabelEvent={sendPickDateFromLabelEvent}
				/>
			)}
			renderDayContents={(day, date) => (
				<div
					className={cx({
						day: true,
						selectableDay: isWithinInterval(date, {
							start: addDays(minDate, -1),
							end: maxDate
						}),
						today: isToday(date),
						selectedDay: isSameDay(date, selectedDate || null),
						dayMobile: !hasFixedWidth
					})}
				>
					{day}
				</div>
			)}
			selected={selectedDate}
			weekDayClassName={() =>
				cx({
					weekDays: true
				})
			}
			onChange={onChange}
			onClickOutside={onClickOutside}
		/>
	);
};

const styles = {
	// DATE PICKER INNER CONTAINER STYLES + CUSTOM STYLES INSIDE DATEPICKER CLASSES
	calendarInnerContainer: {
		// CUSTOM STYLES INSIDE DATEPICKER CLASSES (not safe, to avoid at maximum)
		'& .react-datepicker__month-container': {
			float: 'none'
		},
		'& .react-datepicker__header--custom': {
			// should be padding and not paddingBottom to overwrite padding prop from default DatePicker css
			padding: '0 0 8px 0',
			backgroundColor: 'unset',
			borderBottom: 'none'
		},
		'& .react-datepicker__day-names': {
			display: 'flex',
			alignItems: 'end',
			justifyContent: 'space-between'
		},
		'.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name':
			{
				width: 'unset'
			},
		'& .react-datepicker__month': {
			// should be margin and not marginTop to overwrite margin prop from default DatePicker css
			margin: '12px 0 0 0'
		},
		'& .react-datepicker__week': {
			'fontSize': 14,
			'display': 'flex',
			'alignItems': 'center',
			'justifyContent': 'space-around',
			'marginBottom': '8px',
			'&:last-child': {
				marginBottom: 0
			}
		},
		'& .react-datepicker__day--outside-month, & .react-datepicker__day--disabled':
			{
				color: 'var(--grey-500)'
			},
		'& .react-datepicker__day--selected': {
			backgroundColor: 'unset'
		},
		'& .react-datepicker__day:hover': {
			backgroundColor: 'unset'
		}
	}
};
