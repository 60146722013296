import React from 'react';

export const HR = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5727)'>
				<mask
					id='mask0_11534_5727'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5727)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#F7FCFF'
					/>
					<mask
						id='mask1_11534_5727'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_11534_5727)'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V5H20V0H0Z'
							fill='#E31D1C'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 10V15H20V10H0Z'
							fill='#3D58DB'
						/>
						<rect
							x='6.29785'
							y='3.99834'
							width='7.125'
							height='2.5'
							fill='#E31D1C'
						/>
						<path
							d='M6.65767 5.5999H12.988C12.9987 6.0805 13.0019 6.91887 13.0019 7.72596C13.0018 8.24716 13.0004 8.74894 12.9991 9.12046L12.9972 9.56606L12.9966 9.68953L12.9965 9.7073L12.9962 9.70935C12.9934 9.72599 12.9887 9.75217 12.9815 9.78653C12.9671 9.85533 12.9429 9.95633 12.9043 10.0787C12.827 10.3246 12.6939 10.6505 12.472 10.9743C12.038 11.608 11.2499 12.2596 9.78113 12.2596C8.31431 12.2596 7.56439 11.6089 7.16521 10.9813C6.96025 10.659 6.84319 10.3342 6.77768 10.0886C6.74509 9.96636 6.72571 9.86547 6.71463 9.79672C6.7091 9.7624 6.70566 9.73625 6.70369 9.71965L6.7025 9.7089L6.70216 9.68135L6.70067 9.55866L6.69537 9.11582C6.69097 8.74652 6.68512 8.24738 6.67926 7.72797C6.67032 6.93434 6.6614 6.09586 6.65767 5.5999ZM6.70146 9.6991C6.70144 9.69893 6.70144 9.69892 6.70145 9.69908L6.70146 9.6991Z'
							fill='#F7FCFF'
							stroke='#E31D1C'
							strokeWidth='0.703125'
						/>
						<mask
							id='mask2_11534_5727'
							style={{ maskType: 'luminance' }}
							maskUnits='userSpaceOnUse'
							x='6'
							y='5'
							width='8'
							height='8'
						>
							<path
								d='M6.65767 5.5999H12.988C12.9987 6.0805 13.0019 6.91887 13.0019 7.72596C13.0018 8.24716 13.0004 8.74894 12.9991 9.12046L12.9972 9.56606L12.9966 9.68953L12.9965 9.7073L12.9962 9.70935C12.9934 9.72599 12.9887 9.75217 12.9815 9.78653C12.9671 9.85533 12.9429 9.95633 12.9043 10.0787C12.827 10.3246 12.6939 10.6505 12.472 10.9743C12.038 11.608 11.2499 12.2596 9.78113 12.2596C8.31431 12.2596 7.56439 11.6089 7.16521 10.9813C6.96025 10.659 6.84319 10.3342 6.77768 10.0886C6.74509 9.96636 6.72571 9.86547 6.71463 9.79672C6.7091 9.7624 6.70566 9.73625 6.70369 9.71965L6.7025 9.7089L6.70216 9.68135L6.70067 9.55866L6.69537 9.11582C6.69097 8.74652 6.68512 8.24738 6.67926 7.72797C6.67032 6.93434 6.6614 6.09586 6.65767 5.5999ZM6.70146 9.6991C6.70144 9.69893 6.70144 9.69892 6.70145 9.69908L6.70146 9.6991Z'
								fill='white'
								stroke='white'
								strokeWidth='0.703125'
							/>
						</mask>
						<g mask='url(#mask2_11534_5727)'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M6.56152 5.64995H7.87988V6.98864H6.56152V5.64995ZM9.19824 6.98864H7.87988V8.32732H6.56152V9.66601L7.87988 9.66601V11.0047H6.56152V12.3434H7.87988V11.0047H9.19824V12.3434H10.5166V11.0047H11.835V12.3434H13.1533V11.0047H11.835V9.66601L13.1533 9.66601V8.32732H11.835V6.98864H13.1533V5.64995H11.835V6.98864H10.5166V5.64995H9.19824V6.98864ZM9.19824 8.32732V6.98864H10.5166V8.32732H9.19824ZM9.19824 9.66601V8.32732H7.87988V9.66601H9.19824ZM10.5166 9.66601V11.0047H9.19824V9.66601H10.5166ZM10.5166 9.66601V8.32732H11.835V9.66601H10.5166Z'
								fill='#F50100'
							/>
						</g>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M6.53316 5.10165C6.53316 5.10165 6.94093 4.88384 7.28873 4.77159C7.63653 4.65935 7.91143 4.59802 7.91143 4.59802L7.39397 2.69046L6.35752 2.30322L5.77051 3.27079L6.53316 5.10165Z'
							fill='#56C6F5'
							stroke='white'
							strokeWidth='0.5'
						/>
						<path
							d='M6.36316 4.05675L6.48226 3.99934C6.60525 4.26241 6.76736 4.34367 6.98958 4.26074C7.21656 4.17603 7.3091 4.01585 7.27644 3.7619L7.40717 3.74457C7.44771 4.0598 7.32059 4.27983 7.03506 4.38639C6.74476 4.49473 6.5138 4.37897 6.36316 4.05675Z'
							fill='white'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M6.64521 3.46366L6.53135 3.53264C6.50615 3.5479 6.47483 3.52596 6.48055 3.49707L6.50642 3.36648L6.38976 3.30236C6.36395 3.28818 6.36728 3.25008 6.39516 3.24059L6.52119 3.1977L6.51839 3.06461C6.51777 3.03516 6.55243 3.019 6.57459 3.0384L6.67474 3.1261L6.78861 3.05712C6.8138 3.04186 6.84513 3.0638 6.8394 3.09269L6.81353 3.22328L6.93019 3.2874C6.95601 3.30159 6.95268 3.33968 6.92479 3.34917L6.79876 3.39206L6.80157 3.52515C6.80219 3.5546 6.76753 3.57077 6.74537 3.55136L6.64521 3.46366Z'
							fill='#FECA00'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M9.08799 4.42238C9.08799 4.42238 9.43574 4.39134 9.80114 4.39844C10.1665 4.40554 10.4757 4.40814 10.4757 4.40814L10.6541 2.52721L9.87142 1.82009L9.00138 2.54383L9.08799 4.42238Z'
							fill='#56C6F5'
							stroke='white'
							strokeWidth='0.5'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M9.84389 4.11551C10.1454 4.11551 10.1742 3.78991 10.1742 3.56811C10.0971 3.44509 10.1127 3.35706 9.86558 3.35706C9.6185 3.35706 9.59029 3.43172 9.5182 3.56811C9.5182 3.78991 9.54242 4.11551 9.84389 4.11551Z'
							fill='#FECA00'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M10.1831 3.19555C10.4846 3.19555 10.5135 2.86994 10.5135 2.64814C10.4364 2.52512 10.4519 2.43709 10.2048 2.43709C9.95772 2.43709 9.92951 2.51175 9.85742 2.64814C9.85742 2.86994 9.88165 3.19555 10.1831 3.19555Z'
							fill='#FECA00'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M9.52393 3.19555C9.8254 3.19555 9.85427 2.86994 9.85427 2.64814C9.77719 2.52512 9.79272 2.43709 9.54563 2.43709C9.29854 2.43709 9.27034 2.51175 9.19824 2.64814C9.19824 2.86994 9.22247 3.19555 9.52393 3.19555Z'
							fill='#FECA00'
						/>
						<path
							d='M7.64292 4.62658L7.71495 4.87127L7.95812 4.79434L7.95813 4.79434L7.95815 4.79434L7.95826 4.7943L7.95894 4.79409L7.96198 4.79313L7.97437 4.78928L8.02146 4.77495C8.06172 4.76287 8.11786 4.74641 8.18121 4.72897C8.31144 4.69312 8.45954 4.65665 8.5636 4.64219C8.67595 4.62658 8.81758 4.61499 8.9345 4.60725C8.99223 4.60343 9.04261 4.60063 9.07848 4.5988L9.12035 4.59676L9.13136 4.59627L9.13408 4.59616L9.1347 4.59613L9.13483 4.59612H9.13483L9.13484 4.59612L9.13484 4.59612L9.39611 4.58539L9.37364 4.32485L9.21597 2.49709L9.20602 2.38177L9.11165 2.31475L8.25995 1.70987L8.08458 1.58533L7.93526 1.74013L7.16121 2.54258L7.06025 2.64724L7.10132 2.78674L7.64292 4.62658Z'
							fill='#2E42A5'
							stroke='white'
							strokeWidth='0.5'
						/>
						<mask
							id='mask3_11534_5727'
							style={{ maskType: 'luminance' }}
							maskUnits='userSpaceOnUse'
							x='6'
							y='1'
							width='4'
							height='5'
						>
							<path
								d='M7.64292 4.62658L7.71495 4.87127L7.95812 4.79434L7.95813 4.79434L7.95815 4.79434L7.95826 4.7943L7.95894 4.79409L7.96198 4.79313L7.97437 4.78928L8.02146 4.77495C8.06172 4.76287 8.11786 4.74641 8.18121 4.72897C8.31144 4.69312 8.45954 4.65665 8.5636 4.64219C8.67595 4.62658 8.81758 4.61499 8.9345 4.60725C8.99223 4.60343 9.04261 4.60063 9.07848 4.5988L9.12035 4.59676L9.13136 4.59627L9.13408 4.59616L9.1347 4.59613L9.13483 4.59612H9.13483L9.13484 4.59612L9.13484 4.59612L9.39611 4.58539L9.37364 4.32485L9.21597 2.49709L9.20602 2.38177L9.11165 2.31475L8.25995 1.70987L8.08458 1.58533L7.93526 1.74013L7.16121 2.54258L7.06025 2.64724L7.10132 2.78674L7.64292 4.62658Z'
								fill='white'
								stroke='white'
								strokeWidth='0.5'
							/>
						</mask>
						<g mask='url(#mask3_11534_5727)'>
							<path
								d='M7.77277 4.14864L7.61621 3.77984C7.95247 3.63266 8.52049 3.54235 9.11822 3.50703L9.14119 3.90796C8.58552 3.94079 8.0599 4.02297 7.77277 4.14864Z'
								fill='#C51918'
							/>
							<path
								d='M7.50856 3.25654L7.35199 2.88774C7.68825 2.74056 8.411 2.6214 9.00873 2.58609L9.0317 2.98702C8.47604 3.01985 7.79568 3.13086 7.50856 3.25654Z'
								fill='#C51918'
							/>
						</g>
						<path
							d='M11.9884 4.62112L11.9204 4.87304L11.6716 4.79434L11.6716 4.79434L11.6716 4.79434L11.6715 4.7943L11.6708 4.79409L11.6678 4.79313L11.6554 4.78928L11.6083 4.77495C11.568 4.76287 11.5119 4.74641 11.4486 4.72897C11.3183 4.69312 11.1702 4.65665 11.0662 4.64219C10.9538 4.62658 10.8122 4.61499 10.6953 4.60725C10.6375 4.60343 10.5872 4.60063 10.5513 4.5988L10.5094 4.59676L10.4984 4.59627L10.4957 4.59616L10.4951 4.59613L10.4949 4.59612H10.4949L10.4949 4.59612L10.4949 4.59612L10.2337 4.58539L10.2561 4.32485L10.4138 2.49709L10.4237 2.38177L10.5181 2.31475L11.3698 1.70987L11.5501 1.58182L11.6992 1.74514L12.429 2.54456L12.5215 2.64584L12.4858 2.77825L11.9884 4.62112Z'
							fill='#2E42A5'
							stroke='white'
							strokeWidth='0.5'
						/>
						<mask
							id='mask4_11534_5727'
							style={{ maskType: 'luminance' }}
							maskUnits='userSpaceOnUse'
							x='9'
							y='1'
							width='4'
							height='5'
						>
							<path
								d='M11.9884 4.62112L11.9204 4.87304L11.6716 4.79434L11.6716 4.79434L11.6716 4.79434L11.6715 4.7943L11.6708 4.79409L11.6678 4.79313L11.6554 4.78928L11.6083 4.77495C11.568 4.76287 11.5119 4.74641 11.4486 4.72897C11.3183 4.69312 11.1702 4.65665 11.0662 4.64219C10.9538 4.62658 10.8122 4.61499 10.6953 4.60725C10.6375 4.60343 10.5872 4.60063 10.5513 4.5988L10.5094 4.59676L10.4984 4.59627L10.4957 4.59616L10.4951 4.59613L10.4949 4.59612H10.4949L10.4949 4.59612L10.4949 4.59612L10.2337 4.58539L10.2561 4.32485L10.4138 2.49709L10.4237 2.38177L10.5181 2.31475L11.3698 1.70987L11.5501 1.58182L11.6992 1.74514L12.429 2.54456L12.5215 2.64584L12.4858 2.77825L11.9884 4.62112Z'
								fill='white'
								stroke='white'
								strokeWidth='0.5'
							/>
						</mask>
						<g mask='url(#mask4_11534_5727)'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M11.4986 4.04155C11.4986 4.04155 11.6824 3.93928 11.7088 3.76686C11.7353 3.59443 12.0195 3.38206 11.8774 3.40733C11.7353 3.43261 11.8166 3.45235 11.6108 3.40733C11.4051 3.36231 11.1387 3.33254 11.1387 3.20604C11.1387 3.07955 11.2711 2.97916 11.1629 2.97916C11.0547 2.97916 10.8238 2.85944 10.8702 2.95094C10.9166 3.04245 10.7635 2.93898 10.84 3.12896C10.9166 3.31894 10.8238 3.51032 10.8702 3.56068C10.9166 3.61104 10.84 3.85804 10.84 3.85804C10.84 3.85804 10.8681 3.94978 10.9173 3.81237C10.9665 3.67497 11.074 3.57865 11.0203 3.69551C10.9665 3.81237 10.8978 4.09033 10.9591 4.04155C11.0203 3.99277 11.0811 3.85789 11.0811 3.81237C11.0811 3.76686 11.2355 3.58819 11.2355 3.72312C11.2355 3.85804 11.0903 3.96429 11.1629 3.96429C11.2355 3.96429 11.2763 3.99296 11.3219 3.85804C11.3676 3.72312 11.4785 3.63386 11.524 3.72312C11.5695 3.81237 11.5595 3.8076 11.4986 3.92458C11.4378 4.04155 11.4986 4.04155 11.4986 4.04155Z'
								fill='#FECA00'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M11.067 3.04157L10.9986 2.92713C11.1214 2.85149 11.2341 2.83134 11.333 2.87351C11.378 2.89268 11.412 2.91452 11.5003 2.97668L11.5119 2.98479C11.6039 3.04933 11.6526 3.07561 11.6826 3.07561V3.20948C11.6139 3.20948 11.5546 3.17744 11.4369 3.09494L11.4252 3.08672C11.3457 3.03078 11.315 3.011 11.282 2.99695C11.2273 2.97363 11.1568 2.98622 11.067 3.04157ZM10.8506 3.91466C10.8894 3.91466 10.9209 3.88357 10.9209 3.84521C10.9209 3.80686 10.8894 3.77577 10.8506 3.77577C10.8118 3.77577 10.7803 3.80686 10.7803 3.84521C10.7803 3.88357 10.8118 3.91466 10.8506 3.91466ZM11.0527 3.97908C11.0527 4.01744 11.0213 4.04853 10.9824 4.04853C10.9436 4.04853 10.9121 4.01744 10.9121 3.97908C10.9121 3.94073 10.9436 3.90964 10.9824 3.90964C11.0213 3.90964 11.0527 3.94073 11.0527 3.97908ZM11.2192 4.04336C11.2581 4.04336 11.2896 4.01227 11.2896 3.97392C11.2896 3.93557 11.2581 3.90448 11.2192 3.90448C11.1804 3.90448 11.1489 3.93557 11.1489 3.97392C11.1489 4.01227 11.1804 4.04336 11.2192 4.04336ZM11.5098 4.08506C11.5098 4.12341 11.4784 4.1545 11.4395 4.1545C11.4007 4.1545 11.3692 4.12341 11.3692 4.08506C11.3692 4.0467 11.4007 4.01561 11.4395 4.01561C11.4784 4.01561 11.5098 4.0467 11.5098 4.08506Z'
								fill='#C51918'
							/>
						</g>
						<path
							d='M13.4313 5.15356L13.3233 5.40229L13.0842 5.27456L13.0842 5.27456L13.0842 5.27455L13.0842 5.27454L13.0841 5.27451L13.0835 5.27417L13.0805 5.2726L13.0681 5.26607C13.057 5.26029 13.0405 5.25177 13.0196 5.24114C12.9777 5.21986 12.9181 5.19025 12.8481 5.15726C12.7062 5.09047 12.5285 5.01317 12.3696 4.96188C12.2011 4.90752 12.0501 4.86541 11.9413 4.83694C11.887 4.82272 11.8434 4.81195 11.8136 4.8048L11.7798 4.79681L11.7715 4.7949L11.7696 4.79447L11.7692 4.79439L11.7692 4.79438L11.7692 4.79438L11.7692 4.79438L11.7692 4.79438L11.5108 4.73673L11.5832 4.48203L12.112 2.6221L12.1465 2.50051L12.2649 2.45627L13.3014 2.06903L13.4953 1.9966L13.6026 2.17355L14.1896 3.14111L14.2567 3.25168L14.2052 3.37032L13.4313 5.15356Z'
							fill='#56C6F5'
							stroke='white'
							strokeWidth='0.5'
						/>
						<mask
							id='mask5_11534_5727'
							style={{ maskType: 'luminance' }}
							maskUnits='userSpaceOnUse'
							x='11'
							y='1'
							width='4'
							height='5'
						>
							<path
								d='M13.4313 5.15356L13.3233 5.40229L13.0842 5.27456L13.0842 5.27456L13.0842 5.27455L13.0842 5.27454L13.0841 5.27451L13.0835 5.27417L13.0805 5.2726L13.0681 5.26607C13.057 5.26029 13.0405 5.25177 13.0196 5.24114C12.9777 5.21986 12.9181 5.19025 12.8481 5.15726C12.7062 5.09047 12.5285 5.01317 12.3696 4.96188C12.2011 4.90752 12.0501 4.86541 11.9413 4.83694C11.887 4.82272 11.8434 4.81195 11.8136 4.8048L11.7798 4.79681L11.7715 4.7949L11.7696 4.79447L11.7692 4.79439L11.7692 4.79438L11.7692 4.79438L11.7692 4.79438L11.7692 4.79438L11.5108 4.73673L11.5832 4.48203L12.112 2.6221L12.1465 2.50051L12.2649 2.45627L13.3014 2.06903L13.4953 1.9966L13.6026 2.17355L14.1896 3.14111L14.2567 3.25168L14.2052 3.37032L13.4313 5.15356Z'
								fill='white'
								stroke='white'
								strokeWidth='0.5'
							/>
						</mask>
						<g mask='url(#mask5_11534_5727)'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M13.2047 3.18071L13.1159 3.27996C13.0963 3.30192 13.0599 3.2901 13.057 3.26079L13.0435 3.12835L12.9132 3.10114C12.8844 3.09512 12.8764 3.05771 12.9003 3.04049L13.0083 2.96263L12.9667 2.83617C12.9575 2.80818 12.9859 2.7826 13.0128 2.79467L13.1342 2.84926L13.2229 2.75001C13.2426 2.72805 13.2789 2.73986 13.2819 2.76917L13.2953 2.90161L13.4256 2.92882C13.4545 2.93484 13.4624 2.97225 13.4385 2.98948L13.3306 3.06734L13.3722 3.1938C13.3814 3.22178 13.3529 3.24737 13.3261 3.23529L13.2047 3.18071Z'
								fill='#FECA00'
							/>
							<path
								d='M11.872 4.05477L12.0047 3.53654C12.5927 3.69186 13.2056 3.89091 13.6553 4.08339L13.4504 4.57682C13.0256 4.39498 12.4366 4.20391 11.872 4.05477Z'
								fill='#E31D1C'
							/>
							<path
								d='M12.052 3.57624L12.0833 3.4462C12.6469 3.58608 13.2461 3.80929 13.6815 4.01718L13.6254 4.13832C13.1984 3.93442 12.6075 3.7141 12.052 3.57624Z'
								fill='white'
							/>
							<path
								d='M11.8849 4.11033L11.9162 3.98029C12.4799 4.12017 13.0009 4.30998 13.4363 4.51787L13.3802 4.63901C12.9532 4.43512 12.4404 4.24819 11.8849 4.11033Z'
								fill='white'
							/>
							<path
								d='M12.1393 3.85938L12.26 3.80487C12.3108 3.92382 12.3736 3.95002 12.4785 3.89904C12.7264 3.77863 13.3411 3.9469 13.3411 4.17379C13.3411 4.26231 13.2898 4.31598 13.2115 4.31287C13.1673 4.31111 13.1201 4.29365 13.0508 4.25942C13.0425 4.25527 13.0369 4.25249 13.0233 4.24557C12.9468 4.20653 12.941 4.20368 12.9206 4.19634L12.9642 4.06843C12.9924 4.0786 12.9969 4.08078 13.0819 4.12414C13.095 4.13081 13.1001 4.13339 13.108 4.13727C13.161 4.1635 13.1965 4.17663 13.2166 4.17743C13.2184 4.1775 13.2093 4.18707 13.2093 4.17379C13.2093 4.07396 12.7089 3.93701 12.5349 4.02159C12.3613 4.10593 12.2193 4.04668 12.1393 3.85938Z'
								fill='#272727'
							/>
						</g>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5727'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
