import React from 'react';
import { useTranslation } from '@planity/localization';
import styles from './gift_voucher_item.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { formatDuration, noop } from '@planity/helpers';
import { Icon } from '@planity/ui';
import { CloudinaryPicture, Localize, Address } from '@planity/components';

export function GiftVoucherItem({
	businessName,
	emittedIds,
	business,
	price,
	linkToBusiness
}) {
	useStyles(styles);
	const { t } = useTranslation();

	return (
		<div className={styles.giftVoucherItem}>
			<div
				className={styles.image}
				id='gift-product-image'
				onClick={() => (process.env.WIDGET ? noop() : linkToBusiness(business))}
			>
				{business?.pictures?.[0] ? (
					<CloudinaryPicture
						alt={`image-${business.name}`}
						src={business?.pictures?.at(0)}
						transformation={'c_thumb,g_center,h_128,w_128'}
					/>
				) : (
					<div className={styles.noPicture} />
				)}
			</div>

			<div className={styles.content} id='gift-product-content'>
				<span
					className={styles.businessName}
					id='gift-voucher-item-businessName'
					onClick={() =>
						process.env.WIDGET ? noop() : linkToBusiness(business)
					}
				>
					{businessName}
				</span>

				<div className={styles.address} id='gift-voucher-linkToBusiness'>
					<Address
						{...business.address}
						location={business.location}
						shouldOpenMap
						title={business.name}
					/>
				</div>

				{Object.values(emittedIds).map(service => (
					<div
						className={styles.service}
						id='gift-voucher-item-service'
						key={service.name}
					>
						<span className={styles.name}>
							<Icon icon='Service' size='medium' />
							{service.name} &nbsp;{' '}
						</span>
						{service.duration ? (
							<span className={styles.duration}>
								<Icon icon='Timer' size='medium' />
								{formatDuration(service.duration, t)}
							</span>
						) : (
							''
						)}
					</div>
				))}

				<div className={styles.price}>
					<Localize text={`myAccount.orders.totalPrice`}>
						{text => (
							<>
								<Icon icon='CreditCard' size='medium' />
								<span>
									<span>{`${text} :`}&nbsp;</span>
									<span className={styles.iconText} id='gift-voucher-price'>
										{price}
									</span>
								</span>
							</>
						)}
					</Localize>
				</div>
			</div>
		</div>
	);
}
