import {
	DEFAULT_LANGUAGE,
	DEFAULT_COUNTRY_CODE,
	AVAILABLE_LANGUAGES,
	AVAILABLE_COUNTRY_CODES
} from '@planity/localization';

export function languageFromURL(pathname) {
	if (typeof pathname !== 'string') {
		return DEFAULT_LANGUAGE;
	}
	const localeInURL = pathname.match(/^\/?([a-z]{2})-([A-Z]{2})(?:\/?$|\/.*$)/);
	if (!localeInURL || !Array.isArray(localeInURL) || localeInURL.length < 3)
		return DEFAULT_LANGUAGE;

	return AVAILABLE_LANGUAGES.includes(localeInURL[1])
		? localeInURL[1]
		: DEFAULT_LANGUAGE;
}

export function countryCodeFromURL(pathname) {
	if (typeof pathname !== 'string') {
		return DEFAULT_COUNTRY_CODE;
	}
	const localeInURL = pathname.match(/^\/?([a-z]{2})-([A-Z]{2})(?:\/?$|\/.*$)/);
	if (!localeInURL || !Array.isArray(localeInURL) || localeInURL.length < 3)
		return DEFAULT_COUNTRY_CODE;

	return AVAILABLE_COUNTRY_CODES.includes(localeInURL[2])
		? localeInURL[2]
		: DEFAULT_COUNTRY_CODE;
}

export function localeFromURL(pathname) {
	return `${languageFromURL(pathname)}-${countryCodeFromURL(pathname)}`;
}

/**
 * Duplicate of packages/localization/provider.js function, so we can use
 * it outside a React component (eg : in server)
 * @param locale {String}
 * @returns {string} "/" for frenchies, "/de-DE" for german, "/fr-BE" for belgians, etc
 */
export function localizedBasePath(locale) {
	return locale && locale !== 'fr-FR' ? `/${locale}` : '';
}

import { AVAILABLE_LOCALES } from '@planity/localization';

export const getPathnameWithoutLocale = pathname => {
	// Equivalent to /^\/?fr-FR|^\/?de-DE/gm
	const localeMatcher = new RegExp(
		AVAILABLE_LOCALES.map(locale => `^/?${locale}`).join('|'),
		'gm'
	);

	return pathname.replace(localeMatcher, '');
};
