import React from 'react';
import { Localize } from '@planity/components';
import ReviewForm from './review_form';
import { useTranslation } from '@planity/localization';
import { Button, useModal } from '@planity/ui';
import { reviewHasExpired, getAppointmentDetails } from './';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './review_block.module.scss';

/**
 * Component that renders a block for submitting/updating or viewing reviews.
 *
 * @param {object} appointment - Information about the appointment associated with the review.
 * @param {string} appointmentId  - The ID of the appointment associated with the review.
 * @returns {JSX.Element|null} The component JSX in form of a modal (thanks to useModal).
 */

export function ReviewBlock({ appointment, appointmentId, business }) {
	const { t } = useTranslation();
	const { setModal } = useModal();
	useStyles(styles);

	if (reviewHasExpired(appointment)) {
		return null;
	}

	return (
		<div className={styles.footer} key={appointmentId}>
			<div
				className={styles.footerLink}
				id={'appointment-toggle-review-button'}
			>
				<Button
					iconLeft={'Star'}
					label={
						appointment?.review
							? t('myAccount.appointment.updateReview')
							: t('myAccount.appointment.leaveReview')
					}
					outlined
					size='small'
					stroke
					strokeWidth={1.5}
					type='linked'
					onClick={() => {
						setModal({
							content: (
								<ReviewForm
									appointmentId={appointmentId}
									appointmentInfo={appointment}
									business={business}
									itemReviewed={getAppointmentDetails(
										appointment,
										appointment.business,
										t
									)}
									pastReview={appointment?.review || null}
								/>
							),
							isFullHeight: true,
							showBackBtnOnMobile: true,
							title: appointment?.review ? (
								<Localize text={'myAccount.appointment.updateReview'} />
							) : (
								<Localize text={'myAccount.appointment.leaveReview'} />
							)
						});
					}}
				/>
			</div>
		</div>
	);
}
