import React from 'react';

/**
 * @param src can be an object with picture id || string image url
 * @param transformation a simple cloudinary transformation
 * @param transformations an object with media queries and transformation associated and a default
 *   transformation fallback
 * @param children
 * @param className
 * @param alt
 */
export function CloudinaryPicture({
	src,
	transformation,
	transformations,
	children,
	className,
	alt,
	style
}) {
	const publicId =
		typeof src === 'string' ? `${src.split('/upload/').pop()}` : src?.id;

	const source = transformations
		? transformations.reduce((all, t) => {
				all[t] = cloudinaryURL(publicId, src, t);
				return all;
		  }, {})
		: cloudinaryURL(publicId, src, transformation);

	return typeof children === 'function' ? (
		children(source)
	) : source ? (
		<img
			alt={alt}
			className={className}
			loading={'lazy'}
			src={source}
			style={style}
		/>
	) : null;
}

export function cloudinaryURL(publicId, src, transformation) {
	if (publicId) {
		const [cropMadeByHand] = (src?.thumbs?.d_main?.t || '').split('/');
		const crop = cropMadeByHand.length ? `${cropMadeByHand}/` : '';
		const prefix = transformation ? transformation : 'q_auto';
		const formatAuto = 'f_auto';
		const transformations = [prefix, formatAuto].filter(x => x).join(',');
		return `https://res.cloudinary.com/planity/image/upload/${crop}${transformations}/${publicId}`;
	} else {
		return null;
	}
}
