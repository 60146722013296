import React, { useCallback, useContext } from 'react';
import TabsContext from '@planity/components/tabs/context';
import { Localize, useAuth } from '@planity/components';
import { useTranslation } from '@planity/localization';
import { Card, Spinner, useModal } from '@planity/ui';
import { useErrorMessages } from '@planity/components';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import {
	MY_APPOINTMENTS_TAB,
	createChild,
	deleteChild,
	updateChild
} from '@planity/helpers';
import { Child } from './child';
import styles from './my_children.module.scss';
import { AddChildButton, ChildrenLimitWarning } from '../../children';

const MAX_CHILDREN = 10;

export function MyChildren({
	parentId,
	setChildren,
	userChildren,
	isLoadingChildren
}) {
	useStyles(styles);
	const classes = classNames.bind(styles);

	const { t } = useTranslation();
	const { pushMessage } = useErrorMessages();
	const { setCurrent } = useContext(TabsContext);
	const { setModal } = useModal();
	const { user, userId } = useAuth();

	const parent = {
		email: user.email,
		name: user.name,
		phone: user.phone,
		parentId: userId
	};

	const handleChildCreation = async child => {
		try {
			const childAdded = await createChild(child);
			setChildren(prevChildren => [...prevChildren, childAdded]);
		} catch (e) {
			handleErrorMessage(pushMessage, t);
			console.error(e);
		}
	};

	const handleChildUpdating = async updatedChild => {
		try {
			const childUpdated = await updateChild(updatedChild);
			setChildren(prevChildren =>
				prevChildren.map(child =>
					child.childId === updatedChild.childId ? childUpdated : child
				)
			);
		} catch (e) {
			handleErrorMessage(pushMessage, t);
			console.error(e);
		}
	};

	const handleChildDeletion = async childId => {
		try {
			await deleteChild({
				childId,
				parentId,
				isLast: userChildren.length === 1
			});
			const children = userChildren.filter(child => child.childId !== childId);
			setChildren(children);
		} catch (e) {
			handleErrorMessage(pushMessage, t);
		}
	};

	const redirectToMyAppointments = useCallback(
		() => setCurrent(MY_APPOINTMENTS_TAB),
		[setCurrent]
	);

	const hasMaxChildren = userChildren.length >= MAX_CHILDREN;

	return (
		<Card
			className={classes({ children: true })}
			hasTitleInside
			isVisibleMobile
			title={<Localize text={'myAccount.tabs.myChildren'} />}
			titleClassName={styles.childrenTitle}
		>
			{isLoadingChildren ? (
				<div className={styles.spinner}>
					<Spinner />
				</div>
			) : userChildren.length ? (
				userChildren.map(child => (
					<Child
						child={child}
						deleteChild={handleChildDeletion}
						handleChildUpdating={handleChildUpdating}
						key={child.childId}
						parentId={parentId}
						redirectToMyAppointments={redirectToMyAppointments}
						userChildren={userChildren}
					/>
				))
			) : (
				<p className={styles.text}>
					<Localize text={'children.noChildren'} />
				</p>
			)}
			{hasMaxChildren && <ChildrenLimitWarning />}
			<div
				className={[
					styles.addChild,
					hasMaxChildren && styles.reduceMargin
				].join(' ')}
			>
				<AddChildButton
					handleChildCreation={handleChildCreation}
					isFullMobile={true}
					label={<Localize text={'children.actions.addTitle'} />}
					parent={parent}
					setModal={setModal}
					userChildren={userChildren}
				/>
			</div>
		</Card>
	);
}

const handleErrorMessage = (pushMessage, t) => {
	pushMessage({
		message: t('children.defaultError'),
		type: 'error',
		createdAt: Date.now()
	});
};
