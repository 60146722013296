export const Heart = ({ stroke, fill, ...props }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={25}
		height={24}
		viewBox='0 0 25 24'
		fill={fill || 'none'}
		{...props}
	>
		<path
			stroke={stroke || '#08080B'}
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M12.795 7.233c-1.45-1.623-3.867-2.06-5.683-.573-1.816 1.486-2.072 3.971-.645 5.73l6.328 5.86 6.329-5.86c1.426-1.759 1.201-4.26-.646-5.73-1.848-1.471-4.233-1.05-5.683.573Z'
			clipRule='evenodd'
		/>
	</svg>
);
