import React from 'react';

export const GF = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g>
				<mask
					style={{ maskType: 'alpha' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M14 0H20V15H14V0Z'
						fill='#F50100'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0H7V15H0V0Z'
						fill='#2E42A5'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M6 0H14V15H6V0Z'
						fill='#F7FCFF'
					/>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
				style={{ mixBlendMode: 'multiply' }}
			/>
			<defs>
				<clipPath>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
