import React from 'react';

export function Pencil() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
		>
			<path
				d='M0.75 15.25L5 14.25L14.9491 4.30086C15.3397 3.91033 15.3397 3.27717 14.9491 2.88664L13.1134 1.05086C12.7228 0.660334 12.0897 0.660334 11.6991 1.05086L1.75 11L0.75 15.25Z'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
