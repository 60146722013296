import { useViewerHeaders } from '@planity/components';
import { sendAppBannerHasBeenDisplayed } from '@planity/helpers';
import { useLocalization } from '@planity/localization';
import { isNativeApp } from '@planity/webview';
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';

const FRACTION_OF_USER_TO_SHOW_BANNER = 1; // 100% of users for now

export const TEMPLATES_MODAL = {
	DEFAULT: 'default',
	BOOK_APPOINTMENT: 'book_appointment'
};

const AppBannerContext = createContext({});

export const useAppBanner = () => useContext(AppBannerContext);

export function AppBannerContextProvider({ children }) {
	const { isTabletViewer, isIOSViewer, isAndroidViewer } = useViewerHeaders();
	const { countryCode } = useLocalization();
	const [isDisplayable, setIsDisplayable] = useState(false);
	const [hasBeenDismissed, setHasBeenDismissed] = useState(false);
	const countryCodeIsBlacklisted = [].includes(countryCode);
	const [template, setTemplate] = useState(TEMPLATES_MODAL.DEFAULT);
	const randomNumber = useMemo(() => Math.random(), []);
	const canBeDisplayed =
		!process.env.WIDGET &&
		!isTabletViewer &&
		(isIOSViewer || isAndroidViewer) &&
		!countryCodeIsBlacklisted &&
		!isNativeApp &&
		randomNumber < FRACTION_OF_USER_TO_SHOW_BANNER;

	const appBannerIsVisible =
		canBeDisplayed && !hasBeenDismissed && isDisplayable;

	const dismissAppBanner = useCallback(() => {
		setHasBeenDismissed(true);
		setTemplate(TEMPLATES_MODAL.DEFAULT);
	}, []);

	const displayAppBanner = useCallback(
		(templateModal = TEMPLATES_MODAL.DEFAULT) => {
			setIsDisplayable(true);
			setHasBeenDismissed(false);
			setTemplate(templateModal);
		},
		[]
	);

	const state = {
		dismissAppBanner,
		appBannerIsVisible,
		countryCodeIsBlacklisted,
		setIsDisplayable,
		setHasBeenDismissed,
		displayAppBanner,
		appBannerCanBeDisplayed: canBeDisplayed,
		bannerTemplate: template
	};

	useEffect(() => {
		if (appBannerIsVisible) sendAppBannerHasBeenDisplayed();
	}, [appBannerIsVisible]);

	return (
		<AppBannerContext.Provider value={state}>
			{children}
		</AppBannerContext.Provider>
	);
}

export const WithAppBanner = AppBannerContext.Consumer;
export const withAppBanner = WrappedComponent => props =>
	(
		<WithAppBanner>
			{contextProps => <WrappedComponent {...contextProps} {...props} />}
		</WithAppBanner>
	);
