import React from 'react';

export const GT = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5713)'>
				<mask
					id='mask0_11534_5713'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5713)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M14 0H20V15H14V0Z'
						fill='#58A5FF'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0H6V15H0V0Z'
						fill='#58A5FF'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M6 0H14V15H6V0Z'
						fill='white'
					/>
					<path
						d='M7.97784 10.6484L7.52376 10.2189L12.3284 5.13884L12.7825 5.5683L7.97784 10.6484Z'
						fill='#C6C6C6'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M8.11548 5.0659C8.11548 5.0659 6.57742 5.99414 6.57742 8.32112C6.57742 10.6481 10.322 10.9629 10.322 10.9629C10.322 10.9629 8.08112 9.59613 7.78784 8.61763C7.49455 7.63913 8.11548 5.0659 8.11548 5.0659Z'
						fill='#5AB92D'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M11.9089 5.0659C11.9089 5.0659 13.447 5.99414 13.447 8.32112C13.447 10.6481 9.70243 10.9629 9.70243 10.9629C9.70243 10.9629 12.0573 9.61241 12.3873 8.32112C12.7174 7.02983 11.9089 5.0659 11.9089 5.0659Z'
						fill='#5AB92D'
					/>
					<path
						d='M9.25478 4.74973L9.87722 4.80622C9.85675 5.03174 9.97995 5.26128 10.2846 5.50764C11.265 6.3006 11.7919 7.28975 11.379 8.41378C11.0793 9.22942 10.6723 9.9181 10.1573 10.4775L9.69749 10.0541C10.1548 9.55744 10.5199 8.93961 10.7923 8.19825C11.0889 7.39093 10.6874 6.63734 9.89151 5.99359C9.43802 5.6268 9.21319 5.20791 9.25478 4.74973Z'
						fill='#5AB92D'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M8.85864 6.13058L9.51443 7.98444H9.03238C9.03238 7.98444 8.85864 8.56948 9.18654 8.56948C9.51443 8.56948 11.2042 8.56948 11.2042 8.56948C11.2042 8.56948 11.4239 8.42278 11.314 7.98444C11.2042 7.54609 10.6492 6.33601 10.6492 6.33601C10.6492 6.33601 10.8863 6.13518 10.8863 5.98632C10.8863 5.83746 10.6492 5.82106 10.6492 5.82106H9.15225C8.96118 5.85409 8.85864 6.13058 8.85864 6.13058Z'
						fill='#EFE298'
					/>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5713'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
