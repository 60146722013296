import React, { Component, createContext } from 'react';
import { withAuth } from '@planity/components';

export const GiftVoucherAnalyticContext = createContext();

const STEPS = [
	{
		google: {
			eventCategory: 'PageViews',
			eventAction: 'USER_STARTED_GIFT_VOUCHER_BUYING'
		},
		facebook: 'USER_STARTED_GIFT_VOUCHER_BUYING'
	},
	{
		google: {
			eventCategory: 'PageViews',
			eventAction: 'USER_SELECTED_GIFT_VOUCHER'
		},
		facebook: 'USER_SELECTED_GIFT_VOUCHER'
	},
	{
		google: {
			eventCategory: 'PageViews',
			eventAction: 'USER_DEFINED_VARIABLE_GIFT_VOUCHER_AMOUNT'
		},
		facebook: 'USER_DEFINED_VARIABLE_GIFT_VOUCHER_AMOUNT'
	},
	{
		google: {
			eventCategory: 'PageViews',
			eventAction: 'USER_CONFIRMED_GIFT_VOUCHER_SELECTION'
		},
		facebook: 'USER_CONFIRMED_GIFT_VOUCHER_SELECTION'
	},
	{
		google: {
			eventCategory: 'PageViews',
			eventAction: 'USER_CHOSE_GIFT_VOUCHER_TEMPLATE'
		},
		facebook: 'USER_CHOSE_GIFT_VOUCHER_TEMPLATE'
	},
	{
		google: { eventCategory: 'PageViews', eventAction: 'USER_SIGNED_IN' },
		facebook: 'USER_SIGNED_IN'
	},
	{
		google: {
			eventCategory: 'PageViews',
			eventAction: 'USER_SELECTED_PAYMENT_METHOD'
		},
		facebook: 'USER_SELECTED_PAYMENT_METHOD'
	},
	{
		google: {
			eventCategory: 'PageViews',
			eventAction: 'USER_BOUGHT_GIFT_VOUCHER'
		},
		facebook: 'USER_BOUGHT_GIFT_VOUCHER'
	}
];

export const GiftVouchersEvents = withAuth(
	class GiftVouchersTracker extends Component {
		state = {
			prevPublishedStep: null,
			USER_STARTED_GIFT_VOUCHER_BUYING: () => this.publish({ stepIndex: 0 }),
			USER_SELECTED_GIFT_VOUCHER: () => this.publish({ stepIndex: 1 }),
			USER_DEFINED_VARIABLE_GIFT_VOUCHER_AMOUNT: () =>
				this.publish({ stepIndex: 2 }),
			USER_CONFIRMED_GIFT_VOUCHER_SELECTION: () =>
				this.publish({ stepIndex: 3 }),
			USER_CHOSE_GIFT_VOUCHER_TEMPLATE: () => this.publish({ stepIndex: 4 }),
			USER_SIGNED_IN: () => this.publish({ stepIndex: 5 }),
			USER_SELECTED_PAYMENT_METHOD: () => this.publish({ stepIndex: 6 }),
			USER_BOUGHT_GIFT_VOUCHER: () => this.publish({ stepIndex: 7 })
		};
		render() {
			return (
				<GiftVoucherAnalyticContext.Provider value={this.state}>
					{this.props.children}
				</GiftVoucherAnalyticContext.Provider>
			);
		}
		publish({ stepIndex, alwaysPublish, strict, googlePayload, businessType }) {
			const { prevPublishedStep } = this.state;
			if (
				strict
					? prevPublishedStep === stepIndex - 1
					: prevPublishedStep === null ||
					  prevPublishedStep < stepIndex ||
					  alwaysPublish
			) {
				this.setState(
					{
						prevPublishedStep: stepIndex
					},
					() => {
						const step = STEPS[stepIndex];
						if (process.env.USE_GOOGLE_ANALYTICS) {
							if (window.ga) {
								window.ga('send', {
									hitType: 'event',
									...(googlePayload || {}),
									...step.google
								});
							}
						}
						if (process.env.USE_GOOGLE_ANALYTICS_V4) {
							if (window.gtag) {
								window.gtag('event', step.google.eventCategory, {
									...(googlePayload || {}),
									...step.google
								});
							}
						}
						if (process.env.USE_FACEBOOK_TRACKING) {
							if (window.fbq) {
								window.fbq('trackCustom', step.facebook);
								if (businessType && step.facebookByType[businessType]) {
									window.fbq('trackCustom', step.facebookByType[businessType]);
								}
							}
						}
						if (process.env.USE_PINTEREST_TRACKING && !!step.pinterest) {
							if (window.pintrk) {
								window.pintrk('track', step.pinterest);
							}
						}
						if (process.env.DEBUG_ANALYTICS) {
							console.log(
								`%c# Tracking google analytics event: ${JSON.stringify(step)}`,
								'color:#fb8c00;font-weight:bold;'
							);
						}
					}
				);
			}
		}
	}
);

export const withGiftVouchersEvents = WrappedComponent => props =>
	(
		<GiftVoucherAnalyticContext.Consumer>
			{giftVouchersProps => (
				<WrappedComponent {...giftVouchersProps} {...props} />
			)}
		</GiftVoucherAnalyticContext.Consumer>
	);
