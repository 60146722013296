import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './breadcrumbs.module.scss';

export const BreadCrumbSpan = ({ itemProp, text, isLast }) => {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		breadcrumbs: true
	});

	return (
		<span
			className={`${classes} ${styles.link}`}
			itemProp={itemProp}
			aria-current={!!isLast}
		>
			{text}
		</span>
	);
};
