import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { Localize } from '@planity/components';
import { formatPrice } from '@planity/helpers';
import { Icon } from '@planity/ui';
import styles from './fees_description.scss';
import classNames from 'classnames/bind';

/**
 * Display the fees line in appointments
 * @param fees { Number } fees in cents
 * @param isBold {boolean} should the text be bold
 * @param className
 */
export function FeesDescription({ fees, isBold, className }) {
	useStyles(styles);
	const cx = classNames.bind(styles);

	return (
		<div className={cx({ feesDescription: true, isBold }, className)}>
			<Icon icon='CreditCard' size={'small'} />
			<p className={cx({ text: true, isBold })}>
				<Localize text={'myAccount.appointment.serviceFees'} />
			</p>
			<span
				className={cx({ price: true, isBold })}
				id='appointment-preview-fees'
			>
				{formatPrice(fees, true)}
			</span>
		</div>
	);
}
