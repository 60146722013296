import React, { createContext, useContext } from 'react';

export const FormFactorContext = createContext('desktop');

export const FormFactorContextProvider = ({ formFactor, children }) => {
	return (
		<FormFactorContext.Provider value={formFactor}>
			{children}
		</FormFactorContext.Provider>
	);
};

export function useSkipThisWhenMobile() {
	const formFactor = useContext(FormFactorContext);
	return formFactor === 'phone';
}

export function useIsTouchDevice() {
	const formFactor = useContext(FormFactorContext);
	return formFactor === 'phone' || formFactor === 'tablet';
}

export const useFormFactor = () => {
	const formFactor = useContext(FormFactorContext);
	return formFactor;
};

export const withFormFactor = Component => props =>
	(
		<FormFactorContext.Consumer>
			{formFactor => <Component {...props} formFactor={formFactor} />}
		</FormFactorContext.Consumer>
	);
