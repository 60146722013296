export function HalfEmptyStar({ fill }) {
	return (
		<svg
			width='25'
			height='24'
			viewBox='0 0 24 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M12.6 4.75L14.35 10.25H19.85L15.35 13.75L16.85 19.25L12.6 15.75L8.34998 19.25L9.84998 13.75L5.34998 10.25H10.85L12.6 4.75Z'
				fill={fill || 'var(--grey-400)'}
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M12.6 16V6L11.6 10L7.59998 11L10.6 13L9.59998 17L12.6 16Z'
				fill='currentColor'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
