import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './overlay.module.scss';

export function Overlay({ isVisible = false }) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		overlay: true,
		visible: isVisible
	});

	return <div className={classes}></div>;
}
