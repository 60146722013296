import React, { Component } from 'react';
import { USER_CHOSE_DATE } from '../../events';
import { DesktopAvailabilitiesPicker } from './desktop_availabilities_picker';
import { MobileAvailabilitiesPicker } from './mobile_availabilities_picker';
import { withFormFactor } from '../../../provider';
import classNames from 'classnames/bind';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from '../step.module.scss';
import { Spinner } from '@planity/ui';

// eslint-disable-next-line import/no-default-export
export default withStyles(styles)(
	withFormFactor(
		class AppointmentAvailabilities extends Component {
			chooseDate = date => {
				this.props.dispatch(USER_CHOSE_DATE, { date });
			};

			render() {
				const today = new Date();
				const { business, userFilter, areLoading, availabilities } = this.props;

				const classes = classNames.bind(styles)({
					timetable: true
				});

				return (
					<div className={classes}>
						{areLoading ? (
							<div className={styles.spinner}>
								<Spinner />
							</div>
						) : (
							<>
								<div className={styles.tablet}>
									<DesktopAvailabilitiesPicker
										availabilities={availabilities}
										business={business}
										chooseDate={this.chooseDate}
										today={today}
										userFilter={userFilter}
									/>
								</div>
								<div className={styles.mobile}>
									<MobileAvailabilitiesPicker
										availabilities={availabilities}
										business={business}
										chooseDate={this.chooseDate}
										today={today}
									/>
								</div>
							</>
						)}
					</div>
				);
			}
		}
	)
);
