import classNames from 'classnames/bind';
import { useMemo } from 'react';
import styles from './card_details.module.scss';
import { CardLogo } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';
import { formatCardBrand, formatCardName } from '@planity/helpers';

const ONE_MONTH = 1000 * 60 * 60 * 24 * 30;
const NOW = Date.now();

export function CardDetails({
	card,
	isDefault,
	isCompact,
	isClickable = true
}) {
	useStyles(styles);
	const { t } = useTranslation();

	const classes = classNames.bind(styles)({
		cardDetails: true,
		pointer: isClickable,
		isCompact
	});

	const [isExpiring, isExpired] = useMemo(() => {
		const { exp_month, exp_year } = card;
		const expiringDate = new Date(exp_year, exp_month);
		const isExpiring = expiringDate - NOW < ONE_MONTH && expiringDate - NOW > 0;
		const isExpired = expiringDate - NOW <= 0;
		return [isExpiring, isExpired];
	}, [card]);

	const date = `${card.exp_month.toString().padStart(2, '0')}/${card.exp_year}`;

	if (!card) return null;

	return (
		<div className={classes}>
			<CardLogo brand={formatCardBrand(card.brand)} className={styles.card} />

			<div className={styles.title}>
				<span className={styles.label}>
					<span>{t('myAccount.cards.card')}</span>
					{isDefault && (
						<span className={styles.defaultDesktop}>
							{` ${t('myAccount.cards.defaultCard')}`}
						</span>
					)}
				</span>
				<span className={styles.value}>
					{formatCardName(card.brand)} {card.last4}
				</span>

				{isDefault && (
					<span className={styles.defaultMobile}>
						{` ${t('myAccount.cards.defaultCard')}`}
					</span>
				)}
			</div>

			<div className={styles.info}>
				<span className={styles.label}>{t('myAccount.cards.expire')}</span>
				<span className={styles.value}>
					<span>{date}</span>
					{isExpired && (
						<span className={styles.expired}>
							{t('myAccount.cards.expiredCard')}
						</span>
					)}
					{isExpiring && (
						<span className={styles.expiring}>
							{t('myAccount.cards.expireSoonCard')}
						</span>
					)}
				</span>
			</div>
		</div>
	);
}
