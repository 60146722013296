import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './nav.module.scss';
import { Icon } from '@planity/ui';

export function Nav({ items = [], current = 0 }) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		nav: true
	});

	const renderItems = items.map((item, i) => {
		const itemClasses = classNames.bind(styles)({
			item: true,
			complete: i < current + 1,
			current: i === current
		});

		return (
			<li className={itemClasses} key={item.label}>
				{i !== 0 && <Icon icon='ChevronRight' />}
				<span>{item.label}</span>
			</li>
		);
	});

	return (
		<nav className={classes}>
			<ul className={styles.items}>{renderItems}</ul>
		</nav>
	);
}
