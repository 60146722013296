import { useLocalizedRoutes } from '@planity/localization';
import React, { forwardRef } from 'react';
import { Pagination, PaginationInfinite } from '../ui';
import { Results } from './results';
import { NoResults } from './no_results';
import { Spinner } from '@planity/ui';

export default forwardRef(function SearchPageMain(
	{
		businesses,
		availabilities,
		isLoading,
		onBusinessHover,
		pagesCount,
		search,
		services,
		hoveredBusinessId,
		date,
		setPageSearch,
		pageSearch
	},
	ref
) {
	const { routes } = useLocalizedRoutes();

	if (
		(isLoading && !date) ||
		(isLoading && date && (!businesses || !businesses.length))
	) {
		return (
			<div css={styles.spinner}>
				<Spinner />
			</div>
		);
	}
	if ((businesses && businesses.length) || search.bounds) {
		return (
			<div ref={ref}>
				<Results
					availabilities={availabilities}
					businesses={businesses}
					hoveredBusinessId={hoveredBusinessId}
					isLoading={isLoading}
					pagesCount={pagesCount}
					pageSearch={pageSearch}
					search={search}
					services={services}
					userHasPickedDate={!!date}
					onBusinessHover={onBusinessHover}
				/>
				{!date ? (
					<Pagination
						currentPage={pageSearch}
						getPageURL={page =>
							routes.catchAll({
								search: {
									...search,
									page
								}
							})
						}
						pagesCount={pagesCount}
						setPageSearch={setPageSearch}
					/>
				) : (
					<PaginationInfinite
						currentPage={pageSearch}
						isLoading={isLoading}
						loadNextPage={setPageSearch}
						maxItemsWithoutAutoScroll={50}
						nbItems={businesses.length}
						pagesCount={pagesCount}
					/>
				)}
			</div>
		);
	}
	return <NoResults />;
});

const styles = {
	spinner: {
		paddingTop: '1em',
		display: 'flex',
		justifyContent: 'center'
	}
};
