import { useTranslation } from '@planity/localization';

export function Localize({ text, args, children }) {
	const { t } = useTranslation();
	if (children && typeof children === 'function') {
		return children(t(text, args));
	} else {
		return t(text, args) || '';
	}
}
