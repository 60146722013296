// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".business_tags-module_container-RGWy9 .business_tags-module_tags-f6xa4{display:flex;flex-wrap:wrap;overflow:auto;gap:8px}.business_tags-module_container-RGWy9 .business_tags-module_tags-f6xa4 .business_tags-module_tag-\\+NK7A{font:var(--regular) var(--body-4);color:var(--grey-700);padding:8px 16px;border:1px var(--grey-300) solid;border-radius:50px}", "",{"version":3,"sources":["webpack://./../../packages/ui/components/business/business_tags/business_tags.module.scss"],"names":[],"mappings":"AAGC,uEACC,YAAA,CACA,cAAA,CACA,aAAA,CACA,OAAA,CAEA,wGACC,iCAAA,CACA,qBAAA,CACA,gBAAA,CACA,gCAAA,CACA,kBAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.container {\n\t.tags {\n\t\tdisplay: flex;\n\t\tflex-wrap: wrap;\n\t\toverflow: auto;\n\t\tgap: 8px;\n\n\t\t.tag {\n\t\t\tfont: var(--regular) var(--body-4);\n\t\t\tcolor: var(--grey-700);\n\t\t\tpadding: 8px 16px;\n\t\t\tborder: 1px var(--grey-300) solid;\n\t\t\tborder-radius: 50px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"container": "business_tags-module_container-RGWy9",
	"tags": "business_tags-module_tags-f6xa4",
	"tag": "business_tags-module_tag-+NK7A"
};
module.exports = ___CSS_LOADER_EXPORT___;
