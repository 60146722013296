// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stars-module_stars-Ncp9X{display:flex;align-items:center;justify-content:center}.stars-module_stars-Ncp9X.stars-module_withMargin-xH2tm{margin-left:6px}.stars-module_stars-Ncp9X.stars-module_withMargin-xH2tm:first-of-type{margin-left:0}.stars-module_stars-Ncp9X .stars-module_isEmpty-d\\+66X{color:var(--grey-400)}", "",{"version":3,"sources":["webpack://./../../packages/components/stars/stars.module.scss"],"names":[],"mappings":"AAEA,0BACC,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,wDACC,eAAA,CACA,sEACC,aAAA,CAGF,uDACC,qBAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.stars {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\t&.withMargin {\n\t\tmargin-left: 6px;\n\t\t&:first-of-type {\n\t\t\tmargin-left: 0;\n\t\t}\n\t}\n\t.isEmpty {\n\t\tcolor: var(--grey-400);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"stars": "stars-module_stars-Ncp9X",
	"withMargin": "stars-module_withMargin-xH2tm",
	"isEmpty": "stars-module_isEmpty-d+66X"
};
module.exports = ___CSS_LOADER_EXPORT___;
