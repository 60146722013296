// @ts-check
import React, {
	createContext,
	useEffect,
	useReducer,
	useMemo,
	useContext
} from 'react';
import {
	CLEAN,
	DATE,
	filtersdDefaultState,
	PUSH,
	setTagInHotjar,
	SORT
} from '@planity/helpers';
import { withSearch } from '@planity/context';

export const FilterContext = createContext(null);

export const useFilters = () => useContext(FilterContext);

const FilterProvider = withSearch(
	({
		children,
		parentCategory,
		category,
		parentPlace,
		place,
		googlePlace,
		userLocation
	}) => {
		const [filters, dispatch] = useReducer(filterReducer, filtersdDefaultState);
		const state = useMemo(
			() => ({
				filters,
				dispatch
			}),
			[filters]
		);
		useEffect(() => {
			if (filters && filters.byDate > 0) {
				setTagInHotjar('SearchByDate');
			}
		}, [filters]);
		useEffect(() => {
			if (
				(!parentCategory && !category) ||
				(!parentPlace && !place && !googlePlace && !userLocation)
			) {
				dispatch({ type: CLEAN });
			}
		}, [
			parentCategory,
			category,
			parentPlace,
			place,
			googlePlace,
			userLocation
		]);

		return (
			<FilterContext.Provider value={state}>{children}</FilterContext.Provider>
		);
	}
);

const filterReducer = (state, { type, id, date, payload }) => {
	switch (type) {
		case SORT:
			return { ...state, sort: id };
		case DATE:
			return { ...state, byDate: id, data: { date } };
		case CLEAN:
			return filtersdDefaultState;
		case PUSH:
			return { ...payload };
		default:
			return state;
	}
};

export const withFilter = Component => props =>
	(
		<FilterContext.Consumer>
			{({ filters }) => <Component userFilter={filters} {...props} />}
		</FilterContext.Consumer>
	);

export default FilterProvider;
