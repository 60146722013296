import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import credentials from '@planity/credentials';
import { isMobileSafari } from '@planity/helpers';
/*
 * This should be refactored, and we should not use "compat" modules.
 * For instance I just need to upgrade firebase, just in case it solves our bug
 * https://firebase.google.com/docs/web/modular-upgrade
 *
 */
if (process.env.NODE_ENV === 'development') {
	if (process.env.BROWSER) {
		window.firebase = firebase;
	}
}
firebase.initializeApp({
	apiKey: credentials.FIREBASE_API_KEY,
	authDomain: credentials.FIREBASE_AUTH_DOMAIN,
	databaseURL: credentials.FIREBASE_DATABASE_URL,
	storageBucket: credentials.FIREBASE_STORAGE_BUCKET
});

export default firebase;

export const masterShard = firebase;

export const availabilitiesShards = {
	1: firebase.initializeApp(
		{
			apiKey: credentials.FIREBASE_API_KEY,
			authDomain: credentials.FIREBASE_AUTH_DOMAIN,
			databaseURL: credentials.FIREBASE_AVAILABILITIES_SHARD_1_URL,
			storageBucket: credentials.FIREBASE_STORAGE_BUCKET
		},
		'availabilitiesShard-1'
	),
	2: firebase.initializeApp(
		{
			apiKey: credentials.FIREBASE_API_KEY,
			authDomain: credentials.FIREBASE_AUTH_DOMAIN,
			databaseURL: credentials.FIREBASE_AVAILABILITIES_SHARD_2_URL,
			storageBucket: credentials.FIREBASE_STORAGE_BUCKET
		},
		'availabilitiesShard-2'
	)
};

export const publicShard = firebase.initializeApp(
	{
		apiKey: credentials.FIREBASE_API_KEY,
		authDomain: credentials.FIREBASE_AUTH_DOMAIN,
		databaseURL: credentials.FIREBASE_PUBLIC_SHARD_URL,
		storageBucket: credentials.FIREBASE_STORAGE_BUCKET
	},
	'publicShard'
);

if (typeof window !== 'undefined') {
	if (isMobileSafari()) {
		console.log(
			'%cSAFARI MOBILE DETECTED',
			'background:black;color:white;font-weight:bold'
		);
		window.onfocus = () => {
			for (const app of firebase.apps) {
				app.database().goOffline();
				setTimeout(() => {
					app.database().goOnline();
				}, 250);
			}
		};
	}
}
