import { useLocalizedRoutes } from '@planity/localization';
import { Logo } from '@planity/ui';
import styles from './error_header.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { Link } from 'react-router-dom';

export function ErrorHeader() {
	useStyles(styles);
	const { routes } = useLocalizedRoutes();

	const classes = classNames.bind(styles);
	return (
		<header
			className={classes({
				header: true
			})}
		>
			<div
				className={classes({
					container: true
				})}
			>
				<Link
					className={classes({
						logo: true
					})}
					to={routes.home}
				>
					<Logo className={styles.logoImage} id={'nav-item-planity-logo'} />
				</Link>
			</div>
		</header>
	);
}
