import { useAppointmentSource, useAuth } from '@planity/components';
import { useGoalEvents } from '@planity/helpers/analytics';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createAuthMachine } from './auth_machine';
import { useMachine } from '@xstate/react';
import { WRONG_PASSWORD_ERROR } from './errors';
import { safeRead } from '@planity/helpers';
import { firebase } from '@planity/datastores';
import {
	CHECK_SUBSCRIPTION_STEP,
	EMAIL_CHANGE,
	FIRST_NAME_CHANGE,
	LAST_NAME_CHANGE,
	PHONE_NUMBER_CHANGE
} from './auth_actions';
import { formatPhoneNumber } from '@planity/helpers';
import { useLocalization } from '@planity/localization';

const IMAGES_URLS = [
	'https://res.cloudinary.com/planity/image/upload/v1701340648/portail/illustrations/LOGIN/2023/1.jpg',
	'https://res.cloudinary.com/planity/image/upload/v1701340648/portail/illustrations/LOGIN/2023/2.jpg',
	'https://res.cloudinary.com/planity/image/upload/v1701340648/portail/illustrations/LOGIN/2023/3.jpg',
	'https://res.cloudinary.com/planity/image/upload/v1701340649/portail/illustrations/LOGIN/2023/4.jpg'
];

export const SIGN_IN = 'SIGN_IN';
/** Sign up authentication type */
export const SIGN_UP = 'SIGN_UP';
/** Forgotten password authentication type */
export const FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD';
const PENDING_STATES = [
	'signingInWithGoogleAndMergeAccount',
	'checkingPasswordInput',
	'getCredentials',
	'mergingEmailAndPasswordWithProvider',
	'checkingUserDataEdition',
	'checkingPhoneNumberConfirmation',
	'creatingUserInDatabase',
	'checkingUserData',
	'checkingProData',
	'fetchingDifferentAccounts',
	'signingInWithEmailAndPassword',
	'signingInWithFacebook',
	'signingInWithGithub',
	'signingInWithGoogle',
	'checkingSignUpWithEmailAndPasswordInputs',
	'signingUpWithEmailAndPassword',
	'setTemporaryUserInDatabase',
	'checkingIsDev',
	'sendingPasswordResetEmail',
	'updatingUserInDatabase',
	'checkingSignInWithEmailAndPasswordInputs',
	'checkSignUpWithEmailAndPasswordInputs',
	'checkSendingPasswordResetEmailInputs'
];

export const useAuthHooks = props => {
	const {
		initialSignInWithError,
		fromAccount,
		skipPasswordConfirmation,
		defaultCountry,
		skipVerificationSMS,
		onSignUp,
		children,
		requireIsDev,
		hideLog,
		scrollOnError,
		authBooking
	} = props;

	const { changeIsNewUser } = useAppointmentSource();
	const { publishUserSignedUp } = useGoalEvents() || {};
	const { user, userId, isVerified } = useAuth();

	// That's enough randomness
	const imageUrl = useMemo(
		() => IMAGES_URLS[Math.floor(Math.random() * IMAGES_URLS.length)],
		[]
	);
	const { locale, countryCode } = useLocalization();
	const defaultPhoneInputCountry = defaultCountry || countryCode;
	const initialAuthMachineParams = {
		email:
			(user && user.email) || safeRead(initialSignInWithError, ['email']) || '',
		phoneNumber: user && user.phone,
		lastName: user && user.lastName,
		firstName: user && user.firstName,
		userId,
		password: safeRead(initialSignInWithError, ['password']) || '',
		error: initialSignInWithError ? WRONG_PASSWORD_ERROR : undefined,
		errors: initialSignInWithError
			? [{ name: 'email', message: WRONG_PASSWORD_ERROR }]
			: undefined,
		skipPasswordConfirmation: !!skipPasswordConfirmation,
		skipVerificationSMS: !!skipVerificationSMS,
		publishUserSignedUp: publishUserSignedUp,
		onSignUp,
		requireIsDev,
		hideLog,
		locale
	};
	const isDesktop = window.matchMedia('(min-width: 800px)').matches;
	const machine = useMemo(
		() => createAuthMachine(initialAuthMachineParams),
		[]
	);
	const [current, dispatch, machineService] = useMachine(machine, {
		devTools: true
	});

	const { context, value: machineState } = current;

	const { error, emailHasNotBeenShared, redirection } = context;
	const [pending, setIsPending] = useState(false);
	const [authType, setAuthType] = useState(
		authBooking ? 'AUTH_BOOKING' : SIGN_IN
	);

	const navigateLogState = state => {
		let type = SIGN_IN;
		if (state === 'signup') {
			type = SIGN_UP;
		} else if (state === 'reset') {
			type = FORGOTTEN_PASSWORD;
		}
		dispatch({ type: 'RESET_AUTH_ERRORS' });
		handleChangeAuthType(type);
	};

	const handleChangeAuthType = useCallback(type => {
		setAuthType(type);
	}, []);

	useEffect(() => {
		if (redirection === SIGN_IN) setAuthType(SIGN_IN);
		if (redirection === FORGOTTEN_PASSWORD) setAuthType(SIGN_IN);
		if (redirection === SIGN_UP) setAuthType(SIGN_UP);
	}, [redirection]);

	useEffect(() => {
		setIsPending(PENDING_STATES.includes(machineState));
	}, [machineState]);

	useEffect(() => {
		if (error && scrollOnError) window.scrollTo(0, 0);
	}, [error]);

	useEffect(() => {
		if (!context.fromSMSVerificationModalClosing) {
			dispatch({
				type: EMAIL_CHANGE,
				email: user ? user.email : ''
			});
			!isVerified &&
				dispatch({
					type: PHONE_NUMBER_CHANGE,
					phoneNumber: user ? user.phone : ''
				});
		}

		dispatch({
			type: FIRST_NAME_CHANGE,
			phoneNumber: user ? user.firstName : ''
		});
		dispatch({
			type: LAST_NAME_CHANGE,
			phoneNumber: user ? user.lastName : ''
		});
		if (
			user &&
			user.email &&
			user.phone &&
			!context.fromSMSVerificationModalClosing
		) {
			dispatch({
				type: CHECK_SUBSCRIPTION_STEP
			});
		}
	}, [JSON.stringify(user), context?.fromSMSVerificationModalClosing]);

	useEffect(() => {
		if (!fromAccount) {
			if (authType) {
				if (authType === SIGN_IN) {
					changeIsNewUser && changeIsNewUser(false);
				}
				if (authType === SIGN_UP) {
					changeIsNewUser && changeIsNewUser(true);
				}
			}
		}
	}, [authType]);
	const formValues = {
		...props,
		authType,
		handleChangeAuthType,
		setIsPending,
		setAuthType,
		navigateLogState,
		isDesktop,
		pending,
		children,
		machineService,
		machineState,
		defaultCountry: defaultPhoneInputCountry,
		emailHasNotBeenShared
	};

	const handlePhoneChange = ({ newPhoneNumber, userId, dispatch }) => {
		dispatch({ type: PHONE_NUMBER_CHANGE, phoneNumber: newPhoneNumber });
		return firebase
			.database()
			.ref(`users/${userId}`)
			.update({
				phone: newPhoneNumber,
				phoneFormatted: formatPhoneNumber(newPhoneNumber, 'International')
			});
	};

	return {
		handlePhoneChange,
		formValues,
		context,
		machineState,
		dispatch,
		pending,
		navigateLogState,
		imageUrl
	};
};
