import React from 'react';

export const AU = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5543)'>
				<mask
					id='mask0_11534_5543'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5543)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0H20V15H0V0Z'
						fill='#2E42A5'
					/>
					<rect width='11' height='9' fill='#2E42A5' />
					<mask
						id='mask1_11534_5543'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='11'
						height='9'
					>
						<rect width='11' height='9' fill='white' />
					</mask>
					<g mask='url(#mask1_11534_5543)'>
						<path
							d='M-1.25267 8.12488L1.22292 9.21063L11.3062 1.18046L12.612 -0.432999L9.9647 -0.795888L5.85198 2.66454L2.54167 4.99607L-1.25267 8.12488Z'
							fill='#F7FCFF'
						/>
						<path
							d='M-0.913757 8.88555L0.347444 9.51562L12.1431 -0.582947H10.3722L-0.913757 8.88555Z'
							fill='#F50100'
						/>
						<path
							d='M12.5027 8.12487L10.3056 9.52057L-0.0561714 1.18044L-1.36199 -0.433015L1.2853 -0.795905L5.39802 2.66453L8.70833 4.99605L12.5027 8.12487Z'
							fill='#F7FCFF'
						/>
						<path
							d='M12.4182 8.67082L11.157 9.3009L6.13429 4.97718L4.64517 4.4941L-1.48764 -0.427482H0.283231L6.4126 4.37728L8.04069 4.95655L12.4182 8.67082Z'
							fill='#F50100'
						/>
						<mask
							id='path-11-outside-1_11534_5543'
							maskUnits='userSpaceOnUse'
							x='-1'
							y='-1'
							width='13'
							height='11'
							fill='black'
						>
							<rect fill='white' x='-1' y='-1' width='13' height='11' />
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M6 0H5V4H0V5H5V9H6V5H11V4H6V0Z'
							/>
						</mask>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M6 0H5V4H0V5H5V9H6V5H11V4H6V0Z'
							fill='#F50100'
						/>
						<path
							d='M5 0V-0.9375H4.0625V0H5ZM6 0H6.9375V-0.9375H6V0ZM5 4V4.9375H5.9375V4H5ZM0 4V3.0625H-0.9375V4H0ZM0 5H-0.9375V5.9375H0V5ZM5 5H5.9375V4.0625H5V5ZM5 9H4.0625V9.9375H5V9ZM6 9V9.9375H6.9375V9H6ZM6 5V4.0625H5.0625V5H6ZM11 5V5.9375H11.9375V5H11ZM11 4H11.9375V3.0625H11V4ZM6 4H5.0625V4.9375H6V4ZM5 0.9375H6V-0.9375H5V0.9375ZM5.9375 4V0H4.0625V4H5.9375ZM0 4.9375H5V3.0625H0V4.9375ZM0.9375 5V4H-0.9375V5H0.9375ZM5 4.0625H0V5.9375H5V4.0625ZM5.9375 9V5H4.0625V9H5.9375ZM6 8.0625H5V9.9375H6V8.0625ZM5.0625 5V9H6.9375V5H5.0625ZM11 4.0625H6V5.9375H11V4.0625ZM10.0625 4V5H11.9375V4H10.0625ZM6 4.9375H11V3.0625H6V4.9375ZM5.0625 0V4H6.9375V0H5.0625Z'
							fill='#F7FCFF'
							mask='url(#path-11-outside-1_11534_5543)'
						/>
					</g>
				</g>
				<g clipPath='url(#clip1_11534_5543)'>
					<path
						d='M5.92 12.2784L5.09996 13.0362L5.18117 11.9226L4.07739 11.7539L4.99869 11.1231L4.44234 10.155L5.50998 10.4819L5.92 9.44336L6.33002 10.4819L7.39766 10.155L6.84131 11.1231L7.76261 11.7539L6.65883 11.9226L6.74004 13.0362L5.92 12.2784Z'
						fill='#F7FCFF'
					/>
					<path
						d='M13.24 8.29668L12.6933 8.8019L12.7474 8.05948L12.0116 7.94705L12.6258 7.52649L12.2549 6.88108L12.9666 7.09907L13.24 6.40668L13.5133 7.09907L14.2251 6.88108L13.8542 7.52649L14.4684 7.94705L13.7325 8.05948L13.7867 8.8019L13.24 8.29668Z'
						fill='#F7FCFF'
					/>
					<path
						d='M14.52 2.9633L13.9733 3.46852L14.0274 2.7261L13.2916 2.61368L13.9058 2.19311L13.5349 1.54771L14.2466 1.76569L14.52 1.0733L14.7933 1.76569L15.5051 1.54771L15.1342 2.19311L15.7484 2.61368L15.0125 2.7261L15.0667 3.46852L14.52 2.9633Z'
						fill='#F7FCFF'
					/>
					<path
						d='M17.72 5.62999L17.1733 6.13521L17.2275 5.39279L16.4916 5.28037L17.1058 4.8598L16.7349 4.21439L17.4467 4.43238L17.72 3.73999L17.9934 4.43238L18.7051 4.21439L18.3342 4.8598L18.9484 5.28037L18.2126 5.39279L18.2667 6.13521L17.72 5.62999Z'
						fill='#F7FCFF'
					/>
					<path
						d='M15.16 13.2967L14.6133 13.8019L14.6674 13.0595L13.9316 12.9471L14.5458 12.5265L14.1749 11.8811L14.8866 12.0991L15.16 11.4067L15.4333 12.0991L16.1451 11.8811L15.7742 12.5265L16.3884 12.9471L15.6525 13.0595L15.7067 13.8019L15.16 13.2967Z'
						fill='#F7FCFF'
					/>
					<path
						d='M17.4 9.13918L16.8445 9.4312L16.9506 8.81269L16.5013 8.37466L17.1223 8.28442L17.4 7.72168L17.6777 8.28442L18.2988 8.37466L17.8494 8.81269L17.9555 9.4312L17.4 9.13918Z'
						fill='#F7FCFF'
					/>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5543'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
				<clipPath id='clip1_11534_5543'>
					<rect
						width='15'
						height='13'
						fill='white'
						transform='translate(4 1)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
