import React from 'react';
import classNames from 'classnames/bind';
import { BusinessInfo, Slideshow } from '@planity/ui';
import styles from './business_item_search.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Address } from '@planity/components';
import SymbolEuro from '@planity/components/symbol_euro';
import { formatRating } from '@planity/helpers';

export function BusinessItemSearch({
	className,
	images = [],
	transformations,
	business,
	availabilities,
	showReviews,
	hasFooterContainer,
	isDirectory,
	URL
}) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		business: true,
		hasFooterContainer: hasFooterContainer,
		[className]: className !== undefined,
		isDirectory: isDirectory
	});

	const globalRatingValue =
		business.globalRating ||
		(business['reviews'] &&
			(business['reviews'] || []).reduce(
				(all, iterator) => all + iterator['reviewRating'],
				0
			) / 3) ||
		0;
	const globalRating =
		globalRatingValue % 1 !== 0
			? formatRating(globalRatingValue)
			: globalRatingValue;
	const ratingsCount = business['ratingsCount'] || 0;

	return (
		<div className={classes}>
			{!isDirectory && (
				<Slideshow images={images} transformations={transformations} showDots />
			)}
			<div className={`${styles.infos} ${isDirectory && styles.isDirectory}`}>
				<div>
					<a className={styles.title} href={URL}>
						{business.name}
					</a>
					<BusinessInfo
						address={
							<Address
								isSearch={true}
								{...business.address}
								className={styles.address}
								distance={business?._rankingInfo?.geoDistance}
							/>
						}
						count={ratingsCount}
						note={showReviews && globalRating}
						average={<SymbolEuro rating={business?.globalAverage} />}
						isSearch
					/>
				</div>

				{availabilities}
			</div>
		</div>
	);
}
