import { Spinner } from '@planity/ui';
import { Redirect, useLocation } from 'react-router-dom';
import { useFirebaseSubscription } from '@planity/datastores';
import styles from './webview_redirections.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { UnknownURLPage } from '../unknown_url_page';

export const WebviewBusinessOldSlugRedirection = ({ businessId }) => {
	const location = useLocation();
	useStyles(styles);

	const [newSlug, , isLoading] = useFirebaseSubscription({
		path: `businesses/${businessId}/slug`,
		shard: 'public'
	});

	if (isLoading) {
		return (
			<div className={styles.spinner}>
				<Spinner />
			</div>
		);
	}
	if (newSlug) {
		return <Redirect to={`/${newSlug}${location.search || ''}`} />;
	} else {
		return <UnknownURLPage />;
	}
};
