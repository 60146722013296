import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './flag.module.scss';
import * as library from '@planity/ui/assets/flags';
import classNames from 'classnames/bind';

export function Flag({ country = 'FR', className }) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		icon: true,
		[className]: className !== undefined
	});

	const RenderedIcon = library[country];
	if (process.env.NODE_ENV === 'development') {
		if (!Flag) {
			console.warn('Icon type not known : ' + country);
		}
	}
	if (!RenderedIcon) {
		return null;
	}
	return (
		<span className={classes}>
			<RenderedIcon />
		</span>
	);
}
