import React, { useCallback, useMemo } from 'react';
import { useUserQueries } from '@planity/components';
import { checkIfIsBookable, getDateFromString } from '@planity/helpers';
import { prepareSequence } from '@planity/helpers';
import { BusinessAppointments } from '@planity/ui';
import { parseISO } from 'date-fns';
import { useLocalizedRoutes } from '@planity/localization';
import { useHistory } from 'react-router-dom';

const NOW = new Date().getTime();

// This is definitely not "Past" appointments... But just appointment
export const PastAppointments = ({ businessId, business }) => {
	const { userQueries } = useUserQueries();
	const { routes } = useLocalizedRoutes();
	const history = useHistory();

	const linkToBookAppointment = useCallback(
		({ business, sequence }) =>
			history.push(
				routes.catchAll({
					reservation: { business },
					state: {
						sequence: Object.keys(sequence || {}).map(
							stepId => sequence[stepId]
						),
						business
					}
				})
			),
		[history, routes]
	);
	const formattedAppointments = useMemo(
		() =>
			Object.entries(userQueries[businessId]?.vevents || {})
				.map(([veventId, vevent]) => {
					const preparedApp = prepareSequence(vevent);

					const appointmentStart = parseISO(vevent.start);
					const canBeCancelled =
						!!vevent.start && appointmentStart > new Date();

					return {
						appointment: vevent,
						preparedSequence: preparedApp.sequence,
						veventId,
						isBookable: checkIfIsBookable({
							sequence: preparedApp.sequence,
							business
						}),
						business,
						appointmentStart,
						linkToBookAppointment,
						isBusiness: true,
						start: vevent.start,
						canBeCancelled,
						...vevent
					};
				})
				.sort((x, y) => {
					const xStart = getDateFromString(x.start);
					const yStart = getDateFromString(y.start);

					/*
					 * Compares two dates and sort them by :
					 *  - First the futur appointments, in chronological order
					 *  - Then the past appointments, in reverse chronological order
					 */

					if (xStart >= NOW && yStart >= NOW) {
						return xStart - yStart;
					} else {
						return yStart - xStart;
					}
				}),
		[userQueries, business]
	);

	if (!formattedAppointments?.length) return null;
	return (
		<BusinessAppointments items={(formattedAppointments || []).slice(0, 3)} />
	);
};
