// @ts-check
import { isNativeApp } from '@planity/webview';
import React, { Component } from 'react';
import {
	OnlinePaymentContextConsumer,
	OnlinePaymentContextProvider,
	OnlinePaymentDefaultContextData,
	invokeLambda,
	PAYMENT_TYPE_CARD
} from '@planity/helpers';
import { firebase } from '@planity/datastores';
import { getStripeCustomerNode } from '@planity/helpers';
import { format } from 'date-fns';
import debounce from 'lodash/debounce';
import credentials from '@planity/credentials';
import { withLocalization } from '@planity/localization';
import { doesPaymentMethodNeedConsent } from './does_payment_method_need_consent';

const { ENABLE_USER_PAYS_FEES } = credentials;

export const OnlinePaymentProvider = withLocalization(
	class OnlinePaymentProvider extends Component {
		static defaultProps = {
			initialState: {
				...OnlinePaymentDefaultContextData
			}
		};

		constructor(props) {
			super(props);
			const { initialState } = this.props;
			this.state = {
				...initialState,
				getPaymentMethods: this.getPaymentMethods,
				setStripeCustomerId: this.setStripeCustomerId,
				makeDefault: this.makeDefault,
				deleteCard: this.deleteCard,
				attachPaymentMethod: this.attachPaymentMethod,
				setSelectedPaymentMethod: this.setSelectedPaymentMethod,
				setPaymentMethods: this.setPaymentMethods,
				getCustomerStripe: this.getCustomerStripe,
				resetPaymentMethodDatas: this.resetPaymentMethodDatas,
				getNewIntent: this.getNewIntent,
				getSpecificPaymentMethod: this.getSpecificPaymentMethod,
				setNewPaymentMethodFromElement: this.setNewPaymentMethodFromElement,
				resetPaymentIntent: this.resetPaymentIntent,
				updatePaymentIntent: this.updatePaymentIntent,
				updateSaveCardInIntent: this.updateSaveCardInIntent,
				getRedirectUrl: this.getRedirectUrl,
				setPaymentMethodType: this.setPaymentMethodType,
				updateCardAcceptance: this.updateCardAcceptance,
				getConsentedPaymentMethods: this.getConsentedPaymentMethods,
				updateConsentPaymentMethod: this.updateConsentPaymentMethod,
				setIsSelectedPaymentLinkedToActiveAppointments:
					this.setIsSelectedPaymentLinkedToActiveAppointments
			};
		}

		render() {
			const { children } = this.props;
			return (
				<OnlinePaymentContextProvider value={{ ...this.state }}>
					{children}
				</OnlinePaymentContextProvider>
			);
		}

		updateCardAcceptance = hasAcceptedSaveCard => {
			this.setState({ hasAcceptedSaveCard });
		};

		setIsSelectedPaymentLinkedToActiveAppointments =
			isSelectedPaymentLinkedToActiveAppointments => {
				this.setState({ isSelectedPaymentLinkedToActiveAppointments });
			};

		getCustomerStripe = ({
			userId,
			user,
			countryCode,
			createCustomer = true,
			paymentMethod = null
		}) => {
			const stripeRelationCustomerNode = `stripe_relations/${getStripeCustomerNode(
				countryCode
			)}/${userId}`;
			firebase
				.database()
				.ref(stripeRelationCustomerNode)
				.once('value')
				.then(snapshot => {
					if (snapshot.val()) {
						//Customer exist
						this.setStripeCustomerId(
							snapshot.val(),
							countryCode,
							paymentMethod
						);
					} else {
						if (createCustomer) {
							//Customer doesn't exist, create in Stripe
							firebase
								.auth()
								.currentUser.getIdToken()
								.then(userToken => {
									invokeLambda('onlinePayment', {
										firebaseToken: userToken,
										actions: {
											type: 'customer.create',
											name: user.name,
											email: firebase.auth().currentUser.email,
											id: userId,
											locale: this.props.locale,
											countryCode
										}
									})
										.then(response => {
											this.setStripeCustomerId(
												response.response.id,
												countryCode,
												paymentMethod
											);
										})
										.catch(error => {
											console.warn(error);
										});
								});
						} else {
							this.setStripeCustomerId(null, countryCode);
						}
					}
				})
				.catch(e => {
					console.warn(e);
				});
		};

		getRedirectUrl = async ({
			childId,
			intentID,
			isPro,
			amount,
			appointment,
			user,
			formattedAppointment,
			userPaysFees,
			userId,
			isGiftVoucher,
			isPrePayment,
			isDeposit,
			businessId,
			business,
			redirectUrlRoot,
			redirectPathname,
			shippingFees,
			shippingExplanationSentence,
			steps,
			contextData,
			transactionType,
			veventToDelete,
			orderId,
			countryCode
		}) => {
			const isDevelopmentMode = process.env.NODE_ENV !== 'production';
			const userToken = await firebase.auth().currentUser.getIdToken(true); //refresh token to be able to get redirect data for a longer time
			return await invokeLambda('onlinePayment', {
				firebaseToken: userToken,
				actions: {
					type: 'customer.getRedirectUrl',
					childId,
					countryCode,
					isPro,
					amount,
					appointment,
					customerName: user && user.name,
					ENABLE_USER_PAYS_FEES,
					formattedAppointment,
					userPaysFees,
					userId,
					isGiftVoucher,
					isPrePayment,
					isDeposit,
					isWidget: process.env.WIDGET,
					isWhiteLabelWebsite: process.env.WHITE_LABEL_WEBSITE,
					isDevelopmentMode,
					businessId,
					businessSlug: business?.slug || null,
					paymentIntentId: intentID,
					redirectUrlRoot,
					redirectPathname,
					shippingFees,
					shippingExplanationSentence,
					steps,
					contextData,
					userToken,
					transactionType,
					veventToDelete,
					webviewVersion: isNativeApp ? window.RNVersion : undefined,
					orderId
				}
			});
		};

		getNewIntent = ({
			isPaymentIntent,
			amount,
			customerID,
			intentData = {},
			countryCode
		}) => {
			firebase
				.auth()
				.currentUser.getIdToken()
				.then(userToken => {
					const {
						appointment,
						childId,
						childName,
						userPaysFees,
						formattedSteps,
						isPrePayment,
						isDeposit,
						user,
						businessId,
						userId,
						isGiftVoucher,
						transactionType,
						amountWithoutFees,
						totalAmount
					} = intentData;
					invokeLambda('onlinePayment', {
						firebaseToken: userToken,
						actions: {
							type: 'customer.getIntent',
							intentType: isPaymentIntent ? 'paymentIntent' : 'setupIntent',
							amount,
							amountWithoutFees,
							appointment,
							childId,
							childName,
							customerID,
							date: appointment
								? format(appointment.date, 'yyyy-MM-dd HH:mm')
								: null,
							newPaymentFlow: true,
							userPaysFees,
							isPrePayment,
							isDeposit,
							formattedSteps: formattedSteps && JSON.stringify(formattedSteps),
							isGiftVoucher,
							totalAmount,
							transactionType,
							customerName: user && user.name,
							businessId,
							userId,
							countryCode,
							userToken
						}
					})
						.then(async ({ response }) => {
							if (response) {
								this.setState({
									clientSecret: response.client_secret,
									intentID: response.id,
									paymentIntentAmount: response.amount,
									intentData,
									isPaymentIntentUpdating: false // if getNewIntent comes from updatePaymentIntent
								});
							}
						})
						.catch(error => {
							console.warn(error);
						});
				});
		};

		//reset specific fields only (if we want to keep selected payment method but not the payment intent infos)
		resetPaymentIntent = () => {
			this.setState({
				clientSecret: null,
				intentID: null,
				paymentIntentAmount: null,
				intentData: null,
				newPaymentMethodFromElement: null,
				// not sure
				paymentMethodType: PAYMENT_TYPE_CARD,
				isCurrentCardOnSession: false
			});
		};

		updateSaveCardInIntent = async (countryCode, businessId) => {
			const userToken = await firebase.auth().currentUser.getIdToken();
			const res = await invokeLambda('onlinePayment', {
				firebaseToken: userToken,
				actions: {
					type: 'customer.updateIntentData',
					paymentIntentId: this.state.intentID,
					hasAcceptedSaveCard: this.state.hasAcceptedSaveCard,
					countryCode,
					businessId
				}
			});
			this.setState({
				isCurrentCardOnSession:
					res?.payment_method_options?.card?.setup_future_usage === 'on_session'
			});
		};

		updatePaymentIntent = ({
			intentID,
			amount,
			application_fee_amount,
			metadata,
			countryCode,
			businessId
		}) => {
			this.setState({ isPaymentIntentUpdating: true }, () =>
				this.debouncedUpdatePaymentIntent({
					intentID,
					amount,
					application_fee_amount,
					metadata,
					countryCode,
					businessId
				})
			);
		};

		debouncedUpdatePaymentIntent = debounce(
			async ({
				intentID,
				amount,
				application_fee_amount,
				metadata = {},
				countryCode,
				businessId
			}) => {
				const newIntentData = { ...this.state.intentData, ...metadata };

				const userToken = await firebase.auth().currentUser.getIdToken();

				const response = await invokeLambda('onlinePayment', {
					firebaseToken: userToken,
					actions: {
						type: 'customer.updateIntentData',
						paymentIntentId: intentID,
						amount,
						application_fee_amount,
						metadata: newIntentData,
						countryCode,
						businessId
					}
				});

				if (response.errorMessage) {
					// if we can't update we create a new payment intent (best way to handle this error case)
					this.getNewIntent({
						isPaymentIntent: true,
						amount: amount || this.state.paymentIntentAmount,
						customerID: this.state.customerID,
						intentData: newIntentData,
						countryCode
					});
				} else {
					this.setState({
						paymentIntentAmount: response?.amount,
						intentData: newIntentData,
						isPaymentIntentUpdating: false
					});
				}
			},
			1500
		);

		setStripeCustomerId = (customerID, countryCode, paymentMethod = null) => {
			if (this.state.customerID !== customerID) {
				this.setState(
					{
						customerID
					},
					() => {
						if (customerID != null) {
							this.getPaymentMethods(customerID, countryCode, paymentMethod);
						}
					}
				);
			}
		};

		resetPaymentMethodDatas = () => {
			this.setState({
				...this.props.initialState
			});
		};

		getPaymentMethods = (customerID, countryCode, paymentMethod = null) => {
			firebase
				.auth()
				.currentUser.getIdToken()
				.then(userToken => {
					invokeLambda('onlinePayment', {
						firebaseToken: userToken,
						actions: {
							type: 'customer.getPaymentMethod',
							customerID: customerID,
							countryCode
						}
					})
						.then(response => {
							if (response.response) {
								this.setPaymentMethods(response.response, null, paymentMethod);
							}
						})
						.catch(error => {
							console.warn(error);
						});
				});
		};

		getSpecificPaymentMethod = ({ paymentMethodId, countryCode }) => {
			firebase
				.auth()
				.currentUser.getIdToken()
				.then(userToken => {
					invokeLambda('onlinePayment', {
						firebaseToken: userToken,
						actions: {
							type: 'customer.getSpecificPaymentMethod',
							paymentMethodId,
							countryCode
						}
					})
						.then(response => {
							if (response.response) {
								this.setNewPaymentMethodFromElement(
									response.response.paymentMethod
								);
							}
						})
						.catch(error => {
							console.warn(error);
						});
				});
		};

		setSelectedPaymentMethod = selectedPaymentMethod => {
			this.setState({
				selectedPaymentMethod,
				hasAcceptedSaveCard: null,
				doesSelectedPaymentMethodNeedConsent: selectedPaymentMethod
					? doesPaymentMethodNeedConsent(
							selectedPaymentMethod,
							this.state.consentedPaymentMethods
					  )
					: null
			});
		};

		setNewPaymentMethodFromElement = newPaymentMethodFromElement => {
			this.setState({ newPaymentMethodFromElement });
		};

		setPaymentMethodType = newPaymentMethodType => {
			this.setState({ paymentMethodType: newPaymentMethodType });
		};

		setPaymentMethods = (response, callback = false, paymentMethod = null) => {
			let defaultPaiementMethod = null;

			if (response.errorMessage) {
				throw response.errorMessage;
			}

			const sortedPaymentMethods = response.paymentMethods.data.reduce(
				(acc, element) => {
					if (
						element.id ===
						response.customer.invoice_settings.default_payment_method
					) {
						defaultPaiementMethod = element;
						return [element, ...acc];
					}
					return [...acc, element];
				},
				[]
			);

			const selectedPaymentMethod =
				paymentMethod || response.PaymentMethod || defaultPaiementMethod;

			this.getConsentedPaymentMethods(
				sortedPaymentMethods.map(({ id }) => id)
			).then(consentedCards => {
				this.setState(
					{
						paymentMethods: sortedPaymentMethods,
						selectedPaymentMethod,
						displayAddCard: response.paymentMethods.data.length === 0,
						defaultPaymentMethod: defaultPaiementMethod
							? defaultPaiementMethod.id
							: null,
						doesSelectedPaymentMethodNeedConsent: selectedPaymentMethod
							? doesPaymentMethodNeedConsent(
									selectedPaymentMethod,
									consentedCards
							  )
							: null,
						consentedPaymentMethods: consentedCards
					},
					() => {
						if (callback) {
							callback(response);
						}
					}
				);
			});
		};
		makeDefault = (paymentMethod, countryCode) => {
			firebase
				.auth()
				.currentUser.getIdToken()
				.then(userToken => {
					invokeLambda('onlinePayment', {
						firebaseToken: userToken,
						actions: {
							type: 'customer.makeDefault',
							paymentMethod: paymentMethod,
							countryCode
						}
					})
						.then(() => {
							this.setState({
								defaultPaymentMethod: paymentMethod.id
							});
						})
						.catch(error => {
							console.warn(error);
						});
				});
		};

		deleteCard = (paymentMethod, countryCode) => {
			firebase
				.auth()
				.currentUser.getIdToken()
				.then(userToken => {
					invokeLambda('onlinePayment', {
						firebaseToken: userToken,
						actions: {
							type: 'customer.deleteCard',
							paymentMethod,
							countryCode
						}
					})
						.then(response => {
							if (response.response) {
								this.setPaymentMethods(response.response);
							}
						})
						.catch(error => {
							console.warn(error);
						});
				});
		};

		attachPaymentMethod = (methodId, countryCode, userToken, callback) => {
			return invokeLambda('onlinePayment', {
				firebaseToken: userToken,
				actions: {
					type: 'customer.attachPaymentMethod',
					customerID: this.state.customerID,
					paiementMethodId: methodId,
					paiementMethodCount: this.state.paymentMethods.length,
					countryCode
				}
			})
				.then(response => {
					this.setPaymentMethods(response, callback);
				})
				.catch(error => {
					console.warn(error);
					throw error;
				});
		};

		getConsentedPaymentMethods = paymentIds => {
			if (paymentIds.length === 0) return Promise.resolve([]);
			return new Promise(resolve => {
				invokeLambda('handleSavedPaymentMethodConsent', {
					action: 'getConsentedPaymentMethods',
					paymentIds,
					customerId: this.state.customerID
				})
					.then(res => resolve(Array.isArray(res.body) ? res.body : []))
					.catch(() => {
						console.warn('error on getConsentedPaymentMethods');
						resolve([]);
					});
			});
		};

		updateConsentPaymentMethod = (countryCode, userId) => {
			firebase
				.auth()
				.currentUser.getIdToken()
				.then(firebaseToken => {
					invokeLambda('handleSavedPaymentMethodConsent', {
						action: 'updateConsentPaymentMethod',
						hasAcceptedSaveCard: this.state.hasAcceptedSaveCard,
						id: this.state.selectedPaymentMethod.id,
						customerId: this.state.selectedPaymentMethod.customer,
						stripePlatform: countryCode,
						userId,
						firebaseToken
					}).catch(() => {
						console.warn('could not updateConsentPaymentMethod');
					});
				});
		};
	}
);

export const OnlinePaymentConsumer = Component => props => {
	return (
		<OnlinePaymentContextConsumer>
			{state => <Component {...props} {...state} />}
		</OnlinePaymentContextConsumer>
	);
};
