import React, { forwardRef } from 'react';
import { breakpoints, colors } from '@planity/theme';
import {
	MAIN_WIDTH_DESKTOP,
	ASIDE_WIDTH_DESKTOP,
	MAIN_SIZE_LARGE_DESKTOP,
	GLOBAL_PADDING
} from '../../style';

const section = Tag =>
	forwardRef(({ className, children }, ref) => (
		<Tag css={[styles[Tag], className]} ref={ref}>
			{children}
		</Tag>
	));

export const Wrapper = section('div');
export const Section = section('section');
export const Main = section('main');
export const Aside = section('aside');

const styles = {
	div: {
		height: '100%',
		[breakpoints.desktopQuery]: {
			display: 'flex'
		}
	},
	section: {
		backgroundColor: colors.grey.light,
		padding: `0 ${GLOBAL_PADDING}px`,
		[breakpoints.desktopQuery]: {
			width: `${MAIN_WIDTH_DESKTOP}vw`,
			padding: 0
		},
		[breakpoints.largeDesktopQuery]: {
			width: `${MAIN_SIZE_LARGE_DESKTOP}px`,
			padding: 0
		},
		[breakpoints.tabletQuery]: {
			padding: 0
		},
		[breakpoints.mobile]: {
			padding: 0
		}
	},
	main: {},
	aside: {
		[breakpoints.desktopQuery]: {
			width: `${ASIDE_WIDTH_DESKTOP}vw`,
			paddingTop: 133
		},
		[breakpoints.largeDesktopQuery]: {
			width: `calc(100vw - ${MAIN_SIZE_LARGE_DESKTOP}px)`
		}
	}
};
