import React from 'react';
import { Localize } from '@planity/components';
import { Image, Logo } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './maintenance_page.module.scss';
import { useRandomImages } from '@planity/helpers/useRandomImages';

export function MaintenancePage() {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		maintenance: true
	});
	const imageUrl = useRandomImages();

	return (
		<div className={classes}>
			<div className={styles.columns}>
				<div className={styles.container}>
					<Logo />
					<h1 className={styles.text}>
						<Localize text={'nav.maintenanceText'} />
					</h1>
				</div>
				<div className={styles.image}>
					<Image fit='cover' isCloudinary url={imageUrl} />
				</div>
			</div>
		</div>
	);
}
