import React from 'react';

export const UY = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_6003)'>
				<mask
					id='mask0_11534_6003'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_6003)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#F7FCFF'
					/>
					<mask
						id='mask1_11534_6003'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_11534_6003)'>
						<rect y='2.5' width='20' height='1.25' fill='#2E42A5' />
						<rect y='5' width='20' height='1.25' fill='#2E42A5' />
						<rect y='7.5' width='20' height='1.25' fill='#2E42A5' />
						<rect y='10' width='20' height='1.25' fill='#2E42A5' />
						<rect y='12.5' width='20' height='1.25' fill='#2E42A5' />
						<rect width='10' height='8.75' fill='#F7FCFF' />
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M5.7672 6.89823C5.7672 6.89823 5.12852 8.22242 4.56718 8.49391C4.80947 7.88656 4.89866 6.64735 4.89866 6.64735C4.89866 6.64735 3.44121 7.22519 2.92966 7.11987C3.54478 6.69004 4.39858 5.8939 4.39858 5.8939C4.39858 5.8939 2.51219 5.27719 2.55847 5.01472C3.41001 5.16747 4.58109 5.00096 4.58109 5.00096C4.58109 5.00096 3.25772 3.41393 3.39192 3.29592C3.59364 3.49199 5.17961 4.32044 5.17961 4.32044C5.17961 4.32044 5.29467 2.90844 5.63599 2.43237C5.67693 2.76842 6.15599 4.2899 6.15599 4.2899C6.15599 4.2899 7.11904 3.32629 7.65357 3.32629C7.41878 3.61739 6.86285 4.89611 6.86285 4.89611C6.86285 4.89611 8.24835 4.8743 8.77506 5.13362C8.137 5.22437 7.01915 5.78557 7.01915 5.78557C7.01915 5.78557 8.47506 6.88996 8.34232 7.11987C7.56064 6.73749 6.65693 6.60942 6.65693 6.60942C6.65693 6.60942 6.90672 8.14634 6.7048 8.49391C6.50788 7.98228 5.7672 6.89823 5.7672 6.89823Z'
							fill='#FFD018'
							stroke='#F19900'
							strokeOpacity='0.98'
							strokeWidth='0.5'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M5.68346 6.18237C6.02863 6.18237 6.30846 5.90255 6.30846 5.55737C6.30846 5.2122 6.02863 4.93237 5.68346 4.93237C5.33828 4.93237 5.05846 5.2122 5.05846 5.55737C5.05846 5.90255 5.33828 6.18237 5.68346 6.18237Z'
							fill='#FFD018'
							stroke='#F19900'
							strokeOpacity='0.98'
							strokeWidth='0.5'
						/>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_6003'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
