import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
	filterGiftVoucher,
	formatPrice,
	getFixedPrice
} from '@planity/helpers';
import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';
import { withFormFactor } from '../provider';
import {
	GiftVoucherAnalyticContext,
	GiftVouchersEvents
} from '@planity/helpers/analytics';
import { ServiceSetDisplay } from './service_set_display';
import { BusinessTitle } from '@planity/ui';
import { Localize } from '../index';
import styles from './gift_voucher.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import { GIFT_VOUCHER_BLOC, useTheme } from '@planity/context/theme_context';

const GiftVoucher = withFormFactor(function GiftVoucher({
	services,
	business,
	selectedServ,
	giftVoucherPrc,
	totalPrc,
	selectedType,
	onSubmit,
	giftCards,
	isDark
}) {
	const ctx = useContext(GiftVoucherAnalyticContext);
	useEffect(() => {
		ctx.USER_STARTED_GIFT_VOUCHER_BUYING();
	}, []);

	const [typeSelected, setTypeSelected] = useState(selectedType || null);
	const [totalPrice, setTotalPrice] = useState(totalPrc || 0);
	const selectedServicesOnMount = clone(selectedServ) || [];
	const [selectedServices, setSelectedServices] = useState(
		selectedServicesOnMount
	);
	const giftVoucherPrice = clone(giftVoucherPrc) || 0;

	const setSelectedType = type => {
		setTypeSelected(type);
	};

	useEffect(() => {
		if (!isEqual(selectedServices, selectedServicesOnMount)) {
			ctx.USER_SELECTED_GIFT_VOUCHER();
			onSubmit({
				selectedServices,
				giftVoucherPrice,
				totalPrice: formatPrice(totalPrice),
				totalPriceInCents: totalPrice,
				typeSelected
			});
		}
	}, [selectedServices]);

	const roundOff = (num, places) => {
		const x = Math.pow(10, places);
		return Math.round(num * x) / x;
	};

	const onSelectService = service => {
		const price = getFixedPrice(service.prices);
		let newPrice = totalPrice;
		let selectedServicesTemp =
			typeSelected === 'service' ? selectedServices : [];

		newPrice += parseFloat(price.replace(',', '.'));
		selectedServicesTemp.push(service);

		setSelectedType('service');
		setTotalPrice(Math.round(newPrice * 100));
		setSelectedServices([...selectedServicesTemp]);
	};

	const onSelectGiftVoucher = voucher => {
		const price = voucher.defaultAmount;
		const isVariable = voucher.isVariableAmount;
		let newPrice = totalPrice;
		let selectedServicesTemp = [];

		if (selectedServices.length > 0) {
			//EMPTY SELECTED SERVICES
			selectedServicesTemp = [];
			newPrice = 0;
		}

		selectedServicesTemp.push(voucher);

		if (!isVariable) {
			newPrice += parseFloat(price);
		}

		setSelectedType('gift_card');
		setTotalPrice(newPrice);
		setSelectedServices([...selectedServicesTemp]);
	};

	const giftVouchers = useMemo(() => filterGiftVoucher(giftCards), [giftCards]);

	return (
		<ServiceSetDisplay
			business={business}
			giftVouchers={giftVouchers}
			isDark={isDark}
			selectedServices={selectedServices}
			servicesList={services}
			typeSelected={typeSelected}
			onSelectGiftVoucher={onSelectGiftVoucher}
			onSelectService={onSelectService}
		/>
	);
});

export default ({ ...props }) => {
	useStyles(styles);
	const cx = classNames.bind(styles);
	const { isDark: hasDarkBackground } = useTheme();
	const isDark = hasDarkBackground?.[GIFT_VOUCHER_BLOC];
	const classes = cx({
		giftVoucher: true,
		isDark
	});

	return (
		<div className={classes}>
			<GiftVouchersEvents>
				<div>
					<BusinessTitle
						Component={'h2'}
						isDark={isDark}
						path={'gift.modalTitle'}
					/>
					<span className={cx('subtitle', { isDark })}>
						<Localize text='myAccount.giftVouchers.selectWhatYouWantToGift' />
						<br />
						<Localize text='myAccount.giftVouchers.pdfExplain' />
					</span>
				</div>
				<GiftVoucher {...props} isDark={isDark} />
			</GiftVouchersEvents>
		</div>
	);
};
