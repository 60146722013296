import React from 'react';

export const CL = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5613)'>
				<mask
					id='mask0_11534_5613'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5613)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0H9V9H0V0Z'
						fill='#3D58DB'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M8.75 -1.25H20V8.75H8.75V-1.25Z'
						fill='#F7FCFF'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 8H20V15H0V8Z'
						fill='#E31D1C'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M4.38397 5.61457L2.00668 7.03425L3.1799 4.61872L1.10234 3.04984L3.50077 3.02416L4.40558 0.882141L4.98815 3.02416L7.2583 3.03486L5.53166 4.56934L6.42827 7.03425L4.38397 5.61457Z'
						fill='#F7FCFF'
					/>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5613'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
