import classNames from 'classnames/bind';
import styles from './order_footer.module.scss';
import { Icon } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';
import { Localize, DownloadPdfButton } from '@planity/components';
import React from 'react';

export function OrderFooter({
	shippingFees,
	isClickAndShop,
	price,
	hasDownloadLink,
	shipping_status,
	onDownload,
	downloadLink,
	isGiftVoucher,
	isLoading,
	setIsLoading,
	buyingId
}) {
	useStyles(styles);
	const { t } = useTranslation();

	const onDownloadPdf = async () => {
		if (!!downloadLink) {
			window.open(downloadLink, '_blank');
		} else {
			setIsLoading(true);
			await onDownload();
			setIsLoading(false);
		}
	};

	const classes = classNames.bind(styles)({
		orderFooter: true
	});

	return (
		<div className={`${classes} ${isGiftVoucher && styles.giftVoucherFooter}`}>
			<div
				className={`${styles.details} ${
					isGiftVoucher && styles.giftVoucherDetails
				}`}
			>
				{isClickAndShop && (
					<div className={styles.detail}>
						<span>{t('myAccount.orders.shippingFees')}</span>
						<span className={styles.iconText} id='order-footer-shippingFees'>
							<Icon icon='Euro' size='medium' />
							<div className={styles.shippingFees}> {shippingFees || 0}</div>
						</span>
					</div>
				)}
				<div className={styles.detail}>
					{!isGiftVoucher && (
						<span>
							<Localize text={`myAccount.orders.totalPrice`}>
								{text => (
									<>
										<span>{text} &nbsp;</span>
										<span className={styles.iconText} id='order-footer-price'>
											<Icon icon='CreditCard' size='medium' />
											{price}
										</span>
									</>
								)}
							</Localize>
						</span>
					)}

					{hasDownloadLink && (!!shipping_status || onDownload) && (
						<div>
							<DownloadPdfButton
								text={`myAccount.orders.downloadGiftVoucherPdf`}
								onClick={onDownloadPdf}
								isLoading={isLoading}
								id={buyingId}
								isFullMobile
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
