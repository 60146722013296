import React, { Component } from 'react';
import memoize from 'fast-memoize';
import { algolia } from '@planity/datastores';
import { identity } from '@planity/helpers';
import CreateFetcher from '../create_fetcher';

export default class AlgoliaSearch extends Component {
	static defaultProps = {
		format: identity
	};
	initialState = {
		data: null,
		pagesCount: null
	};

	render() {
		const { children, debug, cacheProvider, onChange, format, ...query } =
			this.props;
		return (
			<CreateFetcher
				cacheProvider={cacheProvider}
				debug={debug && `AlgoliaSearch(${debug})`}
				initialState={this.initialState}
				query={query}
				subscribe={this.fetch}
				onChange={onChange}
			>
				{children}
			</CreateFetcher>
		);
	}

	/**
	 * Localize fetched data
	 * @param fetchedData { Array|Object } some data fetched
	 * @return {Array|Object} some data translated
	 */
	localize = fetchedData => {
		const { language, localizeResults } = this.props;
		if (!localizeResults || !language || !fetchedData) return fetchedData;
		if (Array.isArray(fetchedData)) {
			return fetchedData.map(this.localize);
		}
		if (typeof fetchedData === 'object') {
			return this.localizeKeys(fetchedData);
		}
		return fetchedData;
	};

	/**
	 * For every attribute of the object, find if there is an "attribute_locale"
	 * equivalent. If so, override the field.
	 * If there is an "attribute_locale" but no "attribute", copy in "attribute" anyway
	 * Ex: if there is name_de, override name by name_de
	 * @param item { Object } An element from algolia
	 * @return {Object} A translated element
	 */
	localizeKeys = item => {
		const { language, countryCode } = this.props;
		return Object.entries(item).reduce(
			(all, [attributeKey, attributeValue]) => {
				// If current key has a localized key, take it
				// (eg: name => name_de, singular => singular_de...)
				if (item[`${attributeKey}_${language}`]) {
					all[attributeKey] = item[`${attributeKey}_${language}`];
					return all;
				}

				// If current key is a localized key, put it in the not localized key
				// (eg : demonstrativeAdjective_de exists but not demonstrativeAdjective)
				const languageMatcher = new RegExp(`(_${language})$`);
				if (attributeKey.match(languageMatcher)) {
					all[attributeKey.replace(languageMatcher, '')] =
						this.localize(attributeValue);
					return all;
				}
				// Same as previous, but for keys specific to a country,
				// (eg : places_DE, places_BE...)
				const countryMatcher = new RegExp(`(_${countryCode})$`);
				if (attributeKey.match(countryMatcher)) {
					all[attributeKey.replace(countryMatcher, '')] =
						this.localize(attributeValue);
					return all;
				}
				all[attributeKey] = this.localize(attributeValue);
				return all;
			},
			{}
		);
	};

	getClient = memoize(algolia, ({ index, apiKey }) => `${index}-${apiKey}`);

	fetch = ({ emit }) => {
		const client = this.getClient(this.props);
		const { getObjects, query, queries, format } = this.props;
		let runQuery;
		if (getObjects) {
			const index = client.initIndex(getObjects.index);
			runQuery = index
				.getObjects(getObjects && getObjects.objectIDs)
				.then(({ results }) => {
					return {
						hits: {
							[getObjects.index]: {
								data: results
							}
						}
					};
				});
		} else {
			if (queries) {
				const queryNames = Object.keys(queries);
				runQuery = client
					.search(
						queryNames.map(name => {
							return {
								indexName: queries[name].index,
								query: queries[name].query || query || '',
								params: queryRefinements(queries[name].params || queries[name])
							};
						})
					)
					.then(({ results }) => {
						return {
							hits: queryNames.reduce((response, name, i) => {
								response[name] = {
									data: results[i].hits,
									pagesCount: results[i].nbPages
								};
								return response;
							}, {})
						};
					});
			} else {
				runQuery = client.search(query || '', queryRefinements(this.props));
			}
		}
		runQuery
			.then(response =>
				emit({
					data: format(this.localize(response.hits), query),
					pagesCount: response.nbPages || null
				})
			)
			.catch(e => {
				if (process.env.NODE_ENV === 'development') {
					console.warn(e);
				}
				emit({
					data: [],
					pagesCount: 0
				});
			});
		return null;
	};
}

function queryRefinements(query) {
	const refinements = {
		page: (query.page || 1) - 1
	};
	[
		'filters',
		'hitsPerPage',
		'attributesToHighlight',
		'attributesToRetrieve',
		'aroundRadius',
		'getRankingInfo',
		'aroundLatLng',
		'insideBoundingBox'
	].forEach(k => {
		if (query.hasOwnProperty(k)) {
			refinements[k] = query[k];
		}
	});
	return refinements;
}
