import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './spinner.module.scss';
import classNames from 'classnames/bind';

export function Spinner({ className = '' }) {
	useStyles(styles);

	const classes = classNames.bind(styles)({
		spinner: true,
		[className]: className !== undefined
	});

	return (
		<svg className={classes} viewBox='0 0 24 24'>
			<circle
				cx='12'
				cy='12'
				r='11'
				fill='none'
				stroke='currentColor'
				strokeWidth='1'
			/>
			<circle
				cx='12'
				cy='12'
				r='11'
				fill='none'
				stroke='currentColor'
				strokeWidth='1'
			/>
		</svg>
	);
}
