import React, { forwardRef, useState } from 'react';
import Autocomplete from '../autocomplete';
import { withTranslation } from '@planity/localization';
import { GooglePlacesSearch } from '@planity/components';
import { getGooglePlace } from '@planity/datastores';
import debounce from 'lodash/debounce';

export const GooglePlacesAutocomplete = forwardRef((props, ref) => (
	<WrappedGooglePlacesAutocomplete {...props} forwardedRef={ref} />
));

const WrappedGooglePlacesAutocomplete = withTranslation()(
	({
		inputId,
		forwardedRef,
		className,
		placeholder,
		renderSuggestion,
		selectedItem,
		autoFocus,
		onPress,
		isDisabled,
		suggestOnFocus,
		userLocated,
		renderIcon,
		iconBlank,
		iconFilled,
		overChargedSuggestionWrapper,
		overChargedIcon,
		t,
		onSelect,
		textFilled
	}) => {
		const [query, setQuery] = useState(selectedItem ? selectedItem.name : '');
		const [visualQuery, setVisualQuery] = useState(
			selectedItem ? selectedItem.name : ''
		);

		const newSelectedItem = userLocated
			? {
					name: t('search.aroundMe'),
					geometry: {
						location: {
							...userLocated
						}
					}
			  }
			: selectedItem;

		const debouncedSearch = debounce(async q => setQuery(q), 200);

		// TODO GPA Use keepOpen flag for dev
		return (
			<GooglePlacesSearch query={query}>
				{({ places }) => (
					<Autocomplete
						autoFocus={autoFocus}
						className={className}
						getSuggestionKey={hit => hit.place_id}
						getSuggestionName={getSuggestionName}
						iconBlank={iconBlank}
						iconFilled={{
							...iconFilled,
							onClick: () => {
								setQuery('');
								setVisualQuery('');
							}
						}}
						inputId={inputId}
						isDisabled={isDisabled}
						keepOpen={false}
						overChargedIcon={overChargedIcon}
						overChargedSuggestionWrapper={overChargedSuggestionWrapper}
						placeholder={placeholder}
						query={userLocated ? '' : visualQuery}
						ref={forwardedRef}
						renderIcon={renderIcon}
						renderSuggestion={renderSuggestion}
						selectedItem={newSelectedItem}
						suggestions={places || []}
						suggestOnFocus={suggestOnFocus}
						textFilled={{
							...textFilled,
							onClick: () => {
								setQuery('');
								setVisualQuery('');
							}
						}}
						onPress={onPress}
						onQueryChange={q => {
							debouncedSearch(q);
							setVisualQuery(q);
						}}
						onSelect={prediction => {
							if (prediction) {
								getGooglePlace({
									placeId: prediction.place_id,
									prediction
								}).then(place => {
									if (place) {
										onSelect(place);
									}
								});
							}
						}}
					/>
				)}
			</GooglePlacesSearch>
		);
	}
);

function getSuggestionName(hit) {
	return (
		(hit.structured_formatting && hit.structured_formatting.main_text) ||
		hit.name
	);
}
