import React from 'react';
import { Localize } from '@planity/components';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './answer_preview.module.scss';

export const AnswerPreview = ({ review, fromCustomer }) => {
	useStyles(styles);

	return (
		<div className={styles.reviewBody}>
			{fromCustomer ? (
				<p className={styles.titleComment}>
					<Localize text={'reviewPreview.reviewComment'} />
				</p>
			) : (
				<p className={styles.titleAnswer}>
					<Localize text={'reviewPreview.reviewAnswer'} />
				</p>
			)}
			<div className={styles.reviewBodyContainer}>
				<p className={{ margin: 0 }} id='appointment-review-preview-answer'>
					{fromCustomer ? review.reviewBody : review.reviewAnswer}
				</p>
			</div>
		</div>
	);
};
