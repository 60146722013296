// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loading_page-module_loading-Fw1JK{background:var(--grey-100);display:grid;place-items:center;margin-top:64px;height:100%}", "",{"version":3,"sources":["webpack://./../../packages/ui/components/home/native_app_landing_page/loading_page.module.scss","webpack://./../../packages/ui/styles/utilities/variables.scss"],"names":[],"mappings":"AAEA,mCACC,0BAAA,CACA,YAAA,CACA,kBAAA,CACA,eCQe,CDPf,WAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.loading {\n\tbackground: var(--grey-100);\n\tdisplay: grid;\n\tplace-items: center;\n\tmargin-top: styles.$header-height;\n\theight: 100%;\n}\n","// SASS variables\n// Breakpoints\n$small-mobile: 320px;\n$medium-mobile: 395px;\n$mobile: 480px;\n$tablet: 768px;\n$medium-tablet: 1024px;\n$medium-desktop: 1180px;\n$large-desktop: 1440px;\n$desktop: 1080px;\n$xl-desktop: 1920px;\n\n$header-modal-height: 56px;\n$header-modal-height-without-title: 48px;\n$header-height: 64px;\n$filters-height: 72px;\n$search-bar-height: 100px;\n$width-aside: 42vw;\n$focus: 0 0 0 var(--focus-size) var(--focus-color);\n$focus-alt: 0 0 0 var(--focus-size) var(--focus-alt-color);\n\n// export variables to be able to use them inside JS\n// https://til.hashrocket.com/posts/sxbrscjuqu-share-scss-variables-with-javascript\n:export {\n\theaderHeight: $header-height;\n\tsearchBarHeight: $search-bar-height;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"loading": "loading_page-module_loading-Fw1JK"
};
module.exports = ___CSS_LOADER_EXPORT___;
