import { useLocalizedRoutes } from '@planity/localization';
import React, { useState } from 'react';
import { Localize } from '@planity/components';
import {
	AuthForm,
	FORGOTTEN_PASSWORD,
	SIGN_IN,
	SIGN_UP
} from '@planity/components';
import credentials from '@planity/credentials';
import { withGoalEvents } from '@planity/helpers/analytics';
import styles from './signed_out.module.scss';
import { ErrorMessage } from '../error_message';

export default withGoalEvents(
	({
		onSignUp,
		isVerified,
		user,
		userId,
		publishUserSignedUp,
		hasReviewError
	}) => {
		const [authType, setAuthType] = useState(SIGN_IN);
		const { routes } = useLocalizedRoutes();

		return (
			<div className={styles.signedOut} id='auth-form'>
				<AuthForm
					conditionsLink={`https://www.${credentials.HOST}${routes.legal}`}
					defaultCountry={authType === SIGN_UP && 'FR'}
					fromAccount={authType === SIGN_IN || authType === SIGN_UP}
					fullWidth
					hideWarnings
					isVerified={isVerified}
					publishUserSignedUp={publishUserSignedUp}
					scrollOnError={true}
					setAuthType={setAuthType}
					skipPasswordConfirmation
					type={authType}
					user={user}
					userId={userId}
					onSignUp={authType === SIGN_UP && onSignUp}
				/>
				{authType === FORGOTTEN_PASSWORD && (
					<div css={styles.title}>
						<Localize text={'nav.forgottenPassword'} />
					</div>
				)}
				{hasReviewError && (
					<ErrorMessage error={'reviewForm.reviewDateHasPassed'} />
				)}
			</div>
		);
	}
);
