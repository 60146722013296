import React from 'react';
import { PhoneInput, useAuth } from '@planity/components';
import { Button } from '@planity/ui';
import { useTranslation, useLocalization } from '@planity/localization';
import styles from './phone_verification_form.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';

export const PhoneVerificationUpdate = ({
	handleClickEvents,
	currentState,
	handleInputChange
}) => {
	const { isPending, phoneNumber } = currentState;
	const { t } = useTranslation();
	const { countryCode } = useLocalization();
	useStyles(styles);
	const classes = classNames.bind(styles)({
		phoneVerification: true
	});
	const { user } = useAuth();

	const phoneNumberTel = phoneNumber || user?.phone || '';

	const localizedText = {
		id: 'phone_verification_update_form',
		title: t('phoneVerificationForm.updatePhoneNumber'),
		labels: {
			submit: t(`actions.submit`),
			phoneNumber: t('auth.form.labels.phone'),
			signout: t(`phoneVerificationForm.signOut`)
		},
		placeholders: {
			phoneNumber: t(`auth.form.placeholders.phoneNumber`)
		}
	};
	return (
		<form
			className={classes}
			id={localizedText.id}
			onSubmit={e =>
				handleClickEvents({ event: e, type: 'updateUserPhoneNumber' })
			}
		>
			<div className={`${styles.title}  ${styles.title__update}`}>
				{localizedText.title}
			</div>
			<PhoneInput
				autoFocus={true}
				data={null}
				defaultCountry={countryCode}
				id={`${localizedText.id}__phone`}
				localizedPlaceholder={localizedText.placeholders.phoneNumber}
				localizedText={localizedText}
				value={phoneNumberTel}
				onChange={e => handleInputChange({ value: e, type: 'setPhoneNumber' })}
			/>
			<Button
				className={`${styles.btn} ${styles.btn__submit}`}
				id={`${localizedText.id}__submit`}
				isDisabled={isPending}
				isLoading={isPending}
				label={localizedText.labels.submit}
				onClick={e =>
					handleClickEvents({ event: e, type: 'updateUserPhoneNumber' })
				}
			/>
			<Button
				className={`${styles.btn}  ${styles.btn__signOut}`}
				id={`${localizedText.id}__signOut`}
				isDisabled={isPending}
				isFullWidth
				label={localizedText.labels.signout}
				type={'secondary'}
				onClick={e => handleClickEvents({ event: e, type: 'signOutAndClean' })}
			/>
		</form>
	);
};
