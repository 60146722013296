import React from 'react';
import { MemberConnection } from '../commun/memberConnection';
import styles from '../authentication.module.scss';

export const FormComponent = () => {
	return (
		<div className={styles.form} id='auth-form-comp'>
			<MemberConnection />
		</div>
	);
};
