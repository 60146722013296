import { Localize } from '@planity/components';
import { Icon } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './no_availabilities.module.scss';

export const NoAvailabilities = () => {
	const classes = classNames.bind(styles);
	useStyles(styles);

	return (
		<div className={classes({ container: true })}>
			<div className={styles.iconContainer}>
				<Icon className={styles.icon} icon='Calendar' size={'medium'} />
			</div>
			<div className={styles.noAvailabilityText}>
				<Localize text={'business.noOnlineAvailability'} />
			</div>
		</div>
	);
};
