// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".alert-module_alert-ZNLoi{text-align:center;width:100%;font-size:16px;line-height:24px;font:var(--regular) var(--body-3);font-weight:400;color:var(--grey-700)}.alert-module_alert-ZNLoi .alert-module_noResults-cCu8z{padding:24px}", "",{"version":3,"sources":["webpack://./app/components/ui/alert.module.scss"],"names":[],"mappings":"AAAA,0BACC,iBAAA,CACA,UAAA,CACA,cAAA,CACA,gBAAA,CACA,iCAAA,CACA,eAAA,CACA,qBAAA,CACA,wDACC,YAAA","sourcesContent":[".alert {\n\ttext-align: center;\n\twidth: 100%;\n\tfont-size: 16px;\n\tline-height: 24px;\n\tfont: var(--regular) var(--body-3);\n\tfont-weight: 400;\n\tcolor: var(--grey-700);\n\t.noResults {\n\t\tpadding: 24px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "alert-module_alert-ZNLoi",
	"noResults": "alert-module_noResults-cCu8z"
};
module.exports = ___CSS_LOADER_EXPORT___;
