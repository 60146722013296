import { noop } from '@planity/helpers';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './gallery.module.scss';
import { Image, IconButton, Container, useSwiper } from '@planity/ui';
import { getTransitionDuration, realIndex } from '@planity/ui/shared/utils';
import { useHistory } from 'react-router-dom';
import { breakpoints } from '@planity/theme';
import { isNativeApp, sendToUserApp } from '@planity/webview';

const TRANSFORMATIONS = {
	breakpoints: [
		{ query: breakpoints.tabletQuery, transformation: 'w_1120' },
		{ query: breakpoints.phoneQuery, transformation: 't_m_main' }
	]
};

export function Gallery({
	images = [],
	closeGallery,
	isOpen,
	forwardedRef,
	transformations
}) {
	const history = useHistory();
	const [index, setIndex] = useState(0);
	const { onTouchStart, onTouchMove, onTouchEnd } = useSwiper();
	const [isSlideshow, setIsSlideshow] = useState(false);
	useStyles(styles);

	const previous = useCallback(
		e => {
			e?.preventDefault();
			e?.stopPropagation();
			setIndex((index - 1 + images.length) % images.length);
		},
		[index, images]
	);

	const next = useCallback(
		e => {
			e?.preventDefault();
			e?.stopPropagation();
			setIndex((index + 1) % images.length);
		},
		[index, images]
	);

	const close = useCallback(
		isOnBack => {
			if (isSlideshow) {
				setIsSlideshow(false);
				if (!isOnBack) {
					history.goBack();
				}
			} else {
				closeGallery();
			}
		},
		[closeGallery, history, isSlideshow]
	);

	useEffect(() => {
		const bind = event => {
			if (!isOpen) return;

			switch (event.key) {
				case 'ArrowRight':
					return next();
				case 'ArrowLeft':
					return previous();
				case 'Escape':
					return close();
				default:
					return noop();
			}
		};

		window.addEventListener('keyup', bind);
		return () => window.removeEventListener('keyup', bind);
	}, [close, next, previous, isOpen]);

	const classes = classNames.bind(styles)({
		gallery: true,
		isOpen: isOpen,
		isSlideshow: isSlideshow
	});

	useEffect(() => {
		if (isSlideshow) {
			const listener = () => {
				close(true);
			};
			window.addEventListener('popstate', listener);
			return () => window.removeEventListener('popstate', listener);
		}
	}, [isSlideshow, close]);

	useEffect(() => {
		if (isNativeApp) {
			sendToUserApp({
				type: 'DARK_MODE',
				payload: {
					isDarkMode: isSlideshow
				}
			});
			return;
		}
	}, [isSlideshow]);

	return (
		<div className={classes} ref={forwardedRef}>
			<IconButton
				className={styles.close}
				label='Fermer'
				icon='Close'
				size={'medium'}
				onClick={() => close()}
				onMouseDown={e => e.preventDefault()} // Prevent focus state on click
			/>
			<Container
				className={classNames.bind(styles)({
					previews: true,
					hasOneOrNoPicture: images.length <= 1
				})}
			>
				{images.map((props, i) => (
					<button
						key={i}
						className={styles.preview}
						onClick={() => {
							setIsSlideshow(true);
							setIndex(i);
							history.push({
								...history.location,
								search: 'gallery=2'
							});
						}}
					>
						<Image
							{...props}
							fit='cover'
							url={images[realIndex(i, images.length)]}
							transformations={transformations}
						/>
					</button>
				))}
			</Container>

			<div className={styles.slideshow}>
				<Container className={styles.container}>
					<div
						className={styles.items}
						onTouchStart={onTouchStart}
						onTouchMove={onTouchMove}
						onTouchEnd={e => onTouchEnd(next, previous, e)}
					>
						{images.map((props, i) => (
							<div
								key={i}
								className={classNames.bind(styles)({
									item: true,
									isCurrent: i === index
								})}
							>
								<Image
									fit='contain'
									isCloudinary
									url={images[realIndex(i, images.length)]}
									transformations={TRANSFORMATIONS}
								/>
							</div>
						))}
					</div>
				</Container>

				{images && images.length > 1 && (
					<>
						<IconButton
							aria-label='previous'
							className={styles.previous}
							icon={'ChevronLeft'}
							onClick={previous}
							onMouseDown={e => e.preventDefault()} // Prevent focus state on click
						/>

						<IconButton
							aria-label='next'
							className={styles.next}
							icon={'ChevronRight'}
							onClick={next}
							onMouseDown={e => e.preventDefault()} // Prevent focus state on click
						/>
					</>
				)}
			</div>
		</div>
	);
}

const GalleryContext = React.createContext();

const GalleryProvider = props => {
	const history = useHistory();
	const [images, setGalleryImages] = useState();
	const [transformations, setGalleryTransformations] = useState();
	const [isOpen, setIsOpen] = useState(false);

	const gallery = useRef();

	const closeGallery = useCallback(
		isOnBack => {
			setIsOpen(false);

			document.body.style.overflow = '';
			document.body.style.height = '';
			if (!isOnBack) {
				history.goBack();
			}
		},
		[history]
	);

	const openGallery = useCallback(() => {
		setIsOpen(true);
		// Prevent double scrollbars on Windows
		gallery.current.scrollTop = 0;
		gallery.current.style.overflow = 'hidden';
		setTimeout(() => {
			document.body.style.overflow = 'hidden';
			document.body.style.height = '100vh';
			gallery.current.style.overflow = '';
		}, getTransitionDuration(gallery?.current));
		history.push({
			...history.location,
			search: 'gallery=1'
		});
	}, [history]);

	useEffect(() => {
		if (isOpen) {
			const listener = () => {
				if (!history.location.search) {
					closeGallery(true);
				}
			};
			window.addEventListener('popstate', listener);
			return () => window.removeEventListener('popstate', listener);
		}
	}, [isOpen, closeGallery, history]);

	return (
		<GalleryContext.Provider
			value={{
				openGallery,
				closeGallery,
				setGalleryImages,
				setGalleryTransformations
			}}
			{...props}
		>
			{props.children}

			<Gallery
				forwardedRef={gallery}
				images={images}
				closeGallery={closeGallery}
				isOpen={isOpen}
				transformations={transformations}
			/>
		</GalleryContext.Provider>
	);
};

const useGallery = () => {
	const context = React.useContext(GalleryContext);
	if (context === undefined) {
		throw new Error('useGallery must be used within a UserProvider');
	}

	return context;
};

export { GalleryProvider, useGallery };
