import { useLocalizedRoutes, useTranslation } from '@planity/localization';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { useLocation } from 'react-router-dom';
import config from '@planity/credentials';
import { BreadCrumbIcon, BreadCrumbLink, BreadCrumbSpan } from '@planity/ui';
import styles from './breadcrumbs.module.scss';

export function Breadcrumbs({
	crumbs,
	crumbName,
	hasBackgroundColor,
	removePadding,
	isHiddenOnMobile,
	isHiddenOnTablet,
	className
}) {
	const classes = classNames.bind(styles)({
		breadcrumbs: true,
		[className]: className,
		hasBackgroundColor,
		isHiddenOnMobile,
		isHiddenOnTablet,
		removePadding
	});
	useStyles(styles);

	const location = useLocation();
	const { routes } = useLocalizedRoutes();
	const isLast = crumbs && crumbs.length && crumbs.length - 1;
	return (
		<div className={classes}>
			<nav
				className={styles.nav}
				itemScope
				itemType={'https://schema.org/BreadcrumbList'}
			>
				{(crumbs || [])
					.concat(
						crumbName
							? {
									path: location.pathname,
									localizedName: crumbName
							  }
							: []
					)
					.map((crumb, i) => (
						// Yes, it could have been CSS
						<span className={styles.center} key={crumb.path + i}>
							{i !== 0 && <BreadCrumbIcon />}
							<Crumb {...crumb} index={i + 2} isLast={isLast === i} />
						</span>
					))}
			</nav>
		</div>
	);
}

function Crumb({ path, name, localizedName, index, isLast }) {
	const metaURL = `https://www.${config.HOST}${path}`;
	const microdata = {
		itemProp: 'item',
		itemScope: true,
		itemType: 'https://schema.org/WebPage',
		id: metaURL
	};
	const { t } = useTranslation();
	return (
		<span
			id='breadcrumb-item'
			itemProp={'itemListElement'}
			itemScope
			itemType={'https://schema.org/ListItem'}
		>
			{isLast ? (
				<BreadCrumbSpan
					id={`breadcrumb-span-${localizedName || name}`}
					isLast
					itemProp={'name'}
					text={localizedName ? t(`nav.${localizedName}`) : name}
				/>
			) : (
				<BreadCrumbLink
					to={path}
					{...microdata}
					id={`breadcrumb-link-${localizedName || name}`}
					isLast={false}
					itemProp={'name'}
					text={localizedName ? t(`nav.${localizedName}`) : name}
				/>
			)}
			<meta content={index} itemProp={'position'} />
			<meta content={metaURL} itemProp={'url'} />
		</span>
	);
}
