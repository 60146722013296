import { useState } from 'react';

export const useUikit = () => {
	const [toggle, setToggleIcon] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const [hasError, setHasError] = useState(false);
	const toggleIcon = () => {
		setToggleIcon(!toggle);
	};
	// Events
	const dispatchError = error => {
		setHasError(error); // Todo: error checking
	};

	return {
		toggleIcon,
		toggle,
		dispatchError,
		isFocused,
		setIsFocused,
		hasError,
		setHasError
	};
};
