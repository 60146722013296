import React from 'react';

export default function VogueLogo() {
	return (
		<svg
			height='18'
			viewBox='0 0 70 18'
			width='70'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M20.403.078c4.078-.03 7.546 4.406 7.546 8.555 0 3.755-2.578 9.244-7.546 9.26-4.967.017-7.412-5.081-7.412-9.26 0-3.972 3.335-8.526 7.412-8.555zm0 .156c-3.412 0-4.27 4.253-4.27 8.399 0 6.618 1.048 9.075 4.27 9.08 3.223.006 4.334-2.996 4.334-9.08 0-4.612-.921-8.4-4.334-8.4zm8.893 8.51c-.019-4.518 3.581-8.521 6.628-8.72 1.1-.078 2.516.428 3.341.789.6.262 1.825-.117 2.042-.813h.119v6.156h-.119c0-.263-1.135-5.977-5.383-5.977-3.138 0-3.441 5.544-3.441 8.566 0 3.022.091 9.004 3.753 9.004 1.774 0 3.087-.78 3.428-2.358v-4.797h-1.49v-.204h5.772v.204h-1.423v7.013h-.116S42.175 16.595 41 16.6c-1.501.004-2.916 1.314-4.642 1.298-4.563-.042-7.045-4.633-7.063-9.152zM1.797.685H0V.483h6.295v.203h-1.58l4.5 12.274C10.62 8.608 11.71 5.39 13.176.685h-1.562V.482h3.323v.203h-1.513L7.958 17.81 1.798.685zm43.03 12.933L44.8.665h-1.71V.47h6.302v.194h-1.71l.038 14.201c.378 1.626 1.051 2.86 3.35 2.878 2.299.019 3.862-1.366 4.442-3.258V.665h-1.956V.477H69.57l.033 6.217h-.154C69.153 2.68 67.611 1.162 64.915.72c-.927-.152-1.9-.04-3.13-.05v7.936c1.07.083 2.212.015 2.98-.649.7-.603.898-1.998.95-2.564h.135v6.909h-.147c-.042 0-.098-1.734-.937-2.557-.844-.828-1.92-1.031-2.982-.834v8.477c1.5 0 2.697.127 3.736-.159 3.158-.87 4.27-3.547 4.26-6.231h.176v6.59H57.394v-.2h1.42V.665h-3.069v13.82c-.348 1.323-1.619 3.51-4.877 3.496-3.03-.014-6.034-1.386-6.04-4.364z'
				fill='#ffff'
				fillRule='evenodd'
			/>
		</svg>
	);
}
