import React from 'react';

export function Instagram() {
	return (
		<svg
			width='32'
			height='32'
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M20.5 7H11.5C9.01472 7 7 9.01472 7 11.5V20.5C7 22.9853 9.01472 25 11.5 25H20.5C22.9853 25 25 22.9853 25 20.5V11.5C25 9.01472 22.9853 7 20.5 7Z'
				stroke='#202020'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M19.5986 15.4333C19.7096 16.1823 19.5817 16.9473 19.2329 17.6194C18.8842 18.2915 18.3324 18.8365 17.656 19.177C16.9797 19.5174 16.2132 19.6359 15.4656 19.5156C14.718 19.3953 14.0273 19.0423 13.4919 18.5069C12.9565 17.9715 12.6035 17.2809 12.4832 16.5333C12.3629 15.7857 12.4814 15.0192 12.8219 14.3428C13.1623 13.6664 13.7073 13.1146 14.3795 12.7659C15.0516 12.4171 15.8165 12.2892 16.5656 12.4003C17.3296 12.5136 18.0369 12.8696 18.5831 13.4157C19.1292 13.9619 19.4853 14.6692 19.5986 15.4333Z'
				stroke='#202020'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
