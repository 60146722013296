import React from 'react';
import { colors } from '@planity/theme';
import {
	FacebookIconRound,
	GithubIcon,
	GoogleIcon
} from '@planity/components/icons';
import { Localize } from '@planity/components';

/**
 *
 * @param provider {String} such as "facebook", "google", "github" and so on...
 * @param isDisabled {boolean}
 * @param onPress {function}
 * @param isAlone {boolean} if the button is alone or with other social buttons
 * @param className
 * @param style
 * @param localizedText
 * @param buttonProps
 */
export const SocialButton = ({
	onPress,
	className,
	isDisabled,
	style,
	localizedText,
	provider,
	isAlone,
	...buttonProps
}) => {
	const buttonText =
		localizedText ||
		`auth.form.buttons.CONTINUE_WITH_${provider.toUpperCase()}`;
	return (
		<div
			css={[
				styles.button,
				isAlone && styles.loneButton,
				isDisabled && styles.unusableButton,
				className,
				style
			]}
			title={
				isDisabled
					? "Merci de vous connecter dans la fenêtre qui vient de s'ouvrir"
					: undefined
			}
			onClick={isDisabled ? undefined : onPress}
			{...buttonProps}
		>
			{getIcon(provider, buttonProps)}
			{isAlone && (
				<React.Fragment>
					<div css={styles.buttonText}>
						<Localize text={buttonText} />
					</div>
					<div />
				</React.Fragment>
			)}
		</div>
	);
};

const getIcon = (provider, buttonProps, style) => {
	switch (provider) {
		case 'facebook':
			return <FacebookIconRound css={[styles.icon, style]} {...buttonProps} />;
		case 'google':
			return <GoogleIcon css={[styles.icon, style]} {...buttonProps} />;
		case 'github':
			return <GithubIcon css={[styles.icon, style]} {...buttonProps} />;
		default:
			console.error(`Provider unknown ${provider}`);
	}
};

const transition = 'all .3s ease-out';

const styles = {
	loneButton: {
		justifyContent: 'center'
	},
	button: {
		'flex': 1,
		'display': 'flex',
		'alignItems': 'center',
		'justifyContent': 'center',
		'cursor': 'pointer',
		'textDecoration': 'none',
		'fontSize': 14,
		'lineHeight': 1.29,
		'borderRadius': 4,
		'height': 42,
		transition,
		'minHeight': 42,
		':focus': {
			outline: 0
		},
		'color': colors.white,
		'backgroundColor': '#3b5a99',
		':hover': {
			'& > div': {
				transition,
				color: colors.white
			},
			'color': colors.white,
			'backgroundColor': '#2F4A81',
			transition
		}
	},
	buttonText: {
		color: colors.white,
		fontSize: 14,
		marginLeft: 16,
		transition
	},
	icon: {
		transition,
		maxHeight: 42
	},
	unusableButton: {
		cursor: 'not-allowed'
	}
};
