import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './card.module.scss';

export function Card({
	className = undefined,
	titleClassName = undefined,
	children = undefined,
	title = undefined,
	hasTitleInside = false,
	isVisibleMobile = true,
	hasPadding = true,
	hasPaddingSmall = false,
	id,
	hasMore = false,
	hideBorders = false,
	hasTransparentBackground
}) {
	useStyles(styles);
	const cx = classNames.bind(styles);
	const classes = cx({
		card: true,
		hasTitleInside,
		isVisibleMobile,
		hasPadding,
		hasPaddingSmall,
		hasMore,
		hideBorders,
		widget: process.env.WIDGET,
		hasTransparentBackground
	});

	return (
		<div className={classes}>
			{title ? (
				<h2
					className={cx({ title: true }, titleClassName)}
					id={id || 'card-title'}
				>
					{title}
				</h2>
			) : null}
			<div className={`${styles.content} ${className || ''}`}>{children}</div>
		</div>
	);
}
