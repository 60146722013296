import { getSEOCatToPushFromCountryCode } from '../config/seoTags';

const updateTagsScoresMap = ({ tagsScoresMap, tagIds, seoCatIds }) => {
	tagIds.forEach(tagId => {
		if (!seoCatIds.includes(tagId)) {
			return;
		}

		if (tagsScoresMap[tagId] === undefined) {
			tagsScoresMap[tagId] = 1;
		} else {
			tagsScoresMap[tagId] = tagsScoresMap[tagId] + 1;
		}
	});
};

export const getSEOTagsScores = ({ brand, countryCode, services, tags }) => {
	const tagsScoresMap = {};
	const seoCatIds = getSEOCatToPushFromCountryCode(countryCode);

	if (!seoCatIds || !services) {
		//seoCat could be undefined if the business is not FR, DE or BE
		return [];
	}

	const tagsById = tags.reduce((acc, tag) => {
		acc[tag.objectID] = tag;
		return acc;
	}, {});

	Object.values(services).forEach(serviceSet => {
		if (!serviceSet.children) {
			return;
		}
		Object.values(serviceSet.children).forEach(service => {
			if (
				!service.bookable ||
				!service.tags ||
				service.deletedAt ||
				typeof service.tags !== 'string'
			) {
				return;
			}
			const tagIds = service.tags.split(',');

			tagIds.forEach(tagId => {
				const tag = tagsById[tagId];

				if (!tag) {
					return;
				}

				const parentTagsIds = tag.parentIds ?? [];

				updateTagsScoresMap({
					tagsScoresMap,
					tagIds: [tagId, ...parentTagsIds],
					seoCatIds
				});
			});
		});
	});

	updateTagsScoresMap({
		tagsScoresMap,
		tagIds: [brand],
		seoCatIds
	});

	return Object.entries(tagsScoresMap).map(([tagId, score]) => ({
		tagId,
		score
	}));
};
