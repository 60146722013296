export const APPOINTMENT_SOURCE = {
	SEARCH: {
		CATEGORY: 'Search by category',
		BUSINESS: "Search with business's name",
		PLACE: 'Search by place',
		MOBILE: 'Mobile search'
	},
	BOOK_AGAIN: {
		PROFILE: 'Book again from profile',
		BUSINESS: "Book again from business's page",
		HOME: 'Book again from home',
		MOBILE_SEARCH: 'Mobile book again from search'
	}
};

export default APPOINTMENT_SOURCE;
