import { useLocalization, useLocalizedRoutes } from '@planity/localization';
import { useModal } from '@planity/ui';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isNativeApp } from '@planity/webview';
import { RedirectToCountryModalContent } from './redirect_to_country_modal_content';

export function RedirectToCountryModal() {
	const { locale, viewerCountry } = useLocalization();
	const [suggestedCountry, setSuggestedCountry] = useState(null);
	const location = useLocation();
	const { routes } = useLocalizedRoutes();
	const { setModal, closeModal } = useModal();

	useEffect(() => {
		// Yes a switch is overkill, but it **could** be useful
		switch (locale) {
			case 'fr-FR':
				if (['BE', 'DE'].includes(viewerCountry)) {
					setSuggestedCountry(viewerCountry);
				}
				break;
			default:
				break;
		}
	}, [locale, viewerCountry]);

	useEffect(() => {
		const storeRedirectCountryModalHasBeenDisplayed = () => {
			sessionStorage.setItem('hasRedirectCountryModalBeenDisplayed', 'true');
			return closeModal();
		};
		const hasRedirectCountryModalBeenDisplayed =
			process.env.BROWSER &&
			!!sessionStorage.getItem('hasRedirectCountryModalBeenDisplayed');

		if (
			!isNativeApp &&
			viewerCountry &&
			location.pathname === routes.home &&
			suggestedCountry &&
			!hasRedirectCountryModalBeenDisplayed
		) {
			setModal({
				content: (
					<RedirectToCountryModalContent
						suggestedCountry={suggestedCountry}
						onSave={storeRedirectCountryModalHasBeenDisplayed}
					/>
				),
				hasCloseBtn: true,
				order: 3,
				onCloseModal: storeRedirectCountryModalHasBeenDisplayed
			});
		}
	}, [viewerCountry, location, routes, suggestedCountry]);

	return null;
}
