import { useLocalization } from '@planity/localization';
import { Localize } from '@planity/components';
import React from 'react';
import { noop } from '@planity/helpers';
import { format, isSameDay } from 'date-fns';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './page.module.scss';

export function Page({ days, today, availabilities, chooseDate }) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		timeslot: true
	});
	const { locale } = useLocalization();

	const firstLineFormatter = new Intl.DateTimeFormat(locale, {
		weekday: 'long'
	});
	const secondLineFormatter = new Intl.DateTimeFormat(locale, {
		day: '2-digit',
		month: 'short'
	});
	return (
		<div className={classes}>
			<div className={styles.days}>
				{days.map((day, dayIndex) => (
					<div className={styles.dayWrapper} key={day}>
						<div
							className={`${styles.header}  planity_appointment-availabilities-header`}
						>
							<span
								className={`${styles.day} ${
									isSameDay(day, today) ? styles.today : ''
								}`}
							>
								{firstLineFormatter.format(day)}
							</span>
							<span className={styles.space}>
								<Localize text={'punctuation.space'} />
							</span>
							<span className={styles.date}>
								{secondLineFormatter.format(day)}
							</span>
						</div>

						<div className={styles.content}>
							<div className={styles.items}>
								{dayHours(day, availabilities).map(({ hour, isStale }, i) => (
									<button
										className={classNames.bind(styles)({
											isStale,
											item: true,
											planity_appointment_days_slider_hour_availability: true
										})}
										id={`availabilities-${dayIndex}-${i}`}
										key={hour}
										onClick={
											isStale
												? noop
												: () => chooseDate(format(day, 'yyyy-MM-dd'), hour)
										}
									>
										{hour}
									</button>
								))}
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

function dayHours(day, availabilities) {
	return (availabilities || {})[format(day, 'yyyy-MM-dd')] || [];
}
