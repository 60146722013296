let lighter = 300;
let normal = 400;
let semiBold = 500;
let medium = 600;
let bold = 700;
let bolder = 800;
let inherit = 'inherit';

if (process.env.WIDGET) {
	lighter = 'normal';
	normal = '500';
	bold = 'bold';
}

export default {
	lighter: lighter,
	normal: normal,
	semiBold: semiBold,
	medium: medium,
	bold: bold,
	bolder: bolder,
	inherit: inherit
};
