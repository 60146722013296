import useStyles from 'isomorphic-style-loader/useStyles';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { Drawer, Flag, Icon, SelectModal } from '@planity/ui';
import styles from './select.module.scss';
import { noop } from '@planity/helpers';

export function Select({
	children,
	className,
	defaultValue = '',
	errorMessage = 'Invalid field',
	isDisabled = false,
	isOutlined,
	isRadio = false,
	isRequired = false,
	isMobile,
	items = [],
	label,
	name,
	placeholder = '',
	value,
	onSelect,
	flag,
	icon,
	openModal = false,
	setOpenModal = noop,
	hasAtLeastOnePicture,
	openGallery,
	isAttached,
	isFullHeight,
	hasNoRightPadding,
	preventScroll,
	id
}) {
	useStyles(styles);
	const input = useRef();
	const button = useRef();
	const select = useRef();

	// States
	const [isFocused, setIsFocused] = useState(false);
	const [isSelecting, setIsSelecting] = useState(false);
	const [hasError, setHasError] = useState(false);

	const handleErrorEmptyField = () => {
		setTimeout(() => {
			setHasError(isRequired && input.current.value === '');
		}, 100);
	};

	useEffect(() => {
		const handleClickOutside = event => {
			if (!select?.current?.contains(event.target) && isSelecting) {
				setIsSelecting(false);
			}
		};
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [setIsSelecting, isSelecting]);

	const classes = classNames.bind(styles);

	const renderLabel = label && (
		<label
			htmlFor={name}
			onClick={() => input.current.focus()}
			className={styles.label}
		>
			{label} {isRequired && '*'}
		</label>
	);

	const renderField = (
		<button
			className={classes(
				{
					container: true,
					isDisabled,
					isFocused,
					isInvalid: hasError,
					isOutlined
				},
				'planity_appointment_choose_with'
			)}
			type={'button'}
			ref={button}
			onClick={() =>
				isDisabled
					? null
					: isMobile
					? setOpenModal(!openModal)
					: setIsSelecting(!isSelecting)
			}
		>
			{flag && (
				<div className={styles.flag}>
					<Flag country={flag} />
				</div>
			)}
			<input
				type='text'
				readOnly
				className={styles.field}
				disabled={isDisabled}
				value={value !== '' ? value : defaultValue}
				placeholder={placeholder}
				ref={input}
				onFocus={() => setIsFocused(true)}
				onBlur={() => {
					setIsFocused(false);
					handleErrorEmptyField();
				}}
				id={id}
			/>
			<Icon
				className={styles.icon}
				icon={icon || !isSelecting ? 'ChevronDown' : 'ChevronUp'}
				size={'medium'}
			/>
		</button>
	);

	const renderError = !isFocused && !isDisabled && hasError && errorMessage && (
		<span className={styles.error}>{errorMessage}</span>
	);

	return (
		<div
			className={classNames.bind(styles)({
				select: true,
				[className]: className !== undefined
			})}
		>
			{renderLabel}
			<div className={styles.rel} ref={select}>
				{renderField}
				{isMobile ? (
					<SelectModal
						setOpenModal={setOpenModal}
						openModal={openModal}
						isFullHeight={isFullHeight}
						hasNoRightPadding={hasNoRightPadding}
						preventScroll={preventScroll}
					>
						{children}
					</SelectModal>
				) : (
					<Drawer
						className={classes(
							{
								drawer: true
							},
							'planity_appointment_choose_with_wrapper'
						)}
						isOpen={isSelecting}
						isRadio={isRadio}
						items={items}
						selected={value}
						onSelect={props => {
							onSelect(props);
							setIsSelecting(false);
						}}
						hideDescription
						openGallery={openGallery}
						hasAtLeastOnePicture={hasAtLeastOnePicture}
						isAttached={isAttached}
					/>
				)}
			</div>
			{renderError}
		</div>
	);
}
