export function Amex() {
	return (
		<svg
			width='50'
			height='34'
			viewBox='0 0 50 34'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<rect
				x='0.75'
				y='0.75'
				width='48.5'
				height='32.5'
				rx='4.25'
				fill='#26A6D1'
				stroke='#E9EAF0'
				strokeWidth='0.5'
			/>
			<path
				d='M11.0629 12L7 21.9872H11.8639L12.4668 20.3948H13.8451L14.4481 21.9872H19.8018V20.7718L20.2789 21.9872H23.0482L23.5253 20.7461V21.9872H34.6596L36.0135 20.4361L37.2812 21.9872L43 22L38.9243 17.0215L43 12H37.3699L36.052 13.5224L34.8242 12H22.7115L21.6714 14.5778L20.6069 12H15.7532V13.174L15.2132 12C15.2132 12 11.0629 12 11.0629 12ZM12.004 13.4182H14.3749L17.0698 20.1905V13.4182H19.667L21.7485 18.2739L23.6669 13.4182H26.2511V20.5846H24.6786L24.6658 14.969L22.3733 20.5846H20.9667L18.6614 14.969V20.5846H15.4266L14.8133 18.978H11.5L10.888 20.5832H9.15483L12.004 13.4182ZM27.6897 13.4182H34.0836L36.0391 15.7646L38.0577 13.4182H40.0133L37.042 17.02L40.0133 20.5804H37.969L36.0135 18.2067L33.9846 20.5804H27.6897V13.4182ZM13.1574 14.6307L12.0658 17.4928H14.2477L13.1574 14.6307ZM29.2686 14.902V16.2102H32.7568V17.6684H29.2686V19.0966H33.1811L34.999 16.993L33.2582 14.9008H29.2686V14.902Z'
				fill='white'
			/>
		</svg>
	);
}
