// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".faq-module_faq-q4iRk{padding:80px 0}.faq-module_faq-q4iRk.faq-module_hasGreyBackground-09IeS{background-color:var(--grey-100)}@media(min-width: 769px){.faq-module_faq-q4iRk{padding:100px 0 118px}}.faq-module_faq-q4iRk .faq-module_accordions-TfoP0{margin-top:12px;font:var(--regular) var(--body-3)}.faq-module_faq-q4iRk .faq-module_accordions-TfoP0>*{padding-bottom:32px}.faq-module_faq-q4iRk .faq-module_answer-RGyA8{margin-bottom:12px;color:var(--grey-600)}.faq-module_faq-q4iRk .faq-module_answer-RGyA8 a{text-decoration:underline}", "",{"version":3,"sources":["webpack://./../../packages/ui/components/home/faq/faq.module.scss"],"names":[],"mappings":"AAEA,sBACC,cAAA,CACA,yDACC,gCAAA,CAGD,yBAND,sBAOE,qBAAA,CAAA,CAGD,mDACC,eAAA,CACA,iCAAA,CAEA,qDACC,mBAAA,CAIF,+CACC,kBAAA,CACA,qBAAA,CACA,iDACC,yBAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.faq {\n\tpadding: 80px 0;\n\t&.hasGreyBackground {\n\t\tbackground-color: var(--grey-100);\n\t}\n\n\t@media (min-width: #{styles.$tablet + 1px}) {\n\t\tpadding: 100px 0 118px;\n\t}\n\n\t.accordions {\n\t\tmargin-top: 12px;\n\t\tfont: var(--regular) var(--body-3);\n\n\t\t> * {\n\t\t\tpadding-bottom: 32px;\n\t\t}\n\t}\n\n\t.answer {\n\t\tmargin-bottom: 12px;\n\t\tcolor: var(--grey-600);\n\t\ta {\n\t\t\ttext-decoration: underline;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"faq": "faq-module_faq-q4iRk",
	"hasGreyBackground": "faq-module_hasGreyBackground-09IeS",
	"accordions": "faq-module_accordions-TfoP0",
	"answer": "faq-module_answer-RGyA8"
};
module.exports = ___CSS_LOADER_EXPORT___;
