// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".items_pagination-module_showMore-FmRHg{display:flex;align-items:center;justify-content:flex-end;font:var(--semibold) var(--body-4);padding-top:18px;margin:0;cursor:pointer}.items_pagination-module_showMore-FmRHg .items_pagination-module_showMoreText-6dAHR{margin:0}.items_pagination-module_showMore-FmRHg .items_pagination-module_showMoreIcon-tLxZS{margin-top:6px}", "",{"version":3,"sources":["webpack://./../../packages/components/items_list/items_pagination.module.scss"],"names":[],"mappings":"AAEA,wCACC,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,kCAAA,CACA,gBAAA,CACA,QAAA,CACA,cAAA,CAEA,oFACC,QAAA,CAGD,oFACC,cAAA","sourcesContent":["@use '@planity/ui/styles/utilities/variables.scss';\n\n.showMore {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: flex-end;\n\tfont: var(--semibold) var(--body-4);\n\tpadding-top: 18px;\n\tmargin: 0;\n\tcursor: pointer;\n\n\t.showMoreText {\n\t\tmargin: 0;\n\t}\n\n\t.showMoreIcon {\n\t\tmargin-top: 6px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"showMore": "items_pagination-module_showMore-FmRHg",
	"showMoreText": "items_pagination-module_showMoreText-6dAHR",
	"showMoreIcon": "items_pagination-module_showMoreIcon-tLxZS"
};
module.exports = ___CSS_LOADER_EXPORT___;
