import { withLocalization } from '@planity/localization';
import { withTranslation } from '@planity/localization';
import React, { Component } from 'react';
import { firebase } from '@planity/datastores';
import { ErrorMessage, Localize, Stars, withAuth } from '@planity/components';
import { format, parseISO } from 'date-fns';
import { Button, Textarea } from '@planity/ui';
import { withModal } from '@planity/ui/context';
import styles from './review_form.module.scss';
import withStyles from 'isomorphic-style-loader/withStyles';
import { AnswerPreview } from './answer_preview';
import { RejectedReview } from './rejected_review';
import classNames from 'classnames/bind';
import { invokeLambda } from '@planity/helpers/browser';

const MISSING_RATING_CRITERIA = 'reviewForm.errors.missingRating';
const MISSING_PSEUDO = 'reviewForm.errors.noPseudo';
const PSEUDO_TOO_LONG = 'reviewForm.errors.pseudoTooLong';
const ERROR_DELETE_REVIEW = 'reviewForm.errors.deleteReview';
const WHITELISTED_ERRORS = [
	MISSING_RATING_CRITERIA,
	MISSING_PSEUDO,
	PSEUDO_TOO_LONG,
	ERROR_DELETE_REVIEW
];

function getDetail(review) {
	return review.ratingDetails || review.ratingsDetail;
}

const CHARACTER_LIMIT = 200;

class ReviewForm extends Component {
	state = {
		welcomeRating: this.props.pastReview
			? getDetail(this.props.pastReview).welcomeRating
			: 0,
		ambianceRating: this.props.pastReview
			? getDetail(this.props.pastReview).ambianceRating
			: 0,
		hygieneRating: this.props.pastReview
			? getDetail(this.props.pastReview).hygieneRating
			: 0,
		benefitRating: this.props.pastReview
			? getDetail(this.props.pastReview).benefitRating
			: 0,
		reviewComment: this.props.pastReview
			? this.props.pastReview.reviewBody
			: '',
		error: null,
		isLoading: false,
		pseudoIsEditable: false,
		newReviewIsInModeration: false,
		wasReviewDeleted: false
	};

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	updateComment = comment => {
		this.setState({
			reviewComment: comment
		});
	};

	render() {
		const {
			reviewComment,
			error,
			wasReviewDeleted,
			newReviewIsInModeration,
			isLoading
		} = this.state;
		const { pastReview, locale, appointmentInfo, t, user } = this.props;
		const appointmentStart = appointmentInfo && parseISO(appointmentInfo.start);
		const formattedAppointmentStart = new Intl.DateTimeFormat(locale, {
			month: 'long',
			day: 'numeric'
		}).format(appointmentStart);
		const cx = classNames.bind(styles);

		return (
			<div className={styles.formWrapper}>
				<div className={styles.formTitleDesktop}>
					<h2 className={cx({ formMainTitle: true, tablet: true })}>
						{pastReview ? (
							<Localize text={'myAccount.appointment.updateReview'} />
						) : (
							<Localize text={'myAccount.appointment.leaveReview'} />
						)}
					</h2>
					<div
						className={styles.formTitleBis}
						id='appointment-review-form-title'
					>
						<Localize
							args={{
								date: formattedAppointmentStart,
								name: this.props.business?.name
							}}
							text={'reviewForm.formTitle'}
						/>
					</div>
				</div>

				<form className={styles.form} id='appointment-review-form'>
					<div className={cx({ greyTitleWrapper: true, mobile: true })}>
						<h2>
							<Localize text={'reviewForm.gradeTitle'} />
						</h2>
					</div>
					<div className={styles.reviewsWrapper}>
						{[
							'welcomeRating',
							'ambianceRating',
							'hygieneRating',
							'benefitRating'
						].map(ratingDetail => (
							<div className={styles.reviewWrapper} key={ratingDetail}>
								<div
									className={styles.reviewTitle}
									id={`appointment-review-form-${ratingDetail}`}
								>
									<Localize text={`reviewForm.ratings.${ratingDetail}`} />
								</div>
								<div
									className={styles.starsWrapper}
									id='appointment-review-preview-stars'
								>
									<Stars
										clastName={styles.stars}
										showEvaluation
										value={this.state[ratingDetail]}
										withMargin
										onChange={rating =>
											this.setState({
												[ratingDetail]: rating,
												error: null
											})
										}
									/>
								</div>
							</div>
						))}
					</div>
					<div className={styles.formContainer}>
						<div
							className={cx({
								greyTitleWrapper: true,
								mobile: true,
								secondQuestion: true
							})}
						>
							<h2>
								<Localize text={'reviewForm.secondQuestion'} />
							</h2>
						</div>

						<div className={styles.formInnerWrapper}>
							<div
								className={cx({
									subtitle: true,
									tablet: true
								})}
							>
								<Localize text={'reviewForm.secondQuestion'} />
							</div>
							<div
								className={cx({
									subtitle: true,
									mobile: true
								})}
							>
								<Localize text={'myAccount.appointment.yourComment'} />
							</div>
							<Textarea
								characterLimit={CHARACTER_LIMIT}
								id={'appointment-review-form-textarea'}
								isSubstractingCharacters
								minHeight={100}
								placeholder={t('reviewForm.commentPlaceholder')}
								value={reviewComment}
								onChange={this.updateComment}
							/>

							<div
								className={styles.reviewAuthor}
								id='appointment-review-form-publishedBy'
							>
								<Localize text={'reviewForm.publishedBy'} />
								{user?.firstName} {user?.lastName?.charAt(0)}
							</div>

							<div
								className={cx({
									reviewWarning: true,
									reviewWarningDesk: true
								})}
							>
								<Localize text={'reviewForm.reviewWarning1'} />
							</div>

							<div className={styles.reviewResponse}>
								{pastReview?.reviewAnswer && (
									<AnswerPreview review={pastReview} />
								)}
								{pastReview?.wasApproved === 'rejected' && (
									<RejectedReview review={pastReview} />
								)}
							</div>
						</div>
					</div>
					<div className={styles.actionButtonsWrapper}>
						{pastReview && (
							<div className={styles.deleteButtonWrapper}>
								<Button
									id={'appointment-review-form-deleteMessage'}
									isFullMobile
									label={
										<Localize
											text={
												isLoading
													? 'reviewForm.loadingSubmitMessage'
													: 'reviewForm.deleteMessage'
											}
										/>
									}
									type='danger'
									onClick={this.onDelete}
								/>
							</div>
						)}
						<div className={styles.submitButtonWrapper}>
							<Button
								id={'appointment-review-form-submitMessage'}
								isFullMobile
								label={
									<Localize
										text={
											isLoading
												? 'reviewForm.loadingSubmitMessage'
												: pastReview
												? 'reviewForm.updateMessage'
												: 'reviewForm.submitMessage'
										}
									/>
								}
								type='primary'
								onClick={this.onSubmit}
							/>
							<div
								className={cx({
									reviewWarning: true,
									reviewWarningMobile: true
								})}
							>
								<Localize text={'reviewForm.reviewWarning1'} />
							</div>
						</div>
					</div>
				</form>

				{error && (
					<ErrorMessage
						defaultMessage={`reviewForm.reviewDeletedError`}
						error={error}
						whitelist={WHITELISTED_ERRORS}
					/>
				)}

				{newReviewIsInModeration && !error && (
					<ErrorMessage
						error={`reviewForm.${
							!pastReview ? 'newReview' : 'review'
						}SentSuccess`}
						errorType={'success'}
					/>
				)}
				{wasReviewDeleted && !error && (
					<ErrorMessage
						error={`reviewForm.reviewDeletedSuccess`}
						errorType={'success'}
					/>
				)}
			</div>
		);
	}

	getError = ({
		welcomeRating,
		ambianceRating,
		hygieneRating,
		benefitRating
	}) => {
		if (!welcomeRating || !ambianceRating || !hygieneRating || !benefitRating) {
			return MISSING_RATING_CRITERIA;
		}
	};

	onSubmit = e => {
		e?.preventDefault();
		const {
			welcomeRating,
			ambianceRating,
			hygieneRating,
			benefitRating,
			reviewComment,
			error,
			isLoading
		} = this.state;

		const {
			itemReviewed,
			appointmentInfo,
			appointmentId,
			userId,
			closeModal,
			pastReview
		} = this.props;

		if (!error && !isLoading) {
			const newError = this.getError({
				welcomeRating,
				ambianceRating,
				hygieneRating,
				benefitRating
			});
			if (newError) {
				this.setState({ error: newError });
			} else {
				this.setState(
					{
						isLoading: true,
						newReviewIsInModeration: true
					},
					() => {
						writePendingReviewApprovals({
							ratingDetails: {
								welcomeRating,
								ambianceRating,
								hygieneRating,
								benefitRating
							},
							reviewComment,
							reviewAnswer: pastReview?.reviewAnswer,
							appointmentInfo,
							appointmentId,
							itemReviewed,
							userId
						})
							.then(response => {
								if (this._isMounted) {
									if (response && response.errorType) {
										const error = response.errorType;
										this.setState({
											isLoading: false,
											newReviewIsInModeration: false,
											error
										});
									} else {
										this.setState({
											isLoading: false,
											newReviewIsInModeration: false
										});
										closeModal();
									}
								}
							})
							.catch(error => {
								if (this._isMounted) {
									this.setState({
										isLoading: false,
										newReviewIsInModeration: false,
										error
									});
								}
							});
					}
				);
			}
		}
	};
	onDelete = e => {
		e?.preventDefault();
		const { error, isLoading } = this.state;
		const { appointmentInfo, appointmentId, pastReview, closeModal } =
			this.props;
		if (!error && !isLoading) {
			this.setState(
				{
					isLoading: true
				},
				() => {
					invokeLambda('deleteBusinessReview', {
						reviewInfo: pastReview,
						reviewId: appointmentId,
						appointmentInfo: {
							businessId: appointmentInfo.businessId,
							sequence: appointmentInfo.sequence,
							start: appointmentInfo.start
						}
					})
						.then(response => {
							if (this._isMounted) {
								if (response && response.statusCode) {
									this.setState({
										isLoading: false,
										wasReviewDeleted: true
									});
									closeModal();
								} else if (response.errorMessage) {
									this.setState({
										isLoading: false,
										wasReviewDeleted: false,
										error: ERROR_DELETE_REVIEW
									});
								}
							}
						})
						.catch(error => {
							if (this._isMounted) {
								this.setState({
									isLoading: false,
									wasReviewDeleted: false,
									error: ERROR_DELETE_REVIEW
								});
							}
						});
				}
			);
		}
	};
}

function writePendingReviewApprovals({
	ratingDetails,
	reviewComment,
	reviewAnswer,
	appointmentInfo,
	appointmentId,
	itemReviewed,
	userId
}) {
	const { start, businessId, originalSequence } = appointmentInfo;

	const updates = {
		[`business_pending_reviews/${appointmentInfo.businessId}/${appointmentId}`]:
			{
				ratedAt: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
				appointmentInfo: {
					start,
					businessId,
					sequence: originalSequence //* originalSequence is not mutated identical to the one in firebase
				},
				wasApproved: false,
				reviewRating: average(ratingDetails),
				reviewBody: reviewComment,
				reviewAnswer: null,
				author: {
					userId,
					userPseudo: '',
					email: null,
					name: ''
				},
				itemReviewed: itemReviewed
					? itemReviewed
					: 'Pas de détails sur la prestation réalisée',
				ratingDetails,
				reviewedByAdmin: null
			},
		[`user_vevents/${userId}/${appointmentId}/review`]: {
			ratedAt: new Date(),
			author: {
				userId,
				userPseudo: '',
				email: null
			},
			wasApproved: false,
			reviewRating: average(ratingDetails),
			reviewBody: reviewComment,
			itemReviewed: itemReviewed
				? itemReviewed
				: 'Pas de détails sur la prestation réalisée',
			ratingDetails,
			reviewedByAdmin: null,
			reviewAnswer: null
		}
	};
	return firebase.database().ref().update(updates);
}

function average(values) {
	let sum = 0;
	const divider = Object.keys(values).reduce((acc, value) => {
		sum = sum + values[value];
		if (values[value] > 0) {
			acc.push(value);
		}
		return acc;
	}, []).length;
	return sum / divider ? sum / divider : 0;
}

export default withAuth(
	withLocalization(withTranslation()(withStyles(styles)(withModal(ReviewForm))))
);
