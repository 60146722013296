import {
	INVALID_PASSWORD_ERROR as invalidPasswordError,
	MISSING_PASSWORD_ERROR as missingPasswordError
} from '@planity/components';

export const PASSWORD_CHANGE = 'PASSWORD_CHANGE';
export const SUBMIT = 'SUBMIT';
export const REAUTH_WITH_FACEBOOK = 'REAUTH_WITH_FACEBOOK';
export const RESET = 'RESET';
export const INVALID_PASSWORD_ERROR = invalidPasswordError;
export const MISSING_PASSWORD_ERROR = missingPasswordError;
