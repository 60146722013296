import React from 'react';
import { Button, useModal } from '@planity/ui';
import { SetChildModal } from './set_child_modal';

const MAX_CHILDREN = 10;

export const AddChildButton = props => {
	const {
		parent,
		userChildren,
		handleChildCreation,
		label,
		isFullMobile = false
	} = props;

	const { setModal } = useModal();

	const hasMaxChildren = userChildren.length >= MAX_CHILDREN;

	return (
		<Button
			iconLeft={'UserAdd'}
			id={'add-child-button'}
			isDisabled={hasMaxChildren}
			isFullMobile={isFullMobile}
			label={label}
			onClick={() => {
				setModal({
					className: 'isChildModal',
					content: (
						<SetChildModal
							parent={parent}
							userChildren={userChildren}
							onConfirmClick={handleChildCreation}
						/>
					),
					isFullHeight: true
				});
			}}
		/>
	);
};
