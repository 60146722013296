import { noop } from '@planity/helpers';
import { useLocalization } from '@planity/localization';
import { useCache } from 'planity-website/app/components/data';
import { Layout } from 'planity-website/app/components/layout';
import React, { useCallback, useEffect, useState } from 'react';
import { getGooglePlace } from '@planity/datastores';
import CreateFetcher from '../create_fetcher';
import { Spinner } from '@planity/ui';

export default function GooglePlaceById({ useCache: _useCache, id, children }) {
	const { googleSearchableCountries: countries } = useLocalization();
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true);
	}, []);

	const cache = useCache();
	const fetch = useCallback(
		({ emit }) => {
			const idWas = id;
			getGooglePlace({ placeId: idWas, countries }, 3).then(place => {
				if (id === idWas) {
					emit({ place });
				}
			});
			return noop;
		},
		[id]
	);

	if (!process.env.BROWSER || !isMounted)
		return (
			<Layout>
				<div css={styles.spinner}>
					<Spinner />
				</div>
			</Layout>
		);
	return (
		<CreateFetcher
			cacheProvider={_useCache && cache}
			key={id}
			query={id}
			subscribe={fetch}
		>
			{children}
		</CreateFetcher>
	);
}

const styles = {
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
};
