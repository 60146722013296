import { useLocalizedRoutes, useTranslation } from '@planity/localization';
import {
	FakeSearchBar,
	SearchBarButton,
	WhatSearchBar,
	WhereSearchBar
} from '@planity/ui';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useSearch } from '@planity/context';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './home_search_bar.module.scss';

export const HomeSearchBar = ({ className }) => {
	const history = useHistory();
	useStyles(styles);
	const [whatResult, setWhatResult] = useState('');
	const [whereResult, setWhereResult] = useState(null);

	const whereSearchBarRef = useRef(null);

	const { setSearch } = useSearch();
	const { t } = useTranslation();
	const { routes } = useLocalizedRoutes();

	const classes = classNames.bind(styles);

	const onSubmit = useCallback(() => {
		if (whatResult) {
			setSearch(
				{
					category: whatResult?.isCategory ? whatResult : null,
					googlePlace: whereResult,
					place: null,
					parentPlace: null
				},
				true,
				'push'
			);
		}
	}, [whereResult, whatResult, setSearch]);

	useEffect(() => {
		if (whatResult) whereSearchBarRef?.current?.focus();
	}, [whatResult]);
	useEffect(() => {
		if (whereResult && whatResult) {
			onSubmit();
		}
	}, [whereResult, whatResult, onSubmit]);

	return (
		<div
			className={classes({
				homeSearchBar: true,
				[className]: className
			})}
		>
			<div className={styles.container}>
				<WhatSearchBar
					onSelect={onSubmit}
					setWhatResult={setWhatResult}
					displayOnlyOnDesktop
				/>
				<FakeSearchBar
					label={t('search.placeholders.category')}
					placeholder={t('search.placeholders.business')}
					displayOnlyOnMobileAndTablet
					onClick={() => history.push(routes.searchBusiness)}
				/>
				<div className={classes({ separator: true })} />
				<WhereSearchBar
					ref={whereSearchBarRef}
					displayOnlyOnDesktop
					setWhereResult={setWhereResult}
				/>
				<SearchBarButton
					id={'home-search-button'}
					onClick={onSubmit}
					onIconClick={() => history.push(routes.searchBusiness)}
				/>
			</div>
		</div>
	);
};
