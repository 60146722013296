import memoize from 'fast-memoize';
import { byId, leaves, sortedTree } from './helpers';

export const businessCalendars = memoize(business =>
	leaves({
		tree: business.calendars,
		keepSet: ({ set }) => !set.deletedAt,
		keepLeaf: ({ leaf }) => !leaf.deletedAt,
		forwardSetProps: ({ set, setId }) => ({
			calendarSetId: setId,
			calendarSetType: set.system
		})
	})
);

export const businessCalendarsById = memoize(business =>
	byId(businessCalendars(business))
);

export const businessCalendar = memoize(
	(business, calendarId) => businessCalendarsById(business)[calendarId]
);

export const businessWebVisibleCalendars = memoize(business =>
	leaves({
		tree: business.calendars,
		keepSet: ({ set }) => !set.deletedAt && set.system === 'people',
		keepLeaf: ({ leaf }) => !leaf.deletedAt && !leaf.webHidden,
		forwardProps: ({ setId }) => ({
			calendarSetId: setId
		})
	})
);

export const businessWebVisibleCalendarsById = memoize(business =>
	byId(businessWebVisibleCalendars(business))
);

export const businessSortedCalendarSets = memoize(business =>
	sortedTree({
		tree: business.calendars,
		keepSet: ({ node }) => !node.deletedAt,
		keepLeaf: ({ node }) => !node.deletedAt,
		forwardProps: ({ setId }) => ({ calendarSetId: setId })
	})
);

export const businessSortedWebVisibleCalendarSets = memoize(business =>
	sortedTree({
		tree: business.calendars,
		keepSet: ({ node }) => !node.deletedAt && node.system === 'people',
		keepLeaf: ({ node }) => !node.deletedAt && !node.webHidden,
		forwardProps: ({ setId }) => ({ calendarSetId: setId })
	})
);
