export function sanitizeName(str, flags = ['/eDiagnostic/']) {
	if (!str || typeof str !== 'string') return str;
	const nameWithoutSpace = str.slice().trim();
	if (Array.isArray(flags) && flags.length) {
		if (flags.includes(str)) {
			return nameWithoutSpace;
		}
	}
	const REGEX_LOWERCASE_CHARACTER = /[a-z]/;
	const checkFirstLowerCaseCharacter = str =>
		REGEX_LOWERCASE_CHARACTER.test(str);
	//if there is at least an emoji in the string
	if (nameWithoutSpace.codePointAt(0) > 255) {
		const myStringArray = Array.from(nameWithoutSpace);
		const myNewStringArray = myStringArray.map(value => {
			if (value.codePointAt() <= 255 && value !== ' ') {
				return value;
			}
		});
		const myNewName = myNewStringArray.join('');
		const firstCharacter = myNewName.charAt(0);
		// if the first character is in lowercase
		if (checkFirstLowerCaseCharacter(firstCharacter)) {
			const indexLowerCaseCharacter = myNewStringArray.indexOf(firstCharacter);
			const firstCharacterInUppercase = firstCharacter.toUpperCase();
			const othersCharacters = myStringArray.slice(indexLowerCaseCharacter + 1);
			const emojis = myStringArray.slice(0, indexLowerCaseCharacter);
			return emojis
				.concat(firstCharacterInUppercase, othersCharacters)
				.join('');
		}
		return nameWithoutSpace;
	}
	const firstCharacterInName = nameWithoutSpace.charAt(0);
	//if the first character is in lowercase
	if (checkFirstLowerCaseCharacter(firstCharacterInName)) {
		const othersCharacters = nameWithoutSpace.slice(1);
		return firstCharacterInName.toUpperCase().concat(othersCharacters);
	}
	return nameWithoutSpace;
}
