import { Machine, assign } from 'xstate';
import { firebase } from '@planity/datastores';
import {
	INVALID_PASSWORD_ERROR,
	MISSING_PASSWORD_ERROR,
	PASSWORD_CHANGE,
	SUBMIT,
	REAUTH_WITH_FACEBOOK,
	RESET
} from './constants';

const checkPassword = ({ password }) =>
	new Promise((resolve, reject) => {
		if (!password.length) reject({ code: MISSING_PASSWORD_ERROR });
		if (password.length < 6) reject({ code: INVALID_PASSWORD_ERROR });
		resolve();
	});

const createPassword = ({ password }) =>
	firebase.auth().currentUser.updatePassword(password);
const linkUserWithCred = ({ email, password }) => {
	const credential = firebase.auth.EmailAuthProvider.credential(
		email,
		password
	);
	return firebase
		.auth()
		.currentUser.linkAndRetrieveDataWithCredential(credential);
};

const reauthenticateUserWithFacebook = () =>
	firebase
		.auth()
		.currentUser.reauthenticateWithPopup(
			new firebase.auth.FacebookAuthProvider()
		);

const IGNORED_ERRORS_CODES = [
	'auth/popup-closed-by-user',
	'auth/user-cancelled'
];

export const createPasswordMachine = ({ email, callback }) =>
	Machine(
		{
			id: 'createPasswordMachine',
			initial: 'idle',
			context: {
				email,
				password: '',
				error: null,
				pending: false
			},
			states: {
				idle: {
					entry: ['logState', 'setIsNotPending'],
					on: {
						[PASSWORD_CHANGE]: {
							actions: 'setPassword'
						},
						[SUBMIT]: {
							target: 'checkingPassword'
						}
					}
				},
				checkingPassword: {
					entry: ['logState', 'setIsPending'],
					invoke: {
						id: 'checkPassword',
						src: checkPassword,
						onError: {
							actions: 'setError',
							target: 'idle'
						},
						onDone: [
							{
								cond: 'userIsNotClean',
								actions: 'setError',
								target: 'idle'
							},
							{
								cond: 'userIsKindOfAnonymous',
								target: 'linkingUserWithCred'
							},
							{
								target: 'creatingPassword'
							}
						]
					}
				},
				creatingPassword: {
					entry: 'logState',
					invoke: {
						id: 'createPassword',
						src: createPassword,
						onError: [
							{
								cond: 'mustReauth',
								target: 'askingUserToReauthenticateWithFacebook'
							},
							{
								actions: ['setIsNotPending', 'setError'],
								target: 'idle'
							}
						],
						onDone: 'success'
					}
				},
				linkingUserWithCred: {
					entry: 'logState',
					invoke: {
						id: 'linkUserWithCred',
						src: linkUserWithCred,
						onError: [
							{
								cond: 'mustReauth',
								target: 'askingUserToReauthenticateWithFacebook'
							},
							{
								actions: ['setIsNotPending', 'setError'],
								target: 'idle'
							}
						],
						onDone: 'success'
					}
				},
				askingUserToReauthenticateWithFacebook: {
					entry: ['logState', 'setIsNotPending'],
					on: {
						[REAUTH_WITH_FACEBOOK]: {
							target: 'reauthenticatingUserWithFacebook'
						},
						[RESET]: {
							actions: ['clearError'],
							target: 'idle'
						}
					}
				},
				reauthenticatingUserWithFacebook: {
					entry: ['logState', 'setIsPending'],
					invoke: {
						id: 'reauthenticateUserWithFacebook',
						src: reauthenticateUserWithFacebook,
						onError: {
							actions: ['setIsNotPending', 'setError'],
							target: 'idle'
						},
						onDone: 'linkingUserWithCred'
					}
				},
				success: {
					entry: ['logState', 'setIsNotPending', 'clearError', 'callback'],
					type: 'final'
				}
			}
		},
		{
			actions: {
				setPassword: assign({
					password: (_, { password }) => password
				}),
				setError: assign({
					error: (_, { data }) => {
						if (IGNORED_ERRORS_CODES.includes(data.code)) return null;
						else return data.code;
					}
				}),
				clearError: assign({
					error: null
				}),
				setIsNotPending: assign({
					pending: false
				}),
				setIsPending: assign({
					pending: true
				}),
				callback: () => {
					if (callback && typeof callback === 'function') {
						const hasAPassword = !!firebase
							.auth()
							.currentUser.providerData.find(
								({ providerId: id }) => id === 'password'
							);
						callback(hasAPassword);
					}
				},
				logState: (
					{ password, passwordConfirmation, value, ...context },
					_,
					{ state }
				) => {
					if (process.env.NODE_ENV === 'development') {
						console.log(
							`🌟 Machine dans l'état : %c${state.value}`,
							'background: #a0e7a0; color:black;font-weight: bold'
						);
						console.log({
							...context,
							password: password ? password.replace(/./g, '*') : undefined
						});
					}
				}
			},
			guards: {
				userIsNotClean: ({ email }) => !email || !email.length, // This should never ever happen
				userIsKindOfAnonymous: () => !firebase.auth().currentUser.email,
				mustReauth: (_, { data }) => data.code === 'auth/requires-recent-login'
			}
		}
	);
