export const sendAnalytics = (eventLabel, eventCategory, eventAction) => () => {
	if (!window) return;
	if (process.env.BROWSER) {
		if (process.env.USE_GOOGLE_ANALYTICS && window.ga) {
			window.ga('send', {
				hitType: 'event',
				eventCategory,
				eventAction,
				eventLabel
			});
		}
		if (process.env.USE_GOOGLE_ANALYTICS_V4 && window.gtag) {
			window.gtag('event', eventAction, {
				event_category: eventCategory,
				event_label: eventLabel
			});
		}
	}
};
