import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { PropagateSearch } from '@planity/context';
import { CategoryPageSearchBar } from '@planity/ui';
import { useTranslation } from '@planity/localization';
import { safeRead } from '@planity/helpers';
import { Title } from './title';
import styles from './search_bar.module.scss';

export const SearchBar = withRouter(
	({ search, title: _title, isFrance, className }) => {
		const { t } = useTranslation();
		const classes = classNames.bind(styles)({
			searchBar: true,
			className
		});
		useStyles(styles);
		const category = safeRead(search, ['parentCategory']);

		const text =
			!!isFrance && safeRead(category, ['objectID']) === 'beauty_salon'
				? 'beauty'
				: 'noBeauty';

		const title =
			_title ||
			safeRead(category, ['title']) ||
			t(`category.title.${text}`, {
				preposition: preposition(category),
				article: article(category),
				singular: singular(category)
			});

		return (
			<div className={classes}>
				<section className={styles.content}>
					<Title
						className={`${styles.title} ${styles[category?.objectID]}`}
						dangerouslyHTML={{ __html: title }}
						id={'title-category-page'}
					/>
					<PropagateSearch>
						<CategoryPageSearchBar category={category} />
					</PropagateSearch>
				</section>
			</div>
		);
	}
);

// Should be used only for french categories (and I'm not even totally sure they do)
function singular(category) {
	if (!category) return '';
	return category.singular || (category.name || '').toLowerCase();
}

// Should be used only for french categories (and I'm not even totally sure they do)
function preposition(category) {
	if (!category || category.isBusinessCategory) {
		return '';
	}
	return category.isType ? 'avec' : 'pour';
}

// Should be used only for french categories (and I'm not even totally sure they do)
function article(category) {
	if (!category || category.isBusinessCategory) {
		return '';
	}
	return category.isFeminine ? 'une' : 'un';
}
