// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(min-width: 769px){.logo-module_logo-RZa6g{width:140px;height:16px}}@media(max-width: 768px){.logo-module_logo-RZa6g{width:120px;height:16px}}", "",{"version":3,"sources":["webpack://./../../packages/ui/uikit/images/logo/logo.module.scss"],"names":[],"mappings":"AAGC,yBADD,wBAEE,WAAA,CACA,WAAA,CAAA,CAGD,yBAND,wBAOE,WAAA,CACA,WAAA,CAAA","sourcesContent":["@use '@planity/ui/styles/utilities/variables.scss';\n\n.logo {\n\t@media (min-width: #{variables.$tablet + 1px}) {\n\t\twidth: 140px;\n\t\theight: 16px;\n\t}\n\n\t@media (max-width: variables.$tablet) {\n\t\twidth: 120px;\n\t\theight: 16px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"logo": "logo-module_logo-RZa6g"
};
module.exports = ___CSS_LOADER_EXPORT___;
