// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".search_bar-module_searchBar-hI4gy{width:100%}.search_bar-module_searchBar-hI4gy .search_bar-module_content-qM0DT{display:flex;align-items:center;flex-direction:column;justify-content:center}.search_bar-module_searchBar-hI4gy .search_bar-module_title-EIhFZ{text-align:center}.search_bar-module_searchBar-hI4gy .search_bar-module_well_being-szpiL span{color:var(--Healness-500, #5bab72)}", "",{"version":3,"sources":["webpack://./app/components/category_page/search_bar.module.scss"],"names":[],"mappings":"AAAA,mCACC,UAAA,CAEA,oEACC,YAAA,CACA,kBAAA,CACA,qBAAA,CACA,sBAAA,CAGD,kEACC,iBAAA,CAGD,4EACC,kCAAA","sourcesContent":[".searchBar {\n\twidth: 100%;\n\n\t.content {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tflex-direction: column;\n\t\tjustify-content: center;\n\t}\n\n\t.title {\n\t\ttext-align: center;\n\t}\n\n\t.well_being span {\n\t\tcolor: var(--Healness-500, #5bab72);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBar": "search_bar-module_searchBar-hI4gy",
	"content": "search_bar-module_content-qM0DT",
	"title": "search_bar-module_title-EIhFZ",
	"well_being": "search_bar-module_well_being-szpiL"
};
module.exports = ___CSS_LOADER_EXPORT___;
