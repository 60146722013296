import React from 'react';
import { Localize } from '@planity/components';
import { Card, Icon } from '@planity/ui';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';
import styles from './how_to.module.scss';

export function HowTo({
	countryCode,
	displayClickAndCollect,
	displayClickAndShop,
	isModal
}) {
	const { t } = useTranslation();
	useStyles(styles);
	const classes = classNames.bind(styles)({
		howTo: true,
		isModal: isModal
	});

	const nameSpace =
		displayClickAndShop && displayClickAndCollect
			? 'clickAndShopAndCollect'
			: displayClickAndShop
			? 'clickAndShop'
			: 'clickAndCollect';

	return (
		<div className={classes}>
			<Card
				className={styles.howToCard}
				hasPadding={!isModal}
				hideBorders
				title={t('clickAndCollect.howTo')}
			>
				<div className={styles.content}>
					<h4>{t('clickAndCollect.modal.howToTitle')}</h4>
					<ul>
						<li>
							<Localize
								args={{ displayClickAndCollect, displayClickAndShop }}
								text={`clickAndCollect.modal.${nameSpace}.step1`}
							/>
						</li>
						<li>
							<Localize
								args={{ displayClickAndCollect, displayClickAndShop }}
								text={`clickAndCollect.modal.${nameSpace}.step2`}
							/>
						</li>
						<li>
							<Localize
								args={{ displayClickAndCollect, displayClickAndShop }}
								text={`clickAndCollect.modal.${nameSpace}.step3`}
							/>
						</li>
					</ul>

					<h4>
						<Localize text={`clickAndCollect.modal.${nameSpace}.advantages`} />
					</h4>
					<ul>
						<li>
							<Icon className={styles.listIcon} icon='Timer' size={'medium'} />
							<Localize
								text={`clickAndCollect.modal.${nameSpace}.advantages2`}
							/>
						</li>
						<li>
							<Icon className={styles.listIcon} icon='Store' size={'medium'} />
							<Localize
								text={`clickAndCollect.modal.${nameSpace}.advantages3`}
							/>
						</li>
						<li>
							<Icon className={styles.listIcon} icon='Crown' size={'medium'} />
							<Localize
								text={`clickAndCollect.modal.${nameSpace}.advantages4`}
							/>
						</li>
						<li>
							<Icon
								className={styles.listIcon}
								icon='CreditCard'
								size={'medium'}
							/>
							<Localize
								text={
									countryCode === 'BE'
										? `clickAndCollect.modal.${nameSpace}.advantages6`
										: `clickAndCollect.modal.${nameSpace}.advantages5`
								}
							/>
						</li>
					</ul>
				</div>
			</Card>
		</div>
	);
}
