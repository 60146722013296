export const USER_ADDED_STEP = 'USER_ADDED_STEP';
export const USER_CHOSE_STEP_SERVICE = 'USER_CHOSE_STEP_SERVICE';
export const USER_CHOSE_STEP_CALENDAR = 'USER_CHOSE_STEP_CALENDAR';
export const USER_CHOSE_SUBSTEP_CALENDAR = 'USER_CHOSE_SUBSTEP_CALENDAR';
export const USER_REMOVED_STEP = 'USER_REMOVED_STEP';
export const USER_CHOSE_DATE = 'USER_CHOSE_DATE';
export const USER_REMOVED_DATE = 'USER_REMOVED_DATE';
export const USER_CHOSE_PAST_APPOINTMENT = 'USER_CHOSE_PAST_APPOINTMENT';
export const USER_ADD_COMMENT = 'USER_ADD_COMMENT';
export const USER_CHOSE_STEP_CALENDAR_NEW = 'USER_CHOSE_STEP_CALENDAR_NEW';
