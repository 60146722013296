// 🤷
export function ChevronBottom() {
	return (
		<svg
			width='6'
			height='4'
			viewBox='0 0 6 4'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M3 4L-8.26528e-07 5.24537e-07L6 0L3 4Z' fill='currentColor' />
		</svg>
	);
}
