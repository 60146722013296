import algolia from 'algoliasearch';
import planityCredentials from '@planity/credentials';

const envCredentials = {
	appId: planityCredentials.ALGOLIA_APP_ID,
	apiKey: planityCredentials.ALGOLIA_API_KEY
};

const productionCredentials = {
	appId: planityCredentials.ALGOLIA_PRODUCTION_APP_ID,
	apiKey: planityCredentials.ALGOLIA_PRODUCTION_API_KEY
};

const PRODUCTION_INDICES = {
	products: true
};

export default function getClient({ index, apiKey }) {
	const { appId, apiKey: envAPIKey } =
		index && PRODUCTION_INDICES[index] ? productionCredentials : envCredentials;
	const client = algolia(appId, apiKey || envAPIKey);
	return index ? client.initIndex(index) : client;
}
