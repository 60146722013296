import React, { createContext, useContext, useMemo } from 'react';
import { Route } from 'react-router-dom';

const RandomIdContext = createContext();

/**
 * This provider is made to have an isomorphic random number, meaning a random
 * number that is the same on client and server side
 */
export function RandomIdProvider({ children }) {
	const id = useMemo(getInitialState, []);
	return (
		<Route
			render={({ staticContext }) => {
				if (staticContext) {
					staticContext.randomId = id; // Mutation but Paul said it's ok
				}
				return (
					<RandomIdContext.Provider value={id}>
						{children}
					</RandomIdContext.Provider>
				);
			}}
		/>
	);
}

function getInitialState() {
	if (!process.env.BROWSER) {
		return Date.now();
	}
	if (
		typeof window !== 'undefined' &&
		window._planity_isHydrating &&
		window._planity_random_id
	) {
		return window._planity_random_id;
	}
	// Client-side apps should not trigger an error (eg: webview )
	if (!process.env.WEBVIEW && process.env.NODE_ENV === 'development') {
		console.error(
			"Client-side randomId not hydrated : this is a bug, We don't expect this to happen."
		);
	}
	return Date.now();
}

export function useRandomId() {
	return useContext(RandomIdContext);
}
