export const SEO_CAT_TO_PUSH = {
	FR: [
		'hair_care', //coiffeur
		'odkz', //coiffure-homme
		'ot4c', //coiffure-femme
		'yofo', //balayage-meches
		'axjm', //lissage-et-defrisage
		'beauty_salon', //institut-de-beaute
		'bs4', //drainage-lymphatique
		'75te', //extensions-de-cils
		'na2', //rehaussement-de-cils
		'wmzh', //epilation
		'bs2', //microblading
		'4n6t', //soins-du-visage
		'barber_shop', //barber
		'nails', //prothesiste-ongulaire
		'spa',
		'10om', //massage
		'4g0g' //massage-thailandais
	],
	DE: [
		'hair_care', //friseur
		'barber_shop', //barbershops
		'odkz', //herren-friseure
		'75te', //wimpernverlaengerung
		'bs2', //microblading
		'w0u1', //augenbrauen-zupfen
		'1qqe', //friseure-kinder-jugendliche
		'lvbv', //gelnagel
		'sphhydr', //Hydrafacial®
		'na2', //lifting
		'7tg7', //pedikuere
		'g1az', //permanent-make-up
		'wmzh', //haarentfernung
		'ot4c', //damen-friseure
		'thsj', //haarverlaengerung
		'4n6t', //gesichtsbehandlungen
		'spa',
		'10om', //massage
		'4g0g' //thailaendische-massage
	],
	BE: [
		'hair_care', //kappers
		'barber_shop', //barbiers
		'biguineparis', //biguine-paris
		'ot4c', //kapper-voor-vrouwen
		'odkz', //herenkapper
		'1qqe', //knippen-jongeren
		'jp02', //knippen-vrouwen
		'odkz2', //knippen-mannen
		'sphhydr', //Hydrafacial®
		'kg39', //make-up
		'g1az', //permanente-make-up
		'bs2', //microblading
		'bs1', //microneedling
		'spa',
		'10om', //massage
		'4g0g' //thaise-massage
	]
};

const guessCountryCode = countryCode => {
	switch (countryCode) {
		case 'FR':
		case 'MC':
		case 'RE':
		case 'LU':
		case 'AD':
		case 'CH':
		case 'GP':
		case 'MQ':
		case 'GF':
		case 'PM':
		case 'YT':
		case 'NC':
		case 'PF':
		case 'MF':
		case 'TF':
		case 'WF':
		case 'BL':
			return 'FR';
		default:
			return countryCode;
	}
};

export const getSEOCatToPushFromCountryCode = countryCode =>
	SEO_CAT_TO_PUSH[guessCountryCode(countryCode)];
