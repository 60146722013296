import { noop } from '@planity/helpers';
import { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './search_bar_input.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Icon } from '@planity/ui';
export function SearchBarInput({
	label = '',
	placeholder = '',
	className,
	value,
	onChange = noop,
	onBlur = noop,
	onFocus = noop,
	onClick = noop,
	children,
	icon,
	type,
	isExpanded,
	readOnly = false,
	hasAClearInput = false
}) {
	useStyles(styles);

	const [isFocused, setIsFocused] = useState(false);

	const classes = classNames.bind(styles)({
		SearchBarInput: true,
		focused: isFocused,
		[className]: className !== undefined,
		isExpanded,
		hasAClearInput,
		[type]: true
	});
	return (
		<div className={classes} onClick={onClick}>
			<label className={styles.field}>
				<span className={styles.label}>{label}</span>
				<Icon icon={icon} className={styles.icon} size={'medium'} />
				<span className={hasAClearInput && value ? styles.inputClear : ''}>
					<input
						value={value || ''}
						onChange={e => onChange(e?.currentTarget?.value || '')}
						className={`${styles.input} ${styles[type]}`}
						type='text'
						placeholder={placeholder}
						onFocus={event => {
							onFocus(event);
							setIsFocused(true);
						}}
						onBlur={event => {
							onBlur(event);
							setIsFocused(false);
						}}
						readOnly={readOnly}
					/>
					{children}
					{hasAClearInput && isExpanded && value && (
						<Icon
							icon={'CloseCircleStroke'}
							onClick={() => onChange('')}
							size={'medium'}
						/>
					)}
				</span>
			</label>
		</div>
	);
}
