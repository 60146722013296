// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (max-width: 767px){.fake_search_bar-module_fakeSearchBar-wSKpa.fake_search_bar-module_displayOnlyOnMobileAndTablet-rEIRf{display:block}}@media only screen and (min-width: 768px)and (max-width: 1079px){.fake_search_bar-module_fakeSearchBar-wSKpa.fake_search_bar-module_displayOnlyOnMobileAndTablet-rEIRf{display:block}}@media only screen and (min-width: 1080px){.fake_search_bar-module_fakeSearchBar-wSKpa.fake_search_bar-module_displayOnlyOnMobileAndTablet-rEIRf{display:none}}.fake_search_bar-module_fakeSearchBar-wSKpa.fake_search_bar-module_hide-8wqXk{display:none}", "",{"version":3,"sources":["webpack://./../../packages/ui/components/main_search/fake_search_bar/fake_search_bar.module.scss"],"names":[],"mappings":"AAME,0CAFD,sGAGE,aAAA,CAAA,CAGD,iEAND,sGAOE,aAAA,CAAA,CAGD,2CAVD,sGAWE,YAAA,CAAA,CAGF,8EACC,YAAA","sourcesContent":["@use '@planity/ui/styles/utilities/mixins.scss';\n@use '@planity/ui/styles/utilities/variables.scss';\n\n.fakeSearchBar {\n\t&.displayOnlyOnMobileAndTablet {\n\t\t// mobiles\n\t\t@media only screen and (max-width: #{variables.$tablet - 1px}) {\n\t\t\tdisplay: block;\n\t\t}\n\t\t// tablets\n\t\t@media only screen and (min-width: variables.$tablet) and (max-width: #{variables.$desktop - 1px}) {\n\t\t\tdisplay: block;\n\t\t}\n\t\t// desktops\n\t\t@media only screen and (min-width: variables.$desktop) {\n\t\t\tdisplay: none;\n\t\t}\n\t}\n\t&.hide {\n\t\tdisplay: none;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"fakeSearchBar": "fake_search_bar-module_fakeSearchBar-wSKpa",
	"displayOnlyOnMobileAndTablet": "fake_search_bar-module_displayOnlyOnMobileAndTablet-rEIRf",
	"hide": "fake_search_bar-module_hide-8wqXk"
};
module.exports = ___CSS_LOADER_EXPORT___;
