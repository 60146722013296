// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".appointment_user-module_appointmentUser-Ahb7H{margin-bottom:10px}.appointment_user-module_loadingContainer-JbFtK{display:flex;justify-self:center;align-items:center;justify-content:center;height:5vh}", "",{"version":3,"sources":["webpack://./../../packages/components/book_appointment/user/appointment_user.module.scss"],"names":[],"mappings":"AAAA,+CACC,kBAAA,CAED,gDACC,YAAA,CACA,mBAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA","sourcesContent":[".appointmentUser {\n\tmargin-bottom: 10px;\n}\n.loadingContainer {\n\tdisplay: flex;\n\tjustify-self: center;\n\talign-items: center;\n\tjustify-content: center;\n\theight: 5vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appointmentUser": "appointment_user-module_appointmentUser-Ahb7H",
	"loadingContainer": "appointment_user-module_loadingContainer-JbFtK"
};
module.exports = ___CSS_LOADER_EXPORT___;
