import { useState, useRef, useEffect, useMemo } from 'react';
import classNames from 'classnames/bind';
import { BusinessAppointment, Button } from '@planity/ui';
import styles from './business_appointments.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useLocalizedRoutes, useTranslation } from '@planity/localization';
import { useHistory } from 'react-router-dom';

export const BusinessAppointments = ({ items = [] }) => {
	useStyles(styles);

	const { t } = useTranslation();
	const { routes } = useLocalizedRoutes();
	const container = useRef();
	const history = useHistory();

	const [index, setIndex] = useState(0);
	const [travelDistance, setTravelDistance] = useState(0);

	const displayEmptyAppointment = useMemo(
		() => items.every(x => !x.isEmpty),
		[items]
	);
	const numberOfAppointments = items.length + (displayEmptyAppointment ? 1 : 0);
	const classes = classNames.bind(styles);

	const seeMore = id => {
		setIndex(prev => (prev === id ? prev : id));
	};

	useEffect(() => {
		const el = container.current;
		const handle = () => {
			const prevIndex = Math.min(
				Math.floor((el.scrollLeft / travelDistance) * numberOfAppointments),
				numberOfAppointments - 1
			);
			setIndex(prevIndex);
		};

		el.addEventListener('scroll', handle);
		return () => {
			el.removeEventListener('scroll', handle);
		};
	}, [travelDistance, numberOfAppointments]);

	useEffect(() => {
		const handle = () => {
			setTravelDistance(
				container.current.scrollWidth - container.current.offsetWidth
			);
		};

		handle();
		window.addEventListener('resize', handle);
		return () => {
			window.removeEventListener('resize', handle);
		};
	}, []);

	return (
		<div className={classes('businessAppointments')}>
			<span className={styles.title}>{t('business.myAppointments')}</span>

			<div ref={container} className={styles.items}>
				{items
					?.filter(x => !x.isEmpty)
					?.map((item, i) => (
						<BusinessAppointment
							key={item.veventId}
							isVisible={i === index}
							{...item}
							seeMore={() => seeMore(i)}
						/>
					))}
				<div
					className={classes({
						myAccountCardContainer: true,
						displayMyAccountCardContainer: displayEmptyAppointment
					})}
				>
					<p className={classes({ myAccountCardText: true })}>
						{t('business.bookAppointment.more')}
					</p>
					<Button
						label={t('business.myAccount')}
						isFullMobile
						onClick={() => history.push(routes.myAccount)}
						id={'appointment-empty-button'}
					/>
				</div>
			</div>

			<div className={styles.bullets}>
				{new Array(numberOfAppointments).fill(null)?.map((_, i) => (
					<span
						key={i}
						className={classes({ bullet: true, isCurrent: i === index })}
					/>
				))}
			</div>
		</div>
	);
};
