import React from 'react';
import classNames from 'classnames/bind';
import { Button, SearchTags } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './tag_bar.module.scss';
import { useTranslation } from '@planity/localization';

export function TagBar({ filterCount, handleFilterClick }) {
	useStyles(styles);
	const { t } = useTranslation();
	const classes = classNames.bind(styles)({
		tagBar: true
	});

	return (
		<div className={classes}>
			<Button
				label={t('search.filter.titleResponsive')}
				type='tertiary'
				iconLeft='Filter'
				onClick={handleFilterClick}
				hasBadgeIcon={filterCount}
				hasColoredIcon={filterCount}
			/>
			<SearchTags />
		</div>
	);
}
