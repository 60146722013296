import React from 'react';
import { Localize } from '@planity/components';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './rejected_review.module.scss';
import { useTranslation } from '@planity/localization';

export const RejectedReview = ({ review }) => {
	useStyles(styles);
	const { t } = useTranslation();

	return (
		<div className={styles.alert}>
			<div>
				<Localize text={'reviewPreview.rejectedReview'} />
			</div>
			<div id='appointment-review-preview-reject-reason'>
				<span>
					<Localize style={styles.spaceMe} text={'reviewPreview.reason'} />
				</span>
				{t(`reviewPreview.refusal.${review.rejectionReason}`)}
			</div>
		</div>
	);
};
