import { useState } from 'react';
import { firebase } from '@planity/datastores';
import { invokeLambda } from '@planity/helpers/browser';

/**
 * This hook make available CRUD operations on handleWaitingList lambda
 *
 * @description waitingListAppointment, waitingListError, waitingListLoading  get updated every time a crud op is called.It refers to the op.unless noStateUpdate is true
 * @param appointmentId
 * @returns {{getWaitingListAppointment: (function(*, {noStateUpdate?: boolean}=): Promise<void | WaitingListAppointment>), updateWaitingListAppointment: (function(*, {noStateUpdate?: boolean}=): Promise<void | WaitingListAppointment>), deleteWaitingListAppointment: (function({noStateUpdate?: boolean}=): Promise<void | WaitingListAppointment>), waitingListAppointment: WaitingListAppointment | null, addWaitingListAppointment: (function(*, {noStateUpdate?: boolean}=): Promise<void | WaitingListAppointment>), waitingListError: Object | null, waitingListLoading: boolean}}
 */
export function useWaitingList(appointmentId) {
	const [waitingListAppointment, setWaitingListAppointment] = useState(null);
	const [waitingListLoading, setWaitingListLoading] = useState(false);
	const [waitingListError, setWaitingListError] = useState(null);

	if (!appointmentId)
		return {
			waitingListAppointment,
			waitingListError,
			waitingListLoading,
			addWaitingListAppointment: async () => {},
			deleteWaitingListAppointment: async () => {},
			getWaitingListAppointment: async () => {},
			updateWaitingListAppointment: async () => {}
		};

	const callHandler = async (
		lambdaName,
		action,
		{ noStateUpdate = false },
		payload
	) => {
		if (!noStateUpdate) {
			setWaitingListLoading(true);
			setWaitingListError(null);
		}

		try {
			const userToken = await firebase.auth().currentUser.getIdToken();
			const res = await invokeLambda(lambdaName, {
				...payload,
				action,
				userToken,
				userId: firebase.auth().currentUser.uid,
				sequenceId: appointmentId
			});
			if (noStateUpdate) return res;
			if (res.statusCode === 200) {
				setWaitingListAppointment(res.body);
				setWaitingListError(null);
			} else setWaitingListError(res);
			setWaitingListLoading(false);
		} catch (e) {
			if (noStateUpdate) throw e;
			setWaitingListError(e);
			setWaitingListLoading(false);
		}
	};

	/**
	 * [GET] get the waiting list doc for the current appointment.
	 *
	 * @param payload the new values
	 * @param {Object} options - The options for getting the appointment.
	 * @param {boolean} [options.noStateUpdate=false] - If true, prevents updating the state for GET. the function will return a normal promise in this case.
	 * @returns {Promise} - It updates the state with the new values. unless noStateUpdate is true.
	 */
	const getWaitingListAppointment = (payload, options = {}) =>
		callHandler('handleWaitingList', 'getAppointment', options, payload);

	/**
	 * [POST] add a waiting list for the current appointment.
	 *
	 * @param payload the new values
	 * @param {Object} options - The options for updating the appointment.
	 * @param {boolean} [options.noStateUpdate=false] - If true, prevents updating the state for POST. the function will return a normal promise in this case.
	 * @returns {Promise} - It updates the state with the new values. unless noStateUpdate is true.
	 */
	const addWaitingListAppointment = (payload, options = {}) =>
		callHandler('handleWaitingList', 'addAppointment', options, payload);

	/**
	 * [PUT] Update the waiting list for the current appointment.
	 *
	 * @param payload the new values
	 * @param {Object} options - The options for updating the appointment.
	 * @param {boolean} [options.noStateUpdate=false] - If true, prevents updating the state for PUT. the function will return a normal promise in this case.
	 * @returns {Promise} - It updates the state with the new values. unless noStateUpdate is true.
	 */
	const updateWaitingListAppointment = (payload, options = {}) =>
		callHandler('handleWaitingList', 'updateAppointment', options, payload);

	/**
	 * [DELETE] Deletes the waiting list for the current appointment.
	 *
	 * @param {Object} options - The options for deleting the appointment.
	 * @param {boolean} [options.noStateUpdate=false] - If true, prevents updating the state for DELETE. the function will return a normal promise in this case.
	 * @returns {Promise} - It updates the state with the new values. unless noStateUpdate is true.
	 */
	const deleteWaitingListAppointment = (options = {}) =>
		callHandler('handleWaitingList', 'deleteAppointment', options, undefined);

	return {
		waitingListAppointment,
		waitingListError,
		waitingListLoading,
		getWaitingListAppointment,
		addWaitingListAppointment,
		updateWaitingListAppointment,
		deleteWaitingListAppointment
	};
}
