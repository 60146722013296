import { Localize } from '@planity/components';
import { useState } from 'react';
import { Icon } from '@planity/ui';
import styles from './items_pagination.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
/**
 *
 * @items array of items that you want to list
 * @itemComponent component rendering one single item
 * @pageSize number of items incrementing your page when you click on 'show more'
 */
export default function ItemsPagination({ items, itemComponent, pageSize }) {
	useStyles(styles);
	const [pages, setPages] = useState(1);
	const itemsToRender = items.slice(0, pages * pageSize);
	const renderMoreItems = () => setPages(pages + 1);

	const classes = classNames.bind(styles)({
		showMore: true
	});

	let ItemComponent = itemComponent;

	return (
		<div>
			{itemsToRender.map((item, index) => (
				<ItemComponent item={item} key={index} />
			))}
			{itemsToRender.length < items.length && (
				<div className={classes} onClick={renderMoreItems}>
					<p className={styles.showMoreText}>
						<Localize text={'myAccount.invoice.showMore'} />
					</p>
					<div className={styles.showMoreIcon}>
						<Icon icon='ChevronDown' size={'medium'} />
					</div>
				</div>
			)}
		</div>
	);
}
