import { format as formatDate, parseISO } from 'date-fns';
import { Localize } from '@planity/components/';
import React from 'react';
import styles from './confirm_cancellation_modal_content.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useLocalization } from '@planity/localization';
import { AppointmentDetails } from './appointment_details';

export const ConfirmCancellationModalContent = ({
	className,
	appointment,
	business
}) => {
	const { dateLocale } = useLocalization();
	const start = parseISO(appointment.start);
	const date = formatDate(start, 'eeee d MMMM', { locale: dateLocale });
	const hour = formatDate(start, 'HH:mm');

	useStyles(styles);
	const cx = classNames.bind(styles);

	return (
		<div
			className={cx(
				{
					cancellationModal: true
				},
				className
			)}
		>
			<div className={styles.cancelAppointmentModal}>
				<p className={styles.modalTitle} id='confirm-cancel-appointment-title'>
					<Localize text={'myAccount.appointment.confirmCancellation.title'} />
				</p>
				<p
					className={styles.description}
					id='confirm-cancel-appointment-description'
				>
					<Localize
						args={{
							date,
							hour,
							business: business.name
						}}
						text={'myAccount.appointment.confirmCancellation.description'}
					/>
				</p>
				<div className={styles.stepContainer}>
					<div className={styles.stepContent}>
						{appointment.sequence.map((step, idx) => (
							<AppointmentDetails
								business={business}
								key={step.stepId}
								step={step}
								style={
									idx < appointment.sequence.length - 1 &&
									appointment.sequence.length > 1
										? { marginBottom: '8px' }
										: {}
								}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
