import React, { useEffect } from 'react';
import Customer from './customer';
import { BusinessDirectoryPage } from './business_directory_page';
import {
	useStripeElements,
	WithBusinessGiftVouchers
} from '@planity/components';
import { Spinner } from '@planity/ui';
import { getFinalStripeCountryCode } from '@planity/helpers';
import credentials from '@planity/credentials';

export function BusinessPageMain({ business, ...props }) {
	const isDirectory = business.plStatus < 3;

	const { setStripeCountryCode, stripeCountryCode } = useStripeElements();
	/**
	 * Stripe country code will be changed when reaching:  the business page
	 * OR "My account" page ONLY if the actual stripe country code
	 * is different from the business country code or locale country code
	 * PS : not a very safe solution for now :(
	 */
	const countryCode = business.countryCode;
	useEffect(() => {
		if (!isDirectory) {
			const finalCountryCode = getFinalStripeCountryCode(
				credentials,
				countryCode
			);
			if (countryCode && finalCountryCode !== stripeCountryCode) {
				setStripeCountryCode(finalCountryCode);
			}
		}
	}, [isDirectory, countryCode]);

	if (isDirectory) {
		if (business.objectID) {
			return (
				<div css={styles.spinner}>
					<Spinner />
				</div>
			);
		}
		return <BusinessDirectoryPage business={business} {...props} />;
	}

	return (
		<WithBusinessGiftVouchers businessId={props.businessId}>
			{({ businessGiftVouchers: giftCards, businessGiftVouchersIsLoading }) => (
				<Customer
					business={business}
					{...props}
					businessGiftVouchersIsLoading={businessGiftVouchersIsLoading}
					giftCards={giftCards}
					parentPlace={props.parentPlace}
					place={props.place}
				/>
			)}
		</WithBusinessGiftVouchers>
	);
}

const styles = {
	spinner: {
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
};
