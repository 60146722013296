import React from 'react';
import classNames from 'classnames/bind';
import styles from './business_gallery.module.scss';
import { Image, useGallery, realIndex, Slideshow, Logo } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';
import { noop } from '@planity/helpers';

export function BusinessGallery({ className, images = [], transformations }) {
	useStyles(styles);
	const { setGalleryImages, openGallery, setGalleryTransformations } =
		useGallery();
	const { t } = useTranslation();

	const cx = classNames.bind(styles);
	const classes = cx({
		businessGallery: true,
		[className]: className !== undefined
	});

	const onPictureClick = () => {
		setGalleryTransformations(transformations);
		setGalleryImages(images);
		openGallery();
	};

	if (!images?.length) {
		return (
			<div className={classes}>
				<div
					className={cx({
						thumbnails: true,
						hasOneOrNoPicture: true
					})}
				>
					<div
						className={cx({
							thumbnail: true,
							showOnMobile: true,
							hasOneOrNoPicture: true,
							hasNoPicture: true
						})}
						onClick={noop}
					>
						<div className={styles.noPicture}>
							<Logo />
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={classes}>
			<div
				className={cx({
					thumbnails: true,
					hasOneOrNoPicture: true
				})}
			>
				{images.length === 1 ? (
					<div
						className={cx({
							thumbnail: true,
							showOnMobile: true,
							hasOneOrNoPicture: true
						})}
						onClick={onPictureClick}
					>
						<Image
							fit='cover'
							url={images[0]}
							isCloudinary
							transformations={transformations}
							cloudinaryClassName={styles.onePictureBg}
						/>
						<div className={styles.onePictureWrapper}>
							<Image
								fit='contain'
								url={images[0]}
								isCloudinary
								transformations={transformations}
								cloudinaryClassName={styles.onePicture}
							/>
						</div>
					</div>
				) : (
					<>
						{images?.map((props, i) => (
							<div
								key={i}
								className={cx({
									thumbnail: true,
									hasTwoPictures: images.length === 2,
									hasThreePictures: images.length === 3,
									hasFourPictures: images.length === 4
								})}
								onClick={onPictureClick}
							>
								<Image
									{...props}
									fit='contain'
									url={images[realIndex(i, images.length)]}
									transformations={transformations}
								/>
							</div>
						))}
						<div className={styles.hideOnDesktop}>
							<Slideshow
								images={images}
								transformations={transformations}
								showPagination
								simpleNav
								onPictureClick={onPictureClick}
								isBusinessSlideShow
							/>
						</div>
					</>
				)}
				<button
					aria-label={t('business.gallery.buttons.title')}
					className={cx({
						overlay: true,
						overlayIsNeededTablet: images?.length > 3,
						overlayIsNeededDesktop: images?.length > 5
					})}
					onClick={onPictureClick}
				>
					<span>
						{t('business.gallery.buttons.show', {
							count: images?.length
						})}
					</span>
				</button>
			</div>
		</div>
	);
}
