import { useFirebaseSubscription } from '@planity/datastores';
import { useAuth } from '@planity/components';

export const useFutureAppointments = ({ isAllowedToFetchData, startAt }) => {
	const { userId } = useAuth();
	const [
		futureAppointments,
		futureAppointmentsError,
		futureAppointmentsLoading
	] = useFirebaseSubscription(
		isAllowedToFetchData && {
			path: userId && `user_vevents/${userId}`,
			startAt,
			orderByChild: 'start'
		}
	);

	if (process.env.NODE_ENV === 'development' && futureAppointmentsError) {
		console.warn({ futureAppointmentsError });
	}

	return {
		futureAppointments,
		futureAppointmentsLoading
	};
};
