// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".country_code_alert_modal_buttons-module_countryCodeAlertModalButtons-3bVyL{display:flex;margin-bottom:20px;margin-top:-10px}.country_code_alert_modal_buttons-module_countryCodeAlertModalButtons-3bVyL .country_code_alert_modal_buttons-module_button-KgVHB{margin-right:10px}@media(max-width: 767px){.country_code_alert_modal_buttons-module_countryCodeAlertModalButtons-3bVyL{flex-direction:column-reverse;margin-bottom:-45px;margin-top:-40px}.country_code_alert_modal_buttons-module_countryCodeAlertModalButtons-3bVyL .country_code_alert_modal_buttons-module_button-KgVHB{margin-right:0;margin-top:20px}}", "",{"version":3,"sources":["webpack://./../../packages/ui/components/country_code_alert_modal/country_code_alert_modal_buttons/country_code_alert_modal_buttons.module.scss"],"names":[],"mappings":"AAIA,4EACC,YAAA,CACA,kBAAA,CACA,gBAAA,CAEA,kIACC,iBAAA,CAGD,yBATD,4EAUE,6BAAA,CACA,mBAAA,CACA,gBAAA,CAEA,kIACC,cAAA,CACA,eAAA,CAAA","sourcesContent":["@use '@planity/ui/styles';\n@use '@planity/ui/styles/utilities/mixins.scss';\n@use '@planity/ui/styles/utilities/variables.scss';\n\n.countryCodeAlertModalButtons {\n\tdisplay: flex;\n\tmargin-bottom: 20px;\n\tmargin-top: -10px;\n\n\t.button {\n\t\tmargin-right: 10px;\n\t}\n\n\t@media (max-width: #{styles.$tablet - 1px}) {\n\t\tflex-direction: column-reverse;\n\t\tmargin-bottom: -45px;\n\t\tmargin-top: -40px;\n\n\t\t.button {\n\t\t\tmargin-right: 0;\n\t\t\tmargin-top: 20px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"countryCodeAlertModalButtons": "country_code_alert_modal_buttons-module_countryCodeAlertModalButtons-3bVyL",
	"button": "country_code_alert_modal_buttons-module_button-KgVHB"
};
module.exports = ___CSS_LOADER_EXPORT___;
