import { Localize } from '@planity/components';
import { Icon } from '@planity/ui';
import React from 'react';
import styles from './search_bar_button.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';

/**
 * This component is too specific to be a simple button from @planity/ui. It has
 * nothing like Buttons, doesn't have behavior on hover, the size changes, icon
 * and then no icon...
 * @param id {string}
 * @param onClick {function}
 * @param onIconClick {function} We need a special case for clicking icon...
 */
export const SearchBarButton = ({ id, onIconClick, onClick }) => {
	useStyles(styles);
	const classes = classNames.bind(styles);

	return (
		<button
			onClick={onClick}
			id={id}
			className={classes({ searchBarButton: true })}
		>
			<Icon
				icon={'Search'}
				size={'medium'}
				className={classes({ icon: true })}
				onClick={onIconClick}
			/>
			<span className={classes({ text: true })}>
				<Localize text={'search.triggerSearch'} />
			</span>
		</button>
	);
};
