import React from 'react';
import classNames from 'classnames/bind';
import styles from './offers.module.scss';
import { Image, Button, Title, Container } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useLocalizedRoutes, useTranslation } from '@planity/localization';
import { useInView } from 'react-intersection-observer';

export function Offers() {
	useStyles(styles);

	const { routes } = useLocalizedRoutes();
	const { t } = useTranslation();
	const { ref, inView } = useInView({
		threshold: 0
	});

	const classes = classNames.bind(styles)({
		offers: true
	});

	return (
		<div className={classes} ref={ref}>
			<Container noStupidMarginBottom>
				<div className={styles.columns}>
					<div>
						<Title
							label={t('landing.team.professional')}
							title={t('landing.team.description')}
						/>
						<p className={styles.text}>{t('landing.team.monseigneur')}</p>

						<Button
							className={styles.tablet}
							label={t('nav.discoverUs')}
							size={'mobile'}
							isFullWidth
							isExternal
							to={routes.careers}
						/>

						<Button
							className={styles.mobile}
							label={t('nav.discoverUs')}
							size={'large'}
							isExternal
							to={routes.careers}
						/>
					</div>
					<div>
						{inView && (
							<Image
								className={styles.image}
								alt={'planity-team'}
								url={
									'https://res.cloudinary.com/planity/image/upload/portail/new_team.jpg'
								}
								isCloudinary
							/>
						)}
					</div>
				</div>
			</Container>
		</div>
	);
}
