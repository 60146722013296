import { differenceInMinutes } from 'date-fns';
import memoize from 'fast-memoize';
import { serviceIsForbidden } from './businesses';

/**
 * @param {Object} options
 * @param {Date} options.appointmentStart Appointment starting dateTime object
 * @param {number} options.businessCancellationDelay in minutes : cancellation delay configured in admin
 * @param {number} options.veventLateCancellationDelay in minutes : cancellation delay configured in pro
 * @description * max value between businessCancellationDelay and lateCancellationDelay is obtained in mins
 * 				* Check appointment doesn't pass the cancellation delay
 *
 * @returns {boolean} canSwitchWaitingList
 */
export const checkWaitingListCancellationDelay = ({
	appointmentStart,
	businessCancellationDelay,
	veventLateCancellationDelay
}) => {
	// closest time to take an appointment
	const validCancellationDelay = Math.max(
		businessCancellationDelay,
		veventLateCancellationDelay
	);
	const now = new Date();

	return differenceInMinutes(appointmentStart, now) > validCancellationDelay;
};

/**
 * returns the list of hidden and not-bookable services
 * @type {function(*): string[]}
 */
export const getWaitingListIneligibleServices = memoize(business => {
	const ids = [];

	for (const serviceId in business.services) {
		const service = business.services[serviceId];
		for (const childId in service.children) {
			const child = service.children[childId];
			const { webHidden, bookable, deletedAt } = child;
			if (
				webHidden ||
				!bookable ||
				deletedAt ||
				serviceIsForbidden(child) ||
				service.deletedAt
			) {
				ids.push(childId);
			}
		}
	}
	return ids;
});
