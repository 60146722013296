export const EdgeSvg = () => {
	return (
		<svg
			enableBackground='new 0 0 2500 2500'
			id='Calque_1'
			version='1.1'
			viewBox='0 0 2500 2500'
			x='0px'
			xmlns='http://www.w3.org/2000/svg'
			xmlSpace='preserve'
			y='0px'
		>
			<style type='text/css'>
				{`
          .st0 { fill: url(#SVGID_1_); }
          .st1 { opacity: 0.35; fill: url(#SVGID_00000173868658252364146200000011693588370876673950_); enable-background: new; }
          .st2 { fill: url(#SVGID_00000044897214065224065160000004121210229303009206_); }
          .st3 { opacity: 0.41; fill: url(#SVGID_00000069393825820998975980000004834145136978892441_); enable-background: new; }
          .st4 { fill: url(#SVGID_00000021120125216220683840000005714562602870359224_); }
          .st5 { fill: url(#SVGID_00000080908130955578285770000015061188950781081508_); }
        `}
			</style>
			<g>
				<linearGradient
					gradientTransform='matrix(8.563 0 0 8.563 -6368.7144 -10995.584)'
					gradientUnits='userSpaceOnUse'
					id='SVGID_1_'
					x1='811.2996'
					x2='1013.1129'
					y1='1485.5317'
					y2='1485.5317'
				>
					<stop offset='0' stopColor='#0C59A4' />
					<stop offset='1' stopColor='#114A8B' />
				</linearGradient>
				<path
					className='st0'
					d='M2248.9,1855.9c-33.1,17.3-67.2,32.5-102.2,45.7c-111.3,41.6-229.2,62.8-348,62.6
		c-458.7,0-858.2-315.5-858.2-720.4c1.2-110.6,62.1-211.8,159.2-264.7c-414.8,17.4-521.5,449.7-521.5,703
		c0,716.1,660,788.7,802.2,788.7c76.7,0,192.3-22.3,261.7-44.2l12.7-4.3c267.1-92.3,494.7-272.8,645.5-511.8
		c11.5-18.1,6.1-42-12-53.5C2276.6,1849.5,2261.4,1849,2248.9,1855.9L2248.9,1855.9z'
				/>

				<radialGradient
					cx='922.7935'
					cy='1496.6371'
					gradientTransform='matrix(8.563 0 0 8.1348 -6368.7144 -10436.0801)'
					gradientUnits='userSpaceOnUse'
					id='SVGID_00000047747867133560207300000002490616431333863598_'
					r='107.9623'
				>
					<stop offset='0.72' stopColor='#000000' stopOpacity='0' />
					<stop offset='0.95' stopColor='#000000' stopOpacity='0.53' />
					<stop offset='1' stopColor='#000000' />
				</radialGradient>
				<path
					d='
		M2248.9,1855.9c-33.1,17.3-67.2,32.5-102.2,45.7c-111.3,41.6-229.2,62.8-348,62.6c-458.7,0-858.2-315.5-858.2-720.4
		c1.2-110.6,62.1-211.8,159.2-264.7c-414.8,17.4-521.5,449.7-521.5,703c0,716.1,660,788.7,802.2,788.7c76.7,0,192.3-22.3,261.7-44.2
		l12.7-4.3c267.1-92.3,494.7-272.8,645.5-511.8c11.5-18.1,6.1-42-12-53.5C2276.6,1849.5,2261.4,1849,2248.9,1855.9L2248.9,1855.9z'
					enableBackground='new'
					fill='url(#SVGID_00000047747867133560207300000002490616431333863598_)'
					opacity='0.35'
				/>

				<linearGradient
					gradientTransform='matrix(8.563 0 0 8.563 -6368.7144 -10995.584)'
					gradientUnits='userSpaceOnUse'
					id='SVGID_00000062176462285799382970000000977389153731262081_'
					x1='917.7883'
					x2='791.704'
					y1='1398.0497'
					y2='1535.3853'
				>
					<stop offset='0' stopColor='#1B9DE2' />
					<stop offset='0.16' stopColor='#1595DF' />
					<stop offset='0.67' stopColor='#0680D7' />
					<stop offset='1' stopColor='#0078D4' />
				</linearGradient>
				<path
					d='M1034.1,2349.1
		c-86.5-53.7-161.4-124-220.4-206.8c-254.7-349-178.3-838.5,170.7-1093.2c36.4-26.6,75.1-50,115.5-69.9
		c30.2-14.2,81.9-40,150.6-38.8c98.1,0.7,190.3,47.3,249,126c39.1,52.3,60.7,115.6,61.6,180.9c0-2,237.1-771.5-775.4-771.5
		c-425.5,0-775.4,403.8-775.4,758.1c-1.7,187.4,38.4,372.8,117.4,542.8c267.1,569.9,918.6,849.5,1515.7,650.5
		c-204.4,64.4-426.8,36.1-608.5-77.5L1034.1,2349.1z'
					fill='url(#SVGID_00000062176462285799382970000000977389153731262081_)'
				/>

				<radialGradient
					cx='571.3884'
					cy='1449.8654'
					gradientTransform='matrix(1.2844 -8.4773 6.8504 1.0276 -9972.5264 5292.043)'
					gradientUnits='userSpaceOnUse'
					id='SVGID_00000163754129052882126800000010607761352882037410_'
					r='162.1359'
				>
					<stop offset='0.76' stopColor='#000000' stopOpacity='0' />
					<stop offset='0.95' stopColor='#000000' stopOpacity='0.5' />
					<stop offset='1' stopColor='#000000' />
				</radialGradient>
				<path
					d='
		M1034.1,2349.1c-86.5-53.7-161.4-124-220.4-206.8c-254.7-349-178.3-838.5,170.7-1093.2c36.4-26.6,75.1-50,115.5-69.9
		c30.2-14.2,81.9-40,150.6-38.8c98.1,0.7,190.3,47.3,249,126c39.1,52.3,60.7,115.6,61.6,180.9c0-2,237.1-771.5-775.4-771.5
		c-425.5,0-775.4,403.8-775.4,758.1c-1.7,187.4,38.4,372.8,117.4,542.8c267.1,569.9,918.6,849.5,1515.7,650.5
		c-204.4,64.4-426.8,36.1-608.5-77.5L1034.1,2349.1z'
					enableBackground='new'
					fill='url(#SVGID_00000163754129052882126800000010607761352882037410_)'
					opacity='0.41'
				/>

				<radialGradient
					cx='869.9834'
					cy='1131.4803'
					gradientTransform='matrix(-0.3425 8.563 -18.2391 -0.685 21195.1484 -6206.229)'
					gradientUnits='userSpaceOnUse'
					id='SVGID_00000126283090355604284430000008940524131333978280_'
					r='229.1341'
				>
					<stop offset='0' stopColor='#35C1F1' />
					<stop offset='0.11' stopColor='#34C1ED' />
					<stop offset='0.23' stopColor='#2FC2DF' />
					<stop offset='0.31' stopColor='#2BC3D2' />
					<stop offset='0.67' stopColor='#36C752' />
				</radialGradient>
				<path
					d='M1485.7,1451.9c-7.9,10.2-32,24.2-32,54.9
		c0,25.3,16.5,49.6,45.7,70.1c139.4,96.9,402.1,84.1,402.8,84.1c103.3-0.2,204.6-28.2,293.4-80.9
		c182.3-106.4,294.5-301.5,294.9-512.5c2.5-217.2-77.5-361.6-109.9-425.6C2175.4,240.2,1732,9.1,1249.9,9.1
		C571.5,9,18.8,553.9,9.3,1232.3C13.9,878.1,366,592.1,784.7,592.1c33.9,0,227.4,3.3,407.1,97.6c158.4,83.2,241.3,183.6,299,283.1
		c59.9,103.4,70.6,234.1,70.6,286.1C1561.3,1311,1534.8,1388.2,1485.7,1451.9z'
					fill='url(#SVGID_00000126283090355604284430000008940524131333978280_)'
				/>

				<radialGradient
					cx='1114.6881'
					cy='1142.0222'
					gradientTransform='matrix(2.3976 8.2205 -6.6791 1.9695 7291.2793 -10653.0654)'
					gradientUnits='userSpaceOnUse'
					id='SVGID_00000123406087706827800740000000223146018109458582_'
					r='110.1809'
				>
					<stop offset='0' stopColor='#66EB6E' />
					<stop offset='1' stopColor='#66EB6E' stopOpacity='0' />
				</radialGradient>
				<path
					d='M1485.7,1451.9c-7.9,10.2-32,24.2-32,54.9
		c0,25.3,16.5,49.6,45.7,70.1c139.4,96.9,402.1,84.1,402.8,84.1c103.3-0.2,204.6-28.2,293.4-80.9
		c182.3-106.4,294.5-301.5,294.9-512.5c2.5-217.2-77.5-361.6-109.9-425.6C2175.4,240.2,1732,9.1,1249.9,9.1
		C571.5,9,18.8,553.9,9.3,1232.3C13.9,878.1,366,592.1,784.7,592.1c33.9,0,227.4,3.3,407.1,97.6c158.4,83.2,241.3,183.6,299,283.1
		c59.9,103.4,70.6,234.1,70.6,286.1C1561.3,1311,1534.8,1388.2,1485.7,1451.9z'
					fill='url(#SVGID_00000123406087706827800740000000223146018109458582_)'
				/>
			</g>
		</svg>
	);
};
