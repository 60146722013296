import React, { useState } from 'react';
import { Localize } from '@planity/components';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './address.module.scss';

export function Address({
	address,
	isDefault,
	actions: Actions,
	localizedDescription,
	customStyle
}) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		address: true
	});

	const [isRemoving, setIsRemoving] = useState(false);

	return (
		<div className={classes} css={customStyle}>
			<div className={styles.addressInfo}>
				<div className={styles.name}>
					{address.name
						? address.name
						: `${address.firstName} ${address.lastName}`}
					{isDefault && (
						<span css={{ color: '#a1a1a1' }}>
							{' '}
							<Localize text={'myAccount.myProfile.defaultAddress'} />
						</span>
					)}
					{localizedDescription && (
						<span css={{ color: '#a1a1a1' }}>
							{' '}
							<Localize text={localizedDescription} />
						</span>
					)}
				</div>
				<span className={styles.address}>{`${address.address1}, ${
					address.address2 ? address.address2 + ',' : ''
				} ${address.zip} ${address.city}, ${address.country}`}</span>
			</div>
			{!!Actions && (
				<div>
					<Actions
						address={address}
						isRemoving={isRemoving}
						onRemoveClick={() => setIsRemoving(true)}
					/>
				</div>
			)}
		</div>
	);
}
