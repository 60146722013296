import React, { useEffect, useState } from 'react';
import { ErrorMessage } from '@planity/components';
import { formatPhoneNumber, invokeLambda, safeRead } from '@planity/helpers';
import { Button, Card, Input } from '@planity/ui';
import { useTranslation } from '@planity/localization';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './directory_form.module.scss';

const MISSING_NAME_ERROR = 'business.directoryFormErrors.missingName';

export function DirectoryForm({
	business,
	businessId,
	preposition,
	categoryName,
	placeholderText,
	submittingText,
	submitText
}) {
	const { t } = useTranslation();
	useStyles(styles);
	const classes = classNames.bind(styles)({
		directoryForm: true
	});

	const [wasSuccessful, setWasSuccessful] = useState(false);
	const [name, setName] = useState('');
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true);
	}, []);

	const onSubmit = e => {
		if (e && e.preventDefault) e.preventDefault();
		if (!error && !isLoading) {
			setIsLoading(true);
			invokeLambda('sendDirectoryFormRequest', {
				name,
				businessId,
				business: {
					name: business.name,
					address: ['street', 'locality', 'postalCode']
						.map(x => safeRead(business, ['address', x]))
						.filter(x => !!x)
						.join(' '),
					phoneNumber: business.phoneNumber
						? formatPhoneNumber(business.phoneNumber)
						: null
				}
			})
				.then(response => {
					if (isMounted) {
						if (response && response.errorMessage) {
							setIsLoading(false);
							setError(response.errorMessage);
						} else {
							setIsLoading(true);
							setWasSuccessful(true);
						}
					}
				})
				.catch(error => {
					if (isMounted) {
						setIsLoading(false);
						setError(error);
					}
				});
		}
	};

	if (wasSuccessful) return <div>{t('business.directoryFormSuccess')}</div>;

	return (
		<Card className={classes} title={t('business.directoryForm.title')}>
			<p className={styles.description}>
				{t('business.directoryForm.text', {
					preposition,
					categoryName
				})}
			</p>
			<form className={styles.form} onSubmit={onSubmit}>
				<ErrorMessage error={error} whitelist={[MISSING_NAME_ERROR]} />
				<div className={styles.input}>
					<Input
						isRequired
						label={t('addressForm.lastName')}
						placeholder={placeholderText}
						type={'text'}
						value={name}
						onChange={name => {
							setName(name);
							setError(error === MISSING_NAME_ERROR && name ? null : error);
						}}
					/>
				</div>
				<div>
					<Button
						isDisabled={!!error || !name}
						isFullMobile
						label={isLoading ? submittingText : submitText}
					/>
				</div>
			</form>
		</Card>
	);
}
