import React from 'react';
import {
	Button,
	Form,
	Inputs,
	Link,
	Localize,
	PasswordInput,
	FORGOTTEN_PASSWORD
} from '@planity/components';
import { useFormHooks } from '../useFormHooks';
import { breakpoints } from '@planity/theme';

import {
	PASSWORD_CHANGE,
	RESET_MACHINE,
	USER_PASSWORD_SUBMIT
	// FORGOTTEN_PASSWORD
} from '../../auth_actions';

export const SignUpWithMergeEmailAndPasswordWithProvider = () => {
	const { state, dispatch, formValues } = useFormHooks();
	const { children, pending, handleChangeAuthType } = formValues;

	return (
		<Form>
			{children}
			<p>
				<Localize text={'auth.texts.usePasswordAuth'} />
			</p>
			<Inputs>
				<PasswordInput
					localizedPlaceholder={'auth.form.placeholders.password'}
					value={state && state.context && state.context.password}
					onChange={passwordInput =>
						dispatch({
							type: PASSWORD_CHANGE,
							password: passwordInput
						})
					}
				/>
			</Inputs>

			<div css={css.buttonContainer}>
				<Button
					css={css.button}
					isActive={pending}
					localizedText={
						pending ? 'auth.form.buttons.pending' : 'auth.form.buttons.submit'
					}
					onPress={() => dispatch({ type: USER_PASSWORD_SUBMIT })}
				/>
			</div>
			<div css={css.bottomLinksContainer}>
				<Link
					css={css.backToSignInLink}
					localizedText={`auth.form.links.BACK_TO_SIGN_IN`}
					onPress={() => dispatch({ type: RESET_MACHINE })}
				/>
				<Link
					css={css.forgottonPasswordLink}
					localizedText={`auth.form.links.${FORGOTTEN_PASSWORD}`}
					onPress={() => handleChangeAuthType(FORGOTTEN_PASSWORD)}
				/>
			</div>
		</Form>
	);
};
const css = {
	button: {
		width: '100%',
		marginBottom: 18,
		marginTop: 12
	},
	password: {
		[breakpoints.tabletQuery]: {
			width: '50%',
			paddingRight: 15
		}
	},
	forgottenPasswordLink: {
		marginBottom: 26,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'center',
		fontSize: 16,
		lineHeight: 1.13
	},
	bottomLink: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'center',
		fontSize: 16,
		lineHeight: 1.13
	},
	bottomLinksContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'center',
		fontSize: 16,
		lineHeight: 1.13,
		[breakpoints.tabletQuery]: {
			flexDirection: 'row',
			justifyContent: 'space-between'
		}
	},
	backToSignInLink: {
		marginBottom: 18,
		[breakpoints.tabletQuery]: {
			marginBottom: 0
		}
	},
	forgottonPasswordLink: {
		lineHeight: 1.5
	}
};
