import React, { useEffect, useMemo, useState } from 'react';
import TabsContext from './context';

export function Wrapper({ children, currentId }) {
	const [current, setCurrent] = useState(currentId || 0);
	const context = useMemo(
		() => ({
			current,
			setCurrent
		}),
		[current]
	);

	useEffect(() => {
		if (current !== currentId) {
			setCurrent(currentId || 0);
		}
	}, [currentId]);

	return (
		<TabsContext.Provider value={context}>{children}</TabsContext.Provider>
	);
}
