import { Localize } from '@planity/components';
import { Button } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppointmentsList } from './appointments_list';
import styles from './past_appointments.module.scss';
import debounce from 'lodash/debounce';

export const PastAppointments = ({
	customBookAnAppointment,
	businessesAreLoading,
	hasPastAppointments,
	linkToBusiness,
	linkToBookAppointment,
	isDark,
	loadMoreAppointments,
	pastAppointments,
	pastAppointmentsLoading,
	pageSize
}) => {
	const history = useHistory();

	useStyles(styles);
	const [isLoading, setIsLoading] = useState(
		pastAppointmentsLoading || businessesAreLoading
	);
	const debounceLoadingState = useMemo(
		() => debounce(() => setIsLoading(false), 500),
		[]
	);

	useEffect(() => {
		// we use a isLoading state because there is a moment where pageSize is changed but businessesAreLoading
		// and pastAppointmentsLoading value are still false.
		// so hasMore = businessesAreLoading || pastAppointmentsLoading || pastAppointments?.length > pageSize - 1; will not work.
		if (isLoading && !pastAppointmentsLoading && !businessesAreLoading) {
			debounceLoadingState();
		}
		return () => debounceLoadingState.cancel();
	}, [pastAppointmentsLoading, businessesAreLoading, isLoading]);

	const loadMore = () => {
		setIsLoading(true);
		loadMoreAppointments();
	};
	const appointments = useMemo(() => {
		// Last appointment is a cursor for fetching. Slice it.
		return pastAppointments.slice(0, pageSize - 1);
	}, [pastAppointments, pageSize]);

	if (!hasPastAppointments) return null;
	// if we are loading new appointments or if we fetched more appointments than displayed.
	const hasMoreToLoad = isLoading || pastAppointments.length > pageSize - 1;
	return (
		<div>
			<div>
				<AppointmentsList
					appointments={appointments}
					customBookAnAppointment={customBookAnAppointment}
					history={history}
					isPast
					linkToBookAppointment={linkToBookAppointment}
					linkToBusiness={linkToBusiness}
					order={'desc'}
				/>
				<div className={styles.footer}>
					{hasMoreToLoad ? (
						<Button
							id={'appointment-load-more-button'}
							isDark={isDark}
							isFullMobile
							isFullWidth
							isLoading={isLoading}
							label={<Localize text={'myAccount.loadMoreAppointments'} />}
							size={'medium'}
							type='tertiary'
							onClick={loadMore}
						/>
					) : (
						<div className={styles.allLoaded}>
							<Localize text={'myAccount.noMorePastAppointments'} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
