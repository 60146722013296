import React from 'react';
import * as Icons from '@planity/ui/assets/icons';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './banner.module.scss';

const statusConfig = {
	['INFORMATION']: {
		icon: 'Information',
		style: styles.information
	},
	['WARNING']: {
		icon: 'WarningTriangle',
		style: styles.warning
	},
	['INFORMATION_WARNING']: {
		icon: 'Information',
		style: styles.infowarning
	}
};

export const Banner = ({ status, children, iconSize = 24 }) => {
	useStyles(styles);

	const messageConfig = statusConfig[status] || statusConfig['INFORMATION'];
	const RenderedIcon = Icons[messageConfig.icon];

	return (
		<div className={messageConfig.style}>
			<div>
				{RenderedIcon ? (
					<RenderedIcon width={iconSize} height={iconSize} />
				) : null}
			</div>
			<p className={styles.text}>{children}</p>
		</div>
	);
};
