import React from 'react';
import { LoginForm } from './login_form';
import { useFormHooks } from '../useFormHooks';
import { LoginWithSetPhoneInDatabase } from './login_with_set_phone_in_database';
import { LoginWithGoogle } from './login_with_google';
import { MissingPhoneNumber } from './missing_phone_number';
import { LoginWithAskingMissingData } from './login_with_asking_missing_data';
import { Spinner } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './login.module.scss';
export const Login = () => {
	const { formValues } = useFormHooks();
	const { machineState } = formValues;
	useStyles(styles);
	const classes = classNames.bind(styles)({
		login: true
	});
	const renderSwitch = () => {
		switch (machineState) {
			case 'askingUserPassword':
			case 'checkingPasswordInput':
			case 'getCredentials':
			case 'mergingEmailAndPasswordWithProvider':
			case 'settingUserPhoneInDatabase':
				return <LoginWithSetPhoneInDatabase />;

			case 'askingUserToConnectWithGoogle':
			case 'signingInWithGoogleAndMergeAccount':
				return <LoginWithGoogle />;

			case 'checkingPhoneNumberInput':
			case 'missingPhoneNumber':
				return <MissingPhoneNumber />;

			case 'checkingUserDataEdition':
			case 'askingMissingData':
				return <LoginWithAskingMissingData />;

			case 'checkingSignInWithEmailAndPasswordInputs':
			case 'checkingUserData':
			case 'checkingProData':
			case 'fetchingDifferentAccounts':
			case 'idle':
			case 'transient':
			case 'success':
			case 'signingInWithEmailAndPassword':
			case 'signingInWithFacebook':
			case 'signingInWithGithub':
			case 'signingInWithGoogle':
			case 'setTemporaryUserInDatabase':
			case 'checkingIsDev':
				return <LoginForm />;

			case 'updatingPhoneNumberInDatabase':
			case 'creatingUserInDatabase':
			case 'checkingPhoneNumberConfirmation':
				return (
					<div className={styles.spinner}>
						<Spinner />
					</div>
				);

			default:
				console.warn(`Unhandled state view for ${machineState}`);
				return null;
		}
	};
	return <div className={classes}>{renderSwitch()}</div>;
};
