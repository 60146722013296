import React from 'react';

export const KR = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g>
				<mask
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#F7FCFF'
					/>
					<mask
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M10 11.3872C12.0017 11.3872 13.6244 9.6639 13.6244 7.53811C13.6244 5.41232 12.0017 3.68903 10 3.68903C7.9983 3.68903 6.3756 5.41232 6.3756 7.53811C6.3756 9.6639 7.9983 11.3872 10 11.3872Z'
							fill='#3D58DB'
						/>
						<mask
							style={{ maskType: 'luminance' }}
							maskUnits='userSpaceOnUse'
							x='6'
							y='3'
							width='8'
							height='9'
						>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M10 11.3872C12.0017 11.3872 13.6244 9.6639 13.6244 7.53811C13.6244 5.41232 12.0017 3.68903 10 3.68903C7.9983 3.68903 6.3756 5.41232 6.3756 7.53811C6.3756 9.6639 7.9983 11.3872 10 11.3872Z'
								fill='white'
							/>
						</mask>
						<g mask='url(#mask2_4303_1658)'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M13.7512 7.83294C13.7512 7.83294 13.5477 6.01797 12.0411 5.91944C10.5344 5.82092 10.1282 7.25043 10.0412 7.62917C9.95428 8.0079 9.73422 9.01821 8.14788 9.01821C6.56154 9.01821 6.41844 6.44919 6.41844 6.44919V3.41992H13.7512V7.83294Z'
								fill='#E31D1C'
							/>
						</g>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M4.56209 1.28259L5.13395 1.7998L2.48379 4.89474L1.91193 4.37753L4.56209 1.28259ZM5.50521 2.11957L6.07706 2.63677L3.5219 5.68687L2.95005 5.16967L5.50521 2.11957ZM7.01586 3.48406L6.44401 2.96685L3.88175 6.0401L4.4536 6.55731L7.01586 3.48406Z'
							fill='#272727'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M15.8523 1.06573L15.2804 1.581L16.2725 2.74527L16.8445 2.23001L15.8523 1.06573ZM17.6159 3.20738L17.0439 3.72265L18.036 4.88692L18.608 4.37166L17.6159 3.20738ZM13.3925 3.258L13.9645 2.74273L14.9566 3.907L14.3846 4.42227L13.3925 3.258ZM15.728 4.88438L15.156 5.39964L16.1481 6.56392L16.7201 6.04865L15.728 4.88438ZM14.3722 2.39449L14.9442 1.87922L17.7058 5.24226L17.1339 5.75753L14.3722 2.39449Z'
							fill='#272727'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M16.2843 8.90192L15.7075 8.38464L14.7071 9.55346L15.2838 10.0707L16.2843 8.90192ZM14.6023 10.9588L14.0255 10.4415L13.025 11.6103L13.6018 12.1276L14.6023 10.9588ZM17.5291 10.0205L18.1058 10.5378L17.1054 11.7066L16.5286 11.1893L17.5291 10.0205ZM16.506 12.6423L15.9292 12.1251L14.9288 13.2939L15.5055 13.8112L16.506 12.6423ZM14.9791 11.2363L15.5559 11.7535L14.5115 13.0016L13.9347 12.4843L14.9791 11.2363ZM17.2693 9.67387L16.6925 9.15659L15.6481 10.4047L16.2249 10.922L17.2693 9.67387Z'
							fill='#272727'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M4.0041 9.30618L4.5761 8.79034L7.12897 11.7156L6.55697 12.2314L4.0041 9.30618ZM4.5996 11.8647L5.1716 11.3489L6.24992 12.5336L5.67791 13.0494L4.5996 11.8647ZM2.69339 10.4318L2.12139 10.9476L4.68751 13.9122L5.25951 13.3964L2.69339 10.4318ZM3.10038 10.0951L3.67238 9.57929L4.60718 10.6755L4.03517 11.1913L3.10038 10.0951Z'
							fill='#272727'
						/>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
				style={{ mixBlendMode: 'multiply' }}
			/>
			<defs>
				<clipPath>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
