import { useModal } from '@planity/ui';
import { Button } from '@planity/ui';
import React from 'react';
import styles from './country_code_alert_modal_buttons.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';

export const CountryCodeAlertModalButtons = ({
	onCancelClick,
	onSubmitClick
}) => {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		countryCodeAlertModalButtons: true
	});
	const { closeModal } = useModal();
	const { t } = useTranslation();

	return (
		<div className={classes}>
			<Button
				className={styles.button}
				id='country-code-alert-cancel-button'
				isFullMobile
				label={t('actions.cancel')}
				onClick={() => closeModal(onCancelClick)}
				type={'secondary'}
			/>
			<Button
				className={styles.button}
				id='country-code-alert-confirm-button'
				isFullMobile
				label={t('countryChange.confirm')}
				onClick={() => closeModal(onSubmitClick)}
			/>
		</div>
	);
};
