import React from 'react';
import { FirebaseSubscription } from '@planity/components';

export default function WithBusinessGiftVouchersComponent({
	businessId,
	children
}) {
	return (
		<FirebaseSubscription path={`business_gift_vouchers/${businessId}`}>
			{({ data, isLoading }) =>
				children({
					businessGiftVouchers: data,
					businessGiftVouchersIsLoading: isLoading
				})
			}
		</FirebaseSubscription>
	);
}
