import React from 'react';
import classNames from 'classnames/bind';
import styles from './search_bar_date.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useFilters } from 'planity-website/app/components/ui/filter/context';
import { useDateFilter } from '@planity/helpers';
import { DatePickerField } from '@planity/ui';

export const SearchBarDate = ({
	className,
	setWhenResult,
	isExpanded,
	isRedirected
}) => {
	useStyles(styles);
	const { dispatch: updateFilter } = useFilters();
	const { handleInputClick } = useDateFilter(updateFilter);

	const classes = classNames.bind(styles)({
		SearchBarDate: true,
		[className]: !!className
	});
	return (
		<div className={classes} onClick={handleInputClick}>
			<DatePickerField
				isExpanded={isExpanded}
				setWhenResult={setWhenResult}
				isRedirected={isRedirected}
			/>
		</div>
	);
};
