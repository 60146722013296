import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './business_reviews_card.scss';
import { BusinessRating, BusinessReviews, Card, Tabs } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';
import { formatRating } from '@planity/helpers';

export function BusinessReviewsCard({ data }) {
	useStyles(styles);
	const { t } = useTranslation();
	const classes = classNames.bind(styles);
	const [state, setState] = useState('note');

	const [rating, setRating] = useState(getStateFromData(data, t));
	const tabs = [
		{ label: t('business.reviews.tabs.globalRating'), state: 'note' },
		{ label: t('business.reviews.tabs.rating'), state: 'reviews' }
	];
	const handleTabChange = (tabs, i) => {
		setState(tabs[i].state);
	};
	useEffect(() => {
		const nextState = getStateFromData(data, t);
		setRating(nextState);
	}, [data?.rating, t]);
	return (
		<div
			className={classes({
				businessReviewsCard: true
			})}
		>
			<div className={classes({ hideOnMobile: true })}>
				<Card hasPadding={false}>
					<Tabs
						index={0}
						isFullWidth={true}
						items={tabs.map(tab => tab.label)}
						onChange={i => handleTabChange(tabs, i)}
					/>

					{state === 'reviews' && (
						<div className={styles.reviews}>
							<BusinessReviews hasPagination={true} review={data} />
						</div>
					)}

					{state === 'note' && (
						<div className={styles.note}>
							<BusinessRating
								items={rating?.items}
								voteCount={rating?.voteCount}
								total={formatRating(rating?.total)}
							/>
						</div>
					)}
				</Card>
			</div>
			<div className={classes({ hideOnDesktop: true })}>
				<Card title={t('business.reviews.tabs.rating')}>
					<BusinessRating
						items={rating?.items}
						voteCount={rating?.voteCount}
						total={formatRating(rating?.total)}
					/>
					<BusinessReviews
						className={styles.reviews}
						review={data}
						hasPagination
					/>
				</Card>
			</div>
		</div>
	);
}

function getStateFromData(data, t) {
	if (!data) {
		return {
			items: [],
			voteCount: 0,
			total: 0
		};
	}
	const items = [];
	const ratingCategories = [
		'welcomeRating',
		'hygieneRating',
		'ambianceRating',
		'benefitRating'
	];
	ratingCategories.map(category => {
		return items.push({
			category: t(`business.reviews.ratingDetails.${category}`),
			note: formatRating(data?.rating?.ratingDetails[category])
		});
	});
	return {
		items: items,
		voteCount: data?.rating?.ratingsCount,
		total: data?.rating?.globalRating
	};
}
