export const SamsungInternetSvg = () => {
	return (
		<svg
			fill='none'
			width='28'
			height='28'
			viewBox='0 0 28 28'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_7606_3000)'>
				<path
					d='M10.812 0.017477H17.188C23.1496 0.017477 27.9825 4.85035 27.9825 10.812V17.188C27.9825 23.1496 23.1496 27.9825 17.188 27.9825H10.812C4.85036 27.9825 0.0174866 23.1496 0.0174866 17.188V10.812C0.0174866 4.85035 4.85036 0.017477 10.812 0.017477Z'
					fill='#7882FF'
				/>
				<path
					d='M21.4295 14C21.4295 13.6939 21.4112 13.3922 21.3758 13.0957C21.2952 12.932 21.2038 12.7672 21.1014 12.602C22.8899 14.1892 23.7654 15.9015 23.2499 17.2463C22.7905 18.4447 21.3147 19.1216 19.3067 19.2444C17.3464 19.3641 14.879 18.9556 12.3552 17.9881C9.83766 17.0231 7.73373 15.682 6.35562 14.2864C4.9369 12.8497 4.28741 11.3552 4.74793 10.1539C5.23743 8.87692 6.88112 8.19193 9.09294 8.13882L9.14838 8.13762C8.17622 8.92487 7.40379 9.94905 6.91894 11.1223C7.35368 12.4507 9.24148 14.0051 12.9021 15.5479C15.8972 16.8101 19.3974 17.4304 20.9604 16.4474L21.0416 16.3937C21.2932 15.6416 21.4295 14.8367 21.4295 14Z'
					fill='#4D5CC1'
				/>
				<path
					d='M21.3758 13.0957C21.4113 13.3922 21.4295 13.6939 21.4295 14C21.4295 14.8367 21.2932 15.6416 21.0416 16.3937C19.5216 17.4521 15.9503 16.8326 12.9021 15.5479C9.24148 14.0051 7.35369 12.4507 6.91894 11.1223C7.4038 9.94905 8.17623 8.92489 9.14838 8.13762L9.18632 8.10711C10.4758 7.07656 12.1109 6.46041 13.8899 6.46041C17.7478 6.46041 20.929 9.35806 21.3758 13.0957ZM13.8898 21.5395C9.82177 21.5395 6.50617 18.3176 6.35562 14.2864C7.73372 15.682 9.83765 17.0231 12.3552 17.9881C14.879 18.9556 17.3464 19.3641 19.3067 19.2443C17.9361 20.6597 16.0157 21.5395 13.8898 21.5395Z'
					fill='white'
				/>
			</g>
			<defs>
				<clipPath id='clip0_7606_3000'>
					<rect width='28' height='28' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
