import { useEffect, useMemo, useReducer } from 'react';
import { availabilitiesShards, masterShard, publicShard } from './firebase';
import hash from 'object-hash';

const DEFAULT_STATE = {};

/**
 * useFirebaseSubscriptions lets you perform multiple queries at the same time.
 * WARNING. We do not have time to clean this hook. We do not cancel any subscription.
 * Feel free to add the feature but, don't forget: cleanup is triggered at every
 * hook call. And we do not want to kill every subscription and relaunch them.
 *
 * @param {Object[]} params
 * currently used params are:
 * @param {string} params[].path - firebase path
 * @param {string} params[].shard - firebase shard
 *
 * but could be any of those used in refFromParams function (line 128)
 * @returns {Object<{
 * 	areLoading: boolean,
 * 	data: Object,
 * 	errors: Object
 * }>}
 */
export function useFirebaseSubscriptions(params) {
	const [state, dispatch] = useReducer(subscriptionsReducer, DEFAULT_STATE);
	useEffect(() => {
		if (!params) dispatch({ type: 'RESET_STATE' });

		Object.entries(params || {})?.forEach(([id, param]) => {
			const ref = refFromParams(param);

			// subscription already exist, ignore it
			if (state[id] && hash(state[id]?.param || {}) === hash(param)) {
				return;
			}
			// subscription has changed, remove the old one and listen to the new one
			if (!!state[id]?.param && hash(state[id].param) !== hash(param)) {
				// https://firebase.google.com/docs/database/web/read-and-write#detach_listeners
				// Calling off() on the location with no arguments removes all listeners at that location.
				state[id].ref.off();
				const onData = snapshot =>
					dataListener({ snapshot, id, dispatch, ref, param });
				const onError = error => errorListener({ error, dispatch, id });
				ref.on('value', onData, onError);
			}

			// new subscription
			if (!state[id]) {
				dispatch({
					id,
					type: 'DATA_LOADING'
				});
				const onData = snapshot =>
					dataListener({ snapshot, id, dispatch, ref, param });
				const onError = error => errorListener({ error, dispatch, id });
				ref.on('value', onData, onError);
			}
		});

		// remove useless subscriptions
		Object.entries(state).forEach(([id, { ref }]) => {
			if (!params[id]) {
				if (ref) {
					// need to check this because of savagely deleted businesses
					ref.off();
				}
				dispatch({
					id,
					type: 'REMOVE_FROM_STATE'
				});
			}
		});

		/*
		 TODO One day, clean this hook, with a mutable useRef.
		 Do not cancel every ref on every hook call please please please
		 */
	}, [hash(params)]);

	const returnData = useMemo(
		() =>
			Object.keys(params).reduce((all, id) => {
				if (state[id]?.data) all[id] = state[id].data;
				return all;
			}, {}),
		[state, hash(params)]
	);

	const returnErrors = useMemo(
		() =>
			Object.keys(params).reduce((all, id) => {
				all[id] = state[id]?.error;
				return all;
			}, {}),
		[state, hash(params)]
	);

	return {
		areLoading: getLoadingState(state),
		data: returnData,
		errors: returnErrors
	};
}

function subscriptionsReducer(state, action) {
	switch (action.type) {
		case 'DATA_LOADING':
			return {
				...state,
				[action.id]: {
					...state[action.id],
					error: null,
					isLoading: true
				}
			};
		case 'QUERY_CHANGE':
			return {
				...state,
				[action.id]: {
					...state[action.id],
					error: null,
					isLoading: false
				}
			};
		case 'DATA_CHANGE':
			return {
				...state,
				[action.id]: {
					...state[action.id],
					data: action.data,
					param: action.param,
					ref: action.ref,
					error: null,
					isLoading: false
				}
			};
		case 'ERROR':
			return {
				...state,
				[action.id]: {
					...state[action.id],
					error: action.error,
					isLoading: false
				}
			};
		case 'REMOVE_FROM_STATE': {
			delete state[action.id];
			return state;
		}
		case 'RESET_STATE':
			return DEFAULT_STATE;
		default:
			console.error('UNKNOWN_ACTION_ERROR');
	}
}

function refFromParams({
	path,
	orderByKey,
	orderByChild,
	orderByValue,
	startAt,
	equalTo,
	endAt,
	limitToFirst,
	limitToLast,
	shard
}) {
	const firebase =
		shard === 'availabilities1'
			? availabilitiesShards['1']
			: shard === 'availabilities2'
			? availabilitiesShards['2']
			: shard === 'public'
			? publicShard
			: masterShard;
	let ref = firebase.database().ref(path);
	if (orderByKey) {
		ref = ref.orderByKey();
	} else if (orderByChild) {
		ref = ref.orderByChild(orderByChild);
	} else if (orderByValue) {
		ref = ref.orderByValue(orderByValue);
	}
	if (isValidLimit(startAt)) ref = ref.startAt(startAt);
	if (isValidLimit(equalTo)) ref = ref.equalTo(equalTo);
	if (isValidLimit(endAt))
		ref = ref.endAt(typeof endAt === 'string' ? `${endAt}\uf8ff` : endAt);
	if (limitToFirst) ref = ref.limitToFirst(limitToFirst);
	if (limitToLast) ref = ref.limitToLast(limitToLast);
	return ref;
}

function isValidLimit(limit) {
	return limit || limit === 0 || limit === '';
}

const getLoadingState = data =>
	!!Object.values(data || {}).some(({ isLoading }) => isLoading);

function dataListener({ snapshot, dispatch, id, param, ref }) {
	const data = snapshot.val();
	// some businesses have been savagely deleted, just a little check
	if (data) {
		dispatch({
			id,
			type: 'DATA_CHANGE',
			data,
			param,
			ref
		});
	} else {
		dispatch({
			id,
			type: 'QUERY_CHANGE'
		});
	}
}

function errorListener({ error, dispatch, id }) {
	dispatch({
		id,
		type: 'ERROR',
		error
	});
}
