// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".signed_in-module_right-DAyRX{padding-top:82px}@media(min-width: 1080px){.signed_in-module_right-DAyRX{padding-top:0}}.signed_in-module_appointmentsContainer-bctAh{display:flex;flex-direction:column}.signed_in-module_appointmentsContainer-bctAh>:not(:last-child){margin-bottom:20px}.signed_in-module_fadeInWrapper-6kG2d{animation:signed_in-module_fadeIn-CvXbQ ease 1s 0s 1}@keyframes signed_in-module_fadeIn-CvXbQ{0%{opacity:0}50%{opacity:0}100%{opacity:1}}.signed_in-module_spinner-8Z8WU{display:flex;justify-content:center;align-items:center}@media(max-width: 768px){.signed_in-module_spinner-8Z8WU{padding-top:24px}}", "",{"version":3,"sources":["webpack://./../../packages/components/my_account_page/signed_in.module.scss"],"names":[],"mappings":"AAEA,8BACC,gBAAA,CAEA,0BAHD,8BAIE,aAAA,CAAA,CAIF,8CACC,YAAA,CACA,qBAAA,CACA,gEACC,kBAAA,CAIF,sCAEC,oDAAA,CAGD,yCACC,GACC,SAAA,CAED,IACC,SAAA,CAED,KACC,SAAA,CAAA,CAIF,gCACC,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,yBAJD,gCAKE,gBAAA,CAAA","sourcesContent":["@use '@planity/ui/styles/utilities/variables.scss';\n\n.right {\n\tpadding-top: 82px;\n\n\t@media (min-width: #{variables.$desktop}) {\n\t\tpadding-top: 0;\n\t}\n}\n\n.appointmentsContainer {\n\tdisplay: flex;\n\tflex-direction: column;\n\t& > :not(:last-child) {\n\t\tmargin-bottom: 20px;\n\t}\n}\n\n.fadeInWrapper {\n\t// Using animation-fill-mode: forward makes modal overlay not taking the all screen 🤷‍\n\tanimation: fadeIn ease 1s 0s 1;\n}\n\n@keyframes fadeIn {\n\t0% {\n\t\topacity: 0;\n\t}\n\t50% {\n\t\topacity: 0;\n\t}\n\t100% {\n\t\topacity: 1;\n\t}\n}\n\n.spinner {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\t@media (max-width: #{variables.$tablet}) {\n\t\tpadding-top: 24px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"right": "signed_in-module_right-DAyRX",
	"appointmentsContainer": "signed_in-module_appointmentsContainer-bctAh",
	"fadeInWrapper": "signed_in-module_fadeInWrapper-6kG2d",
	"fadeIn": "signed_in-module_fadeIn-CvXbQ",
	"spinner": "signed_in-module_spinner-8Z8WU"
};
module.exports = ___CSS_LOADER_EXPORT___;
