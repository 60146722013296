// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".search_bar_date-module_SearchBarDate-ob9cD{position:relative;width:100%}", "",{"version":3,"sources":["webpack://./../../packages/ui/components/search/search_bar_date/search_bar_date.module.scss"],"names":[],"mappings":"AAAA,4CACC,iBAAA,CACA,UAAA","sourcesContent":[".SearchBarDate {\n\tposition: relative;\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SearchBarDate": "search_bar_date-module_SearchBarDate-ob9cD"
};
module.exports = ___CSS_LOADER_EXPORT___;
