// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".overlay-module_overlay-gvqUl{position:absolute;top:0;left:0;width:100%;height:100%;background-color:var(--grey-700);backdrop-filter:blur(10px);transition:none var(--timing-medium) var(--ease-in-out);transition-property:opacity,visibility}.overlay-module_overlay-gvqUl:not(.overlay-module_visible-RIWjF){opacity:0;perspective:none;visibility:hidden}.overlay-module_overlay-gvqUl.overlay-module_visible-RIWjF{opacity:.5}", "",{"version":3,"sources":["webpack://./../../packages/ui/uikit/background/overlay/overlay.module.scss"],"names":[],"mappings":"AAAA,8BACC,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CAEA,gCAAA,CACA,0BAAA,CAEA,uDAAA,CACA,sCAAA,CAEA,iEACC,SAAA,CACA,gBAAA,CACA,iBAAA,CAGD,2DACC,UAAA","sourcesContent":[".overlay {\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\twidth: 100%;\n\theight: 100%;\n\n\tbackground-color: var(--grey-700);\n\tbackdrop-filter: blur(10px);\n\n\ttransition: none var(--timing-medium) var(--ease-in-out);\n\ttransition-property: opacity, visibility;\n\n\t&:not(.visible) {\n\t\topacity: 0;\n\t\tperspective: none;\n\t\tvisibility: hidden;\n\t}\n\n\t&.visible {\n\t\topacity: 0.5;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "overlay-module_overlay-gvqUl",
	"visible": "overlay-module_visible-RIWjF"
};
module.exports = ___CSS_LOADER_EXPORT___;
