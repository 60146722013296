import { Localize, DownloadPdfButton, useAuth } from '@planity/components';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useLocalization, useTranslation } from '@planity/localization';
import { formatPrice, invokeLambda } from '@planity/helpers';
import credentials from '@planity/credentials';
import ItemsPagination from '../items_list/items_pagination';
import { ErrorMessage } from '../error_message';
import { Badge, Button, Card } from '@planity/ui';
import styles from './invoices.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import {
	sendToUserApp,
	isNativeApp,
	decodeMessageFromUserApp
} from '@planity/webview';

export function Invoices() {
	useStyles(styles);
	const { userId } = useAuth();
	const [invoices, setInvoices] = useState([]);
	const [pdfDownloadError, setPdfDownloadError] = useState(false);
	const [isLoading, setIsLoading] = useState(null);
	const { t } = useTranslation();
	const { dateLocale } = useLocalization();

	useEffect(() => {
		let mounted = true;

		async function loadInvoices() {
			const invoicesData = await getInvoices(userId);
			if (mounted) {
				if (process.env.WIDGET) {
					// filter invoices by current business -
					const currentBusinessInvoices = invoicesData.filter(
						invoice => invoice.businessId === window.planity.key
					);
					setInvoices(currentBusinessInvoices);
				} else {
					setInvoices(invoicesData);
				}
			}
		}

		loadInvoices();

		return () => {
			mounted = false;
		};
	}, []);

	const renderItem = ({ item }) => {
		const invoice = item;
		const type = invoiceType(invoice);
		const invoiceKey = invoice.chargeId + '-' + type;
		const invoiceJSON = JSON.stringify(invoice);

		// the key for each item will be the charge id + the type of the invoice as an invoice and a credit might have the same charge id
		return (
			<div className={styles.invoice} key={invoiceKey}>
				<div className={styles.invoiceLeft}>
					<div className={styles.invoiceLeftUpper}>
						<p className={styles.businessName} id='invoices-business-name'>
							{invoice.business.name}
						</p>
						<Badge
							className={`
								${type === 'credit' && styles.creditTag}
								${type === 'invoice' && styles.invoiceTag}
							`}
							id={`invoices-${type}-badge`}
							label={t(`myAccount.invoice.${type}.label`)}
						/>
					</div>

					<p className={styles.invoiceInfo} id='invoices-amount-createdAt'>
						{formatPrice(+invoice.orders[0].amount)}
						<Localize text={'punctuation.spacedHyphen'} />
						{format(invoice.createdAt * 1000, 'dd MMMM yyyy', {
							locale: dateLocale
						})}
					</p>
				</div>
				<div className={styles.invoiceRight}>
					<DownloadButton
						invoice={invoice}
						invoiceJSON={invoiceJSON}
						invoiceKey={invoiceKey}
						type={type}
					/>
				</div>
			</div>
		);
	};

	const DownloadButton = ({ invoiceKey, invoiceJSON, invoice, type }) =>
		navigator.userAgent.match(/iPhone/i) &&
		(navigator.userAgent.includes('FxiOS') ||
			navigator.userAgent.includes('OPT')) ? (
			<form
				action={`https://product-api.lab.planity.app/generateInvoicePdf?invoice=${encodeURIComponent(
					invoiceJSON
				)}`}
				id='invoices-download-form'
				method={'post'}
				target={''}
			>
				<input name={'invoice'} type={'hidden'} value={invoiceJSON} />
				<Button
					iconLeft='Download'
					id='invoices-download-button'
					isDisabled={isLoading === invoiceKey}
					isLoading={isLoading === invoiceKey}
					label={t('myAccount.myProfile.download.invoice.button')}
					type='tertiary'
				/>
			</form>
		) : (
			<DownloadPdfButton
				id={invoiceKey}
				isLoading={isLoading}
				isTertiary
				text={'myAccount.myProfile.download.invoice.button'}
				onClick={async () =>
					await downloadPdf({
						setIsLoading,
						setPdfDownloadError,
						invoiceKey,
						invoiceJSON,
						invoice,
						type,
						t
					})
				}
			/>
		);

	const invoiceType = invoice => (invoice.creditId ? 'credit' : 'invoice');
	return (
		<div>
			{invoices?.length > 0 && (
				<>
					{pdfDownloadError && (
						<ErrorMessage
							error={'myAccount.myProfile.download.invoice.error'}
							errorType={'warning'}
						/>
					)}

					<Card
						hasTitleInside
						id='invoices-title'
						title={t('myAccount.myProfile.title.invoices')}
					>
						<ItemsPagination
							itemComponent={renderItem}
							items={invoices}
							pageSize={3}
						/>
					</Card>
				</>
			)}
		</div>
	);
}

async function getInvoices(userId) {
	return await invokeLambda('getCustomerInvoices', { userId });
}

async function downloadPdf({
	setIsLoading,
	setPdfDownloadError,
	invoiceKey,
	invoiceJSON,
	invoice,
	type,
	t
}) {
	setIsLoading(invoiceKey);

	if (isNativeApp) {
		sendToUserApp({
			type: 'DOWNLOAD_PDF',
			payload: JSON.stringify({
				invoiceKey,
				invoiceJSON,
				invoice,
				type
			})
		});

		const handleMessage = event => {
			const decodedMessage = decodeMessageFromUserApp(event.data);
			if (!decodedMessage) return;

			const { type, params } = decodedMessage;

			if (type === 'DATA_FROM_USER_APP') {
				const { message } = params;

				if (message && message === 'FILE_DOWNLOAD_SUCCESS') {
					console.log('FILE_DOWNLOAD_SUCCESS');
					setIsLoading(false);
				}
			}
		};

		window.addEventListener('message', handleMessage);
		return () => window.removeEventListener('message', handleMessage);
	}

	const action = () =>
		fetch(
			`${
				credentials.AWS_API_ENDPOINT
			}/generateInvoicePdf?invoice=${encodeURIComponent(invoiceJSON)}`,
			{
				method: 'POST',
				body: `invoice=${encodeURIComponent(invoiceJSON)}`
			}
		).catch(() => setPdfDownloadError(true));
	const pdf = await action();
	if (!pdf.errorType) {
		// create a blob in order to read the readable stream sent back from fetch
		const blob = await pdf.blob();
		const newBlob = new Blob([blob]);
		const linkSource = window.URL.createObjectURL(newBlob);
		const fileName = `${t(`myAccount.invoice.${type}.label`)}_Planity_${
			invoice.creditId || invoice.invoiceId
		}.pdf`;
		const downloadLink = document.createElement('a');
		downloadLink.href = linkSource;
		downloadLink.download = fileName;
		downloadLink.click();
		setIsLoading(false);
	} else {
		setPdfDownloadError(true);
	}
}
