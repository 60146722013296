import { useRandomId } from '@planity/context';
import {
	getScrollPosition,
	scrollTo,
	useIsomorphicLayoutEffect
} from '@planity/helpers';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './modal.module.scss';
import { Icon } from '@planity/ui';
import { useTranslation } from '@planity/localization';
import { useViewerHeaders } from '@planity/components';
import FocusTrap from 'focus-trap-react';

/**
 * **Do NOT use this component**
 * We strongly recommend using the useModal custom hook below instead of the Modal function.
 * zIndex are hard to manage, and the hook does it for you.
 * component directly. The useModal is safer and usually cleans itself whenever we use it. To set
 * the params, all you need is to extract the setModal from the useModal hook and set inside all
 * the necessary props.
 * @param {string} title - The title of the modal... obviously...
 * @param {JSX.Element} content - The body of the modal - what you want to show in the modal. This
 *   is the main stuff in your modal.
 * @param {JSX.Element} children - The children of the modal if any.
 * @param {JSX.Element} footerButtons - The buttons to be displayed in the footer of the modal -
 *   what are the actions the user can take when this modal is open
 * @param {function} closeModal - The function to be called when the modal wants to be closed.
 * @param {boolean} isOpen - A boolean indicating whether the modal is open or not.
 * @param {boolean} hasCloseBtn=true - A boolean indicating whether the modal has a close button or
 *   not. Top left corner in mobile view / Top right corner in tablet and desktop.
 * @param {boolean} showBackBtnOnMobile=false - A boolean indicating whether to show an arrow left
 *   instead of a cross for the back button, on mobile devices.
 * @param {boolean} isFullHeight - A boolean indicating whether the modal takes the full height of
 *   the screen or not. Usually for mobile...
 * @param {string} className - The CSS class name of the modal... Obviously!
 * @param {boolean} preventScroll - A boolean indicating whether the background should be prevented
 *   from scrolling when the modal is open or not.
 * @param hasBorderRadius {boolean}
 * @param onCloseModal {function} callback when modal is closed
 * @returns {JSX.Element} - The whole Modal component.
 */
export function Modal({
	isOpen,
	content,
	isFullHeight,
	className,
	preventScroll,
	preventScrollTrap,
	children,
	footerButtons,
	title,
	closeModal: _closeModal,
	hasCloseBtn = true,
	showBackBtnOnMobile = false,
	hasBorderRadius = false,
	onCloseModal,
	order,
	active, // used with useModal provider to handle multiple modals.
	disableScrollTop = false
	// If legacy component is used (props contain children), we determine if the modal is active by checking isOpen
}) {
	const { t } = useTranslation();
	const [bodyStyle, setBodyStyle] = useState({});
	const { isIOSViewer } = useViewerHeaders();
	useStyles(styles);
	const classes = classNames.bind(styles);
	const ref = useRef(null);
	useIsomorphicLayoutEffect(() => {
		if (disableScrollTop) return;
		if (isOpen) {
			preventBodyScroll(setBodyStyle, isIOSViewer);
		}
		return () => allowBodyScroll(bodyStyle);
	}, [isOpen]);

	const closeModal = useCallback(() => {
		if (typeof onCloseModal === 'function') onCloseModal();
		_closeModal();
	}, [_closeModal, onCloseModal]);

	useEffect(() => {
		const bind = e => {
			if (e.keyCode !== 27) return null;
			if (
				document.activeElement &&
				['INPUT', 'SELECT'].includes(document.activeElement.tagName)
			)
				return null;

			if (isOpen) {
				e.preventDefault();
				e.stopPropagation();
				closeModal();
				return false;
			}
		};

		document.addEventListener('keyup', bind);
		return () => document.removeEventListener('keyup', bind);
	}, [isOpen, closeModal]);

	const randomId = useRandomId();
	const modalId = `modal-container-${randomId}`;

	return (
		<div
			className={classes({
				modal: true,
				isOpen: isOpen,
				hasTitle: title,
				isFullHeight,
				[className]: className !== null,
				preventScroll,
				hasBorderRadius: hasBorderRadius,
				first: order === 1,
				second: order === 2
			})}
			tabIndex={-1}
			id={modalId}
		>
			<div className={styles.overlay} onClick={closeModal} />

			<FocusTrap
				active={!!active || (!!children && !!isOpen)}
				className={classes({ focusTrap: true })}
				focusTrapOptions={{
					escapeDeactivates: false,
					clickOutsideDeactivates: false,
					fallbackFocus: `#${modalId}`,
					allowOutsideClick: true,
					preventScroll: preventScrollTrap
				}}
			>
				<div
					className={classes({
						container: true,
						active: !!active || (!!children && !!isOpen)
					})}
					ref={ref}
				>
					{hasCloseBtn && (
						<div className={styles.top}>
							<button
								aria-label={t('common.close')}
								className={styles.close}
								onClick={closeModal}
							>
								<Icon
									className={classes({
										closeIcon: true,
										showBackBtnOnMobile
									})}
									icon={'Close'}
									size={'medium'}
								/>
								<Icon
									className={classes({
										arrowLeftIcon: true,
										showBackBtnOnMobile
									})}
									icon={'ArrowLeft'}
								/>
							</button>
							{title && <span className={styles.title}>{title}</span>}
						</div>
					)}
					<div className={styles.content}>{content ? content : children}</div>
					{footerButtons && (
						<div className={classes({ actions: true })}>{footerButtons}</div>
					)}
				</div>
			</FocusTrap>
		</div>
	);
}

function allowBodyScroll(bodyStyle) {
	const { body } = document;
	const isPrevented = !!body.dataset.hasPreventedScroll;
	if (isPrevented) {
		const top = Math.abs(parseInt(body.style.top) || 0);
		body.style = bodyStyle;

		scrollTo({ top });
		delete body.dataset.hasPreventedScroll;
	}
}

function preventBodyScroll(setBodyStyle, isIOSViewer) {
	const { body } = document;
	setBodyStyle(body.style);
	if (isIOSViewer) {
		body.style.position = 'sticky'; // For Safari on iOS
	} else {
		body.style.position = 'fixed';
	}
	body.style.overflow = 'hidden';
	body.style.left = '0';
	body.style['-webkit-overflow-scrolling'] = 'touch';
	body.style.width = '100%';
	body.dataset.hasPreventedScroll = 'true';
}

const ModalContext = React.createContext({});

export const ModalProvider = props => {
	const [modalList, setModalList] = useState([]);
	const closeModal = useCallback(
		async onCloseModal => {
			const TIMING_MEDIUM = parseFloat(
				getComputedStyle(document.documentElement).getPropertyValue(
					'--timing-medium'
				)
			);
			const modalToClose = modalList?.at(-1);

			// Start modal deletion animation; mark last item as "not active"
			setModalList(prevList =>
				prevList
					.slice(0, prevList.length - 1)
					.concat({ ...modalToClose, active: false })
			);

			// Perform deletion for real; remove last item
			await new Promise(resolve =>
				setTimeout(
					() => resolve(setModalList(prevList => prevList.slice(0, -1) || [])),
					isNaN(TIMING_MEDIUM) ? 500 : TIMING_MEDIUM * 1000
				)
			);

			// Deletion is done, execute callbacks
			if (
				typeof onCloseModal === 'function' &&
				typeof modalToClose?.onCloseModal === 'function' &&
				process.env.NODE_ENV === 'development'
			) {
				console.warn(
					'Closing modal but it has 2 callbacks. Are you sure you want to perform both of them ?'
				);
				console.log(onCloseModal.toString());
				console.log(modalToClose.onCloseModal.toString());
			}
			if (typeof onCloseModal === 'function') {
				onCloseModal();
			}
			if (typeof modalToClose?.onCloseModal === 'function')
				modalToClose?.onCloseModal();
		},
		[modalList]
	);

	const setModal = modal => {
		if (modal?.key && modalList?.find(_modal => _modal?.key === modal.key))
			return;

		return setModalList(prevList =>
			[...prevList, { ...modal, active: true }].sort(sortModalsByOrder)
		);
	};

	return (
		<ModalContext.Provider
			value={{
				closeModal,
				setModal,
				modalList
			}}
			{...props}
		>
			{props.children}

			<Modal
				closeModal={closeModal}
				isOpen={modalList.filter(modal => !!modal.active).length > 0}
				{...(modalList?.at(-1) || {})}
			/>
		</ModalContext.Provider>
	);
};

export const useModal = () => {
	const context = React.useContext(ModalContext);
	if (context === undefined) {
		throw new Error('useModal must be used within a UserProvider');
	}

	return context;
};

export const WithModal = ModalContext.Consumer;
export const withModal = WrappedComponent => props =>
	(
		<WithModal>
			{modalProps => <WrappedComponent {...modalProps} {...props} />}
		</WithModal>
	);

/**
 * the result of sorting should be: [{}, {}, {order: 3}, {order: 1}]
 * @param a {object}
 * @param b {object}
 * @return {number}
 */
function sortModalsByOrder(a, b) {
	if (!a.order && !b.order) return 0;
	if (!a.order) return -1;
	if (!b.order) return 1;
	return b.order - a.order;
}
