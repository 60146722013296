import React, { createContext, useContext, useState } from 'react';

export const AppointmentSourceContext = createContext({
	source: '',
	changeSource: () => {},
	isNewUser: false,
	changeIsNewUser: () => {}
});

export function AppointmentSourceProvider({ children }) {
	const [source, setSource] = useState('');
	const [isNewUser, setIsNewUser] = useState(false);

	function changeSource(newSource) {
		setSource(newSource);
	}

	function changeIsNewUser(isNewUser) {
		setIsNewUser(isNewUser);
	}

	return (
		<AppointmentSourceContext.Provider
			value={{ source, changeSource, isNewUser, changeIsNewUser }}
		>
			{children}
		</AppointmentSourceContext.Provider>
	);
}

export function AppointmentSourceConsumer({ children }) {
	return (
		<AppointmentSourceContext.Consumer>
			{({ source, changeSource, isNewUser, changeIsNewUser }) =>
				children({ source, changeSource, isNewUser, changeIsNewUser })
			}
		</AppointmentSourceContext.Consumer>
	);
}

export const useAppointmentSource = () => useContext(AppointmentSourceContext);
