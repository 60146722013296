import credentials from '@planity/credentials';
import { AVAILABLE_LANGUAGES, useLocalization } from '@planity/localization';
import React, { useMemo } from 'react';
import { AlgoliaSearch } from '../data';

export default function BusinessPageWithPlace({ business, children }) {
	const placeIds = [business.placeId, business.parentPlaceId].filter(x => !!x);
	const { language } = useLocalization();
	const LOCALIZED_ATTRIBUTES = [
		'name',
		'preposition',
		'postalCodes',
		'slug',
		'type'
	];
	const attributesToNotRetrieve = useMemo(
		() =>
			AVAILABLE_LANGUAGES.reduce(
				(all, lng) =>
					lng === language
						? all
						: all.concat(
								...LOCALIZED_ATTRIBUTES.map(attr => `-${attr}_${lng}`)
						  ),
				[]
			),
		[language, AVAILABLE_LANGUAGES]
	);

	if (placeIds.length) {
		return (
			<AlgoliaSearch
				attributesToHighlight={[]}
				attributesToRetrieve={['*'].concat(attributesToNotRetrieve)}
				filters={placeIds.map(id => `objectID:${id}`).join(' OR ')}
				index={credentials.PLACES_INDEX}
				localizeResults
			>
				{({ data }) => {
					if (data) {
						const place = data.find(p => p.objectID === business.placeId);
						const parentPlace = data.find(
							p => p.objectID === business.parentPlaceId
						);
						return children({ place, parentPlace });
					} else {
						return children({});
					}
				}}
			</AlgoliaSearch>
		);
	} else {
		return children({});
	}
}
