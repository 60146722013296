import React from 'react';

export const GH = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5693)'>
				<mask
					id='mask0_11534_5693'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5693)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 10H20V15H0V10Z'
						fill='#5EAA22'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 5H20V10H0V5Z'
						fill='#FECA00'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0H20V5H0V0Z'
						fill='#E11C1B'
					/>
					<path
						opacity='0.9'
						fillRule='evenodd'
						clipRule='evenodd'
						d='M10.0471 9.05651L7.86861 10.5676L8.56473 7.97948L6.96547 6.32621L9.13095 6.23682L10.0471 3.67758L10.9633 6.23682H13.1251L11.5295 7.97948L12.3286 10.4148L10.0471 9.05651Z'
						fill='#1D1D1D'
					/>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5693'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
