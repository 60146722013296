//@ts-check
import React, { useEffect, useState } from 'react';
import { Localize } from '@planity/components';
import { publicShard } from '@planity/datastores';
import { getLocalizedBasePath } from '@planity/localization';
import { BusinessSlideshow, Icon } from '@planity/ui';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './brand_content.module.scss';

export function BrandContent({
	business,
	brandContentId,
	setBrandContentIsExpanded
}) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		brandContent: true
	});

	const [brandContent, setBrandContent] = useState(null);
	const { countryCode, language } = business;

	useEffect(() => {
		const getBrandContent = async () => {
			const businessLocalePath = getLocalizedBasePath({
				language,
				countryCode
			});
			const content = await publicShard
				.database()
				.ref(`brand_content_localized/${businessLocalePath}/${brandContentId}`)
				.once('value');
			setBrandContent(content.val());
		};
		getBrandContent().catch(console.error);
	}, []);

	if (!brandContent) return null;
	const { title, description, media } = brandContent;
	const formattedDescription = description.split('/n').map(descriptionPart => {
		return descriptionPart === '' ? '<br />' : descriptionPart;
	});
	return (
		<div className={classes}>
			<div className={styles.moreContainer}>
				<div className={styles.container}>
					<BusinessSlideshow
						items={media.map(url => ({
							src: url,
							srcSet: url,
							isVideo: url.includes('youtube'),
							customCss: {
								display: 'flex',
								height: 'auto'
							}
						}))}
					/>
				</div>
				<div>
					<div className={styles.titleContainer}>
						<span className={styles.title}>{title}</span>
					</div>
					<div className={styles.paragraphe}>
						{formattedDescription.map((descriptionPart, index) => (
							<p
								className={styles.description}
								css={styles.description}
								dangerouslySetInnerHTML={{ __html: descriptionPart }}
								key={`description-part-${index}`}
							/>
						))}
					</div>
					<button
						className={styles.brandContentButton}
						onClick={e => {
							e.stopPropagation();
							setBrandContentIsExpanded(false);
						}}
					>
						<Icon icon='PlayRound' size={'medium'} />
						<Localize text={'bookAppointment.showLessDetailsDescription'} />
						<Icon icon='ChevronUp' size={'medium'} />
					</button>
				</div>
			</div>
		</div>
	);
}
