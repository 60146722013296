import React, { useEffect, useState } from 'react';
import { useLocalizedRoutes, useTranslation } from '@planity/localization';
import styles from './cancel_email_modification.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Button, InputPassword } from '@planity/ui';
import classNames from 'classnames/bind';
import { firebase } from '@planity/datastores';
import {
	useErrorMessages,
	useAuth,
	Form,
	signInWithCustomToken
} from '@planity/components';
import { invokeLambda, noop } from '@planity/helpers';
import { useLocation, useHistory } from 'react-router-dom';
import { useUikit } from '@planity/ui/shared/useUikit';
import { INVALID_PASSWORD_ERROR } from '@planity/components/authentication';

export const CancelEmailModification = () => {
	const auth = firebase.auth();
	const { isLoading } = useAuth();
	const location = useLocation();
	const { t } = useTranslation();
	const { routes } = useLocalizedRoutes();
	const { pushMessage } = useErrorMessages();
	useStyles(styles);
	const classes = classNames.bind(styles);
	const [hasRequestedPasswordReset, setHasRequestedPasswordReset] =
		useState(false);
	const [newPassword, setNewPassword] = useState('');
	const searchParams = new URLSearchParams(location?.search);
	const { toggle, toggleIcon } = useUikit();
	const history = useHistory();

	useEffect(() => {
		const resetEmailProcess = async () => {
			if (hasRequestedPasswordReset) return;

			try {
				const actionCode = searchParams.get('oobCode');

				const response = await invokeLambda('recoverOldEmail', {
					actionCode
				});
				if (response.errorMessage) throw response.errorMessage;
				await signInWithCustomToken(response.customToken);
			} catch (error) {
				console.error(error);
				pushMessage({
					message: t('cancelModificationEmail.defaultError'),
					type: 'error',
					createdAt: Date.now()
				});
			}
		};
		resetEmailProcess().then(noop);
	}, [hasRequestedPasswordReset]);

	const saveNewPassword = async event => {
		event?.preventDefault();
		try {
			if (newPassword.length < 6) {
				return pushMessage({
					message: t(`cancelModificationEmail.${INVALID_PASSWORD_ERROR}`),
					type: 'error',
					createdAt: Date.now()
				});
			}

			await auth.currentUser.updatePassword(newPassword);
			// Update successful
			pushMessage({
				message: t('cancelModificationEmail.newPasswordSuccess'),
				type: 'success',
				createdAt: Date.now()
			});
			history.push(routes.myAccount);
		} catch (error) {
			console.error(error);
			pushMessage({
				message: t('cancelModificationEmail.defaultError'),
				type: 'error',
				createdAt: Date.now()
			});
		}
	};

	if (hasRequestedPasswordReset) {
		return (
			<div className={classes({ cancelEmailModification: true })}>
				<Form
					className={classes({ container: true })}
					onSubmit={saveNewPassword}
				>
					<h1 className={classes({ title: true })}>
						{t('resetPassword.title')}
					</h1>
					<p className={classes({ text: true })}>{t('resetPassword.alert')}</p>
					<InputPassword
						autoFocus
						icon={toggle ? 'Eye' : 'EyeOff'}
						id={'reset-password-comp__input-password'}
						isRequired
						label={t('resetPassword.label')}
						name='password'
						placeholder={t('resetPassword.placeholder')}
						toggle={toggle}
						value={newPassword || ''}
						onChange={setNewPassword}
						onToggleIcon={toggleIcon}
					/>
					<div className={styles.actions}>
						<Button
							id={'reset-password-comp__submit'}
							isDisabled={newPassword?.length <= 0}
							isFullMobile
							isFullWidth
							isLoading={isLoading}
							label={t('resetPassword.submit')}
							onClick={saveNewPassword}
						/>
					</div>
				</Form>
			</div>
		);
	}

	return (
		<div
			className={classes({ cancelEmailModification: true })}
			id='recover-email-comp'
		>
			<Form
				className={classes({ container: true })}
				onSubmit={() => setHasRequestedPasswordReset(true)}
			>
				<h1 className={classes({ title: true })}>
					{t('cancelModificationEmail.confirmation')}
				</h1>
				<p className={classes({ text: true })}>
					{t('cancelModificationEmail.wasNotMe')}
				</p>
				<div className={classes({ actions: true })}>
					<Button
						id={'cancel-modification__submit'}
						isFullMobile
						isFullWidth
						isLoading={isLoading}
						label={t('cancelModificationEmail.changePassword')}
						onClick={() => setHasRequestedPasswordReset(true)}
					/>
					<Button
						id={'go-to-my-account__submit'}
						isFullMobile
						isFullWidth
						isLoading={isLoading}
						label={t('cancelModificationEmail.seeMyAccount')}
						outlined
						size={'default'}
						to={routes.myAccount}
						type={'secondary'}
					/>
				</div>
			</Form>
		</div>
	);
};
