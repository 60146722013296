import React, {
	useMemo,
	createContext,
	useContext,
	useEffect,
	useState
} from 'react';
import { ElementsConsumer, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import credentials from '@planity/credentials';
import { useLocalization } from '@planity/localization';

export const StripeElementsContext = createContext({});

export const useStripeElements = () => useContext(StripeElementsContext);
export const StripeElementsProvider = ({ children }) => {
	const { countryCode: localeCountryCode } = useLocalization();

	const [stripeCountryCode, setStripeCountryCode] = useState(localeCountryCode);

	const initialAPIKey =
		credentials[`STRIPE_API_KEY_${localeCountryCode}`] ||
		credentials.STRIPE_API_KEY_FR;
	const [stripeAPIKey, setStripeAPIKey] = useState(initialAPIKey);

	/**
	 * Stripe country code will be changed when reaching:  the business page
	 * OR "My account" informations page ONLY if the actual stripe country code
	 * is different than the business country code or locale country code
	 * PS : not a very safe solution for now :(
	 */
	useEffect(() => {
		const newAPIKey =
			credentials[`STRIPE_API_KEY_${stripeCountryCode}`] ||
			credentials.STRIPE_API_KEY_FR;
		if (newAPIKey !== stripeAPIKey) {
			// Important for the logs
			console.log(
				`💸 %cStripe API %c${stripeCountryCode}%c will be loaded`,
				'font-weight:bold',
				'background:white;color:blue;font-weight:bold',
				'background:white;color:black;font-weight:normal'
			);

			setStripeAPIKey(newAPIKey);
		}
	}, [stripeCountryCode]);

	const stripePromise = useMemo(() => loadStripe(stripeAPIKey), [stripeAPIKey]);
	return (
		// for rerendering
		<Elements key={stripeAPIKey} stripe={stripePromise}>
			<ElementsConsumer>
				{({ elements, stripe }) => (
					<StripeElementsContext.Provider
						value={{
							elements,
							stripe,
							setStripeCountryCode,
							stripeCountryCode
						}}
					>
						{children}
					</StripeElementsContext.Provider>
				)}
			</ElementsConsumer>
		</Elements>
	);
};

export const withStripeElementsConsumer = Component => props =>
	(
		<StripeElementsContext.Consumer>
			{({ elements, stripe, setStripeCountryCode, stripeCountryCode }) => (
				<Component
					{...props}
					elements={elements}
					setStripeCountryCode={setStripeCountryCode}
					stripe={stripe}
					stripeCountryCode={stripeCountryCode}
				/>
			)}
		</StripeElementsContext.Consumer>
	);
