import styles from './details.module.scss';
import { useTranslation } from '@planity/localization';
import { getStepInfo } from '@planity/helpers';
import { Icon } from '@planity/ui';
import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
export function AppointmentDetails({ business, step, ...rest }) {
	const { t } = useTranslation();
	const { serviceName, duration, price } = getStepInfo(step, business, t);
	useStyles(styles);
	const cx = classNames.bind(styles);
	return (
		<div className={cx()} {...rest}>
			<div className={styles.inline}>
				<Icon icon='Service' id='step-desc-service-name-icon' size={'small'} />
				<span className={styles.text}>{serviceName}</span>
			</div>
			<div className={styles.info}>
				<div className={styles.inline}>
					<Icon icon='Timer' id='step-desc-duration-icon' size={'small'} />
					<span className={styles.text}>{duration}</span>
				</div>
				<div className={styles.inline}>
					<div className={styles.dot} />
				</div>
				<div className={styles.inline}>
					<Icon icon='BankNote' id='step-desc-price-icon' size={'small'} />
					<span className={styles.text}>{price}</span>
				</div>
			</div>
		</div>
	);
}
