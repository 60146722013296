import { withLocalizedRoutes } from '@planity/localization';
import React, { Children, Component, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { isMobile } from '@planity/theme/breakpoints';
import { safeRead } from '@planity/helpers';
import { withCategories } from './with_categories';

// const isEqual = require('lodash/isEqual');
export const SearchContext = React.createContext();
export const SearchProvider = withLocalizedRoutes(
	withCategories(
		withRouter(
			class SearchContextProvider extends Component {
				/**
				 * Set a search request.
				 * This prototype is so much pain
				 * @param search the query
				 * @param isRoot on sait pas à quoi il sert :'(
				 * @param executeQuery if we should execute the query
				 * @param navigationType optional : should we push, replace... in history ?
				 */
				setSearch = (search, isRoot, executeQuery, navigationType) => {
					if (search) {
						this.setState(
							({ prevRelevantLocationWasRoot }) => ({
								category: search.category || search.parentCategory || null,
								parentCategory: search.parentCategory || null,
								googlePlace: search.googlePlace || null,
								place: search.place || null,
								parentPlace: search.parentPlace || null,
								userLocation: search.userLocation || null,
								prevRelevantLocationWasRoot:
									isRoot === undefined ? prevRelevantLocationWasRoot : isRoot
							}),
							() => {
								if (executeQuery) {
									this.submit(
										navigationType || (isMobile() ? 'replace' : 'push')
									);
								}
							}
						);
					} else if (isRoot !== undefined) {
						this.setState({
							prevRelevantLocationWasRoot: isRoot
						});
					}
				};

				submit = navigationType => {
					const category =
						safeRead(this.state, ['category', 'depth']) === 2 &&
						this.state.category;
					const parentCategory = category
						? (this.props.categories || []).find(
								c => c.objectID === category.parentId
						  )
						: safeRead(this.state, ['category', 'depth']) === 1 &&
						  this.state.category;
					const nextRoute = this.props.routes.catchAll({
						search: {
							parentCategory: parentCategory || null,
							category: category || null,
							...(this.state.googlePlace
								? { googlePlace: this.state.googlePlace }
								: this.state.userLocation
								? { userLocation: this.state.userLocation }
								: {
										place: this.state.place || null,
										parentPlace: this.state.parentPlace || null
								  })
						}
					});
					if (
						(category || parentCategory) &&
						nextRoute.pathname !== this.props.location.pathname
					) {
						this.props.history[navigationType](nextRoute);
					}
				};

				state = {
					category: null,
					googlePlace: null,
					place: null,
					parentPlace: null,
					prevRelevantLocationWasRoot: null,
					setSearch: this.setSearch,
					submitSearch: this.submit,
					userLocation: null
				};

				render() {
					return (
						<SearchContext.Provider value={this.state}>
							{Children.only(this.props.children)}
						</SearchContext.Provider>
					);
				}
			}
		)
	)
);

export const WithSearch = SearchContext.Consumer;
export const useSearch = () => useContext(SearchContext);

export function withSearch(WrappedComponent) {
	return props => (
		<WithSearch>
			{search => <WrappedComponent {...search} {...props} />}
		</WithSearch>
	);
}

export function PropagateSearch(props) {
	return (
		<WithSearch>
			{({ setSearch }) => <SetSearch {...props} setSearch={setSearch} />}
		</WithSearch>
	);
}

class SetSearch extends Component {
	componentDidMount() {
		this.setSearch();
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.search !== prevProps.search ||
			this.props.category !== prevProps.category ||
			this.props.isRoot !== prevProps.isRoot
		) {
			this.setSearch();
		}
	}

	render() {
		return Children.only(this.props.children);
	}

	setSearch() {
		const { search, category, isRoot } = this.props;
		this.props.setSearch(search || (category ? { category } : null), isRoot);
	}
}
