import React, { useRef, useEffect } from 'react';
import { LoginWithSocialMedia } from '../../commun/loginWithSocialMedia';
import { ContextSocialLogin } from '../../context_form';
import { useFormHooks } from '../useFormHooks';
import { useTranslation } from '@planity/localization';
import { Authentication } from '@planity/ui';
import { PhoneInput } from '@planity/components';
import { PHONE_NUMBER_CHANGE } from '../../auth_actions';
import styles from '../../authentication.module.scss';

export const SignUpForm = () => {
	const { t } = useTranslation();
	const phoneInput = useRef(null);

	const { state, dispatch, socialLoginValues, formValues, errors } =
		useFormHooks();
	const {
		conditionsLink,
		defaultCountry,
		pending,
		machineState,
		navigateLogState
	} = formValues;

	useEffect(() => {
		if (phoneInput && phoneInput.current && phoneInput.current.focus)
			phoneInput.current.focus();
	}, [machineState]);
	const SIGN_IN = 'SIGN_IN';
	const SIGN_UP = 'SIGN_UP';
	const data = state && state.context;
	const localizedSignUpText = {
		title: t(`${process.env.WIDGET ? 'widget.' : ''}auth.form.buttons.new`),
		labels: {
			phoneNumber: t('auth.form.labels.phone'),
			email: t('auth.form.labels.email'),
			password: t('auth.form.labels.password'),
			passwordConfirmation: t('auth.form.labels.passwordConfirmation'),
			checkbox: {
				accept: t('auth.form.labels.checkbox.accept'),
				cgu: t('auth.form.labels.checkbox.cgu'),
				from: t('auth.form.labels.checkbox.from'),
				link: conditionsLink
			},
			button: t(`auth.form.buttons.${SIGN_UP}`)
		},
		placeholders: {
			phoneNumber: t(`auth.form.placeholders.phone`),
			email: t(`auth.form.placeholders.email`),
			password: t(`auth.form.placeholders.password`),
			passwordConfirmation: t(`auth.form.placeholders.passwordConfirmation`)
		},
		memberConnection: {
			title: t(
				`${process.env.WIDGET ? 'widget.' : ''}auth.form.buttons.already_used`
			),
			label: t(`auth.form.buttons.${SIGN_IN}`)
		}
	};
	const phoneInputField = (
		<PhoneInput
			autoFocus
			css={css.phoneInput}
			data={data}
			defaultCountry={defaultCountry}
			errors={errors}
			forwardedRef={phoneInput}
			id='signup-form-comp__input-phone'
			localizedPlaceholder={'auth.form.placeholders.phoneNumber'}
			localizedText={localizedSignUpText}
			value={data?.phoneNumber || ''}
			onChange={(phoneNumberInput, value) =>
				dispatch({
					type: PHONE_NUMBER_CHANGE,
					phoneNumber: phoneNumberInput,
					phoneNumberFormat: value
				})
			}
		/>
	);
	return (
		<div className={styles.signup} id='signup-form'>
			<Authentication
				data={data}
				localizedText={localizedSignUpText}
				logState={'signup'}
				navigateLogState={navigateLogState}
				pending={pending}
				phoneInput={phoneInputField}
			/>
			<ContextSocialLogin.Provider value={socialLoginValues}>
				<LoginWithSocialMedia type={'signUp'} />
			</ContextSocialLogin.Provider>
		</div>
	);
};
const css = {
	phoneInput: {
		width: '100%'
	}
};
