import { firebase } from '@planity/datastores';
import { invokeLambda } from './browser';

/**
 *
 * @typedef Child
 * @property {string} childId
 * @property {string} parentId
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} name
 * @property {string} [email]
 * @property {string} [phone]
 * @property {number} [deletedAt]
 * @property {boolean} hasPlanityAccount
 */

const callHandler = async ({ action, payload }) => {
	const userToken = await firebase.auth().currentUser?.getIdToken();
	const res = await invokeLambda('handleUserChildren', {
		...payload,
		action,
		userToken
	});
	if (res.statusCode === 200) return res.body;
	throw new Error(res.statusCode);
};

/**
 * get all children of the passed parent
 * @param {string} parentId - The parent ID
 * @returns {Promise<Child[]>}
 */
export const getAllChildren = ({ parentId }) =>
	callHandler({ action: 'getAllChildren', payload: { parentId } });

/**
 * create a child
 * @param {string} childId
 * @param {string} parentId
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} name
 * @param {string} [email]
 * @param {string} [phone]
 * @returns {Promise<Child>}
 */
const createChild = ({ parentId, firstName, lastName, name, email, phone }) =>
	callHandler({
		action: 'createChild',
		payload: { parentId, firstName, lastName, name, email, phone }
	});

/**
 * update a child
 * @param {string} childId
 * @param {string} parentId
 * @param {string} [firstName]
 * @param {string} [lastName]
 * @param {string} [name]
 * @param {string} [email]
 * @param {string} [phone]
 * @returns {Promise<Child>}
 */
const updateChild = ({
	childId,
	parentId,
	firstName,
	lastName,
	name,
	email,
	phone
}) =>
	callHandler({
		action: 'updateChild',
		payload: {
			childId,
			parentId,
			firstName,
			lastName,
			name,
			email,
			phone
		}
	});

/**
 * delete a child
 * @param {string} childId
 * @param {boolean} isLast
 * @param {string} parentId
 * @returns {Promise<*>}
 */
const deleteChild = ({ childId, isLast, parentId }) =>
	callHandler({
		action: 'deleteChild',
		payload: { childId, isLast, parentId }
	});

/**
 * check if the child have a future appointments
 * @param parentId
 * @param childId
 * @returns {Promise<boolean>}
 */
const checkChildDeletion = ({ parentId, childId }) =>
	callHandler({ action: 'checkChildDeletion', payload: { parentId, childId } });

/**
 * check if child already exist in user children or parent
 * @param userChildren
 * @param newChild
 * @param parent
 * @returns {string|null} return 'parent' | 'children' | null
 */
const checkIfChildAlreadyExists = async ({
	userChildren,
	newChild,
	parent
}) => {
	const newChildName = newChild.name.toLowerCase();
	const parentName = parent.name.toLowerCase();

	if (newChildName === parentName) return 'parent';

	const existingChild = userChildren.find(
		child => child.name.toLowerCase() === newChildName
	);

	if (existingChild) return 'children';

	return null;
};

export {
	createChild,
	checkIfChildAlreadyExists,
	updateChild,
	deleteChild,
	checkChildDeletion
};
