// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fees_description_feesDescription-3rH5a{display:flex;flex-direction:row;align-items:center;color:var(--grey-600);margin-top:8px;font:var(--regular) var(--body-4)}.fees_description_feesDescription-3rH5a.fees_description_isBold-UGVjm{font:var(--medium) var(--body-4)}.fees_description_text-P8rzr{margin-left:4px}.fees_description_text-P8rzr.fees_description_isBold-UGVjm{font:var(--medium) var(--body-4)}.fees_description_price-UrE7l:before{content:\" \"}.fees_description_price-UrE7l.fees_description_isBold-UGVjm{font:var(--medium) var(--body-4)}", "",{"version":3,"sources":["webpack://./../../packages/ui/components/appointment/fees_description/fees_description.scss"],"names":[],"mappings":"AAAA,wCAAA,YACC,CAAA,kBACA,CAAA,kBACA,CAAA,qBACA,CAAA,cACA,CAAA,iCACA,CAAA,sEACA,gCACC,CAAA,6BAIF,eACC,CAAA,2DACA,gCACC,CAAA,qCAKD,WAGC,CAAA,4DAED,gCACC","sourcesContent":[".feesDescription {\n\tdisplay: flex;\n\tflex-direction: row;\n\talign-items: center;\n\tcolor: var(--grey-600);\n\tmargin-top: 8px;\n\tfont: var(--regular) var(--body-4);\n\t&.isBold {\n\t\tfont: var(--medium) var(--body-4);\n\t}\n}\n\n.text {\n\tmargin-left: 4px;\n\t&.isBold {\n\t\tfont: var(--medium) var(--body-4);\n\t}\n}\n\n.price {\n\t&:before {\n\t\t// We need a span for the price but we also need a space between text and price\n\t\t// So here it it\n\t\tcontent: '\\00a0';\n\t}\n\t&.isBold {\n\t\tfont: var(--medium) var(--body-4);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feesDescription": "fees_description_feesDescription-3rH5a",
	"isBold": "fees_description_isBold-UGVjm",
	"text": "fees_description_text-P8rzr",
	"price": "fees_description_price-UrE7l"
};
module.exports = ___CSS_LOADER_EXPORT___;
