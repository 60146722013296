import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './radio_picture.module.scss';
import { Avatar } from '../avatar';

export function RadioPicture({
	hidePicture,
	imageUrl,
	name,
	label,
	value,
	onChange
}) {
	useStyles(styles);
	const classes = classNames.bind(styles)('planity_ui_action_worker-name', {
		radioPicture: true
	});

	return (
		<label className={classes}>
			{!hidePicture && (
				<Avatar
					altDescription={label}
					className={styles.image}
					imageUrl={imageUrl}
					initial={label[0]}
					isCloudinary
					size='large'
				/>
			)}

			<span className={styles.label}>{label}</span>

			<input
				checked={value === name}
				className={styles.input}
				name={name}
				type='radio'
				value={value}
				onChange={onChange}
			/>
			<span />
			<div className={styles.border}></div>
		</label>
	);
}
