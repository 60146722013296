import React from 'react';

export function Cart({ outlined }) {
	return (
		<svg
			width='25'
			height='24'
			viewBox='0 0 25 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M8.34998 7.75H19.85L18.2128 14.7081C18.0002 15.6115 17.194 16.25 16.2659 16.25H12.1394C11.232 16.25 10.4382 15.639 10.206 14.7618L8.34998 7.75ZM8.34998 7.75L7.59998 4.75H5.34998'
				stroke={!outlined ? 'currentColor' : 'none'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M11.1 19C11.1 19.2761 10.8761 19.5 10.6 19.5C10.3238 19.5 10.1 19.2761 10.1 19C10.1 18.7239 10.3238 18.5 10.6 18.5C10.8761 18.5 11.1 18.7239 11.1 19Z'
				stroke={!outlined ? 'currentColor' : 'none'}
			/>
			<path
				d='M18.1 19C18.1 19.2761 17.8761 19.5 17.6 19.5C17.3238 19.5 17.1 19.2761 17.1 19C17.1 18.7239 17.3238 18.5 17.6 18.5C17.8761 18.5 18.1 18.7239 18.1 19Z'
				stroke={!outlined ? 'currentColor' : 'none'}
			/>
		</svg>
	);
}
