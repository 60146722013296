import React from 'react';

export default ({ style, className, onClick, transform, ...props }) => (
	<svg
		css={[style, className]}
		height={'24'}
		viewBox={'0 0 24 24'}
		width={'24'}
		xmlns='http://www.w3.org/2000/svg'
		onClick={onClick}
		{...props}
	>
		<path
			d='M17.983 9.4c0-.622-.05-1.248-.158-1.86H9.188v3.526h4.946c-.205 1.137-.864 2.143-1.83 2.782v2.288h2.95c1.733-1.595 2.73-3.95 2.73-6.736z'
			fill='#4285F4'
			transform='translate(3 3)'
		/>
		<path
			d='M9.188 18.347c2.47 0 4.553-.811 6.07-2.21l-2.95-2.289c-.822.559-1.881.875-3.116.875-2.389 0-4.414-1.612-5.141-3.778H1.006v2.358c1.554 3.092 4.72 5.044 8.182 5.044z'
			fill='#34A853'
			transform='translate(3 3)'
		/>
		<path
			d='M4.047 10.945c-.383-1.138-.383-2.369 0-3.506V5.08H1.006c-1.299 2.588-1.299 5.636 0 8.223l3.041-2.358z'
			fill='#FBBC04'
			transform='translate(3 3)'
		/>
		<path
			d='M9.188 3.657c1.306-.02 2.568.471 3.513 1.373l2.614-2.614C13.66.86 11.463.006 9.188.034 5.726.034 2.56 1.985 1.006 5.08L4.047 7.44c.724-2.17 2.753-3.782 5.141-3.782z'
			fill='#EA4335'
			transform='translate(3 3)'
		/>
		{!!transform && <use transform={transform} />}
	</svg>
);
