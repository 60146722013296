import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/**
 * The crash handler you need. Use the prop `errorComponent` to print the page you
 * want when crashing. You can implement the componentDidCatch if you want
 * to do side effects with the caught error.
 */
export const CrashHandler = withRouter(
	class extends Component {
		state = { hasError: false };

		componentDidMount() {
			const { history } = this.props;

			history.listen(() => {
				// On page change, reset error
				this.setState({
					hasError: false
				});
			});
		}

		static getDerivedStateFromError() {
			return { hasError: true };
		}
		componentDidCatch(error, info) {
			console.log(error, info.componentStack);
		}

		render() {
			if (this.state.hasError) {
				return this.props.errorComponent || <div />;
			}
			return this.props.children;
		}
	}
);
