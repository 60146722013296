import { differenceInDays } from 'date-fns';
import credentials from '@planity/credentials';
import fetchPonyfill from 'fetch-ponyfill';
const { fetch } = fetchPonyfill();

const { GOOGLE_CONVERSION_ENDPOINT, GOOGLE_PARTNERID } = credentials;
const GOOGLE_TOKEN_MAX_VALID_DAYS = 30;
/**
 *
 * @param {Object} rwgToken rwgToken details
 * @param {string} rwgToken.value conversion token to be sent to google
 * @param {number} rwgToken.createdAt rwgToken creation time
 * @description if the token is valid, send a conversion event to google
 */
export function sendGoogleConversion(rwgToken) {
	const isRwgTokenValid =
		differenceInDays(Date.now(), rwgToken.createdAt) <=
		GOOGLE_TOKEN_MAX_VALID_DAYS;

	if (isRwgTokenValid) {
		fetch(GOOGLE_CONVERSION_ENDPOINT, {
			method: 'POST',
			body: JSON.stringify({
				conversion_partner_id: GOOGLE_PARTNERID,
				rwg_token: rwgToken.value
			})
		}).catch(console.error);
	}
}
