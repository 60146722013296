/**
 * Only keeps gift vouchers from userBuyings
 * @param buyings {Object} User buyings
 * @return {Object} only gift vouchers buyings
 */
export const giftVouchersFilter = buyings => {
	return Object.entries(buyings || {}).reduce((all, [buyingId, buying]) => {
		if (['gift_voucher'].includes(buying.type)) {
			all[buyingId] = buying;
		}
		return all;
	}, {});
};
