import React from 'react';
import { LoginWithSocialMedia } from '../../commun/loginWithSocialMedia';
import { ContextSocialLogin } from '../../context_form';
import { useFormHooks } from '../useFormHooks';
import { Authentication } from '@planity/ui';
import { useTranslation } from '@planity/localization';
import styles from '../../authentication.module.scss';

export const LoginForm = () => {
	const { state, socialLoginValues, formValues } = useFormHooks();
	const { pending, navigateLogState } = formValues;
	const { t } = useTranslation();
	const FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD';
	const SIGN_IN = 'SIGN_IN';
	const SIGN_UP = 'SIGN_UP';
	const localizedLoginText = {
		title: t(
			`${process.env.WIDGET ? 'widget.' : ''}auth.form.buttons.already_used`
		),
		labels: {
			email: t('auth.form.labels.email'),
			password: t('auth.form.labels.password'),
			forgetPassword: t(`auth.form.links.${FORGOTTEN_PASSWORD}`),
			button: t(`auth.form.buttons.${SIGN_IN}`)
		},
		placeholders: {
			email: t(`auth.form.placeholders.email`),
			password: t(`auth.form.placeholders.password`)
		},
		memberConnection: {
			title: t(`${process.env.WIDGET ? 'widget.' : ''}auth.form.buttons.new`),
			label: t(`auth.form.buttons.${SIGN_UP}`)
		}
	};
	return (
		<div className={styles.login} id='login-form'>
			<Authentication
				data={state?.context}
				localizedText={localizedLoginText}
				logState={'login'}
				navigateLogState={navigateLogState}
				pending={pending}
			/>
			<ContextSocialLogin.Provider value={socialLoginValues}>
				<LoginWithSocialMedia type={'signIn'} />
			</ContextSocialLogin.Provider>
		</div>
	);
};
