export function Download() {
	return (
		<svg
			width='25'
			height='24'
			viewBox='0 0 25 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M5.14999 14.75V16.25C5.14999 17.9069 6.49314 19.25 8.14999 19.25H16.65C18.3068 19.25 19.65 17.9069 19.65 16.25V14.75'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M12.4 14.25L12.4 4.75'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M9.14999 10.75L12.4 14.25L15.65 10.75'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
