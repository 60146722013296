import React from 'react';

export const PA = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5879)'>
				<mask
					id='mask0_11534_5879'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5879)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#F7FCFF'
					/>
					<mask
						id='mask1_11534_5879'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_11534_5879)'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M10 0V7.5H20V0H10Z'
							fill='#E31D1C'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M14.388 11.9685L12.949 12.859L13.5077 11.3642L12.2845 10.2325H13.7681L14.388 8.61554L14.8611 10.2325H16.347L15.2762 11.3642L15.8005 12.859L14.388 11.9685Z'
							fill='#E31D1C'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M5.63804 5.228L4.19902 6.11845L4.75769 4.62362L3.53452 3.49194H5.01813L5.63804 1.875L6.11111 3.49194H7.59702L6.52615 4.62362L7.05045 6.11845L5.63804 5.228Z'
							fill='#2E42A5'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 7.5V15H10V7.5H0Z'
							fill='#2E42A5'
						/>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5879'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
