import { useAuth, useViewerHeaders } from '@planity/components';
import { Button, Drawer, useModal } from '@planity/ui';
import React, { useState } from 'react';
import { useTranslation, useLocalization } from '@planity/localization';
import styles from './add_to_calendar.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import {
	appointmentStartTime,
	appointmentEndTime,
	businessAddress,
	ICAL,
	YAHOO_CALENDAR,
	OUTLOOK_CALENDAR,
	GOOGLE_CALENDAR,
	APPLE_CALENDAR,
	buildUrl,
	saveFile
} from './helpers';
import { isNativeApp, sendToUserApp } from '@planity/webview';

export const AddToCalendar = ({
	myAccountLink,
	business,
	appointment,
	className
}) => {
	useStyles(styles);
	const { isIOSViewer } = useViewerHeaders();
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const { t } = useTranslation();
	const { language } = useLocalization();
	const classes = classNames.bind(styles);
	const { setModal, closeModal } = useModal();
	const { user } = useAuth();

	const getCalendarPayload = () => {
		return {
			description: t('myAccount.appointment.addToCalendar.description', {
				myAccountLink
			}),
			title: t('myAccount.appointment.addToCalendar.title', {
				name: business.name
			}),
			location: businessAddress(business)
		};
	};

	const onSelect = ({ label }) => {
		const { description, title, location } = getCalendarPayload();
		const hasSequence = !!Object.keys(appointment?.originalSequence || {})
			.length;
		const appointmentId =
			appointment.id === 'confirmedApp' && hasSequence
				? Object.keys(appointment.originalSequence).join(',')
				: appointment.id;
		try {
			const url = buildUrl(
				{
					description,
					title,
					location,
					startTime: appointmentStartTime(appointment, business),
					endTime: appointmentEndTime(appointment, business, t),
					myAccountLink,
					business,
					user,
					appointmentId
				},
				label
			);

			switch (label) {
				case GOOGLE_CALENDAR:
				case YAHOO_CALENDAR:
				case OUTLOOK_CALENDAR:
					window.open(url, '_blank');
					break;
				case APPLE_CALENDAR:
				case ICAL:
				default:
					saveFile(
						`data:text/calendar;charset=utf-8,${encodeURIComponent(url)}`,
						t('myAccount.appointment.addToCalendar.filename')
					);
					break;
			}
			setIsDrawerOpen(false);
			closeModal();
		} catch (e) {
			console.error(e);
		}
	};

	const calendarsSelection = [
		isIOSViewer && { label: APPLE_CALENDAR },
		{ label: GOOGLE_CALENDAR },
		{ label: OUTLOOK_CALENDAR },
		{ label: YAHOO_CALENDAR },
		{ label: ICAL }
	].filter(x => x);

	const onAddToCalendarClick = () => {
		const { description, title, location } = getCalendarPayload();
		if (isNativeApp) {
			sendToUserApp({
				type: 'ADD_EVENT_TO_CALENDAR',
				payload: {
					description,
					title,
					location,
					startTime: appointmentStartTime(appointment, business),
					endTime: appointmentEndTime(appointment, business, t),
					myAccountLink
				}
			});
			return;
		}
		setModal({
			content: (
				<div
					className={classes({
						addToCalendar: true,
						[className]: className,
						hideOnTabletAndDesktop: true
					})}
				>
					<div className={styles.title}>
						{t('myAccount.appointment.addToCalendar.text')}
					</div>

					<div className={styles.calendarLines}>
						{calendarsSelection.map(calendar => (
							<div
								className={classes({ calendarLine: true })}
								key={calendar.label}
								onClick={() => onSelect(calendar)}
							>
								<div className={classes({ calendarTitle: true })}>
									{calendar.label}
								</div>
								<Button iconLeft={'CalendarPlus'} size={'medium'} />
							</div>
						))}
					</div>
				</div>
			),
			isFullHeight: true,
			hasCloseBtn: true
		});
	};

	return (
		<div
			className={classes(
				{
					addToCalendar: true,
					[className]: className
				},
				'react-add-to-calendar__wrapper'
			)}
		>
			<div className={classes({ hideOnMobile: true })}>
				<Button
					onBlur={() => setIsDrawerOpen(false)}
					label={t('myAccount.appointment.addToCalendar.text')}
					onClick={() => setIsDrawerOpen(!isDrawerOpen)}
					type={'linked'}
					iconLeft={'Calendar'}
				/>
				<div>
					<Drawer
						isOpen={isDrawerOpen}
						className={classes({ drawer: true })}
						onSelect={onSelect}
						items={calendarsSelection}
					/>
				</div>
			</div>

			{/* Mobile */}
			<div className={classes({ hideOnTabletAndDesktop: true })}>
				<Button
					label={t('myAccount.appointment.addToCalendar.text')}
					onClick={onAddToCalendarClick}
					type={'linked'}
					iconLeft={'Calendar'}
				/>
			</div>
		</div>
	);
};
