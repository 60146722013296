import { useContext, useRef, useEffect } from 'react';
import credentials from '@planity/credentials';
import { ContextForm } from '../context_form';
import { useActor } from '@xstate/react';
import { useRecaptcha } from '@planity/helpers';
import { SIGN_UP_WITH_EMAIL_AND_PASSWORD } from '../auth_actions';
import { signOut } from '../provider';

export const useFormHooks = props => {
	const formValues = useContext(ContextForm);
	const { machineService, machineState, navigateLogState } = formValues;
	const [state, dispatch] = useActor(machineService);
	const computeNumberOfSocialButtons = enabledProviders =>
		enabledProviders.reduce(
			(sum, enabledProvider) => sum + (enabledProvider ? 1 : 0),
			0
		);
	const { ENABLE_FACEBOOK_LOGIN, ENABLE_GOOGLE_LOGIN, ENABLE_GITHUB_LOGIN } =
		credentials;
	const numberOfSocialButtons = computeNumberOfSocialButtons([
		ENABLE_GOOGLE_LOGIN,
		ENABLE_FACEBOOK_LOGIN,
		ENABLE_GITHUB_LOGIN
	]);

	/**
	 * Trust me
	 * @param numberOfSocialButtons { Number }
	 * @return {number} the spacing between social buttons
	 */
	const spacingBetweenSocialLogin = numberOfSocialButtons =>
		!numberOfSocialButtons || numberOfSocialButtons === 1
			? 0
			: 30 / numberOfSocialButtons;

	const isDesktop = window.matchMedia('(min-width: 800px)').matches;
	const socialLoginWidth = spacingBetweenSocialLogin(numberOfSocialButtons);
	const socialLoginValues = {
		...credentials,
		...numberOfSocialButtons,
		...props,
		dispatch,
		isDesktop,
		socialLoginWidth
	};
	const onTokenResponse = token =>
		dispatch({
			type: SIGN_UP_WITH_EMAIL_AND_PASSWORD,
			token
		});
	const { getRecaptchaToken } = useRecaptcha(onTokenResponse);

	const onSubmitSignUp = () => {
		getRecaptchaToken();
	};

	const phoneInput = useRef(null);

	useEffect(() => {
		if (phoneInput && phoneInput.current && phoneInput.current.focus)
			phoneInput.current.focus();
	}, [machineState]);
	const signOutAndClean = () => {
		dispatch({ type: 'RESET_MACHINE' });
		navigateLogState('login');
		signOut();
	};
	const handleDispatchEvent = ({ event, type }) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 'login') {
			dispatch({ type: 'SIGN_IN_WITH_EMAIL_AND_PASSWORD' });
		} else if (type === 'signup') {
			onSubmitSignUp();
		} else if (type === 'reset') {
			dispatch({ type: 'REQUEST_PASSWORD_RESET_EMAIL' });
		} else if (type === 'finishsignup') {
			dispatch({ type: 'USER_DATA_EDITION_SUBMIT' });
		} else if (type === 'signOutfinishsignup' || type === 'signOut') {
			signOutAndClean();
		} else if (type === 'updatePhoneNumber') {
			dispatch({ type: 'PHONE_NUMBER_SUBMIT' });
		}
	};
	const handleDispatchInputs = payload => {
		dispatch(payload);
	};
	return {
		state,
		dispatch,
		numberOfSocialButtons,
		socialLoginValues,
		phoneInput,
		formValues,
		handleDispatchEvent,
		handleDispatchInputs,
		signOutAndClean
	};
};
