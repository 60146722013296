import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import styles from './title.module.scss';
import { useTranslation, useLocalization } from '@planity/localization';
import { formatCategorySubTitle } from '@planity/helpers';

export const Title = ({ className, search }) => {
	const classes = classNames.bind(styles)({
		title: true,
		[className]: className
	});
	useStyles(styles);

	const { t } = useTranslation();
	const { language, locale } = useLocalization();

	const h1Text = buildH1Text({ search, t, language, locale });

	return (
		<div className={classes}>
			<span className={styles.titleSearch}>
				{t('business.searchPageTitle')}
			</span>
			<h1 className={styles.subtitle}>{h1Text}</h1>
		</div>
	);
};

const buildH1Text = ({ search, t, language, locale }) => {
	const { googlePlace, parentPlace, place, category, parentCategory } = search;
	const categorySearch = category || parentCategory;
	const isDE = locale === 'de-DE';

	const isTitleDefault = shouldBeDefault({ categorySearch, parentPlace });

	if (!isDE && isTitleDefault) {
		return t('business.searchPageSubTitle', {
			country:
				googlePlace?.name ||
				parentPlace?.name ||
				place?.name ||
				t('search.myHome')
		});
	}
	//	Les meilleur(e)s ${name}
	const categoryTitle = formatCategorySubTitle({ categorySearch, t, language });

	//	à|de ${city}
	const placeTitle = t('search.title.place.aroundPlace', {
		placeName:
			googlePlace?.name ||
			parentPlace?.name ||
			place?.name ||
			t('search.myHome')
	});

	//	: réservation en ligne
	const suffix = t('search.title.place.onlineBooking');

	return categoryTitle.concat(' ', placeTitle, suffix);
};

const shouldBeDefault = ({ categorySearch, parentPlace }) => {
	const isHairCare = categorySearch?.objectID === 'hair_care';
	const isDynamicTitle =
		dynamicTitles.includes(categorySearch?.objectID) && !!parentPlace?.objectID;

	return isHairCare || !isDynamicTitle;
};

//https://planity.atlassian.net/browse/PLAN-13309
//specific category + ville
const dynamicTitles = [
	'spa',
	'10om',
	'7enn',
	'o5q5',
	'o5q4',
	't68t',
	'4g0g',
	'c1ro'
];
