import React from 'react';
import { formatCardBrand, formatCardName } from '@planity/helpers';
import { Localize } from '@planity/components';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './cards_list.module.scss';
import { Button, CardLogo, Radio, RemoveCard, useModal } from '@planity/ui';
import { useTranslation } from '@planity/localization';

export function CardsList({
	paymentMethods,
	selectedPaymentMethod,
	defaultPaymentMethod,
	paymentMethodNeededToDelete,
	setSelectedPaymentMethod,
	canDefault,
	makeDefault,
	countryCode, // countryCode from business locale since this component is used only in book appointment page for now
	deleteCard,
	expiredCards = {}
}) {
	const { t } = useTranslation();
	const { setModal, closeModal } = useModal();
	useStyles(styles);
	const classes = classNames.bind(styles)({
		cardsList: true
	});

	return (
		<ul className={classes}>
			{paymentMethods.map(
				method =>
					(!paymentMethodNeededToDelete ||
						(paymentMethodNeededToDelete &&
							method.id !== paymentMethodNeededToDelete.id)) && (
						<li className={styles.cardContainer} key={method.id}>
							<div className={styles.cardInfos}>
								<Radio
									isChecked={selectedPaymentMethod?.id === method.id}
									textClassName={expiredCards[method.id] && styles.disabled}
									onChange={() => {
										if (expiredCards[method.id]) return;
										if (
											selectedPaymentMethod == null ||
											selectedPaymentMethod.id !== method.id
										) {
											setSelectedPaymentMethod(method);
										}
									}}
								/>
								<CardLogo
									brand={formatCardBrand(method.card.brand)}
									className={classNames(styles.card, {
										[styles.disabled]: expiredCards[method.id]
									})}
								/>
								<div className={styles.cardInfosContent}>
									<div className={styles.cardInfosContentRow}>
										<span
											className={classNames(styles.cardNumber, {
												[styles.disabledLabel]: expiredCards[method.id]
											})}
										>{`${formatCardName(method.card.brand)} - ${
											method.card.last4
										}`}</span>
										{defaultPaymentMethod === method.id && (
											<span className={styles.isDefault}>
												<Localize text={'bookAppointment.isDefault'} />
											</span>
										)}
									</div>
									{expiredCards[method.id] ? (
										<div className={styles.cardInfosContentRow}>
											<span className={styles.expiredLabel}>
												<Localize text={'myAccount.cards.cardExpired'} />
											</span>
											<div>
												<Button
													className={styles.choseDefault}
													label={t('bookAppointment.deleteCard')}
													type={'underlined'}
													onClick={() => {
														setModal({
															content: (
																<RemoveCard
																	countryCode={countryCode}
																	deleteCardFromBookAppointment={deleteCard}
																	paymentMethod={method}
																/>
															),
															title: null,
															isFullHeight: false,
															onCloseModal: closeModal,
															order: 1
														});
													}}
												/>
											</div>
										</div>
									) : (
										canDefault && (
											<div className={styles.cardInfosContentRow}>
												<div className={styles.choseDefaultContainer}>
													{defaultPaymentMethod === method.id && (
														<span className={styles.isDefaultMobile}>
															<Localize text={'bookAppointment.isDefault'} />
														</span>
													)}
													{defaultPaymentMethod !== method.id && (
														<Button
															className={styles.choseDefault}
															label={t('bookAppointment.defineByDefault')}
															type={'underlined'}
															onClick={e => {
																e.stopPropagation();
																makeDefault(method, countryCode);
															}}
														/>
													)}
												</div>
											</div>
										)
									)}
								</div>
							</div>
						</li>
					)
			)}
		</ul>
	);
}

CardsList.defaultProps = {
	canDefault: true
};
