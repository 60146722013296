import React from 'react';

export function CurePayment() {
	return (
		<svg
			width='50'
			height='34'
			viewBox='0 0 50 34'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<rect
				x='0.75'
				y='0.75'
				width='48.5'
				height='32.5'
				rx='4.25'
				fill='#F7F7F7'
				stroke='#C7CFD7'
				stroke-width='0.5'
			/>
			<path
				d='M24 19.25H25.5C28.1234 19.25 30.25 17.1234 30.25 14.5C30.25 11.8766 28.1234 9.75 25.5 9.75H22C19.6528 9.75 17.75 11.6528 17.75 14V15.25'
				stroke='#040407'
				stroke-width='2'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M26 14.75H24.5C21.8766 14.75 19.75 16.8766 19.75 19.5C19.75 22.1234 21.8766 24.25 24.5 24.25H28C30.3472 24.25 32.25 22.3472 32.25 20V18.75'
				stroke='#040407'
				stroke-width='2'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	);
}
