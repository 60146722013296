// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".children_limit_warning-module_limitWarning-erPRe{display:flex;width:100%;margin-top:20px;padding:15px;text-align:left;border-radius:12px;color:var(--warning-300);background-color:var(--warning-50);border:1px solid var(--warning-200)}.children_limit_warning-module_limitWarning-erPRe .children_limit_warning-module_warningText-tX\\+xM{display:flex;flex-wrap:wrap;padding-left:12px}.children_limit_warning-module_limitWarning-erPRe .children_limit_warning-module_text1-lBSfU{font:var(--medium) var(--body-4);line-height:18px}.children_limit_warning-module_limitWarning-erPRe .children_limit_warning-module_text2-DkTny{font:var(--regular);line-height:18px}", "",{"version":3,"sources":["webpack://./../../packages/components/children/children_limit_warning/children_limit_warning.module.scss"],"names":[],"mappings":"AAEA,kDACC,YAAA,CACA,UAAA,CACA,eAAA,CACA,YAAA,CACA,eAAA,CACA,kBAAA,CACA,wBAAA,CACA,kCAAA,CACA,mCAAA,CAEA,oGACC,YAAA,CACA,cAAA,CACA,iBAAA,CAGD,6FACC,gCAAA,CACA,gBAAA,CAGD,6FACC,mBAAA,CACA,gBAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.limitWarning {\n\tdisplay: flex;\n\twidth: 100%;\n\tmargin-top: 20px;\n\tpadding: 15px;\n\ttext-align: left;\n\tborder-radius: 12px;\n\tcolor: var(--warning-300);\n\tbackground-color: var(--warning-50);\n\tborder: 1px solid var(--warning-200);\n\n\t.warningText {\n\t\tdisplay: flex;\n\t\tflex-wrap: wrap;\n\t\tpadding-left: 12px;\n\t}\n\n\t.text1 {\n\t\tfont: var(--medium) var(--body-4);\n\t\tline-height: 18px;\n\t}\n\n\t.text2 {\n\t\tfont: var(--regular);\n\t\tline-height: 18px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"limitWarning": "children_limit_warning-module_limitWarning-erPRe",
	"warningText": "children_limit_warning-module_warningText-tX+xM",
	"text1": "children_limit_warning-module_text1-lBSfU",
	"text2": "children_limit_warning-module_text2-DkTny"
};
module.exports = ___CSS_LOADER_EXPORT___;
