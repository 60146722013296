import React from 'react';

export const SG = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5931)'>
				<mask
					id='mask0_11534_5931'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5931)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#F7FCFF'
					/>
					<mask
						id='mask1_11534_5931'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_11534_5931)'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V7.5H20V0H0Z'
							fill='#E31D1C'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M5.54275 6.6185C5.54275 6.6185 3.77669 5.9082 3.77669 3.98378C3.77669 2.05935 5.54275 1.37199 5.54275 1.37199C4.68441 1.1547 2.40685 1.34905 2.40685 3.98378C2.40685 6.6185 4.65 7.11538 5.54275 6.6185ZM5.99523 6.30041L6.50187 5.99455L7.01968 6.30041L6.89295 5.70356L7.31389 5.23272H6.74357L6.50187 4.67541L6.26017 5.23272L5.68889 5.25687L6.11079 5.70356L5.99523 6.30041ZM8.34331 5.9604L7.83667 6.26626L7.95223 5.6694L7.53032 5.22272L8.10161 5.19857L8.34331 4.64126L8.58501 5.19857H9.15532L8.73439 5.6694L8.86111 6.26626L8.34331 5.9604ZM7.05447 3.60619L7.56111 3.30033L8.07891 3.60619L7.95219 3.00934L8.37312 2.5385H7.80281L7.56111 1.98119L7.31941 2.5385L6.74812 2.56265L7.17003 3.00934L7.05447 3.60619ZM5.85611 4.51418L5.34947 4.82004L5.46504 4.22319L5.04313 3.7765L5.61441 3.75235L5.85611 3.19504L6.09782 3.75235H6.66813L6.24719 4.22319L6.37392 4.82004L5.85611 4.51418ZM8.69848 4.79263L9.20512 4.48677L9.72293 4.79263L9.5962 4.19578L10.0171 3.72494H9.44682L9.20512 3.16763L8.96342 3.72494L8.39214 3.74909L8.81404 4.19578L8.69848 4.79263Z'
							fill='#F1F9FF'
						/>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5931'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
