import { Localize, useUserQueries } from '@planity/components';
import React, { useEffect, useState } from 'react';
import { useTranslation, useLocalizedRoutes } from '@planity/localization';
import classNames from 'classnames/bind';
import styles from './home_cover.module.scss';
import { HomeSearchBar, BusinessPreview, Button } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';

// You probably don't want to edit this style. The background positioning is
// very... touchy
export const HomeCover = () => {
	useStyles(styles);
	const { beautyTeam, isLoadingUserQueries } = useUserQueries();
	const { routes } = useLocalizedRoutes();
	const classes = classNames.bind(styles);

	const [showBecomePartnerCTA, setShowBecomePartnerCTA] = useState(false);
	const [resize, setResize] = useState(false);

	useEffect(() => {
		setShowBecomePartnerCTA(!isLoadingUserQueries && !beautyTeam.length);
	}, [beautyTeam, isLoadingUserQueries]);

	const { t } = useTranslation();

	useEffect(() => {
		const handleResize = () => setResize(true);
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	// This useEffect is used to ensure that the CSS is applied when the window is resized.
	// This is necessary to properly adjust the gradient proportions relative to the new viewport height of the window
	useEffect(() => setResize(false), [resize]);

	const hasALotOfUserQueries = !isLoadingUserQueries && beautyTeam?.length > 1;

	return (
		<div className={classes({ container: true })}>
			<div
				className={classes('planity-background', { backgroundContainer: true })}
			/>
			<div
				className={classes({
					'homeCover': true,
					'hasALotOfUserQueries-1': !resize && hasALotOfUserQueries,
					'hasALotOfUserQueries-2': resize && hasALotOfUserQueries
				})}
			>
				<div className={styles.headline}>
					<h1 className={styles.title}>{t('landing.catchLine.title')}</h1>
				</div>
				<div className={styles.slogan}>
					<span>{t('landing.catchLine.subtitle')}</span>
				</div>

				<div
					className={classes({
						searchbar: true,
						hasMargin: beautyTeam.length
					})}
				>
					<HomeSearchBar />
				</div>

				{showBecomePartnerCTA && (
					<div
						className={classes({
							becomePartnerCTAContainer: true
						})}
					>
						<Button
							className={classes({
								mobile: true,
								button: true
							})}
							isExternal
							isFullMobile
							label={t('nav.becomePartner')}
							to={`${routes.becomePartner}?utm_source=planity&utm_campaign=menumobile`}
							type={'primary'}
						/>
					</div>
				)}

				<div className={styles.businessList}>
					<BusinessPreview Title={<BusinessPreviewTitle />} />
				</div>
			</div>
		</div>
	);
};

const BusinessPreviewTitle = () => {
	const { beautyTeam } = useUserQueries();
	return (
		<h2 className={styles.businessPreviewTitle}>
			<Localize
				text={'landing.beautyTeam'}
				args={{ count: beautyTeam.length }}
			/>
		</h2>
	);
};
