import { useLocalization } from '@planity/localization';
import { format, parseISO } from 'date-fns';
import capitalize from 'lodash/capitalize';
import { Localize } from '@planity/components';
import { Icon } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './distant_availabilities.module.scss';

export const DistantAvailabilities = ({ nextAvailability }) => {
	const classes = classNames.bind(styles);
	const { dateLocale } = useLocalization();
	useStyles(styles);

	return (
		<div
			className={classes({
				distantAvailabilities: true,
				container: true
			})}
		>
			<div
				className={classes({
					iconContainer: true,
					nextAvailabilityIconContainer: nextAvailability
				})}
			>
				<Icon className={styles.icon} icon='Calendar' size={'medium'} />
			</div>
			<div className={styles.availabilityContainer}>
				<div className={styles.nextAvailability}>
					<Localize
						className={styles.nextAvailability}
						text={'business.nextAvailability'}
					/>
				</div>
				<div className={styles.availability}>
					{capitalize(
						format(parseISO(nextAvailability), 'PPPP', { locale: dateLocale })
					)}
				</div>
			</div>
		</div>
	);
};
