import React from 'react';
import { breakpoints, colors, fontWeight } from '@planity/theme';

export default function ItemProductsList({
	products,
	productItemComponent: ProductItemComponent
}) {
	return (
		<div css={[styles.container]}>
			<div css={styles.productsList}>
				{products.map(product => (
					<div key={product.id}>
						<ProductItemComponent product={product} />
					</div>
				))}
			</div>
		</div>
	);
}

const styles = {
	container: {
		padding: '20px 16px 0',
		[breakpoints.tabletQuery]: {
			padding: '20px 20px 6px'
		}
	},
	orderIdLabel: {
		fontWeight: fontWeight.bold
	},
	orderContainer: {
		marginBottom: 6,
		lineHeight: 1.29
	},
	productsList: {
		'display': 'flex',
		'flexDirection': 'column',
		'marginTop': -16 * 0.5,
		'marginBottom': -16 * 0.5,

		'& > *': {
			marginTop: 16 * 0.5,
			marginBottom: 16 * 0.5
		},
		[breakpoints.tabletQuery]: {
			'marginTop': -12 * 0.5,
			'marginBottom': -12 * 0.5,

			'& > *': {
				marginTop: 12 * 0.5,
				marginBottom: 12 * 0.5
			}
		}
	},
	border: {
		borderBottom: `1px solid ${colors.grey.border}`
	}
};
