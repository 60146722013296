export function Expand({ outlined }) {
	return (
		<svg
			width='25'
			height='24'
			viewBox='0 0 25 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M5.54999 14.75V17.25C5.54999 18.3546 6.44542 19.25 7.54999 19.25H10.05'
				stroke={!outlined ? 'currentColor' : 'none'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M20.05 14.75V17.25C20.05 18.3546 19.1546 19.25 18.05 19.25H15.55'
				stroke={!outlined ? 'currentColor' : 'none'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M20.05 9.25V6.75C20.05 5.64543 19.1546 4.75 18.05 4.75H15.55'
				stroke={!outlined ? 'currentColor' : 'none'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M5.54999 9.25V6.75C5.54999 5.64543 6.44542 4.75 7.54999 4.75H10.05'
				stroke={!outlined ? 'currentColor' : 'none'}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
