import React from 'react';

export const HK = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5721)'>
				<mask
					id='mask0_11534_5721'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5721)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#EA1A1A'
					/>
					<mask
						id='mask1_11534_5721'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_11534_5721)'>
						<g filter='url(#filter0_d_11534_5721)'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M9.91845 7.23423C9.91845 7.23423 6.2999 4.00719 10.6179 2.12695C10.6179 2.12695 11.8737 3.51234 10.9316 5.06763C10.6226 5.57773 10.3619 5.94199 10.166 6.21573C9.76464 6.77656 9.63519 6.95745 9.91845 7.23423ZM4.57797 4.91719C4.32945 9.62022 8.83379 7.82629 8.83379 7.82629C8.45974 7.95585 8.3558 7.7592 8.03367 7.1497C7.87637 6.85208 7.66704 6.45602 7.34883 5.95144C6.37887 4.41334 4.57797 4.91719 4.57797 4.91719ZM11.3988 7.39191C11.3988 7.39191 16.0438 8.78248 12.9288 12.3148C12.9288 12.3148 11.2042 11.5915 11.3988 9.78375C11.4627 9.19098 11.5445 8.75071 11.606 8.4198L11.606 8.41979L11.606 8.41979C11.732 7.74154 11.7726 7.52271 11.3988 7.39191ZM10.178 8.47606C10.178 8.47606 11.0107 13.2525 6.45855 12.0454C6.45855 12.0454 6.33462 10.1796 8.03867 9.54552C8.59765 9.33749 9.02812 9.21376 9.35162 9.12078C10.0144 8.93028 10.2281 8.86884 10.178 8.47606ZM15.9242 6.42207C13.077 2.6707 10.7961 6.94905 10.7961 6.94905C10.9982 6.60853 11.2045 6.69156 11.8439 6.94889C12.1563 7.07458 12.5719 7.24186 13.1409 7.42167C14.8746 7.9696 15.9242 6.42207 15.9242 6.42207Z'
								fill='white'
							/>
						</g>
						<path
							d='M6.35077 6.38005C6.35077 6.38005 7.26065 7.81202 8.75071 7.81202'
							stroke='#EA1A1A'
							strokeWidth='0.625'
						/>
						<path
							d='M9.91266 4.40639C9.91266 4.40639 9.09611 5.91279 9.79565 7.22843'
							stroke='#EA1A1A'
							strokeWidth='0.625'
						/>
						<path
							d='M13.8187 6.10098C13.8187 6.10098 11.9124 5.81198 10.9153 6.91931'
							stroke='#EA1A1A'
							strokeWidth='0.625'
						/>
						<path
							d='M12.7052 9.59059C12.7052 9.59059 12.3248 7.70035 10.9432 7.14216'
							stroke='#EA1A1A'
							strokeWidth='0.625'
						/>
						<path
							d='M8.70828 10.6167C8.70828 10.6167 10.3544 9.61265 10.4064 8.1235'
							stroke='#EA1A1A'
							strokeWidth='0.625'
						/>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<filter
					id='filter0_d_11534_5721'
					x='3.56805'
					y='1.12695'
					width='13.3561'
					height='12.1878'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset />
					<feGaussianBlur stdDeviation='0.5' />
					<feColorMatrix
						type='matrix'
						values='0 0 0 0 0.6 0 0 0 0 0 0 0 0 0 0 0 0 0 0.36 0'
					/>
					<feBlend
						mode='normal'
						in2='BackgroundImageFix'
						result='effect1_dropShadow_11534_5721'
					/>
					<feBlend
						mode='normal'
						in='SourceGraphic'
						in2='effect1_dropShadow_11534_5721'
						result='shape'
					/>
				</filter>
				<clipPath id='clip0_11534_5721'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
