import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './search_bar.module.scss';
import {
	Icon,
	SearchBarCategory,
	SearchBarPlaces,
	SearchBarDate
} from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useSearch } from '@planity/context';
import { withRouter } from 'react-router-dom';
import { useTranslation } from '@planity/localization';
import { format as formatDate } from 'date-fns';
import { useLocalization } from '@planity/localization';
import { useSearchFilter } from '@planity/ui';

export const SearchBar = withRouter(({ className, handleIsExpanded }) => {
	useStyles(styles);

	const element = useRef();
	const container = useRef();
	const [isExpanded, setIsExpanded] = useState(false);
	const [isRedirected, setIsRedirected] = useState(false); // change it default by false
	const [result, setResult] = useState({ what: '', where: '', when: '' });
	const { submitSearch } = useSearch();
	const classes = classNames.bind(styles)({
		searchBar: true,
		isExpanded,
		[className]: className !== undefined
	});
	const { t } = useTranslation();
	const { dateLocale } = useLocalization();
	const { submitDateChange, localFilters } = useSearchFilter();

	const selectedDate = localFilters.data.date;
	useEffect(() => {
		const handle = e => {
			if (e.keyCode !== 27) return null;
			document.activeElement.blur();
			setIsExpanded(false);
		};
		window.addEventListener('keydown', handle);
		return () => window.removeEventListener('keydown', handle);
	}, []);
	useEffect(() => {
		handleIsExpanded(isExpanded);
	}, [isExpanded, handleIsExpanded]);

	useEffect(() => {
		const handle = () => {
			setIsExpanded(true);
		};

		// Constant used here because of eslint.
		// ESLint: The ref value 'container.current' will likely have changed by
		// the time this effect cleanup function runs. If this ref points to a node
		// rendered by React, copy 'container.current' to a variable inside the
		// effect, and use that variable in the cleanup function.
		// (react-hooks/exhaustive-deps)
		const containerCurrent = container?.current;
		containerCurrent?.addEventListener('focusin', handle);
		return () => containerCurrent?.removeEventListener('focusin', handle);
	}, []);
	const handleSearchBarFilterResult = useCallback(
		(type, value) => {
			setResult(prevState => ({ ...prevState, [type]: value }));
		},
		[setResult]
	);

	const onSubmit = () => {
		setIsExpanded(false);
		if (result && (result?.what || result?.when || result?.where)) {
			if (selectedDate !== result?.when) submitDateChange(result?.when);
			submitSearch('push');
		}
	};
	const getWhenResult = () => {
		return selectedDate
			? formatDate(selectedDate, 'PPP', { locale: dateLocale })
			: t('search.placeholders.when.dateReset');
	};
	const handleSearchBarExpanded = (isExpanded, isRedirected) => {
		setIsExpanded(isExpanded);
		setIsRedirected(isRedirected);
	};

	const setWhereResult = useCallback(
		value => handleSearchBarFilterResult('where', value),
		[handleSearchBarFilterResult]
	);

	return (
		<div ref={element} className={classes}>
			<div
				className={styles.overlay}
				onClick={() => handleSearchBarExpanded(false, false)}
			/>

			<div className={styles.container}>
				<div className={styles.content}>
					<div className={styles.search}>
						<button
							onClick={() => handleSearchBarExpanded(true, true)}
							className={styles.recap}
						>
							<Icon
								className={styles.queryIcon}
								icon='Search'
								size={'medium'}
							/>
							<div className={styles.query}>
								<span className={styles.queryValues}>
									<span>
										{result?.what || t('search.filter.submit')}
										<span className={styles.dotSeparator}>
											{t('punctuation.dotSeparator')}
										</span>
										{result?.where || t(`search.filter.anywhere`)}
									</span>
								</span>
								<span className={styles.date}>{getWhenResult()}</span>
							</div>
							<Icon className={styles.editIcon} icon='Pencil' size={'medium'} />
						</button>

						<button
							className={styles.close}
							onClick={() => setIsExpanded(false)}
						>
							<Icon icon='Close' size={'medium'} />
						</button>
					</div>

					<div className={styles.searchItems} ref={container}>
						<div className={styles.item}>
							<SearchBarCategory
								setWhatResult={value =>
									handleSearchBarFilterResult('what', value)
								}
								isExpanded={isExpanded}
								setIsExpanded={setIsExpanded}
								isRedirected={isRedirected}
							/>
						</div>

						<hr className={styles.separator} />

						<div className={styles.item}>
							<SearchBarPlaces
								setWhereResult={setWhereResult}
								isExpanded={isExpanded}
								isRedirected={isRedirected}
							/>
						</div>

						<hr className={styles.separator} />

						<div className={styles.item}>
							<SearchBarDate
								setWhenResult={value =>
									handleSearchBarFilterResult('when', value)
								}
								isExpanded={isExpanded}
								isRedirected={isRedirected}
							/>
						</div>
						<button className={styles.submit} onClick={onSubmit}>
							<Icon
								className={styles.submit__icon}
								icon='Search'
								size={'medium'}
							/>
							<span className={styles.submit__text}>
								{t('search.filter.submit')}
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
});
