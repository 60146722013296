import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import { Localize } from '@planity/components';
import { Button, Spinner } from '@planity/ui';
import styles from './filter.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';

export const PaginationInfinite = ({
	currentPage,
	pagesCount,
	loadNextPage,
	nbItems,
	maxItemsWithoutAutoScroll,
	isLoading
}) => {
	useStyles(styles);
	const hasNextPage = currentPage < pagesCount;
	const showNextPageButton = hasNextPage && nbItems > maxItemsWithoutAutoScroll;

	const [inViewRef, inView] = useInView({
		skip: showNextPageButton || !hasNextPage
	});

	const loadMore = () => loadNextPage(currentPage + 1);
	// automatically launch new page search if reaching intersection observer
	// if still visible after finishing launching new page, launch again
	useEffect(() => {
		if (inView && !isLoading && hasNextPage && !showNextPageButton) {
			loadMore();
		}
	}, [inView, isLoading, hasNextPage, showNextPageButton]);
	const classes = classNames.bind(styles)({
		filter: true
	});
	return (
		<div className={classes}>
			<div className={styles.paginationInfinite} ref={inViewRef}>
				{isLoading ? (
					<div className={styles.spinner}>
						<Spinner />
					</div>
				) : showNextPageButton ? (
					<Button
						className={styles.button}
						isFullMobile
						label={<Localize text={'search.pagination.displayMore'} />}
						type={'tertiary'}
						onClick={loadMore}
					/>
				) : !hasNextPage ? (
					<div className={styles.endResults}>
						<Localize text={'search.pagination.endOfResults'} />
					</div>
				) : null}
			</div>
		</div>
	);
};
