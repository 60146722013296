import React from 'react';
import Helmet from 'react-helmet';
import { Layout } from '../layout';
import { MyAccountGate } from '@planity/components';

export default function ForgottenPasswordPage() {
	// TODO refactor
	return (
		<Layout crumbName={'forgottenPassword'} isDark skipCoronavirusModal>
			<div>
				<Helmet>
					<meta content={'noindex,nofollow'} name={'robots'} />
				</Helmet>
				<MyAccountGate fromForgottenPasswordPage />
			</div>
		</Layout>
	);
}
