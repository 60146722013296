import React from 'react';
import { Localize } from '@planity/components';

export default function LocalizeHelper({
	text,
	localizedText,
	args,
	children
}) {
	return localizedText ? (
		<Localize args={args} text={localizedText}>
			{children}
		</Localize>
	) : (
		children(text)
	);
}
