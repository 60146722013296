import { useTranslation, useLocalizedRoutes } from '@planity/localization';
import { ErrorHeader } from './error_header';
import React from 'react';
import { Localize } from '@planity/components';
import { Button, Image } from '@planity/ui';
import { useRandomImages } from '@planity/helpers/useRandomImages';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './error_page.module.scss';
import { isNativeApp } from '@planity/webview';
import classNames from 'classnames/bind';

export const ErrorPage = () => {
	const { routes } = useLocalizedRoutes();
	const { t } = useTranslation();
	useStyles(styles);

	const imageUrl = useRandomImages();
	const classes = classNames.bind(styles);
	return (
		<div className={styles.container}>
			<ErrorHeader />
			<div className={styles.columns}>
				<div className={styles.container}>
					<div className={classes({ contentContainer: true, isNativeApp })}>
						<div className={styles.title}>
							<Localize text={'errors.errorHappened'} />
						</div>
						{isNativeApp && (
							<div className={styles.nativeBackButton}>
								<Button
									label={t('error.nav.backHome')}
									size={'mobile'}
									to={routes.home}
									type='primary'
								/>
							</div>
						)}
						{!isNativeApp && (
							<>
								<div className={styles.subTitle}>
									<Localize text={'errors.usefulLinks'} />
								</div>
								<div className={styles.links}>
									<Button
										label={t('nav.home')}
										size={'medium'}
										to={routes.home}
										type={'linked'}
									/>
									<Button
										label={t('nav.myAccount')}
										size={'medium'}
										to={routes.myAccount}
										type={'linked'}
									/>
									<Button
										isExternal
										label={t('nav.becomePartner')}
										size={'medium'}
										to={`${routes.becomePartner}?utm_source=planity&utm_campaign=404`}
										type={'linked'}
									/>
								</div>
							</>
						)}
					</div>
				</div>
				<div className={styles.image}>
					<Image fit='cover' isCloudinary url={imageUrl} />
				</div>
			</div>
		</div>
	);
};
