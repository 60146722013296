import credentials from '@planity/credentials';
import {
	AVAILABLE_LANGUAGES,
	DEFAULT_LANGUAGE,
	useLocalization
} from '@planity/localization';
import { isNativeApp } from '@planity/webview';
import { useEffect } from 'react';
import { useInjectScript } from '@planity/helpers';

export const WebviewScripts = () => {
	if (!isNativeApp || !process.env.BROWSER) return null;
	const { language, countryCode } = useLocalization();
	const isReadyToInjectJavaScript = !!countryCode && !!language;

	console.log(
		`Language is ${language} and we are looking in places in ${countryCode}`
	);

	useEffect(() => {
		document.documentElement.setAttribute(
			'lang',
			AVAILABLE_LANGUAGES.includes(language) ? language : DEFAULT_LANGUAGE
		);
	}, [language]);

	useInjectScript(
		isReadyToInjectJavaScript && {
			src: `https://maps.googleapis.com/maps/api/js?key=${credentials.GOOGLE_API_BROWSER_KEY}&libraries=places&language=${language}-${countryCode}&callback=initMap`,
			location: document.body
		}
	);

	if (process.env.USE_HOTJAR) {
		useInjectScript(
			isReadyToInjectJavaScript && {
				type: 'didomi/javascript',
				datasetVendor: 'c:hotjar',
				innerText: `
				(function(h,o,t,j,a,r){
						h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
						h._hjSettings={hjid:92347,hjsv:6};
						a=o.getElementsByTagName('head')[0];
						r=o.createElement('script');r.async=1;
						r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
						a.appendChild(r);
					})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`.replace(
					/[\n\t\r]/gim,
					''
				)
			}
		);
	}
	if (process.env.PLANITY_ENV === 'production') {
		if (process.env.USE_GOOGLE_ANALYTICS_V4) {
			useInjectScript(
				isReadyToInjectJavaScript && {
					type: 'didomi/javascript',
					datasetVendor: 'c:googleana-4TXnJigR',
					src: `https://www.googletagmanager.com/gtag/js?id=${credentials.GOOGLE_ANALYTICS_V4_TRACKING_CODE}`
				}
			);

			useInjectScript(
				isReadyToInjectJavaScript && {
					type: 'didomi/javascript',
					datasetVendor: 'c:googleana-4TXnJigR',
					innerText: `
						window.dataLayer = window.dataLayer || [];
						function gtag() {
							dataLayer.push(arguments);
						}
						gtag('js', new Date());
						gtag('config', "${credentials.GOOGLE_ANALYTICS_V4_TRACKING_CODE}");
				`.replace(/[\n\t\r]/gim, '')
				}
			);
		}

		if (process.env.USE_GOOGLE_ANALYTICS) {
			useInjectScript({
				type: 'didomi/javascript',
				datasetVendor: 'c:googleana-4TXnJigR',
				func: () => {
					/* eslint-disable */
					window.ga =
						window.ga ||
						function () {
							(ga.q = ga.q || []).push(arguments);
						};
					ga.l = +new Date();
					let GA_LOCAL_STORAGE_KEY = 'ga:clientId';
					if (window.localStorage) {
						ga('create', `${credentials.GOOGLE_ANALYTICS_TRACKING_CODE}`, {
							storage: 'none',
							storeGac: false,
							clientId: localStorage.getItem(GA_LOCAL_STORAGE_KEY)
						});
						ga(function (tracker) {
							localStorage.setItem(
								GA_LOCAL_STORAGE_KEY,
								tracker.get('clientId')
							);
						});
					} else {
						ga('create', `${credentials.GOOGLE_ANALYTICS_TRACKING_CODE}`, {
							storage: 'none',
							storeGac: false
						});
					}
					/* eslint-enable */
				}
			});
			useInjectScript({
				type: 'didomi/javascript',
				src: 'https://www.google-analytics.com/analytics.js',
				datasetVendor: 'c:googleana-4TXnJigR'
			});
		}
	}
	return null;
};
