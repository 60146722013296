import { invokeLambda } from '@planity/helpers';

/**
 * Fetch the products for click & collect.
 * @param {string} businessId business id
 */
export const fetchExposedProductsForClickAndCollect = async businessId => {
	const res = await invokeLambda('exposeProductsForClickAndCollect', {
		businessId,
		isNewVersionWithClickAndShop: true
	});
	return res;
};
