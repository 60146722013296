import React from 'react';

export const IN = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5741)'>
				<mask
					id='mask0_11534_5741'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5741)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#F7FCFF'
					/>
					<mask
						id='mask1_11534_5741'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_11534_5741)'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V5H20V0H0Z'
							fill='#FF8C1A'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 10V15H20V10H0Z'
							fill='#5EAA22'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M7.5 7.5C7.5 8.88071 8.61929 10 10 10C11.3807 10 12.5 8.88071 12.5 7.5C12.5 6.11929 11.3807 5 10 5C8.61929 5 7.5 6.11929 7.5 7.5ZM11.875 7.5C11.875 8.53553 11.0355 9.375 10 9.375C8.96447 9.375 8.125 8.53553 8.125 7.5C8.125 6.46447 8.96447 5.625 10 5.625C11.0355 5.625 11.875 6.46447 11.875 7.5Z'
							fill='#3D58DB'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M9.99656 8.03794L9.47534 9.95903L9.78057 7.99203L8.52304 9.53503L9.60192 7.86224L7.82552 8.76036L9.49151 7.671L7.50339 7.76895L9.46843 7.4514L7.61235 6.73223L9.53667 7.24138L8.13357 5.82946L9.68442 7.07729L8.97691 5.21673L9.88615 6.98747L9.99656 5L10.107 6.98747L11.0162 5.21673L10.3087 7.07729L11.8596 5.82946L10.4565 7.24138L12.3808 6.73223L10.5247 7.4514L12.4897 7.76895L10.5016 7.671L12.1676 8.76036L10.3912 7.86224L11.4701 9.53503L10.2126 7.99203L10.5178 9.95903L9.99656 8.03794Z'
							fill='#3D58DB'
						/>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5741'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
