import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './search_bar_places.module.scss';
import { Drawer } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import {
	GooglePlacesSearch,
	useAppointmentSource,
	APPOINTMENT_SOURCE
} from '@planity/components';
import { SearchBarInput } from '../search_bar_input';
import {
	useLocalization,
	useLocalizedRoutes,
	useTranslation
} from '@planity/localization';
import { getGooglePlace } from '@planity/datastores';
import { useSearch } from '@planity/context';
import { useHistory } from 'react-router-dom';

export const SearchBarPlaces = ({
	className,
	setWhereResult,
	isExpanded,
	isRedirected
}) => {
	const classes = classNames.bind(styles)({
		SearchBarPlaces: true,
		[className]: !!className
	});
	useStyles(styles);

	const {
		category,
		googlePlace,
		place,
		parentPlace,
		setSearch,
		submitSearch,
		userLocation
	} = useSearch();
	const history = useHistory();
	const { routes } = useLocalizedRoutes();
	const { t } = useTranslation();
	const selectedPlace = googlePlace || place || parentPlace || userLocation;
	const [whereQuery, setWhereQuery] = useState('');
	const [whereInputContent, setWhereInputContent] = useState('');
	const [isWhereDrawerOpen, setIsWhereDrawerOpen] = useState(false);
	const { googleSearchableCountries } = useLocalization();
	const { changeSource } = useAppointmentSource();

	useEffect(() => {
		if (!selectedPlace) setWhereResult(t(`search.filter.anywhere`));
		else {
			const placeName = selectedPlace?.name;
			setWhereInputContent(placeName ? placeName : '');
			setWhereResult(placeName ? placeName : t(`search.aroundMe`));
		}
	}, [selectedPlace, setWhereResult, t]);
	const onWhereBlur = event => {
		event.preventDefault();
		setIsWhereDrawerOpen(false);
	};
	const onWhereFocus = event => {
		event.preventDefault();
		setWhereQuery(whereInputContent);
		setIsWhereDrawerOpen(true);
	};
	const onWhereSelect = prediction => {
		if (prediction) {
			getGooglePlace({
				placeId: prediction.place_id,
				prediction,
				countries: googleSearchableCountries
			}).then(place => {
				changeSource(APPOINTMENT_SOURCE.SEARCH.PLACE);
				setSearch(
					{
						googlePlace: place,
						place: null,
						parentPlace: null,
						category: category || null,
						userLocation: null
					},
					null,
					isRedirected
				);
				setWhereInputContent(place?.name || '');
				setWhereResult(place?.name || '');
			});
			isRedirected && submitSearch('push');
		}
	};
	const onWhereChange = where => {
		setWhereInputContent(where || '');
		setWhereQuery(where || '');
	};

	return (
		<div className={classes}>
			<SearchBarInput
				className={`${styles.whereInput} ${styles.mobile}`}
				hasAClearInput
				value={whereInputContent}
				onChange={onWhereChange}
				onBlur={onWhereBlur}
				onFocus={onWhereFocus}
				onClick={() => isRedirected && history.push(routes.searchPlace)}
				label={t('search.placeholders.placeShort')}
				placeholder={t('search.placeholders.place')}
				icon={'Pin'}
				type={'where'}
				isExpanded={isExpanded}
			/>
			{!isRedirected && (
				<GooglePlacesSearch query={whereQuery} format={formatWhere}>
					{({ places }) => (
						<Drawer
							header={{
								label: whereQuery?.length
									? t('common.results')
									: t('category.frequentSearches')
							}}
							onSelect={onWhereSelect}
							className={styles.whereDrawer}
							items={places || []}
							id={'Algolia'}
							isOpen={isWhereDrawerOpen}
							hasArrowKeysNavigation
						/>
					)}
				</GooglePlacesSearch>
			)}
		</div>
	);
};

function formatWhere(places) {
	return places?.map(formatPlace);
}

function formatPlace(place) {
	if (!place) return null;
	const { structured_formatting } = place;
	const { main_text, secondary_text, main_text_matched_substrings } =
		structured_formatting;

	// Bold the matched text
	const main_text_formatted = main_text.split('').reduce((str, char, i) => {
		const needToOpenBracket = main_text_matched_substrings.find(
			({ offset }) => offset === i
		);
		const needToCloseBracket = main_text_matched_substrings.find(
			({ offset, length }) => offset + length === i
		);
		if (needToOpenBracket) {
			return str + '<b>' + char;
		}
		if (needToCloseBracket) return str + '</b>' + char;
		return str + char;
	}, '');
	return {
		...place,
		dangerousLabel: main_text_formatted,
		description: secondary_text
	};
}
