import React, { useEffect, useRef, useState } from 'react';
import { Localize } from '@planity/components';
import {
	formatDuration,
	formatPhoneNumber,
	formatPrices,
	noop,
	sanitizeName,
	scrollTo
} from '@planity/helpers';
import { useTranslation } from '@planity/localization';
import { BrandContent } from './brand_content';
import credentials from '@planity/credentials';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './service.module.scss';
import { Button, Icon } from '@planity/ui';
import debounce from 'lodash/debounce';
import ResizeObserver from 'resize-observer-polyfill';

const DESCRIPTION_LINE_HEIGHT = 24; // Equals to body-4 font-size

export function Service({
	className,
	service,
	business,
	serviceId,
	dispatch,
	redirectTo,
	isGiftVoucher,
	isHighlightedServices = false,
	setIndex,
	index,
	alternateColors,
	isSearch
}) {
	const { t } = useTranslation();
	useStyles(styles);
	const descriptionRef = useRef();
	const [isCollapsed, setIsCollapsed] = useState(true);
	const [brandContentIsExpanded, setBrandContentIsExpanded] = useState(false);
	const [hasMoreThanOneLine, setHasMoreThanOneLine] = useState(
		descriptionRef?.current?.scrollHeight > DESCRIPTION_LINE_HEIGHT
	);
	const [phoneNumber, setPhoneNumber] = useState('');

	const hasDetails = service.description !== null;

	const isWhiteLabel = process.env.WIDGET;

	const displayBrandContent =
		!isWhiteLabel && credentials.ENABLE_ENRICHMENT && !!service.brandContent;

	const duration = formatDuration(service.duration, t);
	const price = formatPrice(service.prices, null);
	const isBookable = !!service.bookable || isGiftVoucher;
	const { hideServiceDurations, hideServicePrices } = business;

	const hasPrice = !!price && !hideServicePrices;
	const hasDuration = !!duration && !hideServiceDurations;

	useEffect(() => {
		if (!descriptionRef.current) return;
		const resizeObserver = new ResizeObserver(
			debounce(
				([{ target }]) => {
					setHasMoreThanOneLine(target?.scrollHeight > DESCRIPTION_LINE_HEIGHT);
				},
				500,
				{ leading: true }
			)
		);
		resizeObserver.observe(descriptionRef.current);
		return () => resizeObserver.disconnect(); // clean up
	}, []);

	useEffect(() => {
		// Display phoneNumber dynamically for SEO purpose
		setPhoneNumber(formatPhoneNumber(business.phoneNumber));
	}, [business.phoneNumber]);

	const cx = classNames.bind(styles);
	const classes = cx({
		businessService: true,
		[className]: className !== undefined,
		isCollapsed,
		hasBrandContent: displayBrandContent,
		isHighlightedServices,
		alternateColors
	});

	const scrollTop = () => {
		const appointmentTop = document.getElementById('bookAppointment');
		scrollTo({
			node: appointmentTop,
			behavior: 'instant'
		});
	};

	const dispatchAction = () => {
		dispatch(service);
		isWhiteLabel && scrollTop();
	};

	const handleOnClick = () => {
		if (isBookable) {
			if (!redirectTo) {
				return dispatchAction();
			}
			return redirectTo(serviceId);
		}
		return noop;
	};

	const renderDetails = (
		<div
			className={cx({
				accordion: true,
				isCollapsed
			})}
			onClick={() => setIsCollapsed(!isCollapsed)}
		>
			{service?.description?.trim() && !displayBrandContent && (
				<div className={styles.details} ref={descriptionRef}>
					{service?.description?.trim()}
				</div>
			)}
			{displayBrandContent && !brandContentIsExpanded && (
				<button
					className={styles.brandContentButton}
					onClick={e => {
						e.stopPropagation();
						setBrandContentIsExpanded(true);
						displayServicesEnrichment();
					}}
				>
					<Icon icon='PlayRound' size={'medium'} />
					<Localize text={'bookAppointment.showMoreDetailsDescription'} />
					<Icon icon='ChevronDown' size={'medium'} />
				</button>
			)}
			{!isHighlightedServices &&
				(hasDetails || !isBookable) &&
				!displayBrandContent &&
				hasMoreThanOneLine && (
					<button
						className={styles.toggle}
						onClick={e => {
							setIsCollapsed(!isCollapsed);
							e.stopPropagation();
						}}
					>
						{isCollapsed
							? t('bookAppointment.showMoreDetailsDescription')
							: t('bookAppointment.showLessDetailsDescription')}
						<Icon className={styles.icon} icon='ChevronUp' size={'medium'} />
					</button>
				)}
		</div>
	);

	const schemaProps = isSearch
		? {}
		: {
				itemProp: 'itemListElement',
				itemScope: true,
				itemType: 'https://schema.org/Offer'
		  };

	const nestedSchemaProps = isSearch
		? {}
		: {
				itemProp: 'itemOffered',
				itemScope: true,
				itemType: 'https://schema.org/Service'
		  };
	const nameSchemaProp = isSearch
		? {}
		: {
				itemProp: 'name'
		  };
	return (
		<div
			className={`${classes} planity_ui_item-list-element`}
			css={{ cursor: !isBookable ? 'default' : 'pointer' }}
			onClick={handleOnClick}
			{...schemaProps}
		>
			<div className={styles.card} {...nestedSchemaProps}>
				<div className={styles.summary}>
					<div>
						<span
							className={`${styles.name} planity_appointment_service_cell`}
							id={`service-name-${setIndex}-${index}`}
							{...nameSchemaProp}
						>
							{sanitizeName(service.name)}
						</span>
						{service.parentCategory && (
							<span className={styles.parentCategory}>
								{service.parentCategory}
							</span>
						)}
					</div>
					{(service.description || displayBrandContent) &&
						!isHighlightedServices &&
						renderDetails}
					{!isBookable && (
						<div className={styles.notBookable}>
							{service.webDisplaySentence || (
								<Localize
									args={{
										phoneNumber
									}}
									text={'bookAppointment.unbookableSentence.'.concat(
										phoneNumber ? 'withPhone' : 'withoutPhone'
									)}
								/>
							)}
						</div>
					)}
				</div>
				<div className={styles.info}>
					<span
						className={cx(
							'planity-secondary-color',
							'planity_appointment_service_small-cell',
							{
								duration: true,
								isHidden: !hasDuration
							}
						)}
						id={`service-${setIndex}-${index}-duration`}
					>
						{duration}
					</span>
					<div className={cx({ separator: hasPrice && hasDuration })} />
					<div
						className={cx(
							'planity-secondary-color',
							'planity_appointment_service_small-cell',
							{
								price: true,
								isHidden: !hasPrice
							}
						)}
						id={`service-${setIndex}-${index}-price`}
					>
						{price}
					</div>
				</div>

				{process.env.WIDGET ? (
					<Button
						className={cx(
							{ button: true },
							'button',
							'planity_bookappointment-button-choose',
							'planity-secondary-color',
							'planity-secondary-border'
						)}
						data-hide={!isBookable}
						id={`button-choose-${setIndex}-${index}`}
						label={t('bookAppointment.chooseService')}
						size='small'
						type={'primary'}
					/>
				) : (
					<>
						<Button
							className={cx({
								button: true,
								isHighlightedServices,
								hideOnDesktop: true
							})}
							data-hide={!isBookable}
							id={`button-choose-${setIndex}-${index}`}
							label={t('bookAppointment.chooseService')}
							size='small'
							type={'primary'}
						/>
						<Button
							className={cx({
								button: true,
								isHighlightedServices,
								hideOnMobileOrTablet: true
							})}
							data-hide={!isBookable}
							id={`button-choose-${setIndex}-${index}`}
							label={t('bookAppointment.chooseService')}
							size='small'
							type={isHighlightedServices ? 'secondary' : 'primary'}
						/>
					</>
				)}
				{displayBrandContent && brandContentIsExpanded && (
					<BrandContent
						brandContentId={service.brandContent}
						brandContentIsExpanded={brandContentIsExpanded}
						business={business}
						setBrandContentIsExpanded={setBrandContentIsExpanded}
					/>
				)}
			</div>
		</div>
	);
}

function formatPrice(...args) {
	const prices = formatPrices(...args);
	if (prices) {
		if (!prices.find(x => !!x.price || !!x.text)) return null;
		return prices.map((price, i) => {
			return (
				<span className={styles.priceSpace} key={i}>
					{price.text ? <Localize text={price.text} /> : price.price}
				</span>
			);
		});
	} else {
		return null;
	}
}

function displayServicesEnrichment() {
	if (window.ga) {
		window.ga('send', {
			hitType: 'event',
			eventCategory: 'BrandContent',
			eventAction: 'services.brand.content.open'
		});
	}
}
