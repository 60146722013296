import { firebase, useFirebaseSubscription } from '@planity/datastores';
import { DEFAULT_LANGUAGE, useLocalization } from '@planity/localization';
import { isNativeApp, sendToUserApp } from '@planity/webview';
import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';

const AuthContext = createContext({});
export const useAuth = () => useContext(AuthContext);

const DEFAULT_STATE_CUSTOM_TOKEN_HAS_BEEN_USED = process.env.WEBVIEW
	? !window?.userAppUser?.token
	: true;

export function AuthContextProvider({ skipIsAdmin, requireIsDev, children }) {
	const { language } = useLocalization();
	const [customTokenHasBeenUsed, setCustomTokenHasBeenUsed] = useState(
		DEFAULT_STATE_CUSTOM_TOKEN_HAS_BEEN_USED
	);
	firebase.auth().languageCode = language || DEFAULT_LANGUAGE;
	const userId = useMonitorAuth();

	const [user] = useFirebaseSubscription({ path: userId && `users/${userId}` });

	const [pro] = useFirebaseSubscription({ path: userId && `pros/${userId}` });
	const [isAdmin] = useFirebaseSubscription({
		path: userId && !skipIsAdmin && `admins/${userId}` // C'est à true alors que j'ai pas de userId
	});
	const phone = !!user && user.phone;
	const isAllowedToFetchVerifiedUsers = userId && !!phone;
	const [isVerified] = useFirebaseSubscription({
		path: isAllowedToFetchVerifiedUsers && `verified_users/${userId}/${phone}`
	});
	const [isDev] = useFirebaseSubscription({
		path: !!(userId && requireIsDev) && `dev_console_statuses/${userId}`
	});
	const isLoading = userIsLoading({
		user,
		pro,
		isVerified,
		userId,
		customTokenHasBeenUsed,
		isAllowedToFetchVerifiedUsers
	});
	useEffect(() => {
		if (requireIsDev && !isDev && isDev !== undefined) signOut().then();
	}, [isDev, requireIsDev]);

	useEffect(() => {
		try {
			if (!isNativeApp) return;
			if (customTokenHasBeenUsed) return;

			const { userAppUser } = window;

			if (!userAppUser?.token) {
				sendToUserApp({ type: 'AUTH_PROCESS_ENDED', payload: null });
				return;
			}

			signInWithCustomToken(userAppUser.token).then(() => {
				setCustomTokenHasBeenUsed(true);
				sendToUserApp({ type: 'AUTH_PROCESS_ENDED', payload: null });
				sendToUserApp({
					type: 'SIGN_OUT_USER',
					payload: null
				});
			});
		} catch (e) {
			setCustomTokenHasBeenUsed(true);
			console.error(e);
			sendToUserApp({ type: 'AUTH_PROCESS_ENDED', payload: null });
		}
	}, [customTokenHasBeenUsed]);

	const state = useMemo(
		() =>
			authState({
				userId,
				user,
				pro,
				isVerified,
				isAdmin,
				isDev,
				isLoading,
				skipIsAdmin,
				requireIsDev
			}),
		[
			userId,
			user,
			pro,
			isVerified,
			isAdmin,
			isDev,
			isLoading,
			skipIsAdmin,
			requireIsDev
		]
	);
	return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
}

const userIsLoading = ({
	user,
	pro,
	isVerified,
	userId,
	customTokenHasBeenUsed,
	isAllowedToFetchVerifiedUsers
}) => {
	if (!customTokenHasBeenUsed) return true;
	if (userId === undefined) return true;
	if (userId === null) return false;

	// User is a pro
	if (user === null) return pro === undefined;

	// User is a user (lol)
	return (
		user === undefined ||
		(isAllowedToFetchVerifiedUsers && isVerified === undefined)
	);
};

function useMonitorAuth() {
	const [uid, setUid] = useState();
	useEffect(
		() =>
			firebase.auth().onAuthStateChanged(data => {
				const { uid } = data || {};
				setUid(uid || null);
			}),
		[]
	);
	return uid;
}

function authState({
	skipIsAdmin,
	userId,
	user,
	pro,
	isVerified,
	isAdmin,
	isDev,
	isLoading
}) {
	const isPro = user !== undefined && pro !== undefined ? !!pro : undefined;
	return {
		userId,
		isSignedIn: isSignedIn(userId, user || pro, isPro),
		user: user || pro,
		isPro,
		isAdmin: skipIsAdmin || isAdmin,
		isVerified: isPro || isVerified,
		isDev,
		isLoading
	};
}

/**
 * Checks if a user is logged and if he has every data we expect
 * @param userId { String } the user id from Firebase
 * @param user { Object } can either be a pro or a common user
 * @param isPro { Boolean } if the user is a pro ot not
 * @return {boolean}
 */
const isSignedIn = (userId, user, isPro) => {
	// This blurps
	if (userId === undefined) {
		return undefined;
	}

	if (!user || !userId) {
		return false;
	}

	const { firstName, lastName, phone, email } = user;
	if (isPro) {
		return true;
	} else {
		return !!firstName && !!lastName && !!phone && !!email;
	}
};

export const WithAuth = AuthContext.Consumer;

export const withAuth = WrappedComponent => props =>
	(
		<WithAuth>
			{authProps => <WrappedComponent {...authProps} {...props} />}
		</WithAuth>
	);

export function signOut() {
	if (isNativeApp) {
		window.userAppUser = null;
		sendToUserApp({ type: 'SIGN_OUT_USER' });
	}
	sessionStorage.clear();

	return firebase
		.auth()
		.signOut()
		.then(() => {
			window.userAppUser = null;
		})
		.catch(err => console.error('signOut :', err));
}

export function getUserToken() {
	const currentUser = firebase.auth().currentUser;
	if (!currentUser) {
		return Promise.resolve(null);
	}
	return currentUser.getIdToken().catch(e => {
		console.warn(e);
		return null;
	});
}

export function getUserId() {
	const currentUser = firebase.auth().currentUser;
	if (!currentUser) {
		return null;
	}
	return currentUser.uid;
}

export function getUserClaims() {
	const currentUser = firebase.auth().currentUser;
	if (!currentUser) {
		return Promise.resolve({});
	}
	return currentUser
		.getIdTokenResult()
		.then(({ claims }) => claims || {})
		.catch(e => {
			console.warn(e);
			return {};
		});
}

export async function signInWithCustomToken(token) {
	return await firebase.auth().signInWithCustomToken(token);
}
