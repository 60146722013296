import React, { useEffect } from 'react';
import chunk from 'lodash/chunk';
import memoize from 'fast-memoize';
import { format, isSameDay } from 'date-fns';
import { noop, safeRead } from '@planity/helpers';
import { Page } from './page';
import NextAvailabilityOverlay from './next_availability_overlay';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from '../../step.module.scss';
import {
	sendTimeslotDoesNotMatchSearch,
	sendTimeslotMatchesSearch
} from '@planity/helpers/analytics';
import { Icon } from '@planity/ui';

export function AppointmentAvailabilitiesSlider({
	days,
	currentDay,
	pageSize,
	availabilities,
	chooseDate,
	navToDay,
	today,
	searchDate,
	setSearchDate
}) {
	useStyles(styles);
	// const { t } = useTranslation();
	// const [isLimited, setIsLimited] = useState(true);
	const pages = getPages(days, pageSize);

	// set current page & day index different if search date is set
	let currentPageIndex = Math.max(
		0,
		searchDate
			? pages.findIndex(page => page.find(day => isSameDay(day, searchDate)))
			: pages.findIndex(page => page.find(day => isSameDay(day, currentDay)))
	);

	const firstDayAtPage = pages[currentPageIndex][0]; // retrieve first day of the page with search date

	const currentDayIndex = searchDate
		? days.findIndex(d => isSameDay(d, firstDayAtPage))
		: days.findIndex(d => isSameDay(d, currentDay));

	const hasPastPage = currentPageIndex !== 0;
	const hasFuturePage = currentPageIndex !== pages.length - 1;

	useEffect(() => {
		if (searchDate) {
			const hasAvailabilities = !!safeRead(availabilities, [
				format(searchDate, 'yyyy-MM-dd'),
				'length'
			]);
			hasAvailabilities
				? sendTimeslotMatchesSearch()
				: sendTimeslotDoesNotMatchSearch();
		}
	}, [searchDate]);

	return (
		<div className={`${styles.hidden} planity_appointment_steps_step`}>
			<div
				className={styles.top}
				css={{
					width: `calc(${pages.length * 100}%)`,
					transform: `translateX(${(currentPageIndex / pages.length) * -100}%)`,
					transition: 'transform ease .5s'
				}}
			>
				<div className={styles.pages}>
					{pages.map(page => (
						<Page
							availabilities={availabilities}
							chooseDate={chooseDate}
							currentPageIndex={currentPageIndex}
							days={page}
							hasFuturePage={hasFuturePage}
							key={page[0]}
							pages={pages}
							pageSize={pageSize}
							today={today}
						/>
					))}
				</div>
			</div>
			<div>
				<button
					className={`${styles.previous} ${
						!hasPastPage ? styles.disabled : ''
					}`}
					onClick={
						hasPastPage
							? () => {
									navToDay(days[Math.max(0, currentDayIndex - pageSize)]);
									setSearchDate(null); // set search date to null to go to the previous page
							  }
							: noop
					}
				>
					<Icon icon='ChevronLeft' size={'40'} />
				</button>
				<button
					className={`${styles.next} ${!hasFuturePage ? styles.disabled : ''}`}
					onClick={
						hasFuturePage
							? () => {
									navToDay(
										days[Math.min(days.length - 1, currentDayIndex + pageSize)]
									);
									setSearchDate(null); // set search date to null to go to the next page
							  }
							: noop
					}
				>
					<Icon icon='ChevronRight' size={'40'} />
				</button>
			</div>
			<NextAvailabilityOverlay
				availabilities={availabilities}
				currentDayIndex={currentDayIndex}
				currentDays={pages[currentPageIndex]}
				days={days}
				navToDay={navToDay}
				pageSize={pageSize}
			/>
		</div>
	);
}

const getPages = memoize((days, pageSize) => chunk(days, pageSize));
