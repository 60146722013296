import React from 'react';
import Helmet from 'react-helmet';
import { Localize } from '@planity/components';
import { useLocalization, AVAILABLE_LOCALES } from '@planity/localization';
import credentials from '@planity/credentials';

export function Metadata() {
	const { locale } = useLocalization();
	const oppositeLocales = AVAILABLE_LOCALES.filter(
		oppositeLocale => oppositeLocale !== locale
	);

	return (
		<Localize text={'landing.meta'}>
			{({ title, description }) => (
				<Helmet>
					<title>{title}</title>
					<meta content={description} name={'description'} />
					<meta content={description} property={'og:description'} />
					{oppositeLocales.map(locale => {
						<link
							href={`https://www.${credentials.HOST}/${locale}`}
							hrefLang={locale}
							rel={'alternate'}
						/>;
					})}
				</Helmet>
			)}
		</Localize>
	);
}
