import { PAYMENT_TYPE_CARD } from '@planity/helpers';
import credentials from '@planity/credentials';

/**
 *
 * @param selectedCard
 * @param consentedPaymentMethods the list managed cards saved in dynamo saved-payment-methods-${env}
 * @returns {boolean} true represent that we need to handle consent for this card
 */
export const doesPaymentMethodNeedConsent = (
	selectedCard,
	consentedPaymentMethods
) => {
	if (selectedCard.type !== PAYMENT_TYPE_CARD) return false;

	// don't manage the cards added after the save card is controllable
	if (selectedCard.created > credentials.SAVE_CARD_CONTROL_ACTIVATION_DATE)
		return false;

	return !consentedPaymentMethods.some(saved => saved.id === selectedCard.id);
};
