import React, { Component } from 'react';
import { setUserPhoneInDatabase } from '@planity/components';
import { invokeLambda } from '@planity/helpers';
import { BACK_TO_IDLE, RESET_MACHINE, withAuth } from '../authentication';
import { signOut } from '@planity/components';
import { DEFAULT_LOCALE, withLocalization } from '@planity/localization';
import { Modal } from '@planity/ui';
import { PhoneVerificationConfirm } from './phone_verification_confirm';
import { PhoneVerificationUpdate } from './phone_verification_update';
import { ErrorMessage } from '../error_message';
import styles from './phone_verification_form.module.scss';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames/bind';
import { sendPhoneNumberVerificationCodeRecaptchaProtected } from '@planity/helpers/recaptcha';
import { breakpoints } from '@planity/theme';

const MISSING_CODE_ERROR = 'MISSING_ACTUAL_CODE_ERROR';
const INVALID_CODE_ERROR = 'INVALID_CODE_ERROR';

const NETWORK_ERROR = 'NETWORK_ERROR';
const MISSING_PHONE_NUMBER_ERROR = 'MISSING_PHONE_NUMBER_ERROR';

const WHITELISTED_ERRORS = [
	MISSING_CODE_ERROR,
	INVALID_CODE_ERROR,
	NETWORK_ERROR,
	MISSING_PHONE_NUMBER_ERROR
].map(e => `phoneVerificationForm.errors.${e}`);

export const PhoneVerificationForm = withStyles(styles)(
	withLocalization(
		withAuth(
			class extends Component {
				state = {
					code: '',
					isPending: false,
					error: null,
					sendingSMS: false,
					smsWasSent: false,
					displayEditPhoneForm: false,
					newPhoneNumber: ''
				};

				componentDidMount() {
					if (this.props.sendSMSOnMount) {
						this.sendSMS(true);
					}
				}

				componentDidUpdate(props, previousState) {
					if (previousState.error !== this.state.error) {
						this.setState({
							error: null
						});
					}
				}

				closeModal = () => {
					if (this.state.displayEditPhoneForm)
						this.setState({ displayEditPhoneForm: false });
					else return this.returnToPreviousStep();
				};

				render() {
					const { isVerified } = this.props;
					const { displayEditPhoneForm, error } = this.state;
					const classes = classNames.bind(styles)({
						phoneVerification: true
					});
					return (
						<Modal
							closeModal={this.closeModal}
							hasCloseBtn={breakpoints.isMobile()}
							isFullHeight
							isOpen
						>
							<div className={classes}>
								<div
									className={`${styles.overlay} ${styles.modal}`}
									id='phone-verif-modal'
								>
									<ErrorMessage
										defaultMessage={'phoneVerificationForm.errors.defaultError'}
										error={error && `phoneVerificationForm.errors.${error}`}
										whitelist={WHITELISTED_ERRORS}
									/>
									{displayEditPhoneForm && !isVerified ? (
										<PhoneVerificationUpdate
											currentState={this.state}
											handleClickEvents={this.handleClickEvents}
											handleInputChange={this.handleInputChange}
										/>
									) : (
										<PhoneVerificationConfirm
											currentState={this.state}
											handleClickEvents={this.handleClickEvents}
											handleInputChange={this.handleInputChange}
										/>
									)}
								</div>
							</div>
						</Modal>
					);
				}

				updateUserPhoneNumber = () => {
					if (
						this.state?.newPhoneNumber &&
						this.state?.newPhoneNumber !== this.props.phoneNumber
					) {
						this.handlePhoneChange(this.state?.newPhoneNumber);
						this.sendSMS(true);
					}
					this.setState({ displayEditPhoneForm: false });
				};

				setCode = code => {
					const regex = /^[0-9]*$/;
					const codeLength =
						(this.props.phoneNumber || '').substring(0, 3) === '+33' ? 3 : 6;

					if (code === '' || code.match(regex)) {
						this.setState(
							{
								code,
								error: null
							},
							() => {
								if (code && code.length === codeLength) {
									return this.onSubmit();
								}
							}
						);
					}
				};
				handleInputChange = ({ type, value }) => {
					switch (type) {
						case 'setPhoneNumber':
							return this.setPhoneNumber(value);
						case 'setCode':
							return this.setCode(value);
						default:
							return console.error(
								'Unhandled input change type. Received :',
								type
							);
					}
				};
				handleClickEvents = ({ type, event }) => {
					if (event) {
						event.preventDefault();
						event.stopPropagation();
					}

					switch (type) {
						case 'clickHere':
							return this.setState({
								displayEditPhoneForm: !this.state.displayEditPhoneForm
							});
						case 'sendSMS':
							return this.sendSMS();
						case 'signOutAndClean':
							return this.signOutAndClean();
						case 'onSubmit':
							return this.onSubmit(event);
						case 'updateUserPhoneNumber':
							return this.updateUserPhoneNumber();
						default:
							return console.error(
								'Unhandled click event type. Received :',
								type
							);
					}
				};

				setPhoneNumber = phoneNumberInput => {
					phoneNumberInput.length
						? this.setState({ newPhoneNumber: phoneNumberInput })
						: this.setState({ newPhoneNumber: '' });
				};

				onSubmit = e => {
					if (e && e.preventDefault) e.preventDefault();
					const { code, isPending } = this.state;
					const { userId, onSuccess } = this.props;
					const phoneNumber = this.props.phoneNumber || this.props.user.phone;
					if (!code)
						return this.setState({
							error: MISSING_CODE_ERROR,
							isPending: false
						});

					if (isPending) return;

					return this.setState({ isPending: true }, () =>
						invokeLambda('verifyPhoneNumberCode', {
							uid: userId,
							phoneNumber,
							code
						})
							.then(response => {
								if (response && response.errorMessage) {
									return this.setState({
										code: '',
										error: response.errorMessage,
										isPending: false
									});
								}
								this.setState({
									isPending: false,
									error: null
								});
								if (onSuccess) onSuccess();
							})
							.catch(error => {
								console.error(error);
								this.setState({
									code: '',
									isPending: false,
									error: error.code || error
								});
							})
					);
				};
				handlePhoneChange = newPhoneNumber => {
					const { userId, isPro } = this.props;
					return setUserPhoneInDatabase({
						userId,
						phoneNumber: newPhoneNumber,
						isPro
					});
				};
				sendSMS = inBackground => {
					const phoneNumber =
						this.state.newPhoneNumber ||
						this.props.phoneNumber ||
						this.props.user.phone;
					!this.props.isPro &&
						this.setState({ sendingSMS: !inBackground }, () => {
							sendPhoneNumberVerificationCodeRecaptchaProtected({
								phoneNumber,
								locale: this.props.locale ?? DEFAULT_LOCALE
							});
							//we cancel loader 1,5sec after call to step function instead of listening to it's response, which is complicated
							setTimeout(() => {
								if (!inBackground) {
									this.setState({ sendingSMS: false, smsWasSent: true });
								}
							}, 1500);
						});
				};

				signOutAndClean = async () => {
					const { dispatch, navigateLogState } = this.props;
					if (dispatch) dispatch({ type: RESET_MACHINE });

					if (navigateLogState) navigateLogState('signup');
					await signOut();
				};

				returnToPreviousStep = async () => {
					const { dispatch } = this.props;
					if (dispatch) {
						dispatch({
							type: BACK_TO_IDLE,
							fromSMSVerificationModalClosing: true
						});
					}
					await signOut();
				};
			}
		)
	)
);
