import React from 'react';

export const TZ = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5993)'>
				<mask
					id='mask0_11534_5993'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5993)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#3195F9'
					/>
					<mask
						id='mask1_11534_5993'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_11534_5993)'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15L20 0H0Z'
							fill='#73BE4A'
						/>
						<path
							d='M-1.13854 15.8995L-0.70481 16.5493L-0.0550152 16.1156L22.6081 0.988416L23.2579 0.554691L22.8241 -0.0951043L21.4362 -2.17445L21.0025 -2.82424L20.3527 -2.39052L-2.31039 12.7367L-2.96018 13.1704L-2.52646 13.8202L-1.13854 15.8995Z'
							fill='#272727'
							stroke='#FFD018'
							strokeWidth='1.5625'
						/>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5993'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
