// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".search_header-module_header-D6u7s{background-color:var(--white);display:flex;align-items:center;justify-content:center;min-height:56px;height:56px;border-bottom:1px solid var(--grey-200);font:var(--medium) var(--body-2);position:relative}.search_header-module_header-D6u7s .search_header-module_icon-Ixp9n{position:absolute;left:15px;top:50%;transform:translateY(-50%);height:24px}", "",{"version":3,"sources":["webpack://./app/components/ui/search_header/search_header.module.scss"],"names":[],"mappings":"AAAA,mCACC,6BAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,WAAA,CACA,uCAAA,CACA,gCAAA,CACA,iBAAA,CAEA,oEACC,iBAAA,CACA,SAAA,CACA,OAAA,CACA,0BAAA,CACA,WAAA","sourcesContent":[".header {\n\tbackground-color: var(--white);\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tmin-height: 56px;\n\theight: 56px;\n\tborder-bottom: 1px solid var(--grey-200);\n\tfont: var(--medium) var(--body-2);\n\tposition: relative;\n\n\t.icon {\n\t\tposition: absolute;\n\t\tleft: 15px;\n\t\ttop: 50%;\n\t\ttransform: translateY(-50%);\n\t\theight: 24px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "search_header-module_header-D6u7s",
	"icon": "search_header-module_icon-Ixp9n"
};
module.exports = ___CSS_LOADER_EXPORT___;
