const PLACE_IDS_OVERRIDES = Object.freeze({
	// Paris 12ème -> overriden by "The People Bercy" coordinates
	ChIJR9fXt4xy5kcRABuUaMOCCwU: {
		location: {
			lat: 48.8390501,
			lng: 2.3899325
		}
	}
});

export { PLACE_IDS_OVERRIDES };
