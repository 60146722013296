import React from 'react';

export default function RichText({
	text,
	children,
	transform = identity,
	...props
}) {
	if (text) {
		if (typeof text === 'string') {
			return (
				<div>
					{text.split(/\n|\\n/).map((paragraph, index) => {
						if (!paragraph?.trim()) return <br key={index} />;
						return (
							<p key={`description-${index}`} {...props}>
								{paragraph}
							</p>
						);
					})}
					{children}
				</div>
			);
		} else if (text.blocks && text.blocks.length) {
			return (
				<div {...props}>
					{text.blocks.map((block, i) => {
						if (block && block.text) {
							let text = transform(block.text);
							if (block.inlineStyleRanges) {
								text = text
									.split('')
									.reduce((html, char, i) => {
										const isBold = block.inlineStyleRanges.find(
											range =>
												i >= range.offset && i < range.offset + range.length
										);
										if (isBold) {
											return `${html}<b>${char}</b>`;
										} else {
											return html + char;
										}
									}, '')
									.replace(/<\/b><b>/g, '');
							}
							return <p dangerouslySetInnerHTML={{ __html: text }} key={i} />;
						} else {
							return <br key={i} />;
						}
					})}
					{children}
				</div>
			);
		} else {
			return null;
		}
	} else {
		return null;
	}
}

function identity(x) {
	return x;
}
