import {
	formatDuration,
	formatPrices,
	filter,
	find,
	safeRead,
	reduce
} from '@planity/helpers';

export function getStepInfo(step, business, t, canBeCancelled) {
	if (!business) return {};
	const serviceId = step.serviceOrigin || step.serviceId;
	const serviceSetId = Object.keys(business.services || {}).find(setId => {
		return !!safeRead(business, ['services', setId, 'children', serviceId]);
	});
	const serviceName = safeRead(business, [
		'services',
		serviceSetId,
		'children',
		serviceId,
		'name'
	]);
	const durationInMins =
		(step.serviceOrigin &&
			safeRead(business, [
				'services',
				serviceSetId,
				'children',
				serviceId,
				'duration'
			])) ||
		step.duration;
	const duration = business.hideServiceDurations
		? null
		: formatDuration(durationInMins, t);

	const price = business.hideServicePrices
		? null
		: formatStepPrice(
				step.serviceOriginPrice || step.price
					? {
							default: step.serviceOriginPrice || step.price,
							onQuotation: step.onQuotation
					  }
					: safeRead(business, [
							'services',
							serviceSetId,
							'children',
							serviceId,
							'prices'
					  ]),
				(step.calendars || '').split(','),
				business.calendars,
				t
		  );
	const calendars = formatCalendars(
		canBeCancelled,
		step,
		business.calendars || {},
		business.priceCategories
	);
	return {
		serviceName,
		duration,
		durationInMins,
		price,
		calendars
	};
}

function formatStepPrice(prices, calendars, businessCalendars, t) {
	if (prices) {
		if (calendars.find(c => prices.hasOwnProperty(c))) {
			prices = filter(prices, (_, key) => calendars.indexOf(key) !== -1);
		}
		const _prices = formatPrices(prices);
		return (_prices || []).reduce((a, x) => `${a} ${x.price || t(x.text)}`, '');
	}
	return '';
}

function formatCalendars(
	canBeCancelled,
	step,
	businessCalendars,
	priceCategories
) {
	const { selectedCalendars, calendars } = step;

	if (canBeCancelled && !selectedCalendars) return null;

	const finalCalendar = canBeCancelled ? selectedCalendars : calendars;
	const collaborators =
		finalCalendar &&
		Array.from(
			finalCalendar.split(',').reduce((all, calendarId) => {
				const setId = find(
					businessCalendars,
					set => !!set?.children?.[calendarId]
				);
				const set = businessCalendars[setId];
				if (set && set.system === 'people') {
					all.add(set?.children?.[calendarId]);
				}

				return all;
			}, new Set())
		);

	if (!collaborators?.length) return null;
	if (collaborators?.find(c => !c)) {
		const selectedPriceCategories = collaborators?.map(
			calendar => calendar.priceCategory
		);
		if (
			selectedPriceCategories.filter > 1 ||
			selectedPriceCategories.find(priceCategory => !priceCategory)
		)
			return null;

		const name =
			priceCategories?.[Object.keys(selectedPriceCategories || {})[0]]?.name;

		return name || null;
	} else {
		return collaborators?.map(c => c.name).join(', ');
	}
}

/**
 * This is a very confusing function. It returns an appointment, with an altered
 * sequence (that becomes an array). But we keep using the original sequence
 * most of the time (as an object). And when using the origin sequence, we
 * convert it to an array most of the time too. Yay.
 * @param appointment {Object} an appointment
 * @returns {Object} a cursed appointment
 */
export function prepareSequence(appointment) {
	if (!appointment) return {};
	return {
		...appointment,
		originalSequence: appointment.sequence,
		sequence: reduce(
			appointment.sequence || {},
			(sequence, step, stepId) => {
				sequence.push({ ...step, stepId });
				return sequence;
			},
			[]
		)
			.sort((x, y) => x.sort - y.sort)
			.reduce((sequence, step) => {
				if (step.serviceOrigin && step.serviceOriginIndex !== 1) {
					const lastIndex = sequence.length - 1;
					if (sequence[lastIndex]) {
						sequence[lastIndex].calendars = addCalendars(
							sequence[lastIndex].calendars,
							step.calendars
						);
						sequence[lastIndex].selectedCalendars = addCalendars(
							sequence[lastIndex].selectedCalendars,
							step.selectedCalendars
						);
					} else {
						sequence.push(step);
					}
				} else {
					sequence.push(step);
				}
				return sequence;
			}, [])
	};
}
function addCalendars(list, add) {
	const were = new Set((list || '').split(','));
	(add || '').split(',').forEach(c => {
		were.add(c);
	});
	return Array.from(were).join(',');
}
