import { reduce, getStepInfo } from '@planity/helpers';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import {
	addMinutes,
	differenceInHours,
	differenceInMinutes,
	format,
	parseISO
} from 'date-fns';
import { safeRead } from '@planity/helpers';

export function addToCalendarDate(date, timeZone = 'Europe/Paris') {
	return format(zonedTimeToUtc(date, timeZone), "yyyy-MM-dd'T'HH:mm:00xxx");
}

export function appointmentStartTime(appointment, business) {
	return addToCalendarDate(parseISO(appointment.start), business.timeZone);
}

export function appointmentEndTime(appointment, business, t) {
	const start = parseISO(appointment.start);
	const appointmentSequence = appointment.sequence;
	const fullDurationInMins = getFullSequenceDuration(
		appointmentSequence,
		business,
		t
	);
	return addToCalendarDate(
		addMinutes(start, fullDurationInMins),
		business.timeZone
	);
}

export function businessAddress({ address }) {
	if (!address) {
		return '';
	}
	const { street, postalCode, locality } = address;
	const city = [postalCode, locality].filter(x => !!x).join(' ');
	return [street?.replace(',', ''), city].filter(x => !!x).join(', ');
}

export function getFullSequenceDuration(sequence, business, t) {
	try {
		return reduce(
			sequence,
			(currentDuration, step) => {
				const { durationInMins } = getStepInfo(step, business, t);
				return currentDuration + durationInMins;
			},
			0
		);
	} catch (e) {
		console.warn(e);
		return 0;
	}
}

export const GOOGLE_CALENDAR = 'Google';
export const APPLE_CALENDAR = 'Apple Calendar';
export const YAHOO_CALENDAR = 'Yahoo';
export const OUTLOOK_CALENDAR = 'Outlook';
export const ICAL = 'iCal';

//2018-07-03T08:00:00.000Z -> 20180703T080000Z
const formatTime = (date, isOutlook = false) => {
	if (isOutlook) {
		return parseISO(date)
			.toISOString()
			.replace(/\.000Z$/, 'Z');
	}
	return parseISO(date)
		.toISOString()
		.replace(/[-:]/g, '')
		.replace(/\.000Z$/, 'Z');
};

const computeDuration = (startTime, endTime) => {
	const start = parseISO(startTime);
	const end = parseISO(endTime);
	return `${differenceInHours(end, start)}:${differenceInMinutes(end, start)}`;
};

export const buildUrl = (
	{
		description,
		endTime,
		location,
		startTime,
		title,
		myAccountLink,
		business,
		user,
		appointmentId
	},
	type
) => {
	switch (type) {
		case GOOGLE_CALENDAR:
			return (
				'https://calendar.google.com/calendar/render' +
				'?action=TEMPLATE' +
				'&dates=' +
				formatTime(startTime) +
				'/' +
				formatTime(endTime) +
				'&location=' +
				encodeURIComponent(location) +
				'&text=' +
				encodeURIComponent(title) +
				'&details=' +
				encodeURIComponent(description)
			);

		case YAHOO_CALENDAR:
			return (
				'https://calendar.yahoo.com/?v=60&view=d&type=20' +
				'&title=' +
				encodeURIComponent(title) +
				'&st=' +
				formatTime(startTime) +
				'&dur=' +
				computeDuration(startTime, endTime) +
				'&desc=' +
				encodeURIComponent(description) +
				'&in_loc=' +
				encodeURIComponent(location)
			);

		case OUTLOOK_CALENDAR:
			return (
				'https://outlook.live.com/calendar/0/deeplink/compose/?rru=addevent' +
				'&startdt=' +
				formatTime(startTime, true) +
				'&enddt=' +
				formatTime(endTime, true) +
				'&subject=' +
				encodeURIComponent(title) +
				'&location=' +
				encodeURIComponent(location) +
				'&body=' +
				encodeURIComponent(description)
			);

		case ICAL:
		case APPLE_CALENDAR:
		default:
			const prodId = {
				company: 'Planity',
				product: 'Planity appointments'
			};
			const timezone = business.timeZone || 'Europe/Paris';
			const address = `${business.address.street}, ${business.address.postalCode} ${business.address.locality}`;
			const _location = address.replace(/,/g, '\\,');
			const lat = safeRead(business, ['location', 'lat']);
			const lng = safeRead(business, ['location', 'lng']);
			const organizerMail = process.env.WIDGET
				? user.email
				: 'noreply@planity.com';
			const organizer = {
				name: business.name,
				email: organizerMail
			};

			return [
				'BEGIN:VCALENDAR',
				'VERSION:2.0',
				'PRODID://' + prodId.company + '//' + prodId.product,
				'NAME:' + title,
				'X-WR-CALNAME:' + title,
				'TIMEZONE-ID:' + timezone,
				'X-WR-TIMEZONE:' + timezone,
				'BEGIN:VEVENT',
				'UID:' + appointmentId,
				'SEQUENCE:' + 0,
				'DTSTAMP:' + formatTime(addToCalendarDate(new Date())),
				'DTSTART:' + formatTime(startTime),
				'DTEND:' + formatTime(endTime),
				'SUMMARY:' + title,
				'LOCATION:' + _location,
				'DESCRIPTION:' + description,
				'ORGANIZER;CN="' + organizer.name + '":mailto:' + organizer.email,
				'ATTENDEE;ROLE=REQ-PARTICIPANT;CUTYPE=INDIVIDUAL;PARTSTAT=ACCEPTED;CN="' +
					user.name +
					'":MAILTO:' +
					user.email,
				'BEGIN:VALARM',
				'ACTION:DISPLAY',
				'TRIGGER:-PT2H',
				'DESCRIPTION:' + title,
				'END:VALARM',
				'URL;VALUE=URI:' + myAccountLink,
				'STATUS:CONFIRMED',
				'END:VEVENT',
				'END:VCALENDAR'
			]
				.concat(lat && lng ? `GEO:${lat};${lng}` : null)
				.filter(x => x)
				.join('\n');
	}
};

export function saveFile(file, filename) {
	try {
		if (!process.env.BROWSER) return;
		const save = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
		save.rel = 'noopener';
		save.href = file;
		save.target = '_self';
		save.download = filename;
		const evt = new MouseEvent('click', {
			view: window,
			button: 0,
			bubbles: true,
			cancelable: false
		});
		save.dispatchEvent(evt);
		(window.URL || window.webkitURL).revokeObjectURL(save.href);
	} catch (e) {
		console.error(e);
	}
}
