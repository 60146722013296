import React from 'react';

/**
 * This should have been United Kingdom but libphonenumber wants "GB" as a
 * calling code, so I decided to call everything the same.
 */
export const GB = props => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='15'
			fill='none'
			viewBox='0 0 20 15'
			{...props}
		>
			<g clipPath='url(#clip0_3902_1516)'>
				<mask
					id='mask0_3902_1516'
					style={{ maskType: 'luminance' }}
					width='20'
					height='15'
					x='0'
					y='0'
					maskUnits='userSpaceOnUse'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_3902_1516)'>
					<path
						fill='#2E42A5'
						fillRule='evenodd'
						d='M0 0V15H20V0H0Z'
						clipRule='evenodd'
					/>
					<mask
						id='mask1_3902_1516'
						style={{ maskType: 'luminance' }}
						width='20'
						height='15'
						x='0'
						y='0'
						maskUnits='userSpaceOnUse'
					>
						<path
							fill='#fff'
							fillRule='evenodd'
							d='M0 0V15H20V0H0Z'
							clipRule='evenodd'
						/>
					</mask>
					<g mask='url(#mask1_3902_1516)'>
						<mask
							id='mask2_3902_1516'
							style={{ maskType: 'luminance' }}
							width='20'
							height='15'
							x='0'
							y='0'
							maskUnits='userSpaceOnUse'
						>
							<rect width='20' height='15' fill='white' />
						</mask>
						<g mask='url(#mask2_3902_1516)'>
							<path
								fill='#fff'
								d='M-2.22696 13.9284L2.17409 15.7896L20.0999 2.02363L22.4213 -0.742296L17.715 -1.36439L10.4035 4.56778L4.51853 8.56467L-2.22696 13.9284Z'
							/>
							<path
								fill='#F50100'
								d='M-1.62445 15.2324L0.617684 16.3126L21.5877 -0.999277H18.4395L-1.62445 15.2324Z'
							/>
							<path
								fill='#fff'
								d='M22.227 13.9284L17.8259 15.7896L-0.0998707 2.02363L-2.42133 -0.742296L2.28497 -1.36439L9.59646 4.56778L15.4815 8.56467L22.227 13.9284Z'
							/>
							<path
								fill='#F50100'
								d='M22.0768 14.8643L19.8347 15.9444L10.9054 8.53233L8.25807 7.70419L-2.6447 -0.732803H0.503517L11.4002 7.50394L14.2946 8.49696L22.0768 14.8643Z'
							/>
							<mask id='path-11-inside-1_3902_1516' fill='#fff'>
								<path
									fillRule='evenodd'
									d='M12.3611 -1.25H7.63889V5H-1.23279V10H7.63889V16.25H12.3611V10H21.2672V5H12.3611V-1.25Z'
									clipRule='evenodd'
								/>
							</mask>
							<path
								fill='#F50100'
								fillRule='evenodd'
								d='M12.3611 -1.25H7.63889V5H-1.23279V10H7.63889V16.25H12.3611V10H21.2672V5H12.3611V-1.25Z'
								clipRule='evenodd'
							/>
							<path
								fill='#fff'
								d='M7.63889 -1.25V-2.5H6.38889V-1.25H7.63889ZM12.3611 -1.25H13.6111V-2.5H12.3611V-1.25ZM7.63889 5V6.25H8.88889V5H7.63889ZM-1.23279 5V3.75H-2.48279V5H-1.23279ZM-1.23279 10H-2.48279V11.25H-1.23279V10ZM7.63889 10H8.88889V8.75H7.63889V10ZM7.63889 16.25H6.38889V17.5H7.63889V16.25ZM12.3611 16.25V17.5H13.6111V16.25H12.3611ZM12.3611 10V8.75H11.1111V10H12.3611ZM21.2672 10V11.25H22.5172V10H21.2672ZM21.2672 5H22.5172V3.75H21.2672V5ZM12.3611 5H11.1111V6.25H12.3611V5ZM7.63889 0H12.3611V-2.5H7.63889V0ZM8.88889 5V-1.25H6.38889V5H8.88889ZM-1.23279 6.25H7.63889V3.75H-1.23279V6.25ZM0.0172081 10V5H-2.48279V10H0.0172081ZM7.63889 8.75H-1.23279V11.25H7.63889V8.75ZM8.88889 16.25V10H6.38889V16.25H8.88889ZM12.3611 15H7.63889V17.5H12.3611V15ZM11.1111 10V16.25H13.6111V10H11.1111ZM21.2672 8.75H12.3611V11.25H21.2672V8.75ZM20.0172 5V10H22.5172V5H20.0172ZM12.3611 6.25H21.2672V3.75H12.3611V6.25ZM11.1111 -1.25V5H13.6111V-1.25H11.1111Z'
								mask='url(#path-11-inside-1_3902_1516)'
							/>
						</g>
					</g>
				</g>
			</g>
			<rect
				width='19'
				height='14'
				x='0.5'
				y='0.5'
				stroke='#000'
				strokeOpacity='0.1'
				rx='1'
				style={{ mixBlendMode: 'multiply' }}
			/>
			<defs>
				<clipPath id='clip0_3902_1516'>
					<rect width='20' height='15' fill='#fff' rx='1.5' />
				</clipPath>
			</defs>
		</svg>
	);
};
