import React from 'react';

export const DE = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5635)'>
				<mask
					id='mask0_11534_5635'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5635)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 10H20V15H0V10Z'
						fill='#FFD018'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 5H20V10H0V5Z'
						fill='#E31D1C'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0H20V5H0V0Z'
						fill='#272727'
					/>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5635'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};

// const styles = {};
