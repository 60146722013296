import React from 'react';
import GiftVoucherHeader from './gift_voucher/gift_voucher_header';
import { USER_BUYING_TYPES } from './user_buying_types';
import ClickAndCollectProductHeader from './click_and_collect/click_and_collect_product_header';

export default function UserBuyingItemHeader({
	boughtAt,
	orderId,
	receiver,
	trackingNumber,
	trackingUrl,
	giftVoucherId,
	expiresAt,
	orderStatus, // for click and collect
	type,
	businessName,
	linkToBusiness,
	businessId,
	business
}) {
	// gift_voucher
	// clickAndCollect

	if (type === USER_BUYING_TYPES.GIFT_VOUCHER) {
		return (
			<GiftVoucherHeader
				boughtAt={boughtAt}
				business={business}
				expiresAt={expiresAt}
				giftVoucherId={giftVoucherId}
				linkToBusiness={linkToBusiness}
				receiver={receiver}
			/>
		);
	} else if (
		type === USER_BUYING_TYPES.CLICK_AND_COLLECT ||
		type === USER_BUYING_TYPES.CLICK_AND_SHOP
	) {
		return (
			<ClickAndCollectProductHeader
				boughtAt={boughtAt}
				business={business}
				businessId={businessId}
				businessName={businessName}
				isClickAndShop={type === USER_BUYING_TYPES.CLICK_AND_SHOP}
				linkToBusiness={linkToBusiness}
				orderId={orderId}
				orderStatus={orderStatus}
				trackingNumber={trackingNumber}
				trackingUrl={trackingUrl}
			/>
		);
	} else {
		return null;
	}
}
