// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".step-module_services-rVxKJ .step-module_service-U4U0F{display:block}.step-module_services-rVxKJ .step-module_addStep-q8YOJ{margin-top:12px;margin-bottom:12px}.step-module_services-rVxKJ .step-module_label-JenYO{display:block;font:var(--medium) var(--body-1);margin-bottom:8px}.step-module_services-rVxKJ .step-module_index-j8K6d{color:var(--primary-200)}.step-module_services-rVxKJ .step-module_removeStep-LxYXr{margin-top:32px;display:flex;justify-content:space-between;align-items:center}.step-module_services-rVxKJ .step-module_removeStep-LxYXr button{margin-top:0;padding-right:31px}@media(max-width: 768px){.step-module_services-rVxKJ .step-module_removeStep-LxYXr button{padding-right:0}}@media(max-width: 767px){.step-module_services-rVxKJ .step-module_firstTitle-SV83C{margin-top:20px}}", "",{"version":3,"sources":["webpack://./../../packages/components/book_appointment/steps/step.module.scss"],"names":[],"mappings":"AAGC,uDACC,aAAA,CAGD,uDACC,eAAA,CACA,kBAAA,CAGD,qDACC,aAAA,CACA,gCAAA,CACA,iBAAA,CAGD,qDACC,wBAAA,CAGD,0DACC,eAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CAGD,iEACC,YAAA,CACA,kBAAA,CACA,yBAHD,iEAIE,eAAA,CAAA,CAKD,yBADD,0DAEE,eAAA,CAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.services {\n\t.service {\n\t\tdisplay: block;\n\t}\n\n\t.addStep {\n\t\tmargin-top: 12px;\n\t\tmargin-bottom: 12px;\n\t}\n\n\t.label {\n\t\tdisplay: block;\n\t\tfont: var(--medium) var(--body-1);\n\t\tmargin-bottom: 8px;\n\t}\n\n\t.index {\n\t\tcolor: var(--primary-200);\n\t}\n\n\t.removeStep {\n\t\tmargin-top: 32px;\n\t\tdisplay: flex;\n\t\tjustify-content: space-between;\n\t\talign-items: center;\n\t}\n\n\t.removeStep button {\n\t\tmargin-top: 0;\n\t\tpadding-right: 31px;\n\t\t@media (max-width: styles.$tablet) {\n\t\t\tpadding-right: 0;\n\t\t}\n\t}\n\n\t.firstTitle {\n\t\t@media (max-width: #{styles.$tablet - 1px}) {\n\t\t\tmargin-top: 20px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"services": "step-module_services-rVxKJ",
	"service": "step-module_service-U4U0F",
	"addStep": "step-module_addStep-q8YOJ",
	"label": "step-module_label-JenYO",
	"index": "step-module_index-j8K6d",
	"removeStep": "step-module_removeStep-LxYXr",
	"firstTitle": "step-module_firstTitle-SV83C"
};
module.exports = ___CSS_LOADER_EXPORT___;
