import { Banner } from '@planity/ui';
import { Localize } from '../../localize';
import React, { useEffect, useState } from 'react';
import { format as formatDate } from 'date-fns';
import { useLocalization } from '@planity/localization';
import { noAvailabilitiesFromEmail } from '@planity/helpers';

export const Notifications = ({
	startDateToDelete,
	isMoveAppointment = false,
	shouldDisplayNoAvailabilities,
	fromWaitingListMail,
	isLoading
}) => {
	const shouldDisplayNoAvailabilitiesBanner =
		shouldDisplayNoAvailabilities === true && !isLoading;
	const [hasDispatchNoAvailabilityEvent, setHasDispatchNoAvailabilityEvent] =
		useState(false);

	useEffect(() => {
		const shoudDispatchEvent =
			fromWaitingListMail &&
			!hasDispatchNoAvailabilityEvent &&
			shouldDisplayNoAvailabilitiesBanner;
		if (shoudDispatchEvent) {
			setHasDispatchNoAvailabilityEvent(true);
			noAvailabilitiesFromEmail();
		}
	}, [
		shouldDisplayNoAvailabilitiesBanner,
		hasDispatchNoAvailabilityEvent,
		fromWaitingListMail
	]);

	const { dateLocale } = useLocalization();
	if (!startDateToDelete) return null; // no notification if we are not in move appointment case

	const start = new Date(startDateToDelete);
	const date = formatDate(start, 'eeee d MMMM', { locale: dateLocale });
	const hour = formatDate(start, 'HH:mm', { locale: dateLocale });

	switch (isMoveAppointment) {
		case !fromWaitingListMail: // normal move appointment
		case shouldDisplayNoAvailabilities === false && !isLoading:
			return (
				<NotificationBanner
					args={{ date, hour }}
					status='INFORMATION'
					text='bookAppointment.appointment.oldDateReminder'
				/>
			);
		case shouldDisplayNoAvailabilitiesBanner:
			return (
				<NotificationBanner
					args={{ date, hour }}
					status='WARNING'
					text='bookAppointment.appointment.noMoreAvailability'
				/>
			);
		default:
			return null;
	}
};

function NotificationBanner({ args, status, text }) {
	return (
		<Banner key={text} status={status}>
			<Localize args={args} text={text} />
		</Banner>
	);
}
