import React from 'react';

export const IM = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5739)'>
				<mask
					id='mask0_11534_5739'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5739)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#E31D1C'
					/>
					<mask
						id='mask1_11534_5739'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_11534_5739)'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M3.80846 2.58812C3.80365 2.53029 3.87285 2.50225 3.91198 2.54509C4.19269 2.85237 4.93459 3.61736 5.36365 3.61736C5.90053 3.61736 8.00902 1.84071 8.63224 2.13013C9.25546 2.41955 10.5173 5.44557 10.5173 5.44557L9.695 6.47698L8.63224 5.89722L8.43138 3.61736C8.43138 3.61736 6.38572 4.66953 5.36365 4.28762L4.7891 4.77041C4.7891 4.77041 4.23579 3.67478 4.0179 3.33967C3.87374 3.11794 3.82496 2.78681 3.80846 2.58812Z'
							fill='#F7FCFF'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M5.16369 5.07775L4.81382 5.28973L4.88064 4.84075L4.59758 4.52279L4.98875 4.45728L5.16369 4.04879L5.33863 4.45728L5.72979 4.52279L5.44674 4.84075L5.51356 5.28973L5.16369 5.07775Z'
							fill='#FECA00'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M8.13293 2.37C8.13293 2.2448 8.23443 2.1433 8.35963 2.1433C8.48483 2.1433 8.58633 2.2448 8.58633 2.37V3.441C8.58633 3.5662 8.48483 3.66769 8.35963 3.66769C8.23443 3.66769 8.13293 3.5662 8.13293 3.441L8.13293 2.37Z'
							fill='#FECA00'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M15.7626 3.18965C15.8142 3.21622 15.8013 3.28975 15.7443 3.30024C15.3349 3.37546 14.293 3.59925 14.0657 3.9631C13.7811 4.4184 14.1705 7.14799 13.5948 7.52314C13.0191 7.8983 9.78424 7.36483 9.78424 7.36483L9.34528 6.12093L10.4001 5.52689L12.44 6.56469C12.44 6.56469 12.6317 4.27231 13.4972 3.60792L13.3923 2.86484C13.3923 2.86484 14.6146 2.9762 15.0143 2.969C15.2787 2.96424 15.5854 3.09835 15.7626 3.18965Z'
							fill='#F7FCFF'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M12.9331 3.01964L12.9388 2.6106L13.2841 2.90519L13.7038 2.83365L13.552 3.20009L13.8058 3.56491L13.3666 3.4968L13.1038 3.79381L12.9841 3.38528L12.568 3.20402L12.9331 3.01964Z'
							fill='#FECA00'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M13.6964 7.00298C13.8086 7.07261 13.8455 7.21873 13.7796 7.33324C13.7089 7.45609 13.5491 7.49351 13.4313 7.4148L12.4634 6.76838C12.3766 6.71037 12.341 6.60028 12.3774 6.50241C12.427 6.36904 12.5864 6.31454 12.7073 6.38954L13.6964 7.00298Z'
							fill='#FECA00'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M9.28907 13.1148C9.24027 13.1462 9.18302 13.0982 9.20248 13.0436C9.34201 12.6515 9.66915 11.6373 9.46772 11.2584C9.21567 10.7844 6.6571 9.75678 6.62006 9.07064C6.58302 8.38449 8.66245 5.84975 8.66245 5.84975L9.95917 6.09155L9.9462 7.3021L8.0275 8.54977C8.0275 8.54977 9.9169 9.86202 10.0595 10.9438L10.7555 11.2244C10.7555 11.2244 10.0479 12.2273 9.85432 12.577C9.72623 12.8084 9.45675 13.0069 9.28907 13.1148Z'
							fill='#F7FCFF'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M10.8511 10.7494L11.2025 10.9588L10.7747 11.1105L10.6268 11.5097L10.3853 11.1951L9.94254 11.2324L10.2211 10.8862L10.0953 10.5101L10.5089 10.6107L10.874 10.3409L10.8511 10.7494Z'
							fill='#FECA00'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M6.93964 9.34824C6.80022 9.40145 6.64518 9.32361 6.60458 9.18C6.56899 9.05411 6.63482 8.92178 6.75669 8.87423L7.79937 8.46734C7.91522 8.42213 8.04684 8.46593 8.11251 8.57154C8.19823 8.7094 8.13698 8.89123 7.98531 8.94912L6.93964 9.34824Z'
							fill='#FECA00'
						/>
						<path
							d='M8.53913 6.10309C8.46135 6.07591 8.4198 5.98928 8.44633 5.9096C8.47287 5.82991 8.59748 5.69682 8.67527 5.724L9.76014 6.10309C9.81611 6.12265 9.85546 6.1743 9.86039 6.23468L9.9574 7.42458C9.96424 7.50848 9.84204 7.57635 9.76014 7.58336C9.67824 7.59036 9.6063 7.52803 9.59946 7.44413L9.50963 6.42085L8.53913 6.10309Z'
							fill='#FECA00'
						/>
						<path
							d='M10.4254 5.47972C10.4902 5.42799 10.606 5.48794 10.6565 5.55437C10.707 5.6208 10.6953 5.71658 10.6305 5.7683L9.87899 6.53856C9.81415 6.59029 9.66831 6.48939 9.61782 6.42297C9.56733 6.35654 9.55297 6.17559 9.61782 6.12386L10.4254 5.47972Z'
							fill='#FECA00'
						/>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5739'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
