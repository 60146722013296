import classNames from 'classnames/bind';
import styles from './home_press.module.scss';
import { Container, Title } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { ElleLogo, GraziaLogo, VogueLogo } from '@planity/components';
import { MarieClaire } from '../../../assets';
import { useTranslation } from '@planity/localization';

export function HomePress() {
	useStyles(styles);
	const { t } = useTranslation();

	const classes = classNames.bind(styles)({
		homePress: true
	});

	return (
		<div className={classes}>
			<Container noStupidMarginBottom>
				<Title
					label={t('landing.media.subtitle')}
					title={t('landing.media.title')}
					type={'secondary'}
					align='center'
					isInverted={true}
				/>

				<div className={styles.grid}>
					<div className={styles.item}>
						<VogueLogo />
					</div>
					<div className={styles.item}>
						<GraziaLogo css={styles.grazia} />
					</div>
					<div className={styles.item}>
						<ElleLogo />
					</div>
					<div className={styles.item}>
						<MarieClaire />
					</div>
				</div>
			</Container>
		</div>
	);
}
