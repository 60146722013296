import { useFormHooks } from '@planity/components';
import React from 'react';
import { Input, Phone, Button, Spinner } from '@planity/ui';
import classNames from 'classnames/bind';
import styles from '../authentication/authentication.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { checkInputError } from '@planity/components';
import {
	LAST_NAME_CHANGE,
	FIRST_NAME_CHANGE,
	PHONE_NUMBER_CHANGE
} from '@planity/components';
import { useTranslation } from '@planity/localization';

export function FinishSignUp({ data, localizedText, pending, phoneInput }) {
	const { handleDispatchEvent, handleDispatchInputs, formValues } =
		useFormHooks();
	const { authBooking } = formValues;
	const { t } = useTranslation();
	const classes = classNames.bind(styles)({
		auth: true
	});
	useStyles(styles);
	return (
		<div
			id='finish-signup-comp'
			className={`${classes} ${styles.finishsingnup}`}
		>
			<h1 className={styles.title}>{localizedText.title}</h1>
			<form className={styles.form}>
				<div className={styles.fields}>
					{!authBooking &&
						(phoneInput ? (
							phoneInput
						) : (
							<Phone
								id={'finish-signup-comp__input-phone'}
								label={localizedText.labels.phoneNumber}
								isRequired={true}
								isDisabled={true}
								name='phone_number'
								placeholder={localizedText.placeholders.phoneNumber}
								value={data && data.phoneNumber}
								onChange={phoneNumber =>
									handleDispatchInputs({
										type: PHONE_NUMBER_CHANGE,
										phoneNumber
									})
								}
								error={checkInputError('phoneNumber', t, data)?.format}
								errorMessage={checkInputError('phoneNumber', t, data)?.message}
							/>
						))}
					<Input
						id={'finish-signup-comp__input-firstName'}
						autoFocus={true}
						label={localizedText.labels.firstName}
						isRequired={true}
						name='firstName'
						placeholder={localizedText.placeholders.firstName}
						value={data && data.firstName}
						onChange={firstName =>
							handleDispatchInputs({
								type: FIRST_NAME_CHANGE,
								firstName
							})
						}
						error={checkInputError('firstName', t, data)?.format}
						errorMessage={checkInputError('firstName', t, data)?.message}
					/>
					<Input
						id={'finish-signup-comp__input-lastName'}
						label={localizedText.labels.lastName}
						isRequired={true}
						name='lastName'
						placeholder={localizedText.placeholders.lastName}
						value={data && data.lastName}
						onChange={lastName =>
							handleDispatchInputs({
								type: LAST_NAME_CHANGE,
								lastName
							})
						}
						error={checkInputError('lastName', t, data)?.format}
						errorMessage={checkInputError('lastName', t, data)?.message}
					/>
				</div>
				<Button
					className={`${authBooking && styles.finish} ${styles.submit}`}
					size='large'
					label={pending ? <Spinner /> : localizedText.labels.button.confirm}
					onClick={event =>
						handleDispatchEvent({ event: event, type: 'finishsignup' })
					}
					id={'finish-signup-comp__submit'}
				/>
				<Button
					className={`${styles.submit} ${styles.deconnect}`}
					size='large'
					type='secondary'
					label={localizedText.labels.button.deconnect}
					onClick={event =>
						handleDispatchEvent({
							event: event,
							type: 'signOutfinishsignup'
						})
					}
					id={'finish-signup-comp__signOut'}
				/>
			</form>
		</div>
	);
}
