import React from 'react';

export const AE = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5521)'>
				<mask
					id='mask0_11534_5521'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5521)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0H20V15H0V0Z'
						fill='#F7FCFF'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V5H20V0H0Z'
						fill='#5EAA22'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 10V15H20V10H0Z'
						fill='#272727'
					/>
					<rect width='6' height='15' fill='#E31D1C' />
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5521'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
