import React, { useEffect, useState } from 'react';
import { Button } from '@planity/ui';
import { safeRead } from '@planity/helpers';
import { USER_ADDED_STEP, USER_REMOVED_STEP } from '../events';
import AppointmentServices from './services';
import { BookAppointmentTitle } from '@planity/components';
import { useTranslation } from '@planity/localization';
import AppointmentStep from './step';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './step.module.scss';
import { breakpoints, Match } from '@planity/theme';
import { MY_ACCOUNT_BLOC, useTheme } from '@planity/context/theme_context';

export default function AppointmentSteps({
	appointment,
	highlightedServices,
	globalSearch,
	business,
	dispatch,
	isPending,
	redirectTo,
	onBooking,
	hasPastAppointments,
	serviceSetsWhitelist,
	servicesWhitelist,
	servicesNotCollapsed
}) {
	const { t } = useTranslation();
	useStyles(styles);
	const { isDark: hasDarkBackground } = useTheme();
	const isDark = hasDarkBackground?.[MY_ACCOUNT_BLOC];
	const classes = classNames.bind(styles)({
		services: true
	});
	const { steps } = appointment;
	const [hasOnlyOneSimpleService, setHasOnlyOneSimpleService] = useState(false);

	useEffect(() => {
		const simpleServicesLength = steps.filter(step => !step.sequence).length;
		setHasOnlyOneSimpleService(
			simpleServicesLength === steps.length && steps.length === 1
		);
	}, [steps.length]);

	const shouldDisplayServices =
		!steps.length || !safeRead(steps, [steps.length - 1, 'serviceId']);
	const hasDefinedSteps = !!steps.find(step => !!step.serviceId);
	const canAddStep = !shouldDisplayServices && !appointment.date;
	const showAllServicesText =
		!onBooking && !highlightedServices.length && hasPastAppointments;
	const stepsTitle = shouldDisplayServices
		? 'bookAppointment.stepsTitle.shouldDisplay'
		: steps.length > 1
		? 'bookAppointment.stepsTitle.steps'
		: 'bookAppointment.stepsTitle.step';
	return (
		<Match query={breakpoints.simpleTabletQuery}>
			{isTablet => (
				<div className={classes}>
					<div
						className={`${styles.service} planity_ui_appointment_background`}
					>
						{onBooking && (
							<BookAppointmentTitle
								className={styles.firstTitle}
								index={'1.'}
								isDark={isDark}
								text={stepsTitle}
							/>
						)}
						{!onBooking &&
							!highlightedServices.length &&
							!hasPastAppointments &&
							!process.env.WIDGET && (
								<BookAppointmentTitle
									className={'business-services'}
									text={'bookAppointment.chooseServices'}
								/>
							)}
						{showAllServicesText ? (
							<BookAppointmentTitle
								className={'business-services'}
								text={'bookAppointment.allServices'}
							/>
						) : null}
						<div className={styles.service}>
							{steps.reduce((displayedSteps, step, index) => {
								if (step.serviceId) {
									displayedSteps.push(
										<AppointmentStep
											appointment={appointment}
											business={business}
											dispatch={dispatch}
											hasOnlyOneSimpleService={hasOnlyOneSimpleService}
											isMobile={!isTablet}
											isPending={isPending}
											key={index}
											path={[index]}
											step={step}
											stepIndex={index}
										/>
									);
								}

								return displayedSteps;
							}, [])}
						</div>
						{shouldDisplayServices && (
							<div>
								{hasDefinedSteps && (
									<div className={styles.removeStep}>
										<BookAppointmentTitle
											className={'business-services'}
											text={'bookAppointment.addStepTitle'}
										/>
										<Button
											className={`${styles.removeStep} planity_ui_action_button_root planity_ui_action_button_icon-remove`}
											label={t('common.delete')}
											type={'underlined'}
											onClick={() =>
												dispatch(USER_REMOVED_STEP, {
													stepIndex: steps.length - 1
												})
											}
										/>
									</div>
								)}
								<AppointmentServices
									business={business}
									dispatch={dispatch}
									globalSearch={globalSearch}
									hasPastAppointments={hasPastAppointments}
									highlightedServices={highlightedServices}
									isDark={isDark}
									redirectTo={redirectTo}
									serviceSetsWhitelist={serviceSetsWhitelist}
									servicesNotCollapsed={servicesNotCollapsed}
									servicesWhitelist={servicesWhitelist}
									onBooking={onBooking}
								/>
							</div>
						)}
						{canAddStep && (
							<div className={styles.addStep}>
								<Button
									className={'planity_ui_button_root planity-component-button'}
									iconLeft={isTablet ? 'Add' : ''}
									isFullWidth={!isTablet}
									label={t('bookAppointment.addStep')}
									size='medium'
									type='primary'
									onClick={() => dispatch(USER_ADDED_STEP)}
								/>
							</div>
						)}
					</div>
				</div>
			)}
		</Match>
	);
}
