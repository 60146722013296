import { prepareSequence } from '@planity/helpers';
import React, { useMemo } from 'react';
import { useErrorMessages } from '../../error_message';
import { AppointmentPreviewComponent } from './appointment_preview_component';
import { Spinner } from '@planity/ui';

export const AppointmentPreviewContainer = ({
	appointment,
	appointmentId,
	authWith,
	isPast,
	linkToBookAppointment,
	linkToBusiness,
	appointmentIndex,
	...props
}) => {
	const { pushMessage } = useErrorMessages();
	const preparedAppointment = useMemo(
		() => prepareSequence(appointment),
		[appointment]
	);

	const business = appointment?.business;
	if (!business) {
		return <Spinner />;
	}
	return (
		<AppointmentPreviewComponent
			appointment={preparedAppointment}
			appointmentId={appointmentId}
			appointmentIndex={appointmentIndex}
			authWith={authWith}
			business={business}
			isPast={isPast}
			linkToBookAppointment={linkToBookAppointment}
			linkToBusiness={linkToBusiness}
			{...props}
			pushMessage={pushMessage}
			sequence={appointment.sequence}
		/>
	);
};
