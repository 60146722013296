import React from 'react';

export const SK = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5939)'>
				<mask
					id='mask0_11534_5939'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5939)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0V15H20V0H0Z'
						fill='#3D58DB'
					/>
					<mask
						id='mask1_11534_5939'
						style={{ maskType: 'luminance' }}
						maskUnits='userSpaceOnUse'
						x='0'
						y='0'
						width='20'
						height='15'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V15H20V0H0Z'
							fill='white'
						/>
					</mask>
					<g mask='url(#mask1_11534_5939)'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 0V5H20V0H0Z'
							fill='#F7FCFF'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M0 10V15H20V10H0Z'
							fill='#E31D1C'
						/>
						<path
							d='M9.75246 3.76898L10.0632 3.73592L10.0335 3.45648H9.75246H3.96553H3.6734L3.65374 3.74796L3.96553 3.76898L3.65374 3.74801L3.65373 3.74815L3.65369 3.74865L3.65357 3.75057L3.65307 3.75802L3.65116 3.78693L3.64408 3.89705C3.63804 3.99229 3.62958 4.12932 3.61983 4.29679C3.60035 4.63163 3.57574 5.08864 3.55523 5.57687C3.5148 6.53913 3.488 7.66561 3.55801 8.19169C3.69461 9.21824 4.3197 11.5227 6.7374 12.567L6.86349 12.6215L6.98888 12.5654C9.28526 11.5386 10.0533 9.47074 10.2305 8.1934C10.3199 7.54875 10.2744 6.42073 10.2117 5.48335C10.1799 5.00834 10.1428 4.57187 10.1137 4.25425C10.0991 4.09538 10.0865 3.96608 10.0776 3.87638L10.067 3.77273L10.0642 3.7455L10.0635 3.73845L10.0633 3.73661L10.0632 3.73612L10.0632 3.73598L9.75246 3.76898Z'
							fill='#E31D1C'
							stroke='white'
							strokeWidth='0.625'
						/>
						<mask
							id='mask2_11534_5939'
							style={{ maskType: 'luminance' }}
							maskUnits='userSpaceOnUse'
							x='3'
							y='3'
							width='8'
							height='10'
						>
							<path
								d='M9.75246 3.76898L10.0632 3.73592L10.0335 3.45648H9.75246H3.96553H3.6734L3.65374 3.74796L3.96553 3.76898L3.65374 3.74801L3.65373 3.74815L3.65369 3.74865L3.65357 3.75057L3.65307 3.75802L3.65116 3.78693L3.64408 3.89705C3.63804 3.99229 3.62958 4.12932 3.61983 4.29679C3.60035 4.63163 3.57574 5.08864 3.55523 5.57687C3.5148 6.53913 3.488 7.66561 3.55801 8.19169C3.69461 9.21824 4.3197 11.5227 6.7374 12.567L6.86349 12.6215L6.98888 12.5654C9.28526 11.5386 10.0533 9.47074 10.2305 8.1934C10.3199 7.54875 10.2744 6.42073 10.2117 5.48335C10.1799 5.00834 10.1428 4.57187 10.1137 4.25425C10.0991 4.09538 10.0865 3.96608 10.0776 3.87638L10.067 3.77273L10.0642 3.7455L10.0635 3.73845L10.0633 3.73661L10.0632 3.73612L10.0632 3.73598L9.75246 3.76898Z'
								fill='white'
								stroke='white'
								strokeWidth='0.625'
							/>
						</mask>
						<g mask='url(#mask2_11534_5939)'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M6.4107 4.11859C6.4107 4.11859 6.53975 4.31523 6.59753 4.61591C6.6553 4.91658 6.66527 5.44341 6.66527 5.44341L5.44097 5.29267V6.22097L6.69262 6.06157L6.67228 6.94266C6.67228 6.94266 6.29782 6.98147 5.99071 6.94266C5.6836 6.90384 5.07858 6.74122 5.07858 6.74122V7.80396C5.07858 7.80396 5.69327 7.6073 5.99071 7.56218C6.28815 7.51705 6.67228 7.56218 6.67228 7.56218V8.86737H7.18042V7.56218C7.18042 7.56218 7.73589 7.51389 8.06725 7.56617C8.39861 7.61845 8.71293 7.80396 8.71293 7.80396V6.74122C8.71293 6.74122 8.39449 6.89987 8.09415 6.93934C7.79381 6.97882 7.18042 6.94266 7.18042 6.94266L7.20076 6.06157C7.20076 6.06157 7.61653 6.01852 7.84979 6.05019C8.08305 6.08185 8.375 6.22097 8.375 6.22097V5.29267C8.375 5.29267 8.09045 5.44751 7.86656 5.47559C7.64266 5.50368 7.17341 5.44341 7.17341 5.44341C7.17341 5.44341 7.18173 4.89974 7.22987 4.63501C7.27801 4.37029 7.41434 4.11859 7.41434 4.11859H6.4107Z'
								fill='#F7FCFF'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M4.31085 10.1533C4.31085 10.1533 4.58553 9.5638 5.03249 9.4517C5.47945 9.3396 5.94813 9.80252 5.94813 9.80252C5.94813 9.80252 6.23432 8.86725 6.93188 8.86725C7.62945 8.86725 7.87426 9.80252 7.87426 9.80252C7.87426 9.80252 8.17188 9.4517 8.59986 9.4517C9.02785 9.4517 9.42437 10.1533 9.42437 10.1533C9.42437 10.1533 8.14599 12.4742 6.86761 12.4742C5.58923 12.4742 4.31085 10.1533 4.31085 10.1533Z'
								fill='#2E42A5'
							/>
						</g>
					</g>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5939'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
