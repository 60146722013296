import React, { Fragment } from 'react';
import { formatPhoneNumber } from '@planity/helpers';
import { Localize, useAuth } from '@planity/components';
import { Button, InlineLink, Input, Spinner } from '@planity/ui';
import { useTranslation } from '@planity/localization';
import styles from './phone_verification_form.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';

export const PhoneVerificationConfirm = ({
	currentState,
	handleClickEvents,
	handleInputChange
}) => {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		phoneVerification: true
	});
	const { user } = useAuth();
	const { t } = useTranslation();

	const { code, isPending, sendingSMS, smsWasSent, phoneNumber } = currentState;
	const phoneNumberTel = phoneNumber || user?.phone || '';
	return (
		<form
			className={classes}
			id='phone-verif-form'
			onSubmit={e => handleClickEvents({ event: e, type: 'onSubmit' })}
		>
			<span className={styles.title}>{t('phoneVerificationForm.title')}</span>
			<div className={styles.text}>
				<Localize
					args={{
						phoneNumber: formatPhoneNumber(phoneNumberTel).replace(' ', '\xa0')
					}}
					text={'phoneVerificationForm.alert'}
				/>
				<div className={`${styles.text} ${styles.text__update}`}>
					<InlineLink
						className={`${styles.text} ${styles.text__clickHere}`}
						id={'phone-verif-modify-button'}
						label={t('phoneVerificationForm.clickHere')}
						type='linked'
						onClick={event => handleClickEvents({ event, type: 'clickHere' })}
					/>

					<Localize
						args={{ phoneNumber: formatPhoneNumber(phoneNumberTel) }}
						text={'phoneVerificationForm.alert2'}
					/>
				</div>
				<Input
					autoFocus={true}
					className={`${styles.text} ${styles.text__code}`}
					id={'phone-verif-code-input'}
					isDisabled={isPending}
					placeholder={t('phoneVerificationForm.placeholder')}
					type={'tel'}
					value={code}
					onChange={event =>
						handleInputChange({ type: 'setCode', value: event })
					}
				/>
				<div className={`${styles.text} ${styles.text__reSend}`}>
					{smsWasSent ? (
						<span className={styles.hasSent}>
							<Localize
								args={{ phoneNumber: formatPhoneNumber(phoneNumberTel) }}
								text={'phoneVerificationForm.smsWasSent'}
							/>
						</span>
					) : (
						<Fragment>
							<span
								className={`${styles.text} ${styles.text__reSend__notReceived}`}
							>
								<Localize text={'phoneVerificationForm.smsNotReceived'} />
							</span>
							{sendingSMS ? (
								<div className={styles.spinner}>
									<Spinner />
								</div>
							) : (
								<InlineLink
									className={[`${styles.text} ${styles.text__reSend__link}`]}
									id={'phone-verif-sendSMSAgain-button'}
									label={t('phoneVerificationForm.sendSMSAgain')}
									type='linked'
									onClick={event =>
										handleClickEvents({ event: event, type: 'sendSMS' })
									}
								/>
							)}
						</Fragment>
					)}
				</div>
				<Button
					className={`${styles.btn} ${styles.btn__submit}`}
					id={'phone-verif-submit-button'}
					isDisabled={isPending}
					isLoading={isPending}
					label={<Localize text={'actions.submit'} />}
				/>
				<Button
					className={`${styles.btn}  ${styles.btn__signOut}`}
					id='phone-verif-signOut-button'
					isDisabled={isPending}
					isFullWidth={true}
					label={t('phoneVerificationForm.signOut')}
					type={'secondary'}
					onClick={event =>
						handleClickEvents({ event: event, type: 'signOutAndClean' })
					}
				/>
			</div>
		</form>
	);
};
