// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(min-width: 1080px){.legal_page-module_legal-FNz0Y{margin-top:64px}}.legal_page-module_legal-FNz0Y .legal_page-module_container-bE6a1{position:relative;height:100%;margin:0 auto}.legal_page-module_legal-FNz0Y h1{margin-bottom:40px}.legal_page-module_legal-FNz0Y iframe{border:none;outline:none;height:100%;width:100%;position:absolute;top:0;right:0;bottom:0;left:0}", "",{"version":3,"sources":["webpack://./app/components/static_pages/legal_page.module.scss"],"names":[],"mappings":"AAIC,0BADD,+BAEE,eAAA,CAAA,CAGD,kEACC,iBAAA,CACA,WAAA,CACA,aAAA,CAGD,kCACC,kBAAA,CAGD,sCACC,WAAA,CACA,YAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACA,KAAA,CACA,OAAA,CACA,QAAA,CACA,MAAA","sourcesContent":["@use '@planity/ui/styles/utilities/mixins.scss';\n@use '@planity/ui/styles/utilities/variables.scss';\n\n.legal {\n\t@media (min-width: variables.$desktop) {\n\t\tmargin-top: #{variables.$header-height};\n\t}\n\n\t.container {\n\t\tposition: relative;\n\t\theight: 100%;\n\t\tmargin: 0 auto;\n\t}\n\n\th1 {\n\t\tmargin-bottom: 40px;\n\t}\n\n\tiframe {\n\t\tborder: none;\n\t\toutline: none;\n\t\theight: 100%;\n\t\twidth: 100%;\n\t\tposition: absolute;\n\t\ttop: 0;\n\t\tright: 0;\n\t\tbottom: 0;\n\t\tleft: 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"legal": "legal_page-module_legal-FNz0Y",
	"container": "legal_page-module_container-bE6a1"
};
module.exports = ___CSS_LOADER_EXPORT___;
