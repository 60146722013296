import React from 'react';
import { Localize } from '@planity/components';
import VoucherItem from './voucher_item';
import { BookAppointmentTitle } from '@planity/components';
import ServiceItem from './service_item';
import classNames from 'classnames/bind';
import styles from './service_item.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';
import { Button } from '@planity/ui';
import { formatPrice } from '@planity/helpers';
import { GIFT_VOUCHER_BLOC, useTheme } from '@planity/context/theme_context';

export default function ServicesList({
	selectedServices,
	giftVoucherPrice,
	popupGiftService,
	business,
	totalPrc,
	onRemove,
	changeVariablePrice,
	typeSelected,
	hideChose,
	variablePriceValidation,
	hasSelectedVariableVoucher,
	reinjectData,
	isPending
}) {
	useStyles(styles);
	const { isDark: hasDarkBackground } = useTheme();
	const isDark = hasDarkBackground?.[GIFT_VOUCHER_BLOC];
	const { t } = useTranslation();
	const classes = classNames.bind(styles)({
		bookingCart: true,
		isDark
	});

	return (
		<div className={classes}>
			<BookAppointmentTitle
				index={'1. '}
				isDark={isDark}
				text={'gift.services'}
			/>
			{selectedServices.map((service, index) => {
				if (service.validity)
					return (
						<VoucherItem
							business={business}
							giftVoucherPrice={formatPrice(giftVoucherPrice * 100)}
							hasSelectedVariableVoucher={hasSelectedVariableVoucher}
							hideChose
							index={index}
							isPending={isPending}
							key={index}
							reinjectData={reinjectData}
							totalPrice={totalPrc}
							updatePrice={changeVariablePrice}
							variablePriceValidation={variablePriceValidation}
							voucher={service}
							onBooking
							onRemove={onRemove}
						/>
					);
				else
					return (
						<ServiceItem
							business={business}
							hideChose={hideChose}
							index={index}
							key={index}
							service={service}
							serviceId={service.id}
							onBooking
							onRemove={onRemove}
						/>
					);
			})}
			<div>
				{typeSelected === 'service' ? (
					<Button
						className={`${styles.addStep} planity_ui_button_root planity-component-button`}
						iconLeft={'Add'}
						isFullMobile
						isLoading={isPending}
						label={t('gift.addOrModify')}
						size='medium'
						type='primary'
						onClick={popupGiftService}
					/>
				) : (
					<div />
				)}
				{!isNaN(parseFloat(totalPrc)) && (
					<Localize
						args={{ totalPrice: formatPrice(totalPrc * 100) }}
						text={'gift.totalToPay'}
					>
						{text => <span className={styles.total}>{text}</span>}
					</Localize>
				)}
			</div>
		</div>
	);
}
