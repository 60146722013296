import React, { Fragment } from 'react';
import { ErrorMessage } from '../error_message';
import Inputs from '../inputs';
import {
	EMAIL_IN_USE_ERROR,
	Link,
	Localize,
	MISSING_PASSWORD_ERROR,
	NETWORK_ERROR,
	SocialButton,
	WRONG_PASSWORD_ERROR
} from '@planity/components';
import { breakpoints, colors, fontSize, fontWeight } from '@planity/theme';
import { useMachine } from '@xstate/react';
import {
	PASSWORD_CHANGE,
	REAUTH_WITH_FACEBOOK,
	RESET,
	SUBMIT
} from './constants';
import { createPasswordMachine } from './machine';
import { INVALID_PASSWORD_ERROR } from './constants';
import { Button, Card, InputPassword } from '@planity/ui';
import { useTranslation } from '@planity/localization';

const MISSING_NEW_PASSWORD_ERROR = 'MISSING_NEW_PASSWORD_ERROR';
const INVALID_NEW_PASSWORD_ERROR = 'auth/weak-password';
const WRONG_CONFIRMATION_PASSWORD = 'WRONG_CONFIRMATION_PASSWORD';
const SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG';
const USER_MISMATCH = 'auth/user-mismatch';
const POP_UP_BLOCKED = 'auth/popup-blocked';

const WHITELISTED_ERRORS = [
	MISSING_PASSWORD_ERROR,
	MISSING_NEW_PASSWORD_ERROR,
	INVALID_NEW_PASSWORD_ERROR,
	WRONG_PASSWORD_ERROR,
	NETWORK_ERROR,
	WRONG_CONFIRMATION_PASSWORD,
	INVALID_PASSWORD_ERROR,
	SOMETHING_WENT_WRONG,
	POP_UP_BLOCKED,
	USER_MISMATCH,
	EMAIL_IN_USE_ERROR
].map(e => `passwordChangeForm.errors.${e}`);

export const CreateAPasswordForm = ({ callback, user }) => {
	const [current, dispatch] = useMachine(
		createPasswordMachine({ email: user.email, callback })
	);
	const { context, value: machineState } = current;
	const { password, error, pending } = context;
	const { t } = useTranslation();
	return (
		<Fragment>
			<ErrorMessage
				defaultMessage={'passwordChangeForm.errors.defaultError'}
				error={error && `passwordChangeForm.errors.${error}`}
				whitelist={WHITELISTED_ERRORS}
			/>
			<Card
				hasTitleInside
				id='profile-create-password-title'
				title={t('myAccount.myProfile.title.CREATE_PASSWORD')}
			>
				<div>
					{machineState === 'askingUserToReauthenticateWithFacebook' ||
					machineState === 'reauthenticatingUserWithFacebook' ? (
						<Fragment>
							<div css={styles.text}>
								<Localize text={'passwordChangeForm.texts.pleaseReauth'} />
							</div>
							<div>
								<SocialButton
									css={styles.socialButton}
									isAlone
									isDisabled={pending}
									provider={'facebook'}
									onPress={() => dispatch({ type: REAUTH_WITH_FACEBOOK })}
								/>
								<div css={styles.bottomLink}>
									<Link
										localizedText={`passwordChangeForm.placeholders.cancelPasswordCreation`}
										onPress={() => dispatch({ type: RESET })}
									/>
								</div>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<div css={styles.text}>
								<Localize
									text={'passwordChangeForm.placeholders.newPassword'}
								/>
							</div>
							<Inputs css={styles.passwordContainer}>
								<InputPassword
									id={'create-password-input'}
									placeholder={t('passwordChangeForm.placeholders.newPassword')}
									value={password}
									onChange={passwordInput =>
										dispatch({
											type: PASSWORD_CHANGE,
											password: passwordInput
										})
									}
								/>
							</Inputs>
							<Button
								id={'create-password-submit-button'}
								isDisabled={!!pending}
								label={pending ? t('actions.submitting') : t('actions.submit')}
								onClick={() => dispatch({ type: SUBMIT })}
							/>
						</Fragment>
					)}
				</div>
			</Card>
		</Fragment>
	);
};

const styles = {
	title: {
		fontSize: fontSize.extraRegular,
		fontWeight: fontWeight.bold,
		marginBottom: 11,
		marginLeft: 15,
		[breakpoints.tabletQuery]: {
			marginBottom: 5,
			marginLeft: 0
		}
	},
	text: {
		marginBottom: 2
	},
	content: {
		backgroundColor: colors.white,
		padding: '22px 15px 24px 15px',
		[breakpoints.tabletQuery]: {
			borderRadius: 4,
			boxShadow: '0 1px 4px 1px rgba(0, 0, 0, 0.08)',
			padding: '16px 29px 24px 29px'
		}
	},
	passwordContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		margin: '10px 0',
		flex: 1,
		[breakpoints.tabletQuery]: {
			flexDirection: 'row',
			justifyContent: 'space-between'
		}
	},
	passwordInput: {
		marginRight: 30,
		marginBottom: 14,
		[breakpoints.tabletQuery]: {
			marginBottom: 0
		}
	},
	submitButton: {
		width: '100%',
		[breakpoints.tabletQuery]: {
			width: 'auto'
		}
	},
	socialButton: {
		marginBottom: 16
	},
	bottomLink: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'center',
		fontSize: 16,
		lineHeight: 1.13
	}
};
