import { noop } from '@planity/helpers';
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react';
import TabsContext from './context';
import { Localize } from '@planity/components';
import classNames from 'classnames/bind';
import styles from './list.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { isNativeApp } from '@planity/webview';
import { scrollTo } from '@planity/helpers/browser';
import { isMobile } from '@planity/theme/breakpoints';

export function List({
	tabs,
	hidden = false,
	hideIfOnlyOneTab = false,
	onTabChanged = noop,
	executeScrollIntoView = true,
	className
}) {
	useStyles(styles);
	const classes = classNames.bind(styles);
	const { current, setCurrent } = useContext(TabsContext);
	const [isScrolled, setIsScrolled] = useState(false);

	useEffect(() => {
		if (process.env.BROWSER) {
			window.addEventListener('scroll', handleScroll);
			return () => {
				window.removeEventListener('scroll', handleScroll);
			};
		}
	}, []);

	const handleScroll = () => {
		setIsScrolled(executeScrollIntoView ? window.scrollY >= 64 : false);
	};

	const has3TabsOrLess = tabs?.length <= 3;
	const onTabClick = useCallback((tab, i) => {
		setCurrent(tab.id);
		onTabChanged(tab, i);
	}, []);

	const onRefChange = node => {
		requestAnimationFrame(() => {
			// setTimeout is needed for chrome/android...
			// without it, only one scroll is executed
			if (isNativeApp || isMobile()) scrollTo({ top: 0, animated: isMobile() });
			if (node) {
				setTimeout(
					() =>
						node.scrollIntoView({
							behavior: 'smooth'
						}),
					0
				);
			}
		});
	};

	const tabRefs = useRef({});
	const previousCurrent = usePrevious(current);
	useEffect(() => {
		if (
			executeScrollIntoView &&
			previousCurrent &&
			previousCurrent !== current
		) {
			onRefChange(tabRefs.current[current]);
		}
	}, [previousCurrent, current, executeScrollIntoView]);

	if (hidden || (tabs.length === 1 && hideIfOnlyOneTab)) return null;
	return (
		<div className={classes({ container: true }, className)}>
			<div
				className={classes({
					tabs: true,
					has3TabsOrLess,
					fixed: isNativeApp || isScrolled,
					top: isNativeApp
				})}
			>
				{tabs.map((tab, i) => (
					<div
						aria-current={i === current || tab.id === current}
						className={styles.item}
						id={tab.localizedText || tab.text}
						key={`tab-${i}`}
						ref={node => (tabRefs.current[tab.id] = node)}
						onClick={() => onTabClick(tab, i)}
					>
						{tab.localizedText ? (
							<Localize text={tab.localizedText} />
						) : (
							tab.text
						)}
					</div>
				))}
			</div>
		</div>
	);
}

// Hook
function usePrevious(value) {
	// The ref object is a generic container whose current property is mutable ...
	// ... and can hold any value, similar to an instance property on a class
	const ref = useRef();

	// Store current value in ref
	useEffect(() => {
		ref.current = value;
	}, [value]); // Only re-run if value changes

	// Return previous value (happens before update in useEffect above)
	return ref.current;
}
