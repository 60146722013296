import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React, { forwardRef } from 'react';
import styles from './page_section.module.scss';

export const PageSection = forwardRef(
	({ children, className, ...props }, ref) => {
		const classes = classNames.bind(styles)(
			{
				pageSection: true
			},
			className
		);
		useStyles(styles);
		return (
			<section className={classes} ref={ref} {...props}>
				{children}
			</section>
		);
	}
);
