import React from 'react';
import { noop } from '@planity/helpers';
import { Icon } from '@planity/ui';
import { isNativeApp, sendToUserApp } from '@planity/webview';

export default function Address({
	title,
	street,
	postalCode,
	locality,
	country,
	distance,
	className,
	location,
	shouldOpenMap,
	upperCase,
	isSearch
}) {
	const mapLink = googleMapsLink(location);
	const getDistance = () => {
		return (
			!!distance && (
				<span id='address-distance'>
					{(isSearch ? '(' : '') +
						formatDistance(distance) +
						(isSearch ? ')' : '')}
					&nbsp;
				</span>
			)
		);
	};

	/* 'street' is set in Admin when creating or modifying a business
	 - For german businesses, address should have a comma after street number because order is reversed compared to french addresses. E.g. : France: '5 StreetName, postalCode' VS Germany : 'StreetName 5, postalCode'
	 TO fix this "for now", we remove the extra comma in the 'street', 
	 Result: no double commas in german adress and no extra comma after the number.
	 N.B.: the root problem is in Admin !!!!
	 */

	const formattedStreet = street?.replace(',', '');

	const countryWithDefault = country || 'France';

	const schemaProps = isSearch
		? {}
		: {
				itemProp: 'address',
				itemScope: true,
				itemType: 'https://schema.org/PostalAddress'
		  };

	return street || postalCode || locality ? (
		<span
			className={className}
			id='address-map-link'
			onClick={
				!(shouldOpenMap && mapLink)
					? noop
					: () => {
							if (isNativeApp) {
								sendToUserApp({
									type: 'OPEN_MAP',
									payload: {
										longitude: location?.lng,
										latitude: location?.lat,
										title
									}
								});
							} else window.open(mapLink, '_blank');
					  }
			}
			{...schemaProps}
		>
			{!isSearch && <Icon icon='Pin' size='medium' />}
			<p>
				{!isSearch && getDistance()}
				<span id='address-value'>
					{!isSearch && (
						<>
							<meta
								content={
									upperCase ? formattedStreet?.toUpperCase() : formattedStreet
								}
								itemProp='streetAddress'
							/>
							<meta
								content={upperCase ? postalCode.toUpperCase() : postalCode}
								itemProp='postalCode'
							/>
							<meta
								content={upperCase ? locality.toUpperCase() : locality}
								itemProp='addressLocality'
							/>
							<meta
								content={
									upperCase
										? countryWithDefault.toUpperCase()
										: countryWithDefault
								}
								itemProp='addressCountry'
							/>
						</>
					)}
					<span>
						{upperCase ? formattedStreet?.toUpperCase() : formattedStreet}
					</span>
					{!!(postalCode || locality) && <span>{`,`}&nbsp;</span>}
					<span>{upperCase ? postalCode.toUpperCase() : postalCode}&nbsp;</span>
					<span>{upperCase ? locality.toUpperCase() : locality}</span>
					{isSearch && <span>&nbsp;{getDistance()}</span>}
				</span>
			</p>
		</span>
	) : null;
}

const formatDistance = distance => {
	// TODO localize
	if (!distance) return '0m';
	if (distance < 1000) return `${distance}m`;
	return `${Math.round(distance / 100) / 10}km`;
};

const googleMapsLink = location => {
	if (location) {
		return `https://www.google.com/maps?daddr=${location.lat},${location.lng}`;
	} else {
		return null;
	}
};
