// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".how_to_modal-module_howToModal-vOvyv{margin:0;padding:52px 16px 32px;background-color:var(--white)}@media(min-width: 768px){.how_to_modal-module_howToModal-vOvyv{height:100%;padding:0 32px 48px;display:flex;flex-direction:row}}.how_to_modal-module_howToModal-vOvyv .how_to_modal-module_openingHours-wANUN{margin-top:33px}", "",{"version":3,"sources":["webpack://./../../packages/components/click_and_collect/how_to_modal.module.scss"],"names":[],"mappings":"AAGA,sCACC,QAAA,CACA,sBAAA,CACA,6BAAA,CAEA,yBALD,sCAME,WAAA,CACA,mBAAA,CACA,YAAA,CACA,kBAAA,CAAA,CAGD,8EACC,eAAA","sourcesContent":["@use '@planity/ui/styles/utilities/mixins.scss';\n@use '@planity/ui/styles/utilities/variables.scss';\n\n.howToModal {\n\tmargin: 0;\n\tpadding: 52px 16px 32px;\n\tbackground-color: var(--white);\n\n\t@media (min-width: variables.$tablet) {\n\t\theight: 100%;\n\t\tpadding: 0 32px 48px;\n\t\tdisplay: flex;\n\t\tflex-direction: row;\n\t}\n\n\t.openingHours {\n\t\tmargin-top: 33px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"howToModal": "how_to_modal-module_howToModal-vOvyv",
	"openingHours": "how_to_modal-module_openingHours-wANUN"
};
module.exports = ___CSS_LOADER_EXPORT___;
