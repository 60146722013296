// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon_button-module_iconButton-vWDjZ{width:48px;height:48px;position:relative;display:flex;align-items:center;justify-content:center;border-radius:var(--border-radius-medium);background-color:var(--white)}.icon_button-module_iconButton-vWDjZ .icon_button-module_icon-tt\\+co{position:absolute}", "",{"version":3,"sources":["webpack://./../../packages/ui/uikit/button/icon_button/icon_button.module.scss"],"names":[],"mappings":"AAAA,qCACC,UAAA,CACA,WAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,yCAAA,CACA,6BAAA,CAEA,qEACC,iBAAA","sourcesContent":[".iconButton {\n\twidth: 48px;\n\theight: 48px;\n\tposition: relative;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tborder-radius: var(--border-radius-medium);\n\tbackground-color: var(--white);\n\n\t.icon {\n\t\tposition: absolute;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconButton": "icon_button-module_iconButton-vWDjZ",
	"icon": "icon_button-module_icon-tt+co"
};
module.exports = ___CSS_LOADER_EXPORT___;
