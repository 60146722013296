// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".address-module_address-08zJO{display:flex;justify-content:space-between;align-items:center}.address-module_address-08zJO .address-module_addressInfo-sMSsj{font:var(--regular) var(--body-3)}@media(min-width: 768px){.address-module_address-08zJO .address-module_addressInfo-sMSsj{font:var(--regular) var(--body-4)}}.address-module_address-08zJO .address-module_addressInfo-sMSsj .address-module_address-08zJO{color:var(--grey-600)}.address-module_address-08zJO .address-module_name-dyj\\+E{font:var(--medium) var(--body-3)}@media(min-width: 768px){.address-module_address-08zJO .address-module_name-dyj\\+E{font:var(--medium) var(--body-4)}}.address-module_address-08zJO .address-module_address-08zJO{padding-right:12px}", "",{"version":3,"sources":["webpack://./../../packages/components/click_and_collect/address.module.scss"],"names":[],"mappings":"AAEA,8BACC,YAAA,CACA,6BAAA,CACA,kBAAA,CAEA,gEACC,iCAAA,CACA,yBAFD,gEAGE,iCAAA,CAAA,CAGD,8FACC,qBAAA,CAIF,0DACC,gCAAA,CACA,yBAFD,0DAGE,gCAAA,CAAA,CAIF,4DACC,kBAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.address {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\n\t.addressInfo {\n\t\tfont: var(--regular) var(--body-3);\n\t\t@media (min-width: styles.$tablet) {\n\t\t\tfont: var(--regular) var(--body-4);\n\t\t}\n\n\t\t.address {\n\t\t\tcolor: var(--grey-600);\n\t\t}\n\t}\n\n\t.name {\n\t\tfont: var(--medium) var(--body-3);\n\t\t@media (min-width: styles.$tablet) {\n\t\t\tfont: var(--medium) var(--body-4);\n\t\t}\n\t}\n\n\t.address {\n\t\tpadding-right: 12px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"address": "address-module_address-08zJO",
	"addressInfo": "address-module_addressInfo-sMSsj",
	"name": "address-module_name-dyj+E"
};
module.exports = ___CSS_LOADER_EXPORT___;
