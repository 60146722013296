import { noop } from '@planity/helpers';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Input } from '@planity/ui';
import { Localize } from '@planity/components';
import styles from './address_page.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';

export function FormControlledTextInput({
	errors,
	control,
	values,
	setValues,
	field,
	localizedError,
	localizedPlaceholder,
	customStyle,
	rules = { required: true },
	label,
	icon,
	onBlur = noop,
	onFocus = noop
}) {
	useStyles(styles);

	return (
		<div className={customStyle}>
			<Controller
				control={control}
				defaultValue=''
				name={field}
				render={({ onChange }) => (
					<Input
						icon={icon}
						label={label}
						name={field}
						placeholder={localizedPlaceholder}
						type='text'
						value={values[field]}
						onBlur={event => onBlur(event)}
						onChange={value => {
							setValues(old => ({ ...old, [field]: value }));
							onChange(value);
						}}
						onFocus={event => onFocus(event)}
					/>
				)}
				rules={rules}
			/>
			{errors && errors[field] && (
				<p className={styles.errorMessage}>
					{<Localize text={localizedError} />}
				</p>
			)}
		</div>
	);
}
