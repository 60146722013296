// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (min-width: 1080px){.page_section-module_pageSection-qej1b{max-width:100%}}", "",{"version":3,"sources":["webpack://./app/components/layout/page_section.module.scss"],"names":[],"mappings":"AAGC,2CADD,uCAEE,cAAA,CAAA","sourcesContent":["@use '@planity/ui/styles/utilities/variables';\n\n.pageSection {\n\t@media only screen and (min-width: variables.$desktop) {\n\t\tmax-width: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"pageSection": "page_section-module_pageSection-qej1b"
};
module.exports = ___CSS_LOADER_EXPORT___;
