import { BookAppointmentTitle } from '@planity/components';
import { GIFT_VOUCHER_BLOC, useTheme } from '@planity/context/theme_context';
import { useTranslation } from '@planity/localization';
import {
	GiftVoucherChoice,
	GiftVoucherCustomisation,
	IconButton,
	Image
} from '@planity/ui';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useState } from 'react';
import styles from './personalization.module.scss';
import { isMobile } from '@planity/theme/breakpoints';

const newVariantNumber = 16;

export default ({
	templateWasChosen,
	onComplete,
	onTemplateChose,
	selectedTemplate,
	templateContainerRef,
	continueButtonRef,
	personalizationContainerRef,
	colors,
	reinjectData,
	giftMessage = '',
	isPending,
	onTemplateValidated,
	variant
}) => {
	useStyles(styles);
	const { t } = useTranslation();
	const { isDark: hasDarkBackground } = useTheme();
	const isDark = hasDarkBackground?.[GIFT_VOUCHER_BLOC];
	const [targetMail, setTargetMail] = useState('');
	const [displayLightBox, setDisplayLightBox] = useState(false);
	const [message, setMessage] = useState(giftMessage);
	const [messageIsMin, setMessageIsMin] = useState(reinjectData);

	const giftVouchersPlanity = [
		{
			id: 0,
			url: t('bookAppointment.giftVoucher.preview.black'),
			name: t('bookAppointment.giftVoucher.theme.black'),
			color: 'black'
		},
		{
			id: 1,
			url: t('bookAppointment.giftVoucher.preview.blue'),
			name: t('bookAppointment.giftVoucher.theme.blue'),
			color: 'blue'
		},
		{
			id: 2,
			url: t('bookAppointment.giftVoucher.preview.red'),
			name: t('bookAppointment.giftVoucher.theme.red'),
			color: 'red'
		}
	];
	const giftVouchersWhiteLabel = [
		{
			id: 0,
			url: t('bookAppointment.giftVoucher.preview.whitelabel.black'),
			name: t('bookAppointment.giftVoucher.theme.black'),
			color: 'black'
		},
		{
			id: 1,
			url: t('bookAppointment.giftVoucher.preview.whitelabel.blue'),
			name: t('bookAppointment.giftVoucher.theme.blue'),
			color: 'blue'
		},
		{
			id: 2,
			url: t('bookAppointment.giftVoucher.preview.whitelabel.red'),
			name: t('bookAppointment.giftVoucher.theme.red'),
			color: 'red'
		}
	];

	let newGiftVouchersPlanity = [];
	let newGiftVouchersWhiteLabel = [];

	for (let i = 1; i <= newVariantNumber; i++) {
		newGiftVouchersPlanity.push({
			id: i - 1,
			url: t(`bookAppointment.giftVoucher.preview.variant_${i}`),
			pdfUrl: t(`bookAppointment.giftVoucher.pdfPreview.variant_${i}`),
			name: t(`bookAppointment.giftVoucher.theme.variant_${i}`),
			color: `variant_${i}`
		});
		newGiftVouchersWhiteLabel.push({
			id: i - 1,
			url: t(`bookAppointment.giftVoucher.preview.whitelabel.variant_${i}`),
			pdfUrl: t(
				`bookAppointment.giftVoucher.pdfPreview.whitelabel.variant_${i}`
			),
			name: t(`bookAppointment.giftVoucher.theme.variant_${i}`),
			color: `variant_${i}`
		});
	}

	const giftVouchers = process.env.WIDGET
		? giftVouchersWhiteLabel
		: giftVouchersPlanity;
	const newGiftVouchers = process.env.WIDGET
		? newGiftVouchersWhiteLabel
		: newGiftVouchersPlanity;
	const selectedGiftVouchers = colors
		? newGiftVouchers.filter(giftCard =>
				colors.find(color => color === giftCard.color)
		  )
		: newGiftVouchers;

	const openLightBox = id => {
		setDisplayLightBox(id);
	};

	const complete = isSkip => {
		setMessageIsMin(true);
		isSkip && setMessage('');
		onComplete({
			targetMail,
			message: isSkip ? '' : message
		});
	};

	const classes = classNames.bind(styles)({
		personalization: true
	});

	return (
		<div ref={templateContainerRef}>
			<BookAppointmentTitle
				index={'2. '}
				isDark={isDark}
				text={'bookAppointment.choseGiftVoucher'}
			/>
			<GiftVoucherChoice
				continueButtonRef={continueButtonRef}
				expand={openLightBox}
				isPending={isPending}
				items={selectedGiftVouchers}
				reinjectData={reinjectData}
				selectedTemplate={selectedTemplate}
				templateWasChosen={templateWasChosen}
				variant={variant}
				onTemplateChose={onTemplateChose}
				onTemplateValidated={onTemplateValidated}
			/>
			{templateWasChosen && (
				<div ref={personalizationContainerRef}>
					<BookAppointmentTitle
						index={'3. '}
						isDark={isDark}
						text={'bookAppointment.personalizeTemplate'}
					/>
					<GiftVoucherCustomisation
						isPending={isPending}
						message={message}
						messageIsMinified={messageIsMin}
						setMessageIsMin={setMessageIsMin}
						onChange={setMessage}
						onClick={() => complete()}
						onSkip={() => complete(true)}
					/>
				</div>
			)}
			{displayLightBox !== false && (
				<div className={classes}>
					<div className={styles.lightBoxContainer}>
						<div className={styles.container}>
							<IconButton
								className={styles.icon}
								icon='Close'
								label='Fermer'
								size='small'
								onClick={() => setDisplayLightBox(false)}
								onMouseDown={e => e.preventDefault()} // Prevent focus state on click
							/>
							<Image
								alt='template'
								className={styles.lightBoxImage}
								isCloudinary
								style={
									isMobile()
										? { width: '100%', borderRadius: 8 }
										: { height: '100%', borderRadius: 8 }
								}
								url={newGiftVouchers[displayLightBox].pdfUrl}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
