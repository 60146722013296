// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".date_picker_field-module_DatePickerField-6Z3T5{position:relative;flex:1;width:100%}.date_picker_field-module_DatePickerField-6Z3T5 .date_picker_field-module_calendar-IZQuc{display:none;position:absolute;top:calc(100% + 20px);width:359px;z-index:2}@media(min-width: 768px)and (max-width: 1079px){.date_picker_field-module_DatePickerField-6Z3T5 .date_picker_field-module_calendar-IZQuc{width:340px}}@media(max-width: 767px){.date_picker_field-module_DatePickerField-6Z3T5 .date_picker_field-module_calendar-IZQuc{top:100%;width:100%}}.date_picker_field-module_DatePickerField-6Z3T5 .date_picker_field-module_isDatePickerOpen-S7VMR{display:block}", "",{"version":3,"sources":["webpack://./../../packages/ui/components/search/date_picker_field/date_picker_field.module.scss"],"names":[],"mappings":"AAGA,gDACC,iBAAA,CACA,MAAA,CACA,UAAA,CACA,yFACC,YAAA,CACA,iBAAA,CACA,qBAAA,CACA,WAAA,CACA,SAAA,CACA,gDAND,yFAOE,WAAA,CAAA,CAED,yBATD,yFAUE,QAAA,CACA,UAAA,CAAA,CAIF,iGACC,aAAA","sourcesContent":["@use '@planity/ui/styles/utilities/mixins';\n@use '@planity/ui/styles/utilities/variables';\n\n.DatePickerField {\n\tposition: relative;\n\tflex: 1;\n\twidth: 100%;\n\t.calendar {\n\t\tdisplay: none;\n\t\tposition: absolute;\n\t\ttop: calc(100% + 20px);\n\t\twidth: 359px;\n\t\tz-index: 2;\n\t\t@media (min-width: #{variables.$tablet }) and (max-width: #{variables.$desktop - 1px }) {\n\t\t\twidth: 340px;\n\t\t}\n\t\t@media (max-width: #{variables.$tablet - 1px}) {\n\t\t\ttop: 100%;\n\t\t\twidth: 100%;\n\t\t}\n\t}\n\n\t.isDatePickerOpen {\n\t\tdisplay: block;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"DatePickerField": "date_picker_field-module_DatePickerField-6Z3T5",
	"calendar": "date_picker_field-module_calendar-IZQuc",
	"isDatePickerOpen": "date_picker_field-module_isDatePickerOpen-S7VMR"
};
module.exports = ___CSS_LOADER_EXPORT___;
