import React from 'react';

export function Person() {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M10.1667 5.33334C10.1667 6.52996 9.19661 7.5 7.99999 7.5C6.80338 7.5 5.83333 6.52996 5.83333 5.33334C5.83333 4.13672 6.80338 3.16667 7.99999 3.16667C9.19661 3.16667 10.1667 4.13672 10.1667 5.33334Z'
				stroke='#625DF5'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M4.565 12.8333H11.435C12.1963 12.8333 12.7827 12.1788 12.4272 11.5056C11.9042 10.5154 10.712 9.33333 8 9.33333C5.288 9.33333 4.09577 10.5154 3.57282 11.5056C3.21731 12.1788 3.80372 12.8333 4.565 12.8333Z'
				stroke='#625DF5'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
