// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".spinner-module_spinner-I0If4{width:24px;height:24px;overflow:visible;animation:spinner-module_rotate-EPPSU 2s linear infinite}.spinner-module_spinner-I0If4>svg{overflow:visible}.spinner-module_spinner-I0If4>circle:nth-child(1){overflow:visible;animation:spinner-module_dash-rnLbn 1.5s ease-in-out infinite}.spinner-module_spinner-I0If4>circle:nth-child(2){overflow:visible;opacity:.5}@keyframes spinner-module_rotate-EPPSU{100%{transform:rotate(360deg)}}@keyframes spinner-module_dash-rnLbn{0%{stroke-dasharray:1,90;stroke-dashoffset:0}50%{stroke-dasharray:54,90;stroke-dashoffset:-21}100%{stroke-dasharray:54,90;stroke-dashoffset:-77.4}}", "",{"version":3,"sources":["webpack://./../../packages/ui/uikit/indication/spinner/spinner.module.scss"],"names":[],"mappings":"AAAA,8BACC,UAAA,CACA,WAAA,CAKA,gBAAA,CACA,wDAAA,CAJA,kCACC,gBAAA,CAKD,kDACC,gBAAA,CACA,6DAAA,CAGD,kDACC,gBAAA,CACA,UAAA,CAIF,uCACC,KACC,wBAAA,CAAA,CAIF,qCACC,GACC,qBAAA,CACA,mBAAA,CAED,IACC,sBAAA,CACA,qBAAA,CAED,KACC,sBAAA,CACA,uBAAA,CAAA","sourcesContent":[".spinner {\n\twidth: 24px;\n\theight: 24px;\n\n\t& > svg {\n\t\toverflow: visible;\n\t}\n\toverflow: visible;\n\tanimation: rotate 2s linear infinite;\n\n\t& > circle:nth-child(1) {\n\t\toverflow: visible;\n\t\tanimation: dash 1.5s ease-in-out infinite;\n\t}\n\n\t& > circle:nth-child(2) {\n\t\toverflow: visible;\n\t\topacity: 0.5;\n\t}\n}\n\n@keyframes rotate {\n\t100% {\n\t\ttransform: rotate(360deg);\n\t}\n}\n\n@keyframes dash {\n\t0% {\n\t\tstroke-dasharray: 1, 90;\n\t\tstroke-dashoffset: 0;\n\t}\n\t50% {\n\t\tstroke-dasharray: 54, 90;\n\t\tstroke-dashoffset: -21;\n\t}\n\t100% {\n\t\tstroke-dasharray: 54, 90;\n\t\tstroke-dashoffset: -77.4;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "spinner-module_spinner-I0If4",
	"rotate": "spinner-module_rotate-EPPSU",
	"dash": "spinner-module_dash-rnLbn"
};
module.exports = ___CSS_LOADER_EXPORT___;
