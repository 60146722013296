import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './card_logo.scss';
import * as library from '@planity/ui';
import classNames from 'classnames/bind';

export function CardLogo({ brand = 'Visa', className }) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		icon: true,
		[className]: className !== undefined
	});

	const RenderedIcon = library[brand];
	if (process.env.NODE_ENV === 'development') {
		if (!CardLogo) {
			console.warn('Icon type not known : ' + brand);
		}
	}
	if (!RenderedIcon) {
		return null;
	}
	return (
		<span className={classes}>
			<RenderedIcon />
		</span>
	);
}
