// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".payment_warning_modal_paymentWarningModal-ZWbKV{padding:24px 16px 32px}@media(min-width: 768px){.payment_warning_modal_paymentWarningModal-ZWbKV{padding:24px 32px 32px}}.payment_warning_modal_paymentWarningModal-ZWbKV .payment_warning_modal_title-frLF1{font:var(--medium) var(--body-1);margin-bottom:8px}@media(min-width: 768px){.payment_warning_modal_paymentWarningModal-ZWbKV .payment_warning_modal_title-frLF1{font:var(--semibold) var(--title-3)}}.payment_warning_modal_paymentWarningModal-ZWbKV .payment_warning_modal_message-5zgPv{font:var(--regular) var(--body-3);padding-bottom:24px;color:var(--grey-600)}@media(min-width: 768px){.payment_warning_modal_paymentWarningModal-ZWbKV .payment_warning_modal_message-5zgPv{padding-bottom:32px;font:var(--regular) var(--body-4)}}", "",{"version":3,"sources":["webpack://./../../packages/components/business_booking/online_payment/payment_warning_modal.scss"],"names":[],"mappings":"AAEA,iDACC,sBAAA,CAEA,yBAHD,iDAIE,sBAAA,CAAA,CAGD,oFACC,gCAAA,CACA,iBAAA,CAEA,yBAJD,oFAKE,mCAAA,CAAA,CAIF,sFACC,iCAAA,CACA,mBAAA,CACA,qBAAA,CAEA,yBALD,sFAME,mBAAA,CACA,iCAAA,CAAA","sourcesContent":["@use '@planity/ui/styles/utilities/variables.scss';\n\n.paymentWarningModal {\n\tpadding: 24px 16px 32px;\n\n\t@media (min-width: variables.$tablet) {\n\t\tpadding: 24px 32px 32px;\n\t}\n\n\t.title {\n\t\tfont: var(--medium) var(--body-1);\n\t\tmargin-bottom: 8px;\n\n\t\t@media (min-width: variables.$tablet) {\n\t\t\tfont: var(--semibold) var(--title-3);\n\t\t}\n\t}\n\n\t.message {\n\t\tfont: var(--regular) var(--body-3);\n\t\tpadding-bottom: 24px;\n\t\tcolor: var(--grey-600);\n\n\t\t@media (min-width: variables.$tablet) {\n\t\t\tpadding-bottom: 32px;\n\t\t\tfont: var(--regular) var(--body-4);\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"paymentWarningModal": "payment_warning_modal_paymentWarningModal-ZWbKV",
	"title": "payment_warning_modal_title-frLF1",
	"message": "payment_warning_modal_message-5zgPv"
};
module.exports = ___CSS_LOADER_EXPORT___;
