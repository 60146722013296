import React from 'react';
import { noop } from '@planity/helpers';
import { Icon } from '@planity/ui';

import classNames from 'classnames/bind';
import styles from './stars.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';

export default function Stars({
	value,
	isDisabled,
	hoveredIndex,
	onMouseEnter,
	onClick,
	withMargin
}) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		stars: true,
		withMargin
	});
	const stars = [];
	for (let i = 1; i < 6; i++) {
		const star = hoveredIndex ? (
			i <= hoveredIndex ? (
				<Icon icon='FullStar' size={'medium'} />
			) : (
				<Icon className={styles.isEmpty} icon='EmptyStar' size={'medium'} />
			)
		) : i <= value ? (
			<Icon icon='FullStar' size={'medium'} />
		) : !Number.isInteger(value) && i === Math.ceil(value) ? (
			<Icon icon='HalfEmptyStar' size={'medium'} />
		) : (
			<Icon className={styles.isEmpty} icon='EmptyStar' size={'medium'} />
		);

		stars.push(
			<Star
				className={classes}
				index={i}
				isDisabled={isDisabled}
				key={i}
				star={star}
				onClick={onClick}
				onMouseEnter={onMouseEnter}
			/>
		);
	}
	return stars;
}

const Star = ({
	index,
	star,
	onClick,
	isDisabled,
	onMouseEnter,
	className
}) => (
	<div
		className={className}
		id='review-star'
		onClick={isDisabled ? noop : () => onClick(index)}
		onMouseEnter={() => {
			if (!isDisabled) {
				onMouseEnter(index);
			}
		}}
	>
		{star}
	</div>
);
