// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".confirm_cancellation_modal_buttons-module_confirmButton-tdaNL{white-space:normal}@media(max-width: 767px){.confirm_cancellation_modal_buttons-module_confirmButton-tdaNL{margin:8px 0 20px}}@media only screen and (max-width: 767px){.confirm_cancellation_modal_buttons-module_cancelButton-wMbHc{grid-area:2/1/3/2}}", "",{"version":3,"sources":["webpack://./../../packages/components/my_account_page/appointment_preview/confirm_cancellation_modal/confirm_cancellation_modal_buttons/confirm_cancellation_modal_buttons.module.scss"],"names":[],"mappings":"AAEA,+DACC,kBAAA,CACA,yBAFD,+DAGE,iBAAA,CAAA,CAKD,0CADD,8DAEE,iBAAA,CAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.confirmButton {\n\twhite-space: normal;\n\t@media (max-width: #{styles.$tablet - 1px}) {\n\t\tmargin: 8px 0 20px;\n\t}\n}\n\n.cancelButton {\n\t@media only screen and (max-width: #{styles.$tablet - 1px}) {\n\t\tgrid-area: 2/1/3/2;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"confirmButton": "confirm_cancellation_modal_buttons-module_confirmButton-tdaNL",
	"cancelButton": "confirm_cancellation_modal_buttons-module_cancelButton-wMbHc"
};
module.exports = ___CSS_LOADER_EXPORT___;
