import { breakpoints } from '@planity/theme';
import { useAuth } from '@planity/components';
import React, { createRef } from 'react';
import UserBuyingItem from './user_buying_item';
import { useTranslation } from '@planity/localization';

export function UserBuyingsList({
	userBuyings,
	linkToBusiness,
	filterByBusiness
}) {
	const { userId } = useAuth();
	const [userBuyingsKeys] = Object.keys(userBuyings);
	const { t } = useTranslation();
	const productContainerRef = createRef();

	return (
		<div ref={productContainerRef}>
			<div css={styles.accountOrders}>
				<span css={styles.title} id='user-buyings-type-title'>
					{userBuyings[userBuyingsKeys]?.type === 'gift_voucher'
						? t('myAccount.myGiftVouchers')
						: t('myAccount.myOrders')}
				</span>

				{Object.entries(userBuyings)
					.filter(x =>
						filterByBusiness ? x[1].businessId === window.planity.key : x
					)
					.filter(x => !!x[1].type)
					.sort((a, b) => {
						let aTimestamp = a[1].boughtAt;
						let bTimestamp = b[1].boughtAt;

						if (aTimestamp < bTimestamp) return 1;
						else return -1;
					})
					.map(x => {
						const [userBuyingKey, userBuying] = x;
						return (
							<div css={[styles.buyingContainer]} key={userBuyingKey}>
								<UserBuyingItem
									buyingId={userBuyingKey}
									linkToBusiness={linkToBusiness}
									type={userBuying.type}
									userBuying={userBuying}
									userId={userId}
								/>
							</div>
						);
					})}
			</div>
		</div>
	);
}

const styles = {
	title: {
		display: 'flex',
		marginBottom: 12,
		font: 'var(--medium) var(--body-1)'
	},
	buyingContainer: {
		marginBottom: 12,
		[breakpoints.tabletQuery]: {
			marginBottom: 0
		}
	},
	backContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 15,
		paddingLeft: 15
	},
	backLabel: {
		fontSize: 14,
		color: '#979797',
		textDecoration: 'underline',
		margin: 0
	},
	accountOrders: {
		font: 'var(--regular) var(--body-1)',
		[breakpoints.tabletQuery]: {
			boxShadow: 'var(--shadow-regular)',
			backgroundColor: 'var(--white)',
			borderRadius: 'var(--border-radius-large)',
			border: '1px solid var(--grey-200)',
			padding: 32
		}
	}
};
