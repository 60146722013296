export const homeTablet = 720;
export const tablet = 768;
export const desktop = 1080;
export const largeDesktop = 1440;

export const homeTabletQuery = `@media all and (min-width: ${homeTablet}px)`;
export const homeDesktopQuery = `@media all and (min-width: ${largeDesktop}px)`;
export const simpleTabletQuery = `(min-width: ${tablet}px)`;
export const simpleDesktopQuery = `(min-width: ${desktop}px)`;
export const simpleLargeDesktopQuery = `(min-width: ${largeDesktop}px)`;
export const simpleLargeTabletQuery = `(max-width: ${desktop - 1}px)`;
export const tabletQuery = `@media all and ${simpleTabletQuery}`;
export const desktopQuery = `@media all and ${simpleDesktopQuery}`;
export const largeDesktopQuery = `@media all and ${simpleLargeDesktopQuery}`;
export const largeTabletQuery = `@media all and ${simpleLargeTabletQuery}`;
export const smallerThanIphoneSE = `@media all and (max-width: 321px) and (max-height: 570px)`;
export const smallerThanIphoneSE_landscape = `@media all and (max-width: 568px) and (max-height: 320px) and (orientation: landscape)`;
export const smallerThanTablet = `(max-width: ${tablet - 1}px)`;
export const phoneQuery = `@media all and ${smallerThanTablet}`;

export const mobile = `@media only screen and (max-width: ${tablet - 1}px)`;
export const mobileLandscape = `@media only screen and (orientation: landscape)`;

export const isMobile = () => {
	const match =
		process.env.BROWSER &&
		window.matchMedia &&
		window.matchMedia(`(max-width: ${tablet - 1}px)`);
	return match && match.matches;
};
