// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".answer_preview-module_reviewBody-eU6vz{width:100%}.answer_preview-module_reviewBody-eU6vz .answer_preview-module_titleComment-HvqiA{font:var(--medium) var(--body-4);padding-top:20px;margin-bottom:8px;color:var(--grey-700)}.answer_preview-module_reviewBody-eU6vz .answer_preview-module_titleAnswer-JdXlQ{font:var(--medium) var(--body-4);margin-top:16px;margin-bottom:4px;color:var(--grey-700)}.answer_preview-module_reviewBody-eU6vz .answer_preview-module_reviewBodyContainer-FGRFh{width:100%;border-radius:8px;padding:8px;position:relative;background-color:var(--grey-200);font:var(--regular) var(--body-4);color:var(--grey-700)}", "",{"version":3,"sources":["webpack://./../../packages/components/my_account_page/appointment_preview/review/answer_preview.module.scss"],"names":[],"mappings":"AAAA,wCACC,UAAA,CAEA,kFACC,gCAAA,CACA,gBAAA,CACA,iBAAA,CACA,qBAAA,CAGD,iFACC,gCAAA,CACA,eAAA,CACA,iBAAA,CACA,qBAAA,CAGD,yFACC,UAAA,CACA,iBAAA,CACA,WAAA,CACA,iBAAA,CACA,gCAAA,CACA,iCAAA,CACA,qBAAA","sourcesContent":[".reviewBody {\n\twidth: 100%;\n\n\t.titleComment {\n\t\tfont: var(--medium) var(--body-4);\n\t\tpadding-top: 20px;\n\t\tmargin-bottom: 8px;\n\t\tcolor: var(--grey-700);\n\t}\n\n\t.titleAnswer {\n\t\tfont: var(--medium) var(--body-4);\n\t\tmargin-top: 16px;\n\t\tmargin-bottom: 4px;\n\t\tcolor: var(--grey-700);\n\t}\n\n\t.reviewBodyContainer {\n\t\twidth: 100%;\n\t\tborder-radius: 8px;\n\t\tpadding: 8px;\n\t\tposition: relative;\n\t\tbackground-color: var(--grey-200);\n\t\tfont: var(--regular) var(--body-4);\n\t\tcolor: var(--grey-700);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reviewBody": "answer_preview-module_reviewBody-eU6vz",
	"titleComment": "answer_preview-module_titleComment-HvqiA",
	"titleAnswer": "answer_preview-module_titleAnswer-JdXlQ",
	"reviewBodyContainer": "answer_preview-module_reviewBodyContainer-FGRFh"
};
module.exports = ___CSS_LOADER_EXPORT___;
