import React from 'react';

export const LinkIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='25'
			height='24'
			viewBox='0 0 25 24'
			fill='none'
		>
			<path
				d='M17.55 13.25L18.8 12C20.4568 10.3431 20.4568 7.65682 18.8 5.99996C17.1431 4.34311 14.4568 4.34311 12.8 5.99996L11.55 7.24996'
				stroke='var(--grey-700)'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M8.05001 10.75L6.80001 12C5.14316 13.6569 5.14316 16.3431 6.80001 18C8.45687 19.6569 11.1432 19.6569 12.8 18L14.05 16.75'
				stroke='var(--grey-700)'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M15.05 9.75L10.55 14.25'
				stroke='var(--grey-700)'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
