export function BankNote() {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M3.95834 13.5417V6.25C4.37501 6.52778 5.33334 7.08333 6.66668 7.08333C8.33334 7.08333 11.25 5.625 13.3333 5.625C15 5.625 16.0417 6.66667 16.0417 6.66667V13.5434C15.625 13.4045 14.6667 13.125 13.3333 13.125C11.25 13.125 9.58334 14.375 7.50001 14.375C5.83334 14.375 4.5139 13.8194 3.95834 13.5417Z'
				stroke='currentColor'
				strokeLinejoin='round'
			/>
			<circle cx='10' cy='10' r='2' stroke='currentColor' />
		</svg>
	);
}
