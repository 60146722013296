export function UserSearch({ color }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='24'
			viewBox='0 -960 960 960'
			width='24'
			fill={color || 'currentColor'}
		>
			<path d='M440-492.309q-57.749 0-98.874-41.124-41.125-41.125-41.125-98.874 0-57.75 41.125-98.874 41.125-41.125 98.874-41.125 57.749 0 98.874 41.125 41.125 41.124 41.125 98.874 0 57.749-41.125 98.874-41.125 41.124-98.874 41.124Zm0-59.998q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5Zm405.23 479.69-96.154-96.154q-20.23 12.77-42.692 20-22.461 7.231-46.305 7.231-66.232 0-112.386-46.244-46.153-46.244-46.153-112.307 0-66.062 46.244-112.216 46.244-46.153 112.307-46.153 66.062 0 112.216 46.218Q818.46-366.025 818.46-300q0 23.923-7.231 46.384-7.23 22.462-20 42.692l96.154 96.154q8.307 8.307 8.5 20.884.192 12.577-8.5 21.269-8.692 8.692-21.077 8.692-12.384 0-21.076-8.692ZM660-201.54q41.615 0 70.038-28.423 28.423-28.423 28.423-70.038 0-41.615-28.423-70.038-28.423-28.423-70.038-28.423-41.615 0-70.038 28.423-28.423 28.423-28.423 70.038 0 41.615 28.423 70.038 28.423 28.423 70.038 28.423Zm-519.999-46.923V-276q0-29.769 15.654-54.731 15.654-24.961 42.961-38.807 47.154-23.692 113.654-42.077 66.5-18.384 145.654-15.307-8.923 14.153-15.491 29.146t-10.431 31.083q-65.77.769-119.693 17.423-53.924 16.654-85.539 33.269-12.693 6.154-19.731 16.872Q200-288.411 200-276v28.307h227.386q3.462 15.846 9.461 31.038 6 15.192 14.154 28.961H200.769q-25.307 0-43.037-17.731-17.731-17.73-17.731-43.037ZM440-632.307Zm-12.614 384.614Z' />
		</svg>
	);
}
