// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-module_login-0j--B{height:100%}.login-module_login-0j--B .login-module_spinner-Fzsqd{padding:20px 0;display:flex;justify-content:center;min-height:95vh}", "",{"version":3,"sources":["webpack://./../../packages/components/authentication/forms/login/login.module.scss"],"names":[],"mappings":"AAAA,0BACC,WAAA,CACA,sDACC,cAAA,CACA,YAAA,CACA,sBAAA,CACA,eAAA","sourcesContent":[".login {\n\theight: 100%;\n\t.spinner {\n\t\tpadding: 20px 0;\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\tmin-height: 95vh;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": "login-module_login-0j--B",
	"spinner": "login-module_spinner-Fzsqd"
};
module.exports = ___CSS_LOADER_EXPORT___;
