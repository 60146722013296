import React from 'react';

export const GR = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_11534_5709)'>
				<mask
					id='mask0_11534_5709'
					style={{ maskType: 'luminance' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='20'
					height='15'
				>
					<rect width='20' height='15' fill='white' />
				</mask>
				<g mask='url(#mask0_11534_5709)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0H20V15H0V0Z'
						fill='#F7FCFF'
					/>
					<rect
						x='0.0169735'
						y='3.4375'
						width='20'
						height='1.875'
						fill='#4564F9'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0H20V1.875H0V0Z'
						fill='#4564F9'
					/>
					<rect
						x='-0.0367947'
						y='6.875'
						width='20'
						height='1.875'
						fill='#4564F9'
					/>
					<rect
						x='0.0697899'
						y='10.25'
						width='20'
						height='1.875'
						fill='#4564F9'
					/>
					<rect
						x='0.0634193'
						y='13.4375'
						width='20'
						height='1.875'
						fill='#4564F9'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M0 0H10V8.75H0V0Z'
						fill='#4564F9'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M4.04442 0H6.02211V3.4375H10V5.80399H6.02211V9.375H4.04442V5.80399H0V3.4375H4.04442V0Z'
						fill='#F7FCFF'
					/>
				</g>
			</g>
			<rect
				x='0.5'
				y='0.5'
				width='19'
				height='14'
				rx='1'
				stroke='black'
				strokeOpacity='0.1'
			/>
			<defs>
				<clipPath id='clip0_11534_5709'>
					<rect width='20' height='15' rx='1.5' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
