import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './logo.module.scss';

export function Logo({
	className = '',
	type = 'full', // full, app
	size = 40,
	id
}) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		logo: true,
		[className]: true
	});

	if (type === 'app') {
		return (
			<svg
				width={size}
				height={size}
				viewBox='0 0 60 60'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<rect width='60' height='60' rx='13' fill='#202020' />
				<path
					d='M33.3 19.5H20.7V40.5H24.9V36.3H33.3C37.9387 36.3 41.7 32.5387 41.7 27.9C41.7 23.2613 37.9387 19.5 33.3 19.5ZM33.3 32.1H24.9V23.7H33.3C35.6193 23.7 37.5 25.5807 37.5 27.9C37.5 30.2193 35.6193 32.1 33.3 32.1Z'
					fill='white'
				/>
			</svg>
		);
	}
	return (
		<svg
			id={id}
			className={classes}
			width='100%'
			viewBox='0 0 140 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.8421 0.631592H0V15.3684H2.94737V12.4211H8.8421C12.0973 12.4211 14.7368 9.78153 14.7368 6.52633C14.7368 3.27112 12.0973 0.631592 8.8421 0.631592ZM8.8421 9.4737H2.94737V3.57896H8.8421C10.4697 3.57896 11.7895 4.89873 11.7895 6.52633C11.7895 8.15393 10.4697 9.4737 8.8421 9.4737ZM50.1053 0.631592L42.7368 15.3684H45.6842L47.1579 12.4211H56L57.4737 15.3684H60.421L53.0526 0.631592H50.1053ZM23.5789 0.631592H26.5263V12.4211H35.3684V15.3684H23.5789V0.631592ZM94.3158 0.631592H91.3684V15.3684H94.3158V0.631592ZM103.158 3.57896V0.631592H117.895V3.57896H112V15.3684H109.053V3.57896H103.158ZM137.053 0.631592L132.632 7.26317L128.211 0.631592H125.263L131.158 9.4737V15.3684H134.105V9.4737L140 0.631592H137.053ZM48.6316 9.4737L51.5789 3.57896L54.5263 9.4737H48.6316ZM70.7369 0.631592L79.579 10.9474V0.631592H82.5263V15.3684H79.579L70.7369 5.05264V15.3684H67.7895V0.631592H70.7369Z'
				fill='currentColor'
			/>
		</svg>
	);
}
