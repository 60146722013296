import React from 'react';
import { Address, Localize } from '@planity/components';
import classNames from 'classnames/bind';
import styles from './main_info.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { BusinessTitle, Icon } from '@planity/ui';
import { formatPhoneNumber } from '@planity/helpers';
import { useTranslation } from '@planity/localization';
import SymbolEuro from '@planity/components/symbol_euro';

export default function MainInfo({
	business,
	showRating,
	hasStartedBooking,
	scrollToReviews,
	isDirectory,
	alwaysVisible
}) {
	useStyles(styles);
	const cx = classNames.bind(styles);
	const classes = cx({
		businessInfo: true,
		hasStartedBooking,
		hideSeparator: !showRating || !business.globalAverage,
		isDirectory,
		alwaysVisible
	});
	const { t } = useTranslation();
	const globalRatingValue =
		business?.rating?.globalRating || business?.globalRating;
	const globalRating =
		globalRatingValue && globalRatingValue % 1 !== 0
			? globalRatingValue.toFixed(1)
			: globalRatingValue;
	const ratingsCount = business?.rating?.ratingsCount || business.ratingsCount;

	return (
		<div className={classes}>
			<div className={styles.title}>
				<BusinessTitle
					id={'business-name'}
					itemProp={'name'}
					text={business.name}
				/>
			</div>
			<div className={styles.infos}>
				<Address
					{...business.address}
					className={styles.address}
					location={business.location}
					shouldOpenMap
					title={business.name}
				/>
				<span className={styles.rateAndPrice}>
					{!!showRating && (
						<span className={styles.rating} onClick={scrollToReviews}>
							<Icon icon='Star' outlined size={'small'} />
							<span>
								{globalRating}
								{ratingsCount &&
									` (${t('search.reviewsNumber', { number: ratingsCount })})`}
							</span>
						</span>
					)}
					{business.globalAverage && (
						<span
							className={cx({
								priceAverage: true,
								hasStartedBooking,
								hideSeparator: !showRating || !business.globalAverage
							})}
						>
							<SymbolEuro rating={business.globalAverage} />
						</span>
					)}
				</span>
				{isDirectory && !!business.phoneNumber && (
					<div className={styles.rateAndPrice}>
						<meta content={business.phoneNumber} itemProp={'telephone'} />
						<Icon icon='PhoneIcon' size={'small'} />
						<Localize text={'business.phoneNumber'} />
						<a href={`tel:${business.phoneNumber}`}>
							&nbsp;{formatPhoneNumber(business.phoneNumber)}
						</a>
					</div>
				)}
			</div>
		</div>
	);
}
