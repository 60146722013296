import { Localize } from '@planity/components/';
import React, {
	useState,
	useRef,
	useMemo,
	useEffect,
	useCallback
} from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './native_app_home_appointments.module.scss';
import classNames from 'classnames/bind';
import { AppointmentPreview } from '@planity/components';
import { useHistory } from 'react-router-dom';
import { useLocalizedRoutes } from '@planity/localization';

export const NativeAppHomeAppointments = ({
	allFutureAppointments,
	pastAppointments
}) => {
	useStyles(styles);
	const cx = classNames.bind(styles);

	const { routes } = useLocalizedRoutes();
	const history = useHistory();
	const linkToBusiness = business =>
		history.push(routes.catchAll({ business }));

	const linkToBookAppointment = ({
		business,
		sequence,
		isDeposit,
		veventToDelete
	}) =>
		history.push(
			routes.catchAll({
				reservation: { business },
				state: { sequence, business, isDeposit, veventToDelete }
			})
		);

	const sortedAppointments = useMemo(() => {
		const futures = Object.entries(allFutureAppointments || {})
			?.filter(([, x]) => !x.isEmpty)
			?.sort(([, a], [, b]) => new Date(a.start) - new Date(b.start));
		const pasts = Object.entries(pastAppointments)
			?.filter(([, x]) => !x.isEmpty)
			?.sort(([, a], [, b]) => new Date(b.start) - new Date(a.start))
			?.slice(0, 3);
		return futures.concat(pasts);
	}, [allFutureAppointments, pastAppointments]);

	const appointmentsRef = useRef([]);

	// Keep track of what's on screen
	const [onScreenDivs, setOnScreenDivs] = useState([]);
	const areVisibleOnScreen = appointmentsRef?.current?.reduce(
		(all, cur, i) => (onScreenDivs.includes(cur) ? all.concat(i) : all),
		[]
	);
	const current = areVisibleOnScreen?.[0];

	const observerCallback = useCallback(
		entries =>
			setOnScreenDivs(_onScreenDivs =>
				entries.reduce((all, cur) => {
					if (cur.isIntersecting) return all.concat(cur.target);
					else return all.filter(t => t !== cur.target);
				}, _onScreenDivs)
			),
		[]
	);
	// Tells if refs are visible on screen (on X axis).
	// Expensive in computation because of setState
	const intersectionsObserver = useMemo(
		() =>
			new IntersectionObserver(observerCallback, {
				threshold: 0,
				rootMargin: '100% 0% 100% 0%'
			}),
		[observerCallback]
	);

	useEffect(() => {
		if (!appointmentsRef?.current || !intersectionsObserver) return;
		intersectionsObserver.disconnect();
		appointmentsRef?.current?.forEach(ref => {
			if (ref) {
				intersectionsObserver.observe(ref);
			}
		});
		return () => intersectionsObserver.disconnect();
	}, [appointmentsRef, intersectionsObserver, sortedAppointments]);

	return (
		<div className={styles.nativeAppHomeAppointments}>
			<h2 className={styles.title}>
				<Localize text={'landing.native.myAppointments'} />
			</h2>

			<div className={styles.appointmentsContainer}>
				{sortedAppointments?.map(([appointmentId, appointment], i) => (
					<div
						id='appointment'
						key={i}
						ref={node => (appointmentsRef.current[i] = node)}
					>
						<AppointmentPreview
							appointment={appointment}
							appointmentId={appointmentId}
							collapseAppointmentSteps={false}
							key={appointmentId}
							linkToBookAppointment={linkToBookAppointment}
							linkToBusiness={linkToBusiness}
						/>
					</div>
				))}
			</div>
			<div className={styles.bullets}>
				{sortedAppointments.length > 1 &&
					new Array(sortedAppointments.length)?.fill(null)?.map((_, i) => (
						<span
							className={cx({
								bullet: true,
								isCurrent: i === current
							})}
							key={i}
						/>
					))}
			</div>
		</div>
	);
};
