// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".search_item-module_searchItem-ailxp{padding:14px 16px;background-color:var(--grey-100);display:flex;flex-direction:row}@media(min-width: 768px){.search_item-module_searchItem-ailxp{padding:14px 24px}}.search_item-module_searchItem-ailxp:last-child{margin-bottom:calc(var(--gutter)*2)}.search_item-module_mainText-y3KZM{font:var(--regular) var(--body-3);color:var(--grey-700)}.search_item-module_secondaryText-jRIQG{font:var(--regular) var(--body-4);color:var(--grey-600)}.search_item-module_description-\\+1EF5{display:flex;flex-direction:column;margin-left:10px;align-self:center}", "",{"version":3,"sources":["webpack://./app/components/search_business_page/search_item.module.scss"],"names":[],"mappings":"AAEA,qCACC,iBAAA,CACA,gCAAA,CACA,YAAA,CACA,kBAAA,CACA,yBALD,qCAME,iBAAA,CAAA,CAMD,gDACC,mCAAA,CAIF,mCACC,iCAAA,CACA,qBAAA,CAED,wCACC,iCAAA,CACA,qBAAA,CAED,uCACC,YAAA,CACA,qBAAA,CACA,gBAAA,CACA,iBAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.searchItem {\n\tpadding: 14px 16px;\n\tbackground-color: var(--grey-100);\n\tdisplay: flex;\n\tflex-direction: row;\n\t@media (min-width: #{styles.$tablet }) {\n\t\tpadding: 14px 24px;\n\t}\n\t/*\n\tproblem of display on the webview when opening the keyboard in Android it was hiding the last category when searching,\n\tto solve the problem a marginBottom has been added to the last item in the category list.\n\t*/\n\t&:last-child {\n\t\tmargin-bottom: calc(var(--gutter) * 2);\n\t}\n}\n\n.mainText {\n\tfont: var(--regular) var(--body-3);\n\tcolor: var(--grey-700);\n}\n.secondaryText {\n\tfont: var(--regular) var(--body-4);\n\tcolor: var(--grey-600);\n}\n.description {\n\tdisplay: flex;\n\tflex-direction: column;\n\tmargin-left: 10px;\n\talign-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"searchItem": "search_item-module_searchItem-ailxp",
	"mainText": "search_item-module_mainText-y3KZM",
	"secondaryText": "search_item-module_secondaryText-jRIQG",
	"description": "search_item-module_description-+1EF5"
};
module.exports = ___CSS_LOADER_EXPORT___;
