import React from 'react';

export default function GraziaLogo({ className }) {
	return (
		<svg
			css={className}
			height='18'
			viewBox='0 0 57 18'
			width='57'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M5.942.134c-.027.142-.056.33-.12.476-.208.483-.697.397-.955.02C4.514.116 4.203 0 3.76 0 1.125 0 0 5.712 0 8.82c0 3.75.972 8.952 3.764 8.96.844.003 1.495-1.265 2.102-1.265.547 0 .565.734.687 1.09.001.002.095 0 .097 0V9.029h.845v-.215H4.129v.215h.834V14.5c0 1.713-.408 3.012-1.16 3.012-1.558 0-1.901-5.889-1.901-8.695 0-2.167.466-8.635 1.855-8.635 1.806 0 2.27 5.274 2.441 5.955l.083-.005L6.035.135S5.943.131 5.942.134zM43.808 17.29V.447 17.29h-.746v.208h3.18v-.208h-.746L45.491.447h.75V.242h-3.18v.205h.747V17.29zm-9.231-.001L38.789.243h-5.863V5.27l.168-.002s.013-.842.013-1.278c0-1.696.667-3.541 1.875-3.541h1.912l-4.098 17.05 6.036-.004v-5.026l-.177-.001c0 .49-.006.98-.006 1.448 0 2.455-1.145 3.335-2.08 3.373h-1.992zM14.34.45l.652-.003c.92 0 1.636 2.302 1.636 3.745 0 1.353-.22 3.168-.773 3.888-.596.774-1.169.735-1.515.737V.45zM11.92.244V.45h.735l.003 16.836h-.84v.211h3.273v-.211h-.75s-.003-8.254 0-8.254c.374-.003.48-.003.815.06.75.14.991 1.752 1.08 2.291.101.62.04 3.697.227 4.703.114.617.517 1.604 1.313 1.604.958 0 1.337-1.263 1.346-1.308l-.054-.04c-.058.099-.383.737-.826.329-.525-.484-.164-5.382-.688-6.447-.13-.263-.775-1.253-1.633-1.253-.174 0-.191-.116 0-.116 1.432 0 2.361-2.128 2.361-4.412 0-2.29-1.104-4.2-2.807-4.2H11.92zm12.043 12l1.237-6.749 1.15 6.75h-2.387zm-1.191 5.042l-.733.005v.205h1.872v-.207h-.868l.873-4.825h2.463l.842 4.821h-.677v.212h3.092v-.209l-.676-.002L25.99 0l-3.216 17.286zM51.3 12.244l1.236-6.749 1.15 6.75H51.3zm-1.191 5.042l-.734.005v.205h1.873v-.207h-.869l.874-4.825h2.463l.842 4.821h-.678v.212h3.093v-.209l-.676-.002L53.324 0 50.11 17.286z'
				fill='#ffff'
				fillRule='evenodd'
			/>
		</svg>
	);
}
