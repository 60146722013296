import React from 'react';
import { Layout } from '../layout';
import { Localize } from '@planity/components';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './cookies_page.module.scss';
import { Container } from '@planity/ui';

export function CookiesPage() {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		cookies: true
	});
	return (
		<div className={classes}>
			<Layout
				breadcrumbsAtTop
				crumbName={'cookies'}
				isDark
				isHiddenOnMobile
				showAppTeaser
				skipCoronavirusModal
			>
				<Container size={'large'}>
					<Localize text='cookies'>
						{text => (
							<div>
								<h1>{text.subtitle1}</h1>
								<p>{text.text1}</p>
								<div className={styles.bloc}>
									<h3>{text.subtitle2}</h3>
									<p>{text.text2}</p>
									<ul>
										<li>
											{text.ieList}
											<ul>
												<li>{text.ieItem1}</li>
												<li>{text.ieItem2}</li>
											</ul>
										</li>
										<li>
											{text.ffList}
											<ul>
												<li>{text.ffItem1}</li>
												<li>{text.ffItem2}</li>
											</ul>
										</li>
										<li>
											{text.safariList}
											<ul>
												<li>{text.safariItem1}</li>
												<li>{text.safariItem2}</li>
												<li>{text.safariItem3}</li>
											</ul>
										</li>
										<li>
											{text.chromeList}
											<ul>
												<li>{text.chromeItem1}</li>
												<li>{text.chromeItem2}</li>
												<li>{text.chromeItem3}</li>
												<li>{text.chromeItem4}</li>
												<li>{text.chromeItem5}</li>
											</ul>
										</li>
									</ul>
								</div>

								<h3>{text.subtitle3}</h3>
								<p>{text.text3}</p>
							</div>
						)}
					</Localize>
				</Container>
			</Layout>
		</div>
	);
}
