import { useLocalization } from '@planity/localization';
import React from 'react';
import OnlinePayment from './appointment_payment';
import { EshopPayment } from './eshopPayment';

export const AppointmentPayment = ({ isEshop, ...props }) => {
	const localizationProps = useLocalization();
	if (isEshop) return <EshopPayment {...props} {...localizationProps} />;
	return <OnlinePayment {...props} />;
};
