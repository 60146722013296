import React from 'react';
import { useTranslation } from '@planity/localization';
import { Button } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './business_popup.module.scss';

export function BusinessPopup({ message, onSubmit }) {
	const { t } = useTranslation();
	useStyles(styles);
	const cx = classNames.bind(styles);

	return (
		<div className={cx({ businessPopup: true })}>
			<p className={styles.title}>{t('covid.measures')}</p>
			<Message message={message} />
			<Button
				className={styles.button}
				isFullMobile
				isFullWidth
				label={t('nav.gotIt')}
				onClick={onSubmit}
			/>
		</div>
	);
}

function Message({ message }) {
	useStyles(styles);
	const cx = classNames.bind(styles);

	const lines = message.trim().split(/\n+/g);
	return (
		<>
			{lines.map((line, i) => {
				const style = lineIndent(line);
				return (
					<p
						className={cx({
							message: true,
							...style
						})}
						key={i}
					>
						{line}
					</p>
				);
			})}
		</>
	);
}

function lineIndent(line) {
	if (line.startsWith('• ') || line.startsWith('- ')) {
		return { textIndent: true };
	}
	return { textIndent: false };
}
