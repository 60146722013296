import { invokeLambda } from '@planity/helpers';
import { firebase } from '@planity/datastores';

export const buyCACProducts = async ({
	businessId,
	userId: customerId,
	totalPrice,
	shippingFees,
	products: orders,
	isClickAndCollect,
	isClickAndShop,
	deliveryAddress,
	billingAddress,
	paymentMethod,
	currentIntentId,
	paymentIntentId
}) => {
	const firebaseToken = await firebase.auth().currentUser.getIdToken();
	return new Promise((resolve, reject) => {
		invokeLambda('buyOrderClickAndCollect', {
			businessId,
			customerId,
			totalPrice,
			shippingFees,
			orders,
			paymentMethod: paymentMethod.id || null,
			isClickAndCollect,
			isClickAndShop,
			deliveryAddress,
			billingAddress,
			firebaseToken,
			chargeId: currentIntentId,
			paymentIntentId,
			last4:
				(paymentMethod && paymentMethod.card && paymentMethod.card.last4) ||
				null,
			newPaymentFlow: true
		})
			.then(response => {
				if (response.errorMessage) {
					throw response.errorMessage;
				}

				resolve(response);
				//if (JSON.stringify(response) === JSON.stringify({})) resolve(response);
			})
			.catch(e => {
				console.error(e);
				let objectError = null;

				try {
					objectError = JSON.parse(e);
				} catch (e) {
					console.error(e);
				}

				if (
					!!objectError &&
					objectError.errorMessage === 'REQUIRE_NEXT_ACTION_ERROR'
				) {
					reject(objectError);
				}

				reject(e);
			});
	});
};
