import styles from './slideshow.module.scss';
import { Icon, Image, NavButton } from '@planity/ui';
import { realIndex } from '../../shared';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Dots } from '@planity/components';
import ReactSwipe from 'react-swipe';
import { useTranslation } from '@planity/localization';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import { noop } from '@planity/helpers';

export function Slideshow({
	images,
	transformations,
	showDots,
	showPagination,
	simpleNav,
	onPictureClick = noop,
	isBusinessSlideShow
}) {
	const { t } = useTranslation();
	useStyles(styles);
	const classes = classNames.bind(styles)({
		slideshow: true,
		container: true,
		isBusinessSlideShow
	});
	const container = useRef();
	const slideshowRef = useRef(null);
	const [index, setIndex] = useState(0);
	const goTo = useCallback(
		(i = 0) => {
			const total = images.length;
			i = ((i % total) + total) % total;
			setIndex(i);
		},
		[images]
	);

	const previous = useCallback(
		e => {
			e.preventDefault();
			e.stopPropagation();
			const prevIndex = index - 1;
			const currentSwipe = slideshowRef?.current?.swipe;
			goTo(prevIndex);
			currentSwipe?.prev();
		},
		[goTo, index]
	);

	const next = useCallback(
		e => {
			e.preventDefault();
			e.stopPropagation();
			const nextIndex = index + 1;
			const currentSwipe = slideshowRef?.current?.swipe;
			goTo(nextIndex);
			currentSwipe?.next();
		},
		[goTo, index]
	);
	const pictures = useMemo(() => {
		if (!images || images.length !== 2) {
			return images;
		}
		// fixing strange bug when there are only 2 pics - dirty I know
		return images.concat(images);
	}, [images]);

	const slideRenderer = useCallback(
		({ index }) => {
			const picture = pictures[realIndex(index, pictures.length)];
			return (
				<Image
					fit='cover'
					key={index}
					transformations={transformations}
					url={picture}
					onClick={onPictureClick}
				/>
			);
		},
		[onPictureClick, pictures, transformations]
	);
	return (
		<div className={classes}>
			<div className={styles.images} ref={container}>
				<ReactSwipe
					className={styles.image}
					ref={slideshowRef}
					swipeOptions={{
						continuous: true,
						callback: setIndex
					}}
				>
					{pictures?.length ? (
						pictures?.map((_, index) => slideRenderer({ index }))
					) : (
						<div className={`${styles.image} ${styles.empty}`}>
							<Image
								alt={'planity-logo'}
								className={styles.noImage}
								url={'https://d2skjte8udjqxw.cloudfront.net/pics/logo.svg'}
							/>
						</div>
					)}
				</ReactSwipe>
			</div>

			{!pictures || pictures.length < 1 ? null : (
				<>
					{showDots && (
						<Dots
							imageIndex={realIndex(index, images.length)}
							imagesLength={images.length}
						/>
					)}
					{simpleNav ? (
						<div className={styles.simpleNav}>
							<button
								aria-label={t('business.gallery.buttons.prev')}
								className={styles.previous}
								onClick={previous}
							>
								<Icon icon='ChevronLeft' size='large' />
							</button>

							<button
								aria-label={t('business.gallery.buttons.next')}
								className={styles.next}
								onClick={next}
							>
								<Icon icon='ChevronRight' size='large' />
							</button>

							{showPagination && (
								<div className={styles.counter}>
									<span>{realIndex(index, images.length) + 1}</span>
									<span>
										{'/'}
										{images.length}
									</span>
								</div>
							)}
						</div>
					) : (
						<nav className={styles.nav}>
							<NavButton
								icon='ChevronLeft'
								isWhite
								label={t('common.previous')}
								onClick={event => previous(event)}
							/>
							<NavButton
								isWhite
								label={t('common.next')}
								onClick={event => next(event)}
							/>
						</nav>
					)}
				</>
			)}
		</div>
	);
}
