import React from 'react';
import { DatePicker } from '@planity/components';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import { format as formatDate } from 'date-fns';
import {
	sendPickDateFromLabelEvent,
	sendPickDateFromNumberEvent
} from '@planity/helpers/analytics';
import { useLocalization, useTranslation } from '@planity/localization';
import { SearchBarInput } from '@planity/ui';
import styles from './date_picker_field.module.scss';
import { useSearchFilter, SearchFilters } from '@planity/ui';

export const DatePickerField = ({
	isExpanded,
	setWhenResult,
	isRedirected
}) => {
	useStyles(styles);

	const cx = classNames.bind(styles);
	const { dateLocale } = useLocalization();
	const {
		localFilters,
		isDatePickerOpen,
		handleDateChange,
		handleInputClick,
		closeDatePicker,
		handleFilterClick
	} = useSearchFilter();

	const selectedDate = localFilters.data.date;
	const { t } = useTranslation();
	const handleDatePickerChange = date => {
		handleDateChange(date);
		setWhenResult(date);
	};
	return (
		<div
			className={cx({
				DatePickerField: true
			})}
		>
			<SearchBarInput
				icon={'Clock'}
				isExpanded={isExpanded}
				label={t('search.placeholders.when.default')}
				placeholder={t('search.placeholders.when.dateReset')}
				readOnly={true}
				type={'when'}
				value={
					selectedDate
						? formatDate(selectedDate, 'PPP', { locale: dateLocale })
						: t('search.placeholders.when.dateReset')
				}
				onClick={event =>
					!isRedirected
						? handleInputClick(event)
						: handleFilterClick({
								event,
								title: t('search.filter.when'),
								content: <SearchFilters onlyDate />,
								className: 'hasOnlyDate',
								isFullHeight: true
						  })
				}
			/>
			<div
				className={cx({
					calendar: true,
					isDatePickerOpen: isDatePickerOpen && !isRedirected
				})}
			>
				<DatePicker
					handleChange={handleDatePickerChange}
					hasFixedWidth
					hasBackground
					selectedDate={selectedDate}
					sendPickDateFromLabelEvent={sendPickDateFromLabelEvent}
					sendPickDateFromNumberEvent={sendPickDateFromNumberEvent}
					onClickOutside={closeDatePicker}
				/>
			</div>
		</div>
	);
};
