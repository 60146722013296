import credentials from '@planity/credentials';
import { createContext, useContext, useEffect, useState } from 'react';

const ViewerHeadersContext = createContext({});

export const useViewerHeaders = () => useContext(ViewerHeadersContext);

const DEFAULT_STATE = {
	isTabletViewer: null,
	isIOSViewer: null,
	isAndroidViewer: null,
	viewerCountry: null,
	viewerHeadersHaveLoaded: false
};
export function ViewerHeadersContextProvider({ children }) {
	const [viewerHeaders, setViewerHeaders] = useState(DEFAULT_STATE);

	useEffect(() => {
		fetch(`https://www.${credentials.HOST}/get-viewer-headers`)
			.then(response =>
				setViewerHeaders({
					isTabletViewer: JSON.parse(
						response?.headers?.get('x-planity-is-tablet-viewer') || null
					),
					isIOSViewer: JSON.parse(
						response?.headers?.get('x-planity-is-ios-viewer') || null
					),
					isAndroidViewer: JSON.parse(
						response?.headers?.get('x-planity-is-android-viewer') || null
					),
					viewerCountry:
						response?.headers?.get('x-planity-viewer-country') || null,
					viewerHeadersHaveLoaded: true
				})
			)
			.catch(console.error);
	}, []);

	useEffect(() => {
		if (process.env.NODE_ENV === 'development')
			console.log(`Detected viewer headers are `, viewerHeaders);
	}, [viewerHeaders]);

	return (
		<ViewerHeadersContext.Provider value={viewerHeaders}>
			{children}
		</ViewerHeadersContext.Provider>
	);
}

export const withViewerHeaders = Component => props =>
	(
		<ViewerHeadersContext.Consumer>
			{viewerHeadersProps => <Component {...viewerHeadersProps} {...props} />}
		</ViewerHeadersContext.Consumer>
	);
