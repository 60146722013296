import React, { useEffect, useRef } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { scrollTo } from '@planity/helpers';
import { Localize, useAuth } from '@planity/components';
import { useTranslation } from '@planity/localization';
import { Button } from '@planity/ui';
import styles from './eshop_confirmation.module.scss';

export const EshopConfirmation = ({ onClick }) => {
	const { user } = useAuth();
	const { t } = useTranslation();
	const ref = useRef();

	useStyles(styles);
	useEffect(() => {
		if (process.env.WHITE_LABEL_WEBSITE)
			ref?.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		if (process.env.BROWSER && !process.env.WHITE_LABEL_WEBSITE) scrollTo();
	}, []);

	return (
		<div className={styles.container} ref={ref}>
			<p className={styles.title}>
				<Localize text='eshop.confirmation.title' />
			</p>
			<div className={styles.description}>
				<Localize text={'eshop.confirmation.text1'} />
				<span className={styles.email}>{user?.email}</span>
				<div>
					<Localize text={'eshop.confirmation.text2'} />
				</div>
			</div>
			<Button
				className={styles.recapButton}
				isFullMobile
				label={t('eshop.confirmation.orderButton')}
				size='large'
				onClick={onClick}
			/>
		</div>
	);
};
