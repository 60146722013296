import { useLocalization } from '@planity/localization';
import React, { useState } from 'react';
import { format as formatDate } from 'date-fns';
import { centsToPrice } from '@planity/helpers';
import { GiftVoucherItem } from './gift_voucher/gift_voucher_item';
import UserBuyingItemHeader from './user_buying_item_header';
import { downloadPdf } from '@planity/helpers';
import ClickAndCollectProductsList from './click_and_collect/click_and_collect_products_list';
import { USER_BUYING_TYPES } from './user_buying_types';
import { OrderFooter } from '@planity/ui';
import styles from './user_buying_item.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { sendToUserApp, isNativeApp } from '@planity/webview';

const USER_BUYINGS_WITH_DOWNLOAD = [USER_BUYING_TYPES.GIFT_VOUCHER];

export default function UserBuyingItem({
	buyingId,
	userId,
	userBuying,
	type,
	linkToBusiness
}) {
	useStyles(styles);
	const { dateLocale } = useLocalization();
	const [isLoadingPDF, setIsLoadingPDF] = useState(null);
	const formattedBoughtAt = formatDate(userBuying.boughtAt, 'd MMMM yyyy', {
		locale: dateLocale
	});

	const formattedTotalPrice = centsToPrice(userBuying.totalPrice);
	const formattedShippingFees = centsToPrice(userBuying.deliveryFees);

	let orderNumber = userBuying.order_number;

	// It is an old eshop buying
	if (!orderNumber) {
		orderNumber = userBuying.order_id
			? userBuying.order_id.slice(userBuying.order_id.lastIndexOf('/') + 1)
			: null;
	}

	// It's not an old user buying and there's a buyingId
	// so it must be a click & collect buying
	if (!orderNumber && !!buyingId) {
		orderNumber = buyingId.toUpperCase();
	}

	const expirationDate = userBuying.expires
		? formatDate(userBuying.expires, 'd MMMM yyyy', {
				locale: dateLocale
		  })
		: null;

	const hasDownloadLink = USER_BUYINGS_WITH_DOWNLOAD.find(
		type => userBuying.type === type
	);

	const downloadPdfFile = async () => {
		try {
			if (isNativeApp) {
				setIsLoadingPDF(buyingId);
				const res = await downloadPdf(userId, buyingId);
				sendToUserApp({
					type: 'DOWNLOAD_GIFT_VOUCHER',
					payload: JSON.stringify({
						type,
						userBuying,
						res
					})
				});
				setIsLoadingPDF(false);
			} else {
				setIsLoadingPDF(buyingId);
				const res = await downloadPdf(userId, buyingId);
				if (!res.url) {
					throw new Error();
				} else {
					//window.location.href = res.url;
					const link = document.createElement('a');
					link.href = res.url;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					setIsLoadingPDF(false);
					//window.open(res.url, '_blank');
				}
			}
		} catch (e) {
			console.error(e);
		}
	};
	const business = userBuying?.business;
	if (!business) return null;
	return (
		<div
			className={`planity_my-account-profile-form-wrapper ${styles.userBuyingItem}`}
		>
			<UserBuyingItemHeader
				boughtAt={formattedBoughtAt}
				business={business}
				businessId={userBuying.businessId}
				businessName={userBuying.businessName}
				expiresAt={expirationDate}
				giftVoucherId={userBuying.id}
				linkToBusiness={linkToBusiness}
				orderId={orderNumber}
				orderStatus={userBuying.status}
				receiver={userBuying.receiver}
				shipping_status={userBuying.shipping_status}
				trackingNumber={userBuying.tracking_number}
				trackingUrl={userBuying.tracking_url}
				type={type}
			/>
			<div
				className={`
								${styles.bodyContainer}
								${type === USER_BUYING_TYPES.GIFT_VOUCHER && styles.giftVoucherContainer}
							`}
			>
				{type === USER_BUYING_TYPES.GIFT_VOUCHER && (
					<GiftVoucherItem
						business={business}
						businessName={userBuying.businessName}
						emittedIds={userBuying.emittedIds}
						linkToBusiness={linkToBusiness}
						price={formattedTotalPrice}
					/>
				)}

				{(type === USER_BUYING_TYPES.CLICK_AND_COLLECT ||
					type === USER_BUYING_TYPES.CLICK_AND_SHOP) && (
					<ClickAndCollectProductsList
						business={business}
						isClickAndShop={type === USER_BUYING_TYPES.CLICK_AND_SHOP}
						linkToBusiness={linkToBusiness}
						orderId={orderNumber}
						products={userBuying.products}
						shippingFees={formattedShippingFees}
					/>
				)}

				<OrderFooter
					buyingId={buyingId}
					downloadLink={userBuying.receipt_pdf_url}
					hasDownloadLink={hasDownloadLink}
					isClickAndShop={type === USER_BUYING_TYPES.CLICK_AND_SHOP}
					isGiftVoucher={type === USER_BUYING_TYPES.GIFT_VOUCHER}
					isLoading={isLoadingPDF}
					price={formattedTotalPrice}
					setIsLoading={setIsLoadingPDF}
					shipping_status={userBuying.shipping_status}
					shippingFees={formattedShippingFees}
					onDownload={!userBuying.receipt_pdf_url && downloadPdfFile}
				/>
			</div>
		</div>
	);
}
