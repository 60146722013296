import React from 'react';

export const FileText = () => {
	return (
		<svg
			width='25'
			height='24'
			viewBox='0 0 25 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M8.14999 19.25H16.65C17.7546 19.25 18.65 18.3546 18.65 17.25V9L14.4 4.75H8.14999C7.04542 4.75 6.14999 5.64543 6.14999 6.75V17.25C6.14999 18.3546 7.04542 19.25 8.14999 19.25Z'
				stroke='#08080B'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M18.4 9.25H14.15V5'
				stroke='#08080B'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M10.15 15.25H14.65'
				stroke='#08080B'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M10.15 12.25H14.65'
				stroke='#08080B'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
