import React from 'react';

export default function IconWrapper({
	color,
	style,
	onClick,
	code,
	width,
	height,
	size,
	transform,
	viewBox = '0 0 24 24',
	className,
	paths
}) {
	if (!!paths && paths.length > 0) {
		// Multiple paths
		return (
			<svg
				css={[style, className]}
				height={height || size || '24'}
				viewBox={viewBox}
				width={width || size || '24'}
				xmlns='http://www.w3.org/2000/svg'
				onClick={onClick}
			>
				{paths.map((path, i) => (
					<path
						d={path.code}
						fill={color}
						fillRule='evenodd'
						key={`svg-${i}`}
						transform={path.transform}
					/>
				))}
			</svg>
		);
	}

	// Single path
	return (
		<svg
			css={[style, className]}
			height={height || size || '24'}
			viewBox={viewBox}
			width={width || size || '24'}
			xmlns='http://www.w3.org/2000/svg'
			onClick={onClick}
		>
			<path d={code} fill={color} fillRule='evenodd' />
			{!!transform && <use transform={transform} />}
		</svg>
	);
}
