import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './avatar.module.scss';
import { Image, Icon } from '@planity/ui';

export function Avatar({
	altDescription = '',
	className = '',
	imageUrl,
	icon = '',
	initial,
	size = 'medium',
	sizeIcon = '24',
	shape = 'circle'
}) {
	useStyles(styles);
	const classes = classNames.bind(styles)({
		avatar: true,
		contentIcon: icon,
		[shape]: true,
		[size]: true,
		[className]: className !== undefined
	});

	const renderImage = imageUrl && (
		<Image
			alt={altDescription}
			url={imageUrl}
			transformation={'w_128,h_128,c_thumb'}
			isCloudinary
		/>
	);

	const renderInitial = !imageUrl && initial && (
		<span className={styles.initial}>{initial}</span>
	);

	const renderIcon = icon && <Icon icon={icon} size={sizeIcon} />;

	return (
		<div className={classes}>
			{renderImage}
			{renderInitial}
			{renderIcon}
		</div>
	);
}
