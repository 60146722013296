import React from 'react';
import { Localize } from '@planity/components';
import { Button } from '@planity/ui';

const GiftVoucherCTA = ({ id, onPress, size }) => {
	return (
		<Button
			iconLeft={'Gift'}
			id={id}
			label={<Localize text={'gift.modalTitle'} />}
			size={size}
			type={'tertiary'}
			onClick={onPress}
		/>
	);
};

export default GiftVoucherCTA;
