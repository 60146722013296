export const BACK_TO_IDLE = 'BACK_TO_IDLE';
export const EMAIL_CHANGE = 'EMAIL_CHANGE';
export const PASSWORD_CHANGE = 'PASSWORD_CHANGE';
export const PASSWORD_CONFIRMATION_CHANGE = 'PASSWORD_CONFIRMATION_CHANGE';
export const PHONE_NUMBER_CHANGE = 'PHONE_NUMBER_CHANGE';
export const FIRST_NAME_CHANGE = 'FIRST_NAME_CHANGE';
export const LAST_NAME_CHANGE = 'LAST_NAME_CHANGE';
export const CGU_ACCEPTANCE_CHANGE = 'CGU_ACCEPTANCE_CHANGE';
export const SIGN_IN_WITH_EMAIL_AND_PASSWORD =
	'SIGN_IN_WITH_EMAIL_AND_PASSWORD';
export const SIGN_UP_WITH_EMAIL_AND_PASSWORD =
	'SIGN_UP_WITH_EMAIL_AND_PASSWORD';
export const REQUEST_PASSWORD_RESET_EMAIL = 'REQUEST_PASSWORD_RESET_EMAIL';
export const SIGN_IN_WITH_GOOGLE = 'SIGN_IN_WITH_GOOGLE';
export const SIGN_IN_WITH_FACEBOOK = 'SIGN_IN_WITH_FACEBOOK';
export const SIGN_IN_WITH_GITHUB = 'SIGN_IN_WITH_GITHUB';
export const PHONE_NUMBER_SUBMIT = 'PHONE_NUMBER_SUBMIT';
export const USER_DATA_EDITION_SUBMIT = 'USER_DATA_EDITION_SUBMIT';
export const USER_PASSWORD_SUBMIT = 'USER_PASSWORD_SUBMIT';
export const USER_NAMES_SUBMIT = 'USER_NAMES_SUBMIT';
export const CHECK_SUBSCRIPTION_STEP = 'CHECK_SUBSCRIPTION_STEP';
export const RESET_MACHINE = 'RESET_MACHINE';
export const RESET_AUTH_ERRORS = 'RESET_AUTH_ERRORS';
